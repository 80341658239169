const COMMON = "https://bk.collegeclue.in/";
const URL_ENUM = {
  UNIVERSITY_SIGNUP_REQUEST: COMMON + 'university/register',
  COACHING_SIGNUP_REQUEST: COMMON + "coaching/register",
  PARTNER_REQUEST: COMMON + "partner/register",
  VEIRY: COMMON + "university/verify",
  CONTENT: COMMON + "common/addOnContent",
  SEND_OTP_EMAIL: COMMON + "university/send-otp",
  UPDATE_UNIVERSITY_DATA: COMMON + "university",
  UPDATE_COACHING_DATA: COMMON + "coaching",
  UNIVERISTY_BY_TOKEN: COMMON + "university/access-token",
  COACHING_BY_TOKEN: COMMON + "coaching/access-token",
  STUDENT_BY_TOKEN: COMMON + "student/access-token",
  PARTNER_BY_TOKEN: COMMON + "partner/access-token",
  UPDATE_PARTNER: COMMON + "partner/editPartner",
  PARTNER: COMMON + "partner",
  PARTNER_LEAD: COMMON + "partner/lead",
  UNIVERSITY_PRODUCT: COMMON + "university/product",
  COACHING_PRODUCT: COMMON + "coaching/product",
  STUDENT_SIGNUP: COMMON + "student/register",
  STUDENT_REVIEWS: COMMON + "student/review",
  STUDENT_PRODUCT: COMMON + "student/products",
  STUDENT_ADD_ONS: COMMON + "student/add-ons",
  STUDENT_SUB_PRODUCT: COMMON + "student/sub-products",
  STUDENT_ADD_TO_CART: COMMON + "student/cart-item",
  DECREMENT_CART_ITEM: COMMON + "student/cart-item",
  UNIVERSITY_STUDENT: COMMON + "university/student-application",
  COACHING_STUDENT: COMMON + "coaching/student-application",
  STUDENT_APPLICATION: COMMON + "student/student-application",
  REVIEW_STATS: COMMON + "student/review/stats",
  GET_FROM_CART: COMMON + "student/cart",
  UNIVERSITY_BLOG: COMMON + "university/blog",
  COACHING_BLOG: COMMON + "coaching/blog",
  STUDENT_BLOG: COMMON + "student/blog",
  STDENT_CONTACT: COMMON + "student/contact-us",
  ADD_ORDER: COMMON + "student/order",
  VERIFY_ORDER: COMMON + "student/verify-order",
  ADD_UNIVERSITY_TEST: COMMON + "student/",
  UNIVERSITY_TEST: COMMON + "university/test",
  COACHING_TEST: COMMON + "coaching/test",
  UNIVERSITY_QUESTION: COMMON + "university/question",
  COACHING_QUESTION: COMMON + "coaching/question",
  STUDENT_TEST: COMMON + "student/test",
  UNIVERSITY_COURSE: COMMON + "university/course",
  COACHING_COURSE: COMMON + "coaching/course",
  STUDENT_COURSE: COMMON + "student/course",
  STUDENT_PROGRESS: COMMON + "student/course-progress",
  STUDENT_PURCHASE: COMMON + "student/purchase",
  STUDENT_PAGE: COMMON + 'student/custom-page',

  LOGIN: COMMON + "university/login",
  COACHING_LOGIN: COMMON + "coaching/login",
  STUDENT_LOGIN: COMMON + "student/login",
  PARTNER_LOGIN: COMMON + "partner/login",

  STUDENT_UNIVERSITY: COMMON + "student/university",
  STUDENT_COACHING: COMMON + "student/coaching",
  UPLOAD_ON_S3: COMMON + "university/upload_data_on_s3",
  CONFIG: COMMON + "university/configuration",

  PARTENR_RESET_PASSWORD: COMMON + "partner/setNewPassword",
  STUDENT_RESET_PASSWORD: COMMON + "student/setNewPassword",
  STUDENT_FORGOT_PASSWORD: COMMON + "student/forgetPassword",
  PARTNER_FORGOT_PASSWORD: COMMON + "partner/forgetPassword",
  COACHING_RESET_PASSWORD: COMMON + "coaching/setNewPassword",
  COACHING_FORGOT_PASSWORD: COMMON + "coaching/forgetPassword",
};
export default URL_ENUM;
