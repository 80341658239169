import * as React from 'react';
import "./blog.css";
import AddIcon from '@mui/icons-material/Add';
import HomeLayout from "../homeLayout/HomeLayout";
import StudentBlogTable from "../../components/BasicTable/BasicTable";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../components/Pagination/Pagination";
import Spinner from "../../components/Spinner/Spinner";
import { 
    Typography,
    IconButton,
    Button
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from "react-router-dom";
import { 
    getBlogs,
    getBlogById,
    setBlogById } from "../../redux/instituteStudent/instituteStudentSlice";
import routes from '../../react_routes_enum';
import { toast } from 'react-toastify';

export default function UniversityBlog(){
    const [limit, setLimit] = React.useState(10);
    const [offset, setOffset] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const isWebsiteFor = localStorage.getItem("is-website-for");
    const colR = ["SNo", "Title", "Tags","Category","Status","Action",];
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { 
        isLoading,
        searchText,
        isError,
        message,
        totalCount,
        blogsData } = useSelector((state) => state.instituteStudent);
    const createBlog = () => {
        dispatch(setBlogById())
        navigate(routes.CREATE_BLOG)
    }
    React.useEffect(() => {
        getBlog();
    },[limit, offset, searchText])

    const getBlog = () => {
        dispatch(getBlogs({limit, offset, searchText}))
    }
    const viewBlog = (id) => {
        const res = dispatch(getBlogById({id}));
        res?.then((r) => {
            if(r.payload.statusCode === 200){
                navigate(routes.VIEW_BLOG);
            }else{
                toast.error(r?.payload?.message);
            }
        })

    }

    if (isLoading) return <Spinner />;
    if (isError) return <h1>{message}</h1>;

    return (
        <>
        <HomeLayout/>
        <div style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginRight: '24px',
            marginTop:'124px'
        }}>
            <Button className='create-blog-btn' 
                sx={{
                    background: isWebsiteFor === 'university' ? 'var(--secondary-color)' : 'var(--tertiary-color)',
                    "&:hover":{
                        background: isWebsiteFor === 'university' ? 'var(--secondary-color)' : 'var(--tertiary-color)'
                    }
                }} 
                onClick={createBlog}>
                <AddIcon /> Create Blog
            </Button>
        </div>
        <div style={{marginTop: '0'}} className="pagination-addPost"> 
            <Pagination
                totalCount={totalCount}
                limit={limit}
                page={page}
                changeLimit={setLimit}
                changeOffset={setOffset}
                changePage={setPage}
            />
        </div>
        <div className="content-box table-box" style={{marginTop: '0px'}}>
            <StudentBlogTable
                columns={colR}
                where="recipes"
                rows={
                    blogsData && blogsData?.map((item) => {
                    const res = {
                    ...item,
                    Status:(
                        <>
                            {item?.status === "pending" &&
                            <Typography 
                                style={{
                                    color: '#ECAB2D',
                                    fontWeight: 'bold'}}>
                                Pending
                            </Typography>}
                            {item?.status === "rejected" &&
                            <Typography style={{
                                color: '#EF4840',
                                fontWeight: 'bold'}}>
                                Rejected
                            </Typography>}
                            {item?.status === "accepted" &&
                            <Typography style={{
                                color: '#07AB35',
                                fontWeight: 'bold'}}>
                                Accepted
                            </Typography>}
                        </>
                    ),
                    Action: (
                        <div>
                            <IconButton
                                color="inherit"
                                edge="start"
                                onClick={() => viewBlog(item?._id)}
                                style={{display:'block'}}
                                >
                                    <VisibilityIcon sx={{color: '#BA68C8'}} className="action-icon" />
                                    <Typography component="p" variant="p" 
                                    sx={{
                                        color: '#BA68C8',
                                        fontWeight: 'bold'
                                        }} className="action-buton">
                                    View
                                    </Typography>
                            </IconButton>
                        </div>
                    )
                    };
                    return res;
                })
                }
            />
        </div>
        </>
    )
}