import * as React from 'react';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import HomeLayout from "../homeLayout/HomeLayout";
import Pagination from '@mui/material/Pagination';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import QuestionImage from "../../assest/images/gallery_image.png";
import { getStudentTestById, getTestResult } from "../../redux/test/testSlice";
import { makeStyles } from "@mui/styles";
import { toast } from "react-toastify";
import LoaderIcon from "../../components/Loader/Loader";
import CircularProgress from '@mui/material/CircularProgress';
import './student.css';

const useStyle = makeStyles({
  addMore:{
    height: '36px',
    textTransform: 'capitalize !important',
    fontWeight: 'bold !important',
  },
  changesButton: {
    color: '#fff !important',
    textTransform: 'capitalize !important',
    width: '105px',
    height: '40px',
    fontSize: '16px !important',
    borderRadius: '4px !important',
    boxShadow: '0 2px 5px rgb(0, 0, 0, 0.5)',
    "&:hover":{
        backgroundColor: '#07AB35 !important',
    },
  },
  formInputLabel: {
    color: '#000 !important',
    fontSize: '14px !important',
    marginBottom: '4px',
    fontWeight: 'bold !important'
  },
  marginBot24: {
    marginBottom: '24px'
  },
})
const submitBody = {
    responses: []
}
export default function Dashboard() { 
  const classes = useStyle();
  const dispatch = useDispatch();
  const [isTestStarted, setIsTestStarted] = React.useState(false);
  const [isTestCompleted, setIsTestCompleted] = React.useState(false);
  const [isTimeCompleted, setIsTimeCompleted] = React.useState(false);
  const [submitRespBody, setSubmitRespBody] = React.useState(submitBody);
  const [isResult, setIsResult] = React.useState(false);
  const [activePage, setActivePage] = React.useState(1);
  const [btnLoader, setBtnLoader] = React.useState(false);
  const [isShowQuestion, setIsShowQuestion] = React.useState(false);
  const [maxProgress, setMaxProgress] = React.useState(100);
  const [[mins, secs], setTime] = React.useState([
    60,
    0
  ]);

  const {
    studentTestyId,
    testResult
  } = useSelector((state) => state.test)
  
  const selectOption = (index, opt, question) => {
    let array = submitRespBody?.responses;
    if(question?.sub_type === "single_select"){
        let ind = array?.findIndex(item => item.question_id === question?._id);
        if(ind > -1){
            array[ind] = {question_id: question?._id, response: [opt]}
        }else{
            array.push({question_id: question?._id, response: [opt]})
        }
        setSubmitRespBody({...submitRespBody, responses: [...array]})
    }else{
        let ind = array?.findIndex(item => item.question_id === question?._id);
        if(ind > -1){
            let checkedIndex = array[ind].response.indexOf(opt);
            if(checkedIndex > -1){
                array[ind].response.splice(checkedIndex, 1);
                if(array[ind].response.length === 0){
                    array.splice(ind, 1);
                }
            }else{
                array[ind].response.push(opt);
            }
        }else{
            array?.push({question_id: question?._id, response: [opt]})
        }
        setSubmitRespBody({...submitRespBody, responses: [...array]})
    }
  }

  const tick = () => {
    if (mins === 0 && secs === 0) {
        if(!isResult && !isShowQuestion){
            setIsResult(false);
            setIsTimeCompleted(true);
            setIsTestCompleted(false);
            setIsTestStarted(false);
        }
    }else if (secs === 0) {
      setTime([mins - 1, 59]);
    } else {
      setTime([mins, secs - 1]);
    }
  };

  React.useEffect(() => {
    const timerId = setInterval(() => tick(), 1000);
    return () => clearInterval(timerId);
  });

  React.useEffect(() => {
    if(studentTestyId){
        setMaxProgress(studentTestyId?.duration_in_min);
        setTime([studentTestyId?.duration_in_min, 0]);
    }
    dispatch(getStudentTestById({id: "649c6e76fc6871b2d6486f1b"}));
  },[])

  const handlePageChange = (event, value) => {
    setActivePage(value);
  }

  const prevPage = (pageNo) => {
    setActivePage(pageNo-1);
  }

  const nextPage = (pageNo) => {
    setActivePage(pageNo+1);
  }

  const checkSelectedOption = (value, id) => {
    let status = false;
    let itm = submitRespBody?.responses?.filter(
        item => item.question_id === id)
    let index = itm[0]?.response?.indexOf(value);
    if(index > -1){
        status = true
    }else{
        status = false;
    }
    return status;
  }

  const startTestClicked = () => {
    setIsTestStarted(true);
    setMaxProgress(studentTestyId?.duration_in_min);
    if(studentTestyId){
        setTime([studentTestyId?.duration_in_min, 0]);
    }
  }

  const getResult = () => {
    setBtnLoader(true);
    const res = dispatch(getTestResult({id: studentTestyId?._id, body: submitRespBody}));
    res?.then((r) => {
        setBtnLoader(false);
        if(r?.payload?.statusCode === 200){
            setIsResult(true);
            setIsTimeCompleted(false);
            setIsTestCompleted(false);
            setIsTestStarted(false);
        }else{
            toast.error(r?.payload?.message);
        }
    })
  }

  const showQuestions = () => {
    setActivePage(1);
    setIsShowQuestion(true);
    setIsResult(false);
    setIsTimeCompleted(false);
    setIsTestCompleted(false);
    setIsTestStarted(true);
  }

  const checkCorrectOption = (value, id) => {
    let inUserR;
    let inCorectR;
    let color = '#F5F5F5';
    let resultR = testResult?.responses?.filter(
        item => item.question_id === id);
    inUserR = resultR[0]?.response?.find(item => item === value);
    inCorectR = resultR[0]?.correct_answers?.find(item => item === value);
    if(inCorectR){
        color = '#07AB35';
        return color;
    }
    if(inUserR){
        color = '#EF4840';
        return color;
    }
  }

  const resultSection = () => {
    setIsResult(true);
    setIsShowQuestion(false);
    setIsTimeCompleted(false);
    setIsTestCompleted(false);
    setIsTestStarted(false);
  }

  const changePage = (value) => {
    setActivePage(value);
  }

  return (
    <>
      <HomeLayout />
      <div className="application-content-box marginTopTest" >
        <h2 className="subject-heading">
            {studentTestyId?.title}
        </h2>
        {(!isTestStarted && !isTimeCompleted && !isTestCompleted && 
        !isResult) && 
        <div className="before-test">
            <div style={{
                display: 'flex',
                justifyContent: 'center'
            }}>
                <div>
                    <p>
                        Before starting your test, read the instructions:
                    </p>
                    <ul>
                        {studentTestyId?.instructions?.map((item, index) => (
                            <li key={index}>
                                <span>{index+1}.</span> <span>{item}</span>
                            </li>
                        ))}
                    </ul>
                    <p style={{marginBottom: 12}}>
                        Marks Calculation
                    </p>
                    <div>
                        <div className="marks-font" style={{
                            marginBottom: '8px'
                        }}>
                            <span style={{
                                color: '#EF0000',
                                fontWeight: 'bold'
                            }}>Negative marking &nbsp;&nbsp; : </span>
                            <span style={{
                                color: 'rgb(0, 0, 0, 0.5)',
                                fontWeight: 'bold'
                            }}>&nbsp;{studentTestyId?.marks_per_question?.negative} per question</span>
                        </div>
                        <div className="marks-font">
                            <span style={{
                                color: '#3C1CDE',
                                fontWeight: 'bold'
                            }}>Marks per question : </span>
                            <span style={{
                                color: 'rgb(0, 0, 0, 0.5)',
                                fontWeight: 'bold'
                            }}>&nbsp;{studentTestyId?.marks_per_question?.positive} per question</span>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '48px',
            }}>
                <Button 
                    sx={{
                        backgroundColor: '#07AB35',
                    }}
                    className={classes.changesButton}
                    onClick={startTestClicked}>
                    Start Test
                </Button>
            </div>
        </div>
        }
        {(isTestStarted || isTimeCompleted || isTestCompleted || 
        isResult) &&
        <div>
            {isTestStarted && 
            <>
                <div className="test-series-box">
                    <div className="test-series-pagination">
                        {!isShowQuestion ?
                        <Pagination 
                            count={studentTestyId?.question_ids?.length} 
                            variant="contained" 
                            shape="rounded"
                            size="small"
                            color="primary"
                            page={activePage}
                            onChange={handlePageChange}
                            /> :
                        <CustomPagination 
                            active={activePage}
                            testResponse={testResult?.responses} 
                            onChangePage={(value) => changePage(value)}
                            prev={(value) => prevPage(value)}
                            next={(value) => nextPage(value)}
                            
                        />
                        }
                    </div>
                    {!isShowQuestion &&
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                        <span>Time:</span>
                        <span style={{
                            color: 'var(--primary-color)',
                            marginLeft: '4px'
                        }}>{`${mins}:${secs}`}</span>
                        <DiskProgress 
                            max={maxProgress} 
                            min={0} 
                            value={`${mins}:${secs}`} />
                    </div>}
                </div>
                <div className="question-box">
                    <p className="question-number">
                        Question {activePage}
                    </p>
                    <p style={{
                        margin: 0,
                        fontSize: '12px',
                        color: 'rgb(0, 0, 0, 0.5)'
                    }}>
                        {studentTestyId?.question_ids[activePage-1]?.sub_type === "single_select" ? 
                        'Single select type' : 'Multiple select type'}
                    </p>
                    <p className="question-text">
                        {studentTestyId?.question_ids[activePage-1]?.title}
                    </p>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        margin: '8px 0'
                    }}>
                        <img 
                            src={QuestionImage} 
                            alt="QuestionImage" 
                            className="question-image" />
                    </div>
                    <div className="question-option">
                        <ul style={{
                            width: '100%'
                        }}>
                            {!isShowQuestion ? 
                            studentTestyId?.question_ids[activePage-1]?.options?.map((item, index) => (
                                <li key={index} 
                                    onClick={() => selectOption(
                                        index, 
                                        item, 
                                        studentTestyId?.question_ids[activePage-1]
                                    )}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        width: 'fit-content'
                                    }}>
                                    <div style={{
                                        background: 
                                            checkSelectedOption(
                                                item,
                                                studentTestyId?.question_ids[activePage-1]?._id
                                                ) ? 
                                            '#3F9AE0' : 
                                            '#F5F5F5',
                                        boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.25)',
                                        borderRadius: '2px',
                                        marginRight: '12px',
                                        width: '28px',
                                        height: '28px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        color: '#fff'
                                    }}>
                                        <CheckIcon />
                                    </div>
                                    <span style={{
                                        color: checkSelectedOption(
                                            item,
                                            studentTestyId?.question_ids[activePage-1]?._id
                                            ) ? '#3F9AE0' : 'inherit'
                                    }}>{item}</span>
                                </li>
                            )):
                            studentTestyId?.question_ids[activePage-1]?.options?.map((item, index) => (
                                <li key={index} style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}>
                                    <div style={{
                                        background: 
                                            checkCorrectOption(
                                                item,
                                                studentTestyId?.question_ids[activePage-1]?._id
                                                ),
                                        boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.25)',
                                        borderRadius: '2px',
                                        marginRight: '12px',
                                        width: '28px',
                                        height: '28px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        color: '#fff'
                                    }}>
                                        {checkCorrectOption(
                                                item,
                                                studentTestyId?.question_ids[activePage-1]?._id
                                                ) === "#07AB35" ?
                                            <CheckIcon /> : <CloseIcon />}
                                    </div>
                                    <span style={{
                                        color: checkCorrectOption(
                                            item,
                                            studentTestyId?.question_ids[activePage-1]?._id
                                            )
                                    }}>{item}</span>
                                </li>
                            ))
                            }
                        </ul>
                        {studentTestyId?.question_ids[activePage-1]?.image &&
                        <div className="question-image-2">
                            <img 
                                src={studentTestyId?.question_ids[activePage-1]?.image} 
                                alt="QuestionImage"/>
                        </div>}

                    </div>
                    <div style={{
                        display: 'flex',
                        marginTop: 24,
                    }} className='test-series-btns'>
                        <div></div>
                        <div className='test-series-btns-pre-next'>
                            <Button sx={{
                                boxShadow: '0.625px 0.625px 1.25px rgba(0, 0, 0, 0.25)',
                            }} 
                            onClick={() => prevPage(activePage)}
                            disabled={activePage === 1}>
                                <ArrowBackIosIcon fontSize='small' />
                            </Button>
                            <Button sx={{
                                boxShadow: '0.625px 0.625px 1.25px rgba(0, 0, 0, 0.25)',
                                marginLeft: '8px'
                            }} 
                            onClick={() => nextPage(activePage)}
                            disabled={activePage === studentTestyId?.question_ids?.length}>
                                <ArrowForwardIosIcon fontSize='small' />
                            </Button>
                        </div>
                        {!isShowQuestion ?
                        <Button 
                            disabled={btnLoader}
                            className={classes.changesButton} 
                            onClick={getResult}
                            sx={{
                                backgroundColor: btnLoader ? 'rgb(0, 0, 0, 0.3)' :
                                    '#07AB35'
                            }}
                            >
                            {btnLoader ? <LoaderIcon /> : 'Submit'}
                        </Button>:
                        <Button 
                            disabled={btnLoader}
                            className={classes.changesButton} 
                            onClick={resultSection}
                            sx={{
                                backgroundColor: btnLoader ? 'rgb(0, 0, 0, 0.3)' :
                                    '#07AB35'
                            }}
                            >
                            Result
                        </Button>
                        }
                    </div>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '24px',
                    }}>
                        
                    </div>
                </div>
            </>}
            {isTestCompleted && 
            <div className="completed-test question-box">
                <div style={{
                    display: 'flex',
                    justifyContent: 'center'
                }}>
                    <div>
                        <p>
                            Test Completed
                        </p>
                        <p>
                            Click on “GET RESULT” button to know the results
                        </p>
                    </div>
                </div>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '24px',
                }}>
                    <Button className={classes.changesButton}>
                        Get Result
                    </Button>
                </div>
            </div>}
            {isTimeCompleted && 
                <div className="completed-test question-box">
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <div>
                            <p style={{
                                color: '#EF4840'
                            }}>
                                Time Up!!
                            </p>
                            <p>
                                Click on “GET RESULT” button to know the results
                            </p>
                        </div>
                    </div>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '24px',
                    }}>
                        <Button 
                            sx={{
                                backgroundColor: btnLoader ? 'rgb(0, 0, 0, 0.3)' :
                                    '#07AB35'
                            }}
                            className={classes.changesButton} 
                            onClick={getResult}>
                            {btnLoader ? <LoaderIcon /> : 'Get Result'}
                        </Button>
                    </div>
                </div>
            }
            {isResult && 
                <div className="completed-test question-box">
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <div>
                            <p>
                                Result
                            </p>
                            <p>
                                <span style={{
                                    color: '#000',
                                    fontWeight: 'bold'
                                }}>Correct Answer - </span><span style={{
                                    color: '#F69220',
                                    fontWeight: 'bold'
                                }}>{testResult?.marks?.correct_answers}</span><br/><br/>
                                <span style={{
                                    color: '#000',
                                    fontWeight: 'bold'
                                }}>Wrong Answer - </span><span style={{
                                    color: '#F69220',
                                    fontWeight: 'bold'
                                }}>{testResult?.marks?.incorrect_answers}</span>
                            </p>
                        </div>
                    </div>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '24px',
                    }}>
                        <Button 
                            className={classes.changesButton} 
                            sx={{
                                width: '152px',
                                backgroundColor: '#07AB35'
                            }}
                            onClick={showQuestions}
                            >
                                Show Questions
                        </Button>
                    </div>
                </div>
            }
        </div>}
      </div>
      
    </>
  );
}

const CustomPagination = (props) => {
    return (
        <>
            <ul style={{
                listStyleType: 'none',
                padding: '0 8px',
                display: 'inline-block'
            }}>
                <li className='page-no'>
                    <button 
                        disabled={props.active === 1} 
                        onClick={() => props.prev(props.active)}>
                        <ArrowBackIosIcon fontSize='small' />
                    </button>
                </li>
                {props?.testResponse?.map((item, index) => (
                    <li key={index} className='page-no'>
                        <button
                            style={{
                                background: (item?.response && item?.response?.length > 0) ?
                                (item?.isCorrect ? '#07AB35' : '#EF4840') : '#fff',
                                color: (item?.response && item?.response?.length > 0) ?
                                '#fff' : '#000',
                            }}
                            onClick={() => props.onChangePage(index+1)}
                        >{index+1}</button>
                    </li>
                ))}
                
                <li className='page-no'>
                    <button 
                        disabled={props.active === props?.testResponse?.length}
                        onClick={() => props.next(props.active)}>
                        <ArrowForwardIosIcon fontSize='small' />
                    </button>
                </li>
            </ul>
        </>
    )
}

function DiskProgress(props) {
    const normalise = (value) => ((value - props.min) * 100) / (parseFloat(props.max) - props.min);
    return (
        <div className='circular-disk-progress'>
            <CircularProgress 
                variant="determinate" 
                thickness={22}
                value={normalise(parseFloat(props.value))} 
                size="small" />
        </div>
    );
  }