import * as React from 'react';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import TextField from '@mui/material/TextField';
import HomeLayout from "../homeLayout/HomeLayout";
import ThreeDotMenu from '../../assest/svgs/three-dot-menu.svg';
import TwoDotMenu from '../../assest/svgs/tow-dot-menu.svg';
import CloseIcon from '@mui/icons-material/Close';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import UploadBox from "../../components/UploadBox/uploadBox";
import { addQuestion, updateQuestion } from "../../redux/test/testSlice";
import LoaderIcon from "../../components/Loader/Loader";
import { toast } from "react-toastify";
import { makeStyles } from "@mui/styles";
import Checkbox from '@mui/material/Checkbox';

const useStyle = makeStyles({
  addMore:{
    height: '36px',
    textTransform: 'capitalize !important',
    fontWeight: 'bold !important',
    color: '#fff !important',
    '&:hover':{
      backgroundColor: 'var(--primary-color) !important',
    }
  },
  addQuestion:{
    color: '#fff !important',
    borderRadius: '8px !important',
    fontWeight: 'bold !important',
    textTransform: 'capitalize !important',
    '&:hover':{
      backgroundColor: '#3C1CDE !important',
    }
  }
})

let question = {
    title: "",
    sub_type: "single_select",
    options: [
        "",
        "",
        ""
    ],
    correct_answers: []
}

export default function Question() {
  const classes = useStyle();
  const dispatch = useDispatch();
  const location = useLocation();
  const isWebsiteFor = localStorage.getItem('is-website-for');
  const [questionObject, setQuestionObject] = React.useState(question);
  const [btnLoader, setBtnLoader] = React.useState(false);

  const { 
    questionById 
    } = useSelector((state) => state.test);

  const handleChange = (e, index) => {
    e.target.name === "title" && setQuestionObject({...questionObject, title: e.target.value});
  }

  const addMoreOptions = () => {
    let array = [...questionObject.options];
    array.push("")
    setQuestionObject({...questionObject, options: [...array]})
  }

  const removeOption = (index) => {
    let array = [...questionObject.options];
    array.splice(index, 1);
    setQuestionObject({...questionObject, options: [...array]});
  }

  const correctCheck = (e, value, subType) => {
    if(subType === 'multi_select'){
        let array = [...questionObject.correct_answers];
        if(e.target.checked){
            let index = array.indexOf(`Option ${value}`);
            if(index){
                array.push(value);
            }
        }else{
            let index = array.indexOf(value);
            array.splice(index, 1);
        }
        setQuestionObject({...questionObject, correct_answers: [...array]})
    }else{
        let array = [];
        array[0] = value;
        setQuestionObject({...questionObject, correct_answers: [...array]})
    }
  }

  const radioChange = (value) => {
    setQuestionObject({...questionObject, sub_type: value})
  }

  const handleOptionChange = (e, index) => {
    let array = [...questionObject.options]
    array[index] = e.target.value;
    setQuestionObject({...questionObject, options: [...array]});
  }

  const submitQuestion = () =>{
    if(validate().isValid){
        setBtnLoader(true);
        const res = location.pathname === "/question/edit" ?
            dispatch(updateQuestion({body: questionObject, id: questionById?._id, isWebsiteFor})):
            dispatch(addQuestion({body: questionObject, isWebsiteFor}))

        res?.then((r) => {
            setBtnLoader(false);
            if(r?.payload?.statusCode === 200){
                if(location.pathname === "/question/edit"){
                    toast.success("Question Updated Successfully");
                    return;
                }
                toast.success("Question Added Successfully");
                setQuestionObject({
                    title: "",
                    sub_type: "single_select",
                    options: [
                        "",
                        "",
                        ""
                    ],
                    correct_answers: []
                });
            }else{
                toast.error(r?.payload?.message);
            }
        })
    }else{
        toast.error(validate().message);
    }
  }

  const validate = () => {
    let status = {isValid: true, message: ""};
    if(!questionObject?.title || questionObject?.title === ""){
        status.isValid = false;
        status.message = "Please enter question title";
        return status;
    }
    if(questionObject?.options.length < 2){
        status.isValid = false;
        status.message = "Please enter atleast 2 options";
        return status;
    }
    if(questionObject?.correct_answers.length === 0){
        status.isValid = false;
        status.message = "Please select correct option";
        return status;
    }
    if(questionObject?.sub_type === "single_select" && 
        questionObject?.correct_answers?.length > 1){
            status.isValid = false;
            status.message = "For single select type, only one correct answer is allowed";
            return status;
    }
    if(questionObject?.options.length >= 2){
        questionObject?.options.map((item) => {
            if(item === ""){
                status.isValid = false;
                status.message = "Blank option not allowed";
            }
        })
        return status;
    }
    return status;
  }

  const handleUpload = (name, value) => {
    setQuestionObject({...questionObject, image: value})
  }
  const isCheckedOption = (option) => {
    let isCorrect = false;
    questionObject.correct_answers.map((item) => {
        if(item === option){
            isCorrect = true;
        }
    })
    return isCorrect;
  }

  React.useEffect(() => {
    if(questionById){
        if(questionById?.image){
            setQuestionObject({
                title: questionById?.title,
                sub_type: questionById?.sub_type,
                options: questionById?.options,
                correct_answers: questionById?.correct_answers,
                image: questionById?.image
            })
        }else{
            setQuestionObject({
                title: questionById?.title,
                sub_type: questionById?.sub_type,
                options: questionById?.options,
                correct_answers: questionById?.correct_answers
            })
        }
    }
  },[])

  return (
    <>
        <HomeLayout />
        <div className="content-box table-box question-box-in">
            <div style={{
                width: '100%',
            }}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between'
                    }}>
                    <div style={{
                        display: 'flex',
                    }}>
                        <img src={ThreeDotMenu} alt="threeDotIcon" />
                        <p style={{
                        fontSize: '20px',
                        fontWeight: 'bold',
                        color: '#000',
                        marginLeft: '12px'
                        }}>Question</p>
                    </div>
                    {/* <Button onClick={() => removeQuestion(index)}>
                        <CloseIcon sx={{color: 'rgb(0, 0, 0, 0.5)'}} />
                    </Button> */}
                </div>
                <div>
                <TextField 
                    placeholder="" 
                    name="title"
                    type="text" 
                    variant="outlined" 
                    className="inputStyleSh"
                    value={questionObject?.title}
                    sx={{
                        width: '100%',
                        height: '62px'
                    }} 
                    onChange={handleChange} 
                    required />
                    <div style={{
                    display: 'flex',
                    justifyContent: 'center'
                    }}>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={questionObject?.sub_type === 'single_select' ? 'Single Selection' : 
                        'Multiple Selection' }
                        onChange={() => radioChange(
                            questionObject?.sub_type === 'single_select' ? 
                            'multi_select' : 'single_select')}
                    >
                        <FormControlLabel 
                            value="Single Selection" 
                            sx={{
                                color: 'rgb(0, 0, 0, 0.6)'
                            }}
                            control={<Radio />} 
                            label="Single Selection" />
                        <FormControlLabel 
                            value="Multiple Selection" 
                            sx={{
                                color: 'rgb(0, 0, 0, 0.6)'
                            }}
                            control={<Radio />} 
                            label="Multiple Selection" />
                    </RadioGroup>
                    </div>
                    {questionObject?.options?.map((option, i) => (
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            }} key={i}>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                <img src={TwoDotMenu} alt="twoDotIcon" />
                                <p style={{
                                    fontSize: '20px',
                                    fontWeight: 'bold',
                                    color: '#000',
                                    marginLeft: '12px'
                                    }}>{String.fromCharCode(65 + i)}</p>
                            </div>
                            <div style={{
                                display: 'flex',
                                width: '100%',
                                marginLeft: '12px',
                                boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)'
                            }} className="checkbox-aria">
                                <TextField 
                                    placeholder="" 
                                    name={`option_${i}`}
                                    type="text" 
                                    variant="outlined" 
                                    className="inputStyleSh inputH"
                                    value={option}
                                    sx={{
                                        width: '100%',
                                        height: '62px'
                                    }} 
                                    onChange={(e) => handleOptionChange(e, i)} 
                                    required />
                                <div style={{
                                    height: '41px',
                                    width: '41px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    cursor: 'pointer',
                                    backgroundColor: option?.isCorrect ? '#00A4A2' : '#fff'
                                }} 
                                >
                                    <Checkbox sx={{
                                        width: '100%',
                                        height: '100%',
                                    }} 
                                    checked={isCheckedOption(option)}
                                    onChange={(e) => correctCheck(
                                        e, 
                                        option, 
                                        questionObject.sub_type)}
                                    />
                                </div>
                                <Button sx={{
                                    height: '41px',
                                    width: '41px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }} 
                                onClick={() => removeOption(i)}
                                >
                                    <CloseIcon sx={{color: 'rgb(0, 0, 0, 0.5)'}} />
                                </Button>
                            </div>
                        </div>
                    ))}
                    <div style={{
                    display: 'flex',
                    justifyContent: 'flex-end'
                    }}>
                    <Button sx={{
                        textTransform: 'capitalize',
                        color: 'rgb(0, 0, 0, 0.3)',
                        fontSize: '18px'
                    }} 
                    onClick={addMoreOptions}
                    >
                        + Add more options
                    </Button>
                    </div>
                </div>
            </div>
            <div className="question-image-box">
                <p style={{
                    fontSize: '18px',
                    fontWeight: 'bold',
                    color: '#000',
                    marginLeft: '12px'
                }}>Upload Image</p>
                <UploadBox
                    name={`question_image`}
                    height={'190px'}
                    width={'216px'}
                    value={questionObject?.image ? questionObject?.image : '' }
                    handleUpload={(name, value) => handleUpload(name, value)} 
                    />
            </div>
        </div>
        {/* ))} */}
        {/* <div className="add-question-box">
            <Button className="add-question" sx={{
            textTransform: 'capitalize',
            color: '#3C1CDE'
            }}
            onClick={addNewQuestion}>
            Add more question +
            </Button>
        </div> */}
        {location.pathname !== "/question/view" &&
        <div className="add-question-box" style={{
            marginTop: '24px'
        }}>
            <Button className={`${classes.addQuestion} add-question`} 
                disabled={btnLoader}
                sx={{
                    background: btnLoader ? 'rgb(0, 0, 0, 0.2)' : '#3C1CDE'
                }}
                onClick={submitQuestion}>
                {btnLoader ? <LoaderIcon /> : 
                (location.pathname === "/question/edit" ? 'Update' : 'Submit')}
            </Button>
        </div>
        }
    </>
  );
}