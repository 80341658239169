import * as React from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  RadioGroup,
  Radio,
  Button
} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LoginPageImage from "../../assest/images/loginPageImage.jpeg";
import { reset, login, logout } from "../../redux/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import validator from "validator";
import { IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { makeStyles } from "@mui/styles";
import { 
  getUniversityByToken, 
  getCoachingByToken,
  getPartnerByToken,
  getStudentByToken } from "../../redux/requestForm/requestFormSlice";
import routes from "../../react_routes_enum";

const useStyles = makeStyles( theme => ({
  inputBorder: {
    borderColor: '#000'
  }
}))
export default function SignInSide() {
  const [formData, setFormData] = React.useState({
    email: "",
    password: "",
  });
  const [btnLoader, setBtnLoader] = React.useState(false);
  const [emailError, setEmailError] = React.useState(false);
  const [passwordError, setPasswordError] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState("");
  const [isLoginFor, setIsLoginFor] = React.useState(localStorage.getItem('is-website-for') ? localStorage.getItem('is-website-for') : "coaching");
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, isLoading, isError, message, isSuccess } = useSelector(
    (state) => state.auth
  );

  React.useEffect(() => {
    if (isError) {
      toast.error(message);
      dispatch(reset());
      return;
    }
    // if (user?.statusCode === 400) {
    //   console.log("correctr 2");
    //   toast.error(message);
    //   dispatch(reset());
    //   return;
    // }
    if (user?.statusCode === 200) {
      toast.success(message);
      let res = null;
      isLoginFor === "coaching" && (res = dispatch(getCoachingByToken()))
      isLoginFor === "university" && (res = dispatch(getUniversityByToken()))
      isLoginFor === "partner" && (res = dispatch(getPartnerByToken()))

      res?.then((r) => {
        if(r?.payload?.statusCode === 200){
          if(r?.payload?.data?.welcome_screen_check){
            // navigate(routes.DASHBOARD);
            navigate(routes.PRODUCTS);
            return;
          }
          if(isLoginFor === "partner"){
            if(r?.payload?.data?.request_status === "accepted"){
              navigate(routes.DASHBOARD);
              return;
            }
            if(r?.payload?.data?.request_status === "pending" ||
              r?.payload?.data?.request_status === "review" ||
              r?.payload?.data?.request_status === "rejected"){
              navigate(routes.PARTNER_REQUEST_RESPONSE);
              return;
            }
            if(r?.payload?.data?.request_status === "registration"){
              navigate(routes.PARTNER_DOCUMENT);
              return;
            }
            if(r?.payload?.data?.request_status === "documents" ||
              r?.payload?.data?.request_status === "documents_review"){
              navigate(routes.PARTNER_DOCUMENT);
              return;
            }
          }
          else{
            if(!r?.payload?.data?.information_check && !r?.payload?.data?.uploads_check && !r?.payload?.data?.courses_offered_check &&
              !r?.payload?.data?.placement_records_check && isLoginFor === "university"){
                navigate(routes.UNIVERSITY_REQUEST_RESPONSE);
                return;
            }
            if(!r?.payload?.data?.information_check && !r?.payload?.data?.uploads_check && !r?.payload?.data?.faculties_details_check && 
              isLoginFor === "coaching"){
                navigate(routes.COACHING_REQUEST_RESPONSE);
                return;
            }
            if(!r?.payload?.data?.information_check){
              isLoginFor === "coaching" ? navigate(routes.COACHING_INFORMATION) : navigate(routes.UNIVERSITY_INFORMATION);
              return;
            }
            if(!r?.payload?.data?.uploads_check){
              isLoginFor === "coaching" ? navigate(routes.COACHING_UPLOAD) : navigate(routes.UNIVERSITY_UPLOAD);
              return;
            }
            if(!r?.payload?.data?.faculties_details_check && isLoginFor === "coaching"){
              navigate(routes.COACHING_COURSE);
              return;
            }
            if(!r?.payload?.data?.courses_offered_check && isLoginFor === "university"){
              navigate(routes.UNIVERSITY_COURSE);
              return;
            }
            if(!r?.payload?.data?.placement_records_check && isLoginFor === "university"){
              navigate(routes.UNIVERSITY_PLACEMENT);
              return;
            }else{
              isLoginFor === "coaching" ? navigate(routes.COACHING_REGISTRATION_RESPONSE) : 
                navigate(routes.UNIVERSITY_REGISTRATION_RESPONSE);
                return;
            }
          }
        }
      })
    }
  }, [user, isError, message, isSuccess, dispatch]);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const validate = (e) => {
    const { name, value } = e.target;
    if (name === "email" && !validator.isEmail(value)) {
      setEmailError(true);
    }
    if (name === "email" && validator.isEmail(value)) {
      setEmailError(false);
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
    if (name === "password" && value.length === 0) {
      setPasswordError(true);
    }
    if (name === "password" && value.length > 0) {
      setPasswordError(false);
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };
  React.useEffect(() => {
    let isWebFor = localStorage.getItem("is-website-for");
    dispatch(logout());
    localStorage.setItem("is-website-for", isWebFor ? isWebFor : "coaching");
  },[])
  const handleSubmit = (event) => {
    event.preventDefault();
    let body = {
      email: formData.email,
      password: formData.password,
      isLoginFor: isLoginFor
    }
    const res = dispatch(login(body));
    res?.then((r) => {
      if(r?.payload?.statusCode === 400){
        toast.error(r?.payload?.message);
        dispatch(reset());
        return;
      }
    })
    if(isLoginFor === "student") {
      res?.then((r) => {
        if(r?.payload?.statusCode === 200){ 
          dispatch(getStudentByToken());
          navigate(routes.LANDING_PAGE);
        }
      })
    }
  };
  const handleRadioChange = (e) => {
    localStorage.setItem("is-website-for", e.target.value)
    setIsLoginFor(e.target.value)
  }
  const goToSignUp = () => {
    let isWebFor = localStorage.getItem("is-website-for");
    (isWebFor === "university" || isWebFor === "coaching") && navigate(routes.REQUEST_FORM);
    isWebFor === "partner" && navigate(routes.PARTNER_REQUEST_FORM);
    isWebFor === "student" && navigate("/signup")
  }

  const forgotPasswordPressed = () => {
    navigate(routes.FORGOT_PASSWORD);
    // let path = "";
    // let body = {};
    // if(formData?.email === ""){
    //   toast.error("Email Required");
    //   return;
    // }
    // if(!validator.isEmail(formData?.email)){
    //   toast.error("Invalid Email Entered");
    //   return;
    // }
    // isLoginFor === "student" && (path = endpoint.STUDENT_FORGOT_PASSWORD);
    // isLoginFor === "partner" && (path = endpoint.PARTNER_FORGOT_PASSWORD);
    // isLoginFor === "coaching" && (path = endpoint.COACHING_FORGOT_PASSWORD);
    
    // body = {email: formData?.email};
    // const res = dispatch(forgotPassword({path, body}));
    // res?.then((r) => {
    //   if(r?.payload?.message === "Otp Sent"){
    //     navigate(routes.FORGOT_PASSWORD, { isLoginFor, email: formData?.email});
    //     toast.success(r?.payload?.message);
    //     return;
    //   }
    //   toast.error(r?.payload?.message);
    // })
  }
  // if (isLoading) return <Spinner />;
  return (
    <>
      <Grid
        container
        component="main"
        sx={{ height: "100vh" }}
        className="login-form"
      >
        <Grid
          item
          xs={false}
          sm={4}
          md={6}
          sx={{ backgroundColor: 'var(--primary-color)' }}>
            <img src={LoginPageImage} alt="LoginImage" className="login-image" />
          </Grid>
        <Grid
          item
          xs={12}
          sm={8}
          md={6}
          sx={{ alignSelf: "center !important" }}
        >
          <Box
            className="login-box"
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography 
              component="h1" 
              variant="h5" mt={5} mb={5}
              sx={{
                width: "100%",
                color: 'var(--secondary-color)',
                marginBottom: '12px',
                textAlign: 'center',
                fontSize: '24px',
                fontWeight: '600'
              }}>
              Login as:
            </Typography>
            <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="registration-type"
                defaultValue={isLoginFor}
                onChange={handleRadioChange}
                sx={{
                  width: '100%'
                }}
            >
              <FormControlLabel value={"coaching"} 
                control={<Radio sx={{
                    color: 'var(--secondary-color)',
                    '&.Mui-checked': {
                        color: 'var(--secondary-color)',
                      },
                }} />} label="Coaching" />
              <FormControlLabel value={"student"} 
                control={<Radio sx={{
                    color: 'var(--secondary-color)',
                    '&.Mui-checked': {
                        color: 'var(--secondary-color)',
                      },
                }} />} label="Student" />
              <FormControlLabel value={"partner"} 
                control={<Radio sx={{
                    color: 'var(--secondary-color)',
                    '&.Mui-checked': {
                        color: 'var(--secondary-color)',
                      },
                }} />} label="Partner" />
            </RadioGroup>
            <Box component="form" onSubmit={handleSubmit}>
              <TextField
                id="email"
                name="email"
                type="email"
                label="Email Address"
                onChange={validate}
                autoComplete="email"
                autoFocus
                margin="normal"
                className={classes.inputBorder}
                required
                error={emailError}
                helperText={emailError ? "Invalid Email" : ""}
                fullWidth
              />
              <TextField
                id="password"
                name="password"
                type={showPassword ? "text" : "password"}
                label="Password"
                onChange={validate}
                autoComplete="current-password"
                margin="normal"
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={passwordError}
                helperText={passwordError ? "required field" : ""}
                fullWidth
              />
              <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                }}>
                <FormControlLabel 
                  value="remember" 
                  control={<Radio />} 
                  label="Remember me" />
                <div>
                  <Button style={{
                    textTransform: 'capitalize',
                  }} onClick={forgotPasswordPressed}>
                    Forgot Password
                  </Button>
                </div>
              </div>
              <LoadingButton
                type="submit"
                fullWidth
                variant="contained"
                className="btn-grad"
                disabled={emailError || passwordError}
                sx={{ 
                  mt: 3, 
                  mb: 2,
                  backgroundColor: 'var(--primary-color)' }}
                loading={isLoading && !btnLoader}
              >
                Log In
              </LoadingButton>
              <div style={{
                textAlign: 'center',
              }}>
                Not have account ? <Button sx={{
                  textTransform: 'capitalize',
                  color: 'var(--primary-color)',
                  fontWeight: 'bold',
                }} onClick={goToSignUp}>Sign Up</Button>
              </div>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
