import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import testServices from "./testServices";

const initialState = {
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
    searchText: "",
    totalCount: 0,
    testData: null,
    questionData: null,
    testById: null,
    questionById: null,
    studentTestyId: null,
    testResult: null
};

export const addQuestion = createAsyncThunk(
  "test/addQuestion",
  async (params, thunkAPI) => {
    try {
      return await testServices.addQuestion(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const addRecordedCourse = createAsyncThunk(
  "test/addRecordedCourse",
  async (params, thunkAPI) => {
    try {
      return await testServices.addRecordedCourse(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const addTest = createAsyncThunk(
    "test/addTest",
    async (params, thunkAPI) => {
      try {
        return await testServices.addTest(params);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
);

export const updateTest = createAsyncThunk(
  "test/updateTest",
  async (params, thunkAPI) => {
    try {
      return await testServices.updateTest(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getTests = createAsyncThunk(
  "test/getTests",
  async (params, thunkAPI) => {
    try {
      return await testServices.getTests(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getQuestion = createAsyncThunk(
    "test/getQuestion",
    async (params, thunkAPI) => {
      try {
        return await testServices.getQuestion(params);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
);

export const deleteQuestion = createAsyncThunk(
  "test/deleteQuestion",
  async (params, thunkAPI) => {
    try {
      return await testServices.deleteQuestion(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateQuestion = createAsyncThunk(
  "test/updateQuestion",
  async (params, thunkAPI) => {
    try {
      return await testServices.updateQuestion(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getTestResult = createAsyncThunk(
  "test/getTestResult",
  async (params, thunkAPI) => {
    try {
      return await testServices.getTestResult(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getTestById = createAsyncThunk(
  "test/getTestById",
  async (params, thunkAPI) => {
    try {
      return await testServices.getTestById(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteTest = createAsyncThunk(
  "test/deleteTest",
  async (params, thunkAPI) => {
    try {
      return await testServices.deleteTest(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getStudentTestById = createAsyncThunk(
  "test/getStudentTestById",
  async (params, thunkAPI) => {
    try{
      return await testServices.getStudentTestById(params);
    }
    catch(error){
      const message = (
        error.response && 
        error.response.data && 
        error.response.data.message) ||
        error.message ||
        error.toString();
        return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getQuestionById = createAsyncThunk(
    "test/getQuestionById",
    async (params, thunkAPI) => {
      try {
        return await testServices.getQuestionById(params);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

const testSlice = createSlice({
  name: "test",
  initialState,
  reducers: {
    setTestSearchText: (state, action) => {
        state.searchText = action.payload;
    },
    setTestById: (state, action) => {
      state.testById = action.payload;
    },
    setQuestionById: (state, action) => {
      state.questionById = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addQuestion.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addQuestion.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(addQuestion.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(addTest.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addTest.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(addTest.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(getTests.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTests.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getTests.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.testData = action.payload.data.result;
        state.totalCount = action.payload.data.totalCount;
      })
      .addCase(getQuestion.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getQuestion.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getQuestion.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.questionData = action.payload.data.result;
        state.totalCount = action.payload.data.totalCount;
      })
      .addCase(getTestById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTestById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getTestById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.testById = action.payload.data;
      })
      .addCase(getQuestionById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getQuestionById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getQuestionById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.questionById = action.payload.data;
      })
      .addCase(getStudentTestById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getStudentTestById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getStudentTestById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.studentTestyId = action.payload.data;

      })
      .addCase(getTestResult.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTestResult.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getTestResult.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.testResult = action.payload.data;
      })
      .addCase(addRecordedCourse.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addRecordedCourse.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(addRecordedCourse.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(deleteTest.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteTest.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deleteTest.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(updateTest.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateTest.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateTest.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(deleteQuestion.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteQuestion.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deleteQuestion.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(updateQuestion.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateQuestion.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateQuestion.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
  },
});

export const { 
    setTestById,
    setQuestionById,
    setTestSearchText } = testSlice.actions;

export default testSlice.reducer;
