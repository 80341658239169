import * as React from 'react';
import HomeLayout from "../homeLayout/HomeLayout";
import TestTable from "../../components/BasicTable/BasicTable";
import Pagination from "../../components/Pagination/Pagination";
import { useDispatch, useSelector } from "react-redux";
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { useNavigate } from "react-router-dom";
import VisibilityIcon from '@mui/icons-material/Visibility';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from "@mui/material/Dialog";
import { setRightHeaderText } from "../../redux/requestForm/requestFormSlice";
import { getProduct, 
    getProductById, 
    deleteProduct, 
    setProductDetailsById } from '../../redux/product/productSlice';
import {
    getTests,
    getTestById,
    deleteTest,
    setTestById
} from '../../redux/test/testSlice';
import Spinner from "../../components/Spinner/Spinner";
import routes from "../../react_routes_enum";
import { toast } from 'react-toastify';
import {
    Button,
    IconButton,
    Typography,
  } from "@mui/material";

export default function Tests() {
    const [isWebsiteFor, setIsWebsiteFor] = React.useState(localStorage.getItem("is-website-for"));
    const [limit, setLimit] = React.useState(10);
    const [offset, setOffset] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [isAddProduct, setIsAddProduct] = React.useState(false);
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);
    const [deleteId, setDeleteId] = React.useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const colR = ["SNo", "Title", "Duration(min)", "view","edit","delete"];

    const { 
        isLoading, 
        isError, 
        totalCount, 
        message, 
        searchText,
        testData,  
    } = useSelector((state) => state.test);

    React.useEffect(() => {
        dispatch(getTests({limit, offset, searchText, isWebsiteFor}));
    },[limit, offset, searchText])

    const viewTest = (id) => {
        const res = dispatch(getTestById({id, isWebsiteFor}));
        res?.then((r) => {
            r?.payload?.statusCode === 200 && navigate(routes.VIEW_TEST)
        })
    }
    const editTest = (id) => {
        const res = dispatch(getTestById({id, isWebsiteFor}));
        res?.then((r) => {
            r?.payload?.statusCode === 200 ? 
            navigate(routes.EDIT_TEST) :
            toast.error(r?.payload);
        })
    }
    const deleteTestById = () => {
        const res = dispatch(deleteTest({id: deleteId, isWebsiteFor}));
        res.then((r) => {
            if(r.payload.statusCode === 200){
                toast.success(r.payload.message);
                setIsDialogOpen(false);
                dispatch(getTests({limit, offset, isWebsiteFor}));
            }else{
                toast.error(r.payload.message);
            }
        })
    }
    const resetStateForPaginationOfDifferentView = () => {
        setLimit(10);
        setOffset(0);
        setPage(0);
    };

    const handleSearchText = (e) => {
        resetStateForPaginationOfDifferentView();
        if (limit === 10 && offset === 0) {
            dispatch(getProduct({limit, offset, searchText}));
        }
    };
    const handleClose = () => {
        setIsDialogOpen(false);
    };

    const addNewTest = () => {
        dispatch(setTestById(null));
        navigate(routes.ADD_NEW_TEST);
    }

    if (isLoading && !isAddProduct) return <Spinner />;
    if (isError) return <h1>{message}</h1>;
    return (
      <>
        <Dialog maxWidth="xs" open={isDialogOpen} onClose={handleClose} fullWidth>
            <div>
            <Typography component="p" variant="p" 
                sx={{
                textAlign: 'center',
                fontWeight: 'bold',
                margin: '28px 0 48px'}}>
                Are you sure you want to<br/> delete the test ?
            </Typography>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'row-reverse',
                padding: '0px 16px 28px 16px'
            }}>
                <Button onClick={() => {setIsDialogOpen(false)}} variant="outlined" sx={{
                textTransform: 'capitalize'
                }}>
                    Cancel
                </Button>
                <Button onClick={() => {deleteTestById()}} variant="contained" sx={{
                    backgroundColor: 'var(--primary-color)',
                    width: '84px',
                    marginRight: '12px',
                    textTransform: 'capitalize'
                    }}>
                    Yes
                </Button>
            </div>
            </div>
        </Dialog>
        <HomeLayout />
        <div className="pagination-addPost">
            <div className="add-product-button-2 newClass">
                <Button variant="contained" onClick={addNewTest}
                sx={{
                    backgroundColor: isWebsiteFor === "university" ? '#F69220' : 'var(--tertiary-color)',
                    textTransform: 'capitalize',
                    borderRadius: '8px'
                    }}>
                <ControlPointIcon sx={{marginRight: '4px'}} />
                    Add New Test
                </Button>
            </div>
            <Pagination
                totalCount={totalCount}
                limit={limit}
                page={page}
                changeLimit={setLimit}
                changeOffset={setOffset}
                changePage={setPage}
            />
        </div>
        <div className="content-box table-box" style={{
            marginTop: '0px',
            overflowX: isAddProduct ? 'hidden' : 'scroll',
            overflowY: 'hidden'
        }}>
            <TestTable
                columns={colR}
                where="recipes"
                rows={testData && testData?.map((item) => {
                    const res = {
                    ...item,
                    view: (
                        <IconButton
                            color="inherit"
                            edge="start"
                            onClick={() => {viewTest(item._id)}}>
                            <div>
                                <VisibilityIcon sx={{color: '#3C1CDE'}} />
                                <Typography component="p" variant="p" 
                                sx={{
                                    color: '#3C1CDE',
                                    fontSize: '18px',
                                    fontWeight: 'bold'
                                    }}>
                                    View
                                </Typography>
                            </div>
                        </IconButton>
                    ),
                    edit: (
                        <IconButton
                            color="inherit"
                            edge="start"
                            onClick={() => {editTest(item._id)}}>
                                <div>
                                    <BorderColorIcon sx={{color: 'var(--secondary-color)'}} />
                                    <Typography component="p" variant="p" 
                                    sx={{
                                        color: 'var(--secondary-color)',
                                        fontSize: '18px',
                                        fontWeight: 'bold',
                                        marginTop: '4px'
                                        }}>
                                    Edit
                                    </Typography>
                                </div>
                        </IconButton>
                    ),
                    delete: (
                        <IconButton
                            color="inherit"
                            edge="start"
                            onClick={() => {
                                setDeleteId(item?._id)
                                setIsDialogOpen(true)
                            }}>
                            <div>
                            <DeleteIcon sx={{color: '#EF4840'}} />
                            <Typography component="p" variant="p" 
                            sx={{
                                color: '#EF4840',
                                fontSize: '18px',
                                fontWeight: 'bold'
                                }}>
                                Delete
                            </Typography>
                            </div>
                        </IconButton>
                    )
                    };
                    return res;
                })
                }
            />
        </div>
      </>
    );
  }