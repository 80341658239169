import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Typography,
  InputLabel,
  TextField,
  Select,
  MenuItem
} from "@mui/material";
import UploadBox from '../../../components/UploadBox/uploadBox';
import routes from "../../../react_routes_enum";
import { makeStyles } from "@mui/styles";
import AddIcon from '@mui/icons-material/Add';
import HeaderBackground from "../../../assest/images/header-background.jpg";
import HeaderIcon from "../../../assest/images/header-icon.png";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import { useNavigate } from "react-router-dom";
import { updateData, getUniversityByToken } from "../../../redux/requestForm/requestFormSlice";
import { toast } from "react-toastify";
import ClearIcon from '@mui/icons-material/Clear';
import LoaderIcon from "../../../components/Loader/Loader";
import { logout, reset } from "../../../redux/auth/authSlice";
import { getConfigDetails } from "../../../redux/configurationDetails/configDetailsSlice";

const useStyle = makeStyles({
    dot: {
        height: '16px',
        width: '16px',
        borderRadius: '50%',
    },
    line: {
        height: '4px',
        backgroundColor: 'rgb(246, 146, 32, 0.4)'
    },
    changesButton: {
        color: '#fff !important',
        textTransform: 'capitalize !important',
        width: '140px',
        height: '40px',
        fontSize: '16px !important',
        borderRadius: '24px !important',
        boxShadow: '0 2px 5px rgb(0, 0, 0, 0.5)',
        "&:hover":{
            backgroundColor: 'var(--secondary-color) !important',
        }
    },
    cancelButton: {
        backgroundColor: '#fff !important',
        color: 'var(--secondary-color) !important',
        textTransform: 'capitalize !important',
        width: '140px',
        height: '40px',
        fontSize: '16px !important',
        borderRadius: '24px !important',
        borderColor: 'var(--secondary-color) !important',
        "&:hover":{
            backgroundColor: '#fff',
        }
    },
    header: {
        marginTop: '100px',
        marginBottom: '4%',
        backgroundImage: `url(${HeaderBackground})`,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    formIconsBox: {
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        marginTop: '5%',
        marginRight: '14%',
    },
    iconBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    horizontalLine: {
        height: '4px',
        backgroundColor: 'var(--primary-color)',
    },
    iconBox2: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'absolute', 
    },
    iconStyle: {
        backgroundColor: 'var(--primary-color)',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    iconText: {
        color: 'var(--primary-color)',
        marginTop: '8px !important',
    },
    icon: {
        color: '#fff',
    },
    leftBox: {
        height: '200px',
        width: '200px',
        borderRadius: '8px',
        border: '2px dashed rgb(0, 0, 0, 0.5)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: '0px 2px 10px rgba(103, 47, 143, 0.5)'
    },
    upload: {
        border: '1px solid rgb(0, 0, 0, 0.2) !important',
        fontSize: '15px !important',
        color: 'rgb(0, 0, 0, 0.4) !important',
        textTransform: 'capitalize !important',
        width: '122px',
        height: '34px'
    },
    checkBox: {
        color: 'var(--primary-color)',
        '&.Mui-checked': {
            color: 'var(--primary-color)',
        }
    }
})

let placement = {
    placement_records_check: 1,
    placement_records: {
        companies_logo: [],
        brochure: "",
        record: [
            {
                year: "",
                description: "",
                logo: []
            }
        ]
    }
}
export default function RequestRes() {
    const [btnLoader, setBtnLoader] = React.useState(false);
    const [yearList, setYearList] = React.useState([]);
    const { universityByToken } = useSelector((state) => state.form);
    const [placementRecords, setPlacementRecords] = React.useState((universityByToken?.data?.placement_records_check)
        ? 
        {
            placement_records_check: 1,
            placement_records: universityByToken?.data?.placement_records
        }
        : placement)
    const navigate = useNavigate();
    const classes = useStyle();
    const dispatch = useDispatch();

    const saveAndNext = () => {
        let body = {};
        let error = {status: false, message: ''};
        placementRecords.placement_records.record.map((item) => {
            if(item.year === ""){
                error.message = "Year Range Required";
                error.status = true;
                // eslint-disable-next-line array-callback-return
                return;
            }
            if(item.description === "" || item.description.length < 6){
                error.status = true;
                item.description === "" ? error.message = "Description Required" :
                error.message = "Description must be more than 6 characters";
                // eslint-disable-next-line array-callback-return
                return;
            }
        })
        if(error.status){
            toast.error(error.message);
            return;
        }
        
        if(placementRecords?.placement_records?.companies_logo?.length === 0){
            body = {
                placement_records_check: 1,
                placement_records: {
                    brochure: placementRecords?.placement_records?.brochure,
                    record: placementRecords?.placement_records?.record
                }
            } 
        }
        if(placementRecords?.placement_records.brochure === ""){
            body = {
                placement_records_check: 1,
                placement_records: {
                    companies_logo: placementRecords?.placement_records?.companies_logo,
                    record: placementRecords?.placement_records?.record
                }
            }
        }
        if(placementRecords?.placement_records?.brochure === "" && placementRecords?.placement_records?.companies_logo?.length === 0){
            body = {
                placement_records_check: 1,
                placement_records: {
                    record: placementRecords?.placement_records?.record
                }
            }
        }
        if(placementRecords?.placement_records?.brochure !== "" && placementRecords?.placement_records?.companies_logo?.length > 0){
            body = placementRecords
        }
        setBtnLoader(true);
        let res = dispatch(updateData({isWebsiteFor: 'university', body}));
        res.then((r) => {
            setBtnLoader(false);
            if(r.payload.statusCode === 200){
                toast.success(r.payload.message);
                dispatch(getUniversityByToken());
                navigate(routes.UNIVERSITY_REGISTRATION_RESPONSE)
            }else{
                toast.error(r.payload.message);
            }
        })
    }
    React.useEffect(() => {
        const res = dispatch(getUniversityByToken());
        res.then((r) => {
            if(r?.payload?.statusCode === 200){
                if(r?.payload?.data?.placement_records_check){
                    setPlacementRecords({
                        placement_records_check: 1,
                        placement_records: r?.payload?.data?.placement_records
                    })
                }
            }
        })
    },[])

    React.useEffect(() => {
        const res = dispatch(getConfigDetails());
        res?.then((r) => {
            if(r?.payload?.data){
                r?.payload?.data?.map((item) => {
                    if(item?.configuration_type === "year_range"){
                        setYearList(item?.data)
                    }
                })
            }
        })
    },[])
    
    const placementRecord = (e) => {
        let localArray = [];
        let array = placementRecords.placement_records.record;
        localArray = array.map((element, i) => { 
            return {...element, 
            ...(e.target.name === `year_${i}` && {year: e.target.value}),
            ...(e.target.name === `description_${i}` && {description: e.target.value})
            }
        });
        setPlacementRecords({
            ...placementRecords,
            placement_records: {
                ...placementRecords.placement_records,
                record: localArray
            }
        })
    }

    const onUploadChange = async(name, value) => {
        if(name.split("_")[0] === "logo"){
            let localArray = [];
            let newArray = [];
            let array = [...placementRecords.placement_records.record[parseInt(name?.split("_")[2])]?.logo];
            localArray = [...placementRecords.placement_records.record];
            array.push(value);

            newArray = localArray.map((element, i) => {
                return {...element, 
                    ...(name === `logo_box_${i}` && {
                    logo: [...array]
                })}
            })
            setPlacementRecords({
                ...placementRecords,
                placement_records: {
                    ...placementRecords.placement_records,
                    record: [...newArray]
                }
            })
        }else{
            setPlacementRecords({
                ...placementRecords, 
                placement_records: {
                    ...placementRecords.placement_records, 
                    brochure: value}})
        }
    }

    const cancelClicked = () => {
        // navigate(routes.REQUEST_FORM);
        setBtnLoader(false);
        dispatch(logout());
        dispatch(reset());
        navigate("/login");
    }
    const addMoreRecord = () => {
        let array = [...placementRecords?.placement_records?.record];
        array.push(
            {
                year: "",
                description: "",
                logo: []
            }
        );
        setPlacementRecords({
            ...placementRecords,
            placement_records: {
                ...placementRecords.placement_records,
                record: [...array]
            }
        })
    }

    const removeLogo = (index, i) => {
        let localArray = [];
        let newArray = [];
        let array = [...placementRecords.placement_records.record[index]?.logo];
        localArray = [...placementRecords.placement_records.record];
        array.splice(i, 1);
        //localArray[index].logo = array;
        newArray = localArray.map((element, ind) => {
            return {...element, 
                ...(index === ind && {
                logo: [...array]
            })}
        })
        setPlacementRecords({
            ...placementRecords,
            placement_records: {
                ...placementRecords.placement_records,
                record: [...newArray]
            }
        })
    }
    return (
        <Box>
            <section className={classes.header + ' header-set'}>
                <div className="header-icon-box">
                    <img src={HeaderIcon} alt="header-icon" style={{width: '100%'}} />
                </div>
                <Typography component="h2" variant="h2" className="header-heading">
                    UNIVERSITY - LISTING
                </Typography>
                <Typography component="p" variant="p" className="small-header-heading">
                    Lorem ipsum dolor sit amet consectetur.
                </Typography>
            </section>
            <Box sx={{p: 3}}>
                <section>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginBottom: '8px'
                        }} className="progr-lin-box">
                            <div className={classes.dot} style={{
                                background: 'none',
                                }}>
                                <CheckCircleOutlineIcon sx={{width: '100%', color: '#07AB35'}} />
                            </div>
                            <div className={classes.line + ' progress-line'} style={{background: 'none'}}>

                            </div>
                            <div className={classes.dot} style={{
                                background: 'none',
                                visibility: (universityByToken?.data?.information_check && universityByToken?.data?.uploads_check &&
                                    universityByToken?.data?.courses_offered_check && universityByToken?.data?.placement_records_check
                                    ) ? 
                                    'visible' : 'hidden'
                                }}>
                                <CheckCircleOutlineIcon sx={{
                                    width: '100%', 
                                    color: '#07AB35',
                                    }} />
                            </div>
                            <div className={classes.line + ' progress-line'} style={{background: 'none'}}>

                            </div>
                            <div className={classes.dot} style={{
                                background: 'none',
                                visibility: (universityByToken?.data?.information_check && universityByToken?.data?.uploads_check &&
                                    universityByToken?.data?.courses_offered_check && universityByToken?.data?.placement_records_check
                                    && universityByToken?.data?.request_status === "accepted") ? 
                                    'visible' : 'hidden'
                                }}> 
                                <CheckCircleOutlineIcon sx={{width: '100%', color: '#07AB35'}} />
                            </div>
                        </div>
                    </div>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }} className="progr-lin-box">
                            <div className={classes.dot} style={{backgroundColor: 'var(--secondary-color)'}}>
                                
                            </div>
                            <div className={classes.line + ' progress-line'} style={{
                                backgroundColor: (universityByToken?.data?.information_check && 
                                    universityByToken?.data?.uploads_check &&
                                    universityByToken?.data?.courses_offered_check && 
                                    universityByToken?.data?.placement_records_check) ? 'var(--secondary-color)' :
                                    'rgb(246, 146, 32, 0.4)'
                            }}>

                            </div>
                            <div className={classes.dot} style={{backgroundColor: (universityByToken?.data?.information_check && 
                                    universityByToken?.data?.uploads_check &&
                                    universityByToken?.data?.courses_offered_check && 
                                    universityByToken?.data?.placement_records_check) ? 'var(--secondary-color)' :
                                    'rgb(246, 146, 32, 0.4)'}}>

                            </div>
                            <div className={classes.line + ' progress-line'} style={{
                                backgroundColor: (universityByToken?.data?.information_check && 
                                    universityByToken?.data?.uploads_check &&
                                    universityByToken?.data?.courses_offered_check && 
                                    universityByToken?.data?.placement_records_check &&
                                    universityByToken?.data?.request_status === "accepted") ? 'var(--secondary-color)' :
                                    'rgb(246, 146, 32, 0.4)'
                            }}>

                            </div>
                            <div className={classes.dot} style={{backgroundColor: (universityByToken?.data?.information_check && 
                                    universityByToken?.data?.uploads_check &&
                                    universityByToken?.data?.courses_offered_check && 
                                    universityByToken?.data?.placement_records_check &&
                                    universityByToken?.data?.request_status === "accepted") ? 'var(--secondary-color)' :
                                    'rgb(246, 146, 32, 0.4)'}}>
                            </div>
                        </div>
                    </div>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '12px'
                    }}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            justifyContent: 'space-between',
                        }} className="progr-text-box">
                            <Typography component="h2" variant="h2" className="progr-text">
                                Fill the Request Form
                            </Typography>
                            <Typography component="h2" variant="h2" className="progr-text">
                                Fill the Listing Registration Form
                            </Typography>
                            <Typography component="h2" variant="h2" className="progr-text">
                                LIVE Listing
                            </Typography>
                        </div>
                    </div>
                </section>
                <section>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    }} className="request-main-res-box">
                        <div style={{
                            width: '100%',
                        }}>
                            <div className={classes.formIconsBox + ' reg-form-box'}>
                                <div className={classes.iconBox+' icon-box-2'}>
                                    <div style={{position: 'relative'}}>
                                        <div className={classes.horizontalLine+' horizontal-line-2'}></div>
                                        <div className={classes.iconBox2+' icon-1'}>
                                            <div className={classes.iconStyle+' icon-style-2'} 
                                                style={{
                                                    color: '#fff', 
                                                    fontSize: '20px'
                                                    }}>
                                                i
                                            </div>
                                            <Typography component="h2" variant="h2" className={classes.iconText+' icon-text'}>
                                                Information
                                            </Typography>
                                        </div>
                                    </div>
                                    <div style={{position: 'relative'}}>
                                        <div className={classes.horizontalLine+' horizontal-line-2'} 
                                            style={{backgroundColor: 'var(--primary-color)'}}></div>
                                        <div className={classes.iconBox2+' icon-2'}>
                                            <div className={classes.iconStyle+' icon-style-2'} 
                                                style={{backgroundColor: 'var(--primary-color)'}}>
                                                <CameraAltIcon className={classes.icon} fontSize="small" />
                                            </div>
                                            <Typography component="h2" variant="h2" className={classes.iconText+' icon-text'} 
                                            style={{color: 'var(--primary-color)'}}>
                                                Uploads
                                            </Typography>
                                        </div>
                                    </div>
                                    <div style={{position: 'relative'}}>
                                        <div className={classes.horizontalLine+' horizontal-line-2'} 
                                            style={{backgroundColor: 'var(--primary-color)'}}></div>
                                        <div className={classes.iconBox2+' icon-3'}>
                                            <div className={classes.iconStyle+' icon-style-2'} 
                                                style={{backgroundColor: 'var(--primary-color)'}}>
                                                <MenuBookIcon className={classes.icon} fontSize="small" />
                                            </div>
                                            <Typography component="h2" variant="h2" className={classes.iconText+' icon-text'} 
                                            style={{color: 'var(--primary-color)'}}>
                                                Courses
                                            </Typography>
                                        </div>
                                    </div>
                                    <div style={{position: 'relative'}}>
                                        <div className={classes.horizontalLine+' horizontal-line-2'} 
                                            style={{backgroundColor: 'var(--primary-color)'}}></div>
                                        <div className={classes.iconBox2+' icon-4'}>
                                            <div className={classes.iconStyle+' icon-style-2'} 
                                                style={{backgroundColor: 'var(--primary-color)'}}>
                                                <BusinessCenterIcon className={classes.icon} fontSize="small" />
                                            </div>
                                            <Typography component="h2" variant="h2" className={classes.iconText+' icon-text'} 
                                            style={{color: 'var(--primary-color)', textAlign: 'center'}}>
                                                Placement Records
                                            </Typography>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <>
                                <div style={{
                                    marginTop: '130px'
                                }} className="upload-text-box">
                                    <Typography component="h2" variant="h2" className="upload-doc-text">
                                        Placements Record
                                    </Typography>
                                </div>
                                <div style={{
                                marginTop: '32px',
                                }} className="brochr-box">
                                    {placementRecords?.placement_records?.record.map((item, index) => (
                                        <div key={index} style={{
                                            marginBottom: '12px'
                                        }}>
                                            <div style={{
                                                marginBottom: '2%'
                                            }} className="year-shadow">
                                                <InputLabel className="input-label-text">Year</InputLabel>
                                                <Select 
                                                    name={`year_${index}`}
                                                    value={item?.year}
                                                    onChange={placementRecord}
                                                    className="year-select-width"
                                                    sx={{
                                                        height: '62px'
                                                    }}
                                                >
                                                    {yearList.map((menu, i) => (
                                                        <MenuItem value={menu} key={i}>
                                                            {menu}
                                                        </MenuItem>
                                                    ))}
                                                    
                                                </Select>
                                            </div>
                                            <div style={{
                                                width: '100%',
                                            }}>
                                                <Typography component="h2" variant="h2" className="input-label-text" sx={{
                                                    marginBottom: '4px'
                                                }}>
                                                    Description</Typography>
                                                <div style={{marginBottom: '2%'}}>
                                                    <TextField name={`description_${index}`} placeholder="Write description here..." 
                                                        className="inputShadow"
                                                        onChange={placementRecord} 
                                                        value={item?.description}
                                                        variant="outlined" sx={{
                                                        width: '100%',
                                                    }} multiline rows="8"/>
                                                </div>
                                            </div>
                                            <Typography component="h2" variant="h2" className="input-label-text">
                                                Company Logo <span className="optional-text-style">(Optional)</span>
                                            </Typography>
                                            <div className="placement-rec-images">
                                                <UploadBox
                                                    name={`logo_box_${index}`}
                                                    height={'190px'}
                                                    width={'216px'}
                                                    value={item?.logo?.length > 0 && 
                                                        item?.logo[item?.logo?.lenth -1]}
                                                    handleUpload={(name, value) => onUploadChange(name, value)} />
                                                {item?.logo?.length > 0 &&
                                                    <div style={{
                                                        paddingTop: '8px'
                                                    }} className="placement-logo-box">
                                                        {item?.logo?.map((logos, i) => (
                                                            <div key={`${index}${i}`} style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                            }}>
                                                                <p style={{
                                                                    width: 'fit-content',
                                                                    margin: 0
                                                                }}>
                                                                    <span style={{fontWeight: 'bold'}}>
                                                                        {i+1}.{" "}
                                                                    </span>
                                                                    {logos?.split("-")[3]}
                                                                </p>
                                                                <Button sx={{
                                                                    backgroundColor: 'red',
                                                                    height: '20px',
                                                                    width: '20px',
                                                                    borderRadius: '50%',
                                                                    minWidth: '20px',
                                                                    marginLeft: '4px',
                                                                    '&:hover': {
                                                                        backgroundColor: 'red',
                                                                    }
                                                                }} onClick={() => removeLogo(index, i)}>
                                                                    <ClearIcon fontSize="small" sx={{
                                                                        color: '#fff',
                                                                        fontSize: '16px'
                                                                    }} />
                                                                </Button>
                                                            </div>
                                                        ))}
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    ))}
                                    <Button onClick={addMoreRecord} className="add-more-placement-record" sx={{
                                        background: 'var(--primary-color)',
                                        textTransform: 'capitalize',
                                        color: 'white'
                                    }}>
                                        <AddIcon sx={{marginRight: '4px'}} /> Add More
                                    </Button>
                                    <div>
                                        <Typography component="h2" variant="h2" className="input-label-text">
                                            Brochure of the year <span className="optional-text-style">(Optional)</span>
                                        </Typography>
                                        <UploadBox
                                            name={`brochour-upload`}
                                            height={'190px'}
                                            width={'216px'}
                                            value={placementRecords.placement_records.brochure && 
                                                placementRecords.placement_records.brochure}
                                            handleUpload={(name, value) => onUploadChange(name, value)} />
                                    </div>
                                </div>
                            </>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                marginTop: '48px',
                                marginBottom: '52px'
                            }}>
                                <Button className={classes.changesButton} onClick={saveAndNext} disabled={btnLoader} sx={{
                                    backgroundColor: btnLoader ? 'var(--disabled-color)' :'var(--secondary-color)'
                                }}>
                                    {btnLoader ? <LoaderIcon/> : "Save & Next"}
                                </Button>
                                <Button 
                                    variant="outlined" 
                                    className={classes.cancelButton} 
                                    onClick={cancelClicked}
                                    sx={{
                                        backgroundColor: '#fff',
                                        marginLeft: '16px'
                                    }}>
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </div>
                </section>
            </Box>
        </Box>
    );
}

