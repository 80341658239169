import * as React from "react";
import { Box } from "@mui/material";
import "./privacy.css";
import { makeStyles } from "@mui/styles";

const useStyle = makeStyles({
    heading:{
        color: '#000',
        fontSize: '20px',
        textDecoration: 'underline',
        textUnderlineOffset: '4px'
    },
    heading2:{
        fontSize: '18px',
        fontStyle: 'italic',
        fontWeight: '400',
        textDecoration: 'underline',
    }
})

export default function Cancellation() {
    const classes = useStyle();
    
    return (
        <>
            <Box sx={{p: 3}}>
                <div style={{
                    marginTop: '140px'
                }}>
                    <h1 className={classes.heading} style={{
                        textAlign: 'center'
                    }}>
                        Cancellation, Pricing and Refund Policy
                    </h1>
                    <p>
                        The pricing of all the courses is decided by the owner company of 
                        the website and can change at any time subject to offers, peak 
                        enrolment hours, occasions etc. The paid courses come with a 
                        certificate that will be issued once the course is completed. The 
                        company is not responsible for providing discounts or coupon 
                        codes if the limited offers are claimed already.<br /><br />
                        When you buy our listed courses, books, coaching or learning 
                        material, your purchase is not entitled to any return or refund. 
                        If you buy any online batch/service, it is non-refundable. If you 
                        purchase any batch by mistake, you can request to change it to 
                        another batch of the same amount within 3 days of the purchase. 
                        We recommend you check the complete system first and then decide 
                        to make payment in case of any course.<br /><br />
                        If incases a candidate wants to withdraw the admission seat at 
                        any point in time, the refunds are subject to the rules of the 
                        university or coaching center and the registration amount is not 
                        refundable.<br /><br />
                        CONTACT US:<br /><br />
                        If you have any questions about this Refund Policy, You can 
                        contact us:<br /><br />
                        By filling contact us form on our Website<br /><br />
                        <a href="https://www.collegeclue.com/" style={{
                            color: 'rgb(0, 0, 0)'
                        }}>
                            www.collegeclue.com
                        </a><br /><br />
                        By emailing us at: cc@collegeclue.com
                    </p>
                </div>
            </Box>
        </>
    );
}
