import axios from "axios";
import endpoint from "../../index.routes";

const addProduct = async (params) => {
  let path = "";
  params.isWebsiteFor === "coaching" ? 
    (path = endpoint.COACHING_PRODUCT) : (path = endpoint.UNIVERSITY_PRODUCT)
  const response = await axios.post(path, params.body, {
    headers: { token: localStorage.getItem("cc_token") },
  });

  return response.data;
};
const getProduct = async(params) => {
  let path = "";
  let {limit, offset, searchText, isWebsiteFor} = params
  isWebsiteFor === "coaching" ? 
    (path = endpoint.COACHING_PRODUCT) : (path = endpoint.UNIVERSITY_PRODUCT)
  const response = await axios.get(path, {
      params: { limit, offset, searchText },
      headers: { token: localStorage.getItem("cc_token") },
  });
  return response.data;
}

const addContent = async (params) => {
  const { type, body } = params;
  const response = await axios.post(endpoint.CONTENT, body, {
    headers: { token: localStorage.getItem("cc_token"), type },
  });
  return response.data;
}

const getContent = async(params) => {
  const { type, limit, offset, searchText } = params;
  console.log("=========>>>>>",endpoint.CONTENT);
  const response = await axios.get(endpoint.CONTENT, {
    params: { limit, offset, searchText },
    headers: { token: localStorage.getItem("cc_token"), type },
  });
  console.log("=========",response.data);
  return response.data;
}

const getContentById = async(params) => {
  const { type, id } = params;
  const response = await axios.get(endpoint.CONTENT+"/"+id, {
    headers: { token: localStorage.getItem("cc_token"), type },
  });
  return response.data;
}

const updateContent = async(params) => {
  const { id, type, body } = params;
  const response = await axios.put(endpoint.CONTENT+"/"+id, body, {
    headers: { token: localStorage.getItem("cc_token"), type },
  });
  return response.data;
}

const deleteContent = async(params) => {
  const { id, type } = params;
  const response = await axios.delete(endpoint.CONTENT + "/" + id, {
      headers: { token: localStorage.getItem("cc_token"), type },
  });
  return response.data;
}

const getCourses = async(params) => {
  let path = "";
  let {limit, offset, searchText, isWebsiteFor} = params
  isWebsiteFor === "coaching" ? 
    (path = endpoint.COACHING_COURSE) : (path = endpoint.UNIVERSITY_COURSE)
  console.log("get course==", path)
  const response = await axios.get(path, {
    params: { limit, offset, searchText },
    headers: { token: localStorage.getItem("cc_token") },
  });
  console.log("get course==", response.data)
  return response.data;
}

const getCourseById = async(params) => {
  let path = "";
  let { id, isWebsiteFor } = params;
  isWebsiteFor === "coaching" ? 
    (path = endpoint.COACHING_COURSE) : (path = endpoint.UNIVERSITY_COURSE)
  const response = await axios.get(path+"/"+id, {
    headers: { token: localStorage.getItem("cc_token") },
  });
  return response.data;
}

const updateCourse = async(params) => {
  let path = "";
  let { id, body, isWebsiteFor } = params
  isWebsiteFor === "coaching" ? 
    (path = endpoint.COACHING_COURSE) : (path = endpoint.UNIVERSITY_COURSE)
  const response = await axios.put(path+"/"+id, body, {
    headers: { token: localStorage.getItem("cc_token") },
  });
  return response.data;
}

const deleteCourse = async(params) => {
  let path = "";
  let { id, isWebsiteFor } = params
  isWebsiteFor === "coaching" ? 
    (path = endpoint.COACHING_COURSE) : (path = endpoint.UNIVERSITY_COURSE)
  const response = await axios.delete(path + "/" + id, {
      headers: { token: localStorage.getItem("cc_token") },
  });
  return response.data;
}

const updateProductById = async(params) => {
  let path = "";
  let body = {}
  if(params.isWebsiteFor === "coaching"){
    body = {
      name: params.body.name,
      category: params.body.category,
      product_type: params.body.product_type,
      payment_mode: params.body.payment_mode,
      plan: params.body.plan,
      description: params.body.description,
      syllabus_url: params.body.syllabus_url,
      images_url: params.body.images_url,
      top_faculties: params.body.top_faculties,
      toppers: params.body.toppers,
      sub_products: params.body.sub_products,
      add_ons: params.body.add_ons
    }
  }else{
    body = {
      name: params.body.name,
      category: params.body.category,
      product_type: params.body.product_type,
      branches: params.body.branches,
      course_type: params.body.course_type,
      description: params.body.description,
      syllabus_url: params.body.syllabus_url,
      images_url: params.body.images_url,
      placement_records: params.body.placement_records,
      payment_mode: params.body.payment_mode,
      sub_products: params.body.sub_products,
      add_ons: params.body.add_ons
    }
  }

  params.isWebsiteFor === "coaching" ? 
    (path = endpoint.COACHING_PRODUCT) : 
    (path = endpoint.UNIVERSITY_PRODUCT)
  const response = await axios.put(`${path}/${params?.body?._id}`, body, {
    headers: { token: localStorage.getItem("cc_token") },
  });
  return response.data;
}

const getProductById = async(params) => {
  let path = "";
  params.isWebsiteFor === "coaching" ? 
    (path = `${endpoint.COACHING_PRODUCT}/${params.id}`) : 
    (path = `${endpoint.UNIVERSITY_PRODUCT}/${params.id}`)
  const response = await axios.get(path, {
      headers: { token: localStorage.getItem("cc_token") },
  });
  return response.data;
}

const deleteProduct = async(params) => {
  let path = "";
  params.isWebsiteFor === "coaching" ? 
    (path = `${endpoint.COACHING_PRODUCT}/${params.id}`) : 
    (path = `${endpoint.UNIVERSITY_PRODUCT}/${params.id}`)
  const response = await axios.delete(path, {
      headers: { token: localStorage.getItem("cc_token") },
  });
  return response.data;
}

const productService = { 
  addProduct,
  getProduct,
  getProductById,
  updateProductById,
  deleteProduct,
  getCourses,
  getCourseById,
  updateCourse,
  deleteCourse,
  addContent,
  getContent,
  getContentById,
  updateContent,
  deleteContent
};

export default productService;