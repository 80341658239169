import * as React from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from 'prop-types';
import validator from "validator";
import {
  Box,
  Button,
  Typography,
  InputLabel,
  TextField,
  Tab,
  Tabs,
  Rating,
} from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import "./instituteDetail.css";
import parse from 'html-react-parser';
import Spinner from "../../components/Spinner/Spinner";
import DownloadIcon from '@mui/icons-material/Download';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CurrentQual from  "../../assest/images/coaching_grey.png";
import routes from "../../react_routes_enum";
import { makeStyles } from "@mui/styles";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import NewCarousel from "../../components/Carousel/NewCarousel";
import SecondaryHead from "../../components/SecondaryHead/SecondaryHead";
import { getConfigDetails } from "../../redux/configurationDetails/configDetailsSlice";
import { getInstProducts, 
  getProductById,
  getReviews, 
  addToCart,
  getAddonsByProductId,
  getSubProductByProductId,
  postStudentApplication,
  getReviewStats,
  getFromCart,
  addReview,
  setFromBuy } from "../../redux/students/studentSlice";
import { toast } from "react-toastify";
import LoaderIcon from "../../components/Loader/Loader";
import UploadBox from '../../components/UploadBox/uploadBox';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import WidgetsIcon from '@mui/icons-material/Widgets';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import BudgetIcon from "../../assest/images/budget_icon.png";
import CourseIcon from "../../assest/images/course_icon.png";
import SquireCheck from "../../assest/svgs/squire_check.svg";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Dialog from "@mui/material/Dialog";
import InputAdornment from '@mui/material/InputAdornment';
import PersonIcon from '@mui/icons-material/Person';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import CakeIcon from '@mui/icons-material/Cake';
import SchoolIcon from '@mui/icons-material/School';

const useStyle = makeStyles({
  addToCart:{
    width: '112px',
    borderColor: '#F69220 !important',
    color: '#F69220 !important',
    textTransform: 'capitalize !important',
    borderRadius: '5px !important',
    "&:hover":{
      borderColor: '#F69220',
    }
  },
  btnBuyNow: {
    backgroundColor: '#07AB35 !important',
    color: '#fff',
    width: '90px',
    textTransform: 'capitalize !important',
    borderRadius: '5px !important',
    "&:hover":{
      backgroundColor: '#07AB35',
    }
  },
  year:{
    fontSize: '14px',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    marginTop: '4px',
    marginLeft: '8px'
  },
  applyNowButton: {
    height: '40px',
    width: '104px',
    background: '#07AB35 !important',
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.15) !important',
    borderRadius: '5px',
    color: '#fff !important',
    marginTop: '8px !important',
    textTransform: 'capitalize !important',
    "&:hover":{
      background: '#07AB35',
    }
  },
  admissionTitle:{
    color: '#3C9E98',
    fontWeight: 'bold'
  }
})
const reviewBody = {
  name: "",
  email: "",
  message: "",
  rating: 0,
  video_url: "",
  image_url: "",
}
const reviewBreakPoints = [
  {width: 400, itemsToShow: 1},
  {width: 540, itemsToShow: 2},
  {width: 768, itemsToShow: 3},
  {width: 1200, itemsToShow: 3},
  {width: 1500, itemsToShow: 4},
];

const starData = [
  {
    starCount: 5
  },
  {
    starCount: 4
  },
  {
    starCount: 3
  },
  {
    starCount: 2
  },
  {
    starCount: 1
  }
]

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
  //   backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

let applBody = {}

const budgetArray = [
  "Less than 500000", 
  "Between 500000-1000000", 
  "Between 1000000-2000000",
  "Above 2000000"
]
export default function InstituteDetail() {
  const classes = useStyle();
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);
  const [course1, setCourse1] = React.useState([]);
  const [course2, setCourse2] = React.useState([]);
  const [course3, setCourse3] = React.useState([]);
  const [limit, setLimit] = React.useState(10);
  const [offset, setOffset] = React.useState(0);
  const [isAlert, setIsAlert] = React.useState(false);
  const [productList, setProductList] = React.useState([]);
  const [review, setReview] = React.useState([]);
  const [reviewData, setReviewData] = React.useState(reviewBody);
  const [buttonLoader, setButtonLoader] = React.useState(false);
  const [isProductDetails, setIsProductDetails] = React.useState(false);
  const [btnIndex, setBtnIndex] = React.useState();
  const [btnDetailLoader, setBtnDetailLoader] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [submitBody, setSubmitBody] = React.useState(applBody);
  const [selectValue, setSelectValue] = React.useState([]);
  const [submitLoader, setSubmitLoader] = React.useState(false);
  const [isApplicationSent, setIsApplicationSent] = React.useState(false);
  const [subProductId, setSubProductId] = React.useState("");
  const [subProductSentId, setSubProductSentId] = React.useState("");
  const [isPhotoDialog, setIsPhotoDialog] = React.useState(false);
  const [photoUrl, setPhotoUrl] = React.useState("");
  const [buyNowLoader, setBuyNowLoader] = React.useState(false);
  const [totalStarCount, setTotalStarCount] = React.useState(0);
  const [type, setType] = React.useState("");
  const [userData, setUserData] = React.useState(null);
  const [studyMaterialList, setStudyMaterialList] = React.useState([]);
  const [instituteDataById, setInstituteDataById] = React.useState(JSON.parse(localStorage.getItem("coachingDetail")));
  const { 
    // instituteDataById, 
    isLoading,
    productById,
    addonsByProductId,
    subproductByProductId,
    reviewStats,
    instituteId } = useSelector((state) => state.student);
  const dispatch = useDispatch();
  const location = useLocation();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  }

  React.useEffect(() => {
    setInstituteDataById(JSON.parse(localStorage.getItem("coachingDetail")));
  },[]);

  React.useEffect(() => {
    let array1 = [];
    let array2 = [];
    let array3 = [];
    instituteDataById?.courses_offered?.fee_structure.map((item) => {
      if(item?.course === "course 1"){
        array1[0] = "course 1"
        array1.push(item?.fee);
      }
      if(item?.course === "course 2"){
        array2[0] = "course 2"
        array2.push(item?.fee);
      }
      if(item?.course === "course 3"){
        array3[0] = "course 3"
        array3.push(item?.fee);
      }
    })
    setCourse1([...array1]);
    setCourse2([...array2]);
    setCourse3([...array3]);
  },[])

  React.useEffect(() => {
    setUserData(JSON.parse(localStorage.getItem("cc_user")));
    const res = dispatch(getConfigDetails());
    res?.then((r) => {
      if(r?.payload?.statusCode === 200){
        r?.payload?.data?.map((item) => {
          item?.configuration_type === "apply_now_study_material" && 
            setStudyMaterialList(item?.data)
        })
      }
    })
    getInstituteProduct();
    getInstituteReview();
    getReviewStatics();
  },[]);

  const getReviewStatics = () => {
    if(localStorage.getItem("instituteId")){
      const res = dispatch(getReviewStats({coaching_id: localStorage.getItem("instituteId")}));
      res?.then((r) => {
        if(r?.payload?.statusCode === 200){
          setTotalStarCount(r?.payload?.data?.totalCount)
        }
      })
    }
  }
  const getInstituteProduct = () => {
    if(localStorage.getItem("instituteId")){
      const res = dispatch(getInstProducts({limit, offset, coaching_id: localStorage.getItem("instituteId")}));
      res.then((r) => {
        r?.payload?.statusCode === 200 && setProductList(r?.payload?.data?.result)
      },[])
    }
  }
  const getInstituteReview = () => {
    if(localStorage.getItem("instituteId")){
      const res = dispatch(getReviews({coaching_id: localStorage.getItem("instituteId")}));
      res.then((r) => {
        r?.payload?.statusCode === 200 && setReview(r?.payload?.data?.result)
      })
    }
  }

  const seeMoreDetail = (id, i) => {
    setBtnIndex(i);
    setBtnDetailLoader(true);
    dispatch(getAddonsByProductId({id}))
    dispatch(getSubProductByProductId({id}))
    const res = dispatch(getProductById({id}));
    res.then((r) => {
      setBtnDetailLoader(false);
      r?.payload?.statusCode === 200 && setIsProductDetails(true);
    })
  }

  const handleInputChange = (e) => {
    e.target.name === "name" && setReviewData({...reviewData, name: e.target.value })
    e.target.name === "email" && setReviewData({...reviewData, email: e.target.value })
    e.target.name === "message" && setReviewData({...reviewData, message: e.target.value })
    e.target.name === "rating" && setReviewData({...reviewData, rating: e.target.value})
  }
  const submitReview = () => {
    let body = {}
    if(reviewData?.rating === 0){
      toast.error("Rating must be greater than 0");
      return;
    }
    if(reviewData?.name === ""){
      toast.error("Name Required");
      return;
    }
    if(reviewData?.email === ""){
      toast.error("Email Required");
      return;
    }
    if(!validator.isEmail(reviewData?.email)){
      toast.error("Invalid email entered");
      return;
    }
    if(reviewData?.video_url === "" && reviewData?.message === ""){
      toast.error("Message or Video required");
      return;
    }
    if(reviewData?.image_url === ""){
      toast.error("Image Required");
      return;
    }
    if(reviewData?.video_url === ""){
      body = {
        email: reviewData?.email,
        image_url: reviewData?.image_url,
        message: reviewData?.message,
        name: reviewData?.name,
        rating: reviewData?.rating,
        coaching_id: localStorage.getItem("instituteId")
      }
    }else{
      body = {
        email: reviewData?.email,
        image_url: reviewData?.image_url,
        video_url: reviewData?.video_url,
        name: reviewData?.name,
        rating: reviewData?.rating,
        coaching_id: localStorage.getItem("instituteId")
      }
    }
    setButtonLoader(true);
    const res = dispatch(addReview(body))
    res.then((r) => {
      setButtonLoader(false);
      if(r?.payload?.statusCode === 200) {
        getInstituteReview();
        setReviewData({
          name: "",
          email: "",
          message: "",
          rating: 0,
          video_url: "",
          image_url: "",
        })
        toast.success("Review added successfully")
      }else{
        toast.error(r?.payload?.message);
      }
    })
  }

  const handleUploadChange = (name, value) => {
    name === "video" && setReviewData({...reviewData, video_url: value});
    name === "image" && setReviewData({...reviewData, image_url: value});
  }
  const addItemToCart = (id, index, type) => {
    if(!localStorage.getItem('cc_user')){
      setIsAlert(true);
      return;
    }
    setType(type);
    setBtnIndex(index);
    setBtnDetailLoader(true);
    const res = type === "addons" ? dispatch(addToCart({add_on_id: id})) : 
      dispatch(addToCart({sub_product_id: id}))

    res.then((r) => {
      setBtnDetailLoader(false);
      if(r?.payload?.statusCode === 200) {
        dispatch(getFromCart());
        toast.success("Item added successfully")
      }else{
        (r?.payload?.message === "Session Expired" ||
        r?.payload?.message === "Unauthorized") ? 
        toast.error("Your session expired, please login") :
        toast.error(r?.payload?.message)
      }
    })
  }
  const handleClose = () => {
    setIsOpen(false);
    setIsPhotoDialog(false);
    setIsAlert(false);
  }
  const openPhoto = (url) => {
    setPhotoUrl(url)
    setIsOpen(true);
    setIsPhotoDialog(true);
  }
  const applyNowForm = (id, type) => {
    if(!localStorage.getItem('cc_user')){
      setIsAlert(true);
      return;
    }
    setSubProductId(id);
    type === "subProduct" ? setSubmitBody({
      ...submitBody, 
      sub_product_id: id,
      courses: location?.pathname === "/university-detail" ? productById?.branches :
        [productById?.product_type]
      }) :
      setSubmitBody({
        ...submitBody, 
        add_on_id: id,
        courses: location?.pathname === "/university-detail" ? productById?.branches :
        [productById?.product_type]
      })
    setIsOpen(true);
  }

  const applyNowClicked = () => {
    if(!validate(submitBody).status){
      let body = {}
      if(location?.pathname === "/university-detail"){
        if(submitBody?.current_college && submitBody?.current_college !== ""){
          if(submitBody?.budget && submitBody?.budget !== ""){
            body = {
              // university_id: instituteId,
              name: submitBody?.name,
              email: submitBody?.email,
              address: submitBody?.address,
              phone_number: submitBody?.phone_number,
              dob: submitBody?.dob,
              current_college: submitBody?.current_college,
              current_qualification: submitBody?.current_qualification,
              courses: submitBody?.courses,
              budget: submitBody?.budget,
              location: submitBody?.location,
              parent_name: submitBody?.parent_name,
              parent_phone_number: submitBody?.parent_phone_number,
              // university_name: instituteDataById?.university_name,
              // sub_product_id: submitBody?.sub_product_id
            }
          }else{
            body = {
              // university_id: instituteId,
              name: submitBody?.name,
              email: submitBody?.email,
              address: submitBody?.address,
              phone_number: submitBody?.phone_number,
              dob: submitBody?.dob,
              current_college: submitBody?.current_college,
              current_qualification: submitBody?.current_qualification,
              courses: submitBody?.courses,
              location: submitBody?.location,
              parent_name: submitBody?.parent_name,
              parent_phone_number: submitBody?.parent_phone_number,
              // university_name: instituteDataById?.university_name,
              // sub_product_id: submitBody?.sub_product_id
            }
          }
        }else{
          if(submitBody?.budget && submitBody?.budget !== ""){
            body = {
              // university_id: instituteId,
              name: submitBody?.name,
              email: submitBody?.email,
              address: submitBody?.address,
              phone_number: submitBody?.phone_number,
              dob: submitBody?.dob,
              current_qualification: submitBody?.current_qualification,
              courses: submitBody?.courses,
              budget: submitBody?.budget,
              location: submitBody?.location,
              parent_name: submitBody?.parent_name,
              parent_phone_number: submitBody?.parent_phone_number,
              // university_name: instituteDataById?.university_name,
              // sub_product_id: submitBody?.sub_product_id
            }
          }else{
            body = {
              // university_id: instituteId,
              name: submitBody?.name,
              email: submitBody?.email,
              address: submitBody?.address,
              phone_number: submitBody?.phone_number,
              dob: submitBody?.dob,
              current_qualification: submitBody?.current_qualification,
              courses: submitBody?.courses,
              location: submitBody?.location,
              parent_name: submitBody?.parent_name,
              parent_phone_number: submitBody?.parent_phone_number,
              // university_name: instituteDataById?.university_name,
              // sub_product_id: submitBody?.sub_product_id
            }
          }
        }
      }else{
        if(submitBody?.current_college && submitBody?.current_college !== ""){
          if(submitBody?.budget && submitBody?.budget !== ""){
            body = {
              // coaching_id: instituteId,
              name: submitBody?.name,
              email: submitBody?.email,
              address: submitBody?.address,
              phone_number: submitBody?.phone_number,
              dob: submitBody?.dob,
              current_college: submitBody?.current_college,
              current_qualification: submitBody?.current_qualification,
              courses: submitBody?.courses,
              budget: submitBody?.budget,
              location: submitBody?.location,
              parent_name: submitBody?.parent_name,
              parent_phone_number: submitBody?.parent_phone_number,
              // coaching_name: instituteDataById?.coaching_name,
              // sub_product_id: submitBody?.sub_product_id
            }
          }else{
            body = {
              // coaching_id: instituteId,
              name: submitBody?.name,
              email: submitBody?.email,
              address: submitBody?.address,
              phone_number: submitBody?.phone_number,
              dob: submitBody?.dob,
              current_college: submitBody?.current_college,
              current_qualification: submitBody?.current_qualification,
              courses: submitBody?.courses,
              location: submitBody?.location,
              parent_name: submitBody?.parent_name,
              parent_phone_number: submitBody?.parent_phone_number,
              // coaching_name: instituteDataById?.coaching_name,
              // sub_product_id: submitBody?.sub_product_id
            }
          }
        }else{
          if(submitBody?.budget && submitBody?.budget !== ""){
            body = {
              // coaching_id: instituteId,
              name: submitBody?.name,
              email: submitBody?.email,
              address: submitBody?.address,
              phone_number: submitBody?.phone_number,
              dob: submitBody?.dob,
              current_qualification: submitBody?.current_qualification,
              courses: submitBody?.courses,
              budget: submitBody?.budget,
              location: submitBody?.location,
              parent_name: submitBody?.parent_name,
              parent_phone_number: submitBody?.parent_phone_number,
              // coaching_name: instituteDataById?.coaching_name,
              // sub_product_id: submitBody?.sub_product_id
            }
          }else{
            body = {
              // coaching_id: instituteId,
              name: submitBody?.name,
              email: submitBody?.email,
              address: submitBody?.address,
              phone_number: submitBody?.phone_number,
              dob: submitBody?.dob,
              current_qualification: submitBody?.current_qualification,
              courses: submitBody?.courses,
              location: submitBody?.location,
              parent_name: submitBody?.parent_name,
              parent_phone_number: submitBody?.parent_phone_number,
            }
          }
        }
      }
      body = {...body, coaching_id: localStorage.getItem("instituteId"), coaching_name: instituteDataById?.coaching_name}
      body = submitBody?.sub_product_id ? 
        {...body, sub_product_id: submitBody?.sub_product_id} : 
        {...body, add_on_id: submitBody?.add_on_id}
      setSubmitLoader(true);
      const res = dispatch(postStudentApplication(body));
      res.then((r) => {
        setSubmitLoader(false);
        if(r?.payload?.statusCode === 200){
          if(r?.payload?.message === "Application already exists"){
            toast.error(r?.payload?.message);
            return;
          }
          setIsApplicationSent(true);
          setSubProductSentId(body?.sub_product_id ? body?.sub_product_id : body?.add_on_id)
          toast.success("Application sent successfully");

        }else{
          (r?.payload?.message === "Session Expired" ||
          r?.payload?.message === "Unauthorized") ? 
          toast.error("Your session expired, please login") :
          toast.error(r?.payload?.message)
        }
      })
    }else{
      toast.error(validate(submitBody).message);
    }
  }

  const handleApInputChange = (e) => {
    e.target.name === "name" && setSubmitBody({...submitBody, name: e.target.value});
    e.target.name === "address" && setSubmitBody({...submitBody, address: e.target.value});
    e.target.name === "email" && setSubmitBody({...submitBody, email: e.target.value});
    e.target.name === "phone_number" && setSubmitBody({...submitBody, phone_number: e.target.value});
    e.target.name === "dob" && setSubmitBody({...submitBody, dob: e.target.value});
    e.target.name === "current_college" && setSubmitBody({...submitBody, current_college: e.target.value})
    e.target.name === "current_qualification" && setSubmitBody({...submitBody, current_qualification: e.target.value})
    e.target.name === "budget" && setSubmitBody({...submitBody, budget: e.target.value})
    e.target.name === "study_material" && setSubmitBody({...submitBody, study_material: e.target.value})
    e.target.name === "location" && setSubmitBody({...submitBody, location: e.target.value})
    e.target.name === "p_name" && setSubmitBody({...submitBody, parent_name: e.target.value})
    e.target.name === "p_phone_number" && setSubmitBody({...submitBody, parent_phone_number: e.target.value})
    if(e.target.name === "courses"){
      setSelectValue(e.target.value);
      setSubmitBody({...submitBody, courses: e.target.value});
    }
  }

  const validate = (data) => {
    let error = {status: false, message: ''};
    if(!data?.name || data?.name === ""){
      error.status = true;
      error.message = "Name required";
      return error;
    }
    if(!data?.address || data?.address === ""){
      error.status = true;
      error.message = "Address required";
      return error;
    }
    if(!data?.phone_number || data?.phone_number === ""){
      error.status = true;
      error.message = "Phone number required";
      return error;
    }
    if(data?.phone_number?.length !== 10){
      error.status = true;
      error.message = "Invalid phone number entered";
      return error;
    }
    if(!data?.email || data?.email === ""){
      error.status = true;
      error.message = "Email required";
      return error;
    }
    if(!validator.isEmail(data?.email)){
      error.status = true;
      error.message = "Invalid email entered";
      return error;
    }
    if(!data?.dob || data?.dob === ""){
      error.status = true;
      error.message = "DOB required";
      return error;
    }
    if(!data?.current_qualification || data?.current_qualification === ""){
      error.status = true;
      error.message = "Current qualification required";
      return error;
    }
    if((location?.pathname === "/coaching-detail") &&
    (!data?.study_material || data?.study_material === "")){
      error.status = true;
      error.message = "Study materail required";
      return error;
    }
    if(!data?.location || data?.location === ""){
      error.status = true;
      error.message = "Location required";
      return error;
    }
    if(!data?.parent_name || data?.parent_name === ""){
      error.status = true;
      error.message = "Parent name required";
      return error;
    }
    if(!data?.parent_phone_number || data?.parent_phone_number === ""){
      error.status = true;
      error.message = "Parent phone number required";
      return error;
    }
    if(data?.parent_phone_number?.length !== 10){
      error.status = true;
      error.message = "Invalid parent mobile number entered";
      return error;
    }
    return error;
  }
  const calculatePercent = (rev, strCount) => {
    let per = 0;
    rev.map((item) => {
      if(item.rating === strCount){
        per = parseInt((item?.count / totalStarCount) * 100)
      }
    })
    return per
  }

  const buyNow = (id, index, from) => {
    if(!localStorage.getItem('cc_user')){
      setIsAlert(true);
      return;
    }
    setType(from)
    setBtnIndex(index);
    setBuyNowLoader(true);
    const res = from === "addons" ? dispatch(addToCart({add_on_id: id})) : 
          dispatch(addToCart({sub_product_id: id}))
    res?.then((r) => {
      setBuyNowLoader(false);
      if(r?.payload?.statusCode === 200){
        // localStorage.setItem("fromBuy", "buy");
        dispatch(setFromBuy("buy"));
        navigate(routes?.CHECKOUT);
      }else{
        (r?.payload?.message === "Session Expired" ||
        r?.payload?.message === "Unauthorized") ? 
        toast.error("Your session expired, please login") :
        toast.error(r?.payload?.message)
      }
    })

  }
  if(isLoading) return <Spinner />;

  return(
  <>
    <Dialog maxWidth="xs" open={isAlert} onClose={handleClose} fullWidth>
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: '24px'
      }}>
        <ReportProblemIcon 
          sx={{
            fontSize: '40px',
            color: 'red'
          }}
        />
      </div>
      <button 
        onClick={handleClose}
        variant="contained" 
        style={{
          position: 'absolute',
          top: '12px',
          right: '12px',
          background: 'none',
          color: 'black',
          fontWeight: 'bold',
          border: 'none',
          cursor: 'pointer',
          fontSize: '18px'
        }}>
        X
      </button>
      <h4 style={{
        textAlign: 'center',
        marginTop: '8px'
        }}>
        Your are not logged in, click login button
      </h4>
      <div 
        className="submit-btn-aria"
        style={{margin: '0 12px 12px 12px'}}>
        <Button 
          onClick={() => {
            localStorage.setItem("is-website-for", "student");
            navigate("/signup")
          }}
          variant="contained" 
          className="apply-now-submit"
          sx={{
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            textTransform: 'capitalize',
            fontWeight: 'bold',
          }}>
          Sign Up
        </Button>
        <Button 
          onClick={() => {
            localStorage.setItem("is-website-for", "student");
            navigate('/login');
          }} 
          variant="contained" 
          className="apply-now-submit"
          sx={{
            backgroundColor: '#07AB35',
            textTransform: 'capitalize',
            fontWeight: 'bold',
          }}
        >
          Login
        </Button>
      </div>
    </Dialog>
    <Dialog maxWidth="md" open={isOpen} onClose={handleClose} fullWidth>
      {isPhotoDialog ?
      <img src={photoUrl} alt="GalleryPhoto" style={{
        width: '100%'
      }} /> :
      <div style={{
        padding: '16px 24px'
      }}>
        <div className="form-heading-aria">
            <div className="logo-university">
                {instituteDataById && <img src={instituteDataById?.uploads?.coaching_logo || 
                  instituteDataById?.uploads?.university_logo} 
                  alt="university logo"/>}
            </div>
            <div className="register-text">
                <p className="register-title">Register now to apply</p>
                {instituteDataById && <p className="university-name">
                  {instituteDataById?.coaching_name || instituteDataById?.university_name+ ", "}
                  {instituteDataById?.information?.city}
                </p>}
            </div>
            <div style={{
              padding: '8px 0 0 0'
            }} className="course-aria-popup-1">
              <InputLabel className="input-label-text">Course</InputLabel>
              <div style={{
                display: 'flex'
              }}>
                <img src={CourseIcon} alt="" width="16"/>
                <p style={{
                  margin: 0,
                  paddingLeft: '8px'
                }} className="university-name">
                  {location?.pathname === "/university-detail" ?
                  productById?.branches?.map((item, ind) => (
                    ind !== productById?.branches.length - 1 ? `${item}, ` : item
                  )): productById?.product_type}
                </p>
              </div>
            </div>
        </div>
        <div style={{
          padding: '16px 0 0 0',
          marginBottom: '12px'
        }} className="course-aria-popup-2">
          <InputLabel className="input-label-text">Course</InputLabel>
          <div style={{
            display: 'flex'
          }}>
            <img src={CourseIcon} alt="" width="16"/>
            <p style={{
              margin: 0,
              paddingLeft: '8px'
            }} className="university-name">
              {location?.pathname === "/university-detail" ?
              productById?.branches?.map((item, ind) => (
                ind !== productById?.branches.length - 1 ? `${item}, ` : item
              )): productById?.product_type}
            </p>
          </div>
        </div>
        {isApplicationSent && (subProductSentId === subProductId) ? 
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '48px 0'
          }}>
            <img src={SquireCheck} alt="checkIcon" style={{
              width: '64px'
            }} />
            <p className="appl-sent-succes">
              Your Request is Successfully Captured. Please Check It in your Dashboard.
            </p>
            <Link to={location?.pathname === "/university-detail" ? 
              routes.STUDENT_UNIVERSITY : routes.STUDENT_COACHING} 
              style={{
                backgroundColor: 'var(--secondary-color)',
                textTransform: 'capitalize',
                color: '#fff',
                padding: '4px 8px',
                borderRadius: '4px'
              }}>Go To Dashboard</Link>
          </div>
        :
          <div>
            <form className="register-form-control">
              <div className="two-input-aria">
                <div className="input-box-mobile input-box-aria">
                  <InputLabel className="input-label-text">Name</InputLabel>
                    <TextField 
                      placeholder="" 
                      type="text" 
                      variant="outlined" 
                      name="name"
                      className="name-field" 
                      onChange={handleApInputChange}
                      // value={applicationById?.name}
                      required
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PersonIcon fontSize='small' />
                          </InputAdornment>
                        ),
                      }}
                    />                     
                </div>
                <div className="input-box-mobile marginLeftIn input-box-aria">
                  <InputLabel className="input-label-text">Address</InputLabel>
                    <TextField 
                      placeholder="" 
                      type="text" 
                      variant="outlined" 
                      name="address" 
                      className="name-field"
                      onChange={handleApInputChange}
                      // value={applicationById?.address}
                      required
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LocationOnIcon fontSize='small' />
                          </InputAdornment>
                        ),
                      }}
                      />
                </div>
              </div>
              <div className="two-input-aria">
                <div className="input-box-mobile input-box-aria">
                  <InputLabel className="input-label-text">Phone Number</InputLabel>
                  <div style={{display: 'flex'}}>
                    <TextField 
                      className="country-code" 
                      value="+91" 
                      sx={{color: 'rgb(0, 0, 0)'}}>
                    </TextField>
                    <TextField 
                      placeholder="" 
                      type="number" 
                      variant="outlined" 
                      name="phone_number"
                      onChange={handleApInputChange}
                      className="name-field phoneMargin" 
                      // value={applicationById?.phone_number}
                      required
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PhoneIcon fontSize='small' />
                          </InputAdornment>
                        ),
                      }}
                    />   
                  </div>                  
                </div>
                <div className="input-box-mobile marginLeftIn input-box-aria">
                  <InputLabel className="input-label-text">Email Id</InputLabel>
                    <TextField 
                      placeholder="" 
                      type="email" 
                      variant="outlined" 
                      name="email" 
                      onChange={handleApInputChange}
                      className="name-field"
                      // value={applicationById?.email}
                      required
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <EmailIcon fontSize='small' />
                          </InputAdornment>
                        ),
                      }}
                    />
                </div>
              </div>
              <div className="two-input-aria">
                <div className="input-box-mobile input-box-aria">
                  <InputLabel className="input-label-text">Date of Birth</InputLabel>
                    <TextField 
                      placeholder="" 
                      type="date" 
                      inputProps={{
                        max: "2011-04-14"
                      }}
                      variant="outlined" 
                      name="dob"
                      className="name-field" 
                      onChange={handleApInputChange}
                      // value={Moment(applicationById?.dob).format("DD MMMM YYYY")}
                      required
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <CakeIcon fontSize='small' />
                          </InputAdornment>
                        ),
                      }}
                    />                     
                </div>
                <div className="input-box-mobile marginLeftIn input-box-aria">
                  <InputLabel className="input-label-text">Currently School/College
                  <span style={{color:'#00000080', fontSize: '12px'}}>(Optional)</span></InputLabel>
                    <TextField 
                      placeholder="" 
                      type="text" 
                      variant="outlined" 
                      name="current_college" 
                      className="name-field"
                      onChange={handleApInputChange}
                      // value={applicationById?.current_college}
                      required
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SchoolIcon fontSize='small' />
                          </InputAdornment>
                        ),
                      }}
                    />
                </div>
              </div>
              <div className="two-input-aria">
                <div className="input-box-mobile input-box-aria">
                  <InputLabel className="input-label-text">Current Qualification</InputLabel>
                    <TextField 
                      placeholder="" 
                      type="text" 
                      variant="outlined" 
                      name="current_qualification"
                      className="name-field" 
                      onChange={handleApInputChange}
                      // value={applicationById?.current_qualification}
                      required
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img src={CurrentQual} alt="" width="18" />
                          </InputAdornment>
                        ),
                      }}
                    />                     
                </div>
                {location?.pathname === "/university-detail" &&
                <div className="input-box-mobile marginLeftIn input-box-aria">
                  <InputLabel className="input-label-text">Location</InputLabel>
                    <TextField 
                      placeholder="" 
                      type="text" 
                      variant="outlined" 
                      name="location" 
                      className="name-field"
                      onChange={handleApInputChange}
                      required
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LocationOnIcon fontSize='small' />
                          </InputAdornment>
                        ),
                      }}
                    />
                </div>}
                {location?.pathname === "/coaching-detail" &&
                <div className="input-box-mobile marginLeftIn input-box-aria">
                  <InputLabel className="input-label-text">Study Material</InputLabel>
                  <Select
                    className="name-field"
                    name="study_material"
                    defaultValue=""
                    displayEmpty
                    onChange={handleApInputChange}
                    renderValue={(value) => {
                      return (
                        <Box sx={{ 
                          display: "flex", 
                          gap: 1, 
                          alignItems: 'center'}}>
                          <img src={CourseIcon} alt="" width="16"
                            style={value ? {marginTop: 0} : {marginTop: '8px'}}/>
                          {value}
                        </Box>
                      );
                    }}
                  >
                    {studyMaterialList.map((item, index) => (
                    <MenuItem value={item} key={index}>
                      {item}
                    </MenuItem>))}
                  </Select>
                </div>}
              </div>
              <div className="two-input-aria">
                <div className="input-box-mobile input-box-aria">
                  <InputLabel className="input-label-text">Budget
                    <span style={{color:'#00000080', fontSize: '12px'}}>(Optional)</span>
                  </InputLabel>
                  <Select
                    className="name-field"
                    name="budget"
                    // sx={{ width: 130 }}
                    defaultValue=""
                    displayEmpty
                    onChange={handleApInputChange}
                    renderValue={(value) => {
                      return (
                        <Box sx={{ 
                          display: "flex", 
                          gap: 1, 
                          alignItems: 'center'}}>
                          <img src={BudgetIcon} alt="" width="16"
                            style={value ? {marginTop: 0} : {marginTop: '8px'}}/>
                          {value}
                        </Box>
                      );
                    }}
                  >
                    {budgetArray.map((item, index) => (
                    <MenuItem value={item} key={index}>
                      {item}
                    </MenuItem>))}
                  </Select>
                </div>
                {location?.pathname === "/coaching-detail" &&
                <div className="input-box-mobile marginLeftInP input-box-aria">
                  <InputLabel className="input-label-text">Location</InputLabel>
                    <TextField 
                      placeholder="" 
                      type="text" 
                      variant="outlined" 
                      name="location" 
                      className="name-field"
                      onChange={handleApInputChange}
                      required
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LocationOnIcon fontSize='small' />
                          </InputAdornment>
                        ),
                      }}
                    />
                </div>}
                {location?.pathname === "/university-detail" &&
                <div className="input-box-mobile marginLeftInP input-box-aria">
                  <InputLabel className="input-label-text">Parent's Name</InputLabel>
                    <TextField 
                      placeholder="" 
                      type="text" 
                      variant="outlined" 
                      name="p_name" 
                      className="name-field"
                      onChange={handleApInputChange}
                      // value={applicationById?.parent_name}
                      required
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PersonIcon fontSize='small' />
                          </InputAdornment>
                        ),
                      }}
                    />
                </div>}
              </div>
              <div className="two-input-aria">
              {location?.pathname === "/coaching-detail" &&
                <div className="input-box-mobile input-box-aria">
                  <InputLabel className="input-label-text">Parent's Name</InputLabel>
                    <TextField 
                      placeholder="" 
                      type="text" 
                      variant="outlined" 
                      name="p_name" 
                      className="name-field"
                      onChange={handleApInputChange}
                      // value={applicationById?.parent_name}
                      required
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PersonIcon fontSize='small' />
                          </InputAdornment>
                        ),
                      }}
                    />
                </div>}
                <div className={`input-box-mobile input-box-aria 
                  ${location?.pathname === "/coaching-detail" && 'marginLeftInP'}`}>
                  <InputLabel className="input-label-text">Parent's Contact Number</InputLabel>
                  <div style={{display: 'flex'}}>
                    <TextField className="country-code" value="+91" sx={{color: 'rgb(0, 0, 0)'}}></TextField>
                    <TextField 
                      placeholder="" 
                      type="number" 
                      variant="outlined" 
                      name="p_phone_number"
                      className="name-field phoneMargin" 
                      onChange={handleApInputChange}
                      // value={applicationById?.parent_phone_number}
                      required
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PhoneIcon fontSize='small' />
                          </InputAdornment>
                        ),
                      }}
                    />   
                  </div>                  
                </div>
                {location?.pathname === "/university-detail" &&
                <div className="input-box-mobile marginLeftInP input-box-aria">
                </div>}
              </div>
              <div className="submit-btn-aria">
                <p>
                  <span style={{
                    color: 'rgb(0, 0, 0, 0.5)',
                    fontSize: '14px'
                  }}>By Submitting This Form, You Accept And Agree To Our </span>
                  <a href="/" style={{
                    color: '#3C1CDE',
                    fontSize: '14px',
                    fontWeight: 'bold',
                  }}>
                    Term Of Use
                  </a>
                </p>
                <Button 
                  onClick={applyNowClicked} 
                  variant="contained" 
                  className="apply-now-submit"
                  sx={{
                    backgroundColor: '#F69220',
                    textTransform: 'capitalize',
                    fontWeight: 'bold',
                  }}
                  disabled={submitLoader}
                >
                  {submitLoader ? <LoaderIcon /> : 'Submit'}
                </Button>
              </div>
            </form>
        </div>}
      </div>
      }
    </Dialog>
    <div style={{ 
      minHeight: '500px'
    }}>
      <SecondaryHead 
        heading={instituteDataById?.university_name || instituteDataById?.coaching_name} 
        textWidth={window.innerWidth <= 540 ? "auto" : "394px"} />
      <Box sx={{ 
        marginTop: '48px', 
        marginBottom: '4px'}} className="tab-aria">
        <Tabs
          value={value}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="scrollable auto tabs example" 
          onChange={handleChange}> 
            <Tab className="txt-detail" label="Courses & Fees" />
            <Tab className="txt-detail" label="Info"/>
            {location?.pathname === "/university-detail" &&
              <Tab className="txt-detail" label="Placement Records"  />}
            <Tab className="txt-detail" label="Reviews" />
            <Tab className="txt-detail" label="Gallery" />
            <Tab className="txt-detail" label="Notice & Announcements" />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0} className="tab-panel-fees">
          {!isProductDetails ?
          <>
          {(productList && productList?.length > 0) ?
            <>
              <div className="secondText">
                <p>
                  Courses offered by {" "}
                  {instituteDataById?.coaching_name || instituteDataById?.university_name}
                </p>
              </div>
              <div className="details-div">
                <div className="course-info-card">
                  {productList?.length > 0 && productList?.map((item, index) => (
                  <Card sx={{boxShadow:'none'}} key={index}>
                    <CardContent>
                      <Typography className="course-name"  gutterBottom>
                        {item?.name}
                      </Typography>
                      <div className="product-require-details">
                        <p>
                          <span style={{color: '#07AB35'}}>Category: </span><span>{item?.category}</span>
                        </p>
                        <p>
                          <span style={{color: '#07AB35'}}>
                            Type: </span><span>{item?.product_type} Program
                          </span>
                        </p>
                        <p>
                          {item?.course_type && 
                          <>
                            <span style={{color: '#07AB35'}}>
                              Course Type: </span>
                            <span>{item?.course_type}</span>
                          </>
                          }
                          {item?.plan && 
                          <>
                            <span style={{color: '#07AB35'}}>
                              Plan: </span>
                            <span>{item?.plan}</span>
                          </>
                          }
                        </p>
                      </div>
                      <p style={{
                        fontSize: '16px',
                        color: 'rgb(0, 0, 0, 0.5)',
                        margin: '8px 0 16px 0'}}
                        className="prod-descr">{item?.description}</p>
                      <Button 
                        variant="outlined" 
                        sx={{
                          width: '144px'
                        }}
                        disabled={btnDetailLoader && (btnIndex === index)}
                        onClick={() => seeMoreDetail(item?._id, index)}>
                          {(btnDetailLoader && (btnIndex === index)) ? <LoaderIcon /> : 'See More Details'}
                      </Button>
                    </CardContent>
                  </Card>))}
                </div>
              </div>
            </> :
            <>
              <p style={{
                fontWeight: 'bold',
              }}>
                No Course Found
              </p>
            </>
            }
          </>
          :
          <div className="prduct-detail-box">
            <Button onClick={() => {setIsProductDetails(false)}}>
              <ArrowBackIosIcon />
            </Button>
            <div style={{
              background: '#FFFFFF',
              boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.15)',
              borderRadius: '10px',
            }} className="product-detail-inner-box">
              <Typography className="course-name"  gutterBottom>
                {productById?.name}
              </Typography>
              <div style={{
                marginTop: '12px',
                marginBottom: '4px'}}>
                <span style={{color: 'rgb(0, 0, 0, 0.5)'}}>Category: </span>
                <span style={{color: '#ECAB2D', fontWeight: '600'}}>
                  {productById?.category}
                </span>
              </div>
              <div>
                <span style={{color: 'rgb(0, 0, 0, 0.5)'}}>Type: </span>
                <span style={{color: '#ECAB2D', fontWeight: '600'}}>
                  {productById?.product_type}
                </span>
              </div>
              <p style={{
                lineHeight: '28px'
              }}>
                {productById?.description}
              </p>
              <a href={productById?.syllabus_url} 
                target="_blank" 
                rel="noreferrer"
                style={{
                  display: 'flex',
                  color: '#3C9E98',
                  cursor: 'pointer',
                  width: 'fit-content'
                }}>
                <span style={{marginRight: '4px'}}>Download Syllabus</span><SaveAltIcon fontSize="small"/>
              </a>
              {(productById?.images_url && productById?.images_url?.length > 0) && 
              <div>
                <p style={{
                  margin: '12px 0 12px 0',
                  display: 'flex',
                  color: 'var(--primary-color)',
                  alignItems: 'center',
                  }}>
                  <span style={{
                    marginRight: '4px',
                    fontWeight: 'bold',
                  }}>View Gallery</span> <WidgetsIcon fontSize="small" /> <KeyboardArrowUpIcon/>
                </p>
                <div style={{
                  display: 'inline-block',
                  marginBottom: '12px'
                }}>
                  {productById?.images_url?.map((item, index) => (
                    <div style={{
                      // height: '150px',
                      width: '150px',
                      borderRadius: '4px',
                      display: 'inline-block',
                      marginTop: '8px',
                      marginRight: '8px'
                    }} key={index}>
                      <img src={item} alt="galleryImage" style={{width: '100%'}} />
                    </div>
                  ))}
                </div>
                {productById?.placement_records?.length > 0 &&
                  <div>
                  <p style={{
                    margin: '12px 0 12px 0',
                    display: 'flex',
                    color: 'var(--primary-color)',
                    alignItems: 'center',
                    }}>
                    <span style={{fontWeight: 'bold'}}>Placement Record</span>
                  </p>
                  <div style={{
                  display: 'inline-block',
                  marginBottom: '12px'
                }}>
                  {productById?.placement_records?.map((item, index) => (
                    <div style={{
                      marginTop: '8px',
                      marginRight: '44px',
                      display: 'inline-block'
                      }} key={index}>
                      <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                      }}>
                        <div style={{
                          height: '66px',
                          width: '66px',
                          borderRadius: '50%',
                          border: '1px solid rgb(0, 0, 0, 0.4)',
                          overflow: 'hidden'
                        }}>
                          <img src={item?.profile_picture} alt="galleryImage" style={{
                            width: '100%',
                            height: '100%',
                          }} />
                        </div>
                        <p style={{
                          margin: '8px 0 4px 0',
                          textAlign: 'center',
                          fontWeight: 'bold',
                          fontSize: '14px'
                        }}>{item?.student_name} Selected</p>
                        <p style={{
                          marginBottom: '4px',
                          marginTop: '0',
                          textAlign: 'center',
                          fontSize: '14px',
                          textTransform: 'uppercase',
                        }}>{item?.company_name}</p>
                        <p style={{
                          marginTop: '0',
                          textAlign: 'center',
                          fontSize: '14px',
                          color: '#07AB35',
                          fontWeight: 'bold'
                        }}>{item?.experience_years} Batch</p>
                      </div>
                    </div>
                  ))}
                </div>
                </div>}
              </div>}
              {(productById?.top_faculties && productById?.top_faculties?.length > 0) &&
              <div>
                <p style={{
                  margin: '12px 0 12px 0',
                  display: 'flex',
                  color: 'var(--primary-color)',
                  alignItems: 'center',
                  }}>
                  <span style={{
                    marginRight: '4px',
                    fontWeight: 'bold',
                  }}>Top Faculties</span>
                </p>
                <div style={{
                  display: 'inline-block',
                  marginTop: '12px'
                }}>
                  {productById?.top_faculties.map((item, index) => (
                    <div key={index} style={{
                      display: 'inline-block',
                      float: 'left',
                      margin: '12px'
                    }}>
                      <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center'

                      }}>
                        <div style={{
                          width: '66px',
                          height: '66px',
                          borderRadius: '50%',
                          overflow: 'hidden',
                          border: '1px solid rgb(0, 0, 0, 0.3)'
                        }}>
                          <img src={item?.profile_picture} alt="profileImage" style={{
                            width: '100%',
                          }} />
                        </div>
                        <p style={{
                          margin: '8px 0 4px 0',
                          textAlign: 'center',
                          fontWeight: 'bold',
                          fontSize: '14px'
                        }}>{item?.name}</p>
                        <p style={{
                          marginTop: '0',
                          textAlign: 'center',
                          fontSize: '14px',
                          color: '#07AB35',
                          fontWeight: 'bold'
                        }}>{item?.experience_years} Years Of Experience</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>}
              {(productById?.toppers && productById?.toppers?.length > 0) &&
              <div>
                <p style={{
                  margin: '12px 0 12px 0',
                  display: 'flex',
                  color: 'var(--primary-color)',
                  alignItems: 'center',
                  }}>
                  <span style={{
                    marginRight: '4px',
                    fontWeight: 'bold',
                  }}>Our Toppers</span>
                </p>
                <div style={{
                  display: 'inline-block',
                  marginTop: '12px'
                }}>
                  {productById?.toppers.map((item, index) => (
                    <div key={index} style={{
                      display: 'inline-block',
                      float: 'left',
                      margin: "12px 36px",
                    }}>
                      <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}>
                        <div style={{
                          width: '66px',
                          height: '66px',
                          borderRadius: '50%',
                          overflow: 'hidden',
                          border: '1px solid rgb(0, 0, 0, 0.3)'
                        }}>
                          <img src={item?.profile_picture} alt="profileImage" style={{
                            width: '100%',
                          }} />
                        </div>
                        <p style={{
                          margin: '8px 0 4px 0',
                          textAlign: 'center',
                          fontWeight: 'bold',
                          fontSize: '14px'
                        }}>{item?.name}</p>
                        <p style={{
                          margin: '2px 0 4px 0',
                          textAlign: 'center',
                          fontWeight: 'bold',
                          fontSize: '14px'
                        }}>Rank: {item?.rank}</p>
                        <p style={{
                          marginTop: '0',
                          textAlign: 'center',
                          fontSize: '14px',
                          color: '#07AB35',
                          fontWeight: 'bold'
                        }}>200{item?.experience_years} Batch</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>}
            </div>
            <div style={{
              height: '0.5px',
              margin: '24px 0',
              backgroundColor: 'rgb(0 0 0 / 40%)',
              }}>
            </div>
            {(subproductByProductId && subproductByProductId?.length > 0) &&
            <div>
              {subproductByProductId.map((item, index) => ( 
              <div style={{
                marginTop: '12px'
              }} key={index}>
                <Typography className="course-name"  gutterBottom>
                  {item?.title}
                </Typography>
                <div className="admission-seat-outer-box">
                  <div className="admission-seat-inner">
                    <div className="admission-seat-box">
                      <div>
                        <span className={classes.admissionTitle}>Course Duration: </span>
                        <span style={{fontWeight: 'bold'}}>{item?.duration} Years</span>
                      </div>
                      <div className="semester-box">
                        <span className={classes.admissionTitle}>Semester: </span>
                        <span style={{fontWeight: 'bold'}}>{item?.semester} Semesters</span>
                      </div>
                    </div>
                    {(item?.subjects && item?.subjects?.length > 0) && 
                    <div>
                      <span className={classes.admissionTitle}>Subjects: </span>
                      <span style={{fontWeight: 'bold'}}>
                        {item?.subjects?.map((item_2, index) => (
                          `${item_2}${((item?.subjects?.length - 1) !== index) ? ', ' : ''}`
                        ))}
                      </span>
                    </div>}
                    <div>
                      <span className={classes.admissionTitle}>Eligibility Criteria: </span>
                      <span style={{fontWeight: 'bold'}}>{item?.eligibility_criteria}</span>
                    </div>
                    <div>
                      <span className={classes.admissionTitle}>Description: </span>
                      <span className="admission-descrption-mobile">
                        Lorem ipsum dolor sit amet consectetur. Posuere egestas ut in sed nec.Lorem ipsum dolor 
                        sit amet consectetur. Posuere egestas ut in sed nec.
                      </span>
                    </div>
                  </div>
                  <div>
                    <div style={{
                      color: '#07AB35'
                    }}>
                      <span style={{
                        fontWeight: 'bold',
                      }}>Rs {item?.fees + parseInt((item?.fees/100)*item?.gst)}/Annum</span>
                      <span> inc. of GST</span>
                    </div>
                    {item?.discount > 0 &&
                      <span style={{
                      color: 'rgb(0, 0, 0, 0.5)'
                    }}>
                      {item?.is_percentage_discount ? `${item?.discount}%off` : `Rs ${item?.discount} off`}
                    </span>}
                    <div>
                      {item?.payment_procedure !== "full_process" ?
                      <div style={{
                        display: 'flex',
                      }}>
                        <div>
                          <Button 
                            variant="outlined" 
                            disabled={
                              btnDetailLoader && 
                              (btnIndex === index) &&
                              type === "subproduct"
                            }
                            className={classes.addToCart}
                            sx={{margin: '0 12px 0 0'}}
                            onClick={() => addItemToCart(item?._id, index, "subproduct")}>
                              {(btnDetailLoader && 
                                (btnIndex === index) &&
                                type === "subproduct") ? <LoaderIcon /> : 'Add To Cart'}
                          </Button>
                        </div>
                        <div>
                          <Button 
                            variant="contained"
                            disabled={
                              buyNowLoader && 
                              (btnIndex === index) &&
                              type === "subproduct"
                            } 
                            className={classes.btnBuyNow} 
                            onClick={() => buyNow(item?._id, index, "subproduct")}>
                            {(buyNowLoader && 
                              (btnIndex === index) &&
                              type === "subproduct") ? <LoaderIcon /> : 'Buy Now'}
                          </Button>
                        </div>
                      </div>
                      :
                      <Button 
                        className={classes.applyNowButton}
                        onClick={() => applyNowForm(item?._id, "subProduct")}
                      >
                        Apply Now
                      </Button>}
                    </div>
                  </div>
                </div>
                <p className="admission-descrption-desk">
                  Lorem ipsum dolor sit amet consectetur. Posuere egestas ut in sed nec.Lorem ipsum dolor 
                  sit amet consectetur. Posuere egestas ut in sed nec.
                </p>
              </div>))}
            </div>}
            {(addonsByProductId && addonsByProductId?.length > 0) &&
            <div style={{
              margin: '12px 0'
            }}>
              <Typography sx={{
                display: 'flex',
                alignItems: 'center',
              }} className="course-name"  gutterBottom>
                Addons 
              </Typography>
              <div style={{marginTop: '12px'}}>
                {addonsByProductId?.map((item, index) => (
                <div className="addons-box" key={index}>
                  <div className="addons-image">
                    {item?.images_url[0] &&
                      <img src={item?.images_url[0]} 
                      style={{
                        borderRadius: '5px',
                        width: '100%',
                      }} 
                      alt="addonsImage" />}
                  </div>
                  <div className="addons-details">
                    <p style={{
                      fontSize: '18px',
                      fontWeight: 'bold',
                      margin: 0
                    }}>{item?.name}{item?.author_name ? ` by ${item?.author_name}` : ''}</p>
                    <p style={{
                      margin: '0',
                      fontSize: '14px',
                      color: 'var(--primary-color)',
                      fontWeight: 'bold',
                    }}>
                      <span>
                        {item?.product_type}{item?.course_name ? ` | ${item?.course_name}` : ''}
                      </span>
                    </p>
                    <p style={{
                      margin: '0',
                      fontSize: '14px',
                      }}>
                      Lorem ipsum dolor sit amet consectetur. Sollicitudin sed elementum dolor diam id neque.
                      Lorem ipsum dolor sit amet consectetur. Sollicitudin 
                    </p>
                  </div>
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                    <div style={{
                      textAlign: 'right'
                    }}>
                      <p style={{
                        margin: 0,
                        color: '#07AB35',
                        fontWeight: 'bold'
                      }} className="addon-price">
                        Rs {item?.price - (item?.discount > 0 ? 
                        (item?.is_percentage_discount ? 
                          parseInt((item?.price/100)*item?.discount) : 
                          item?.discount) : 0) + (item?.gst > 0 ? 
                            parseInt((item?.price/100)*item?.gst) : 0)} 
                      </p>
                      <del style={{
                        margin: 0,
                        color: 'rgb(0, 0, 0, 0.5)',
                      }}>Rs {item?.price}</del>
                      <p style={{
                        margin: 0,
                        fontSize: '10px',
                        color: 'rgb(0, 0, 0, 0.5)',
                        fontWeight: 'bold'
                      }}>
                        {item?.gst > 0 ? `inc. ${item?.gst}% GST` : 'GST not applicable'}
                      </p>
                    </div>
                    {item?.payment_procedure === "full_process" ?
                      <Button 
                        sx={{
                          margin: '0 0 0 24px',
                        }}
                        className={classes.applyNowButton}
                        onClick={() => applyNowForm(item?._id, "addons")}>
                        Apply Now
                      </Button>
                      :
                      <>
                        <div>
                          <Button 
                            variant="outlined" 
                            className={classes.addToCart}
                            sx={{
                              margin: '0 12px 0 24px',
                            }}
                            disabled={
                              btnDetailLoader && 
                              (btnIndex === index) &&
                              type === "addons"
                            }
                            onClick={() => addItemToCart(
                              item?._id, 
                              index, 
                              "addons"
                              )}>
                              {(btnDetailLoader && 
                                (btnIndex === index) &&
                                type === "addons") ? <LoaderIcon /> : 'Add To Cart'}
                          </Button>
                        </div>
                        <div>
                          <Button 
                            variant="contained" 
                            disabled={
                              buyNowLoader && 
                              (btnIndex === index) &&
                              type === "addons"
                            }
                            className={classes.btnBuyNow}
                            onClick={() => buyNow(item?._id, index, "addons")}>
                            {(buyNowLoader && 
                              (btnIndex === index) &&
                              type === "addons") ? <LoaderIcon /> : 'Buy Now'}
                          </Button>
                        </div>
                      </>
                    }

                  </div>
                </div>
                ))}
              </div>
            </div>}
          </div>
          }
      </TabPanel>
      <TabPanel value={value} index={1} className="minHeight">
        <div className="info-tab-panel">
          <div className="about">
            About Coaching
            {instituteDataById?.placement_records?.brochure && 
            <a href={instituteDataById?.placement_records?.brochure} 
              className="broch-download-btn" target="_blank" rel="noreferrer">
              <DownloadIcon fontSize="small" /> Brochure
            </a>}
          </div>
          <div className="detail">
            {instituteDataById?.information?.about?.college && parse(instituteDataById?.information?.about?.college)}
          </div>
          
          {/* <div className="about-infra">Infrastructure</div>
          <div className="detail">
            {instituteDataById?.information?.about?.infrastructure && parse(instituteDataById?.information?.about?.infrastructure)}
          </div> */}
          <div className="about">Why Join Us?</div>
          <div className="detail">
            {instituteDataById?.information?.about?.why_join_us && parse(instituteDataById?.information?.about?.why_join_us)}
          </div>
          {instituteDataById?.courses_offered?.fee_structure &&
            <>
              <div className="about">Fees Structure for courses</div>
              <div className="table-one">
                <TableContainer component={Paper} className="table-container">
                  <Table aria-label="customized table" sx={{borderCollapse:'none',border:'1px solid rgba(0, 0, 0, 0.2)'}}>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="center" sx={{backgroundColor:'rgba(103, 47, 143, 0.3)'}}>Courses</StyledTableCell>
                        <StyledTableCell align="center" sx={{backgroundColor:'rgba(103, 47, 143, 0.6)'}}>Year 1</StyledTableCell>
                        <StyledTableCell align="center" sx={{backgroundColor:'rgba(103, 47, 143, 0.6)'}}>Year 2</StyledTableCell>
                        <StyledTableCell align="center" sx={{backgroundColor:'rgba(103, 47, 143, 0.6)'}}>Year 3</StyledTableCell>
                        <StyledTableCell align="center" sx={{backgroundColor:'rgba(103, 47, 143, 0.6)'}}>Year 4</StyledTableCell>
                        <StyledTableCell align="center" sx={{backgroundColor:'rgba(103, 47, 143, 0.6)'}}>Year 5</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                        <StyledTableRow>
                          <StyledTableCell 
                            sx={{textTransform: 'capitalize'}}
                            component="th" 
                            scope="row" 
                            align="center" 
                            className="row-cell">
                              {instituteDataById?.courses_offered?.courses[0] ?
                              instituteDataById?.courses_offered?.courses[0]
                              : course1[0]}
                          </StyledTableCell>
                          {course1.map((item, index) => (
                            index > 0 && 
                              <StyledTableCell 
                                component="th" 
                                scope="row" 
                                align="center" 
                                key={index} 
                                className="row-cell">
                              {item > 0 ? `Rs ${item} Lakhs` : '' }
                            </StyledTableCell>))}
                        </StyledTableRow>
                        <StyledTableRow>
                          <StyledTableCell 
                            component="th" 
                            scope="row" 
                            align="center" 
                            sx={{textTransform: 'capitalize'}}
                            className="row-cell">
                              {instituteDataById?.courses_offered?.courses[1] ? 
                              instituteDataById?.courses_offered?.courses[1] :
                              course2[0]}
                          </StyledTableCell>
                          {course2.map((item, index) => (
                            index > 0 && 
                            <StyledTableCell 
                              component="th" 
                              scope="row" 
                              align="center" 
                              key={index} 
                              className="row-cell">
                              {item > 0 ? `Rs ${item} Lakhs` : '' }
                            </StyledTableCell>))}
                        </StyledTableRow>
                        <StyledTableRow>
                          <StyledTableCell 
                            component="th" 
                            scope="row" 
                            align="center"
                            sx={{textTransform: 'capitalize'}} 
                            className="row-cell">
                              {instituteDataById?.courses_offered?.courses[2] ?
                              instituteDataById?.courses_offered?.courses[2] :
                              course3[0]}
                          </StyledTableCell>
                          {course3.map((item, index) => (
                            index > 0 && 
                              <StyledTableCell 
                                component="th" 
                                scope="row" 
                                align="center" 
                                key={index} 
                                className="row-cell">
                                  {item > 0 ? `Rs ${item} Lakhs` : '' }
                            </StyledTableCell>))}
                        </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </>
          }
          {(instituteDataById?.faculties_details && 
          instituteDataById?.faculties_details?.length > 0) &&
            <>
              <div className="about">Top Faculties</div>
              <div style={{
                display: 'flex',
                justifyContent: 'center'
              }}>
                <div style={{
                  display: 'inline-block',
                  marginTop: '12px'
                }}>
                  {instituteDataById?.faculties_details.map((item, index) => (
                    <div key={index} style={{
                      display: 'inline-block',
                      float: 'left',
                      margin: '12px'
                    }}>
                      <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center'

                      }}>
                        <div style={{
                          width: '66px',
                          height: '66px',
                          borderRadius: '50%',
                          overflow: 'hidden',
                          border: '1px solid rgb(0, 0, 0, 0.3)'
                        }}>
                          <img src={item?.profile_picture} alt="profileImage" style={{
                            width: '100%',
                          }} />
                        </div>
                        <p style={{
                          margin: '8px 0 4px 0',
                          textAlign: 'center',
                          fontWeight: 'bold',
                          fontSize: '14px'
                        }}>{item?.name}</p>
                        <p style={{
                          marginTop: '0',
                          textAlign: 'center',
                          fontSize: '14px',
                          color: '#07AB35',
                          fontWeight: 'bold'
                        }}>{item?.experience_years} Years Of Experience</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </>
          }
        </div>
      </TabPanel>
      
      {location?.pathname === "/university-detail" && 
      <TabPanel value={value} index={2} className="minHeight" sx={{
        display: 'flex',
        justifyContent: 'center',
      }}>
        {instituteDataById?.placement_records?.record?.length > 0 &&
        instituteDataById?.placement_records?.record?.map((item, i) => (
        <div className="text-one" key={i}>
          <p>
            <span style={{fontWeight: 'bold', color: '#000'}}>Year: </span><span style={{
              fontSize: '17px', color: 'rgb(0, 0, 0, 0.5)',}}>{item?.year}</span>
          </p>
          <p>
            {item?.description}
          </p>
          {item?.logo && item?.logo?.length > 0 &&
          <div className="brand-logo" align="center">
            {item?.logo?.map((logoUrl, j) => (
              <img src={logoUrl} alt="Logo" className="logo-brand" key={`${i}${j}`}/>
            ))}
          </div>}
        </div>
        ))}
        {/* <div align="center"className="load-more">
          <Button variant="outlined" className="btn-load">Load More</Button>
        </div> */}
      </TabPanel>}
      <TabPanel value={value} className="minHeight"
        index={location?.pathname === "/university-detail" ? 3 : 2}>
        <div>
          <div className="review-detail">
              <Typography>
                  <p className="institute-name">Student Feedback</p>
              </Typography>
              <div className="ratings">
                <div className="rating-number" align="center">
                  <Typography variant="h4"
                    sx={{color:"var(--secondary-color)",fontWeight: 'bold', marginTop:"12px", fontSize:"45px"}}>
                      {parseInt(instituteDataById?.rating)}
                  </Typography>
                  <Rating 
                    name="read-only" 
                    value={parseInt(instituteDataById?.rating)} 
                    sx={{color:"var(--secondary-color)"}} 
                    readOnly />
                  <Typography sx={{color:"rgba(0, 0, 0, 0.5)", fontWeight: 'bold', fontSize: '18px'}} className="star-text">
                    ({instituteDataById?.reviews_count})
                  </Typography>
                  <Typography sx={{color:"rgba(0, 0, 0, 0.5)", fontWeight: 'bold', fontSize: '18px'}} className="star-text">
                    {location?.pathname === "/university-detail" ? "University Rating" : "Coaching Rating"}
                  </Typography>
                </div>
                <div className="rating-bar">
                  {starData.map((item, index) => (
                  <div style={{display:'flex', alignItems: 'center', height: '18px'}} 
                    className="star-box" 
                    key={index}>
                    <p className="star-text">{item.starCount} Stars</p>
                    <div style={{display:'flex', alignItems: 'center'}}>
                      <div className="preogress-star">
                        <div style={{
                          height: '100%',
                          width: `${reviewStats && calculatePercent(reviewStats, item?.starCount)}%`,
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          background: 'var(--secondary-color)',
                          borderRadius: '10px'
                        }}></div>
                      </div>
                      <p>
                        {reviewStats && calculatePercent(reviewStats, item?.starCount)}%
                      </p>
                    </div>
                  </div>))}
                </div>
              </div>
              {(review && review?.length > 0) &&
              <div className="what-student-say-about">
                <Typography align="center" >
                  <p className="what-student-say">What student say about us?</p> 
                </Typography>
                <NewCarousel reviewBreakPoints={reviewBreakPoints} data={review} />
              </div>}
              {userData &&
                <>
                  <div className="review">
                    <Typography>
                      <p className="institute-name">Add a Review</p>
                    </Typography>
                    <Box
                      className="rating-box"
                        sx={{
                          '& > legend': { mt: 2 },
                        }}
                      >
                      <Rating
                        className="rating-controlled"
                        name="rating"
                        value={Number(reviewData?.rating)}
                        onChange={handleInputChange}
                      />
                    </Box>
                  </div>
                  <div>
                    <form className="add-review">
                      <div className="form-field">
                        <div className="field-name">
                          <InputLabel className="input-label-text">Name</InputLabel>
                            <TextField 
                              placeholder="" 
                              type="text" 
                              variant="outlined" 
                              name="name"
                              className="name" 
                              value={reviewData?.name}
                              onChange={handleInputChange}
                              required/>
                        </div>
                        <div className="field-name">
                          <InputLabel className="input-label-text">Email Id</InputLabel>
                            <TextField 
                            placeholder="" 
                            type="email" 
                            variant="outlined" 
                            name="email" 
                            className="email"
                            value={reviewData?.email}
                            onChange={handleInputChange}
                            required/>
                        </div>
                      </div>
                      <div className="field-name">
                        <InputLabel className="input-label-text">Review</InputLabel>
                        <TextField 
                          placeholder="Write Message" 
                          type="text" 
                          variant="outlined" 
                          name="message" 
                          className="message"
                          value={reviewData?.message}
                          onChange={handleInputChange}
                          disabled={reviewData?.video_url}
                          rows={4}
                          multiline
                          required/>
                          <p style={{
                            fontSize: '12px'
                          }}><span style={{color: '#EF4840'}}>*</span>
                          You can't write review or upload video at same time</p>
                      </div>
                      <div className="review-upload-box">
                        <div className="field-name">
                          <InputLabel className="input-label-text">Upload Video</InputLabel>
                          <UploadBox
                            name={'video'}
                            height={'190px'}
                            width={'216px'}
                            isDisabled={reviewData?.message}
                            value={reviewData?.video_url}
                            handleUpload={(name, value) => handleUploadChange(name, value)} />
                        </div>
                        <div className="field-name review-upload-image">
                          <InputLabel className="input-label-text">Upload Image</InputLabel>
                          <UploadBox
                            name={'image'}
                            height={'190px'}
                            width={'216px'}
                            value={reviewData?.image_url}
                            handleUpload={(name, value) => handleUploadChange(name, value)} />
                        </div>
                      </div>
                      <div style={{marginTop:'25px'}} className="field-name">
                        <Button onClick={submitReview}
                          variant="contained" 
                          className="submit-review"
                          disabled={buttonLoader}
                          sx={{
                            width: '130px',
                            height: '40px',
                            backgroundColor: buttonLoader ? 'var(--disable-color)' : 'var(--secondary-color)',
                            "&:hover":{
                              backgroundColor: 'var(--secondary-color)',
                            }
                          }}>
                            {buttonLoader ? <LoaderIcon /> : "Submit Review"}
                            </Button>
                      </div>
                    </form>
                  </div>
                </>
              }
          </div>
        </div>
      </TabPanel>
      <TabPanel value={value} className="minHeight"
        index={location?.pathname === "/university-detail" ? 4 : 3}>
        <div>
          <div className="gallery-aria">
            <Typography>
              <p className="institute-name">
                {instituteDataById?.university_name || instituteDataById?.coaching_name}
              </p>
            </Typography>
            <div className="gallery-image">
              {instituteDataById?.uploads?.photo_gallery?.length && instituteDataById?.uploads?.photo_gallery?.map((item, index) => (
                <img 
                  src={item} 
                  alt="" 
                  onClick={() => openPhoto(item)}
                  className="college-image" 
                  key={index}/>
              ))}
            </div>
          </div>
        </div>
      </TabPanel>
      <TabPanel value={value} className="minHeight"
        index={location?.pathname === "/university-detail" ? 5 : 4}>
        Notice & Announcements
      </TabPanel>
    </div>
  </>
  );
}

