import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import productService from "./productService";

const initialState = {
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
    addProductRes: null,
    productData: null,
    productById: null,
    totalCount: 0,
    updateProdRes: null,
    deleteProdRes: null,
    courseData: null,
    courseById: null,
    contentData: null,
    contentById: null,
    actionType: null
};

export const addProduct = createAsyncThunk(
  "product/addProduct",
  async (params, thunkAPI) => {
    try {
      return await productService.addProduct(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getProduct = createAsyncThunk(
  "product/getProduct",
  async (params, thunkAPI) => {
    try {
      return await productService.getProduct(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getCourses = createAsyncThunk(
  "product/getCourses",
  async (params, thunkAPI) => {
    try {
      return await productService.getCourses(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getCourseById = createAsyncThunk(
  "product/getCourseById",
  async (params, thunkAPI) => {
    try {
      return await productService.getCourseById(params);
    } catch (error) {
      const message = (
          error.response && 
          error.response.data && 
          error.response.data.message) ||
          error.message ||
          error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
)

export const updateCourse = createAsyncThunk(
  "product/updateCourse",
  async (params, thunkAPI) => {
    try {
      return await productService.updateCourse(params);
    } catch (error) {
      const message = (
          error.response && 
          error.response.data && 
          error.response.data.message) ||
          error.message ||
          error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
)

export const deleteCourse = createAsyncThunk(
  "product/deleteCourse",
  async (params, thunkAPI) => {
    try {
      return await productService.deleteCourse(params);
    } catch (error) {
      const message = (
          error.response && 
          error.response.data && 
          error.response.data.message) ||
          error.message ||
          error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
)

export const getProductById = createAsyncThunk(
  "product/getProductById",
  async (params, thunkAPI) => {
    try {
      return await productService.getProductById(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateProductById = createAsyncThunk(
  "product/updateProductById",
  async (params, thunkAPI) => {
    try {
      return await productService.updateProductById(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteProduct = createAsyncThunk(
  "product/deleteProduct",
  async (params, thunkAPI) => {
    try {
      return await productService.deleteProduct(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const addContent = createAsyncThunk(
  "product/addContent", 
  async(params, thunkAPI) => {
      try{
          return await productService.addContent(params)
      }catch(error){
          const message = (
              error.response && 
              error.response.data && 
              error.response.data.message) ||
              error.message ||
              error.toString();
          return thunkAPI.rejectWithValue(message);
      }
  }
);

export const getContent = createAsyncThunk(
  "product/getContent", 
  async(params, thunkAPI) => {
      try{
          return await productService.getContent(params)
      }catch(error){
          const message = (
              error.response && 
              error.response.data && 
              error.response.data.message) ||
              error.message ||
              error.toString();
          return thunkAPI.rejectWithValue(message);
      }
  }
);

export const getContentById = createAsyncThunk(
  "product/getContentById", 
  async(params, thunkAPI) => {
      try{
          return await productService.getContentById(params)
      }catch(error){
          const message = (
              error.response && 
              error.response.data && 
              error.response.data.message) ||
              error.message ||
              error.toString();
          return thunkAPI.rejectWithValue(message);
      }
  }
);

export const updateContent = createAsyncThunk(
  "product/updateContent", 
  async(params, thunkAPI) => {
      try{
          return await productService.updateContent(params)
      }catch(error){
          const message = (
              error.response && 
              error.response.data && 
              error.response.data.message) ||
              error.message ||
              error.toString();
          return thunkAPI.rejectWithValue(message);
      }
  }
);

export const deleteContent = createAsyncThunk(
  "product/deleteContent", 
  async(params, thunkAPI) => {
      try{
          return await productService.deleteContent(params)
      }catch(error){
          const message = (
              error.response && 
              error.response.data && 
              error.response.data.message) ||
              error.message ||
              error.toString();
          return thunkAPI.rejectWithValue(message);
      }
  }
);

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    setProductsFilterDetails: (state, action) => {
      state.searchText = action.payload;
    },
    setProductDetailsById: (state, action) => {
      state.productById = action.payload;
    },
    setActionType: (state, action) => {
      state.actionType = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(addProduct.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addProduct.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(addProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.addProductRes = action.payload;
      })
      .addCase(getProduct.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProduct.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.productData = action.payload.data.result;
        state.totalCount = action.payload.data.totalCount;
      })
      .addCase(getProductById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProductById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getProductById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.productById = action.payload.data;
      })
      .addCase(updateProductById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateProductById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateProductById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.updateProdRes = action.payload.data;
      })
      .addCase(deleteProduct.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteProduct.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deleteProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.deleteProdRes = action.payload.data;
      })
      .addCase(getCourses.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCourses.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getCourses.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.totalCount = action.payload.data.totalCount;
        state.courseData = action.payload.data.result;
      })
      .addCase(getCourseById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCourseById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getCourseById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.message = action.payload.message;
        state.courseById = action.payload.data;
      })
      .addCase(updateCourse.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateCourse.rejected, (state, action) => {
          state.isLoading = false;
          state.isError = true;
          state.message = action.payload;
      })
      .addCase(updateCourse.fulfilled, (state, action) => {
          state.isLoading = false;
          state.isError = false;
          state.isSuccess = true;
          state.message = action.payload.message;
      })
      .addCase(deleteCourse.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteCourse.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deleteCourse.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.message = action.payload.message;
      })
      .addCase(addContent.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addContent.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(addContent.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.message = action.payload;
      })
      .addCase(getContent.pending, (state) => {
          state.isLoading = true;
      })
      .addCase(getContent.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getContent.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.message = action.payload;
        state.totalCount = action.payload.data.totalCount;
        state.contentData = action.payload.data.result;
      })
      .addCase(getContentById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getContentById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getContentById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.message = action.payload;
        state.contentById = action.payload.data;
      })
      .addCase(updateContent.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateContent.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateContent.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.message = action.payload;
      })
      .addCase(deleteContent.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteContent.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deleteContent.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.message = action.payload;
      })
  },
});

export const { 
  setActionType,
  setProductsFilterDetails,
  setProductDetailsById } = productSlice.actions;

export default productSlice.reducer;
