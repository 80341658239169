import * as React from 'react';
import Carousel from 'react-elastic-carousel';
import Rating from '@mui/material/Rating';
import "../instituteCard/instituteCard.css";
import { useState } from 'react';
import "./newCarousel.css"

const NewCarousel = (props) => {
    const [playUrl, setPlayUrl] = useState("");
    const [play, setPlay] = useState(false);
    const [urlIndex, setUrlIndex] = useState();

    const playVideo = (url, index) => {
        setPlay(!play);
        setPlayUrl(play ? url : `${url}?autoplay=1`);
        setUrlIndex(index);
    }
    
    return (
        <>
            <Carousel 
                breakPoints={props.reviewBreakPoints} 
                className="new-carousel">
                {props.data.map((item, index) => (
                    !item?.video_url ? 
                    <div key={index} className="review-box">
                        <div style={{
                            margin: '8px 8px 0',
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            alignItems: 'center'
                        }}>
                            <div style={{
                                height: '60px',
                                width: '60px',
                                borderRadius: '50%',
                                marginBottom: '8px',
                                backgroundColor: 'rgba(0, 0, 0, 0.2)'
                            }}>
                                {(item?.image_url || item?.student_image) &&
                                    <img 
                                        src={item?.image_url || item?.student_image} 
                                        alt="icon" 
                                        style={{
                                            height: '100%',
                                            width: '100%',
                                            borderRadius: '50%'
                                        }} 
                                    />
                                }
                            </div>
                            {item?.rating &&
                            <Rating
                                name="simple-controlled"
                                value={item?.rating}
                                readOnly
                                size="small"
                                // onChange={(event, newValue) => {
                                // setValue(newValue);
                                // }}
                            />}
                            <div style={{
                                height: '150px',
                                overflowY: 'auto'
                            }}>
                                <p style={{
                                    color: 'rgb(0, 0, 0, 0.5)',
                                    fontSize: '14px',
                                    textAlign: 'center',
                                    lineHeight: '26px',
                                }}>
                                    {item?.message || item?.review}
                                </p>
                            </div>
                            <p style={{
                                fontSize: '14px',
                                fontWeight: 'bold',
                                marginBottom: '4px'
                            }}>{item?.name || item?.student_name}</p>
                            <p style={{
                                color: 'rgb(0, 0, 0, 0.5)',
                                fontSize: '14px',
                                marginTop: '4px',
                                textAlign: 'center',
                            }}>
                                {item?.university_id?.university_name ||
                                item?.coaching_id?.coaching_name ||
                                item?.university_name}
                            </p>
                        </div>
                    </div>:
                    <div key={index} className="review-box" style={{
                        padding: 0,
                        position: 'relative',
                        overflow: 'hidden',
                    }}>
                        {/* <video src={item?.video_url} width="100%" height="100%" controls>
                        </video> */}
                        {/* <video class="embed-responsive-item" controls>
                            <source src="assets/Video/yourvideoname.mp4" type="video/mp4">
                        </video> */}
                        <iframe 
                            style={{
                                borderRadius: '5px'
                            }}
                            id={`video_${index}`}
                            width="100%" 
                            height="100%" 
                            src={(playUrl && (urlIndex === index)) ? playUrl : item?.video_url} 
                            title="Review Video" 
                            frameborder="0" 
                            allow="accelerometer;fullscreen; autoplay=0; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share">
                        </iframe>
                        {/* <Button style={{
                            position: "absolute",
                            top: '40%',
                            left: '40%',
                        }} onClick={() => playVideo(item?.video_url, index)}>
                            {(!play && (urlIndex === index)) && <img src={VideoPlay} alt="videoPlayIcon" />}
                        </Button> */}
                    </div>
                ))}
            </Carousel>
        </>
    )
}

export default NewCarousel;