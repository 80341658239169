import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Typography,
  TextField,
  InputLabel,
} from "@mui/material";
import routes from "../../../react_routes_enum";
import { makeStyles } from "@mui/styles";
import HeaderBackground from "../../../assest/images/header-background.jpg";
import HeaderIcon from "../../../assest/images/header-icon.png";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import { useNavigate } from "react-router-dom";
import { updateData, 
    getPartnerByToken,
    getUniversityByToken, 
    getCoachingByToken } from "../../../redux/requestForm/requestFormSlice";
import { toast } from "react-toastify";
import LoaderIcon from "../../../components/Loader/Loader";
import { validateUrl } from "../../../utils/validateUrl";
import UploadBox from '../../../components/UploadBox/uploadBox';
import { logout, reset } from "../../../redux/auth/authSlice";

const useStyle = makeStyles({
    dot: {
        height: '16px',
        width: '16px',
        borderRadius: '50%',
    },
    line: {
        height: '4px',
        backgroundColor: 'rgb(246, 146, 32, 0.4)'
    },
    changesButton: {
        color: '#fff !important',
        textTransform: 'capitalize !important',
        width: '140px',
        height: '40px',
        fontSize: '16px !important',
        borderRadius: '24px !important',
        boxShadow: '0 2px 5px rgb(0, 0, 0, 0.5)',
        "&:hover":{
            backgroundColor: 'var(--secondary-color) !important',
        }
    },
    cancelButton: {
        backgroundColor: '#fff !important',
        color: 'var(--secondary-color) !important',
        textTransform: 'capitalize !important',
        width: '140px',
        height: '40px',
        fontSize: '16px !important',
        borderRadius: '24px !important',
        borderColor: 'var(--secondary-color) !important',
        "&:hover":{
            backgroundColor: '#fff',
        }
    },
    header: {
        marginTop: '100px',
        marginBottom: '4%',
        backgroundImage: `url(${HeaderBackground})`,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    formIconsBox: {
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        marginTop: '5%',
        marginRight: '14%',
    },
    iconBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    horizontalLine: {
        height: '4px',
        backgroundColor: 'var(--primary-color)',
    },
    iconBox2: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'absolute', 
    },
    iconStyle: {
        backgroundColor: 'var(--primary-color)',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    iconText: {
        color: 'var(--primary-color)',
        marginTop: '8px !important',
    },
    icon: {
        color: '#fff',
    },
    leftBox: {
        height: '200px',
        width: '200px',
        borderRadius: '8px',
        border: '2px dashed rgb(0, 0, 0, 0.5)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    upload: {
        border: '1px solid rgb(0, 0, 0, 0.2) !important',
        fontSize: '15px !important',
        color: 'rgb(0, 0, 0, 0.4) !important',
        textTransform: 'capitalize !important',
        width: '122px',
        height: '34px'
    },
    addMore:{
        height: '36px',
        textTransform: 'capitalize !important',
        fontWeight: 'bold !important',
    },
})

let uploadData = {
    status: "documents",
    documents: {
        id_proof: "",
        address_proof: "",
        pan: "",
        business: "",
        contract_copy: "",
        gst_number: ""
    }
}

export default function RequestRes() {
    const isWebsiteFor = localStorage.getItem("is-website-for");
    const [btnLoader, setBtnLoader] = React.useState(false);
    const { 
        isLoading, 
        isError,
        partnerByToken,
        universityByToken,
        coachingByToken,
        message } = useSelector((state) => state.form);
    const navigate = useNavigate();
    const classes = useStyle();
    const dispatch = useDispatch();
    const [uploadInfo, setUploadInfo] = React.useState(uploadData)
    // const [uploadInfo, setUploadInfo] = React.useState((coachingByToken?.data?.uploads || universityByToken?.data?.uploads) ?
    //     {
    //         uploads_check: 1,
    //         uploads: isWebsiteFor === "coaching" ? universityByToken?.data?.uploads : universityByToken?.data?.uploads
    //     } : uploadData);
    let uploadDataInfo = coachingByToken?.data?.uploads_check ? coachingByToken?.data?.uploads : universityByToken?.data?.uploads

    // eslint-disable-next-line react-hooks/rules-of-hooks
    // const [uploadInfo, setUploadInfo] = React.useState((coachingByToken?.data?.uploads_check || universityByToken?.data?.uploads_check) ?
    //     {
    //         uploads_check: 1, 
    //         uploads: (coachingByToken?.data?.uploads_check) ? (coachingByToken?.data?.uploads) : (universityByToken?.data?.uploads),
    //     }
    //     :
    //     uploadData
    // );
    
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [gallaryPhotoNumber, setGallaryPhotoNumber] = React.useState((coachingByToken?.data?.uploads_check || 
        universityByToken?.data?.uploads_check) ? (coachingByToken?.data?.uploads_check ? coachingByToken?.data?.uploads?.photo_gallery : 
            universityByToken?.data?.uploads?.photo_gallery) : ['']);

    const cancelClicked = () => {
        setBtnLoader(false);
        dispatch(logout());
        dispatch(reset());
        navigate("/login");
    }

    const saveAndNext = () => {
        let filtered = {}
        let body = {}
        if(uploadInfo.documents.id_proof === ""){
            return toast.error("Id Proof Required");
        }
        if(uploadInfo.documents.address_proof === ""){
            return toast.error("Address Proof Required");
        }
        if(uploadInfo.documents.pan === ""){
            return toast.error("PAN Card Required");
        }
        if(uploadInfo?.documents?.gst_number !== "" && 
            uploadInfo?.documents?.gst_number?.length !== 15){
            return toast.error("Invalid GST Number Entered");
        }
        for (const key in uploadInfo?.documents) {
            if (uploadInfo?.documents?.hasOwnProperty(key) && 
                uploadInfo?.documents[key] !== null && 
                uploadInfo?.documents[key] !== undefined && 
                uploadInfo?.documents[key] !== '') {
                    filtered[key] = uploadInfo?.documents[key];
            }
        }
        body = {
            status: uploadInfo?.status,
            documents: filtered
        }
        setBtnLoader(true);
        let res = dispatch(updateData({isWebsiteFor, body}));
        res.then((r) => {
            setBtnLoader(false);
            if(r.payload.statusCode === 200){
                toast.success(r.payload.message);
                dispatch(getPartnerByToken());
                navigate(routes.PARTNER_REGISTRATION_RESPONSE);
            }else{
                toast.error(r.payload.message);
            }
        })
    }
    // eslint-disable-next-line react-hooks/rules-of-hooks
    React.useEffect(() => {
        const res = dispatch(getPartnerByToken());
        res.then((r) => {
            if(r?.payload?.statusCode === 200){
                if(r?.payload?.data?.documents){
                    setUploadInfo({
                        status: "documents",
                        documents: {
                            id_proof: r?.payload?.data?.documents?.id_proof,
                            address_proof: r?.payload?.data?.documents?.address_proof,
                            pan: r?.payload?.data?.documents?.pan,
                            business: r?.payload?.data?.documents?.business,
                            contract_copy: r?.payload?.data?.documents?.contract_copy,
                            gst_number: r?.payload?.data?.documents?.gst_number
                        }
                    })
                }
            }
        })
    },[])
    
    const handleImageUploads = (name, value) => {
        name === "id_proof" && setUploadInfo({...uploadInfo, 
            documents: {...uploadInfo.documents, id_proof: value}})

        name === "address_proof" && setUploadInfo({...uploadInfo, 
            documents: {...uploadInfo.documents, address_proof: value}})
            
        name === "pan" && setUploadInfo({...uploadInfo, 
            documents: {...uploadInfo.documents, pan: value}})

        name === "business" && setUploadInfo({...uploadInfo, 
            documents: {...uploadInfo.documents, business: value}})
        
        name === "contract_copy" && setUploadInfo({...uploadInfo, 
            documents: {...uploadInfo.documents, contract_copy: value}})
    }

    const handleChange = (e) => {
        setUploadInfo({...uploadInfo,
            documents: {...uploadInfo.documents, gst_number: e.target.value}})
    }

    return (
        <Box>
            <section className={classes.header + ' header-set'}>
                <div className="header-icon-box">
                    <img src={HeaderIcon} alt="header-icon" style={{width: '100%'}} />
                </div>
                <Typography component="h2" variant="h2" className="header-heading">
                    PARTNER'S ONBOARDING
                </Typography>
            </section>
            <Box sx={{p: 3}}>
                <section>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginBottom: '8px'
                        }} className="progr-lin-box">
                            <div className={classes.dot} style={{
                                background: 'none',
                                }}>
                                <CheckCircleOutlineIcon sx={{width: '100%', color: '#07AB35'}} />
                            </div>
                            <div className={classes.line + ' progress-line'} style={{background: 'none'}}>

                            </div>
                            <div className={classes.dot} style={{
                                background: 'none',
                                visibility: (universityByToken?.data?.information_check && universityByToken?.data?.uploads_check &&
                                    universityByToken?.data?.courses_offered_check && universityByToken?.data?.placement_records_check
                                    ) ? 
                                    'visible' : 'hidden'
                                }}>
                                <CheckCircleOutlineIcon sx={{
                                    width: '100%', 
                                    color: '#07AB35',
                                    }} />
                            </div>
                            <div className={classes.line + ' progress-line'} style={{background: 'none'}}>

                            </div>
                            <div className={classes.dot} style={{
                                background: 'none',
                                visibility: (universityByToken?.data?.information_check && universityByToken?.data?.uploads_check &&
                                    universityByToken?.data?.courses_offered_check && universityByToken?.data?.placement_records_check
                                    && universityByToken?.data?.request_status === "accepted") ? 
                                    'visible' : 'hidden'
                                }}> 
                                <CheckCircleOutlineIcon sx={{width: '100%', color: '#07AB35'}} />
                            </div>
                        </div>
                    </div>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }} className="progr-lin-box">
                            <div className={classes.dot} style={{backgroundColor: 'var(--secondary-color)'}}>
                                
                            </div>
                            <div className={classes.line + ' progress-line'} style={{
                                backgroundColor: (universityByToken?.data?.information_check && 
                                    universityByToken?.data?.uploads_check &&
                                    universityByToken?.data?.courses_offered_check && 
                                    universityByToken?.data?.placement_records_check) ? 'var(--secondary-color)' :
                                    'rgb(246, 146, 32, 0.4)'
                            }}>

                            </div>
                            <div className={classes.dot} style={{backgroundColor: (universityByToken?.data?.information_check && 
                                    universityByToken?.data?.uploads_check &&
                                    universityByToken?.data?.courses_offered_check && 
                                    universityByToken?.data?.placement_records_check) ? 'var(--secondary-color)' :
                                    'rgb(246, 146, 32, 0.4)'}}>

                            </div>
                            <div className={classes.line + ' progress-line'} style={{
                                backgroundColor: (universityByToken?.data?.information_check && 
                                    universityByToken?.data?.uploads_check &&
                                    universityByToken?.data?.courses_offered_check && 
                                    universityByToken?.data?.placement_records_check &&
                                    universityByToken?.data?.request_status === "accepted") ? 'var(--secondary-color)' :
                                    'rgb(246, 146, 32, 0.4)'
                            }}>

                            </div>
                            <div className={classes.dot} style={{backgroundColor: (universityByToken?.data?.information_check && 
                                    universityByToken?.data?.uploads_check &&
                                    universityByToken?.data?.courses_offered_check && 
                                    universityByToken?.data?.placement_records_check &&
                                    universityByToken?.data?.request_status === "accepted") ? 'var(--secondary-color)' :
                                    'rgb(246, 146, 32, 0.4)'}}>
                            </div>
                        </div>
                    </div>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '12px'
                    }}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            justifyContent: 'space-between',
                        }} className="progr-text-box">
                            <Typography component="h2" variant="h2" className="progr-text">
                                Fill the Request Form
                            </Typography>
                            <Typography component="h2" variant="h2" className="progr-text">
                                Upload the documents
                            </Typography>
                            <Typography component="h2" variant="h2" className="progr-text">
                                Success
                            </Typography>
                        </div>
                    </div>
                </section>
                <section>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    }} className="request-main-res-box">
                        <div style={{
                            width: '100%',
                        }}>
                            <div style={{
                                marginTop: '20px'
                            }} className="upload-text-box">
                                <Typography 
                                    component="h2" 
                                    variant="h2" 
                                    className="upload-doc-text">
                                    Upload Documents
                                </Typography>
                                <Typography component="p" variant="p" className="upload-doc-small-text" 
                                    sx={{
                                        fontSize: '14px',
                                        color: 'rgb(0, 0, 0, 0.5)'
                                        }}>
                                    Upload the following documents in the mention format.
                                </Typography>
                            </div>
                            <div className="upload-aria">
                                <div style={{
                                    width: '100%'
                                }} className="upload-id-box">
                                    <div style={{
                                        display: 'inline-block',
                                    }}>
                                        <Typography component="h2" variant="h2" className="input-label-text">
                                            1. Upload ID Proof
                                        </Typography>
                                        <div style={{
                                            margin: '5% 0 5% 12%'
                                        }}>
                                            <UploadBox
                                                name="id_proof"
                                                value={uploadInfo?.documents?.id_proof}
                                                handleUpload={(name, value) => handleImageUploads(name, value)} 
                                            />
                                        </div>
                                    </div>
                                    <div style={{
                                        display: 'inline-block',
                                    }} className="address-proof">
                                        <Typography component="h2" variant="h2" className="input-label-text">
                                            2. Upload Address Proof
                                        </Typography>
                                        <div style={{
                                            margin: '5% 0 5% 12%'
                                        }}>
                                            <UploadBox
                                                name="address_proof"
                                                value={uploadInfo?.documents?.address_proof}
                                                handleUpload={(name, value) => handleImageUploads(name, value)} 
                                            />
                                        </div>
                                    </div>
                                    <div style={{
                                        display: 'inline-block',
                                    }}>
                                        <Typography component="h2" variant="h2" className="input-label-text">
                                            3. Upload PAN Card
                                        </Typography>
                                        <div style={{
                                            margin: '5% 0 5% 12%'
                                        }}>
                                            <UploadBox
                                                name="pan"
                                                value={uploadInfo?.documents?.pan}
                                                handleUpload={(name, value) => handleImageUploads(name, value)} 
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="upload-aria">
                                <div style={{
                                    width: '100%'
                                }} className="upload-id-box">
                                    <div style={{
                                        display: 'inline-block',
                                    }}>
                                        <Typography component="h2" variant="h2" className="input-label-text">
                                            4. Upload company or <br/> 
                                            <span style={{marginLeft: '20px'}}>
                                                business documents
                                            </span><br/>
                                            <span className="optional-text-style"
                                            style={{marginLeft: '20px'}}>
                                                (Optional)
                                            </span>
                                        </Typography>
                                        <div style={{
                                            margin: '5% 0 5% 12%'
                                        }}>
                                            <UploadBox
                                                name="business"
                                                value={uploadInfo?.documents?.business}
                                                handleUpload={(name, value) => handleImageUploads(name, value)} 
                                            />
                                        </div>
                                    </div>
                                    <div style={{
                                        display: 'inline-block',
                                    }} className="address-proof">
                                        <Typography component="h2" variant="h2" className="input-label-text">
                                            5. Copy of contracts with university<br/>
                                            <span className="optional-text-style">
                                                (Optional)
                                            </span>
                                        </Typography>
                                        <div style={{
                                            margin: '5% 0 5% 12%'
                                        }}>
                                            <UploadBox
                                                name="contract_copy"
                                                value={uploadInfo?.documents?.contract_copy}
                                                handleUpload={(name, value) => handleImageUploads(name, value)} 
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="gst_box" style={{
                                width: '100%',
                            }}>
                                <InputLabel className="input-label-text">
                                    GST Number{" "}
                                    <span className="optional-text-style">
                                        (Optional)
                                    </span>
                                </InputLabel>
                                <TextField
                                    placeholder=""
                                    name="gst"
                                    type="text"
                                    variant="outlined"
                                    className="inputShadow"
                                    value={uploadInfo?.documents?.gst_number}
                                    sx={{
                                        width: '100%',
                                        height: '62px',
                                    }}
                                    onChange={handleChange}
                                    required/>
                            </div>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                marginTop: '48px',
                                marginBottom: '52px'
                            }}>
                                <Button className={classes.changesButton} onClick={saveAndNext} disabled={btnLoader} sx={{
                                    backgroundColor: btnLoader ? 'var(--disabled-color)' : (
                                        isWebsiteFor === 'coaching' ? 'var(--tertiary-color)' : 'var(--secondary-color)')
                                }}>
                                    {btnLoader ? <LoaderIcon/> : "Save & Next"}
                                </Button>
                                <Button 
                                    variant="outlined" 
                                    className={classes.cancelButton} 
                                    onClick={cancelClicked}
                                    sx={{
                                        backgroundColor: '#fff',
                                        color: isWebsiteFor === 'coaching' ? 'var(--tertiary-color)' : 'var(--secondary-color)',
                                        borderColor: isWebsiteFor === 'coaching' ? 'var(--tertiary-color)' : 'var(--secondary-color)',
                                        marginLeft: '16px'
                                    }}>
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </div>
                </section>
            </Box>
        </Box>
    );
}
