import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
} from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import routes from "../../react_routes_enum";
import { useNavigate } from "react-router-dom";
import SecondaryHead from "../../components/SecondaryHead/SecondaryHead";
import LoaderIcon from "../../components/Loader/Loader";
import {
    getBlogs, 
    getBlogById} from "../../redux/students/studentSlice";
import Spinner from "../../components/Spinner/Spinner";
import BlogCard from "../../components/BlogCard/BlogCard";
import "./studentsBlog.css";
import { toast } from "react-toastify";


export default function StudentsBlogListing() {
    const [limit, setLimit] = React.useState(10);
    const [offset, setOffset] = React.useState(0);
    const [isViewDetailClicked, setIsViewDetailClicked] = React.useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [buttonLoader, setButtonLoader] = React.useState(false);
    const { 
        isLoading,
        totalCount,
        isError,
        message,
        blogsData } = useSelector((state) => state.student);
    
    React.useEffect(() => {
        const res = dispatch(getBlogs({limit, offset}));
        res?.then((t) => {
            setButtonLoader(false);
        })
    },[limit, offset]);

    const loadMore = () => {
        setButtonLoader(true);
        setLimit(limit+10);
    }
    const viewBlog = (id) => {
        setIsViewDetailClicked(true);
        const res = dispatch(getBlogById({id}));
        res?.then((r) => {
            setIsViewDetailClicked(false)
            if(r?.payload?.statusCode === 200){
                navigate(routes.STUDENTS_BLOG_DETAIL);
            }else{
                toast.error(r?.payload?.message)
            }
        })
    }
    if (isLoading && isViewDetailClicked) return <Spinner />;
    if (isError) return <h1>{message}</h1>;
    return (
        <>
            <SecondaryHead heading={'Top Universities'} from="blog" />
            <Box sx={{p: 3}}>
                <section style={{
                    marginTop: '24px',
                    marginBottom: '24px'
                }}>
                    <div className="category-aria">
                        <p className="we-found">
                            We found <span style={{color: '#000', fontWeight: 'bold'}}>
                                {totalCount}
                            </span> blogs
                        </p>
                        <Button type="button" className="btn-all-cat">
                            All Category <ArrowDropDownIcon />
                        </Button>
                    </div>
                </section>
                <div style={{
                    display: 'inline-block',
                }}>
                    {blogsData && blogsData?.map((item, index) => (
                        <BlogCard 
                            data={item} 
                            detail={viewBlog}
                            key={index} />
                    ))}
                </div>
                {blogsData && (blogsData?.length < totalCount) &&
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <Button 
                        className="load-more-button" 
                        onClick={loadMore} 
                        disabled={buttonLoader}>
                        {buttonLoader ? <LoaderIcon /> : "Load More"}
                    </Button>
                </div>}
            </Box>
        </>
    );
}
