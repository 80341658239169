import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  InputLabel,
  TextField,
} from "@mui/material";
import validator from "validator";
import { toast } from "react-toastify";
import parse from 'html-react-parser';
import Spinner from "../../components/Spinner/Spinner";
import SecondaryHead from "../../components/SecondaryHead/SecondaryHead";
import {addContactUs} from "../../redux/students/studentSlice";
import LoaderIcon from "../../components/Loader/Loader";
import Moment from "moment";
import "./studentsBlog.css";

export default function StudentsBlogDetail() {
    const dispatch = useDispatch();
    const [btnLoader, setBtnLoader] = React.useState(false);
    const [contact, setContact] = React.useState({});
    const { 
        isLoading,
        isError,
        message,
        blogById } = useSelector((state) => state.student);

    const submitForm = () => {
        if(!validate().status){
            let body = contact;
            if(contact?.company_name === ""){
                body = {
                    name: contact?.name,
                    email: contact?.email,
                    phone: contact?.phone,
                    subject: contact?.subject,
                    message: contact?.message
                }
            }
            setBtnLoader(true);
            const res = dispatch(addContactUs(body));
            res?.then((r) => {
                setBtnLoader(false);
                if(r?.payload?.statusCode === 200){
                    toast.success("Message Sent Successfully");
                    setContact({});
                }else{
                    toast.error(r?.payload?.message);
                }
            })
        }else{
            toast.error(validate().message)
        }
    }
    const handleChange = (e) => {
        e.target.name === "name" && setContact({...contact, name: e.target.value});
        e.target.name === "phone" && setContact({...contact, phone: e.target.value});
        e.target.name === "subject" && setContact({...contact, subject: e.target.value});
        e.target.name === "company_name" && setContact({...contact, company_name: e.target.value});
        e.target.name === "email" && setContact({...contact, email: e.target.value});
        e.target.name === "message" && setContact({...contact, message: e.target.value});
    }

    const validate = () => {
        let error = {status: false, message: ""};
        if(!contact?.name || contact?.name === ""){
            error.status = true;
            error.message = "Name Required";
            return error;
        }
        if(!contact?.phone || contact?.phone === ""){
            error.status = true;
            error.message = "Phone Required";
            return error;
        }
        if(contact?.phone.length !== 10){
            error.status = true;
            error.message = "Invalid Phone Number";
            return error;
        }
        if(!contact?.subject || contact?.subject === ""){
            error.status = true;
            error.message = "Subject Required";
            return error;
        }
        if(!contact?.email || contact?.email === ""){
            error.status = true;
            error.message = "Email Required";
            return error;
        }
        if(!validator.isEmail(contact?.email)){
            error.status = true;
            error.message = "Invalid Email";
            return error;
        }
        if(!contact?.message || contact?.message === ""){
            error.status = true;
            error.message = "Message Required";
            return error;
        }
        return error;
    }
    if (isLoading) return <Spinner />;
    if (isError) return <h1>{message}</h1>;
    return (
        <>
            <SecondaryHead heading={'Top Universities'} from="blog" />
            <Box sx={{p: 3}}>
                <section className="blog-detail-box">
                    <div style={{
                        width: '100%',
                    }}>
                        <img 
                            src={blogById && blogById?.images[0]} 
                            alt="blogDetailImage" 
                            style={{width: '100%'}}
                            className="blog-big-image" />
                    </div>
                    <div className="detail-box">
                        <h6 style={{
                            textTransform: 'uppercase',
                        }}>
                            {blogById && Moment(blogById?.created_at).format('MMMM DD, YYYY')}- 
                            {blogById && blogById?.university_id ? 'UNIVERSITY' : 'COACHING'} 
                        </h6>
                        <h1 style={{
                            textTransform: 'capitalize',
                        }}>{blogById && blogById?.title}</h1>
                        <div className="blog-description">
                            {blogById && parse(blogById?.description)}
                        </div>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                            {(blogById?.embedded_videos && 
                            blogById?.embedded_videos?.length > 0) &&
                            <div className="blog-video-aria">
                                <iframe 
                                    style={{
                                        borderRadius: '5px'
                                    }}
                                    width="100%" 
                                    height="100%" 
                                    src={blogById?.embedded_videos[0]} 
                                    title="Review Video" 
                                    frameborder="0" 
                                    allow="accelerometer; fullscreen; autoplay=0; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                                    ></iframe>
                            </div>}
                        </div>
                        <div>
                            <h1 style={{color: 'rgb(0, 0, 0, 0.5)'}}>Contact Us to know more.</h1>
                            <div className="contact-form-box">
                                <div className="contact-part-1">
                                    <div>
                                        <InputLabel className="input-label-text">
                                            Name
                                        </InputLabel>
                                        <TextField 
                                            onChange={handleChange}
                                            placeholder="" 
                                            name="name" 
                                            type="text" 
                                            variant="outlined" 
                                            className="inputShadow" 
                                            value={contact?.name ? contact?.name : '' }
                                            sx={{
                                                width: '100%',
                                                height: '62px',
                                                marginBottom: '16px'
                                            }}/>
                                    </div>
                                    <div>
                                        <InputLabel className="input-label-text">
                                            Contact Number
                                        </InputLabel>
                                        <TextField 
                                            onChange={handleChange}
                                            placeholder="" 
                                            name="phone" 
                                            type="number" 
                                            variant="outlined" 
                                            className="inputShadow" 
                                            value={contact?.phone ? contact?.phone : ''}
                                            sx={{
                                                width: '100%',
                                                height: '62px',
                                                marginBottom: '16px'
                                            }} />
                                    </div>
                                    <div>
                                        <InputLabel className="input-label-text">
                                            Subject
                                        </InputLabel>
                                        <TextField 
                                            onChange={handleChange}
                                            placeholder="" 
                                            name="subject" 
                                            type="text" 
                                            variant="outlined" 
                                            className="inputShadow" 
                                            value={contact?.subject ? contact?.subject : ''}
                                            sx={{
                                                width: '100%',
                                                height: '62px',
                                                marginBottom: '16px'
                                            }}/>
                                    </div>
                                    <div>
                                        <InputLabel className="input-label-text">
                                            Company Name
                                        </InputLabel>
                                        <TextField 
                                            onChange={handleChange}
                                            placeholder="" 
                                            name="company_name" 
                                            type="text" 
                                            variant="outlined" 
                                            className="inputShadow" 
                                            value={contact?.company_name ? 
                                                contact?.company_name : ''}
                                            sx={{
                                                width: '100%',
                                                height: '62px',
                                                marginBottom: '16px'
                                            }} 
                                            required/>
                                    </div>
                                </div>
                                <div className="contact-part-1">
                                    <div>
                                        <InputLabel className="input-label-text">
                                            Email
                                        </InputLabel>
                                        <TextField 
                                            onChange={handleChange}
                                            placeholder="" 
                                            name="email" 
                                            type="email" 
                                            variant="outlined" 
                                            className="inputShadow"
                                            value={contact?.email ? contact?.email : ''} 
                                            sx={{
                                                width: '100%',
                                                height: '62px',
                                                marginBottom: '16px'
                                            }}/>
                                    </div>
                                    <div>
                                        <InputLabel className="input-label-text">
                                            Message
                                        </InputLabel>
                                        <TextField 
                                            onChange={handleChange}
                                            placeholder="" 
                                            name="message" 
                                            type="text" 
                                            value={contact?.message ? contact?.message : ''} 
                                            variant="outlined" 
                                            className="inputShadow"
                                            sx={{
                                                width: '100%',
                                                height: '62px',
                                                marginBottom: '16px'
                                            }} 
                                            multiline 
                                            rows={10.5}/>
                                    </div>
                                </div>
                            </div>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center'
                            }} className="blog-contact-us-submit-btn">
                                <Button sx={{
                                    background: btnLoader ? 'rgb(0, 0, 0, 0.2)' :
                                        'var(--primary-color)',
                                    textTransform: 'capitalize',
                                    color: 'white',
                                    width: '96px',
                                    height: '32px',
                                    '&:hover':{
                                        background: 'var(--primary-color)'
                                    }
                                    }}
                                    disabled={btnLoader}
                                    onClick={submitForm}>
                                        {btnLoader ? <LoaderIcon /> : 'Submit'}
                                </Button>
                            </div>
                        </div>
                    </div>
                </section>
            </Box>
        </>
    );
}
