import axios from "axios";
import endpoint from "../../index.routes";

const addOrder = async (params) => {
    const response = await axios.post(endpoint.ADD_ORDER, params, 
      {
        headers: { token: localStorage.getItem("cc_token") },
      });
    return response.data;
};

const verifyOrder = async (params) => {
    const response = await axios.post(endpoint.VERIFY_ORDER, params, 
      {
        headers: { token: localStorage.getItem("cc_token") },
      });
    return response.data;
};

const paymentServices = { 
    addOrder,
    verifyOrder
};

export default paymentServices;
