import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import { useNavigate } from "react-router-dom";
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import CCLogo from '../../assest/svgs/cc-logo.svg';
import { Typography, InputLabel, Select } from '@mui/material';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import UniversityDash from './UniversityDash';
import CoachingDash from './CoachingDash';
import Footer from "../../pages/footer/Footer";
import HomeLayout from "../homeLayout/HomeLayout";
import { makeStyles } from "@mui/styles";
import "./dashboard.css";
import routes from '../../react_routes_enum';
const useStyle = makeStyles({
  addMore:{
    height: '36px',
    textTransform: 'capitalize !important',
    fontWeight: 'bold !important',
    color: '#fff !important',
    '&:hover':{
      backgroundColor: 'var(--primary-color) !important',
    }
  },
  addQuestion:{
    backgroundColor: '#3C1CDE !important',
    color: '#fff !important',
    borderRadius: '8px !important',
    fontWeight: 'bold !important',
    textTransform: 'capitalize !important',
    '&:hover':{
      backgroundColor: '#3C1CDE !important',
    }
  }
})



export default function Dashboard() {
  const classes = useStyle();
  const isWebsiteFor = localStorage.getItem('is-website-for');
  return (
    <>
      <HomeLayout />
      {isWebsiteFor === "university" && 
        <div className="content-box table-box inst-dash-box">
          <UniversityDash />
        </div>
      }
      {isWebsiteFor === "coaching" && 
        <div className="content-box table-box inst-dash-box">
          <CoachingDash />
        </div>
      }
    </>
  );
}