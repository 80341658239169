import * as React from 'react';
import "./addOnHeader.css";

const AddOnHeader = (props) => {
    
    return (
        <div className="add-header">
            <h1 style={{textTransform: 'capitalize'}}>
                {props?.heading}
            </h1>
            <p>
                {props?.subHeading}
            </p>
        </div>
    )
}

export default AddOnHeader;