import * as React from 'react';
import Button from '@mui/material/Button';
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { MenuItem, InputLabel, Select } from '@mui/material';
import TextField from '@mui/material/TextField';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import HomeLayout from "../homeLayout/HomeLayout";
import EastIcon from '@mui/icons-material/East';
import SelectSearch from "react-dropdown-select";
import { addTest, updateTest, getQuestion } from '../../redux/test/testSlice';
import { getConfigDetails } from "../../redux/configurationDetails/configDetailsSlice";
import LoaderIcon from "../../components/Loader/Loader";
import { toast } from "react-toastify";
import { makeStyles } from "@mui/styles";

const useStyle = makeStyles({
  addMore:{
    height: '36px',
    textTransform: 'capitalize !important',
    fontWeight: 'bold !important',
    color: '#fff !important',
    '&:hover':{
      backgroundColor: 'var(--primary-color) !important',
    }
  },
  addQuestion:{
    color: '#fff !important',
    borderRadius: '8px !important',
    fontWeight: 'bold !important',
    textTransform: 'capitalize !important',
    '&:hover':{
      backgroundColor: '#3C1CDE !important',
    }
  }
})

const testBody = {
    title: "",
    duration_in_min: "",
    instructions: [
        "",
        "",
        ""
    ],
    marks_per_question: {
        negative: 0,
        positive: 1
    },
    question_ids: []
}

export default function Question() {
  const classes = useStyle();
  const dispatch = useDispatch();
  const location = useLocation();
  const isWebsiteFor = localStorage.getItem('is-website-for');
  const [btnLoader, setBtnLoader] = React.useState(false);
  const [isQuestionAdding, setIsQuestionAdding] = React.useState(false);
  const [testSubmit, setTestSubmit] = React.useState(testBody);
  const [negativeMarkList, setNegativeMarkList] = React.useState([]);
  const [markList, setMarkList] = React.useState([]);
  const [selectedQuestions, setSelectedQuestions] = React.useState([])

  const { 
    questionData, 
    testById 
    } = useSelector((state) => state.test);

  const handleChange = (e, index) => {
    e.target.name === "title" && setTestSubmit({...testSubmit, title: e.target.value});
    e.target.name === "negative-mark" && setTestSubmit({
      ...testSubmit, 
      marks_per_question: {...testSubmit?.marks_per_question, negative: e.target.value}});
    e.target.name === "positive-mark" && setTestSubmit({
      ...testSubmit, 
      marks_per_question: {...testSubmit?.marks_per_question, positive: e.target.value}});
    e.target.name === "duration" && setTestSubmit({...testSubmit, duration_in_min: e.target.value});
    if(e.target.name === `instruction_${index}`){
      let array = [...testSubmit?.instructions]
      array[index] = e.target.value;
      setTestSubmit({...testSubmit, instructions: [...array]})
    }
  }

  const addMoreInstructions = () => {
    let array = [...testSubmit?.instructions];
    array.push("");
    setTestSubmit({...testSubmit, instructions: [...array]});
  }

  const submitTest = () => {
    if(validate().isValid){
        let sendBody = {}
        let inst = testSubmit?.instructions.filter((item) => {
            return item !== ""
        })
        sendBody = {
            title: testSubmit?.title,
            duration_in_min: testSubmit?.duration_in_min,
            instructions: inst,
            marks_per_question: testSubmit?.marks_per_question,
            question_ids: testSubmit?.question_ids
        }
        setBtnLoader(true);
        const res = location.pathname === "/test/edit" ? 
            dispatch(updateTest({body: sendBody, isWebsiteFor, id: testById?._id})) :
            dispatch(addTest({body: sendBody, isWebsiteFor}))
        res?.then((r) => {
            setBtnLoader(false);
            if(r?.payload?.statusCode === 200){
                if(location.pathname === "/test/edit"){
                  toast.success("Test Updated Successfully");
                  setIsQuestionAdding(false);
                  return;
                }
                toast.success("Test Added Successfully");
                setIsQuestionAdding(false);
                setTestSubmit({
                    title: "",
                    duration_in_min: "",
                    instructions: ["", "", ""],
                    marks_per_question: {
                        negative: 0,
                        positive: 1
                    },
                    question_ids: []
                });
            }
            else{
                toast.error(r?.payload?.message);
            }
        })
    }else{
        toast.error(validate().message);
    }
  }

  const validate = () => {
    let status = {isValid: true, message: ""};
    if(!testSubmit?.title || testSubmit?.title === ""){
        status.isValid = false;
        status.message = "Please enter test title";
        return status;
    }
    if(!testSubmit?.duration_in_min || testSubmit?.duration_in_min === ""){
        status.isValid = false;
        status.message = "Please enter test duration";
        return status;
    }
    if(testSubmit?.duration_in_min < 1){
        status.isValid = false;
        status.message = "Invalid duration entered";
        return status;
    }
    if(testSubmit?.question_ids?.length === 0){
        status.isValid = false;
        status.message = "Please select atleast 1 question";
        return status;
    }
    if(testSubmit?.instructions?.length > 0){
        status.isValid = false;
        status.message = "Please enter atleast 1 instruction";
        testSubmit?.instructions.map((item) => {
            if(item !== ""){
                status.isValid = true;
                status.message = "";
            }
        })
        return status;
    }
    return status;
  }

  React.useEffect(() => {
    dispatch(getQuestion({isWebsiteFor}));
    const res = dispatch(getConfigDetails());
    res?.then((r) => {
      if(r?.payload?.statusCode === 200){
        r?.payload?.data?.map((item) => {
          item?.configuration_type === 'negative_mark' && setNegativeMarkList(item?.data);
          item?.configuration_type === 'mark_per_question' && setMarkList(item?.data)
        })
      }
    })
  },[])

  React.useEffect(() => {
    if(testById){
        let array = [];
        setTestSubmit({
            title: testById?.title,
            duration_in_min: testById?.duration_in_min,
            instructions: testById?.instructions,
            marks_per_question: {
                negative: testById?.marks_per_question?.negative,
                positive: testById?.marks_per_question?.positive
            },
            question_ids: testById?.question_ids?.map(obj => obj._id)
        })

        testById?.question_ids?.map(obj => 
          array.push({value: obj?._id, label: obj?.title})
        )
        setSelectedQuestions([...array]);
    }
  },[])
  
  const setOptionValues = (values) => {
    let array = [];
    let array_2 = [];
    values?.map((item) => {
        array.push(item?.value);
        array_2.push(item);
    })
    setSelectedQuestions(...array_2)
    setTestSubmit({...testSubmit, question_ids: [...array]})
  }

  const getOptions = () => {
    let blankOptions = [{ 
        value: 1,
        label: "No Data Available"
    }]
    let options = [];
    if(questionData){
        questionData?.map((item) => {
            options.push({value: item._id, label: item.title});
        })
    }
    return options?.length > 0 ? options : blankOptions;
  }

  return (
    <>
      <HomeLayout />
      {!isQuestionAdding &&
      <div className="content-box table-box dashboard-design" 
        style={{overflowY: 'hidden'}}>
        <div className="dasboard-two-input">
          <div style={{
            width: '100%',
          }}>
            <InputLabel className="input-label-text">Title</InputLabel>
            <TextField 
              placeholder="" 
              name="title" 
              type="text" 
              variant="outlined" 
              className="inputShadow"
              value={testSubmit?.title}
              sx={{
                  width: '100%',
                  height: '62px'
              }} 
              onChange={handleChange} 
              required />
          </div>
          <div style={{
            width: '100%',
          }} className="duration-in">
            <InputLabel className="input-label-text">Duration(Min)</InputLabel>
            <TextField 
              placeholder="" 
              name="duration" 
              type="number" 
              variant="outlined" 
              className="inputShadow"
              value={testSubmit?.duration_in_min}
              sx={{
                  width: '100%',
                  height: '62px'
              }} 
              onChange={handleChange} 
              required />
          </div>
        </div>
        <div>
          <InputLabel className="input-label-text">Instructions</InputLabel>
          {testSubmit?.instructions?.map((item, index) => (
          <div key={index}>
            <TextField 
              placeholder="" 
              name={`instruction_${index}`} 
              type="text" 
              variant="outlined" 
              className="inputShadow"
              value={item}
              sx={{
                  width: '100%',
                  height: '62px'
              }} 
              onChange={(e) => handleChange(e, index)} 
              required />
            </div>))}
          <div style={{
            margin: '12px 0'
          }}>
            {location.pathname !== "/test/view" &&
            <Button className={classes.addMore} 
              onClick={addMoreInstructions}
              sx={{
                backgroundColor: 'var(--primary-color)',
                }}>
                + add more
            </Button>}
          </div>
        </div>
        <div className='marks-calculation'>
          <InputLabel className="input-label-text">Marks Calculation</InputLabel>
          <div style={{
            display: 'flex',
            margin: '12px 0'
          }}>
              <p style={{
              fontSize: '18px'
            }}>Negative marking :</p>
              <Select
                  value={testSubmit?.marks_per_question?.negative}
                  onChange={handleChange}
                  name="negative-mark"
                  MenuProps={{ PaperProps: { sx: { maxHeight: 250 } } }}
                  autoWidth
                  required
                  sx={{
                      width: '100px',
                      height: '56px',
                      marginLeft: '24px',
                      marginRight: '4px',
                      position: 'relative',
                  }}
                  >
                  {negativeMarkList.map((item, index) => 
                  <MenuItem value={item} key={index}>
                      {item}
                  </MenuItem>)}
              </Select>
              <p style={{
                fontSize: '16px'
              }}>per question</p>
          </div>
          <div style={{
            display: 'flex'
          }}>
            <p style={{
              fontSize: '18px'
            }}>Marks per Question :</p>
            <Select
                value={testSubmit?.marks_per_question?.positive}
                onChange={handleChange}
                name="positive-mark"
                MenuProps={{ PaperProps: { sx: { maxHeight: 250 } } }}
                autoWidth
                required
                sx={{
                    width: '100px',
                    height: '56px',
                    marginLeft: '8px',
                    marginRight: '4px',
                    position: 'relative',
                }}
                >
                {markList.map((item, index) => 
                <MenuItem value={item} key={index}>
                    {item}
                </MenuItem>)}
            </Select>
            <p style={{
                fontSize: '16px'
              }}>per question</p>
          </div>
        </div>
        {testById && 
        <div>
          <InputLabel className="input-label-text">
            Question List
          </InputLabel>
          <ul style={{
            listStyleType: 'none',
            padding: 0
          }}>
            {testById?.question_ids?.map((item, index) => (
              <li key={index} style={{
                fontSize: '18px',
                marginBottom: '12px'
              }}>
                {index+1}.{" "}{item?.title}
              </li>
            ))}
          </ul>
        </div>
        }
      </div>}
      {!isQuestionAdding &&
      <div className="add-question-box" style={{marginBottom: '32px'}}>
        {location.pathname !== "/test/view" &&
        <Button className={`${classes.addQuestion} add-question`} 
            sx={{backgroundColor: '#3C1CDE'}}
          onClick={() => setIsQuestionAdding(true)}>
          Add Questions +
        </Button>}
      </div>}
      {isQuestionAdding && 
      <>
        <div className="select-div" style={{
          marginTop: 100
        }}>
            <Button onClick={() => setIsQuestionAdding(false)} sx={{
                marginBottom: '8px'
            }}>
                <ArrowBackIosIcon fontSize='small' />
            </Button>
            <InputLabel className="input-label-text">
              Select Questions
            </InputLabel>
            <SelectSearch 
                multi
                values={selectedQuestions}
                options={getOptions()}
                onChange={(values) => setOptionValues(values)}
            />
        </div>
        <div className="add-question-box" style={{
          marginTop: 24
        }}>
            <Button 
                className={`${classes.addQuestion} add-question`} 
                disabled={btnLoader}
                sx={{
                    background: btnLoader ? 'rgb(0, 0, 0, 0.2)' : '#3C1CDE'
                }}
                onClick={submitTest}>
                {btnLoader ? <LoaderIcon /> : 
                <>
                {location.pathname === "/test/edit" ? 'Update Test' : 'Submit Test' }
                <EastIcon sx={{marginLeft: '4px'}} /></>
                }
            </Button>
        </div>
      </>
      }
    </>
  );
}