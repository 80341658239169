import * as React from 'react';
import routes from "../../react_routes_enum";
import ApplicationTable from "../../components/BasicTable/BasicTable";
import TotalStudentIcon from "../../assest/svgs/total_students.svg";
import TotalProductsIcon from "../../assest/svgs/total_products.svg";
import TotalBlogsIcon from "../../assest/svgs/total_blogs.svg";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Link } from "react-router-dom";
import { 
    Typography,
    IconButton,
    Button } from "@mui/material";

const totalData = [
    {
      icon: TotalStudentIcon,
      name: 'Students',
      count: 20,
      bgColor: '#E8E6FB'
    },
    {
      icon: TotalProductsIcon,
      name: 'Products',
      count: 30,
      bgColor: '#FAE5EA'
    },
    {
      icon: TotalBlogsIcon,
      name: 'Blogs',
      count: 40,
      bgColor: '#C1FFD2'
    }
]

const colR = ["SNo", "Name of student", "Status", "actions"];
const demoNewApp = [
    {
      university_name: 'unive namesd'
    },
    {
      coaching_name: 'coaching name'
    }
]
const CoachingDash = () => {
    return (
      <>
        <div style={{
          display: 'inline-block',
          }} className="dash-board-card-big-box">
            {totalData?.map((item, index) => (
                <div key={index} className="dash-board-card-box">
                    <div className="dash-board-top-card" style={{
                        backgroundColor: item?.bgColor
                    }}>
                        <img src={item?.icon} alt={item?.name} />
                        <p style={{
                          fontWeight: 'bold',
                          padding: '0 12px',
                          textAlign: 'center',
                          marginBottom: 0
                        }}>{item?.name}</p>
                        <p style={{
                          fontSize: '22px',
                          fontWeight: 'bold',
                          marginBottom: 0,
                        }}>{item?.count}</p>
                    </div>
                </div>
            ))}
        </div>
        <div className="student-app-box">
          <p style={{
            fontSize: '20px',
            fontWeight: 'bold',
          }}>Student application process</p>
          <div className="student-app-box-co">
            <ApplicationTable
                columns={colR}
                rows={demoNewApp.map((item) => {
                const res = {
                    ...item,
                    actions: (
                    <div style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        <IconButton
                        color="inherit"
                        edge="start"
                        onClick={() => {}}>
                        <Link to={''}>
                            <VisibilityIcon sx={{color: '#BA68C8'}} fontSize="small" />
                            <Typography component="p" variant="p" 
                            sx={{
                                color: '#BA68C8',
                                fontSize: '12px',
                                fontWeight: 'bold',
                                marginTop: '-6px'
                                }}>
                            View
                            </Typography>
                        </Link>
                        </IconButton>
                    </div>
                    )
                };
                return res;
                })}
            />
          </div>
        </div>
        <div className="student-app-box notification-aria">
            <div>
                <p style={{
                    fontSize: '20px',
                    fontWeight: 'bold',
                }}>Notification</p>
                <div className="pending-to-action">
                    
                </div>
            </div>
            <div className="coach-pending-app">
                <p style={{
                    fontSize: '20px',
                    fontWeight: 'bold',
                }}>Pending To Action</p>
                <div className="pending-to-action">
                    <ul>
                    <li style={{marginTop: '12px'}}>
                        Complete the uploading of Test Questions for Microbiology. 
                        <Link to={routes.TEST_QUESTION} 
                        style={{
                            color: '#07AB35',
                            fontWeight: 'bold',
                            marginLeft: '4px',
                            marginTop: '2px'
                            }}>START UPLOADING</Link>
                    </li>
                    <li>
                        Complete the uploading of online recorded sessions for Microbiology. 
                        <Link to={routes.RECORDED_COURSE}
                        style={{
                            color: '#07AB35',
                            fontWeight: 'bold',
                            marginLeft: '4px',
                            marginTop: '2px'
                            }}>START UPLOADING</Link>
                    </li>
                    </ul>
                </div>
            </div>
        </div>
      </>
    )
}

export default CoachingDash;