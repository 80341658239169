import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import instituteStudentsServices from "./instituteStudentService";

const initialState = {
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  totalCount: 0,
  searchText: undefined,
  studentId: null,
  instituteId: null,
  applicationData: null,
  applicationById: null,
  blogsData: null,
  blogsById: null,
  customPageData: null,
  customPageById: null,
  addonId: null
};

export const getInstituteApplication = createAsyncThunk(
    "instituteStudents/getInstituteApplication",
    async (params, thunkAPI) => {
      try {
        return await instituteStudentsServices.getInstituteApplication(params);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
);

export const getCustomPages = createAsyncThunk(
  "instituteStudents/getCustomPages",
  async (params, thunkAPI) => {
    try {
      return await instituteStudentsServices.getCustomPages(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getCustomPagesById = createAsyncThunk(
  "instituteStudents/getCustomPagesById",
  async (params, thunkAPI) => {
    try {
      return await instituteStudentsServices.getCustomPagesById(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getInstituteApplicationById = createAsyncThunk(
    "instituteStudents/getInstituteApplicationById",
    async (params, thunkAPI) => {
      try {
        return await instituteStudentsServices.getInstituteApplicationById(params);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
);

export const updateInstituteApplication = createAsyncThunk(
    "instituteStudents/updateInstituteApplication",
    async (params, thunkAPI) => {
      try {
        return await instituteStudentsServices.updateInstituteApplication(params);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
);

export const addBlog = createAsyncThunk(
  "instituteStudents/addBlog",
  async (params, thunkAPI) => {
    try {
      return await instituteStudentsServices.addBlog(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getBlogs = createAsyncThunk(
  "students/getBlogs",
  async (params, thunkAPI) => {
    try {
      return await instituteStudentsServices.getBlogs(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getBlogById = createAsyncThunk(
  "instituteStudents/getBlogById",
  async (params, thunkAPI) => {
    try {
      return await instituteStudentsServices.getBlogById(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const instituteStudentsSlice = createSlice({
  name: "posts",
  initialState,
  reducers: {
    setSearchText: (state, action) => {
        state.searchText = action.payload;
    },
    setStudentsById: (state, action) => {
        state.studentId = action.payload;
    },
    setInstituteId: (state, action) => {
      state.instituteId = action.payload;
    },
    setBlogById: (state, action) => {
      state.blogsById = action.payload;
    },
    setAddonId: (state, action) => {
      state.addonId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInstituteApplication.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getInstituteApplication.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getInstituteApplication.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = action.payload.message;
        state.totalCount = action.payload.data?.totalCount;
        state.applicationData = action.payload.data?.result;
      })
      .addCase(getInstituteApplicationById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getInstituteApplicationById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getInstituteApplicationById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = action.payload.message;
        state.applicationById = action.payload.data;
      })
      .addCase(updateInstituteApplication.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateInstituteApplication.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateInstituteApplication.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = action.payload.message;
      })
      .addCase(addBlog.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addBlog.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(addBlog.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = action.payload.message;
      })
      .addCase(getBlogs.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBlogs.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getBlogs.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = action.payload.message;
        state.totalCount = action.payload.data?.totalCount;
        state.blogsData = action.payload.data?.result;
      })
      .addCase(getBlogById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBlogById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getBlogById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = action.payload.message;
        state.blogsById = action.payload.data;
      })
      .addCase(getCustomPages.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCustomPages.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getCustomPages.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = action.payload.message;
        state.totalCount = action.payload.data?.totalCount;
        state.customPageData = action.payload.data?.result;
      })
      .addCase(getCustomPagesById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCustomPagesById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getCustomPagesById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = action.payload.message;
        state.customPageById = action.payload.data;
      })
  },
});

export const { 
    setSearchText, 
    setStudentsById,
    setInstituteId,
    setBlogById,
    setAddonId } = instituteStudentsSlice.actions;

export default instituteStudentsSlice.reducer;
