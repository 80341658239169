import * as React from 'react';
import { useNavigate } from "react-router-dom";
import Spinner from "../../components/Spinner/Spinner";
import VisibilityIcon from '@mui/icons-material/Visibility';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import { 
  Typography,
  Button,
  IconButton,
} from '@mui/material';
import HomeLayout from "../homeLayout/HomeLayout";
import routes from '../../react_routes_enum';
import Dialog from "@mui/material/Dialog";
import StudentProfileTable from "../../components/BasicTable/BasicTable";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../components/Pagination/Pagination";
import { toast } from 'react-toastify';
import { 
  getInstituteApplication, 
  updateInstituteApplication,
  setInstituteId } from '../../redux/instituteStudent/instituteStudentSlice';

export default function RejectedStudent() {
  const isWebsiteFor = localStorage.getItem("is-website-for");
  const [limit, setLimit] = React.useState(10);
  const [offset, setOffset] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const colR = ["SNo", "Name of Student", "DOB", "Action", ];
  const [open, setOpen] = React.useState(false);
  const [instId, setInstId] = React.useState("");

  const handleClickOpen = (id) => {
    setInstId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { 
    isLoading,
    totalCount,
    searchText,
    applicationData,  
  }= useSelector((state) => state.instituteStudent);

  React.useEffect(() => {
    getInstApp()
  }, [limit, offset, searchText])

  const handleClickOpenView = (id) => {
    dispatch(setInstituteId(id))
    navigate(routes?.REGISTER_TO_APPLY);
  };

  const getInstApp = () => {
    dispatch(getInstituteApplication({limit, offset, searchText, status: 'target_rejected', isWebsiteFor }))
  }

  const undoRejection = () => {
    const res = dispatch(updateInstituteApplication({id: instId, body: {status: 'target_pending'}, isWebsiteFor}));
    res.then((r) => {
      if(r?.payload?.statusCode === 200){
        setOpen(false);
        getInstApp();
        toast.success("Undo rejection successful")
      }else{
        toast.error("Error while updating document")
      }
    })
  }

  if (isLoading) return <Spinner />;
  
  return (
    <>
      <HomeLayout />
      <Dialog maxWidth={"xs"} open={open} onClose={handleClose} fullWidth>
          <div>
            <Typography component="p" variant="p" 
                sx={{
                textAlign: 'center',
                fontWeight: 'bold',
                margin: '28px 0 48px'}}>
                Are you sure you want to undo <br/> the application rejection ?
            </Typography>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'row-reverse',
                padding: '0px 16px 28px 16px'
            }}>
                <Button onClick={() => {setOpen(false)}} variant="outlined" sx={{
                textTransform: 'capitalize',
                color: isWebsiteFor === "university" ? '#000' : 'var(--tertiary-color)',
                borderColor: isWebsiteFor === "university" ? '#000' : 'var(--tertiary-color)'
                }}>
                    Cancel
                </Button>
                <Button variant="contained" sx={{
                    backgroundColor: isWebsiteFor === "university" ? 'var(--primary-color)' : 'var(--tertiary-color)',
                    width: '84px',
                    marginRight: '12px',
                    textTransform: 'capitalize'
                    }} onClick={undoRejection}>
                    Yes
                </Button>
            </div>
          </div>
      </Dialog>
      <div style={{
        marginTop: '100px'
      }} className="pagination-addPost">
        <Pagination
          totalCount={totalCount}
          limit={limit}
          page={page}
          changeLimit={setLimit}
          changeOffset={setOffset}
          changePage={setPage}
        />
      </div>
      <div className="content-box table-box" style={{marginTop: '0px'}}>
        
        <StudentProfileTable
                    columns={colR}
                    where="recipes"
                    rows={applicationData && applicationData?.map((item) => {
                        const res = {
                        ...item,
                        Action: (
                          <div style={{display:'flex',columnGap:'6px'}}>
                            <div>
                              <IconButton
                                color="inherit"
                                edge="start"
                                onClick={() => handleClickOpen(item?._id)}
                                style={{display:'block'}}
                              >                                    
                              <BlockOutlinedIcon sx={{color: '#07AB35'}} className="action-icon" />
                                <Typography component="p" variant="p" 
                                sx={{
                                  color: '#07AB35',
                                  fontWeight: '600'
                                  }} className="action-buton">
                                  Undo
                                </Typography> 
                              </IconButton>                           
                            </div>
                            <div>
                              <IconButton
                              style={{display:'block'}}
                                color="inherit"
                                edge="start"
                                onClick={() => handleClickOpenView(item?._id)}
                              >                                    
                              <VisibilityIcon sx={{color: '#BA68C8'}} className="action-icon" />
                              <Typography component="p" variant="p" 
                              sx={{
                              color: '#BA68C8',
                              fontWeight: '600'
                              }} className="action-buton">
                              View
                              </Typography>       
                              </IconButton>                           
                            </div>
                          </div>
                        )
                        };
                        return res;
                    })
                    }
                />
      </div>
    </>
  );
}