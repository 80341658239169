import { configureStore } from "@reduxjs/toolkit";
// Slices
import authReducer from "./auth/authSlice";
import routeReducer from "./routeSlice";
import configReducer from "./configurationDetails/configDetailsSlice";
import uploadOnS3Reducer from "./uploadDataOnS3/uploadDataS3.slice";
import requestFormSlice from "./requestForm/requestFormSlice";
import productSlice from "./product/productSlice";
import studentSlice from "./students/studentSlice";
import instituteStudentsSlice from "./instituteStudent/instituteStudentSlice";
import paymentSlice from "./payment/paymentSlice";
import testSlice from "./test/testSlice";

const store = configureStore({
  reducer: {
    auth: authReducer,
    route: routeReducer,
    config: configReducer,
    uploadOnS3: uploadOnS3Reducer,
    form: requestFormSlice,
    product: productSlice,
    student: studentSlice,
    instituteStudent: instituteStudentsSlice,
    payment: paymentSlice,
    test: testSlice
  },
});

export default store;
