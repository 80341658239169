import axios from "axios";
import endpoint from "../../index.routes";

const getInstituteApplication = async (params) => {
    let path = "";
    const { 
      limit, 
      offset, 
      searchText, 
      status,
      isWebsiteFor 
    } = params;
    isWebsiteFor === "university" ? path = endpoint.UNIVERSITY_STUDENT : path = endpoint.COACHING_STUDENT
    const response = await axios.get(path,
      {
        params: { limit, offset, searchText, status },
        headers: { token: localStorage.getItem("cc_token") },
      }
    );
    return response.data;
};

const getInstituteApplicationById = async (params) => {
    let path = "";
    const { id, isWebsiteFor } = params;
    isWebsiteFor === "university" ? path = endpoint.UNIVERSITY_STUDENT+"/"+id : 
        path = endpoint.COACHING_STUDENT+"/"+id
    const response = await axios.get(path,
      {
        headers: { token: localStorage.getItem("cc_token") },
      }
    );
    return response.data;
};

const updateInstituteApplication = async (params) => {
    let path = "";
    const { id, body, isWebsiteFor } = params;
    isWebsiteFor === "university" ? path = endpoint.UNIVERSITY_STUDENT+"/"+id : 
        path = endpoint.COACHING_STUDENT+"/"+id
    const response = await axios.put(path, body,
      {
        headers: { token: localStorage.getItem("cc_token") },
      }
    );
    return response.data;
};

const addBlog = async (params) => {
  const isWebsiteFor = localStorage.getItem("is-website-for");
  let path = "";
  isWebsiteFor === "university" ? path = endpoint.UNIVERSITY_BLOG : 
    path = endpoint.COACHING_BLOG
  const response = await axios.post(path, params, 
    {
      headers: { token: localStorage.getItem("cc_token") },
    });
  return response.data;
};

const getBlogs = async (params) => {
  const isWebsiteFor = localStorage.getItem("is-website-for");
  let path = "";
  const { 
    limit, 
    offset, 
    searchText
  } = params;
  isWebsiteFor === "university" ? path = endpoint.UNIVERSITY_BLOG : 
    path = endpoint.COACHING_BLOG
  const response = await axios.get(path,
    {
      params: { limit, offset, searchText },
      headers: { token: localStorage.getItem("cc_token") },
    }
  );
  return response.data;
};

const getCustomPages = async (params) => {
  const { 
    limit, 
    offset, 
    searchText,
    page_type,
    exam_category
  } = params;
  const response = await axios.get(endpoint.STUDENT_PAGE,
    {
      params: { limit, offset, searchText, page_type, exam_category },
      headers: { token: localStorage.getItem("cc_token") },
    }
  );
  return response.data;
};

const getCustomPagesById = async (params) => {
  const { id } = params;
  const response = await axios.get(endpoint.STUDENT_PAGE + "/" + id,
    {
      headers: { token: localStorage.getItem("cc_token") },
    }
  );
  return response.data;
};

const getBlogById = async (params) => {
  const isWebsiteFor = localStorage.getItem("is-website-for");
  let path = "";
  const { id } = params;
  isWebsiteFor === "university" ? path = endpoint.UNIVERSITY_BLOG+"/"+id : 
      path = endpoint.COACHING_BLOG+"/"+id
  const response = await axios.get(path,
    {
      headers: { token: localStorage.getItem("cc_token") },
    }
  );
  return response.data;
};


const instituteStudentsServices = { 
    getInstituteApplication,
    getInstituteApplicationById,
    updateInstituteApplication,
    addBlog,
    getBlogs,
    getBlogById,
    getCustomPages,
    getCustomPagesById
};

export default instituteStudentsServices;
