import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Typography,
  InputLabel,
  TextField,
  Select,
  MenuItem
} from "@mui/material";
import routes from "../../../react_routes_enum";
import { makeStyles } from "@mui/styles";
import HeaderBackground from "../../../assest/images/header-background.jpg";
import HeaderIcon from "../../../assest/images/header-icon.png";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import { useNavigate } from "react-router-dom";
import { updateData, 
    getUniversityByToken, 
    getCoachingByToken } from "../../../redux/requestForm/requestFormSlice";
import { toast } from "react-toastify";
import validator from "validator";
import LoaderIcon from "../../../components/Loader/Loader";
import { logout, reset } from "../../../redux/auth/authSlice";
import { validateUrl } from "../../../utils/validateUrl";
import Spinner from "../../../components/Spinner/Spinner";
import JoditEditor from 'jodit-react';
import { getConfigDetails } from "../../../redux/configurationDetails/configDetailsSlice";
const cityLocalList = require("../../../data/indiaCity.json");
var nameValidator = /^[a-zA-Z][a-zA-Z\s]*$/

const useStyle = makeStyles({
    dot: {
        height: '16px',
        width: '16px',
        borderRadius: '50%',
    },
    line: {
        height: '4px',
        backgroundColor: 'rgb(246, 146, 32, 0.4)'
    },
    changesButton: {
        color: '#fff !important',
        textTransform: 'capitalize !important',
        width: '140px',
        height: '40px',
        fontSize: '16px !important',
        borderRadius: '24px !important',
        boxShadow: '0 2px 5px rgb(0, 0, 0, 0.5)',
        "&:hover":{
            backgroundColor: 'var(--secondary-color) !important',
        }
    },
    cancelButton: {
        backgroundColor: '#fff !important',
        textTransform: 'capitalize !important',
        width: '140px',
        height: '40px',
        fontSize: '16px !important',
        borderRadius: '24px !important',
        "&:hover":{
            backgroundColor: '#fff',
        }
    },
    header: {
        marginTop: '100px',
        marginBottom: '4%',
        backgroundImage: `url(${HeaderBackground})`,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    formIconsBox: {
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        marginTop: '5%',
        marginRight: '14%',
    },
    iconBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    horizontalLine: {
        height: '4px',
        backgroundColor: 'var(--primary-color)',
    },
    iconBox2: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'absolute', 
    },
    iconStyle: {
        backgroundColor: 'var(--primary-color)',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    iconText: {
        color: 'var(--primary-color)',
        marginTop: '8px !important',
    },
    icon: {
        color: '#fff',
    }
})

let formData = {
    information_check: 1,
    information: {
        address: "",
        city: "",
        country: "",
        about: {
            college: "",
            infrastructure: "",
            why_join_us: ""
        },
        website_link: "",
        relationship_manager: {
            name: "",
            phone: "",
            email: ""
        },
        courses_count: 0,
        enrolled_students_count: 0,
        placement_percentage: 0
    },
}

export default function RequestRes() {
    const isWebsiteFor = localStorage.getItem("is-website-for");
    const editor = React.useRef(null);
    const [btnLoader, setBtnLoader] = React.useState(false);
    const [countryList, setCountryList] = React.useState([]);
    const [cityList, setCityList] = React.useState([]);
    const [coachingCityList, setCoachingCityList] = React.useState([]);
    const { 
        isLoading, 
        universityByToken,
        coachingByToken } = useSelector((state) => state.form);
    const navigate = useNavigate();
    const classes = useStyle();
    const dispatch = useDispatch();

    const [formInfo, setFormInfo] = React.useState((coachingByToken?.data?.information || universityByToken?.data?.information) ?
        {
            information_check: 1, 
            information: {
                address: isWebsiteFor === "coaching" ? coachingByToken?.data?.address : universityByToken?.data?.address,
                city: universityByToken?.data?.information?.city,
                country: universityByToken?.data?.information?.country,
                website_link: universityByToken?.data?.website_link,
                about: {
                    college: universityByToken?.data?.information?.about?.college,
                    infrastructure: universityByToken?.data?.information?.about?.infrastructure,
                    why_join_us: universityByToken?.data?.information?.about?.why_join_us
                },
                relationship_manager: {
                    name: universityByToken?.data?.information?.relationship_manager?.name,
                    phone: universityByToken?.data?.information?.relationship_manager?.phone,
                    email: universityByToken?.data?.information?.relationship_manager?.email
                },
                courses_count: universityByToken?.data?.information?.courses_count,
                enrolled_students_count: universityByToken?.data?.information?.enrolled_students_count,
                placement_percentage: universityByToken?.data?.information?.placement_percentage
            }
        }
        :
        formData
    );
    
    const cancelClicked = () => {
        // navigate(routes.REQUEST_FORM);
        setBtnLoader(false);
        dispatch(logout());
        dispatch(reset());
        navigate("/login");
    }

    const saveAndNext = () => {
        if(validate().status){
            let body = {}
            if(isWebsiteFor === "coaching"){
                body = {
                    information_check: formInfo?.information_check,
                    information: {
                        address: formInfo?.information?.address,
                        city: formInfo?.information?.city,
                        about: {
                            college: formInfo?.information?.about?.college,
                            infrastructure: formInfo?.information?.about?.infrastructure,
                            why_join_us: formInfo?.information?.about?.why_join_us
                        },
                        website_link: formInfo?.information?.website_link,
                        relationship_manager: {
                            name: formInfo?.information?.relationship_manager?.name,
                            phone: formInfo?.information?.relationship_manager?.phone,
                            email: formInfo?.information?.relationship_manager?.email
                        }
                    }
                }
            }else{
                body = formInfo
            }
            setBtnLoader(true);
            let res = dispatch(updateData({isWebsiteFor, body}));
            res.then((r) => {
                setBtnLoader(false);
                if(r.payload.statusCode === 200){
                    toast.success(r.payload.message);
                    isWebsiteFor === "coaching" ? dispatch(getCoachingByToken()) : dispatch(getUniversityByToken())
                    isWebsiteFor === "coaching" ? navigate(routes.COACHING_UPLOAD) : navigate(routes.UNIVERSITY_UPLOAD);
                }else{
                    toast.error(r.payload.message);
                }
            })
        }
        else{
            toast.error(validate().message);
        }
    }

    const handleChange = (e) => {
        e.preventDefault();
        e.target.name === "university" && setFormInfo({...formInfo})
        e.target.name === "city" && setFormInfo({...formInfo, information: {...formInfo.information, city:e.target.value} })
        e.target.name === "country" && setFormInfo({...formInfo, information: {...formInfo.information, country:e.target.value} })
        e.target.name === "address" && setFormInfo({...formInfo, information: {...formInfo.information, address:e.target.value}})
        e.target.name === "number_of_course" && setFormInfo({...formInfo, information: {
            ...formInfo.information, courses_count: e.target.value && parseInt(e.target.value)}})
        e.target.name === "student_enrolled" && setFormInfo({...formInfo, information: {
            ...formInfo.information, enrolled_students_count: e.target.value && parseInt(e.target.value)}})
        e.target.name === "placement_per" && setFormInfo({...formInfo, information: {
            ...formInfo.information, placement_percentage: e.target.value && parseInt(e.target.value)}})
        e.target.name === "website_link" && setFormInfo({...formInfo, information: {...formInfo.information, website_link:e.target.value}})
        e.target.name === "manager" && setFormInfo({
            ...formInfo, information: {
                ...formInfo.information, 
                relationship_manager:{...formInfo.information.relationship_manager, name: e.target.value}}})
        e.target.name === "m_phone" && setFormInfo({
            ...formInfo, information: {
                ...formInfo.information, 
                relationship_manager:{...formInfo.information.relationship_manager, phone: e.target.value}}})
        e.target.name === "m_email" && setFormInfo({
            ...formInfo, information: {
                ...formInfo.information, 
                relationship_manager:{...formInfo.information.relationship_manager, email: e.target.value}}})
    }
    // eslint-disable-next-line react-hooks/rules-of-hooks
    React.useEffect(() => {
        const res = isWebsiteFor === "coaching" ? dispatch(getCoachingByToken()) : dispatch(getUniversityByToken());
        res?.then((r) => {
            if(r?.payload?.statusCode === 200){
                setFormInfo({...formInfo, information: {...formInfo.information, address: r?.payload?.data?.address}})
                if(r?.payload?.data?.information){
                    setFormInfo({
                        information_check: 1,
                        information: {
                            address: r?.payload?.data?.address,
                            city: r?.payload?.data?.information?.city,
                            country: r?.payload?.data?.information?.country,
                            website_link: r?.payload?.data?.information?.website_link,
                            relationship_manager: {
                                name: r?.payload?.data?.information?.relationship_manager?.name,
                                phone: r?.payload?.data?.information?.relationship_manager?.phone,
                                email: r?.payload?.data?.information?.relationship_manager?.email
                            },
                            about: {
                                college: r?.payload?.data?.information?.about?.college,
                                infrastructure: r?.payload?.data?.information?.about?.infrastructure,
                                why_join_us: r?.payload?.data?.information?.about?.why_join_us
                            },
                            courses_count: r?.payload?.data?.information?.courses_count,
                            enrolled_students_count: r?.payload?.data?.information?.enrolled_students_count,
                            placement_percentage: r?.payload?.data?.information?.placement_percentage
                        }
                    })
                }
            }
        })
    },[])
    const validate = () => {
        let error = {status: true, message: ""}
        if(formInfo.information.city === ""){
            error.status = false;
            error.message = "City Required";
            return error;
        }
        if(formInfo.information.address === ""){
            error.status = false;
            error.message = "Address Required";
            return error;
        }
        if(isWebsiteFor === "university"){
            if(formInfo.information.country === ""){
                error.status = false;
                error.message = "Country Required";
                return error;
            }
        }
        if(formInfo?.information.about?.college === "" || formInfo?.information.about?.college === undefined){
            error.status = false;
            error.message = "About Institute Required";
            return error;
        }
        if(formInfo?.information?.about?.infrastructure === "" && formInfo?.information?.about?.infrastructure === undefined){
            error.status = false;
            error.message = "Infrastructure Required";
            return error;
        }
        if(formInfo?.information?.about?.why_join_us === "" && formInfo?.information?.about?.why_join_us === undefined){
            error.status = false;
            error.message = "Why Join Us Required";
            return error;
        }
        if(formInfo.information.website_link === ""){
            error.status = false;
            error.message = "Website Link Required";
            return error;
        }
        if(!validateUrl(formInfo.information.website_link)){
            error.status = false;
            error.message = "Invalid Link Entered";
            return error;
        }
        if(formInfo.information.relationship_manager.name === ""){
            error.status = false;
            error.message = "Manager Name Required";
            return error;
        }
        if(formInfo.information.relationship_manager.name.length < 4 || 
                !nameValidator.test(formInfo.information.relationship_manager.name) ||
                    formInfo.information.relationship_manager.name.length > 20){
            formInfo.information.relationship_manager.name.length < 4 ? (error = {status: false, 
                message: "Manager name must be at least 4 characters"}) :
              (error = {status: false, message: "Invalid Manager Name"})
            formInfo.information.relationship_manager.name.length > 20 && (error = {status: false, 
                message: "Manager name must be less than 20 characters"}) 
            return error;
        }
        if(formInfo.information.relationship_manager.phone === "" || 
            formInfo.information.relationship_manager.phone.length !== 10){
            error.status = false;
            error.message = formInfo.information.relationship_manager.phone === "" ? "Phone Number Required" : "Invalid Phone Number";
            return error;
        }
        if(!validator.isEmail(formInfo.information.relationship_manager.email)){
            error.status = false;
            error.message = "Invalid Email";
            return error;
        }
        if(isWebsiteFor === "university"){
            if(!formInfo?.information?.courses_count || formInfo?.information?.courses_count === ""){
                error.status = false;
                error.message = "Course Count Required";
                return error;
            }
            if(!formInfo?.information?.enrolled_students_count || formInfo?.information?.enrolled_students_count === ""){
                error.status = false;
                error.message = "Student Enrolled Required";
                return error;
            }
            if(!formInfo?.information?.placement_percentage || formInfo?.information?.placement_percentage === ""){
                error.status = false;
                error.message = "Placement Percent Required";
                return error;
            }
        }
        return error;
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    React.useEffect(() => {
        if(localStorage.getItem("isRefresh")){
            window.location.reload();
            localStorage.removeItem("isRefresh");
        }
    }, [])
    const richTextEditor = (text, type) => {
        type === "about_college" && setFormInfo({
            ...formInfo, information: {
            ...formInfo.information, 
            about:{...formInfo.information.about, college: text}}})
        type === "infrastructure" && setFormInfo({
            ...formInfo, information: {
            ...formInfo.information, 
            about:{...formInfo.information.about, infrastructure: text}}})
        type === "join_us" && setFormInfo({
            ...formInfo, information: {
            ...formInfo.information, 
            about:{...formInfo.information.about, why_join_us: text}}})
    }
    if (isLoading) return <Spinner />;
    return (
        <Box>
            <section className={classes.header + ' header-set'}>
                <div className="header-icon-box">
                    <img src={HeaderIcon} alt="header-icon" style={{width: '100%'}} />
                </div>
                <Typography component="h2" variant="h2" className="header-heading">
                    UNIVERSITY - LISTING
                </Typography>
                <Typography component="p" variant="p" className="small-header-heading">
                    Lorem ipsum dolor sit amet consectetur.
                </Typography>
            </section>
            <Box sx={{p: 3}}>
                <section>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginBottom: '8px'
                        }} className="progr-lin-box">
                            <div className={classes.dot} style={{
                                background: 'none',
                                }}>
                                <CheckCircleOutlineIcon sx={{width: '100%', color: '#07AB35'}} />
                            </div>
                            <div className={classes.line + ' progress-line'} style={{background: 'none'}}>

                            </div>
                            <div className={classes.dot} style={{
                                background: 'none',
                                visibility: (universityByToken?.data?.information_check && universityByToken?.data?.uploads_check &&
                                    universityByToken?.data?.courses_offered_check && universityByToken?.data?.placement_records_check
                                    ) ? 
                                    'visible' : 'hidden'
                                }}>
                                <CheckCircleOutlineIcon sx={{
                                    width: '100%', 
                                    color: '#07AB35',
                                    }} />
                            </div>
                            <div className={classes.line + ' progress-line'} style={{background: 'none'}}>

                            </div>
                            <div className={classes.dot} style={{
                                background: 'none',
                                visibility: (universityByToken?.data?.information_check && universityByToken?.data?.uploads_check &&
                                    universityByToken?.data?.courses_offered_check && universityByToken?.data?.placement_records_check
                                    && universityByToken?.data?.request_status === "accepted") ? 
                                    'visible' : 'hidden'
                                }}> 
                                <CheckCircleOutlineIcon sx={{width: '100%', color: '#07AB35'}} />
                            </div>
                        </div>
                    </div>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }} className="progr-lin-box">
                            <div className={classes.dot} style={{backgroundColor: 'var(--secondary-color)'}}>
                                
                            </div>
                            <div className={classes.line + ' progress-line'} style={{
                                backgroundColor: (universityByToken?.data?.information_check && 
                                    universityByToken?.data?.uploads_check &&
                                    universityByToken?.data?.courses_offered_check && 
                                    universityByToken?.data?.placement_records_check) ? 'var(--secondary-color)' :
                                    'rgb(246, 146, 32, 0.4)'
                            }}>

                            </div>
                            <div className={classes.dot} style={{backgroundColor: (universityByToken?.data?.information_check && 
                                    universityByToken?.data?.uploads_check &&
                                    universityByToken?.data?.courses_offered_check && 
                                    universityByToken?.data?.placement_records_check) ? 'var(--secondary-color)' :
                                    'rgb(246, 146, 32, 0.4)'}}>

                            </div>
                            <div className={classes.line + ' progress-line'} style={{
                                backgroundColor: (universityByToken?.data?.information_check && 
                                    universityByToken?.data?.uploads_check &&
                                    universityByToken?.data?.courses_offered_check && 
                                    universityByToken?.data?.placement_records_check &&
                                    universityByToken?.data?.request_status === "accepted") ? 'var(--secondary-color)' :
                                    'rgb(246, 146, 32, 0.4)'
                            }}>

                            </div>
                            <div className={classes.dot} style={{backgroundColor: (universityByToken?.data?.information_check && 
                                    universityByToken?.data?.uploads_check &&
                                    universityByToken?.data?.courses_offered_check && 
                                    universityByToken?.data?.placement_records_check &&
                                    universityByToken?.data?.request_status === "accepted") ? 'var(--secondary-color)' :
                                    'rgb(246, 146, 32, 0.4)'}}>
                            </div>
                        </div>
                    </div>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '12px'
                    }}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            justifyContent: 'space-between',
                        }} className="progr-text-box">
                            <Typography component="h2" variant="h2" className="progr-text">
                                Fill the Request Form
                            </Typography>
                            <Typography component="h2" variant="h2" className="progr-text">
                                Fill the Listing Registration Form
                            </Typography>
                            <Typography component="h2" variant="h2" className="progr-text">
                                LIVE Listing
                            </Typography>
                        </div>
                    </div>
                </section>
                <section>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    }} className="request-main-res-box">
                        <div style={{
                            width: '100%',
                        }}>
                            <div className={classes.formIconsBox + ' reg-form-box'}>
                                <div className={classes.iconBox+' icon-box-2'}>
                                    <div style={{position: 'relative'}}>
                                        <div className={classes.horizontalLine+' horizontal-line-2'}></div>
                                        <div className={classes.iconBox2+' icon-1'}>
                                            <div className={classes.iconStyle+' icon-style-2'} 
                                                style={{
                                                    color: '#fff', 
                                                    fontSize: '20px'
                                                    }}>
                                                i
                                            </div>
                                            <Typography component="h2" variant="h2" className={classes.iconText+' icon-text'}>
                                                Information
                                            </Typography>
                                        </div>
                                    </div>
                                    <div style={{position: 'relative'}}>
                                        <div className={classes.horizontalLine+' horizontal-line-2'} 
                                            style={{backgroundColor: 'var(--disable-color)'}}></div>
                                        <div className={classes.iconBox2+' icon-2'}>
                                            <div className={classes.iconStyle+' icon-style-2'} 
                                            style={{backgroundColor: 'var(--disable-color)'}}>
                                                <CameraAltIcon className={classes.icon} fontSize="small" />
                                            </div>
                                            <Typography component="h2" variant="h2" className={classes.iconText+' icon-text'} 
                                            style={{color: 'var(--disable-color)'}}>
                                                Uploads
                                            </Typography>
                                        </div>
                                    </div>
                                    <div style={{position: 'relative'}}>
                                        <div className={classes.horizontalLine+' horizontal-line-2'} 
                                            style={{backgroundColor: 'var(--disable-color)'}}></div>
                                        <div className={classes.iconBox2+' icon-3'}>
                                            <div className={classes.iconStyle+' icon-style-2'} 
                                                style={{backgroundColor: 'var(--disable-color)'}}>
                                                <MenuBookIcon className={classes.icon} fontSize="small" />
                                            </div>
                                            <Typography component="h2" variant="h2" className={classes.iconText+' icon-text'} 
                                            style={{color: 'var(--disable-color)'}}>
                                                Courses
                                            </Typography>
                                        </div>
                                    </div>
                                    {isWebsiteFor === "university" && 
                                    <div style={{position: 'relative'}}>
                                        <div className={classes.horizontalLine+' horizontal-line-2'} 
                                            style={{backgroundColor: 'var(--disable-color)'}}></div>
                                        <div className={classes.iconBox2+' icon-4'}>
                                            <div className={classes.iconStyle+' icon-style-2'} 
                                                style={{backgroundColor: 'var(--disable-color)'}}>
                                                <BusinessCenterIcon className={classes.icon} fontSize="small" />
                                            </div>
                                            <Typography component="h2" variant="h2" className={classes.iconText+' icon-text'} 
                                            style={{color: 'var(--disable-color)', textAlign: 'center'}}>
                                                Placement Records
                                            </Typography>
                                        </div>
                                    </div>}
                                </div>
                            </div>
                            <div style={{
                                marginTop: '110px'
                            }} className="form-box">
                                <div style={{
                                    display: 'flex',
                                    width: '100%',
                                    marginBottom: '20px'
                                }}>
                                    <div style={{
                                        width: '100%', 
                                        marginRight: '5%',
                                    }}>
                                        <InputLabel className="input-label-text">
                                            {isWebsiteFor === "coaching" ? "Name of Coaching Center" : "Name of University"}
                                        </InputLabel>
                                        <TextField placeholder="XYZ University" name="university" type="text" variant="outlined" 
                                            className="inputShadow"
                                            value={isWebsiteFor === "coaching" ? coachingByToken?.data?.coaching_name : 
                                                universityByToken?.data?.university_name}
                                            disabled={(coachingByToken?.data?.coaching_name || universityByToken?.data?.university_name) 
                                                && true}
                                            sx={{
                                                width: '100%',
                                                height: '62px'
                                            }} onChange={handleChange} required/>
                                    </div>
                                    <div style={{
                                        width: '100%',
                                    }}>
                                        <InputLabel className="input-label-text">City</InputLabel>
                                        <Select
                                            required
                                            name="city"
                                            className="select-class"
                                            value={formInfo?.information?.city}
                                            onChange={handleChange}
                                            placeholder="course"
                                            autoWidth
                                            sx={{
                                                width: '100%',
                                                height: '56px',
                                                position: 'relative',
                                                marginRight: '12px',
                                                boxShadow: '0px 2px 10px 2px rgba(103, 47, 143, 0.3)',
                                                border: 'none'
                                            }}>
                                            {cityLocalList.map((item, index) => (
                                                <MenuItem 
                                                    value={item?.name} 
                                                    key={index}>
                                                    {item?.name}
                                                </MenuItem>
                                            ))}  
                                        </Select>
                                    </div>
                                </div>
                                <div style={{
                                    marginBottom: '20px'
                                }} className="address-country-box">
                                    <div style={{
                                        width: '100%',
                                    }} className="address-aria">
                                        <InputLabel className="input-label-text">Full Address / Google Map Link</InputLabel>
                                        <TextField placeholder="" name="address" type="text" variant="outlined" className="inputShadow"
                                            value={isWebsiteFor === "coaching" ? coachingByToken?.data?.address : 
                                                universityByToken?.data?.address}
                                            disabled={(coachingByToken?.data?.address || universityByToken?.data?.address) && true}
                                            sx={{
                                                width: '100%',
                                                height: '62px'
                                            }} onChange={handleChange} required/>
                                    </div>
                                    {/* {isWebsiteFor === "university" &&
                                    <div style={{
                                        width: '100%',
                                    }} className="marginLeftR">
                                        <InputLabel className="input-label-text">Country</InputLabel>
                                        <Select
                                            required
                                            name="country"
                                            className="select-class"
                                            value={formInfo?.information?.country}
                                            onChange={handleChange}
                                            placeholder="course"
                                            autoWidth
                                            sx={{
                                                width: '100%',
                                                height: '56px',
                                                position: 'relative',
                                                marginRight: '12px',
                                                boxShadow: '0px 2px 10px 2px rgba(103, 47, 143, 0.3)',
                                                border: 'none'
                                            }}
                                            >
                                            {countryList.map((item, index) => (
                                                <MenuItem 
                                                    value={item?.country} 
                                                    key={index}>{item?.country}</MenuItem>
                                            ))}
                                        </Select>
                                    </div>} */}
                                    
                                </div>
                                <InputLabel className="input-label-text" sx={{textDecoration: 'underline'}}>
                                    About Institute
                                </InputLabel>
                                <div style={{
                                    marginBottom: '20px'
                                }} className="rich-text-box">
                                    <InputLabel className="input-label-text">
                                        {isWebsiteFor === "coaching" ? 'About Coaching' : 'About College'}
                                    </InputLabel>
                                    <JoditEditor 
                                        ref={editor}
                                        value={formInfo?.information?.about?.college}
                                        onChange={newText => richTextEditor(newText, "about_college")}
                                    />
                                </div>
                                <div style={{
                                    marginBottom: '20px'
                                }} className="rich-text-box">
                                    <InputLabel className="input-label-text">Infrastructure</InputLabel>
                                    <JoditEditor 
                                        ref={editor}
                                        value={formInfo?.information?.about?.infrastructure}
                                        onChange={newText => richTextEditor(newText, "infrastructure")}
                                    />
                                </div>
                                <div style={{
                                    marginBottom: '20px'
                                }} className="rich-text-box">
                                    <InputLabel className="input-label-text">Why Join Us</InputLabel>
                                    <JoditEditor 
                                        ref={editor}
                                        value={formInfo?.information?.about?.why_join_us}
                                        onChange={newText => richTextEditor(newText, "join_us")}
                                    />
                                </div>
                                <div style={{
                                    marginBottom: '20px'
                                }}>
                                    <InputLabel className="input-label-text">
                                        {isWebsiteFor === "coaching" ? "Coaching Official Website Link" : 
                                            "University Official Website Link"}
                                    </InputLabel>
                                    <TextField placeholder="" name="website_link" type="text" required variant="outlined" 
                                        className="inputShadow"
                                        value={formInfo?.information?.website_link}
                                        sx={{
                                            width: '100%',
                                            height: '62px'
                                        }} onChange={handleChange} />
                                </div>
                                <div style={{
                                    marginBottom: '20px'
                                }}>
                                    <InputLabel className="input-label-text">
                                        Relationship Manager Name
                                    </InputLabel>
                                    <TextField placeholder="" name="manager" type="text" required variant="outlined" 
                                        className="inputShadow"
                                        value={formInfo?.information?.relationship_manager?.name}
                                        sx={{
                                            width: '100%',
                                            height: '62px'
                                        }} onChange={handleChange} />
                                </div>
                                <div>
                                    <InputLabel className="input-label-text" sx={{textDecoration: 'underline'}}>
                                        Relationship Manager Contact Details
                                    </InputLabel>
                                    <div style={{
                                        display: 'flex',
                                        width: '100%',
                                        marginBottom: '12px'
                                    }}>
                                        <div style={{
                                            width: '100%',
                                            marginRight: '5%',
                                        }}>
                                            <InputLabel className="label-sub-text">Phone Number</InputLabel>
                                            <TextField placeholder="" name="m_phone" type="number" required variant="outlined" 
                                                className="inputShadow"
                                                value={formInfo?.information?.relationship_manager?.phone}
                                                sx={{
                                                    width: '100%',
                                                    height: '62px'
                                                }} onChange={handleChange} />
                                        </div>
                                        <div style={{
                                            width: '100%',
                                        }}>
                                            <InputLabel className="label-sub-text">Email</InputLabel>
                                            <TextField placeholder="" name="m_email" required variant="outlined" type="email" 
                                                className="inputShadow"
                                                value={formInfo?.information?.relationship_manager?.email}
                                                sx={{
                                                    width: '100%',
                                                    height: '62px'
                                                }} onChange={handleChange} />
                                        </div>
                                    </div>
                                    {isWebsiteFor === "university" && <div style={{
                                        display: 'flex',
                                        width: '100%',
                                        marginBottom: '12px'
                                    }}>
                                        <div style={{
                                            width: '100%',
                                            marginRight: '5%',
                                        }}>
                                            <InputLabel className="label-sub-text">Number of Course</InputLabel>
                                            <TextField placeholder="" name="number_of_course" type="number" required variant="outlined" 
                                                className="inputShadow"
                                                value={formInfo?.information?.courses_count}
                                                sx={{
                                                    width: '100%',
                                                    height: '62px'
                                                }} onChange={handleChange} />
                                        </div>
                                        <div style={{
                                            width: '100%',
                                        }}>
                                            <InputLabel className="label-sub-text">Students Enrolled</InputLabel>
                                            <TextField placeholder="" name="student_enrolled" required variant="outlined" type="number" 
                                                className="inputShadow"
                                                value={formInfo?.information?.enrolled_students_count}
                                                sx={{
                                                    width: '100%',
                                                    height: '62px'
                                                }} onChange={handleChange} />
                                        </div>
                                    </div>}
                                    {isWebsiteFor === "university" && <div style={{
                                        display: 'flex',
                                        width: '100%',
                                        marginBottom: '4%'
                                    }}>
                                        <div style={{
                                            width: '100%',
                                        }} className="placement-per">
                                            <InputLabel className="label-sub-text">Placement Percent(%)</InputLabel>
                                            <TextField placeholder="" name="placement_per" type="number" required variant="outlined" 
                                                className="inputShadow"
                                                value={formInfo?.information?.placement_percentage}
                                                sx={{
                                                    width: '100%',
                                                    height: '62px'
                                                }} onChange={handleChange} />
                                        </div>
                                        <div style={{
                                            width: '100%',
                                        }} className="input-placement-per">
                                        </div>
                                    </div>}
                                </div>
                            </div>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                marginTop: '48px',
                                marginBottom: '52px'
                            }}>
                                <Button className={classes.changesButton} onClick={saveAndNext} disabled={btnLoader} sx={{
                                    backgroundColor: btnLoader ? 'var(--disabled-color)' : (
                                        isWebsiteFor === 'coaching' ? 'var(--tertiary-color)' : 'var(--secondary-color)')
                                }}>
                                    {btnLoader ? <LoaderIcon/> : "Save & Next"}
                                </Button>
                                <Button 
                                    variant="outlined" 
                                    className={classes.cancelButton} 
                                    onClick={cancelClicked}
                                    sx={{
                                        backgroundColor: '#fff',
                                        color: isWebsiteFor === 'coaching' ? 'var(--tertiary-color)' : 'var(--secondary-color)',
                                        borderColor: isWebsiteFor === 'coaching' ? 'var(--tertiary-color)' : 'var(--secondary-color)',
                                        marginLeft: '16px'
                                    }}>
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </div>
                </section>
            </Box>
        </Box>
    );
}
