import * as React from 'react';
import HomeLayout from "../homeLayout/HomeLayout";
import UniversityTable from "../../components/BasicTable/BasicTable";
import Pagination from "../../components/Pagination/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import ZeroState from "../../assest/images/zero_state.png";
import Spinner from "../../components/Spinner/Spinner";
import routes from "../../react_routes_enum";
import { toast } from 'react-toastify';
import { 
    getStudentApplication,
    getStudentApplicationById
 } from "../../redux/students/studentSlice";
import {
    IconButton,
    Typography,
  } from "@mui/material";

export default function Products() {
    const navigate = useNavigate();
    const [limit, setLimit] = React.useState(10);
    const [offset, setOffset] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [isAddProduct, setIsAddProduct] = React.useState(false);
    const [userData, setUserData] = React.useState(null);
    const [universiApp, setUniversityApp] = React.useState([]);
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);
    const dispatch = useDispatch();
    const colR = ["SNo", "Name of Institute", "Application", "Status", "Action"];
    const { 
        isLoading,
        isError,
        totalCount,
        searchText,
        message } = useSelector((state) => state.student);
    
    React.useEffect(() => {
        const res = dispatch(getStudentApplication({limit, offset, searchText}));
        res?.then((r) => {
            if(r?.payload.statusCode === 200){
                let array = [];
                array = r?.payload?.data?.result?.filter((item) => {
                    return item?.university_name
                });
                setUniversityApp(array);
            }else{
                (r?.payload?.message === "Session Expired" || 
                    r?.payload?.message === "Unauthorized") ?
                    toast.error("Your session expired, please login"):
                    toast.error(r?.payload?.message)
            }
        })
    },[limit, offset, searchText]);

    React.useEffect(() => {
        setUserData(JSON.parse(localStorage.getItem('cc_user')))
        if(localStorage.getItem("isRefreshPage")){
            window.location.reload();
            localStorage.removeItem("isRefreshPage")
        }
    },[])
   
    const resetStateForPaginationOfDifferentView = () => {
        setLimit(10);
        setOffset(0);
        setPage(0);
    };
    const handleClose = () => {
        setIsDialogOpen(false);
    };

    const viewApplication = (id) => {
        localStorage.setItem("application_id", id);
    }
    const applicationDetail = (id) => {
        const res = dispatch(getStudentApplicationById({id}));
        res?.then((r) => {
            if(r?.payload?.statusCode === 200){
                navigate(routes.STUDENT_APP_DETAILS);
            }else{
                toast.error(r?.payload?.message)
            }
        })
    }

    if (isLoading && !isAddProduct) return <Spinner />;
    if (isError) return <h1>{message}</h1>;
    return (
      <>
        <HomeLayout />
        <div style={{
            marginTop: '100px'
        }}>
            {(message !== "Session Expired" && message !== "Unauthorized") &&
                <h3 className="welcome-title welcome-t">
                    Welcome <span style={{color: '#F69220'}}>
                    {(userData && userData?.student && userData?.student?.name) ? 
                        userData?.student?.name[0].toUpperCase() + 
                            userData?.student?.name.substring(1, userData?.student?.name.length) : ''}
                    </span>
                </h3>
            }
            <Pagination
                totalCount={totalCount}
                limit={limit}
                page={page}
                changeLimit={setLimit}
                changeOffset={setOffset}
                changePage={setPage}
            />
        </div>
        <div className="content-box table-box" style={{marginTop: '0px'}}>
            <>
                {universiApp?.length > 0 &&
                    <UniversityTable
                        columns={colR}
                        where="recipes"
                        rows={universiApp && universiApp?.map((item) => {
                            const res = {
                            ...item,
                            Status: (
                                <>
                                    {(item?.status === "pending" || 
                                        item?.status === "college_clue_accepted" ||
                                        item?.status === "target_pending") && 
                                        <span style={{
                                            color: 'var(--secondary-color)',
                                            fontWeight: 'bold'
                                        }} className='fontSizeT'>
                                            Pending
                                    </span>}
                                    {(item?.status === "rejected" ||
                                    item?.status === "target_rejected") && 
                                        <span style={{
                                            color: '#EF0000',
                                            fontWeight: 'bold'
                                        }} className='fontSizeT'>Rejected</span>}
                                    {item?.status === "registration_fee_paid" && 
                                        <span style={{
                                            color: '#07AB35',
                                            fontWeight: 'bold'
                                        }} className='fontSizeT'>
                                            Registration Fees Paid</span>}
                                    {item?.status === "target_upload_requested" && 
                                    <span style={{
                                        color: 'var(--secondary-color)',
                                        fontWeight: 'bold'
                                    }} className='fontSizeT'>
                                        Waiting for document upload</span>}
                                    {item?.status === "target_uploaded" && 
                                    <span style={{
                                        color: '#3F9AE0',
                                        fontWeight: 'bold'
                                    }} className='fontSizeT'>Uploaded</span>}
                                    {item?.status === "target_changes_requested" && 
                                    <span style={{
                                        color: 'var(--secondary-color)',
                                        fontWeight: 'bold'
                                    }} className='fontSizeT'>Need changes</span>}
                                    {item?.status === "target_accepted" && 
                                    <span style={{
                                        color: '#07AB35',
                                        fontWeight: 'bold'
                                    }} className='fontSizeT'>Document accepted</span>}
                                    {item?.status === "target_fee_pending" && 
                                    <span style={{
                                        color: 'var(--secondary-color)',
                                        fontWeight: 'bold'
                                    }} className='fontSizeT'>Waiting for fees</span>}
                                    {item?.status === "enrolled" && 
                                    <span style={{
                                        color: '#07AB35',
                                        fontWeight: 'bold'
                                    }} className='fontSizeT'>Completed</span>}
                                </>
                            ),
                            Application: (
                                <IconButton
                                    color="inherit"
                                    edge="start"
                                    disabled={item?.status === "enrolled"}
                                    onClick={() => {
                                        viewApplication(item._id)
                                    }}
                                    className="application-text">
                                    <Link to={item?.status !== "enrolled" && 
                                        routes.UNIVERSITY_APPLICATION}>
                                        <Typography component="p" variant="p" 
                                        sx={{
                                            color: '#3C1CDE',
                                            fontWeight: 'bold',
                                            textDecoration: 'underline',
                                            textUnderlineOffset: '4px'
                                            }}>
                                        application
                                        </Typography>
                                    </Link>
                                </IconButton>
                            ),
                            Action: (
                                <IconButton
                                    color="inherit"
                                    edge="start"
                                    onClick={() => {
                                        applicationDetail(item._id)
                                    }}
                                    className="application-text">
                                    <Typography component="p" variant="p" 
                                    sx={{
                                        color: '#3C1CDE',
                                        fontWeight: 'bold',
                                        textUnderlineOffset: '4px'
                                        }}>
                                        View
                                    </Typography>
                                </IconButton>
                            ),
                            };
                            return res;
                        })
                        }
                    />
                }
            </>
        </div>
        {(universiApp?.length === 0 || !universiApp) && 
        <div className="zero-image">
            <img src={ZeroState} alt="zeroStateImage" />
            <p className="zero-state-text">
                No University Applied Found
            </p>
        </div>}
      </>
    );
  }