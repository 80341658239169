import React from "react";
import { Routes, Route, Navigate, useLocation } from "react-router";
import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "./App.css";
import Login from "./pages/auth/Login";
import Layout from "./components/Layout/Layout";
import routes from "./react_routes_enum";
import "react-toastify/dist/ReactToastify.css";
import RequestForm from "./pages/home/RequestForm";
import RequestRes from "./pages/home/RequestResponse";
import Dashboard from "./pages/dashboard/dashboard";
import Information from "./pages/onBoard/information/information";
import Uploads from "./pages/onBoard/upload/upload";
import Courses from "./pages/onBoard/course/course";
import PlacementRecord from "./pages/onBoard/placementRecord/PlacementRecord";
import RequestResponse from "./pages/home/RequestResponse";
import RgistrationResponse from "./pages/home/RegistrationResonse";
import Products from "./pages/products/Products";
import ProductView from "./pages/products/ViewProduct";
import ProductEdit from "./pages/products/EditProduct";
import Signup from "./pages/auth/Signup";
import LandingPage from "./pages/landingPage/LandingPage";
import UniversityListing from "./pages/instituteListing/UniversityListing";
import CoachingListing from "./pages/instituteListing/CoachingListing";
import Checkout from "./pages/cart/Checkout";
import Cart from "./pages/cart/Cart";
import StudentDashboard from "./pages/students/Dashboard";  
import StudentCoaching from "./pages/students/Coaching"; 
import StudentUniversity from "./pages/students/University"; 
import UniversityApplication from "./pages/students/Application";
import InstituteStudent from "./pages/instituteStudent/Student";
import ContactUs from "./pages/contact/ContactUs";
import Partner from "./pages/contact/Partner";
import InstituteDetail from "./pages/instituteDetail/InstituteDetail";
import PendingApplication from "./pages/instituteStudent/Student";
import RegisterNowToApply from "./pages/instituteStudent/RegisterNowToApply";
import DocumentTable from "./pages/instituteStudent/DocumentTable";
import ApprovedStudent from "./pages/instituteStudent/ApprovedStudent";
import RejectedStudent from "./pages/instituteStudent/RejectedStudent";
import EnrolledStudent from "./pages/instituteStudent/EnrolledStudent";
import Blogs from "./pages/blogs/Blogs";
import BlogDetail from "./pages/blogs/BlogDetail";
import StudentsBlog from "./pages/studentsBlog/StudentsBlogs";
import StudentsBlogDetail from "./pages/studentsBlog/StudentsBlogDetail";
import Purchase from "./pages/students/Purchase";
import TestSeries from "./pages/students/TestSeries";
import PrivacyPolicy from "./pages/policy/PrivacyPolicy";
import TermsAndConditions from "./pages/policy/TermsAndCond";
import Cancellation from "./pages/policy/Cancellation";
import TestQuestion from "./pages/Question/TestQuestion";
import RecordedCourse from "./pages/Question/RecordedCourse";
import Profile from "./pages/profile/Profile";
import RecordedVideo from "./pages/recordedCourse/RecordedCourse";
import ApplicationDetail from "./pages/students/ApplicationDetails";
import HelpSupport from "./pages/help&Support/Help&Support";
import TicketDetail from "./pages/help&Support/TicketDetail";
import Tests from "./pages/tests/Test";
import Questions from "./pages/QuestionInst/Question";
import AddQuestion from "./pages/QuestionInst/AddQuestions";
import AddTest from "./pages/tests/AddTest";
import NewLead from "./pages/LGS/NewLead";
import LGS from "./pages/LGS/LGS";
import CourseCompleted from "./pages/recordedCourse/CourseCompleted";
import FirstAddonPage from "./pages/addons/FirstAddonPage";
import Exam from "./pages/addons/Exam";
import PartnerRequestForm from "./pages/home/PartnerRequestForm";
import PartnerDocument from "./pages/onBoard/partner/PartnerDocument";
import PartnerRegReq from "./pages/home/PartnerRegReq";
import ResetPassword from "./pages/auth/ResetPassword";
import Course from "./pages/Course/Course";
import Content from "./pages/Material/Material";
import AddContent from "./pages/Question/AddMaterial";

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        text: {
          fontWeight: "600 !important",
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#407BFF",
    },
  },
});

function App() {
  const { user } = useSelector((state) => state.auth);
  let location = useLocation();

  return (
    <>
      <ThemeProvider theme={theme}>
        {location.pathname === "/" && (
          <Navigate to={routes.LANDING_PAGE} replace />
        )}
        <Routes>
          <Route element={<Login />} path="/login" />
          <Route element={<Signup />} path="/signup" />
          <Route element={<ResetPassword />} path={routes.FORGOT_PASSWORD} />
          <Route element={<Dashboard />} path={routes.DASHBOARD}/>
          <Route element={<Products />} path={routes.PRODUCTS} />
          <Route element={<ProductView />} path={routes.PRODUCT_VIEW} />
          <Route element={<ProductEdit />} path={routes.PRODUCT_EDIT} />
          <Route element={<InstituteStudent />} path={routes.STUDENTS} />
          <Route element={<StudentUniversity />} path={routes.STUDENT_UNIVERSITY} />
          <Route element={<StudentCoaching />} path={routes.STUDENT_COACHING} />
          <Route element={<StudentDashboard />} path={routes.STUDENT_DASHBOARD} />
          <Route element={<UniversityApplication />} path={routes.UNIVERSITY_APPLICATION} />
          <Route element={<UniversityApplication />} path={routes.COACHING_APPLICATION} />
          <Route element={<RegisterNowToApply/> } path={routes.REGISTER_TO_APPLY}></Route>
          <Route element={<DocumentTable/>} path={routes.STUDENT_DOCUMENT} ></Route>
          <Route element={<ApprovedStudent/>} path={routes.APPROVED_STUDENT} ></Route>
          <Route element={<RejectedStudent/>} path={routes.REJECTED_STUDENT}></Route>
          <Route element={<EnrolledStudent/>} path={routes.STUDENT_ENROLLED}></Route>
          <Route element={<PendingApplication />} path={routes.STUDENT_PENDING} />
          <Route element={<Blogs />} path={routes.BLOG} />
          <Route element={<Content />} path={routes.CONTENT} />
          <Route element={<AddContent />} path={routes.ADD_CONTENT} />
          <Route element={<BlogDetail />} path={routes.VIEW_BLOG} />
          <Route element={<BlogDetail />} path={routes.CREATE_BLOG} />
          <Route element={<Purchase/>} path={routes.PURCHASES}></Route>
          <Route element={<TestSeries/>} path={routes.TEST_SERIES}></Route>
          <Route element={<TestQuestion/>} path={routes.TEST_QUESTION}></Route> 
          <Route element={<RecordedCourse/>} path={routes.RECORDED_COURSE}></Route> 
          <Route element={<Profile/>} path={routes.PROFILE}></Route>
          <Route element={<ApplicationDetail/>} path={routes.STUDENT_APP_DETAILS}></Route>
          <Route element={<RecordedVideo/>} path={routes.STUDENT_RECORDED_COURSE}></Route>
          <Route element={<HelpSupport/>} path={routes.HELP_SUPPORT}></Route>
          <Route element={<TicketDetail/>} path={routes.HELP_SUPPORT_DETAIL}></Route>
          <Route element={<Tests/>} path={routes.TESTS}></Route>
          <Route element={<Questions />} path={routes.QUESTIONS}></Route>
          <Route element={<AddQuestion />} path={routes.ADD_QUESTIONS}></Route>
          <Route element={<AddTest />} path={routes.ADD_NEW_TEST}></Route>
          <Route element={<AddTest />} path={routes.VIEW_TEST}></Route>
          <Route element={<AddTest />} path={routes.EDIT_TEST}></Route>
          <Route element={<AddQuestion />} path={routes.VIEW_QUESTION}></Route>
          <Route element={<AddQuestion />} path={routes.EDIT_QUESTION}></Route>
          <Route element={<LGS />} path={routes.LGS}></Route>
          <Route element={<Course />} path={routes.COURSE} />
          <Route element={<NewLead />} path={routes.CREATE_LEAD}></Route>
          <Route element={<NewLead />} path={routes.VIEW_LEAD}></Route>
          <Route element={<CourseCompleted />} path={routes.COURSE_COMPLETED}></Route>

          {/* <Route path="/" element={user ? <Home /> : <Navigate to="/login" replace />}>
            
            
          </Route> */}
          <Route
            path="/"
            element={<Layout />}
          >
            <Route element={<RequestForm />} path={routes.REQUEST_FORM} />
            <Route element={<PartnerRequestForm />} path={routes.PARTNER_REQUEST_FORM} />
            <Route element={<RequestRes />} path={routes.FORM_RESPONSE} />
            <Route element={<PartnerDocument />} path={routes.PARTNER_DOCUMENT} />
            <Route element={<Information />} path={routes.UNIVERSITY_INFORMATION} />
            <Route element={<Uploads />} path={routes.UNIVERSITY_UPLOAD} />
            <Route element={<Courses />} path={routes.UNIVERSITY_COURSE} />
            <Route element={<PlacementRecord />} path={routes.UNIVERSITY_PLACEMENT} />
            <Route element={<RequestResponse />} path={routes.UNIVERSITY_REQUEST_RESPONSE} />
            <Route element={<RequestResponse />} path={routes.COACHING_REQUEST_RESPONSE} />
            <Route element={<RequestResponse />} path={routes.PARTNER_REQUEST_RESPONSE} />

            <Route element={<RgistrationResponse />} path={routes.UNIVERSITY_REGISTRATION_RESPONSE} />
            <Route element={<RgistrationResponse />} path={routes.COACHING_REGISTRATION_RESPONSE} />
            <Route element={<PartnerRegReq />} path={routes.PARTNER_REGISTRATION_RESPONSE} />
            <Route element={<Information />} path={routes.COACHING_INFORMATION} />
            <Route element={<Uploads />} path={routes.COACHING_UPLOAD} />
            <Route element={<Courses />} path={routes.COACHING_COURSE} />
            
            <Route element={<LandingPage />} path={routes.LANDING_PAGE} />
            <Route element={<CoachingListing />} path={routes.COACHING_LISTING} />
            <Route element={<UniversityListing />} path={routes.UNIVERSITY_LISTING} />
            <Route element={<Cart />} path={routes.CART} />
            <Route element={<Checkout />} path={routes.CHECKOUT} />
            <Route element={<ContactUs />} path={routes.CONTACT_US} />
            <Route element={<Partner />} path={routes.PARTNER} />
            <Route element={<InstituteDetail/>} path={routes.COACHING_DETAIL}> </Route>
            <Route element={<InstituteDetail/>} path={routes.UNIVERSITY_DETAIL}> </Route>
            <Route element={<StudentsBlog />} path={routes.STUDENTS_BLOG} />
            <Route element={<StudentsBlogDetail />} path={routes.STUDENTS_BLOG_DETAIL} />
            <Route element={<PrivacyPolicy />} path={routes.PRIVACY_POLICY} />
            <Route element={<TermsAndConditions />} path={routes.TERMS_AND_CONDITIONS} />
            <Route element={<Cancellation />} path={routes.CACELLATION} />
            <Route element={<FirstAddonPage />} path={routes.COUNTRY_ADDON}></Route>
            <Route element={<FirstAddonPage />} path={routes.EXAM_DETAIL}></Route>
            <Route element={<Exam />} path={routes.EXAM_ADDON}></Route>
            <Route element={<h1>Page not found</h1>} path="*" />
          </Route>
        </Routes>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </ThemeProvider>
    </>
  );
}

export default App;
