import * as React from 'react';
import HomeLayout from "../homeLayout/HomeLayout";
import AddProduct from './AddProduct';
import Spinner from "../../components/Spinner/Spinner";
import { useSelector } from "react-redux";

const EditProduct = () => {
    const [isAddProduct, setIsAddProduct] = React.useState(false);
    const { 
        isLoading, 
        isError, 
        message, 
    } = useSelector((state) => state.product);

    if (isLoading && !isAddProduct) return <Spinner />;
    if (isError) return <h1>{message}</h1>;

    return (
        <>
            <HomeLayout />
            <div className="content-box table-box">
                <AddProduct isDisable={false} from="product_edit" setIsAddProduct={(arg) => setIsAddProduct(arg)} />
            </div>
        </>
    )
}

export default EditProduct;