import * as React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import routes from "../../react_routes_enum";
import PrepClueWhite from "../../assest/images/prepClueWhite.png";
import PrepClue from "../../assest/images/prepclue-2.png";
import CcWhiteLogo from "../../assest/svgs/ccWhiteLogo.svg";
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import CallIcon from '@mui/icons-material/Call';
import {setInstituteCat} from "../../redux/students/studentSlice";
import "./footer.css";

const contactDetail = [
    'Office No. 10-C, 10th Floor, Gopala Tower, Govind Lal Sikka Marg, Prasad Nagar, New Delhi, Delhi 110008',
    'info@prepclue.com',
    '(+91) - 93103 82181'
]
const courses = [
    'MBBS', 'BTECH', 'MBA', 'MCA', 'BCA'
]
const entranceCoach = [
    'NEET', 'NEET PG', 'UPSC', 'CUET', 'JEE'
]
export default function Footer() {
    const [isWebsiteFor, setIsWebsiteFor] = React.useState(localStorage.getItem("is-website-for"));
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const getInstituteByCat = (cat, type) => {
        dispatch(setInstituteCat(cat))
        type === 'univ' ? navigate(routes.UNIVERSITY_LISTING) : 
            navigate(routes.COACHING_LISTING)
    }
    return (
        <>
            <Box sx={{p: 3}}>
                <div className="laptop-footer">
                    <div className="footer-content">
                        <div>
                            <img src={PrepClueWhite} alt="logo" className="logo-white" />
                            <p style={{
                                color: 'white'
                            }} className="footer-small-desc">
                                PrepClue strives to empower students by providing 
                                comprehensive and effective coaching for entrance exams, 
                                competitive exams.
                            </p>
                            <div className="socail-media-icons">
                                <a href="https://www.facebook.com/CollegeClue22"
                                    target="_blank" rel="noreferrer">
                                    <FacebookOutlinedIcon sx={{
                                        color: 'white',
                                        marginRight: '4px'
                                    }}/>
                                </a>
                                <a href="https://instagram.com/college_clue?igshid=MzRlODBiNWFlZA=="
                                    target="_blank" rel="noreferrer">
                                    <InstagramIcon sx={{
                                        color: 'white',
                                        marginRight: '4px'
                                    }}/>
                                </a>
                                <a href="https://www.linkedin.com/company/collegeclue22/"
                                    target="_blank" rel="noreferrer">
                                    <LinkedInIcon sx={{
                                        color: 'white',
                                        marginRight: '4px'  
                                    }}/>
                                </a>
                                <a href="https://www.youtube.com/@collegeclue22"
                                    target="_blank" rel="noreferrer">
                                    <YouTubeIcon sx={{
                                        color: 'white'
                                    }}/>
                                </a>
                            </div>
                        </div>
                        <div>
                            <h2 className="footer-sub-heading">Contact Us</h2>
                            <ul>
                                {contactDetail.map((item, index) => (
                                    <li key={index}>
                                        {index === 0 && <LocationOnIcon />}
                                        {index === 1 && <EmailIcon />}
                                        {index === 2 && <CallIcon />}
                                        <span 
                                        style={{
                                            marginLeft: '4px',
                                            width: index === 0 ? '360px' : 'auto'
                                            }}>{item}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        {/* <div>
                            <h2 className="footer-sub-heading">Courses You Need</h2>
                            <ul>
                                {courses.map((item, index) => (
                                    <li 
                                        style={{cursor: 'pointer'}}
                                        key={index} 
                                        onClick={() => getInstituteByCat(item, "univ")}>
                                        {item} 
                                    </li>
                                ))}
                            </ul>
                        </div> */}
                        <div>
                            <h2 className="footer-sub-heading">Entrance Coaching</h2>
                            <ul>
                                {entranceCoach.map((item, index) => (
                                    <li 
                                        style={{cursor: 'pointer'}}
                                        key={index}
                                        onClick={() => getInstituteByCat(item, "coach")}>
                                        {item}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className="policy-box">
                        <div className="copyright">
                            Copyrights 2023 PrepClue. All rights reserved.
                        </div>
                        <div className="copyright-privacy">
                            <Link to={routes.PRIVACY_POLICY}>Privacy Policy | </Link>
                            <Link to={routes.CACELLATION}>Refund & Cancellation Policy | </Link>
                            <Link to={routes.TERMS_AND_CONDITIONS}>
                                Terms and Conditions
                            </Link>
                        </div>
                        <div></div>
                    </div>
                </div>
                <div className="mobile-footer">
                    <div className="footer-logo-box">
                        <img src={CcWhiteLogo} alt="logo" className="logo-white" />
                        <p style={{
                                color: 'white',
                                textAlign: 'center'
                            }} className="footer-small-desc">
                            PrepClue strives to empower students by providing 
                            comprehensive and effective coaching for entrance exams, 
                            competitive exams, and college admissions, internships and 
                            fellowships.
                        </p>
                    </div>
                    <div className="footer-mobile-course">
                        {/* <div style={{
                            width: '50%'
                        }}>
                            <h2 className="footer-sub-heading mobile-head-2">Courses You Need</h2>
                            <ul>
                                {courses.map((item, index) => (
                                    <li key={index} style={{
                                        display: 'inline-block',
                                    }}>
                                        {item}
                                    </li>
                                ))}
                            </ul>
                        </div> */}
                        <div>
                            <h2 className="footer-sub-heading mobile-head-2">Entrance Coaching</h2>
                            <ul>
                                {entranceCoach.map((item, index) => (
                                    <li key={index} style={{
                                        display: 'inline-block',
                                    }}>
                                        {item}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className="contact-in-footer">
                        <h2 className="footer-sub-heading mobile-head-2">
                            Contact
                        </h2>
                        <ul>
                            {contactDetail.map((item, index) => (
                                <li key={index} style={{
                                    marginBottom: '4px'
                                }}>
                                    {index === 0 && <LocationOnIcon fontSize="small" />}
                                    {index === 1 && <EmailIcon fontSize="small" />}
                                    {index === 2 && <CallIcon fontSize="small" />}
                                    <span 
                                    style={{
                                        marginLeft: '4px',
                                        width: index === 0 ? '200px' : 'auto'
                                        }}>{item}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="copyright-privacy privacy-aria">
                        <div style={{
                            width: '230px'
                        }}>
                            <Link to={routes.PRIVACY_POLICY}>Privacy Policy | </Link>
                            <Link to={routes.CACELLATION}>Refund & Cancellation Policy | </Link>
                            <Link to={routes.TERMS_AND_CONDITIONS}>Terms and Conditions</Link>
                        </div>
                    </div>
                    <div className="socail-media-icons" style={{
                        justifyContent: 'center',
                        margin: '24px 0'
                    }}>
                        <a href="https://www.facebook.com/CollegeClue22" 
                            target="_blank" rel="noreferrer">
                            <FacebookOutlinedIcon sx={{
                                color: 'white',
                                marginRight: '4px'
                            }}/>
                        </a>
                        <a href="https://instagram.com/college_clue?igshid=MzRlODBiNWFlZA==" 
                            target="_blank" rel="noreferrer">
                            <InstagramIcon sx={{
                                color: 'white',
                                marginRight: '4px'
                            }}/>
                        </a>
                        <a href="https://www.linkedin.com/company/collegeclue22/"
                            target="_blank" rel="noreferrer">
                            <LinkedInIcon sx={{
                                color: 'white',
                                marginRight: '4px'  
                            }}/>
                        </a>
                        <a href="https://www.youtube.com/@collegeclue22"
                            target="_blank" rel="noreferrer">
                            <YouTubeIcon sx={{
                                color: 'white'
                            }}/>
                        </a>
                    </div>
                    <div className="copyright copyright-mob">
                        Copyrights 2023 PrepClue. All rights reserved.
                    </div>
                </div>
            </Box>
        </>
    );
}
