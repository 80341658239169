import * as React from "react";
import { Box } from "@mui/material";
import "./privacy.css";
import { makeStyles } from "@mui/styles";

const useStyle = makeStyles({
    heading:{
        color: '#000',
        fontSize: '20px',
        textDecoration: 'underline',
        textUnderlineOffset: '4px'
    },
    heading2:{
        fontSize: '18px',
        fontStyle: 'italic',
        fontWeight: '400',
        textDecoration: 'underline',
    }
})

export default function TermsAndCond() {
    const classes = useStyle();
    
    return (
        <>
            <Box sx={{p: 3}}>
                <div style={{
                    marginTop: '140px'
                }}>
                    <h1 className={classes.heading} style={{
                        textAlign: 'center'
                    }}>
                        Please read the following carefully
                    </h1>
                    <p>
                        These terms and conditions (“Terms and Conditions”) control your use 
                        of this website CollegeClue.com(“Website”). In these Terms and 
                        Conditions, “CollegeClue International Pvt Ltd” is referred to as the 
                        “Company”, “us,” or “we.”<br/> 
                        ‘You’ refers to a user or a paying customer. If you are a company 
                        or another person who gives access to company products, you agree 
                        to take responsibility in full in case of damages or 
                        indemnification that could properly lie against the customer.<br/>
                        The CollegeClue International Pvt Ltd website (the ‘Site’), the 
                        educational services made available through the site and the 
                        content (the ‘Products’) are owned, operated and maintained, as 
                        applicable, by CollegeClue International Pvt Ltd (‘we’, ‘our’, ‘us’, 
                        or the ‘Company’). The Site, Products and Content are, collectively, 
                        the ‘Company Products’. By (a) using or accessing the Company 
                        Products, including, but not limited to downloading or accessing, (b) 
                        offering a Course through the Site or through Software; you agree to 
                        the terms and conditions set forth in these Terms of Use 
                        (the “Terms”)<br/> By using this website or its Products and Services, 
                        You Agree and Warrant that you have read, understood, and agreed 
                        to be Bound by these terms. Company’s privacy policy can be found 
                        at Privacy Policy. If you do not accept these terms, you must not 
                        use – and are not authorized to use – all or any portion of the 
                        company’s website and its products or services (as defined below).
                    </p>
                    <h1 className={classes.heading}>
                        Please read them carefully before you use the services of this site.
                    </h1>
                    <p>
                        <ul>
                            <li>
                                You should not use this site in an unlawful manner; you 
                                must respect the website terms and conditions and follow 
                                the Privacy Policy.
                            </li>
                            <li>
                                Under no situations or circumstances, will the company be 
                                liable for any change in the content which it provides on 
                                the website through its products and services, including 
                                but not limited to any errors, omissions, loss, or damage 
                                experienced in connection with the use of exposure, any 
                                content made available via our products, services, or 
                                various resources such as email, blog etc.
                            </li>
                            <li>
                                Our services are free to any user with access to the 
                                Internet. However, we are not responsible for the charges 
                                incurred for the usage of hardware, software, or internet 
                                services provider fee. Also, the user is fully responsible 
                                for the proper functioning of computer hardware and 
                                internet access.
                            </li>
                            <li>
                                You will be required to use login credentials for some of 
                                the sections on the site and the company reserves the 
                                right to block access to our services for any user who 
                                does not follow these conditions
                            </li>
                            <li>
                                We make sure that users get uninterrupted access to our 
                                service, but there is no obligation to do so.
                            </li>
                            <li>
                                CollegeClue International Pvt Ltd is not responsible and 
                                is not obligated for issues in your network or server 
                                beyond certain limits.
                            </li>
                        </ul>
                    </p>
                    <h1 className={classes.heading}>
                        Website usage guidelines
                    </h1>
                    <p>
                        <ul>
                            <li>
                                Do not insult, abuse, harass, stalk, threaten, or 
                                otherwise infringe the rights of others;
                            </li>
                            <li>
                                Do not publish, post, distribute or disseminate any 
                                defamatory, infringing, indecent, offensive or unlawful 
                                material or information.
                            </li>
                            <li>
                                Do not upload, install, transfer files which are 
                                protected by Intellectual Property Laws or software which 
                                affect other computers.
                            </li>
                            <li>
                                It’s prohibited to edit HTML source code, reverse engineer
                                or attempt to hack.
                            </li>
                            <li>
                                Do not run Spam services/scripts or anything which could 
                                affect the infrastructure, and in turn, the users.
                            </li>
                            <li>
                                You may not copy, distribute, and indulge in plagiarism 
                                with website content or user submitted content.
                            </li>
                            <li>
                                Do not use any content which violates any legal, 
                                regulatory, Governmental or network operator conditions 
                                or codes of practice.
                            </li>
                        </ul>
                    </p>
                    <h1 className={classes.heading}>
                        Links and Hyperlinks Terms
                    </h1>
                    <p>
                        This website may have links to other websites. We do not undertake 
                        any control on the content of these websites; nor are we 
                        responsible for their website content. The sole purpose of the 
                        links included are to provide users information. Hence, 
                        CollegeClue International Pvt Ltd will not be held responsible.<br/>
                        <ul>
                            <li>
                                You may not mirror or frame the homepage or any other 
                                pages of this Site on any other website or webpage.
                            </li>
                            <li>
                                Do not link to CollegeClue International Pvt Ltd pages 
                                and subpages with spam links/anchor text, which could 
                                provide a false impression. This may create 
                                misunderstanding for the users.
                            </li>
                            <li>
                                Do not use or include copyrighted or registered 
                                trademarks, or Intellectual property images, design or 
                                content as a link to CollegeClue International Pvt Ltd 
                                website.
                            </li>
                            <li>Do not link to pages which support racism, terrorism.</li>
                            <li>
                                Do not link to pages which provide pornographic content and violate human and animal rights.
                            </li>
                            <li>
                                Do not link pages to content which infringes the 
                                intellectual property of any third party, person or 
                                entity.
                            </li>
                            <li>
                                Do not link pages to content which violates any legal, 
                                regulatory, Governmental or network operator conditions 
                                or codes of practice.
                            </li>
                        </ul>
                    </p>
                    <h1 className={classes.heading}>
                        Copyright and Intellectual Property
                    </h1>
                    <p>
                        We value and respect others intellectual property and expect our users 
                        to do the same. The entire contents of the Site are protected by 
                        copyright and trademark laws. The owner of the copyrights and 
                        trademarks are CollegeClue International Pvt Ltd its affiliates, or 
                        other third party licensors. The material on the site, including text,
                        graphics, code and/or software is copyrighted and belongs to 
                        CollegeClue International Pvt Ltd, therefore you may not duplicate, 
                        modify, publish, or reproduce the content in any manner. <br/>
                        CollegeClue International Pvt Ltd does not take any responsibility for the 
                        content on other sites (except our partners and affiliates), that you 
                        may find when searching or accessing CollegeClue International Pvt Ltd 
                        products or services. The Privacy Policy and Terms of Use of the 
                        sites that you visit will administer that material. <br/>
                        CollegeClue International Pvt Ltd has all the rights to disable or prohibit access 
                        to the users who do not respect and involve in the infringement of 
                        CollegeClue International Pvt Ltd intellectual property. <br/>
                        You are not allowed to use any of the digital images or logos from the website. 
                        In case of copyright issues, there has to be a written consent from 
                        the trademark owner.
                    </p>
                    <h1 className={classes.heading}>
                        Claims of Intellectual Property Violations
                    </h1>
                    <p>
                        If you believe that your work has been used without your 
                        permission in a way which prompts for copyright infringement. 
                        Please provide us with the information given below and we will 
                        act on it.<br/>
                        <ul>
                            <li>
                                The authorized person who will act on behalf of the owner 
                                of the copyright, should send a digital or physical 
                                signature.
                            </li>
                            <li>
                                A description of the copyrighted work that you claim to 
                                be infringing your IP.
                            </li>
                            <li>
                                A description of where and how the material that you 
                                claim is infringing is located on the CollegeClue 
                                International Pvt Ltd website, with enough detail that we 
                                may find it on the website.
                            </li>
                            <li>
                                Contact Details – Address, telephone number, and email 
                                address.
                            </li>
                            <li>
                                A statement by you, that the information which you 
                                provided is accurate and your claim of the copyright or 
                                intellectual property is on your owner’s behalf
                            </li>
                            <li>
                                You can reach CollegeClue International Pvt Ltd to 
                                notify your claims of copyright by writing to 
                                info@collegeclue.com
                            </li>
                        </ul>
                    </p>
                    <h1 className={classes.heading}>
                        Transaction Terms
                    </h1>
                    <p>
                        If you believe that your work has been used without your 
                        permission in a way which prompts for copyright infringement. 
                        Please provide us with the information given below and we will 
                        act on it.<br/>
                        <ul>
                            <li>
                                To make a transaction on CollegeClue International Pvt 
                                Ltd website, you are bound to pay for that transaction.
                            </li>
                            <li>
                                Please pay close attention to your payment details such 
                                as total bill, taxes, shipping costs, and discounts.
                            </li>
                            <li>
                                There are certain products which require additional 
                                Terms and Conditions which you have to agree to before 
                                you make the purchase.
                            </li>
                            <li>
                                If a delegate has completed the course and is eligible 
                                for a certificate, then same shall be delivered to the 
                                address provided by the delegate within 30 working days.
                            </li>
                        </ul>
                    </p>
                    <p>
                        WE MAKE NO WARRANTIES OF ANY KIND, EXPRESSED OR IMPLIED, WITH 
                        RESPECT TO ANY PRODUCTS OR SERVICES SOLD ON OR THROUGH 
                        COLLEGECLUE INTERNATIONAL PVT LTD.<br/>
                        No additional or different terms contained in any purchase order, 
                        document, transmission or other communication shall be binding 
                        upon CollegeClue International Pvt Ltd unless agreed to by 
                        CollegeClue International Pvt Ltd in writing.<br/>
                        CollegeClue International Pvt Ltd reserves the right to modify, 
                        change without prior notice and in its sole discretion, to limit 
                        the order quantity on any item and to refuse service to anyone.<br/>
                        Subscription Services<br/>
                        You will be allowed to subscribe to a particular course or 
                        collection of courses (“Subscription Services”) and will be 
                        charged automatically, depending on payment frequency chosen by 
                        you, such as monthly/quarterly/yearly, as may be applicable.<br/>
                        You will be permitted to freeze or schedule the freeze of the 
                        Subscription Services at anytime and CollegeClue International 
                        Pvt Ltd shall not charge you for any such opted Subscription 
                        Services according to the payment cycle. Further, the Subscription 
                        Services, access will be revoked if the user opts to freeze the 
                        subscription. You will have the option to re-subscribe to the 
                        particular Subscription Services again and the payment cycle will 
                        start accordingly. Please note that Subscription Services 
                        discounts and any Subscription Services are only a limited time 
                        special subscription promotions in effect apply only to eligible 
                        items displaying the offer message on the Subscription Services 
                        item information pages, and then only if you select the 
                        Subscription Services. Subscription Services are will last only 
                        till a particular period of time, as may be specified on the 
                        relevant page. Special limited time subscription promotions only 
                        apply during their effective dates.<br/>
                        CollegeClue International Pvt Ltd retains the rights to modify 
                        the Subscription Service from time to time, including by adding 
                        or deleting features and functions, in an effort to improve your 
                        experience. But we will not make changes to the Subscription 
                        Service that materially reduces the functionality of the 
                        Subscription Service provided to you during the Subscription 
                        Term. We might provide some or all elements of the Subscription 
                        Service through third party service providers.<br/>
                        If you register for a free trial of the Subscription Service, 
                        we will make the Subscription Service available to you on a trial 
                        basis, free of charge until the earlier of (a) the end of the 
                        free trial period or (b) the start date of your subscription. 
                        If we include additional terms and conditions on the trial 
                        registration web page, those will apply as well. During the free 
                        trial period, (i) the Subscription Service is provided “as is” 
                        and without warranty of any kind, (ii) we may suspend, limit, or 
                        terminate the Subscription Service for any reason at any time 
                        without notice, and (iii) we will not be liable to you for 
                        damages of any kind related to your use of the Subscription 
                        Service. Unless you subscribe to the Subscription Service before 
                        the end of the free trial, all of your data on the Subscription 
                        Service will be permanently deleted at the end of the trial, 
                        and we will not recover it. CollegeClue International Pvt Ltd 
                        shall not refund or in any ways discount, set off any amount once 
                        the trial period has ended. In case where You wish discontinue to 
                        paid Services, please login to your account. Go to Profile{'>'} 
                        Manage Subscription, click on Subscription and Freeze the 
                        subscription to stop any further payments being made.<br/>
                        Valid credit cards are the only payment method accepted for 
                        Subscription Services purchases. The customer shall at all time 
                        use a credit card for the use of Subscription Services and upon 
                        the completion of the free trial period, you will be charged 
                        automatically without the requirement of any further approval or 
                        consent, such a period of the trial period shall be mentioned on 
                        the at the time of registration to the Subscription Services, 
                        CollegeClue International Pvt Ltd absolves any liability with 
                        regard to information of period of the free trial period. It is 
                        clarified that, once the Subscription Services are accepted the 
                        participants authorize CollegeClue International Pvt Ltd and any 
                        of its affiliates to debit the accounts at regular intervals as 
                        may be notified to the participants<br/>
                        Further, you will have the option of cancelling the Subscription 
                        Services during the free trial period, without incurring any 
                        charges for the same. To initiate the free trial, CollegeClue 
                        International Pvt Ltd shall charge you a token amount to ensure 
                        the validity of Credit Card. The token amount will be refunded 
                        automatically within 5 days. Further You may cancel your account 
                        with CollegeClue International Pvt Ltd by reaching out to our 
                        support email mentioned below.<br/>
                        Your Subscription Services will remain in effect until it is 
                        cancelled. If you cancel your Subscription Services and then 
                        reactivate it, the discount applied to any Subscription Services 
                        item may not be the same discount in effect at the time of 
                        cancellation. If you reactivate a Subscription Services, it will 
                        be created with the same products with which you previously 
                        subscribed. If the Subscription Services discount percentage for 
                        such Subscription Services item changes, the new discount will be 
                        applied to your products of that item.<br/>
                        All fees are exclusive of taxes, which we will charge as 
                        applicable. You agree to pay any taxes applicable to your use of 
                        the Subscription Service. All fees are exclusive of any VAT, 
                        Service tax, GST or any other applicable law and tax deductions. 
                        If you are required to deduct or withhold any tax, you must pay 
                        the amount deducted or withheld as required by law and pay us an 
                        additional amount so that we receive payment in full as if there 
                        were no deduction or withholding.
                    </p>
                    <h1 className={classes.heading}>
                        LIMIT OF LIABILITY
                    </h1>
                    <p>
                        You expressly understand and agree that the Company shall not be 
                        liable for any direct, indirect, incidental, special, 
                        consequential or exemplary damages, including, damages for loss 
                        of profits, goodwill, use, data or other intangible losses 
                        (even if the Company has been advised of the possibility of such 
                        damages), resulting from: (i) the use or the inability to use the 
                        service; (ii) the cost of procurement of substitute goods and 
                        services resulting from any goods, or services purchases or 
                        obtained or messages received or transactions entered into 
                        through or from the Website or otherwise; (iii) unauthorized 
                        access to or alteration of your transmissions or data or 
                        confidential information; (iv) statements or conduct of any third 
                        party on the Products; (v) or (v) any other matter relating to 
                        the Products.
                    </p>
                    <h1 className={classes.heading}>
                        INDEMNITY
                    </h1>
                    <p>
                        You agree to indemnify and hold the Company and our subsidiaries, 
                        affiliates, officers, directors, agents, and employees, harmless 
                        from any claim or demand (including legal expenses and the 
                        expenses of other professionals) made by a third party due to or 
                        arising out of your breach of this Terms of Use or the documents 
                        it incorporates by reference, or your violation of any law or the 
                        rights of a third party.<br/>
                        Pricing Disclaimer<br/>
                        All prices, products, and offers of CollegeClue International Pvt 
                        Ltd website are subject to change without notice.<br/>
                        While we make sure to provide most accurate and up-to-date 
                        information, in some cases one or more items on our website may 
                        be priced incorrectly. This might happen due to human errors, 
                        digital images, technical errors, or a mismatch in pricing 
                        information received from our suppliers.<br/>
                        CollegeClue International Pvt Ltd reserves the right to change 
                        prices for all our products, offers, or deals. These changes are 
                        done due to market conditions, course termination, providers, 
                        price changes, errors in advertisements, and other mitigating 
                        circumstances. However, the price you paid at the time of purchase 
                        still holds for you.<br/>
                        Proprietary Use of CollegeClue International Pvt Ltd Study 
                        Material<br/>
                        CollegeClue International Pvt Ltd reserves the right to change 
                        prices for all our products, offers, or deals. These changes are 
                        done due to market conditions, course termination, providers, 
                        price changes, errors in advertisements and other extenuating 
                        circumstances. However, the price you paid at the time of 
                        purchase still holds for you.
                    </p>
                    <h1 className={classes.heading} style={{
                        textDecoration: 'none',
                    }}>
                        COLLEGECLUE INTERNATIONAL PVT LTD
                    </h1>
                    <p>
                        Address: 10C, Gopala Tower, East Patel Nagar, New Delhi<br/>
                        Email: <span style={{
                            color: 'blue',
                            fontWeight: 'bold'}}>cc@collegeclue.com</span><br/>
                        Phone: +91-9310382181
                    </p>
                </div>
            </Box>
        </>
    );
}
