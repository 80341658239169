import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Input,
  IconButton,
  InputAdornment,
  Typography,
  InputLabel,
  TextField,
  Select,
  MenuItem,
} from "@mui/material";
import routes from "../../react_routes_enum";
import { makeStyles } from "@mui/styles";
import whyUsImage from "../../assest/svgs/why-choose-us.svg";
import BenefitPointIcon from "../../assest/images/benefit-point-icon.jpg";
import SearchIcon from "../../assest/images/search-icon.jpg";
import FormImage from "../../assest/images/form-image.png";
import OtpInput from 'react-otp-input';
import { useNavigate } from "react-router-dom";
import validator from "validator";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { HashLink } from "react-router-hash-link/dist/react-router-hash-link.cjs.development";
import getImageUrls from "../../api/uploadImageOnS3";
import { 
    updateData,
    postNewFormRequest, 
    verify, 
    sendOTP,
    getCoachingByToken,
    getUniversityByToken } from "../../redux/requestForm/requestFormSlice";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import Loader from "../../assest/images/preloader.gif";
import { toast } from "react-toastify";
import { getConfigDetails } from "../../redux/configurationDetails/configDetailsSlice";
import LoaderIcon from "../../components/Loader/Loader";
import HeaderImage from "../../assest/images/hero_image.jpg";
import PartnerImage from "../../assest/images/partner-registration.png";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
const countryList = require("../../data/country.json");

var nameValidator = /^[a-zA-Z][a-zA-Z\s]*$/

const useStyle = makeStyles({
    benefitSingleBox: {
        display: "flex",
        marginBottom: '24px',
        alignItems: "center",
    },
    howItWorkSingleBox: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginBottom: '24px',
        alignItems: "center",
    },
    buttonStyle: {
        background: 'none',
        textTransform: 'capitalize !important',
        position: 'absolute !important',
        right: 0,
        top: 8,
    },
    verifySubmit: {
        height: '36px',
        textTransform: 'capitalize !important',
        color: '#FFFFFF !important',
        width: '150px',
        '&:hover':{
            backgroundColor: '#DC0707 !important',
        },
    },
    marginBot24: {
        marginBottom: '24px'
    },
    formInputLabel: {
        color: '#000 !important',
        fontSize: '18px !important',
        marginBottom: '4px',
        fontWeight: 'bold !important'
    },
    uploadButton: {
        height: '80px',
        border: '2px dashed rgb(0,0,0,0.5) !important',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#FFFFFF !important',
        "&:hover": {
            backgroundColor: '#ffffff !important',
        }
    },
    requestButton: {
        color: '#fff !important',
        textTransform: 'capitalize !important',
        width: '180px',
        height: '44px',
        borderRadius: '24px !important',
        boxShadow: '0 2px 5px rgb(0, 0, 0, 0.5)',
        "&:hover":{
            backgroundColor: 'var(--secondary-color) !important',
        }
    }
})

let formData = {
    email: "",
    password: "",
    name: "",
    university_name: "",
    coaching_name: "",
    designation: "",
    address: "",
    phone: "",
    whatsapp_number: "",
    address_proof: "",
    phone_country_code: "91",
    verification_id_email: "",
    verification_id_phone: ""
}

export default function RequestForm() {
    const [isMobileVerified, setIsMobileVerified] = React.useState(false);
    const [isEmailVerified, setIsEmailVerified] = React.useState(false);
    const [showPassword, setShowPassword] = React.useState("");
    const [otp, setOtp] = React.useState();
    const [isLoader, setIsLoader] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [from, setFrom] = React.useState("");
    const [requestLoading, setRequestLoading] = React.useState(false);
    const [mobileLoading, setMobileLoading] = React.useState(false);
    const [emailLoading, setEmailLoading] = React.useState(false);
    const [isEmailOtpField, setIsEmailOtpField] = React.useState(false);
    const [isPhoneOtpField, setIsPhoneOtpField] = React.useState(false);
    const [isWebsiteFor, setIsWebsiteFor] = React.useState("");
    const [errors, setErrors] = React.useState(false);
    const classes = useStyle();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { university_designation } = useSelector(
        (state) => state.config
    );
    const {
        universityByToken,
        coachingByToken 
    } = useSelector((state) => state.form);

    const [formInformation, setFormInformation] = React.useState((coachingByToken?.data || universityByToken?.data) ?
        {
            email: isWebsiteFor === "coaching" ? coachingByToken?.data?.email : universityByToken?.data?.email,
            password: isWebsiteFor === "coaching" ? coachingByToken?.data?.password : universityByToken?.data?.password,
            name: universityByToken?.data?.name,
            university_name: isWebsiteFor === "coaching" ? coachingByToken?.data?.coaching_name : universityByToken?.data?.university_name,
            designation: universityByToken?.data?.designation,
            address: isWebsiteFor === "coaching" ? coachingByToken?.data?.address : universityByToken?.data?.address,
            phone: isWebsiteFor === "coaching" ? coachingByToken?.data?.phone : universityByToken?.data?.phone,
            whatsapp_number: isWebsiteFor === "coaching" ? coachingByToken?.data?.whatsapp_number : universityByToken?.data?.whatsapp_number,
            address_proof: isWebsiteFor === "coaching" ? coachingByToken?.data?.address_proof : universityByToken?.data?.address_proof,
            phone_country_code: isWebsiteFor === "coaching" ? coachingByToken?.data?.phone_country_code : universityByToken?.data?.phone_country_code,
            verification_id_email: isWebsiteFor === "coaching" ? coachingByToken?.data?.verification_id_email : universityByToken?.data?.verification_id_email,
            verification_id_phone: isWebsiteFor === "coaching" ? coachingByToken?.data?.verification_id_phone : universityByToken?.data?.verification_id_phone
        }
        :
        formData
    );

    React.useEffect(() => {
        if(localStorage.getItem("isRefresh")){
            window.location.reload();
            localStorage.removeItem("isRefresh");
        }
        setIsWebsiteFor(localStorage.getItem("is-website-for"));
        if(localStorage.getItem("cc_token")){
            const res = isWebsiteFor === "coaching" ? dispatch(getCoachingByToken()) : dispatch(getUniversityByToken());
            res?.then((r) => {
                if(r?.payload?.statusCode === 200) {
                    setFormInformation({
                        email: r?.payload?.data?.email,
                        password: r?.payload?.data?.password,
                        name: r?.payload?.data?.name,
                        coaching_name: r?.payload?.data?.coaching_name,
                        university_name: isWebsiteFor === "coaching" ? r?.payload?.data?.owner_name : r?.payload?.data?.university_name,
                        designation: r?.payload?.data?.designation,
                        address: r?.payload?.data?.address,
                        phone: r?.payload?.data?.phone,
                        whatsapp_number: r?.payload?.data?.whatsapp_number,
                        address_proof: r?.payload?.data?.address_proof,
                        phone_country_code: r?.payload?.data?.phone_country_code,
                        verification_id_email: r?.payload?.data?.verification_id_email,
                        verification_id_phone: r?.payload?.data?.verification_id_phone
                    })
                }
            })
        }
        if(!university_designation) dispatch(getConfigDetails());
    },[])

    const handleChange = (event) => {
        event.target.name === 'name' && setFormInformation({...formInformation, university_name: event.target.value})
        event.target.name === 'coaching-name' && setFormInformation({...formInformation, coaching_name: event.target.value})
        event.target.name === 'chairperson-name' && setFormInformation({...formInformation, name: event.target.value})
        event.target.name === 'designation' && setFormInformation({...formInformation, designation: event.target.value})
        event.target.name === 'address' && setFormInformation({...formInformation, address: event.target.value})
        event.target.name === 'phone-country-code' && setFormInformation({...formInformation, phone_country_code: event.target.value})
        event.target.name === 'phone-number' && setFormInformation({...formInformation, phone: event.target.value})
        event.target.name === 'whatsapp-number' && setFormInformation({...formInformation, whatsapp_number: event.target.value})
        event.target.name === 'email' && setFormInformation({...formInformation, email: event.target.value})
        event.target.name === 'password' && setFormInformation({...formInformation, password: event.target.value})
        if(event.target.name === "image-upload"){
            if(event.target?.files[0]?.size > 1049000){
                toast.error("Image size must be less than 1MB");
                return;
            }else{
                handleUploadFilesOnS3(event.target.files[0]);
            }
        }
    };

    const handleUploadFilesOnS3 = async(image) => {
        const response = await getImageUrls({
            image: image,
            setLoading,
            setErrors,
        });
        setFormInformation({...formInformation, address_proof: response?.data?.urls[0]})
    }
    const handleOtpChange = (value) => {
        setOtp(value);
    }
    const requestBtnClicked = () => {
        if(validate().status){
            setRequestLoading(true);
            if(localStorage.getItem("cc_token")){
                let body = {}
                if(isWebsiteFor === "coaching"){
                    body = {
                        address: formInformation?.address,
                        address_proof: formInformation?.address_proof
                    }
                }else{
                    body = {
                        designation: formInformation?.designation,
                        address: formInformation?.address,
                        address_proof: formInformation?.address_proof
                    }
                }
                let res = dispatch(updateData({isWebsiteFor, body}));
                res.then((r) => {
                    setRequestLoading(false);
                    if(r.payload.statusCode === 200){
                        toast.success(r?.payload?.message);
                        navigate(routes.UNIVERSITY_REQUEST_RESPONSE);
                    }else{
                        toast.error(r?.payload?.message);
                    }
                })
            }else{
                formInformation.isWebsiteFor = isWebsiteFor;
                const res = dispatch(postNewFormRequest(formInformation));
                res.then((r) => {
                    setRequestLoading(false)
                    if(r.payload.statusCode === 200){
                        toast.success(r?.payload?.message);
                        localStorage.setItem("cc_token", r?.payload.data.token)
                        dispatch(getUniversityByToken())
                        isWebsiteFor === "coaching" ? navigate(routes.COACHING_REQUEST_RESPONSE) : 
                            navigate(routes.UNIVERSITY_REQUEST_RESPONSE);
                    }else{
                        toast.error(r?.payload?.message);
                    }
                });
            }
        }else{
            toast.error(validate().message)
        }
    }
    const verifyFunc = (type) => {
        if(type === "Mobile"){
            if(formInformation.phone === "" || formInformation.phone.length !== 10){
                toast.error("Invalid Mobile Number");
                return;
            }else{
                setMobileLoading(true);
                let verifyInfo = {
                    phone: formInformation.phone,
                    phone_country_code: formInformation.phone_country_code,
                    type: "phone"
                }
                const res = dispatch(verify(verifyInfo));
                res.then((r) => {
                    setMobileLoading(false);
                    if(r.payload.statusCode === 200){
                        setIsMobileVerified(true);
                        setIsPhoneOtpField(false);
                        setFormInformation({...formInformation, verification_id_phone: r?.payload?.data})
                    }
                    toast.success(r?.payload?.message);
                });
            }
        }else{
            if(!validator.isEmail(formInformation.email)){
                toast.error("Invalid Email");
                return;
            }
            if(otp === ""){
                toast.error("OTP Required");
                return;
            }
            if(otp?.length !== 4){
                toast.error("Invalid OTP Entered");
                return;
            }
            else{
                setEmailLoading(true);
                let verifyInfo = {
                    email: formInformation.email,
                    type: "email",
                    otp: otp
                }
                const res = dispatch(verify(verifyInfo));
                res?.then((r) => {
                    setEmailLoading(false);
                    if(r?.payload?.message === "Verified"){
                        toast.success(r?.payload?.message);
                        setIsEmailVerified(true);
                        setIsEmailOtpField(false);
                        setFormInformation({...formInformation, verification_id_email: r?.payload?.data});
                        return;
                    }
                    toast.error(r?.payload?.message);
                });
            }
        }
    }

    const validate = () => {
        let error = {status: true, message: ""}
        if(isWebsiteFor === "university"){
            if(formInformation.university_name === ""){
                error.status = false;
                error.message = "University Name Required";
                return error;
            }
            if(formInformation.university_name.length < 4 || !nameValidator.test(formInformation.university_name)){
                formInformation.university_name.length < 4 ? (error = {status: false, message: "University Name must be at least 4 characters"}) :
                (error = {status: false, message: "Invalid University Name"})
                return error;
            }
            if(formInformation.name === ""){
                error.status = false;
                error.message = "Chairperson Name Required";
                return error;
            }
            if(formInformation.name.length < 4 || !nameValidator.test(formInformation.name) || 
                formInformation.name.length > 20){
                formInformation.name.length < 4 ? (error = {status: false, message: "HOD or Chairperson Name must be at least 4 characters"}) :
                (error = {status: false, message: "Invalid HOD or Chairperson Name"})
                formInformation.name.length > 20 && (error = {status: false, message: "HOD or Chairperson Name must be less than 20 characters"})
                return error;
            }
            if(formInformation.designation === ""){
                error.status = false;
                error.message = "Person Designation Required";
                return error;
            }
            
        }else{
            if(formInformation.university_name === ""){
                error.status = false;
                error.message = "Owner Name Required";
                return error;
            }
            if(formInformation.university_name.length < 4 || !nameValidator.test(formInformation.university_name) || 
                formInformation.university_name.length > 20){
                formInformation.university_name.length < 4 ? (error = {status: false, message: "Owner name must be at least 4 characters"}) :
                (error = {status: false, message: "Invalid Owner Name"})
                formInformation.university_name.length > 20 && (error = {status: false, message: "Owner name must be less than 20 characters"})
                return error;
            }
            if(formInformation.coaching_name === ""){
                error.status = false;
                error.message = "Coaching Name Required";
                return error;
            }
            if(formInformation.coaching_name.length < 4 || !nameValidator.test(formInformation.coaching_name)){
                formInformation.coaching_name.length < 4 ? (error = {status: false, message: "Coaching name must be at least 4 characters"}) :
                (error = {status: false, message: "Invalid Coaching Name"})
                return error;
            }
            
        }
        //coaching
        if(formInformation.address === "" || formInformation.address.length < 6){
            error.status = false;
            error.message = formInformation.address === "" ? "Address Required" : "Address must be at least 6 characters long";
            return error;
        }
        if(formInformation.phone_country_code === ""){
            error.status = false;
            error.message = "Phone Country Code Required";
            return error;
        }
        if(formInformation.phone === "" || formInformation.phone.length !== 10){
            error.status = false;
            error.message = "Invalid Phone Number";
            return error;
        }
        if(formInformation.whatsapp_number === "" || formInformation.whatsapp_number.length !== 10){
            error.status = false;
            error.message = "Invalid WhatsApp Number";
            return error;
        }
        if(!validator.isEmail(formInformation.email)){
            error.status = false;
            error.message = "Invalid Email";
            return error;
        }
        if(formInformation.password === "" || formInformation.password.length < 8){
            error.status = false;
            error.message = "Password Must Be Greater Than 8 characters";
            return error;
        }
        if(formInformation.address_proof === ""){
            error.status = false;
            error.message = "Address Proof Required";
            return error;
        }
        // if(formInformation.verification_id_phone === ""){
        //     error.status = false;
        //     error.message = "Phone Not Verified";
        //     return error;
        // }
        if(formInformation.verification_id_email === ""){
            error.status = false;
            error.message = "Email Not Verified";
            return error;
        }
        return error;
    }

    const verifyButtonClicked = (type) => {
        if(!validator.isEmail(formInformation.email)){
            toast.error("Invalid Email");
            return;
        }
        let verifyInfo = {
            email: formInformation.email,
            type: "email"
        }
        setFrom(type)
        setIsLoader(true);
        const res = dispatch(sendOTP(verifyInfo));
        res?.then((r) => {
            setIsLoader(false);
            if(r?.payload?.statusCode === 200){ 
                setIsEmailOtpField(true);
                toast.success("OTP Sent Successfully");
            }else{
                toast.error("Server Error");
            }
        })
    }

    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);

    return (
        <Box sx={{p: 3}}>
            <section style={{
                marginTop: '120px',
                marginBottom: '4%',
            }} className="header-carousel request-form-header">
                <Carousel>
                    <div style={{position: 'relative'}} className="paddingBot">
                        <img 
                            src={HeaderImage} 
                            alt="HeroImage" 
                            style={{width: '100%'}} />
                        <div style={{
                            position: 'absolute',
                            zIndex: 1,
                            top: 0
                            }} className="carousel-card">
                            <Typography component="h2" variant="h2" 
                            className="page-heading" sx={{
                                textAlign: 'left',
                            }}>
                                Get more admissions to <br/> your Coaching
                            </Typography>
                            <Typography 
                                component="p" 
                                variant="p" 
                                className="header-small-text">
                                Find talented students from India looking for admissions, 
                                and list your coaching to get more 
                                potential students for free
                            </Typography>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'flex-start'
                            }}>
                                <HashLink to="/request-form/#InstituteRequestForm"
                                    className={`${classes.requestButton} req-button`} 
                                    // onClick={requestBtnClicked}
                                    style={{backgroundColor: 'var(--tertiary-color)',
                                    fontWeight: 'bold',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'}}>
                                    Request Form
                                </HashLink>
                            </div>
                        </div>
                    </div>
                </Carousel>
            </section>
            <section style={{
                marginBottom: '4%'
            }} className="why-choose-us-aria">
                <Typography component="h2" variant="h2" className="page-heading">
                    Why Choose Us ?
                </Typography>
                <div className="why-us-box">
                    <div className="why-us-image-box">
                        <img 
                            src={whyUsImage} 
                            alt="whyUsImage" 
                            className="why-us-image" />
                    </div>
                    <Typography component="p" variant="p" className="why-us-text">
                        PrepClue offers guaranteed coaching admissions in top coaching 
                        onboarded on our portal. Being in the market from the last 15 
                        years we have built a tremendous reach and trust among the 
                        students and this is what makes us a perfect choice to join 
                        hands with any coaching in India or abroad.<br/>
                        We have an easy listing process for valid and verified coaching 
                        with more than 200 coaching onboarded already. It helps 
                        students to choose from a wide range of courses and coaching 
                        across the world, giving them a plethora of options to choose 
                        from. That’s why best coaching are interested to have their 
                        visibility on PrepClue.<br/>
                        With PrepClue, any coaching gets a wider 
                        audience enabling them to reach students from all over India who 
                        are looking for coaching with simple and transparent process 
                        where coachings can track the journey of students individually 
                        in a personalized manner.
                    </Typography>
                </div>
            </section>
            <section>
                <Typography component="h2" variant="h2" className="page-heading benefit-heading">
                    Benefits of joining the PrepClue family
                </Typography>
                <Typography component="p" variant="p" className="benefit-text">
                    List your Coaching for free
                </Typography>
                <div style={{
                    marginTop: '5%',
                    marginBottom: '4%'
                }} className="benefit-list-box ben-list-box-need">
                    <div>
                        <div className={classes.benefitSingleBox}>
                            <div className="benefit-icon-box ben-icon-size">
                                <img src={BenefitPointIcon} alt="benefit-point-icon" />
                            </div>
                            <div>
                                <Typography component="h3" variant="h3" className="benefit-point-title" sx={{marginBottom: '6px'}}>
                                    Reach Wider Audience
                                </Typography>
                                <Typography 
                                    component="p" 
                                    variant="p" 
                                    className="benefit-point-text text-width">
                                    Promote your coaching to a wider audience 
                                    of students all over the world. We have organically 
                                    reached more than 65 lakh students in last 15 months. 
                                    Join us to convert more leads now.
                                </Typography>
                            </div>
                        </div>
                        <div className={classes.benefitSingleBox}>
                            <div className="benefit-icon-box ben-icon-size">
                                <img src={BenefitPointIcon} alt="benefit-point-icon" />
                            </div>
                            <div>
                                <Typography component="h3" variant="h3" className="benefit-point-title" sx={{marginBottom: '6px'}}>
                                    Personalized Learning Management System 
                                </Typography>
                                <Typography 
                                    component="p" 
                                    variant="p" 
                                    className="benefit-point-text text-width">
                                    Get a personalized LMS loaded with all the latest 
                                    and advanced features you need to conduct online 
                                    classes in your Coaching along with 
                                    top-notch comprehensive teaching materials.
                                </Typography>
                            </div>
                        </div>
                    </div>
                    <div className="margin-need">
                        <div className={classes.benefitSingleBox}>
                            <div className="benefit-icon-box ben-icon-size">
                                <img src={BenefitPointIcon} alt="benefit-point-icon" />
                            </div>
                            <div>
                                <Typography component="h3" variant="h3" className="benefit-point-title" sx={{marginBottom: '6px'}}>
                                    Transparent Application Process
                                </Typography>
                                <Typography 
                                    component="p" 
                                    variant="p" 
                                    className="benefit-point-text text-width">
                                    We ensure an efficient, easy and transparent 
                                    application process for all stakeholders involved in 
                                    an admission process. Coaching can easily track the 
                                    progress of students’ application.
                                </Typography>
                            </div>
                        </div>
                        <div className={classes.benefitSingleBox}>
                            <div className="benefit-icon-box ben-icon-size">
                                <img src={BenefitPointIcon} alt="benefit-point-icon" />
                            </div>
                            <div>
                                <Typography component="h3" variant="h3" className="benefit-point-title" sx={{marginBottom: '6px'}}>
                                    One-stop solution to connect with your students 
                                </Typography>
                                <Typography 
                                    component="p" 
                                    variant="p" 
                                    className="benefit-point-text text-width">
                                    We enable our partner coaching to stay connected 
                                    with their students using our high-tech platform and 
                                    also provide their students with recommended study 
                                    materials and add-on courses to help students succeed.
                                </Typography>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <Typography component="h2" variant="h2" className="page-heading benefit-heading">
                    How it works
                </Typography>
                <Typography component="p" variant="p" className="benefit-text">
                    Here are the steps to list your coaching on PrepClue portal.<br/>
                    You can also sell your courses and materials by selecting our 
                    paid packages.
                </Typography>
                <div style={{
                    marginTop: '5%',
                    marginBottom: '4%'
                }} className="benefit-list-box">
                    <div className="how-it-work-two-box">
                        <div className={classes.howItWorkSingleBox + ' margin-r-r' }>
                            <div className="benefit-icon-box">
                                <img src={SearchIcon} alt="benefit-point-icon" />
                            </div>
                            <div>
                                <Typography component="h3" variant="h3" className="benefit-point-title" 
                                    sx={{color: 'var(--secondary-color)', margin: '16px 0 10px 0'}}>
                                    1. Fill request form
                                </Typography>
                                <Typography component="p" variant="p" className="benefit-point-text how-work-point-text">
                                    Fill out the request form below with the correct 
                                    details and official coaching email. You need to 
                                    upload a valid proof of document.
                                </Typography>
                            </div>
                        </div>
                        <div className={classes.howItWorkSingleBox}>
                            <div className="benefit-icon-box">
                                <img src={SearchIcon} alt="benefit-point-icon" />
                            </div>
                            <div>
                                <Typography component="h3" variant="h3" className="benefit-point-title" 
                                    sx={{color: 'var(--secondary-color)', margin: '16px 0 10px 0'}}>
                                    2. Get verified
                                </Typography>
                                <Typography component="p" variant="p" className="benefit-point-text how-work-point-text">
                                    You will get your login. Our team will verify your 
                                    details. The status will be available on your 
                                    coaching dashboard.
                                </Typography>
                            </div>
                        </div>
                    </div>
                    <div className="how-it-work-two-box">
                        <div className={classes.howItWorkSingleBox + ' margin-r-r'}>
                            <div className="benefit-icon-box">
                                <img src={SearchIcon} alt="benefit-point-icon" />
                            </div>
                            <div>
                                <Typography component="h3" variant="h3" className="benefit-point-title" 
                                    sx={{color: 'var(--secondary-color)', margin: '16px 0 10px 0'}}>
                                    3. Register and upload documents
                                </Typography>
                                <Typography component="p" variant="p" className="benefit-point-text how-work-point-text">
                                    Once approved, you can fill the coaching details 
                                    with valid documents as per the requirement. You can 
                                    opt-in for paid services to get more conversions 
                                    assured.
                                </Typography>
                            </div>
                        </div>
                        <div className={classes.howItWorkSingleBox}>
                            <div className="benefit-icon-box">
                                <img src={SearchIcon} alt="benefit-point-icon" />
                            </div>
                            <div>
                                <Typography component="h3" variant="h3" className="benefit-point-title" 
                                    sx={{color: 'var(--secondary-color)', margin: '16px 0 10px 0'}}>
                                    4. Enjoy the benefits
                                </Typography>
                                <Typography component="p" variant="p" className="benefit-point-text how-work-point-text">
                                    Our team will verify and approve your listing, 
                                    products and add-ons, it will be accessible to your 
                                    students. They can also make purchases to paid 
                                    material.
                                </Typography>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="InstituteRequestForm">
                <Typography component="h2" variant="h2" className="page-heading benefit-heading">
                    {isWebsiteFor === "coaching" && "Coaching Request Form"}
                    {isWebsiteFor === "university" && "University Request Form"}
                </Typography>
                <Typography component="p" variant="p" className="benefit-text">
                    We have the top reputed coachings across the globe on our portal.<br/> 
                    Thank you for showing your interest Use your official email to fill <br/>
                    the request form and start your coaching listing process.
                </Typography>
                <div style={{
                    marginTop: '5%',
                    display: 'flex',
                }}>
                    <div className="form-image">
                        <img src={isWebsiteFor === "partner" ? PartnerImage : FormImage} 
                            alt="formImage" />
                    </div>
                    <div style={{
                        backgroundColor: 'rgba(98, 85, 164, 0.11)',
                    }} className="request-form">
                        <Typography component="h2" variant="h2" className="form-heading">
                            {isWebsiteFor === "coaching" && "Coaching Request Form"}
                            {isWebsiteFor === "university" && "University Request Form"}
                        </Typography>
                        <div>
                            <form onSubmit={requestBtnClicked}>
                                <div className={classes.marginBot24}>
                                    <InputLabel className={classes.formInputLabel}>
                                        {isWebsiteFor === "coaching" ? 
                                        "Name of Owner" : 
                                        "Name of University"}
                                    </InputLabel>
                                    <TextField 
                                        placeholder="" 
                                        type="text" 
                                        variant="outlined" 
                                        name="name" 
                                        disabled={(universityByToken?.data?.university_name || coachingByToken?.data?.coaching_name) && true}
                                        sx={{
                                            width: '100%',
                                            height: '62px'
                                        }} 
                                        onChange={handleChange} 
                                        value={formInformation.university_name} 
                                        required/>
                                </div>
                                {isWebsiteFor === "coaching" &&
                                <div className={classes.marginBot24}>
                                    <InputLabel className={classes.formInputLabel}>
                                        Name of Coaching Center
                                    </InputLabel>
                                    <TextField placeholder="" type="text" variant="outlined" name="coaching-name" sx={{
                                        width: '100%',
                                        height: '62px'
                                    }} onChange={handleChange} value={formInformation.coaching_name} required/>
                                </div>}
                                {isWebsiteFor === "university" && 
                                <div className={classes.marginBot24}>
                                    <InputLabel className={classes.formInputLabel}>Name of Chairperson or HODs</InputLabel>
                                    <div className="form-two-inputs">
                                        <Select
                                            name="designation"
                                            value={formInformation.designation}
                                            onChange={handleChange}
                                            placeholder="Select"
                                            autoWidth
                                            required
                                            sx={{
                                                width: '88%',
                                                height: '56px',
                                                position: 'relative',
                                                marginRight: '12px'
                                            }}
                                            >
                                                {university_designation && university_designation[0].data?.map((item, index) => (
                                                    <MenuItem value={item} key={index}>
                                                        {item}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                        <TextField 
                                            placeholder="" 
                                            type="text" 
                                            name="chairperson-name" 
                                            variant="outlined" 
                                            sx={{
                                                width: '100%',
                                                height: '62px'
                                            }} 
                                            onChange={handleChange} 
                                            value={formInformation.name} 
                                            required/>
                                    </div>
                                    
                                </div>}
                                <div className={classes.marginBot24}>
                                    <InputLabel className={classes.formInputLabel}>Full Address</InputLabel>
                                    <TextField 
                                        placeholder="" 
                                        type="text" 
                                        variant="outlined" 
                                        sx={{
                                            width: '100%',
                                            height: '62px'
                                        }} 
                                        name="address" 
                                        onChange={handleChange} 
                                        value={formInformation.address} 
                                        required/>
                                </div>
                                <div className={classes.marginBot24}>
                                    <InputLabel className={classes.formInputLabel}>Phone Number and Whatsapp</InputLabel>
                                    <div style={{
                                        display: 'flex'
                                    }}>
                                        <div style={{
                                            width: '34%',
                                            marginRight: '12px'
                                        }}>
                                            <Select
                                                disabled={(coachingByToken?.data?.phone_country_code || 
                                                    universityByToken?.data?.phone_country_code) && true}
                                                value={formInformation?.phone_country_code}
                                                onChange={handleChange}
                                                placeholder="+91"
                                                name="phone-country-code"
                                                MenuProps={{ PaperProps: { sx: { maxHeight: 250 } } }}
                                                autoWidth
                                                required
                                                sx={{
                                                    width: '100%',
                                                    height: '56px',
                                                    position: 'relative',
                                                }}
                                                >
                                                {countryList?.map((item, index) => (
                                                    <MenuItem value={item?.phonecode} key={index} selected={index === 1 && true}>
                                                        {item?.phonecode}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </div>
                                        <div style={{
                                            width: '100%',
                                            marginRight: '12px',
                                            position: 'relative',
                                        }}>
                                            <TextField 
                                                placeholder="" 
                                                type="number" 
                                                variant="outlined" 
                                                sx={{
                                                    width: '100%',
                                                    height: '62px'
                                                }} 
                                                disabled={(coachingByToken?.data?.phone || universityByToken?.data?.phone) && true}
                                                name="phone-number" 
                                                onChange={handleChange} 
                                                value={formInformation.phone} 
                                                required/>
                                            {/* {!isMobileVerified ? 
                                            <Button 
                                                sx={{color: '#DC0707'}} 
                                                className={classes.buttonStyle}
                                                onClick={() => {setIsPhoneOtpField(true)}}>
                                                Verify
                                            </Button>: 
                                            <Button sx={{color: '#07AB35'}} className={classes.buttonStyle}>
                                                Verified
                                            </Button>} */}
                                        </div>
                                        <div style={{
                                            width: '100%',
                                        }}>
                                            <TextField 
                                                placeholder="" 
                                                type="number" 
                                                variant="outlined" 
                                                sx={{
                                                    width: '100%',
                                                    height: '62px'
                                                }} 
                                                name="whatsapp-number" 
                                                onChange={handleChange} 
                                                disabled={(coachingByToken?.data?.whatsapp_number || 
                                                    universityByToken?.data?.whatsapp_number) && true}
                                                value={formInformation.whatsapp_number} 
                                                required/>
                                        </div>
                                    </div>
                                    {/* {isPhoneOtpField && 
                                    <div>
                                        <OtpAria 
                                            otp={otp} 
                                            type="Mobile"
                                            loading={mobileLoading}
                                            title="OTP send on your above mention phone number"
                                            btnTitle="Verify Phone"
                                            verifyClicked={(type) => verifyFunc(type)}
                                            handleOChange={handleOtpChange} />
                                    </div>} */}
                                </div>
                                <div className={classes.marginBot24}>
                                    <InputLabel className={classes.formInputLabel}>
                                        {isWebsiteFor === "coaching" ? "Coaching official Email ID" : "University Official Email ID"}
                                    </InputLabel>
                                    <div style={{
                                            position: 'relative',
                                        }}>
                                        <TextField 
                                            placeholder="" 
                                            type="email" 
                                            variant="outlined" 
                                            sx={{
                                                width: '100%',
                                                height: '62px'
                                            }} 
                                            name="email" 
                                            onChange={handleChange} 
                                            value={formInformation.email}
                                            disabled={(
                                                coachingByToken?.data?.email || 
                                                universityByToken?.data?.email || 
                                                formInformation?.verification_id_email !== "") && true}
                                            required/>
                                        {isEmailVerified ? 
                                            <Button 
                                                sx={{color: '#07AB35'}} 
                                                className={classes.buttonStyle}>
                                                Verified
                                            </Button>: 
                                            <Button 
                                                sx={{color: '#DC0707'}} 
                                                className={classes.buttonStyle}
                                                disabled={isLoader && from === "Verify"}
                                                onClick={() => {verifyButtonClicked("Verify")}}>
                                                {(isLoader && from === "Verify") ? 
                                                <LoaderIcon /> : 
                                                'Verify'}
                                            </Button> }
                                    </div>
                                    {isEmailOtpField && 
                                    <div>
                                        <OtpAria 
                                            otp={otp} 
                                            type="Email"
                                            from={from}
                                            isLoader={isLoader}
                                            title="OTP send on your above mention email ID"
                                            loading={emailLoading}
                                            btnTitle="Verify Email"
                                            resend={(type) => verifyButtonClicked(type)}
                                            verifyClicked={(type) => verifyFunc(type)}
                                            handleOChange={handleOtpChange} />
                                    </div>}
                                </div>
                                {(!universityByToken && !coachingByToken) &&
                                <div className={classes.marginBot24}>
                                    <InputLabel className={classes.formInputLabel}>Password</InputLabel>
                                    <TextField
                                        id="password"
                                        name="password"
                                        disabled={(coachingByToken?.data?.password || universityByToken?.data?.password) && true}
                                        type={showPassword ? "text" : "password"}
                                        // label="Password"
                                        value={formInformation.password}
                                        onChange={handleChange}
                                        autoComplete="current-password"
                                        margin="normal"
                                        required
                                        InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                            </InputAdornment>
                                        ),
                                        }}
                                        // error={passwordError}
                                        // helperText={passwordError ? "required field" : ""}
                                        fullWidth
                                        sx={{
                                            width: '100%',
                                            height: '62px'
                                        }}
                                    />
                                </div>}
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }} className={classes.marginBot24 +' upload-addr-box'}>
                                    <div>
                                        <InputLabel className={classes.formInputLabel}>Address Proof</InputLabel>
                                        <InputLabel sx={{
                                            fontSize: '12px',
                                            color: 'rgb(0, 0, 0, 0.6)'
                                        }}>Upload the document for address proof</InputLabel>
                                    </div>
                                    <div className="upload-addr-btn-box mar-left" style={{position: 'relative'}}>
                                        <label htmlFor="workout-images">
                                            <Input
                                                accept="image/*"
                                                id="workout-images"
                                                name="image-upload"
                                                type="file"
                                                sx={{display: 'none'}}
                                                onChange={handleChange}
                                            />
                                            <Button component="div" className="upload-addr-btn">
                                                <FileUploadOutlinedIcon />
                                                {loading ? <img src={Loader} width="24" alt="Loader" /> : 
                                                    <span>{formInformation?.address_proof ? 
                                                            formInformation?.address_proof?.split("-")[3] : 'Upload Image'}</span>}
                                            </Button>
                                        </label>
                                    </div>
                                </div>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}>
                                    <Button 
                                        className={classes.requestButton} 
                                        onClick={requestBtnClicked}
                                        sx={requestLoading ? {backgroundColor: 'var(--disable-color)'} : 
                                            {backgroundColor: isWebsiteFor === "coaching" ? 
                                            'var(--tertiary-color)' : 'var(--secondary-color)'}}
                                        disabled={requestLoading}>
                                        {requestLoading ? <LoaderIcon /> : 'Send Request'}
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </Box>
    );
}

const OtpAria = (props) => {
    const classess = useStyle();
    return (
        <>
            <div style={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center'
            }}>
                <Typography sx={{
                    color: '#EF4840',
                    fontSize: '12px'
                }} component="p" variant="p">
                    {props.title}
                </Typography>
                <Button sx={{
                    textTransform: 'capitalize',
                    fontSize: '12px',
                    color: '#000',
                    background: 'none'
                }} 
                disabled={props.isLoader && props.from === "Resend"}
                onClick={() => props.resend("Resend")}>
                    {(props.isLoader && props.from === "Resend") ? 
                    <LoaderIcon />
                    : 
                    "Resend OTP"}
                </Button>
            </div>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '12px'
            }}>
                <OtpInput
                    value={props.otp}
                    onChange={props.handleOChange}
                    numInputs={4}
                    inputStyle={{
                        width: '80px',
                        height: '36px',
                        borderTop: 'none',
                        borderRight: 'none',
                        borderLeft: 'none',
                        "&:focusVisible":{
                            borderTop: 'none',
                            borderRight: 'none',
                            borderLeft: 'none',
                        }
                    }}
                    isInputNum={true}
                    separator={<span>&nbsp;&nbsp;&nbsp;</span>}
                />
            </div>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
            }}>
                <Button 
                    className={classess.verifySubmit} 
                    disabled={props.loading}
                    sx={props.loading ? {backgroundColor: 'var(--disable-color)'} : {backgroundColor: '#DC0707'}}
                    onClick={() => props.verifyClicked(props.type === "Mobile" ? "Mobile" : "Email")}>
                    {props.loading ? 
                        <LoaderIcon />
                     : 
                    props.btnTitle}
                </Button>
            </div>
        </>
    )
} 
