import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import uploadDataOnS3Services from "./uploadOnS3Service";

const initialState = {
  bucket_urls: null,
  isUploadOnS3Error: false,
  isUploadOnS3Success: false,
  isUploadOnS3Loading: false,
  uploadOnS3message: "",
};

export const getUrls = createAsyncThunk(
  "uploadOnS3/getUrls",
  async (params, thunkAPI) => {
    try {
      return await uploadDataOnS3Services.getImageUrls(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const uploadOnS3Slice = createSlice({
  name: "uploadOnS3",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUrls.pending, (state) => {
        state.isUploadOnS3Loading = true;
      })
      .addCase(getUrls.rejected, (state, action) => {
        state.isUploadOnS3Loading = false;
        state.isUploadOnS3Error = false;
        state.uploadOnS3message = action.payload;
      })
      .addCase(getUrls.fulfilled, (state, action) => {
        console.log(action.payload);
        state.isUploadOnS3Loading = false;
        state.isUploadOnS3Success = true;
        state.isUploadOnS3Error = false;
        state.bucket_urls = action.payload.data.urls;
      });
  },
});
export default uploadOnS3Slice.reducer;
