import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Typography
} from "@mui/material";
import routes from "../../react_routes_enum";
import { makeStyles } from "@mui/styles";
import HeaderBackground from "../../assest/images/header-background.jpg";
import HeaderIcon from "../../assest/images/header-icon.png";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useNavigate } from "react-router-dom";
import { getPartnerByToken } from "../../redux/requestForm/requestFormSlice";
import LoaderIcon from "../../components/Loader/Loader";
import Response2Image from "../../assest/images/response_2.jpg";
import SuccessImage from "../../assest/images/success_image.png";
import { useState } from "react";

const useStyle = makeStyles({
    dot: {
        height: '16px',
        width: '16px',
        borderRadius: '50%',
    },
    line: {
        height: '4px',
        backgroundColor: 'rgb(246, 146, 32, 0.4)'
    },
    changesButton: {
        color: '#fff !important',
        textTransform: 'capitalize !important',
        width: '160px',
        height: '40px',
        fontSize: '16px !important',
        borderRadius: '24px !important',
        boxShadow: '0 2px 5px rgb(0, 0, 0, 0.5)',
        "&:hover":{
            backgroundColor: 'var(--secondary-color) !important',
        }
    },
    cancelButton: {
        backgroundColor: '#fff !important',
        color: 'var(--secondary-color) !important',
        textTransform: 'capitalize !important',
        width: '140px',
        height: '40px',
        fontSize: '16px !important',
        borderRadius: '24px !important',
        borderColor: 'var(--secondary-color) !important',
        "&:hover":{
            backgroundColor: '#fff',
        }
    },
    header: {
        marginTop: '100px',
        marginBottom: '4%',
        backgroundImage: `url(${HeaderBackground})`,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    formIconsBox: {
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        marginTop: '5%',
        marginRight: '14%',
    },
    iconBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    horizontalLine: {
        height: '4px',
        backgroundColor: 'var(--primary-color)',
    },
    iconBox2: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'absolute', 
    },
    iconStyle: {
        backgroundColor: 'var(--primary-color)',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    iconText: {
        color: 'var(--primary-color)',
        marginTop: '8px !important',
    },
    icon: {
        color: '#fff',
    },
    leftBox: {
        height: '200px',
        width: '200px',
        borderRadius: '8px',
        border: '2px dashed rgb(0, 0, 0, 0.5)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    upload: {
        border: '1px solid rgb(0, 0, 0, 0.2) !important',
        fontSize: '15px !important',
        color: 'rgb(0, 0, 0, 0.4) !important',
        textTransform: 'capitalize !important',
        width: '122px',
        height: '34px'
    },
    checkBox: {
        color: 'var(--primary-color)',
        '&.Mui-checked': {
            color: 'var(--primary-color)',
        }
    },
    tableNew: {
        border: '1px solid rgb(0, 0, 0, 0.2)',
        borderRadius: '8px',
        borderCollapse: 'collapse',
        width: '100%',
        marginLeft: '0.8%' 
    },
    tableTh: {
        borderBottom: '1px solid rgb(0, 0, 0, 0.2)',
        display: 'flex',
        justifyContent: 'space-between',
        fontWeight: 'bold',
    },
    tableTd: {
        borderRight: '1px solid rgb(0, 0, 0, 0.2)',
        width: '14.5%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '12px',
    }
})

export default function RequestRes() {
    const [loader, setLoader] = useState(false);
    const { partnerByToken } = useSelector((state) => state.form);
    const navigate = useNavigate();
    const classes = useStyle();
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(getPartnerByToken())
        statusCheck();
    },[])
    const statusCheck = () => {
        let interval = setInterval(function() {
            dispatch(getPartnerByToken())
        },1000)
    }
    const continueClicked = () => {
        setLoader(true);
        localStorage.setItem("isRefresh", true);
        navigate(routes.DASHBOARD);
    }
    const requestChange = () => {
        localStorage.setItem("isRefresh", true);
        navigate(routes.PARTNER_DOCUMENT);
    }
    return (
        <Box>
            <section className={classes.header + ' header-set'}>
                <div className="header-icon-box">
                    <img src={HeaderIcon} alt="header-icon" style={{width: '100%'}} />
                </div>
                <Typography component="h2" variant="h2" className="header-heading">
                    PARTNER'S ONBOARDING
                </Typography>
            </section>
            <Box sx={{p: 3}}>
                <section>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginBottom: '8px'
                        }} className="progr-lin-box">
                            <div className={classes.dot} style={{
                                background: 'none',
                                }}>
                                <CheckCircleOutlineIcon sx={{width: '100%', color: '#07AB35'}} />
                            </div>
                            <div className={classes.line + ' progress-line'} style={{background: 'none'}}>

                            </div>
                            <div className={classes.dot} style={{
                                background: 'none',
                                visibility: 'visible'
                                }}>
                                <CheckCircleOutlineIcon sx={{
                                    width: '100%', 
                                    color: '#07AB35',
                                    }} />
                            </div>
                            <div className={classes.line + ' progress-line'} style={{background: 'none'}}>

                            </div>
                            <div className={classes.dot} style={{
                                background: 'none',
                                visibility: (partnerByToken?.data?.request_status === "accepted") ? 
                                    'visible' : 'hidden'
                                }}> 
                                <CheckCircleOutlineIcon sx={{width: '100%', color: '#07AB35'}} />
                            </div>
                        </div>
                    </div>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }} className="progr-lin-box">
                            <div className={classes.dot} style={{backgroundColor: 'var(--secondary-color)'}}>
                                
                            </div>
                            <div className={classes.line + ' progress-line'} style={{backgroundColor: 'var(--secondary-color)'}}>

                            </div>
                            <div className={classes.dot} style={{backgroundColor: 'var(--secondary-color)'}}>

                            </div>
                            <div 
                                className={classes.line + ' progress-line'} 
                                style={{
                                    backgroundColor: (partnerByToken?.data?.request_status === "accepted") ? 
                                    'var(--secondary-color)' :
                                    'rgb(246, 146, 32, 0.4)'
                            }}>

                            </div>
                            <div 
                                className={classes.dot} 
                                style={{
                                    backgroundColor: (partnerByToken?.data?.request_status === "accepted") ? 
                                    'var(--secondary-color)' :
                                    'rgb(246, 146, 32, 0.4)'}}>

                            </div>
                        </div>
                    </div>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '12px'
                    }}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            justifyContent: 'space-between',
                        }} className="progr-text-box">
                            <Typography component="h2" variant="h2" className="progr-text">
                                Fill the Request Form
                            </Typography>
                            <Typography component="h2" variant="h2" className="progr-text">
                                Upload the documents
                            </Typography>
                            <Typography component="h2" variant="h2" className="progr-text">
                                Success
                            </Typography>
                        </div>
                    </div>
                </section>
                {(partnerByToken?.data?.request_status === "documents_review" || 
                    partnerByToken?.data?.request_status === "documents") &&
                    <>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: '48px',
                        }}>
                            <div style={{
                                width: '120px'
                            }}>
                                <img src={Response2Image} alt="univ-response-icon" style={{width: '100%'}} />
                            </div>
                        </div>
                        {partnerByToken?.data?.request_status === "documents" ?
                            <Typography variant="h3" component="h3" className="request-res-main-text">
                                Your Review Pending
                            </Typography> :
                            <>
                                <Typography variant="h3" component="h3" className="request-res-main-text">
                                    Your documents need some changes
                                </Typography> 
                                <div style={{
                                    marginTop: '48px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}>
                                    <div style={{
                                        width: '400px'
                                    }}>
                                        <Typography variant="p" component="p" sx={{
                                            fontSize: '18px',
                                        }}>
                                            CHANGES:
                                        </Typography>
                                        <ul style={{
                                            listStyleType: 'numeric',
                                            paddingLeft: '16px',
                                        }}>
                                            {partnerByToken?.data?.requested_changes.map((item, index) => (
                                                <li key={index}>
                                                    {item}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        marginTop: '48px',
                                        marginBottom: '52px'
                                    }}>
                                        <Button 
                                            className={classes.changesButton} 
                                            sx={{
                                                backgroundColor: 'var(--secondary-color)'
                                            }} 
                                            onClick={requestChange}>
                                            {loader ? <LoaderIcon /> : 'Edit'}
                                        </Button>
                                    </div>
                                </div>
                            </>
                            
                        }
                    </>
                }
                {(partnerByToken?.data?.request_status === "accepted") &&
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <div>
                            <img src={SuccessImage} alt="successImage"className="succes-img" />
                        </div>
                        <Typography variant="h3" component="h3" className="request-res-main-text">
                            Success
                        </Typography>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: '48px',
                            marginBottom: '52px'
                        }}>
                            <Button className={classes.changesButton} sx={{
                                backgroundColor: loader ? 'var(--disable-color)' : 'var(--secondary-color)',
                                width: '190px'
                            }} 
                            disabled={loader}
                            onClick={continueClicked}>
                                {loader ? <LoaderIcon/> :"Go to Dashboard"}
                            </Button>
                        </div>
                    </div>
                }
            </Box>
        </Box>
    );
}
