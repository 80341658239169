import * as React from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  InputLabel,
  TextField,
  Select,
  MenuItem 
} from "@mui/material";
import routes from "../../react_routes_enum";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { getConfigDetails } from "../../redux/configurationDetails/configDetailsSlice";
import Loader from "../../assest/images/preloader.gif";
import { toast } from "react-toastify";
import HomeLayout from "../homeLayout/HomeLayout";
import QIcon from "../../assest/svgs/q-icon.svg";
import "./help&support.css";
import { useState } from "react";

const useStyle = makeStyles({
    input: {
        "&::placeholder": {
          fontSize: '12px',
        },
    },
})

const ticketData = {
    type: '0',
    title: '',
    description: ''
}

export default function HelpSupport() {
    const dispatch = useDispatch();
    const [userData, setUserData] = useState();
    const [isNewTicket, setIsNewTicket] = useState(false);
    const [ticketType, setTicketType] = useState([]);
    const [submitData, setSubmitData] = useState(ticketData);
    const [btnLoader, setBtnLoader] = useState(false);

    React.useEffect(() => {
        setUserData(JSON.parse(localStorage.getItem('cc_user')));
        const res = dispatch(getConfigDetails());
        res?.then((r) => {
            if(r?.payload?.statusCode === 200){
                r.payload?.data?.map((ticketOpt) => {
                    if(ticketOpt?.configuration_type === "ticket_type"){
                        setTicketType(ticketOpt?.data);
                    }
                })
            }
        })
    },[])

    const handleChange = (e) => {
        e.target.name === "select_ticket_type" && setSubmitData({...submitData, type:e.target.value})
        e.target.name === "title" && setSubmitData({...submitData, title :e.target.value})
        e.target.name === "description" && setSubmitData({...submitData, description :e.target.value})
    }
    
    const submitTicket = () => {
        if(validate().isValid){
            console.log('submitted', submitData)
        }else{
            toast.error(validate().message);
        }
    }

    const validate = () => {
        let status = {isValid: true, message: ""}
        if(!submitData?.type || submitData?.type === '0'){
            status.isValid = false;
            status.message = 'Please select ticket type'
            return status;
        }
        if(!submitData?.title || submitData?.title === ""){
            status.isValid = false;
            status.message = 'Please enter ticket title'
            return status;
        }
        if(!submitData?.description || submitData?.description === ""){
            status.isValid = false;
            status.message = 'Please enter ticket description'
            return status;
        }
        return status;
    }
    // if (isLoading && isViewDetailClicked) return <Spinner />;
    // if (isError) return <h1>{message}</h1>;
    return (
        <>
            <HomeLayout />
            <div 
                className="content-box table-box" 
                style={{
                    marginTop: '140px', 
                    color: '#000',
                    overflow: 'hidden'
                }}>
                {isNewTicket ?
                <div className="submit-ticket-box">
                    <div className="ticket-field-aria">
                        <div className="two-input-aria">
                            <div className="input-box-mobile">
                                <InputLabel className="input-label-text">
                                    Issue ticket Type
                                </InputLabel>
                                <Select
                                    name="select_ticket_type"
                                    className="name-field widthSet"
                                    value={submitData?.type}
                                    // value={courseDetail?.courses_offered?.courses}
                                    onChange={handleChange}
                                    autoWidth
                                    sx={{
                                        height: '56px',
                                        position: 'relative',
                                        boxShadow: '0px 2px 10px 2px rgba(103, 47, 143, 0.3)',
                                        border: 'none'
                                    }}
                                    >
                                        <MenuItem value={'0'}>
                                            Choose from here
                                        </MenuItem>
                                        {ticketType?.map((item, index) => (
                                            <MenuItem 
                                            key={index}
                                            value={item}>{item}</MenuItem>
                                        ))}
                                </Select>
                            </div>
                            <div className="input-box-mobile marginLeftIn">
                                <InputLabel className="input-label-text">
                                    Title of ticket
                                </InputLabel>
                                <TextField 
                                    placeholder="Type here!" 
                                    type="text" 
                                    variant="outlined" 
                                    name="title" 
                                    className="name-field widthSet"
                                    sx={{
                                        height: '56px',
                                        position: 'relative',
                                        boxShadow: '0px 2px 10px 2px rgba(103, 47, 143, 0.3)',
                                        border: 'none'
                                    }}
                                    value={submitData?.title}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>
                        <div className="contact-us-message-box">
                            <InputLabel className="input-label-text">
                                Description of your query
                            </InputLabel>
                            <TextField 
                                placeholder="Type here!" 
                                type="text" 
                                variant="outlined" 
                                name="description" 
                                className="message"
                                value={submitData?.description}
                                onChange={handleChange}
                                rows={4}
                                multiline
                                required/>
                        </div>
                    </div>
                    <div className="ticket-submit-btn">
                        <Button 
                            style={{
                                background: btnLoader ? 'rgb(0, 0, 0, 0.2)' : '#F69220',
                                color: '#FFF',
                                width: '96px',
                                height: '32px',
                                textTransform: 'capitalize'
                            }} 
                            disabled={btnLoader}
                            onClick={submitTicket}>
                            {btnLoader ? <img 
                                src={Loader} 
                                alt="" 
                                style={{width: '20px'}} /> : 'Submit'}
                        </Button>
                    </div>
                </div>
                :
                <>
                    <div className="ticket-top">
                        <p>All Tickets</p>
                        <Button 
                            className="btn-new-ticket" 
                            onClick={() => setIsNewTicket(!isNewTicket)}>
                            + New Ticket
                        </Button>
                    </div>
                    <div className="ticket-card-box">
                        <TicketCard />
                    </div>
                </>
                }
            </div>
        
        </>
    );
}

const TicketCard = () => {
    const navigate = useNavigate();
    const ticketDetail = (id) => {
        navigate(routes.HELP_SUPPORT_DETAIL);
    }

    return (

        <div className="ticket-card-aria" onClick={() => ticketDetail('idd')}>
            <div className="ticket-nom-date-time">
                <p className="ticket-number">
                    Ticket Number # 2022-321
                </p>
                <p className="ticket-date-time">
                    <span>
                        wednesday 3 March, 2022
                    </span>
                    <span style={{
                        marginLeft: '8px'
                    }}>
                        11:34 AM
                    </span>
                </p>
            </div>
            <div className="ticket-title-desc-box">
                <div>
                    <img src={QIcon} alt="" />
                </div>
                <div className="ticket-title-desc">
                    <p className="ticket-title">Lorem ipsum dolor sit amet.</p>
                    <p className="ticket-desc">
                        purus sit amet luctus venenatis, lectus magna fringilla urna, 
                        porttitor purus sit amet luctus venenatis, lectus magna fringilla 
                        urna, porttitor
                    </p>
                </div>
            </div>
            <div>
                <span style={{fontWeight: 'bold'}}>STATUS: </span><span>Pending </span>
            </div>
        </div>
    )
}
