import * as React from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Input,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate, useLocation } from "react-router-dom";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { getConfigDetails } from "../../redux/configurationDetails/configDetailsSlice";
import ProfilePic from "../../assest/images/placement_profile_pic.png";
import { toast } from "react-toastify";
import HomeLayout from "../homeLayout/HomeLayout";
import QIcon from "../../assest/svgs/q-icon.svg";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import "./help&support.css";
import { useState } from "react";

const useStyle = makeStyles({
    input: {
        "&::placeholder": {
          fontSize: '12px',
        },
    },
})

const ticketData = {
    type: '0',
    title: '',
    description: ''
}

export default function HelpSupport() {
    const classes = useStyle();
    const dispatch = useDispatch();
    const [userData, setUserData] = useState();
    const [ticketType, setTicketType] = useState([]);
    const [submitData, setSubmitData] = useState(ticketData);

    React.useEffect(() => {
        setUserData(JSON.parse(localStorage.getItem('cc_user')));
        const res = dispatch(getConfigDetails());
        res?.then((r) => {
            if(r?.payload?.statusCode === 200){
                console.log(r.payload?.data)
                r.payload?.data?.map((ticketOpt) => {
                    if(ticketOpt?.configuration_type === "ticket_type"){
                        setTicketType(ticketOpt?.data);
                    }
                })
            }
        })
    },[])

    const handleChange = (e) => {
        e.target.name === "select_ticket_type" && setSubmitData({...submitData, type:e.target.value})
        e.target.name === "title" && setSubmitData({...submitData, title :e.target.value})
        e.target.name === "description" && setSubmitData({...submitData, description :e.target.value})
    }
    
    const submitTicket = () => {
        if(validate().isValid){
            console.log('submitted', submitData)
        }else{
            toast.error(validate().message);
        }
    }

    const validate = () => {
        let status = {isValid: true, message: ""}
        if(!submitData?.type || submitData?.type === '0'){
            status.isValid = false;
            status.message = 'Please select ticket type'
            return status;
        }
        if(!submitData?.title || submitData?.title === ""){
            status.isValid = false;
            status.message = 'Please enter ticket title'
            return status;
        }
        if(!submitData?.description || submitData?.description === ""){
            status.isValid = false;
            status.message = 'Please enter ticket description'
            return status;
        }
        return status;
    }
    // if (isLoading && isViewDetailClicked) return <Spinner />;
    // if (isError) return <h1>{message}</h1>;
    return (
        <>
            <HomeLayout />
            <div 
                className="content-box table-box" 
                style={{
                    marginTop: '140px', 
                    color: '#000',
                    overflow: 'hidden'
                }}>
                <div className="ticket-top">
                    <p></p>
                    <p className="ticket-number" style={{textDecoration: 'none'}}>
                        Ticket Number # 2022-321
                    </p>
                </div>
                <div className="ticket-detail-aria">
                    <p className="ticket-date-time">
                        <span>
                            wednesday 3 March, 2022
                        </span>
                        <span style={{
                            marginLeft: '8px'
                        }}>
                            11:34 AM
                        </span>
                    </p>
                    <div className="ticket-title-desc-box">
                        <div>
                            <img src={QIcon} alt="" />
                        </div>
                        <div className="ticket-title-desc" style={{width: 'auto'}}>
                            <p className="ticket-title">Lorem ipsum dolor sit amet.</p>
                            <p className="ticket-detail-desc">
                                purus sit amet luctus venenatis, lectus magna fringilla 
                                urna, porttitor purus sit amet luctus venenatis, lectus 
                                magna fringilla urna, porttitor Lorem ipsum dolor sit 
                                amet, consectetur adipiscing elit, sed do eiusmod tempor 
                                incididunt ut labore et dolore magna aliqua. Ut enim ad 
                                minim veniam.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="ticket-all-update-box">
                    <div className="ticket-update">
                        All Updates
                    </div>
                    <div className="ticket-update-no">
                        <AutorenewIcon 
                            fontSize="small" 
                            sx={{color: 'rgb(0, 0, 0, 0.4)'}} />
                        <span style={{
                            color: '#EA4848',
                            fontWeight: 'bold',
                            marginLeft: '4px'
                        }}>3 Updates</span>
                    </div>
                </div>
                <div className="ticket-conv-card-box">
                    {[1,2,3,4,5].map((item, index) => (
                        <TicketConvCard item={item} key={index} length={5} />
                    ))}
                </div>
                <div className="ticket-form-heading">
                    <h3>Add Your Update</h3>
                    <AutorenewIcon 
                        fontSize="small" 
                        sx={{color: 'rgb(0, 0, 0, 0.4)', marginLeft: '8px'}} />
                </div>
                <div className="ticket-form-box">
                    <div style={{position: 'relative'}}>
                        <TextField 
                            placeholder="Write here" 
                            type="text" 
                            variant="outlined" 
                            name="message" 
                            className="message"
                            // value={reviewData?.message}
                            onChange={(e) => handleChange(e, "message")}
                            rows={4}
                            multiline
                            required/>
                        <div style={{
                            background: 'var(--primary-color)',
                            height: '64px',
                            borderBottomLeftRadius: '8px',
                            borderBottomRightRadius: '8px',
                            display: 'flex',
                            alignItems: 'center',
                        }}>
                            <div style={{ position: 'relative' }}>
                                <label htmlFor="workout-images">
                                    <Input
                                        accept="image/*"
                                        id="workout-images"
                                        name="image-upload"
                                        type="file"
                                        sx={{display: 'none'}}
                                        onChange={handleChange}
                                    />
                                    <Button component="div" sx={{
                                        color: '#fff',
                                        textTransform: 'capitalize',
                                    }}>
                                        Attach file <AttachFileIcon />
                                        {/* {loading ? <img src={Loader} width="24" alt="Loader" /> : 
                                            <span>{formInformation?.address_proof ? 
                                                    formInformation?.address_proof?.split("-")[3] : 'Upload Image'}</span>} */}
                                    </Button>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        }}>
                        <Button sx={{
                            background: 'var(--primary-color)',
                            color: '#fff',
                            textTransform: 'capitalize',
                            width: '118px',
                            height: '40px',
                            marginTop: '24px',
                            '&:hover':{
                                background: 'var(--primary-color)',
                            }
                        }}>Send</Button>
                    </div>
                </div>
            </div>
        
        </>
    );
}

const TicketConvCard = (props) => {
    return (
        <div className="ticket-conv-big-box">
            <div className="ticket-conv-card-image-line-box">
                <div className="ticket-conv-card-prof-image">
                    <img src={ProfilePic} alt="" />
                </div>
                {(props.item !== props.length) && 
                    <div className="ticket-conv-vertical-line"></div>}
            </div>
            <div className="ticket-conv-aria">
                <div className="ticket-c-card-heading-box">
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                        <h3 style={{
                            margin: '0'
                        }}>Steve Melon</h3>
                        <span style={{
                            fontWeight: 'bold',
                            fontSize: '14px',
                            color: 'rgb(0, 0, 0, 0.5)',
                            marginLeft: '8px'
                        }}>update on your query</span>
                    </div>
                    <p style={{margin: 0}}>March 10</p>
                </div>
                <p style={{
                    margin: '24px 0 28px 0'
                }}>
                    purus sit amet luctus venenatis, lectus magna fringilla urna
                    
                </p>
            </div>
        </div>
    )
}
