import * as React from "react";
import {
  Button,
  TextField,
} from "@mui/material";
import Moment from "moment";
import debounce from "lodash.debounce";
import routes from "../../react_routes_enum";
import SearchIcon from '@mui/icons-material/Search';
import { useDispatch, useSelector } from "react-redux";
import SchoolIcon from '@mui/icons-material/School';
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { getConfigDetails } from "../../redux/configurationDetails/configDetailsSlice";
import "react-alice-carousel/lib/alice-carousel.css";
import { useState } from "react";
import Spinner from "../../components/Spinner/Spinner";
import { 
    setSearchText,
    setAddonId,
    getCustomPages } from "../../redux/instituteStudent/instituteStudentSlice";
import "./addOn.css";
import { toast } from "react-toastify";

const useStyle = makeStyles({
    
})

export default function FirstAddonPage() {
    const isWebsiteFor = localStorage.getItem("is-website-for");
    const classes = useStyle();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [btnLoader, setBtnLoader] = useState(false);
    const [reviewsArray, setReviewsArray] = useState([]);
    const [examCat, setExamCat] = useState([]);
    const [isViewDetailClicked, setIsViewDetailClicked] = React.useState(false);
    const { 
        isLoading, 
        isError, 
        totalCount, 
        message, 
        searchText,
        customPageData,  
      }= useSelector((state) => state.instituteStudent);
    
    React.useEffect(() => {
        dispatch(getCustomPages({limit, offset, searchText, page_type: 'exam'}));
        if(message === "Session Expired" || message === "Unauthorized"){
            toast.error("Your session expired, please login");
        }
    },[limit, offset, searchText])

    React.useEffect(() => {
        const res = dispatch(getConfigDetails());
        res?.then((r) => {
            if(r?.payload?.statusCode === 200){
                r.payload?.data?.map(item => {
                    if(item?.configuration_type === "exam_category"){
                        setExamCat(item?.data);
                    }
                })
            }
        })
    },[])

    const resetStateForPaginationOfDifferentView = () => {
        setLimit(10);
        setOffset(0);
    };

    const examSearch = (e) => {
        if(limit === 10 && offset === 0){
            debouncedSave(e.target.value !== "" ? e.target.value : undefined)
        }else{
            resetStateForPaginationOfDifferentView()
        }
    }

    const debouncedSave = React.useCallback(
        debounce((newValue) => dispatch(setSearchText(newValue)), 1000),
        []
    );

    const goToExamDetail = (id) => {
        dispatch(setAddonId(id));
        localStorage.setItem("addonId", id);
        navigate(routes.EXAM_DETAIL);
    }

    const viewMore = () => {
        setLimit(limit + 10);
        setOffset(offset + 10);
    }

    const searchByExamCat = (item) => {
        dispatch(getCustomPages({
            limit, 
            offset, 
            searchText, 
            page_type: 'exam',
            exam_category: item
        }))
    }

    if (isLoading) return <Spinner />;
    // if (isError) return <h1>{message}</h1>;
    return (
        <>
            {console.log("test===>",customPageData)}
            <section className="exam-section-1">
                <div style={{
                    display: 'flex', 
                    justifyContent: 'flex-start',
                    flexDirection: 'column'
                }}
                className="exam-search-aria">
                    <h1>
                        Exams
                    </h1>
                    <div style={{position: 'relative'}}>
                        <TextField 
                            className="exam-search-text"
                            placeholder="Search Exams" 
                            value={searchText}
                            onChange={examSearch}
                            sx={{
                                border: 'none',
                                boxShadow: '0px 2px 10px 2px rgba(103, 47, 143, 0.3)',
                            }} />
                        <div style={{
                            position: 'absolute',
                            left: 14,
                            top: 12,
                            zIndex: 1,
                            backgroundColor: 'white'
                        }}>
                            <SearchIcon fontSize="large" sx={{
                                color: 'rgb(0, 0, 0, 0.5)'
                            }} />
                        </div>
                    </div>
                </div>
                <div style={{
                    backgroundColor: 'rgb(98, 85, 164, 0.14)',
                    padding: '8px 24px',
                    margin: '48px 0'
                }} className="exam-detail-aria">
                    <h1>
                        Exams category
                    </h1>  
                     {examCat?.map((item, index) => (
                        <div style={{
                            background: '#fff',
                            padding: '6px 16px',
                            borderRadius: '20px',
                            width: 'fit-content',
                            marginRight: '8px',
                            marginBottom: '12px',
                            display: 'inline-block',
                            color: 'rgb(0, 0, 0, 0.5)',
                            fontWeight: 'bold',
                            cursor: 'pointer',
                        }} key={index} onClick={() => searchByExamCat(item)}>
                            {item}
                        </div>
                     ))}
                </div>
                <div>
                    {(customPageData && customPageData?.length > 0) ?
                    customPageData?.map((item, index) => (
                        <div key={index} 
                            className="exam-card"
                            onClick={() => goToExamDetail(item?._id)}
                            style={{
                                marginRight: index%2 === 0 ? '24px' : 0,
                                cursor: 'pointer' }}>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                            }}>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <div>
                                        {item?.exam?.logo ? 
                                        <img 
                                            src={item?.exam?.logo} 
                                            alt="examImage" />
                                        :
                                            <SchoolIcon />
                                        }
                                        
                                    </div>
                                    <div className="exam-card-heading-text">
                                        <h2>{item?.name}</h2>
                                        <p style={{
                                            marginBottom: 0,
                                            marginTop: '4px'
                                        }} className="exam-card-sub-head">
                                            {item?.exam?.course_name}
                                        </p>
                                    </div>
                                </div>
                                <div style={{
                                    backgroundColor: 'rgb(236, 171, 45, 0.49)',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: '24px',
                                    color: 'rgb(0, 0, 0, 0.5)',
                                    fontWeight: 'bold',
                                }}
                                className="exam-mode">
                                    {item?.exam?.exam_type}
                                </div>
                            </div>
                            <div className="exam-card-detail">
                                <h3>
                                    EXAM DATE : {Moment(item?.exam?.exam_date).format('Do MMMM YYYY')}
                                </h3>
                                <p>
                                    JEE Main 2024 is expected to be conducted twice in 
                                    the month of January and April 2024.JEE Main 2024 
                                    first attempt exam date is last week of January 
                                    2024 (tentative). Candidates can expect NTA to 
                                    release JEE Main 2024 notification along with the 
                                    application form in the third week of December 
                                    2024
                                </p>
                            </div>
                        </div>
                    )):
                    <p>
                        No Exams Found
                    </p>
                    
                    }
                    {totalCount > 10 && <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}>
                        <Button sx={{
                            textTransform: 'capitalize',
                            textDecoration: 'underline',
                            color: 'rgb(0, 0, 0, 0.5)',
                            fontWeight: 'bold',
                        }} onClick={viewMore}>
                            View More
                        </Button>
                    </div>}
                </div>
            </section>
        </>
    );
}