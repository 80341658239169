import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import requestFormService from "./requestFormService";

const initialState = {
  productsData: null,
  searchText: "",
  totalCount: 0,
  isError: false,
  isSuccess: false,
  isLoading: false,
  newProductResponse: null,
  verifyResponse: null,
  universityByToken: null,
  coachingByToken: null,
  message: "",
  route: "",
  headerText: 'Dashboard',
  studentSignupResp: null,
  studentByToken: null,
  partnerByToken: null,
  leadGenerationData: null,
  leadById: null
};

export const getUniversityByToken = createAsyncThunk(
  "university/getUniversityByToken",
  async (params, thunkAPI) => {
    try {
      return await requestFormService.getUniversityByToken(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getCoachingByToken = createAsyncThunk(
  "university/getCoachingByToken",
  async (params, thunkAPI) => {
    try {
      return await requestFormService.getCoachingByToken(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getPartnerByToken = createAsyncThunk(
  "partner/getPartnerByToken",
  async (params, thunkAPI) => {
    try {
      return await requestFormService.getPartnerByToken(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getStudentByToken = createAsyncThunk(
  "student/getStudentByToken",
  async (params, thunkAPI) => {
    try {
      return await requestFormService.getStudentByToken(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const verify = createAsyncThunk(
  "verify/verify",
  async (params, thunkAPI) => {
    try {
      return await requestFormService.verify(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const postNewFormRequest = createAsyncThunk(
  "signup/postSignUpRequest",
  async (params, thunkAPI) => {
    try {
      return await requestFormService.postSignUpRequest(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const sendOTP = createAsyncThunk(
  "signup/sendOTP",
  async (params, thunkAPI) => {
    try {
      return await requestFormService.sendOTP(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const studentSignup = createAsyncThunk(
  "signup/studentSignup",
  async (params, thunkAPI) => {
    try {
      return await requestFormService.studentSignup(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const postLeadGeneration = createAsyncThunk(
  "lead/postLeadGeneration",
  async (params, thunkAPI) => {
    try {
      return await requestFormService.postLeadGeneration(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getLeadGeneration = createAsyncThunk(
  "lead/getLeadGeneration",
  async (params, thunkAPI) => {
    try {
      return await requestFormService.getLeadGeneration(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getLeadById = createAsyncThunk(
  "lead/getLeadById",
  async (params, thunkAPI) => {
    try {
      return await requestFormService.getLeadById(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateData = createAsyncThunk(
  "update/updateData",
  async (params, thunkAPI) => {
    try{
      return await requestFormService.updateData(params);
    }catch(error){
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
)

const requestFormSlice = createSlice({
  name: "requestForms",
  initialState,
  reducers: {
    setSearchTextInForm: (state, action) => {
      state.searchText = action.payload;
    },
    setRightHeaderText: (state, action) => {
      state.headerText = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(postNewFormRequest.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postNewFormRequest.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(postNewFormRequest.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.newProductResponse = action.payload;
      })
      .addCase(verify.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(verify.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(verify.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.verifyResponse = action.payload;
      })
      .addCase(getUniversityByToken.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUniversityByToken.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getUniversityByToken.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.universityByToken = action.payload;
      })
      .addCase(getCoachingByToken.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCoachingByToken.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getCoachingByToken.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.coachingByToken = action.payload;
      })
      .addCase(getPartnerByToken.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPartnerByToken.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getPartnerByToken.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.partnerByToken = action.payload;
      })
      .addCase(getStudentByToken.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getStudentByToken.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getStudentByToken.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.studentByToken = action.payload;
      })
      .addCase(studentSignup.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(studentSignup.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(studentSignup.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.studentSignupResp = action.payload;
      })
      .addCase(postLeadGeneration.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postLeadGeneration.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(postLeadGeneration.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(getLeadGeneration.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getLeadGeneration.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getLeadGeneration.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.totalCount = action.payload.data.totalCount;
        state.leadGenerationData = action.payload.data.result;
      })
      .addCase(getLeadById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getLeadById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getLeadById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.leadById = action.payload.data;
      })
      .addCase(sendOTP.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(sendOTP.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(sendOTP.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = action.payload;
      });
  },
});

export const { 
  setSearchTextInForm,
  setRightHeaderText } = requestFormSlice.actions;

export default requestFormSlice.reducer;
