import * as React from 'react';
import "./blog.css";
import HomeLayout from "../homeLayout/HomeLayout";
import { useDispatch, useSelector } from "react-redux";
import routes from "../../react_routes_enum";
import JoditEditor from 'jodit-react';
import LoaderIcon from "../../components/Loader/Loader";
import {
    InputLabel,
    TextField,
    Button,
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { useState } from "react";
import { validateUrl } from "../../utils/validateUrl";
import UploadBox from '../../components/UploadBox/uploadBox';
import { useNavigate, useLocation } from "react-router-dom";
import { 
    addBlog } from "../../redux/instituteStudent/instituteStudentSlice";
import { toast } from "react-toastify";

let array = [];
let array2 = [];
export default function BlogDetail(){
    const isWebsiteFor = localStorage.getItem("is-website-for");
    const editor = React.useRef(null);
    const [btnLoader, setBtnloader] = useState(false);
    const [imageArray, setImageArray] = useState([""]);
    const [blogData, setBlogData] = useState({});
    const [tagsData, setTagsData] = useState("");
    const [metaKeywords, setMetaKeywords] = useState("");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { blogsById } = useSelector((state) => state.instituteStudent);

    const addMore = () => {
        let array = imageArray;
        if(array.length > 4){
            toast.error("Maximum 5 images are allowed")
            return;
        }
        array.push("");
        setImageArray([...array]);
    }
    const handleChange = (e) => {
        
        e.target.name === "title" && setBlogData({...blogData, title: e.target.value});
        e.target.name === "meta_title" && setBlogData({...blogData, meta: 
            {...blogData.meta, title:e.target.value}})
        e.target.name === "meta_description" && setBlogData({...blogData, meta: 
            {...blogData.meta, description: e.target.value}})
        e.target.name === "category" && setBlogData({...blogData, category: e.target.value})
        e.target.name === "description" && 
            setBlogData({...blogData, description: e.target.value})
        e.target.name === "embedded_video" && 
            setBlogData({...blogData, embedded_videos: [e.target.value]})
        if(e.target.name === "tags"){
            setTagsData(e.target.value);
            if(e.target.value.split("\n").length > 1){
                array.push(e.target.value.split("\n")[0]);
                setBlogData({...blogData, tags: [...array]})
                setTagsData("");
            }
        }
        if(e.target.name === "meta_keywords"){
            setMetaKeywords(e.target.value);
            if(e.target.value.split("\n").length > 1){
                array2.push(e.target.value.split("\n")[0]);
                setBlogData({...blogData, meta: {...blogData.meta, keywords: [...array2]}})
                setMetaKeywords("");
            }
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if(!valideForm().status){
            toast.error(valideForm().message);
            return;
        }
        setBtnloader(true);
        const res = dispatch(addBlog(blogData));
        res?.then((r) =>{
            setBtnloader(false);
            if(r?.payload?.statusCode === 200){
                toast.success("Blog Sent Successfully");
                navigate(routes.BLOG);
            }else{
                toast.error(r?.payload?.message);
            }
        })
    }
    const valideForm = () => {
        let error = {message: '', status: true}
        if(!blogData?.title || blogData?.title === ""){
            error.message = "Title Required";
            error.status = false
            return error;
        }
        if(!blogData?.description || blogData?.description === ""){
            error.message = "Description Required";
            error.status = false
            return error;
        }
        if(!blogData?.tags || blogData?.tags.length === 0){
            error.message = "Atleast 1 Tag Required";
            error.status = false
            return error;
        }
        if(!blogData?.category || blogData?.category === ""){
            error.message = "Category Required";
            error.status = false
            return error;
        }
        if(!blogData?.meta?.title || blogData?.meta?.title === ""){
            error.message = "Meta Title Required";
            error.status = false
            return error;
        }
        if(!blogData?.meta?.description || blogData?.meta?.description === ""){
            error.message = "Meta Description Required";
            error.status = false
            return error;
        }
        if(!blogData?.meta?.keywords || blogData?.meta?.keywords.length === 0){
            error.message = "Atleast 1 Meta Keyword Required";
            error.status = false
            return error;
        }
        if(blogData?.embedded_videos && blogData?.embedded_videos[0] !== ""){
            if(!validateUrl(blogData?.embedded_videos[0])){
                error.message = "Video link is not valid";
                error.status = false
                return error;
            }
        }
        if(!blogData?.images || blogData?.images?.length === 0){
            error.message = "Atleast 1 Image Required";
            error.status = false
            return error;
        }
        return error;
    }

    const handleImageUploads = (name, value) => {
        let arr = imageArray;
        arr[parseInt(name.split("_")[3])] = value;
        setBlogData({...blogData, images: [...arr]})
        setImageArray([...arr]);
    }
    React.useEffect(() => {
        if(blogsById){
            setBlogData(blogsById);
            setTagsData(blogsById?.tags?.toString());
            setMetaKeywords(blogsById?.meta?.keywords?.toString());
            setImageArray(blogsById?.images);
        }
    },[])

    return(
        <>
            <HomeLayout/>
            <form onSubmit={handleSubmit}>
                <div className="blog-form-box" 
                    style={{
                        marginTop:'100px', 
                        marginBottom: '24px'}}>
                    <div className="main-writing-box">
                        <div className="blog-text-box">
                            <InputLabel className="input-label-text">Title</InputLabel>
                            <TextField 
                                placeholder="" 
                                name="title" 
                                type="text" 
                                variant="outlined" 
                                className="inputShadow widthInput"
                                value={blogData?.title ? blogData?.title : ''}
                                sx={{
                                    width: '100%',
                                    height: '62px'
                                }} 
                                onChange={handleChange} 
                                required />
                        </div>
                        <div className="blog-text-box">
                            <div style={{
                                marginBottom: '20px'
                            }} className="rich-text-box">
                                <InputLabel className="input-label-text">
                                    Description
                                </InputLabel>
                                <JoditEditor 
                                    name="description"
                                    required
                                    ref={editor}
                                    value={blogData?.description ? blogData?.description : ''}
                                    onChange={(e) => handleChange({
                                        target:{name: 'description', value: e}})}
                                />
                            </div>
                        </div>
                        <div className="blog-tag-box tags-aria">
                            <div className="blog-tag-box-inp">
                                <InputLabel className="input-label-text">
                                    Tags <span style={{
                                        fontSize: '12px',
                                        color: 'rgb(0, 0, 0, 0.5)'
                                    }}>(press enter to add tag)</span>
                                </InputLabel>
                                <TextField 
                                    placeholder=""
                                    multiline
                                    name="tags"
                                    type="text"
                                    variant="outlined"
                                    className="inputShadow"
                                    value={tagsData}
                                    sx={{
                                        width: '100%',
                                        height: '62px'
                                    }} 
                                    onChange={handleChange}/>
                                    {blogData?.tags && 
                                    blogData?.tags?.map((item, index) => (
                                    index + 1 === blogData?.tags.length ? 
                                    `${item}` : `${item}, `))}
                            </div>
                            <div className="blog-tag-box-inp marginLeft">
                                <InputLabel className="input-label-text">
                                    Category
                                </InputLabel>
                                <TextField 
                                    placeholder="" 
                                    name="category" 
                                    type="text" 
                                    variant="outlined" 
                                    className="inputShadow"
                                    value={blogData?.category ? blogData?.category : ''}
                                    sx={{
                                        width: '100%',
                                        height: '62px'
                                    }} 
                                    onChange={handleChange}/>
                            </div>
                        </div>
                        <div className="blog-tag-box tags-aria">
                            <div className="blog-tag-box-inp">
                                <InputLabel className="input-label-text">
                                    Meta Title
                                </InputLabel>
                                <TextField 
                                    placeholder="" 
                                    name="meta_title" 
                                    type="text" 
                                    variant="outlined" 
                                    className="inputShadow"
                                    value={blogData?.meta?.title ? 
                                        blogData?.meta?.title : ''}
                                    sx={{
                                        width: '100%',
                                        height: '62px'
                                    }} 
                                    onChange={handleChange}/>
                            </div>
                            <div className="blog-tag-box-inp marginLeft">
                                <InputLabel className="input-label-text">
                                    Meta Description
                                </InputLabel>
                                <TextField 
                                    placeholder="" 
                                    name="meta_description" 
                                    type="text" 
                                    variant="outlined" 
                                    className="inputShadow"
                                    value={blogData?.meta?.description ? 
                                        blogData?.meta?.description : ''}
                                    sx={{
                                        width: '100%',
                                        height: '62px'
                                    }} 
                                    onChange={handleChange}/>
                            </div>
                        </div>
                        <div className="blog-tag-box tags-aria">
                            <div className="blog-tag-box-inp">
                                <InputLabel className="input-label-text">
                                    Meta Keywords <span style={{
                                        fontSize: '12px',
                                        color: 'rgb(0, 0, 0, 0.5)'
                                    }}>(press enter to add tag)</span>
                                </InputLabel>
                                <TextField 
                                    placeholder="" 
                                    name="meta_keywords" 
                                    type="text" 
                                    multiline
                                    variant="outlined" 
                                    className="inputShadow"
                                    value={metaKeywords ? metaKeywords : ''}
                                    sx={{
                                        width: '100%',
                                        height: '62px'
                                    }} 
                                    onChange={handleChange}/>
                                {blogData?.meta?.keywords && 
                                    blogData?.meta?.keywords?.map((item, index) => (
                                    index + 1 === blogData?.meta?.keywords.length ? 
                                    `${item}` : `${item}, `))}
                            </div>
                            <div className="blog-tag-box-inp marginLeft">
                                <InputLabel className="input-label-text">
                                    Embedded Videos
                                </InputLabel>
                                <TextField 
                                    placeholder="" 
                                    name="embedded_video" 
                                    multiline
                                    type="text" 
                                    variant="outlined" 
                                    className="inputShadow"
                                    value={blogData?.embedded_videos ? 
                                        blogData?.embedded_videos[0] : ''}
                                    sx={{
                                        width: '100%',
                                        height: '62px'
                                    }} 
                                    onChange={handleChange}/>
                            </div>
                        </div>
                        <div className="blog-text-box">
                            <InputLabel className="input-label-text">Upload Images</InputLabel>
                            <div style={{
                                display: 'inline-block'
                            }}>
                                {imageArray.map((item, index) => (
                                    <div style={{
                                        display: 'inline-block',
                                        marginRight: '16px',
                                        marginBottom: '8px'
                                        }} key={index}>
                                        <UploadBox
                                            name={`add_on_img_${index}`}
                                            height={'166px'}
                                            width={'216px'}
                                            value={item ? item : ""}
                                            handleUpload={
                                                (name, value) => handleImageUploads(name, value, index)
                                            }
                                        />
                                    </div>
                                ))}
                                <Button style={{
                                    backgroundColor: isWebsiteFor === 'university' ? 'var(--primary-color)' : 'var(--tertiary-color)',
                                    
                                }} onClick={addMore} className="add-more-button">
                                    <AddIcon /> Add More
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="submit-button-box">
                        {location.pathname !== "/blogs/view" &&
                        <Button variant="contained"
                        type="submit"
                        disabled={btnLoader}
                        sx={{
                            float:'right',
                            backgroundColor: isWebsiteFor === 'university' ? 
                                (btnLoader ? 'rgb(0, 0, 0, 0.2)' : 
                                    'var(--secondary-color)') : 
                                    (btnLoader ? 'rgb(0, 0, 0, 0.2)' : '#000'),
                            width: '90px',
                            height:'30px',
                            textTransform: 'capitalize',
                            "&:hover": {
                                backgroundColor: 'var(--secondary-color)',
                            },
                        }}
                        >
                            {btnLoader ? <LoaderIcon /> : 'Submit'}
                        </Button>}
                    </div>
                </div>
            </form>
        </>
    )
}