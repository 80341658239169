import axios from "axios";
import endpoint from "../../index.routes";

const getStudentUniversity = async (params) => {
    const { 
      limit, 
      offset, 
      searchText, 
      city, 
      category,
      country, 
      rating,
      under_graduation,
      post_graduation } = params;
    const response = await axios.get(
      endpoint.STUDENT_UNIVERSITY,
      {
        params: category ? { limit, 
          offset, 
          searchText, 
          city, 
          category, 
          country, 
          rating, 
          under_graduation: under_graduation ? 1 : null, 
          post_graduation: post_graduation ? 1 : null } :
          { limit, 
            offset, 
            searchText, 
            city, 
            country, 
            rating, 
            under_graduation: under_graduation ? 1 : null, 
            post_graduation: post_graduation ? 1 : null }
          ,
        headers: { token: localStorage.getItem("cc_token") },
      }
    );
    return response.data;
};

const getStudentCoaching = async (params) => {
  const { limit, offset, searchText, category, city, rating, subCategory } = params;
  const response = await axios.get(
    endpoint.STUDENT_COACHING,
    {
      params: { limit, offset, searchText, category, city, rating, subCategory },
      headers: { token: localStorage.getItem("cc_token") },
    }
  );
  return response.data;
};

const getPurchase = async (params) => {
  const { limit, offset, searchText } = params;
  const response = await axios.get(
    endpoint.STUDENT_PURCHASE,
    {
      params: { limit, offset, searchText },
      headers: { token: localStorage.getItem("cc_token") },
    }
  );
  return response.data;
};

const getReviews = async (params) => {
  let instId = {}
  const { coaching_id, university_id } = params;
  if(coaching_id){
    instId = {coaching_id}
  }if(university_id){
    instId = {university_id}
  }
  const response = await axios.get(
    endpoint.STUDENT_REVIEWS,
    {
      params: instId,
      headers: { token: localStorage.getItem("cc_token") },
    }
  );
  return response.data;
};

const addReview = async (params) => {
  
  const response = await axios.post(endpoint.STUDENT_REVIEWS, params, 
    {
      headers: { token: localStorage.getItem("cc_token") },
    });
  return response.data;
};

const addToCart = async (params) => {
  
  const response = await axios.post(endpoint.STUDENT_ADD_TO_CART, params, 
    {
      headers: { token: localStorage.getItem("cc_token") },
    });
  return response.data;
};

const decrementFromCart = async (params) => {
  
  const response = await axios.post(
    `${endpoint.DECREMENT_CART_ITEM}/${params.id}/decrement`, {}, 
    {
      headers: { token: localStorage.getItem("cc_token") },
    });
  return response.data;
};

const postStudentApplication = async (params) => {
  
  const response = await axios.post(endpoint.STUDENT_APPLICATION, params, 
    {
      headers: { token: localStorage.getItem("cc_token") },
    });
  return response.data;
};

const getStudentApplication = async (params) => {
  
  const response = await axios.get(endpoint.STUDENT_APPLICATION, 
    {
      headers: { token: localStorage.getItem("cc_token") },
    });
  return response.data;
};

const getStudentApplicationById = async (params) => {
  let { id } = params;
  const response = await axios.get(endpoint.STUDENT_APPLICATION+"/"+id, 
    {
      headers: { token: localStorage.getItem("cc_token") },
    });
  return response.data;
};

const getCourseById = async (params) => {
  let { id } = params;
  const response = await axios.get(endpoint.STUDENT_COURSE+"/"+id, 
    {
      headers: { token: localStorage.getItem("cc_token") },
    });
  return response.data;
};

const getInstProducts = async (params) => {
  const { limit, offset, searchText, university_id, coaching_id } = params;
  const response = await axios.get(
    endpoint.STUDENT_PRODUCT,
    {
      params: { limit, offset, searchText, university_id, coaching_id },
      headers: { token: localStorage.getItem("cc_token") },
    }
  );
  return response.data;
};

const getReviewStats = async (params) => {
  const { university_id, coaching_id } = params;
  let param = university_id ? {university_id} : {coaching_id}
  const response = await axios.get(
    endpoint.REVIEW_STATS,
    {
      params: param,
      headers: { token: localStorage.getItem("cc_token") },
    }
  );
  return response.data;
};

const getFromCart = async (params) => {
  const response = await axios.get(
    endpoint.GET_FROM_CART,
    {
      headers: { token: localStorage.getItem("cc_token") },
    }
  );
  return response.data;
};

const deletFromCart = async (params) => {
  let { id } = params;
  const response = await axios.delete(
    endpoint.STUDENT_ADD_TO_CART+"/"+id,
    {
      headers: { token: localStorage.getItem("cc_token") },
    }
  );
  return response.data;
};

const getInstituteById = async (params) => {
  const { id, type } = params;
  let path = type === "university" ? endpoint.STUDENT_UNIVERSITY : endpoint.STUDENT_COACHING
  const response = await axios.get(path + "/" + id,
    {
      headers: { token: localStorage.getItem("cc_token") },
    }
  );
  return response.data;
};

const getProductById = async (params) => {
  const { id } = params;
  const response = await axios.get(endpoint.STUDENT_PRODUCT + "/" + id,
    {
      headers: { token: localStorage.getItem("cc_token") },
    }
  );
  return response.data;
};

const payRegistrationFee = async (params) => {
  const { id } = params;
  const response = await axios.post(endpoint.STUDENT_APPLICATION + "/" + id + "/pay-registration-fee",{},
    {
      headers: { token: localStorage.getItem("cc_token") },
    }
  );
  return response.data;
};

const payFinalFee = async (params) => {
  const { billingBody, id } = params;
  const response = await axios.post(endpoint.STUDENT_APPLICATION + "/" + id + "/pay-target-fee",
    billingBody,
    {
      headers: { token: localStorage.getItem("cc_token") },
    }
  );
  return response.data;
};

const getAddonsByProductId = async (params) => {
  const { id } = params;
  const response = await axios.get(endpoint.STUDENT_ADD_ONS + "?product_id=" + id,
    {
      headers: { token: localStorage.getItem("cc_token") },
    }
  );
  return response.data;
};

const getSubProductByProductId = async (params) => {
  const { id } = params;
  const response = await axios.get(endpoint.STUDENT_SUB_PRODUCT + "?product_id=" + id,
    {
      headers: { token: localStorage.getItem("cc_token") },
    }
  );
  return response.data;
};

const updateStudentApplication = async (params) => {
  const { id, body } = params;
  const response = await axios.put(endpoint.STUDENT_APPLICATION + "/" + id, body,
    {
      headers: { token: localStorage.getItem("cc_token") },
    }
  );
  return response.data;
};

const courseProgress = async (params) => {
  const response = await axios.put(endpoint.STUDENT_PROGRESS, params,
    {
      headers: { token: localStorage.getItem("cc_token") },
    }
  );
  return response.data;
};

const updateStudentProfile = async (params) => {
  
  const response = await axios.put(endpoint.ADD_UNIVERSITY_TEST, params,
    {
      headers: { token: localStorage.getItem("cc_token") },
    }
  );
  return response.data;
};

const getBlogs = async (params) => {
  const response = await axios.get(
    endpoint.STUDENT_BLOG,
    {
      headers: { token: localStorage.getItem("cc_token") },
    }
  );
  return response.data;
};

const getBlogById = async (params) => {
  let {id} = params;
  const response = await axios.get(
    endpoint.STUDENT_BLOG+"/"+id,
    {
      headers: { token: localStorage.getItem("cc_token") },
    }
  );
  return response.data;
};

const addContactUs = async (params) => {
  
  const response = await axios.post(endpoint.STDENT_CONTACT, params, 
    {
      headers: { token: localStorage.getItem("cc_token") },
    });
  return response.data;
};

const studentsServices = { 
    getStudentUniversity,
    getStudentCoaching,
    getInstituteById,
    getReviews,
    getInstProducts,
    addReview,
    getProductById,
    getAddonsByProductId,
    addToCart,
    getSubProductByProductId,
    postStudentApplication,
    getReviewStats,
    getFromCart,
    getStudentApplication,
    getStudentApplicationById,
    payRegistrationFee,
    updateStudentApplication,
    payFinalFee,
    deletFromCart,
    getBlogs,
    getBlogById,
    addContactUs,
    decrementFromCart,
    updateStudentProfile,
    getCourseById,
    courseProgress,
    getPurchase
};

export default studentsServices;
