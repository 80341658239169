import * as React from 'react';
import {
    Button,
    Input,
    InputLabel,
    Typography,
    TextField
} from "@mui/material";
import Select from '../Select/Select';
import { makeStyles } from "@mui/styles";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { useSelector } from "react-redux";
import JoditEditor from 'jodit-react';
import { toast } from "react-toastify";
import upload from '../../assest/svgs/upload.svg';
import UploadPinkIcon from '../../assest/svgs/upload-pink-icon.svg';
import FormControlLabel from '@mui/material/FormControlLabel';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import Loader from "../../assest/images/preloader.gif";
import getImageUrls from "../../api/uploadImageOnS3";

const useStyle = makeStyles({
    marginBot24: {
        marginBottom: '24px',
    },
    upload: {
        border: '1px solid rgb(0, 0, 0, 0.2) !important',
        fontSize: '10px !important',
        color: 'rgb(0, 0, 0, 0.4) !important',
        textTransform: 'capitalize !important',
        width: '80px',
        height: '20px',
        marginBottom: '4px'
    }
})


const coachingCat = ["Online", "Offline", "Both"];
const universityCat = ["Engineering", "Medical"];

const SubProduct = (props) => {
    const isWebsiteFor = localStorage.getItem("is-website-for");
    const editor = React.useRef(null);
    const classes = useStyle();

    const { testData } = useSelector((state) => state.test);
    const { courseData, contentData } = useSelector((state) => state.product);

    const { coaching_category } = useSelector((state) => state.config);

    return (
        <div>
            <Typography variant="h3" component="h3" sx={{
                color: isWebsiteFor === "university" ? '#672F8F' : 'var(--tertiary-color)',
                fontSize: '24px',
                marginBottom: '8px',
                fontWeight: 'bold'
            }}>
                Sub-Products
            </Typography>
            <div className={[`${classes.marginBot24} form-part-1`]}>
                <div className='three-input-box marg`in-bot-change'>
                    <InputLabel className={classes.formInputLabel}>Title</InputLabel>
                    <TextField 
                        placeholder="" 
                        type="text" 
                        variant="outlined" 
                        name={`sub_product_${props.index}`} 
                        value={props.item?.title}
                        sx={{
                            width: '100%',
                            height: '62px'
                        }} 
                        onChange={(e) => props.handleChange(e, props.index)} 
                        required/>
                </div>
                <div className='three-input-box category-input margin-bot-change'>
                    <InputLabel className={classes.formInputLabel}>Course Duration</InputLabel>
                    <TextField 
                        placeholder="" 
                        type="text" 
                        variant="outlined" 
                        name={`course_duration_${props.index}`} 
                        sx={{
                            width: '100%',
                            height: '62px'
                        }} 
                        value={props.item?.duration}
                        onChange={(e) => props.handleChange(e, props.index)} 
                        required/>
                </div>
                <div className='three-input-box pay-box'>
                    <InputLabel className={classes.formInputLabel}>
                        How you want to do payment ?
                    </InputLabel>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue={props.item?.payment_procedure}
                        name={`sub_product_payment_process_${props.index}`}
                        className="how-pay"
                        onChange={(e) => props.handleChange(e, props.index)}
                    >
                        <FormControlLabel 
                            value="direct_pay" 
                            control={<Radio sx={{
                                color: 'var(--secondary-color)',
                                '&.Mui-checked': {
                                    color: 'var(--secondary-color)',
                                    },
                            }} />} 
                            label="Direct Pay" />
                        <FormControlLabel 
                            value="full_process" 
                            control={<Radio sx={{
                                color: 'var(--secondary-color)',
                                '&.Mui-checked': {
                                    color: 'var(--secondary-color)',
                                    },
                            }} />} 
                            label="Follow Full Process" />
                    </RadioGroup>
                </div>
            </div>
            <div className={[`${classes.marginBot24} description-box`]}>
                <div className="description-input sub-pro-description-input">
                    <InputLabel className={classes.formInputLabel}>Description</InputLabel>
                    <JoditEditor 
                        ref={editor}
                        value={props.item?.description}
                        onChange={newText => props.textEditor(newText)}
                    />
                </div>
                <div className="branch-course">
                    <div className="three-input-box margin-bot-change sub-pro-fees sub-fee-type">
                        <div>
                            <InputLabel className={classes.formInputLabel}>
                                Fees per annum (Rs)
                            </InputLabel>
                            <TextField 
                                placeholder="" 
                                type="number" 
                                variant="outlined" 
                                name={`fees_${props.index}`}  
                                value={props.item?.fees}
                                sx={{
                                    width: '100%',
                                    height: '62px'
                                }} 
                                onChange={(e) => props.handleChange(e, props.index)} 
                                required/>
                        </div>
                        <div className="marginInLeft">
                            <InputLabel className={classes.formInputLabel}>
                                GST (in %)
                            </InputLabel>
                            <TextField 
                                placeholder="" 
                                type="number" 
                                variant="outlined" 
                                name={`gst_${props.index}`}  
                                value={props.item?.gst}
                                sx={{
                                    width: '100%',
                                    height: '62px'
                                }} 
                                onChange={(e) => props.handleChange(e, props.index)} 
                                required/>
                        </div>
                    </div>
                    <div className="three-input-box course-type-input margin-bot-change">
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue={props.item?.is_percentage_discount ? 'percentage' : 'flat'}
                            name={`per_${props.index}`}
                            onChange={(e) => props.handleChange(e, props.index)}
                            required
                        >
                            <FormControlLabel 
                                value="percentage" 
                                control={<Radio sx={{
                                    color: 'var(--secondary-color)',
                                    '&.Mui-checked': {
                                        color: 'var(--secondary-color)',
                                        },
                                }} />} 
                                label="Percentage Discount"/>
                            <FormControlLabel 
                                value="flat" 
                                control={<Radio sx={{
                                    color: 'var(--secondary-color)',
                                    '&.Mui-checked': {
                                        color: 'var(--secondary-color)',
                                        },
                                }} />} 
                                label="Flat Discount"/>
                        </RadioGroup>
                    </div>
                </div>
            </div>
            <div className={[`${classes.marginBot24} form-part-1 semes-box`]}>
                <div className='three-input-box sub-pro-fees margin-bot-change'>
                    <InputLabel className={classes.formInputLabel}>
                        {isWebsiteFor === "university" ? 'Semester' : 'Subject'}
                    </InputLabel>
                    <Select 
                        name={`s_${props.index}`}
                        value={isWebsiteFor === "university"? props.item?.semester : props.item?.subjects}
                        option={isWebsiteFor === "university" ? props.semesList : coaching_category[0]?.data } 
                        handleSelect={(e) => props.handleChange(e, props.index)}
                    />
                </div>
                {isWebsiteFor === "university" &&
                <div className='three-input-box sub-pro-fees category-input margin-bot-change'>
                    <InputLabel className={classes.formInputLabel}>Subject</InputLabel>
                    <Select 
                        name={`subj_${props.index}`}
                        value={props.item.subjects[0]}
                        option={isWebsiteFor === "university" ? universityCat : coachingCat } 
                        handleSelect={(e) => props.handleChange(e, props.index)}
                    />
                </div>}
                <div className={
                    `three-input-box sub-pro-fees sub-fee-type 
                    ${isWebsiteFor !== "university" && 'leftMargin'}`
                    }>
                    <div>
                        <InputLabel className={classes.formInputLabel}>Discount or offers</InputLabel>
                        <TextField 
                            placeholder="" 
                            type="number" 
                            variant="outlined" 
                            value={props.item.discount}
                            name={`discount_${props.index}`} 
                            sx={{
                                width: '100%',
                                height: '62px'
                            }} 
                            onChange={(e) => props.handleChange(e, props.index)} 
                            required/>
                    </div>
                    <div className="marginInLeft">
                        <InputLabel className={classes.formInputLabel}>
                            Eligibility Criteria
                        </InputLabel>
                        <TextField 
                            placeholder="" 
                            type="text" 
                            variant="outlined" 
                            value={props.item.eligibility_criteria}
                            name={`eligibility_${props.index}`} 
                            sx={{
                                width: '100%',
                                height: '62px'
                            }} 
                            onChange={(e) => props.handleChange(e, props.index)} 
                            required/>
                    </div>
                </div>
            </div>
            <InputLabel className={classes.formInputLabel}>
                Select anyone of them <span className="optional-text-style">(Optional)</span>
            </InputLabel>
            <div className={[`${classes.marginBot24} form-part-1`]}>
                <div className='four-input-box sub-pro-fees margin-bot-change'>
                    <InputLabel className={classes.formInputLabel}>
                        Select Test
                    </InputLabel>
                    <Select 
                        from="test"
                        name={`sub_test_${props.index}`}
                        value={props.item?.test_id}
                        option={testData ? testData : []} 
                        handleSelect={(e) => props.handleChange(e, props.index)}
                    />
                </div>
                <div className='four-input-box sub-pro-fees category-input margin-bot-change'>
                    <InputLabel className={classes.formInputLabel}>
                        Select Course
                    </InputLabel>
                    <Select 
                        from="test"
                        name={`sub_course_${props.index}`}
                        value={props.item?.course_id}
                        option={courseData ? courseData : []} 
                        handleSelect={(e) => props.handleChange(e, props.index)}
                    />
                </div>
                <div className='four-input-box author-box'>
                    <InputLabel className={classes.formInputLabel}>
                        Study Material or Notes
                    </InputLabel>
                    <Select 
                        from="test"
                        name={`sub_study_material_${props.index}`}
                        value={props.item?.reading_material_id}
                        option={contentData ? contentData : []} 
                        handleSelect={(e) => props.handleChange(e, props.index)}
                    />
                    {/* <label htmlFor={`sub_study_material_${props.index}`} style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '64%',
                        height: '100%',
                    }}>
                        {props?.item?.study_material ? 
                            <span style={{
                                fontSize: '12px',
                                color: 'rgb(0, 0, 0, 0.5)',
                                lineHeight: '14px',
                                marginBottom: '4px',
                                marginTop: '8px',
                            }}>{props?.item?.study_material.split('-')[3]}</span>
                            : <img src={isWebsiteFor === "university" ? 
                                upload : UploadPinkIcon} 
                            alt="upload" width="20" height="32" />}
                        <Input
                            id={`sub_study_material_${props.index}`}
                            name={`sub_study_material_${props.index}`}
                            type="file"
                            accept="application/pdf"
                            sx={{display: 'none'}}
                            onChange={(e) => {uploadImage(e, props.index)}}
                        />
                        <Button component="div" className={classes.upload}>
                            <UploadFileIcon fontSize="15" />
                            {(loading && (loaderIndex === props.index)) ? 
                                <img src={Loader} width="12" alt="Loader" /> : 
                                <span>{'Upload'}</span>}
                        </Button>
                    </label> */}
                </div>
            </div>
        </div>
    )
}
export default SubProduct