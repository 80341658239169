import * as React from 'react';
import { useNavigate } from "react-router-dom";
import VisibilityIcon from '@mui/icons-material/Visibility';
import Spinner from "../../components/Spinner/Spinner";
import { 
  Typography,
  IconButton,
} from '@mui/material';
import HomeLayout from "../homeLayout/HomeLayout";
import routes from '../../react_routes_enum';
import StudentProfileTable from "../../components/BasicTable/BasicTable";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../components/Pagination/Pagination";
import { getInstituteApplication, setInstituteId } from '../../redux/instituteStudent/instituteStudentSlice';

export default function ApprovedStudent() {
  const isWebsiteFor = localStorage.getItem("is-website-for");
  const [limit, setLimit] = React.useState(10);
  const [offset, setOffset] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const colR = ["SNo", "Name of Student","DOB","Status","Action", ];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { 
    isLoading,
    totalCount,
    searchText,
    applicationData,
  }= useSelector((state) => state.instituteStudent);
  
  React.useEffect(() => {
    dispatch(getInstituteApplication({
      limit, 
      offset, 
      searchText, 
      status: ['target_accepted', 'target_fee_pending'], 
      isWebsiteFor }))
  }, [limit, offset, searchText])

  const handleClickOpenView = (id) => {
    dispatch(setInstituteId(id))
    navigate(routes?.REGISTER_TO_APPLY);
  };

  if (isLoading) return <Spinner />;

  return (
    <>
      <HomeLayout />
      <div style={{
        marginTop: '100px'
      }} className="pagination-addPost"> 
        <Pagination
          totalCount={totalCount}
          limit={limit}
          page={page}
          changeLimit={setLimit}
          changeOffset={setOffset}
          changePage={setPage}
        />
      </div>
      <div className="content-box table-box" style={{marginTop: '0px'}}>
        <StudentProfileTable
          columns={colR}
          where="recipes"
          rows={
            applicationData && applicationData?.map((item) => {
              const res = {
              ...item,
              Status: (
                <span style={{
                  color: '#F69220',
                  fontSize: '18px',
                  fontWeight: 'bold'
                }}>
                  Awaiting for fee
                </span>
              ),
              Action: (
                <div>
                      <IconButton
                          color="inherit"
                          edge="start"
                          onClick={() => handleClickOpenView(item?._id)}
                          style={{display:'block'}}
                          >
                            <VisibilityIcon sx={{color: '#BA68C8'}} className="action-icon" />
                            <Typography component="p" variant="p" 
                            sx={{
                              color: '#BA68C8',
                              fontWeight: '500'
                              }} className="action-buton">
                            View
                            </Typography>
                      </IconButton>
                  </div>
              )
              };
              return res;
          })
          }
        />
      </div>
    </>
  );
}