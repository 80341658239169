import * as React from 'react';
import Button from '@mui/material/Button';
import { useDispatch } from "react-redux";
import { MenuItem, InputLabel, Select } from '@mui/material';
import TextField from '@mui/material/TextField';
import HomeLayout from "../homeLayout/HomeLayout";
import ThreeDotMenu from '../../assest/svgs/three-dot-menu.svg';
import TwoDotMenu from '../../assest/svgs/tow-dot-menu.svg';
import WhiteTick from '../../assest/svgs/white-tick.svg';
import GreenTick from '../../assest/svgs/green-tick.svg';
import CloseIcon from '@mui/icons-material/Close';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import UploadBox from "../../components/UploadBox/uploadBox";
import EastIcon from '@mui/icons-material/East';
import { getConfigDetails } from "../../redux/configurationDetails/configDetailsSlice";
import { makeStyles } from "@mui/styles";
import "./question.css";

const useStyle = makeStyles({
  addMore:{
    height: '36px',
    textTransform: 'capitalize !important',
    fontWeight: 'bold !important',
    color: '#fff !important',
    '&:hover':{
      backgroundColor: 'var(--primary-color) !important',
    }
  },
  addQuestion:{
    backgroundColor: '#3C1CDE !important',
    color: '#fff !important',
    borderRadius: '8px !important',
    fontWeight: 'bold !important',
    textTransform: 'capitalize !important',
    '&:hover':{
      backgroundColor: '#3C1CDE !important',
    }
  }
})
let question = [
  {
    question: 'sdfsdfg',
    isSingleSelect: true,
    options: [
      {
        optionValue: '',
        isCorrect: true
      },
      {
        optionValue: '',
        isCorrect: false
      }
    ]
  }
]

const testBody = {
  title: "Test 5",
  duration_in_min: 60,
  instructions: [ "", "", ""],
  marks_per_question: {
    negative: 0,
    positive: 1
  },
  question_ids: [
      "64862b632e092cf4bb3e5ef7",
      "64862b192e092cf4bb3e5eec"
  ]
}

export default function Question() {
  const classes = useStyle();
  const dispatch = useDispatch();
  const [isQuestionAdding, setIsQuestionAdding] = React.useState(false);
  const [questionArray, setQuestionArray] = React.useState(question);
  const [testSubmit, setTestSubmit] = React.useState(testBody);
  const [negativeMarkList, setNegativeMarkList] = React.useState([]);
  const [markList, setMarkList] = React.useState([]);

  const handleChange = (e, index) => {
    e.target.name === "title" && setTestSubmit({...testSubmit, title: e.target.value});
    e.target.name === "negative-mark" && setTestSubmit({
      ...testSubmit, 
      marks_per_question: {...testSubmit?.marks_per_question, negative: e.target.value}});
    e.target.name === "positive-mark" && setTestSubmit({
      ...testSubmit, 
      marks_per_question: {...testSubmit?.marks_per_question, positive: e.target.value}});
    e.target.name === "duration" && setTestSubmit({...testSubmit, duration_in_min: e.target.value});
    if(e.target.name === `instruction_${index}`){
      let array = [...testSubmit?.instructions]
      array[index] = e.target.value;
      setTestSubmit({...testSubmit, instructions: [...array]})
    }
  }

  const addNewQuestion = () => {
    let array = [...questionArray];
    array.push({
      question: '',
      isSingleSelect: true,
      options: [
        {
          optionValue: '',
          isCorrect: false
        },
        {
          optionValue: '',
          isCorrect: false
        }
      ]
    })
    setQuestionArray([...array])
  }

  const addMoreOptions = (index) => {
    let array = [...questionArray];
    array[index]?.options.push({
      optionValue: '',
      isCorrect: false
    })
    setQuestionArray([...array])
  }

  const addMoreInstructions = () => {
    let array = [...testBody?.instructions];
    array.push("");
    setTestSubmit({...testSubmit, instructions: [...array]});
  }

  const removeQuestion = (index) => {
    let array = [...questionArray];
    array.splice(index, 1);
    setQuestionArray([...array])
  }

  const removeOption = (index, optionIndex) => {
    let array = [...questionArray];
    array[index]?.options?.splice(optionIndex, 1);
    setQuestionArray([...array]);
  }

  const correctCheck = (index, optionIndex, value) => {
    let array = [...questionArray];
    if(array[index]?.isSingleSelect){
      array[index].options.map((item, ind) => {
        if(ind === optionIndex){
          item.isCorrect = true;
        }else{
          item.isCorrect = false;
        }
      });
    }else{
      array[index].options[optionIndex].isCorrect = !value;
    }
    setQuestionArray([...array]);
  }

  const radioChange = (index, value) => {
    let array = [...questionArray];
    array[index].isSingleSelect = !value;
    setQuestionArray([...array]);
  }

  React.useEffect(() => {
    const res = dispatch(getConfigDetails());
    res?.then((r) => {
      if(r?.payload?.statusCode === 200){
        r?.payload?.data?.map((item) => {
          item?.configuration_type === 'negative_mark' && setNegativeMarkList(item?.data);
          item?.configuration_type === 'mark_per_question' && setMarkList(item?.data)
        })
      }
    })
  },[])

  return (
    <>
      <HomeLayout />
      {!isQuestionAdding &&
      <div className="content-box table-box dashboard-design">
        <div className="dasboard-two-input">
          <div style={{
            width: '100%',
          }}>
            <InputLabel className="input-label-text">Title</InputLabel>
            <TextField 
              placeholder="" 
              name="title" 
              type="text" 
              variant="outlined" 
              className="inputShadow"
              // value={blogData?.title ? blogData?.title : ''}
              sx={{
                  width: '100%',
                  height: '62px'
              }} 
              onChange={handleChange} 
              required />
          </div>
          <div style={{
            width: '100%',
          }} className="duration-in">
            <InputLabel className="input-label-text">Duration(Min)</InputLabel>
            <TextField 
              placeholder="" 
              name="duration" 
              type="number" 
              variant="outlined" 
              className="inputShadow"
              // value={blogData?.title ? blogData?.title : ''}
              sx={{
                  width: '100%',
                  height: '62px'
              }} 
              onChange={handleChange} 
              required />
          </div>
        </div>
        <div>
          <InputLabel className="input-label-text">Instructions</InputLabel>
          {testSubmit?.instructions?.map((item, index) => (
          <div key={index}>
            <TextField 
              placeholder="" 
              name={`instruction_${index}`} 
              type="text" 
              variant="outlined" 
              className="inputShadow"
              // value={testSubmit?.marks_per_question?.negative ? blogData?.title : ''}
              sx={{
                  width: '100%',
                  height: '62px'
              }} 
              onChange={(e) => handleChange(e, index)} 
              required />
            </div>))}
          <div style={{
            margin: '12px 0'
          }}>
            <Button className={classes.addMore} 
              onClick={addMoreInstructions}
              sx={{
                backgroundColor: 'var(--primary-color)',
                }}>
                + add more
            </Button>
          </div>
        </div>
        <div className='marks-calculation'>
          <InputLabel className="input-label-text">Marks Calculation</InputLabel>
          <div style={{
            display: 'flex',
            margin: '12px 0'
          }}>
              <p style={{
              fontSize: '18px'
            }}>Negative marking :</p>
              <Select
                  value={testSubmit?.marks_per_question?.negative}
                  onChange={handleChange}
                  name="negative-mark"
                  MenuProps={{ PaperProps: { sx: { maxHeight: 250 } } }}
                  autoWidth
                  required
                  sx={{
                      width: '100px',
                      height: '56px',
                      marginLeft: '24px',
                      marginRight: '4px',
                      position: 'relative',
                  }}
                  >
                  {negativeMarkList.map((item, index) => 
                  <MenuItem value={item} key={index}>
                      {item}
                  </MenuItem>)}
              </Select>
              <p style={{
                fontSize: '16px'
              }}>per question</p>
          </div>
          <div style={{
            display: 'flex'
          }}>
            <p style={{
              fontSize: '18px'
            }}>Marks per Question :</p>
            <Select
                value={testSubmit?.marks_per_question?.positive}
                onChange={handleChange}
                name="positive-mark"
                MenuProps={{ PaperProps: { sx: { maxHeight: 250 } } }}
                autoWidth
                required
                sx={{
                    width: '100px',
                    height: '56px',
                    marginLeft: '8px',
                    marginRight: '4px',
                    position: 'relative',
                }}
                >
                {markList.map((item, index) => 
                <MenuItem value={item} key={index}>
                    {item}
                </MenuItem>)}
            </Select>
            <p style={{
                fontSize: '16px'
              }}>per question</p>
          </div>
        </div>
      </div>}
      {!isQuestionAdding &&
      <div className="add-question-box">
        <Button className={`${classes.addQuestion} add-question`} 
          onClick={() => setIsQuestionAdding(true)}>
          Add Questions +
        </Button>
      </div>}
      {isQuestionAdding && 
      <>
        {questionArray?.map((item, index) => (
        <div className="content-box table-box question-box-in" 
          key={index}
          style={index !== 0 ? {marginTop: '12px'} : {marginTop: '100px'} }>
            <div style={{
              width: '100%',
            }}>
              <div style={{
                display: 'flex',
                justifyContent: 'space-between'
              }}>
                <div style={{
                  display: 'flex',
                }}>
                  <img src={ThreeDotMenu} alt="threeDotIcon" />
                  <p style={{
                    fontSize: '20px',
                    fontWeight: 'bold',
                    color: '#000',
                    marginLeft: '12px'
                  }}>Question {index+1}</p>
                </div>
                <Button onClick={() => removeQuestion(index)}>
                  <CloseIcon sx={{color: 'rgb(0, 0, 0, 0.5)'}} />
                </Button>
              </div>
              <div>
                <TextField 
                  placeholder="" 
                  name="title" 
                  type="text" 
                  variant="outlined" 
                  className="inputStyleSh"
                  value={item?.question}
                  sx={{
                      width: '100%',
                      height: '62px'
                  }} 
                  // onChange={handleChange} 
                  required />
                  <div style={{
                    display: 'flex',
                    justifyContent: 'center'
                  }}>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={item?.isSingleSelect ? 'Single Selection' : 
                        'Multiple Selection' }
                      onChange={() => radioChange(index, item?.isSingleSelect)}
                    >
                      <FormControlLabel 
                        value="Single Selection" 
                        sx={{
                          color: 'rgb(0, 0, 0, 0.6)'
                        }}
                        control={<Radio />} 
                        label="Single Selection" />
                      <FormControlLabel 
                        value="Multiple Selection" 
                        sx={{
                          color: 'rgb(0, 0, 0, 0.6)'
                        }}
                        control={<Radio />} 
                        label="Multiple Selection" />
                    </RadioGroup>
                  </div>
                  {item?.options?.map((option, i) => (
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                  }} key={`${index}${i}`}>
                    <div style={{
                      display: 'flex',
                      alignItems: 'center'
                    }}>
                      <img src={TwoDotMenu} alt="twoDotIcon" />
                      <p style={{
                        fontSize: '20px',
                        fontWeight: 'bold',
                        color: '#000',
                        marginLeft: '12px'
                      }}>{String.fromCharCode(65 + i)}</p>
                    </div>
                    <div style={{
                      display: 'flex',
                      width: '100%',
                      marginLeft: '12px',
                      boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)'
                    }} className="checkbox-aria">
                      <TextField 
                        placeholder="" 
                        name="title" 
                        type="text" 
                        variant="outlined" 
                        className="inputStyleSh inputH"
                        value={option?.optionValue}
                        sx={{
                            width: '100%',
                            height: '62px'
                        }} 
                        // onChange={handleChange} 
                        required />
                        <div style={{
                          height: '41px',
                          width: '41px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          cursor: 'pointer',
                          backgroundColor: option?.isCorrect ? '#00A4A2' : '#fff'
                        }} onClick={() => correctCheck(index, i, option?.isCorrect)}>
                          <img src={option?.isCorrect ? WhiteTick : GreenTick } 
                            alt="Tick" />
                        </div>
                        <Button sx={{
                          height: '41px',
                          width: '41px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }} onClick={() => removeOption(index, i)}>
                          <CloseIcon sx={{color: 'rgb(0, 0, 0, 0.5)'}} />
                        </Button>
                    </div>
                  </div>))}
                  <div style={{
                    display: 'flex',
                    justifyContent: 'flex-end'
                  }}>
                    <Button sx={{
                      textTransform: 'capitalize',
                      color: 'rgb(0, 0, 0, 0.3)',
                      fontSize: '18px'
                    }} onClick={() => addMoreOptions(index)}>
                      + Add more options
                    </Button>
                  </div>
              </div>
            </div>
            <div className="question-image-box">
              <p style={{
                fontSize: '18px',
                fontWeight: 'bold',
                color: '#000',
                marginLeft: '12px'
              }}>Upload Image</p>
              <UploadBox
                name={`id_proof`}
                height={'190px'}
                width={'216px'}
                value={''}
                handleUpload={(name, value) => handleChange(name, value)} />
            </div>
        </div>))}
        <div className="add-question-box">
          <Button className="add-question" sx={{
            textTransform: 'capitalize',
            color: '#3C1CDE'
          }}
            onClick={addNewQuestion}>
            Add more question +
          </Button>
        </div>
        <div className="add-question-box" style={{
          marginTop: 0
        }}>
          <Button className={`${classes.addQuestion} add-question`} 
            onClick={() => setIsQuestionAdding(true)}>
            Submit Test <EastIcon sx={{marginLeft: '4px'}} />
          </Button>
        </div>
      </>
      }
    </>
  );
}