import * as React from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Avatar,
  Menu,
  MenuItem,
  ListItemIcon,
  Box,
  Toolbar,
  Button,
  List,
  IconButton,
  ListItemText,
  ListItem,
  CssBaseline,
  ListItemButton,
  Typography,
  Badge,
  TextField,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined';
import PersonIcon from '@mui/icons-material/Person';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import { LogoutOutlined } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import { makeStyles } from "@mui/styles";
import MenuIcon from "@mui/icons-material/Menu";
import CCLogo from "../../assest/svgs/cc-logo.svg";
import { logout, reset } from "../../redux/auth/authSlice";
import { toast } from "react-toastify";
import { Outlet } from "react-router-dom";
import routes from "../../react_routes_enum";
import PrepClueLogo from "../../assest/svgs/prep-clue-logo.svg";
import { 
  setAddonId,
  getCustomPages } from "../../redux/instituteStudent/instituteStudentSlice";
import { 
  getFromCart, 
  setSearchText,
  setInstituteCat } from "../../redux/students/studentSlice";
import "./layout.css";
import Footer from "../../pages/footer/Footer";
// import Spinner from "../Spinner/Spinner";
const useStyle = makeStyles({
  headerOption: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '16px',
    cursor: 'pointer',
    position: 'relative',
    "&:hover .dropdown-menu": {
      display: "block"
    }
  },
  headerOptionTitle: {
    fontSize: '18px !important',
    color: 'var(--primary-background-color)',
    fontWeight: '600 !important'
  },
  signUpButton: {
    height: '45px',
    width: '140px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: '600 !important',
    fontSize: '16px',
    color: '#fff !important',
    textTransform: 'capitalize !important',
    borderRadius: '10px',
    "&:hover": {
      backgroundColor: 'var(--primary-color)',
      color: '#fff',
    },
  },
  submenu: {
    fontSize: '18px',
    color: '#000',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
  },
})


const drawerWidth = 300;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  backgroundColor: "var(--primary-background)",
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    // marginLeft: drawerWidth,
    // width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));
export default function Layout() {
  let location = useLocation();
  const [isWebsiteFor, setIsWebsiteFor] = React.useState(localStorage.getItem("is-website-for"));
  const { route } = useSelector((state) => state.route);
  // console.log(route);
  ///Logic for dynamic text in header

  const makeFirstLetterCapital = (str) => {
    if (str.includes(" ")) {
      let a = str.split(" ");
      a = a.map((i) => makeFirstLetterCapital(i));
      return a.join(" ");
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  let arr = route.split("/");
  const nav = (arr) => {
    let str = "";
    let i = arr[0] === "" ? 1 : 0;
    for (i; i < arr.length - 1; i++) {
      if (arr[i].includes("_")) {
        str = str + makeFirstLetterCapital(arr[i].split("_").join(" ")) + " / ";
      } else {
        str = str + `${makeFirstLetterCapital(arr[i])} / `;
      }
    }

    return <span style={{ color: "grey" }}>{str}</span>;
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyle();
  const [isOpen, setIsOpen] = React.useState(false);
  const [inputText, setInputText] = React.useState("");
  const [isSearchText, setIsSearchText] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  // const [countryList, setCountryList] = React.useState([]);
  // const [serviceList, setServiceList] = React.useState([]);
  // const [programList, setProgramList] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [userData, setUserData] = React.useState(null);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const openPopup = Boolean(anchorEl);
  const {
    cartData,
    searchText
  } = useSelector((state) => state.student);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(logout());
    dispatch(reset());
    navigate('/login');
  };
  React.useEffect(() => {
    dispatch(getFromCart());
    if(location?.pathname?.split("/")[1] === "college-clue"){
      setIsWebsiteFor("student");
      localStorage.setItem("is-website-for", "student");
    }
    setUserData(JSON.parse(localStorage.getItem("cc_user")))
  },[]);
  const gotoCart = () => {
    if(userData){
      localStorage.removeItem('fromBuy');
      navigate(routes.CART);
      return;
    }
    setIsOpen(true);
    
  }

  const toggleDrawer = (anchor, open) => (event) => {

    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ 
        width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250,
       }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      {isWebsiteFor !== "student" &&
      <List>
        {['Dashboard', 'Products', 'Students'].map((text, index) => (
          <ListItem key={text} disablePadding sx={
            location?.pathname?.split("/")[1] === text.toLocaleLowerCase() ? 
            {backgroundImage: isWebsiteFor === "university" ? 
            'linear-gradient(#672F8F 40%, #BA68C8 100%, #D9D9D9 0%)' :
            'linear-gradient(#EF4840 100%, #A60800 100%, #D9D9D9 0%)'
          } : 
            {backgroundColor: 'white'}}>
            <ListItemButton>
              <ListItemIcon sx={{color: (location?.pathname?.split("/")[1] === text.toLocaleLowerCase()) ? 'white' :'rgb(0, 0, 0, 0.4)'}}>
                {/* {text === "Dashboard" && <InboxIcon />} */}
              </ListItemIcon>
              <ListItemText primary={text} 
              sx={{color: (location?.pathname?.split("/")[1] === text.toLocaleLowerCase()) ?
               'white' :
                'rgb(0, 0, 0, 0.4)'}} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>}
    </Box>
  );

  var id = null;
  const myMove = (aniType) => {
    var elem = document.getElementById("myAnimation");   
    var pos = aniType === 'in' ? -204 : 0;
    clearInterval(id);
    id = setInterval(frame, 10);
    function frame() {
      if(aniType === 'in'){
        if (pos === 0) {
          clearInterval(id);
        } else {
          pos = 0; 
          elem.style.left = pos + 'px'; 
        }
      }else{
        if (pos === -100) {
          clearInterval(id);
        } else {
          pos = -100; 
          elem.style.left = pos + '%'; 
        }
      }
    }
  }

  const searchInstitute = () => {
    if(inputText !== ""){
      dispatch(setSearchText(inputText));
      navigate(routes.COACHING_LISTING);
      return;
    }
    toast.error("Search field empty");
  }

  const gotoProfile = () => {
    navigate(routes.PROFILE)
  }

  // React.useEffect(() => {
  //   const res = dispatch(getCustomPages({page_type: "country"}));
  //   const resS = dispatch(getCustomPages({page_type: "service"}));
  //   const resP = dispatch(getCustomPages({page_type: "program"}));
  //   res?.then((r) => {
  //     r?.payload?.statusCode === 200 && setCountryList(r.payload?.data?.result);
  //   })
  //   resS?.then((r) => {
  //     r?.payload?.statusCode === 200 && setServiceList(r.payload?.data?.result)
  //   })
  //   resP?.then((r) => {
  //     r?.payload?.statusCode === 200 && setProgramList(r.payload?.data?.result)
  //   })
  // }, [])

  return (
    <>
      <Box sx={{ display: "flex" }} className="profile-box">
        <CssBaseline />
        <AppBar open={open} sx={{ 
          paddingRight: "0 !important", 
          background: '#fff',
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.15)" }}>
          <div style={{
            backgroundColor: 'var(--tertiary-color)', 
            height: '37px', width: '100%'}}>
          </div>
          <Toolbar
            sx={{
              display: "flex",
              placeContent: "space-between",
              paddingLeft: "10px !important",
            }}
          >
            <div 
              className="header-logo-aria"
              style={{ 
              alignItems: "center",
              marginLeft: '8px' }}>
              <Link to={routes.LANDING_PAGE}>
                <img src={PrepClueLogo} alt="logo" className="cclogo" />
              </Link>
            </div>
            <div 
            className="header-logo-aria"
            style={{
              alignItems: "center",
            }}>
              <div className="header-menu">
                {/* <div className={`${classes.headerOption} univ-menu`} 
                  onClick={() => {dispatch(setInstituteCat(null))}}>
                  <Link to={routes.UNIVERSITY_LISTING}
                    className={classes.headerOptionTitle}>
                    Top Universities
                  </Link>
                </div> */}
                <div className={`${classes.headerOption} coach-menu`} 
                  onClick={() => {
                    dispatch(setSearchText(null))
                    dispatch(setInstituteCat(null))
                    }}>
                  <Link  to={routes.COACHING_LISTING}
                    className={classes.headerOptionTitle}>
                      Coaching
                  </Link>
                </div>
                <div className={`${classes.headerOption} coach-menu`} 
                  onClick={() => {
                    dispatch(setSearchText(null))
                    dispatch(setInstituteCat(null))
                    }}>
                  <Link  to={routes.EXAM_ADDON}
                    className={classes.headerOptionTitle}>
                      Exams
                  </Link>
                </div>
                {/* <div className={`${classes.headerOption} coach-menu sub-men`} 
                  onClick={() => {
                    dispatch(setSearchText(null))
                    dispatch(setInstituteCat(null))
                    }}>
                    <p className={classes.submenu}>
                      Country <KeyboardArrowDownIcon />
                    </p>
                    <div className="submenu-drop">
                      <ul>
                        {countryList && countryList?.map((item, index) => (
                          <li key={index} onClick={() => dispatch(setAddonId(item?._id))}>
                            <Link to={routes.EXAM_DETAIL}>{item?.name}</Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                </div>
                <div className={`${classes.headerOption} coach-menu sub-men`} 
                  onClick={() => {
                    dispatch(setSearchText(null))
                    dispatch(setInstituteCat(null))
                    }}>
                    <p className={classes.submenu}>Services <KeyboardArrowDownIcon /></p>
                    <div className="submenu-drop">
                      <ul>
                        {serviceList && serviceList?.map((item, index) => (
                          <li key={index} onClick={() => dispatch(setAddonId(item?._id))}>
                            <Link to={routes.EXAM_DETAIL}>
                              {item?.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                </div>
                <div className={`${classes.headerOption} coach-menu sub-men`} 
                  onClick={() => {
                    dispatch(setSearchText(null))
                    dispatch(setInstituteCat(null))
                    }}>
                    <p className={classes.submenu}>Program <KeyboardArrowDownIcon /></p>
                    <div className="submenu-drop">
                      <ul>
                        {programList && programList?.map((item, index) => (
                          <li key={index} onClick={() => dispatch(setAddonId(item?._id))}>
                            <Link to={routes.EXAM_DETAIL}>{item?.name}</Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                </div> */}
                <div className={classes.headerOption}>
                  <Link  to={routes.STUDENTS_BLOG}
                    className={classes.headerOptionTitle}>
                      Blogs
                  </Link>
                </div>
                <div className={classes.headerOption}>
                  <Typography component="h2" variant="h2" className={classes.headerOptionTitle}>
                    <Link to={routes.PARTNER} style={{
                      color: '#000',
                    }}>Partner With Us</Link>
                  </Typography>
                </div>
                <div className={classes.headerOption}>
                  <Typography component="h2" variant="h2" className={classes.headerOptionTitle}>
                    <Link to={routes.CONTACT_US} style={{
                      color: '#000',
                    }}>Contact Us</Link>
                  </Typography>
                </div>
              </div>
              {userData ? 
              <div style={{position: 'relative'}}>
                <IconButton
                  color="inherit"
                  onClick={handleClick}
                  size="small"
                  sx={{ ml: 2 }}
                >
                  <Badge
                    badgeContent={
                      userData?.student?.name?.substr(0, 6)
                    }
                    color="primary"
                  >
                    {userData?.student?.profile_picture ? 
                    <img 
                      src={userData?.student?.profile_picture} 
                      width="48"
                      height="48"
                      style={{
                        borderRadius: '50%',
                        border: '1px solid grey'
                      }}/>
                    :
                    <Avatar alt="user" />}
                  </Badge>
                </IconButton>
                {isWebsiteFor === "student" &&
                <IconButton
                  color="inherit"
                  size="small"
                  sx={{ marginLeft: '20px' }}
                  onClick={() => {setIsSearchText(!isSearchText)}}
                >
                  <SearchIcon sx={{
                      color: 'rgb(0, 0, 0, 0.5)'
                    }} alt="cart" fontSize="large" />
                </IconButton>
                }
                {isWebsiteFor === "student" &&
                <IconButton
                  color="inherit"
                  onClick={gotoCart}
                  size="small"
                  sx={{ ml: 2 }}
                >
                  <Badge
                    badgeContent={cartData && cartData?.length}
                    color="primary"
                  >
                    <LocalMallIcon sx={{
                      color: 'rgb(0, 0, 0, 0.5)'
                    }} alt="cart" fontSize="large" />
                  </Badge>
                </IconButton>}
                {isSearchText &&
                <div style={{
                  position: 'absolute',
                  top: '50px',
                  background: '#fff',
                  right: 0,
                  width: '280px',
                  borderRadius: '8px'
                  }}>
                    <div>
                      <TextField 
                        placeholder="Search Coaching" 
                        onChange={(e) => {setInputText(e.target.value)}} 
                        variant="outlined" 
                        className="search-text-field"
                        sx={{
                          width: '100%',
                          height: '44px',
                          borderRadius: '16px',
                        }} />
                        <Button 
                          variant="contained" 
                          sx={{
                            position: 'absolute',
                            right: '0',
                            height: '100%',
                            borderRadius: '8px',
                            backgroundColor: 'var(--tertiary-color)',
                            '&:hover': {
                                backgroundColor: 'var(--tertiary-color)'
                            }
                          }} 
                          onClick={searchInstitute}>
                        <SearchIcon />
                      </Button>
                    </div>
                </div>}
              </div>
              : 
              <div style={{
                display: 'flex',
              }}>
                <Link 
                  to="/login" 
                  style={{background: 'var(--tertiary-color)'}} 
                  className={classes.signUpButton}>
                  LogIn / SignUp
                </Link>
                {isWebsiteFor === "student" &&
                <div className="search-icon-box">
                  <IconButton
                    color="inherit"
                    size="small"
                    sx={{ ml: 2 }}
                  >
                    <SearchIcon sx={{
                        color: 'rgb(0, 0, 0, 0.5)'
                      }} alt="SearchIcon" fontSize="large" />
                  </IconButton>
                  <div className="search-dropdown">
                    <div className="search-input-box">
                      <input 
                        value={inputText} 
                        placeholder="Search Coaching"
                        onChange={(e) => setInputText(e.target.value)}
                        className="search-input" />
                      <Button 
                        className="search-btn" 
                        onClick={searchInstitute}>
                        <SearchIcon sx={{
                          color: '#ffffff'
                        }} alt="cart" fontSize="medium" />
                      </Button>
                    </div>
                  </div>
                </div>}
                {isWebsiteFor === "student" &&
                <IconButton
                  color="inherit"
                  onClick={gotoCart}
                  size="small"
                  sx={{ ml: 2 }}
                >
                  <Badge
                    badgeContent={cartData && cartData?.length}
                    color="primary"
                  >
                    <LocalMallIcon sx={{
                      color: 'rgb(0, 0, 0, 0.5)'
                    }} alt="cart" fontSize="large" />
                  </Badge>
                </IconButton>}
              </div>
              }
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={openPopup}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <h4 style={{
                  margin: '0 0 0 16px'
                }}>My Account</h4>
                {isWebsiteFor === "student" &&
                <MenuItem onClick={
                  () => navigate(routes.PROFILE)}>
                  <ListItemIcon>
                    <PersonIcon />
                  </ListItemIcon>{" "}
                  Profile
                </MenuItem>}
                {isWebsiteFor === "student" &&
                <MenuItem onClick={
                  () => navigate(routes.STUDENT_DASHBOARD)}>
                  <ListItemIcon>
                    <WidgetsOutlinedIcon />
                  </ListItemIcon>{" "}
                  Go To Dashboard
                </MenuItem>}
                <MenuItem onClick={handleLogout}>
                  <ListItemIcon>
                    <LogoutOutlined />
                  </ListItemIcon>{" "}
                  Logout
                </MenuItem>
              </Menu>
            </div>
            <div className="mobile-menu">
              <div style={{
                position: 'fixed', 
                backgroundColor: 'white',
                width: '100%',
                zIndex: 1,
                left: 0,
                top: '37px'}}>
                {['left'].map((anchor) => (
                  <React.Fragment key={anchor}>
                    <div style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginTop: '6px'
                    }}>
                      <Button 
                      onClick={() => myMove('in')}
                      >
                        {/* {anchor} */}
                        <MenuIcon />
                      </Button>
                      <div>
                        <Link to={routes.LANDING_PAGE}>
                          <img src={PrepClueLogo}
                                alt="logo" style={{width: '86px'}} />
                        </Link>
                      </div>
                      <div></div>
                    </div>
                  </React.Fragment>
                ))}
              </div>
            </div>
            <div className="drawer-div" id ="myAnimation">
              <div style={{
                width: '50%',
                backgroundColor: '#fff',
                boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px'
              }}>
                <div style={{
                  display: "flex",
                  justifyContent: "flex-end"
                }}>
                  <Button onClick={() => myMove('out')}>
                    <CloseIcon />
                  </Button>
                </div>
                <div>
                  <div style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center"
                  }}>
                    <div style={{
                      height: '52px',
                      width: '52px',
                      borderRadius: '50%',
                      backgroundColor: 'rgb(0, 0, 0, 0.5)',
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      overflow: "hidden"
                    }} onClick={gotoProfile}>
                      {userData ? 
                      <img src={
                        userData?.university?.uploads?.university_logo ||
                        userData?.coaching?.uploads?.coaching_logo ||
                        userData?.student?.profile_picture
                        } alt="Logo" />:
                        <PersonIcon fontSize="large"/>
                      }
                    </div>
                    {userData &&
                    <p style={{
                      color: 'var(--primary-color)',
                      padding: '4px 8px',
                      textAlign: 'center',
                      fontWeight: 'bold',
                      margin: '4px 0',
                      fontSize: '14px'
                    }}>
                      {userData?.student?.name ||
                      userData?.university?.university_name ||
                      userData?.coaching?.coaching_name
                      }
                    </p>}
                  </div>
                  <ul style={{
                    marginTop: '4px'
                  }}>
                    {/* <li onClick={() => {
                      dispatch(setSearchText(null))
                      dispatch(setInstituteCat(null))
                      }}>
                      <Link to={routes.UNIVERSITY_LISTING}>Top Universities</Link>
                    </li> */}
                    <li onClick={() => {
                      dispatch(setSearchText(null))
                      dispatch(setInstituteCat(null))
                      }}>
                      <Link to={routes.COACHING_LISTING}>Coaching</Link>
                    </li>
                    <li>
                      <Link to={routes.STUDENTS_BLOG}>Blogs</Link>
                    </li>
                    <li onClick={() => {
                      dispatch(setSearchText(null))
                      dispatch(setInstituteCat(null))
                      }}>
                      <Link to={routes.EXAM_ADDON}>Exams</Link>
                    </li>
                    {/* <li className="sub-men-mob">
                      <p className={classes.submenu} style={{
                        fontSize: '16px',
                        margin: 0
                      }}>
                        Country <KeyboardArrowDownIcon />
                      </p>
                    </li> */}
                    {/* <li className="sub-men-mob">
                      <p className={classes.submenu} style={{
                        fontSize: '16px',
                        margin: 0
                      }}>
                        Services <KeyboardArrowDownIcon />
                      </p>
                      <div className="submenu-drop-mob">
                        <ul>
                          {serviceList && serviceList?.map((item, index) => (
                            <li key={index} onClick={() => dispatch(setAddonId(item?._id))}>
                              <Link to={routes.EXAM_DETAIL}>{item?.name}</Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </li> */}
                    {/* <li className="sub-men-mob">
                      <p className={classes.submenu} style={{
                        fontSize: '16px',
                        margin: 0
                      }}>
                        Program <KeyboardArrowDownIcon />
                      </p>
                      <div className="submenu-drop-mob">
                        <ul>
                          {programList && programList?.map((item, index) => (
                            <li key={index} onClick={() => dispatch(setAddonId(item?._id))}>
                              <Link to={routes.EXAM_DETAIL}>{item?.name}</Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </li> */}
                    <li>
                      <Link to={routes.PARTNER}>Partner With Us</Link>
                    </li>
                    <li>
                      <Link to={routes.CONTACT_US}>Contact Us</Link>
                    </li>
                    {(isWebsiteFor === 'student' && userData) &&
                    <li>
                      <Link to={routes.CART}>Cart</Link>
                    </li>}
                    <li>
                      {userData ?
                        <Button style={{
                          textTransform: 'capitalize',
                          fontSize: '16px',
                          fontWeight: 'bold',
                          padding: 0,
                          justifyContent: 'flex-start'
                        }} onClick={handleLogout}>
                          Logout
                        </Button>
                      :
                      <Link 
                        to="/login" 
                        style={{
                        background: 'var(--tertiary-color)',
                        width: '120px',
                        height: '34px',
                        borderRadius: '6px',
                        fontSize: '14px'}} 
                        className={classes.signUpButton}>
                        LogIn / SignUp 
                      </Link>}
                    </li>
                  </ul>
                </div>
              </div>
              <div style={{
                width: '50%',
                background: '#7e7b7b',
                opacity: '0.5'
              }} onClick={() => myMove('out')}>

              </div>
            </div>
          </Toolbar>
        </AppBar>
        <Box component="main" sx={{ flexGrow: 1, minHeight: '100vh' }}>
          {/* <DrawerHeader /> */}

          {/* This will help putting other components between the layout perfectly */}

          <Outlet />
        </Box>
      </Box>
      <Dialog maxWidth="xs" open={isOpen} onClose={() => setIsOpen(false)} fullWidth>
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '24px'
        }}>
          <ReportProblemIcon 
            sx={{
              fontSize: '40px',
              color: 'red'
            }}
          />
        </div>
        <button 
          onClick={() => setIsOpen(false)}
          variant="contained" 
          style={{
            position: 'absolute',
            top: '12px',
            right: '12px',
            background: 'none',
            color: 'black',
            fontWeight: 'bold',
            border: 'none',
            cursor: 'pointer',
            fontSize: '18px'
          }}>
          X
        </button>
        <h4 style={{
          textAlign: 'center',
          marginTop: '8px'
          }}>
          Your are not logged in, click login button
        </h4>
        <div 
          className="submit-btn-aria"
          style={{margin: '0 12px 12px 12px'}}>
          <Button 
            onClick={() => {
              localStorage.setItem("is-website-for", "student");
              navigate("/signup")
            }}
            variant="contained" 
            className="apply-now-submit"
            sx={{
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              textTransform: 'capitalize',
              fontWeight: 'bold',
            }}>
            Sign Up
          </Button>
          <Button 
            onClick={() => {
              localStorage.setItem("is-website-for", "student");
              navigate('/login');
            }} 
            variant="contained" 
            className="apply-now-submit"
            sx={{
              backgroundColor: '#07AB35',
              textTransform: 'capitalize',
              fontWeight: 'bold',
            }}
          >
            Login
          </Button>
        </div>
      </Dialog>
      <div style={{
        width: '100%',
        // height: '250px',
        backgroundColor: "var(--tertiary-color)"
      }}>
        <Footer />
      </div>
    </>
  );
}

const TopRightHeader = ({isInMobile}) => {
  const isWebsiteFor = localStorage.getItem("is-website-for");
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [userData, setUserData] = React.useState(null);

  const handleLogout = () => {
    dispatch(logout());
    dispatch(reset());
    navigate('/login')
  };
  React.useEffect(() => {
    setUserData(JSON.parse(localStorage.getItem('cc_user')))
  }, [])
  return (
      <div className="right-menu-top-header" 
      style={isInMobile ? { 
          width: '100%', 
          padding: '28px 16px 24px 0px'} : {
          padding: '28px 16px 24px 16px',
          // backgroundColor: 'red', 
          // position: 'fixed', 
          }}>
      <Typography component="h3" variant="h3" sx={{
          fontWeight: 'bold',
          color: isWebsiteFor === "university" ? 'var(--primary-color)' : 'var(--tertiary-color)',
      }} className="page-top-heading">
          {(location?.pathname?.split("/")[1])[0].toLocaleUpperCase()+
            location?.pathname?.split("/")[1].substring(1, location?.pathname?.split("/")[1].length)}
      </Typography>
      {/* {!isInMobile && <div className="search-aria">
          <TextField placeholder="Search" onChange={(e) => {
            setSearchText(e.target.value)}} 
            variant="outlined" 
          sx={{
            width: '100%',
            height: '100%',
            borderRadius: '16px',
          }} />
          <Button variant="contained" sx={{
            position: 'absolute',
            right: '0',
            height: '100%',
            borderRadius: '16px',
            backgroundColor: isWebsiteFor === "university" ? 'var(--primary-color)' : 'var(--tertiary-color)',
            '&:hover': {
                backgroundColor: isWebsiteFor === "university" ? 'var(--primary-color)' : 'var(--tertiary-color)',
            }
          }} onClick={() => {
            dispatch(setProductsFilterDetails(searchText))
            }}>
          <SearchIcon />
          </Button>
      </div>}
      {!isInMobile && <div className="user-aria">
          <div className="notification-icon" 
          style={{
              position: 'relative',
          }}>
              <NotificationsNoneIcon fontSize="large" sx={{
              color: 'rgb(0, 0, 0, 0.4)'
              }} />
              <div style={{
                backgroundColor: '#EF0000',
                height: '12px',
                width: '12px',
                borderRadius: '50%',
                position: 'absolute',
                top: '4px',
                right: '2px'
              }}>

              </div>
          </div>
          <div style={{
            width: '48px',
            height: '48px',
            borderRadius: '50%',
            backgroundColor: 'grey',
            margin: '0 12px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
            {!userData?.student ? <img src={
              userData?.university?.uploads?.university_logo ||
              userData?.coaching?.uploads?.coaching_logo
            } alt="logo" style={{
              width: '100%'
            }} /> : <PersonIcon fontSize='large' sx={{color: 'white'}} />}
          </div>
          <div style={{
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
          }} className="user-detail">
          <Typography component="p" variant="p" sx={{fontWeight: 'bold'}} className="user-name">
            {userData?.university?.university_name &&
            (userData?.university?.university_name?.length > 6 ? 
            `${userData?.university?.university_name?.substr(0, 6)}...` : 
            `${userData?.university?.university_name?.substr(0, 6)}`)}

            {userData?.coaching?.coaching_name && 
              (userData?.coaching?.coaching_name?.length > 6 ? 
              `${userData?.coaching?.coaching_name?.substr(0, 6)}...` : 
              `${userData?.coaching?.coaching_name?.substr(0, 6)}`)}

            {userData?.student?.name && 
              (userData?.student?.name?.length > 6 ? 
              `${userData?.student?.name?.substr(0, 6)}...` : 
              `${userData?.student?.name?.substr(0, 6)}`)}
          </Typography>
          <div>
              <KeyboardArrowDownIcon sx={{color: 'rgb(0, 0, 0, 0.4)'}} />
          </div>
          <div className="user-detail-options">
            <Button type="button" onClick={handleLogout}>
              Logout
            </Button>
          </div>
          </div>
      </div>}
      {isInMobile && <div className="user-aria">
          <div className="notification-icon" 
          style={{
              position: 'relative',
          }}>
              <NotificationsNoneIcon fontSize="medium" sx={{
              color: 'rgb(0, 0, 0, 0.4)'
              }} />
              <div style={{
                backgroundColor: '#EF0000',
                height: '8px',
                width: '8px',
                borderRadius: '50%',
                position: 'absolute',
                top: '4px',
                right: '2px'
              }}>
              </div>
          </div>
          <div style={{
            width: '36px',
            height: '36px',
            borderRadius: '50%',
            backgroundColor: 'grey',
            margin: '0 12px',
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }} className="user-detail">
          {isWebsiteFor !== "student" ? <img src={
            userData?.university?.uploads?.university_logo ||
            userData?.coaching?.uploads?.coaching_logo
          } alt="logo" style={{
            width: '100%',
          }} />:
          <PersonIcon />}
          <div className="user-detail-options">
              Logout
          </div>
          </div>
      </div>} */}
  </div>
  )
}
