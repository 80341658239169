import * as React from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from "react-router-dom";
import { 
  Typography,
  Button,
  IconButton,
  Select,
  MenuItem,
  FormControl ,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import HomeLayout from "../homeLayout/HomeLayout";
import routes from '../../react_routes_enum';
import StudentProfileTable from "../../components/BasicTable/BasicTable";
import Pagination from "../../components/Pagination/Pagination";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../components/Spinner/Spinner";
import { 
  getInstituteApplication,
  updateInstituteApplication, 
  setInstituteId } from '../../redux/instituteStudent/instituteStudentSlice';
import { toast } from 'react-toastify';

let acceptBody = {
  status: "target_upload_requested",
  documents: [
    {
      title: ""
    },
    {
      title: ""
    },
    {
      title: ""
    }
  ]
}

let rejectBody = {
  status: "target_rejected",
  reject_reason: ""
}

export default function PendingApplication() {
  const isWebsiteFor = localStorage.getItem("is-website-for");
  const colR = ["SNo", "Name of Student", "DOB", "Action"];

  const [limit, setLimit] = React.useState(10);
  const [offset, setOffset] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [openNotes, setOpenNotes] = React.useState(false);
  const [openReject, setOpenReject] = React.useState(false);
  const [openAccept, setOpenAccept] = React.useState(false);
  const [acceptedbody, setAcceptedBody] = React.useState(acceptBody);
  const [reject, setReject] = React.useState(rejectBody);
  const [instId, setInstId] = React.useState("");
  const navigate = useNavigate();
  
  const handleClose = () => {
    setOpen(false);
  };

  const dispatch = useDispatch();
  const { 
    isLoading, 
    isError, 
    totalCount, 
    message, 
    searchText,
    applicationData,  
  }= useSelector((state) => state.instituteStudent);

  React.useEffect(() => {
    getInstAppl();
  },[limit, offset, searchText]);

  const getInstAppl = () => {
    dispatch(getInstituteApplication({limit, offset, searchText, status: 'target_pending', isWebsiteFor }))
  }

  const handleClickOpenView = (id) => {
    dispatch(setInstituteId(id))
    navigate(routes?.REGISTER_TO_APPLY);
  };

  const handleSelectChange = (e, index) => {
    let localArray = [];
    let array = acceptedbody?.documents;
    localArray = array?.map((element, i) => {
      return i === index ? {...element, 
        ...(e.target.name === `document_${index}` && {title: e.target.value}),
      } : element
    })
    setAcceptedBody({...acceptedbody, documents: [...localArray]})
  }

  const submitDocument = () => {
    let error = true;
    let documentsArray = [];
  
    acceptedbody?.documents?.map((item) => {
      if(item?.title !== "") {
        error = false;
      }
    })
    if(error){
      toast.error("Select atleast 1 document");
      return;
    }
    documentsArray = acceptedbody?.documents?.filter((item) => {
      if(item?.title !== "") return item
    })
    let body = {
      status: acceptedbody?.status,
      documents: documentsArray
    }
    const res = dispatch(updateInstituteApplication({id:instId, body, isWebsiteFor}));
    res.then((r) => {
      if(r?.payload?.statusCode === 200){
        setOpen(false);
        setOpenNotes(false);
        getInstAppl()
        toast.success("Document Update Successfully");
      }else{
        toast.error(r?.payload?.message);
      }
    })
  }

  const rejectSubmit = () => {
    if(reject?.reject_reason === ""){
      toast.error("Rejection reason required");
      return;
    }
    const res = dispatch(updateInstituteApplication({id:instId, body: reject, isWebsiteFor}));
    res.then((r) => {
      if(r?.payload?.statusCode === 200){
        setOpen(false);
        setOpenNotes(false);
        getInstAppl()
        toast.success("Document Update Successfully")
      }else{
        toast.error("Error while updating document")
      }
    })
  }
  const addMoreDocument = () => {
    let array = [...acceptedbody?.documents];
    array.push({title: ""});
    setAcceptedBody({...acceptedbody, documents: [...array]})
  }
  if (isLoading) return <Spinner />;

  return (
    <>
      <HomeLayout /> 
      <Dialog maxWidth={openAccept ? "xs" : "sm"} open={open} onClose={handleClose} fullWidth>
          {openAccept && <div>
            <Typography component="p" variant="p" 
                sx={{
                textAlign: 'center',
                fontWeight: 'bold',
                margin: '28px 0 48px'}}>
                Are you sure you want to<br/> accept the application ?
            </Typography>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'row-reverse',
                padding: '0px 16px 28px 16px'
            }}>
                <Button onClick={() => {setOpen(false)}} variant="outlined" sx={{
                textTransform: 'capitalize',
                color: isWebsiteFor === "university" ? '#000' : 'var(--tertiary-color)',
                borderColor: isWebsiteFor === "university" ? '#000' : 'var(--tertiary-color)'
                }}>
                    Cancel
                </Button>
                <Button variant="contained" sx={{
                    backgroundColor: isWebsiteFor === "university" ? 'var(--primary-color)' : 'var(--tertiary-color)',
                    width: '84px',
                    marginRight: '12px',
                    textTransform: 'capitalize'
                    }} onClick={() => {
                      setOpenAccept(false);
                      setOpenNotes(true)
                      }}>
                    Yes
                </Button>
            </div>
          </div>}
          {openReject && 
            <>
              <DialogTitle
                sx={{color: isWebsiteFor === "university" ? 'var(--primary-color)' : 'var(--tertiary-color)',
                fontWeight:'600'}}>
                Reason <span style={{
                  fontSize:'14px', 
                  color:'rgb(0, 0, 0, 0.5)', 
                  fontWeight: '400'}}>(mention the reason why are you rejecting)</span>
              </DialogTitle>
              <DialogContent>
                <div style={{
                  position: 'relative'
                }}>
                  <TextField fullWidth 
                    placeholder="message" 
                    variant="outlined"
                    onChange={(e) => {setReject({...reject, reject_reason: e.target.value})}}
                    multiline
                    rows={7}
                    sx={{border: 'none',
                    "&:hover":{border:'none'}
                    }}
                    required/>
                  <div style={{
                    position: 'absolute',
                    bottom: 8,
                    right: 8
                  }}>
                    <Button variant="contained"
                      sx={{
                        backgroundColor: isWebsiteFor === "university" ? 'var(--primary-color)' : 'var(--tertiary-color)',
                        width: '84px',
                        marginRight: '12px',
                        textTransform: 'capitalize',
                        "&:hover": {
                          backgroundColor: 'var(--primary-color'},
                        }}
                      type="button"
                      onClick={rejectSubmit}>Submit</Button>
                  </div>
                </div>
              </DialogContent>
            </>
          }
          {openNotes && 
            <>
              <DialogTitle
                sx={{color: isWebsiteFor === "university" ? 'var(--primary-color)' : 'var(--tertiary-color)',
                fontWeight:'600'}}>
                Notes<span style={{
                  fontSize:'14px', 
                  color:'rgb(0, 0, 0, 0.5)',
                  fontWeight: '400'
                  }}>(mention the documents you want from student)</span>
              </DialogTitle>
              <DialogContent>
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}>
                  {acceptedbody?.documents?.map((item, index) => (
                  <div align="center" className="div-dropdown-document" key={index}>
                    <Button variant="outlined" style={{height:'40px'}}>Document {index+1}</Button>
                    <FormControl sx={{ m: 1 }} className="dropdown-document">
                      <Select
                        value={item?.title}
                        onChange={(e) => handleSelectChange(e, index)}
                        name={`document_${index}`}
                        inputProps={{ 'aria-label': 'Without label' }}
                        sx={{height:'40px'}}
                      >
                        <MenuItem value={'Aadhar Card'}>Aadhar Card</MenuItem>
                        <MenuItem value={'Pan Card'}>Pan Card</MenuItem>
                        <MenuItem value={'Marksheet'}>Marksheet</MenuItem>
                      </Select>
                    </FormControl>
                  </div>))}
                  <Button onClick={addMoreDocument} sx={{
                    color: 'var(--primary-color)',
                    textTransform: 'capitalize'
                  }}>
                    Add More
                  </Button>
                </div>
              </DialogContent>
              <DialogActions >
                <Button variant="contained"
                  sx={{
                    backgroundColor: isWebsiteFor === "university" ? 'var(--primary-color)' : 'var(--tertiary-color)',
                    width: '84px',
                    marginRight: '12px',
                    textTransform: 'capitalize',
                    "&:hover": {
                      backgroundColor: 'var(--primary-color)',
                    },
                  }}
                onClick={submitDocument}>Submit</Button>
              </DialogActions>
            </>
          }
      </Dialog>
      <div style={{
        marginTop: '100px'
      }} className="pagination-addPost">
        <Pagination
          totalCount={totalCount}
          limit={limit}
          page={page}
          changeLimit={setLimit}
          changeOffset={setOffset}
          changePage={setPage}
        />
      </div>
      <div className="content-box table-box" 
        style={{marginTop: '0px', overflowY: 'hidden'}}>
        <StudentProfileTable
          columns={colR}
          where="recipes"
          rows={
            applicationData && applicationData?.map((item) => {
            const res = {
            ...item,
            Action: (
              <div style={{display:'flex',columnGap:'6px'}}>
                <div>
                  <IconButton
                    color="inherit"
                    edge="start"
                    onClick={() => {
                      setInstId(item?._id)
                      setOpenAccept(false);
                      setOpenNotes(false);
                      setOpenReject(true);
                      setOpen(true);
                    }}
                    style={{display:'block'}}
                  >                                    
                  <BlockOutlinedIcon sx={{color: '#EF0000'}} className="action-icon" />
                  <Typography component="p" variant="p" 
                  sx={{
                  color: '#EF0000',
                  fontWeight: '600'
                  }} className="action-buton">
                  Reject
                  </Typography>
                  </IconButton>                           
                </div>
                <div>
                  <IconButton
                  style={{display:'block'}}
                    color="inherit"
                    edge="start"
                    onClick={() => {
                      setInstId(item?._id)
                      setOpenNotes(false);
                      setOpenAccept(true);
                      setOpenReject(false);
                      setOpen(true);
                    }}
                  >                                    
                  <CheckCircleOutlinedIcon sx={{color: '#07AB35'}} className="action-icon" />
                  <Typography component="p" variant="p" 
                  sx={{
                  color: '#07AB35',
                  fontWeight: '600'
                  }} className="action-buton">
                  Accept
              </Typography>
                  </IconButton>
                </div>
                <div>
                  <IconButton
                  style={{display:'block'}}
                    color="inherit"
                    edge="start"
                    onClick={() => handleClickOpenView(item?._id)}
                  >                                    
                  <VisibilityIcon sx={{color: '#BA68C8'}} className="action-icon" />
                  <Typography component="p" variant="p" 
                  sx={{
                  color: '#BA68C8',
                  fontWeight: '600'
                  }} className="action-buton">
                  View
                  </Typography>       
                  </IconButton>                           
                </div>
              </div>
              )
            };
          return res;
        })
      }
    />
    </div>
  </>
  );
}