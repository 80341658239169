import * as React from 'react';
import Button from '@mui/material/Button';
import { InputLabel, Input } from '@mui/material';
import TextField from '@mui/material/TextField';
import { useDispatch, useSelector } from "react-redux";
import { validateUrl } from "../../utils/validateUrl";
import { addRecordedCourse } from '../../redux/test/testSlice';
import { getCourses, updateCourse } from '../../redux/product/productSlice';
import HomeLayout from "../homeLayout/HomeLayout";
import Loader from "../../assest/images/preloader.gif";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import Upload from '../../assest/svgs/upload-icon.svg';
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import getImageUrls from "../../api/uploadImageOnS3";
import { makeStyles } from "@mui/styles";
import routes from "../../react_routes_enum";
import "./question.css";

const useStyle = makeStyles({
  addMore:{
    height: '36px',
    textTransform: 'capitalize !important',
    fontWeight: 'bold !important',
    color: '#fff !important',
    '&:hover':{
      backgroundColor: 'var(--primary-color) !important',
    }
  },
  addQuestion:{
    color: '#fff !important',
    borderRadius: '8px !important',
    fontWeight: 'bold !important',
    textTransform: 'capitalize !important',
    '&:hover':{
      backgroundColor: '#3C1CDE !important',
    }
  },
  upload:{
    fontSize: '10px !important',
    color: 'rgb(0, 0, 0, 0.4) !important',
    textTransform: 'capitalize !important',
  }
})
let courseData = {
    title: "",
    description: "",
    chapters: [
        {
            name: "",
            videos: [
                {
                    name: "",
                    description: "",
                    video_url: "",
                    video_thumbnail: ""
                }
            ]
        }
    ]
}
export default function Question() {
    const classes = useStyle();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isWebsiteFor = localStorage.getItem('is-website-for');
    const [courseBody, setCourseBody] = React.useState(courseData);
    const [btnLoader, setBtnLoader] = React.useState(false);
    const { actionType, courseById } = useSelector(state => state.product);

    const handleChange = (e, index) => {
        e.target.name === "title" && setCourseBody({...courseBody, title: e.target.value})
        e.target.name === "description" && setCourseBody({...courseBody, description: e.target.value})
        if(e.target.name === `chapter_${index}`){
            let array = [...courseBody.chapters];
            array = array.map((element, i) => {
                return i === index ? {...element,
                    ...({name: e.target.value})
                } : element
            })
            setCourseBody({...courseBody, chapters: [...array]})
        }
    }

    const addNewChapter = () => {
        let array = [...courseBody?.chapters];
        array.push(
            {
                name: '',
                videos: [
                    {
                        name: '',
                        description: '',
                        video_url: '',
                        video_thumbnail: ''
                    }
                ]
            }
        )
        setCourseBody({...courseBody, chapters: [...array]});

    }

    const addMoreRows = (index) => {
        let chapterArray = [...courseBody?.chapters];
        let videoArray = [...courseBody?.chapters[index].videos];
        
        videoArray.push({
            name: '',
            description: '',
            video_url: '',
            video_thumbnail: ''
        })
        chapterArray = chapterArray.map((element, ind) => {
            return ind === index ? {...element,
                ...({videos: [...videoArray]}),
            } : element
        });
        setCourseBody({...courseBody, chapters: [...chapterArray]});
    }

    const submitCourse = () => {
        if(validate().isValid){
            let array = [];
            let body = {};
            courseBody?.chapters.map((item) => {
                let videoArray = item?.videos?.filter((itm) => {
                    if(item.name !== "" && itm?.description !== "" && 
                        itm?.video_url !== "" && itm?.video_thumbnail !== ""){
                            return itm
                        }
                });
                videoArray?.length > 0 && array.push({name: item.name, videos: videoArray});
            })
            if(array?.length === 0){
                toast.error("Atleast 1 chapter required");
                return;
            }
            body = {
                title: courseBody?.title,
                description: courseBody?.description,
                chapters: array
            }
            setBtnLoader(true);
            const res = ( actionType === "Edit" ? 
                dispatch(updateCourse({isWebsiteFor, body, id: courseById?._id}))
                : dispatch(addRecordedCourse({isWebsiteFor, body})))
            res?.then((r) => {
                setBtnLoader(false);
                if(r?.payload?.statusCode === 200){
                    actionType === "Edit" ? toast.success("Course updated successfully") : 
                    toast.success("Course submitted successfully");
                    dispatch(getCourses({isWebsiteFor}));
                    navigate(routes.COURSE)
                }else{
                    toast.error(r?.payload?.message);
                }
            })
        }else{
            toast.error(validate().message);
        }
    }

    const videoDetailChange = (e, index, i) => {
        let chapterArray = [...courseBody?.chapters];
        let videoArray = [...courseBody?.chapters[index].videos];

        videoArray = videoArray.map((element, ind) => {
            return ind === i ? {...element,
                ...(e.target.name === `v_name_${i}` && {name: e.target.value}),
                ...(e.target.name === `v_description_${i}` && {description: e.target.value}),
                ...(e.target.name === `v_video_${i}` && {video_url: e.target.value}),
            } : element
        });

        chapterArray = chapterArray.map((element, ind) => {
            return ind === index ? {...element,
                ...({videos: videoArray})
            } : element
        })
        setCourseBody({...courseBody, chapters: [...chapterArray]});
    }

    const videoUploadChange = (value, name, index, i) => {
        let chapterArray = [...courseBody?.chapters];
        let videoArray = [...courseBody?.chapters[index].videos];

        videoArray = videoArray.map((element, ind) => {
            return ind === i ? {...element,
                ...(name === `v_video_${index}${i}` && {video_url: value}),
                ...(name === `v_thumbnail_${index}${i}` && {video_thumbnail: value}),
            } : element
        });

        chapterArray = chapterArray.map((element, ind) => {
            return ind === index ? {...element,
                ...({videos: videoArray})
            } : element
        })
        setCourseBody({...courseBody, chapters: [...chapterArray]});
    }

    const validate = () => {
        let status = {isValid: true, message: ""};
        if(courseBody?.title === ""){
            status.isValid = false;
            status.message = "Please enter title";
            return status;
        }
        if(courseBody?.description === ""){
            status.isValid = false;
            status.message = "Please enter description";
            return status;
        }
        if(courseBody?.chapters?.length > 0){
            courseBody?.chapters.map((item) => {
                item.videos?.map((itm) => {
                    if(itm?.name !== "" && itm?.description !== "" && 
                        itm?.video_url !== "" && itm?.video_thumbnail !== ""){

                        if(item.name === ""){
                            status.isValid = false;
                            status.message = "Chapter name required";
                            return status;
                        } 
                        if(!validateUrl(itm?.video_url)){
                            status.isValid = false;
                            status.message = "Invalid video url";
                            return status;
                        }  
                        return status;
                    }
                    else{
                        if(itm?.name === "" && itm?.description === "" && 
                        itm?.video_url === "" && itm?.video_thumbnail === ""){
                        }else{
                            status.isValid = false;
                            status.message = "Relevant data missing in chapter video details";
                            return status;
                        }
                        return status;
                    }
                })
            })

        }
        return status;
    }

    React.useEffect(() => {
        if(courseById && (actionType === "View" || actionType === "Edit")){
            setCourseBody({
                title: courseById?.title,
                description: courseById?.description,
                chapters: courseById?.chapters
            })
        }
    },[]);

    return (
        <>
        <HomeLayout />
            <div>
                <div className="content-box table-box dashboard-design" style={{
                    overflow: 'hidden',
                }}>
                    <div className="dasboard-two-input">
                        <div style={{
                            width: '100%',
                        }}>
                            <InputLabel className="input-label-text">
                                Title/Name Of Course
                            </InputLabel>
                            <TextField 
                                placeholder="" 
                                name="title"
                                type="text" 
                                variant="outlined" 
                                className="inputShadow-2 t-width"
                                value={courseBody?.title}
                                sx={{
                                    width: '100%',
                                    height: '62px'
                                }} 
                                onChange={handleChange} 
                                required />
                        </div>
                    </div>
                    <div>
                        <InputLabel className="input-label-text">
                            Course Description
                        </InputLabel>
                        <TextField 
                            multiline
                            placeholder="" 
                            name="description" 
                            type="text" 
                            variant="outlined" 
                            className="inputShadow-2"
                            rows={4}
                            value={courseBody?.description}
                            sx={{
                                width: '100%',
                                height: '62px'
                            }} 
                            onChange={handleChange} 
                            required />
                    </div>
                    <div style={{
                        marginTop: '84px'
                    }}>
                        <p style={{
                            textDecoration: 'underline',
                            fontSize: '20px',
                            fontWeight: 'bold',
                            textAlign: 'center'
                            }}>Course Content</p>
                    </div>
                </div>
                {courseBody?.chapters?.map((item, index) => (
                <div key={index} style={{marginBottom: '24px'}}>
                    <div className="chapter-table-box">
                        <div style={{
                            width: '100%',
                        }}>
                            <InputLabel className="input-label-text">
                                Chapter Name
                            </InputLabel>
                            <TextField 
                                placeholder="" 
                                name={`chapter_${index}`} 
                                type="text" 
                                variant="outlined" 
                                className="inputShadow-2 t-width"
                                value={item?.name}
                                sx={{
                                    width: '100%',
                                    height: '62px'
                                }} 
                                onChange={(e) => handleChange(e, index)} 
                                required />
                        </div>
                        <div className="chapter-table">
                            <div className="v-th">
                                <div className='v-name'>Name of video</div>
                                <div className='v-desc'>Description <span style={{color: 'rgb(0, 0, 0, 0.5)'}}>
                                    (one line)</span></div>
                                <div className='v-video'>Video URL / Upload Video</div>
                                <div className='v-thumb'>Upload Thumbnail</div>
                            </div>
                            {item?.videos?.map((v, i) => (
                            <div className="v-th" key={`${index}${i}`}>
                                <div className='v-name'>
                                    <input 
                                        className='v-input' 
                                        name={`v_name_${i}`}
                                        value={v?.name}
                                        onChange={(e) => videoDetailChange(e, index, i)} />
                                </div>
                                <div className='v-desc'>
                                    <input 
                                        value={v?.description}
                                        className='v-input'
                                        name={`v_description_${i}`}
                                        onChange={(e) => videoDetailChange(e, index, i)} />
                                </div>
                                <div className='v-video v-link'>
                                    <UploadB  
                                        type="video"
                                        // value={v?.video_url}
                                        name={`v_video_${index}${i}`}
                                        handleUpload={
                                            (value, name) => 
                                            videoUploadChange(value, name, index, i)
                                        } />
                                    <span style={{
                                        margin: '0 16px 0 12px',
                                        color: 'rgb(0, 0, 0, 0.5)'
                                    }}>or</span>
                                    <input 
                                        className='v-url-input'
                                        name={`v_video_${i}`}
                                        value={v?.video_url}
                                        onChange={(e) => videoDetailChange(e, index, i)} />
                                </div>
                                <div className='v-thumb'>
                                    <UploadB 
                                        value={v?.video_thumbnail}
                                        type="image"
                                        name={`v_thumbnail_${index}${i}`}
                                        handleUpload={
                                            (value, name) => 
                                            videoUploadChange(value, name, index, i)
                                        }/>
                                </div>
                            </div>))}
                        </div>
                    </div>
                    {actionType !== "View" &&
                    <div className='add-more-rows'>
                        <Button sx={{
                            color: 'rgba(0, 0, 0, 0.3)',
                            fontSize: '16px',
                            textTransform: 'capitalize'
                        }} onClick={() => addMoreRows(index)}>
                            + Add more rows
                        </Button>
                    </div>}
                </div>
                ))}
            </div>
        {actionType !== "View" &&
        <div className="add-question-box">
            <Button className="add-question" sx={{
                textTransform: 'capitalize',
                color: '#3C1CDE',
                backgroundColor: '#E9F1FE'
            }}
                onClick={addNewChapter}>
                Add more chapter +
            </Button>
        </div>}
        {actionType !== "View" &&
        <div className="add-question-box" style={{
            marginBottom: '24px'
        }}>
            <Button 
                sx={{
                    backgroundColor: btnLoader ? 'rgb(0, 0, 0, 0.2)' : '#3C1CDE'
                }}
                disabled={btnLoader}
                className={`${classes.addQuestion} add-question`} 
                onClick={submitCourse}>
            {btnLoader ? <img src={Loader} width="24" alt="Loader" /> : 
            (actionType === "Edit" ? 'Update' : 'Submit Course')}
            </Button>
        </div>}
        </>
    );
}

const UploadB = (props) => {
    const [loading, setLoading] = React.useState(false);
    const [errors, setErrors] = React.useState(false);

    const handleUploadChange = async(event) => {
        
        if(props.type === "video"){
            if(event.target.files[0]?.type !== "video/mp4"){
                toast.error("Invalid file type");
                return;
            }
            if(event.target.files[0]?.size > 10490000){
                toast.error("Video size must be less than 10MB");
                return;
            }else{
                const response = await getImageUrls({
                    image: event.target.files[0],
                    setLoading,
                    setErrors,
                });
                props.handleUpload(response?.data?.urls[0], event.target.name);
            }
            return;
        }else{
            if(event.target.files[0]?.type !== "image/png" &&
            event.target.files[0]?.type !== "image/jpeg"){
                toast.error("Invalid file type");
                return;
            }
            if(event.target.files[0]?.size > 1049000){
                toast.error("Image size must be less than 1MB");
                return;
            }else{
                const response = await getImageUrls({
                    image: event.target.files[0],
                    setLoading,
                    setErrors,
                });
                props.handleUpload(response?.data?.urls[0], event.target.name)
            }
        }
    }

    const classes = useStyle();
    return (
        <div className={classes.tableTd} style={{
            padding: 0}}>
            <label htmlFor={props.name} style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                height: '40px',
                justifyContent: 'center'
            }}>
                <img src={Upload} alt="upload" width="20" height="32"/>
                <Input
                    id={props.name}
                    name={props.name}
                    type="file"
                    accept="application/pdf"
                    sx={{display: 'none'}}
                    onChange={handleUploadChange}
                />
                <Button component="div" className={classes.upload}>
                    {loading ? 
                        <img src={Loader} width="12" alt="Loader" /> : 
                        <>
                            {(props.value && props?.value?.split('-')[3]) 
                                ? props?.value?.split('-')[3] :
                                <>
                                    <UploadFileIcon fontSize="15" />
                                    <span>{'Upload'}</span>
                                </>
                            }
                        </>
                    }
                </Button>
            </label>
        </div>
    )
}