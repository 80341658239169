import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Input,
  IconButton,
  InputAdornment,
  Typography,
  InputLabel,
  TextField,
  Select,
  MenuItem,
} from "@mui/material";
import routes from "../../react_routes_enum";
import { makeStyles } from "@mui/styles";
import ClearIcon from '@mui/icons-material/Clear';
import whyUsImage from "../../assest/svgs/why-choose-us.svg";
import BenefitPointIcon from "../../assest/images/benefit-point-icon.jpg";
import SearchIcon from "../../assest/images/search-icon.jpg";
import OtpInput from 'react-otp-input';
import { useNavigate } from "react-router-dom";
import validator from "validator";
import { MarginOutlined, Visibility, VisibilityOff } from "@mui/icons-material";
import { HashLink } from "react-router-hash-link/dist/react-router-hash-link.cjs.development";
import getImageUrls from "../../api/uploadImageOnS3";
import { 
    updateData,
    postNewFormRequest, 
    verify, 
    getPartnerByToken,
    getUniversityByToken } from "../../redux/requestForm/requestFormSlice";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import Loader from "../../assest/images/preloader.gif";
import { toast } from "react-toastify";
import { getConfigDetails } from "../../redux/configurationDetails/configDetailsSlice";
import LoaderIcon from "../../components/Loader/Loader";
import HeaderImage from "../../assest/images/hero_image.jpg";
import PartnerImage from "../../assest/images/partner-registration.png";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
const countryPhoneCode = require("../../data/country.json");

var nameValidator = /^[a-zA-Z][a-zA-Z\s]*$/

const useStyle = makeStyles({
    benefitSingleBox: {
        display: "flex",
        marginBottom: '24px',
        alignItems: "center",
    },
    howItWorkSingleBox: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginBottom: '24px',
        alignItems: "center",
    },
    buttonStyle: {
        background: 'none',
        textTransform: 'capitalize !important',
        position: 'absolute !important',
        right: 0,
        top: 8,
    },
    verifySubmit: {
        height: '36px',
        textTransform: 'capitalize !important',
        color: '#FFFFFF !important',
        width: '150px',
        '&:hover':{
            backgroundColor: '#DC0707 !important',
        },
    },
    marginBot24: {
        marginBottom: '24px'
    },
    formInputLabel: {
        color: '#000 !important',
        fontSize: '18px !important',
        marginBottom: '4px',
        fontWeight: 'bold !important'
    },
    uploadButton: {
        height: '80px',
        border: '2px dashed rgb(0,0,0,0.5) !important',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#FFFFFF !important',
        "&:hover": {
            backgroundColor: '#ffffff !important',
        }
    },
    requestButton: {
        color: '#fff !important',
        textTransform: 'capitalize !important',
        width: '180px',
        height: '44px',
        borderRadius: '24px !important',
        boxShadow: '0 2px 5px rgb(0, 0, 0, 0.5)',
        "&:hover":{
            backgroundColor: 'var(--secondary-color) !important',
        }
    }
})

let formData = {
    email: "",
    password: "",
    name: "",
    individual_or_company: "",
    experience_in_years: 1,
    partner_type: "",
    consultancy_domain: "",
    level: "",
    category_domain: "",
    number_of_colleges: "",
    proof_of_work: [],
    colleges: [
        {
            name: "",
            address: "",
            city: "",
            country: ""
        }
    ]
}

const experienceData = ["1", "2", "3", "4", "5", "6", "7", "8",]
const partnerType = [
    {
        name: "Coaching",
        value: "coaching",
    },
    {
        name: "Admission Consultancy",
        value: "admission_consultancy",
    }
]
const consDomain = [
    {
        name: "Domestic",
        value: "domestic",
    },
    {
        name: "International",
        value: "international",
    }
]
const levelData = ["UG Program Colleges", "PG Program Colleges", "Entrance Exam"]
const domainCatData = ["Medical", "Engineering", "IT", "Civil Services", "Government Exams", "Social Science", "Legal", "Creative Fields", "Commerce"]
let cityArray = [];
export default function RequestForm() {
    const [isWebsiteFor, setIsWebsiteFor] = React.useState(localStorage.getItem("is-website-for") || "");
    const [showPassword, setShowPassword] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [requestLoading, setRequestLoading] = React.useState(false);;
    const [countryList, setCountryList] = React.useState([]);
    const [cityList, setCityList] = React.useState([]);
    const [errors, setErrors] = React.useState(false);
    const classes = useStyle();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    const {
        universityByToken,
        coachingByToken,
        partnerByToken } = useSelector((state) => state.form);

    const [formInformation, setFormInformation] = React.useState(formData);

    React.useEffect(() => {
        if(localStorage.getItem("cc_token")){
            const res = dispatch(getPartnerByToken());
            res.then((r) => {
                if(r?.payload?.statusCode === 200) {
                    setFormInformation({
                        email: r?.payload?.data?.email,
                        name: r?.payload?.data?.name,
                        individual_or_company: r?.payload?.data?.individual_or_company,
                        experience_in_years: r?.payload?.data?.experience_in_years,
                        partner_type: r?.payload?.data?.partner_type,
                        consultancy_domain: r?.payload?.data?.consultancy_domain,
                        level: r?.payload?.data?.level,
                        category_domain: r?.payload?.data?.category_domain,
                        number_of_colleges: r?.payload?.data?.number_of_colleges,
                        colleges: r?.payload?.data?.colleges,
                        proof_of_work: r?.payload?.data?.proof_of_work
                    })
                }
            })
        }
    },[])

    React.useEffect(() => {
        if(localStorage.getItem("isRefresh")){
            window.location.reload();
            localStorage.removeItem("isRefresh");
        }
        const res = dispatch(getConfigDetails());
        res?.then((r) => {
            if(r?.payload?.statusCode === 200){
                let country = r?.payload?.data?.filter((item) => {
                    if(item?.configuration_type == "university_countries"){
                        return item
                    }
                })
                setCountryList(country[0]?.data);
            }
        })
    },[])

    const handleChange = (event) => {
        event.target.name === 'name' && setFormInformation({...formInformation, name: event.target.value})
        event.target.name === 'individual_or_company' && setFormInformation({...formInformation, individual_or_company: event.target.value})
        event.target.name === 'experience' && setFormInformation({...formInformation, experience_in_years: event.target.value})
        event.target.name === 'type' && setFormInformation({...formInformation, partner_type: event.target.value})
        event.target.name === 'consultancy_domain' && setFormInformation({...formInformation, consultancy_domain: event.target.value})
        event.target.name === 'level' && setFormInformation({...formInformation, level: event.target.value})
        event.target.name === 'number_of_colleges' && setFormInformation({...formInformation, number_of_colleges: event.target.value})
        event.target.name === 'category_domain' && setFormInformation({...formInformation, category_domain: event.target.value})
        event.target.name === 'email' && setFormInformation({...formInformation, email: event.target.value})
        event.target.name === 'password' && setFormInformation({...formInformation, password: event.target.value})
        if(event.target.name === "image-upload"){
            if(event.target?.files[0]?.size > 1049000){
                toast.error("Image size must be less than 1MB");
                return;
            }
            if(event.target?.files[0].type === "image/jpeg" ||
                event.target?.files[0].type === "application/pdf" ||
                event.target?.files[0].type === "image/png"){
                handleUploadFilesOnS3(event.target.files[0]);
                return;
            }
            else{
                toast.error("Only image or pdf are allowed");
            }
        }
    };

    const handleUploadFilesOnS3 = async(image) => {
        const response = await getImageUrls({
            image: image,
            setLoading,
            setErrors,
        });
        let array = [...formInformation.proof_of_work];
        array.push(response?.data?.urls[0]);
        setFormInformation({
            ...formInformation,
            proof_of_work: [...array]})
    }
    
    const requestBtnClicked = () => {
        if(validate().status){
            let body = {}
            for (const key in formInformation) {
                if (formInformation.hasOwnProperty(key) && 
                    formInformation[key] !== null && 
                    formInformation[key] !== undefined && 
                    formInformation[key] !== '') {
                        body[key] = formInformation[key];
                }
            }
            setRequestLoading(true);
            if(localStorage.getItem("cc_token")){
                let body = {}
                let collegesData = []
                collegesData = formInformation?.colleges?.filter((item) => {
                    if(item.name && item?.address && item?.city && item?.country){
                        return item;
                    }
                });
                body = {
                    email: formInformation?.email,
                    name: formInformation?.name,
                    // status: 'pending',
                    individual_or_company: formInformation?.individual_or_company,
                    experience_in_years: formInformation?.experience_in_years,
                    partner_type: formInformation?.partner_type,
                    consultancy_domain: formInformation?.consultancy_domain,
                    level: formInformation?.level,
                    category_domain: formInformation?.category_domain,
                    number_of_colleges: formInformation?.number_of_colleges,
                    colleges: collegesData,
                    proof_of_work: formInformation?.proof_of_work
                }
                let res = dispatch(updateData({isWebsiteFor, body}));
                res.then((r) => {
                    setRequestLoading(false);
                    if(r.payload.statusCode === 200){
                        // isWebsiteFor === "partner" && 
                        // dispatch(updateData({
                        //     isWebsiteFor, 
                        //     type: "edit", 
                        //     body: {email: formInformation?.email, status: 'pending'}}));
                        toast.success(r?.payload?.message);
                        navigate(routes.PARTNER_REQUEST_RESPONSE);
                    }else{
                        toast.error(r?.payload?.message);
                    }
                })
            }else{
                let collegesData = []
                collegesData = formInformation?.colleges?.filter((item) => {
                    if(item.name && item?.address && item?.city && item?.country){
                        return item;
                    }
                });
                body.isWebsiteFor = isWebsiteFor;
                body.colleges = collegesData;
                const res = dispatch(postNewFormRequest(body));
                res.then((r) => {
                    setRequestLoading(false)
                    if(r.payload.statusCode === 200){
                        toast.success(r?.payload?.message);
                        localStorage.setItem("cc_token", r?.payload?.data?.partner?.token)
                        dispatch(getPartnerByToken());
                        navigate(routes.UNIVERSITY_REQUEST_RESPONSE);
                    }else{
                        toast.error(r?.payload?.message);
                    }
                });
            }
        }else{
            toast.error(validate().message)
        }
    }

    const validate = () => {
        let error = {status: true, message: ""}

        if(formInformation.name === ""){
            error.status = false;
            error.message = "Name Required";
            return error;
        }
        if(formInformation.individual_or_company === ""){
            error.status = false;
            error.message = "Individual or Company Required";
            return error;
        }
        if(formInformation.experience_in_years === ""){
            error.status = false;
            error.message = "Experience Required";
            return error;
        }
        if(formInformation.partner_type === ""){
            error.status = false;
            error.message = "Partner Type Required";
            return error;
        }
        if(formInformation.consultancy_domain === ""){
            error.status = false;
            error.message = "Consultancy Domain Required";
            return error;
        }
        if(formInformation.level === ""){
            error.status = false;
            error.message = "Level Required";
            return error;
        }
        // if(formInformation.number_of_colleges === ""){
        //     error.status = false;
        //     error.message = "Number of Colleges Required";
        //     return error;
        // }
        // if(formInformation.colleges?.length === 0){
        //     error.status = false;
        //     error.message = "Atleast 1 College Detail Required";
        //     return error;
        // }
        // if(formInformation.colleges?.length > 0){
        //     let filterData = [];
        //     filterData = formInformation.colleges.filter((item) => {
        //         if((item.name === "" && item.address === "" && item.city === "" &&
        //             item.country === "") || (item.name !== "" && item.address !== ""
        //             && item.city !== "" && item.country !== "")){
                    
        //         }else{
        //             return item;
        //         }
        //     })
        //     if(filterData?.length > 0){
        //         error.status = false;
        //         error.message = "Revent data missing in colleges details";
        //         return error;
        //     }
        // }
        if(formInformation.email === ""){
            error.status = false;
            error.message = "Email Required";
            return error;
        }
        if(!validator.isEmail(formInformation.email)){
            error.status = false;
            error.message = "Invalid Email Entered";
            return error;
        }
        if(!partnerByToken && formInformation?.password === ""){
            error.status = false;
            error.message = "Password Required";
            return error;
        }
        if(!partnerByToken && formInformation?.password?.length < 7){
            error.status = false;
            error.message = "Password must be greater than 6 characters";
            return error;
        }
        // if(formInformation.proof_of_work.length === 0){
        //     error.status = false;
        //     error.message = "Upload atleast 1 proof of work required";
        //     return error;
        // }
        return error;
    }

    const addMoreColleges = () => {
        let array = [...formInformation.colleges];
        array.push({
            name: "",
            address: "",
            city: "",
            country: ""
        });
        setFormInformation({...formInformation, colleges: [...array]})
    }

    const handleCollegeInput = (e, index) => {
        let array = [...formInformation.colleges];
        array = array.map((element, i) => {
            return i === index ? {...element, 
                ...(e.target.name === `college-name-${index}` && {name: e.target.value}),
                ...(e.target.name === `address-${index}` && {address: e.target.value}),
                ...(e.target.name === `city-${index}` && {city: e.target.value}),
                ...(e.target.name === `country-${index}` && 
                {country: e.target.value}),
            } : element
        })
        setFormInformation({...formInformation, colleges: [...array]});

        if(e.target.name === `country-${index}`){
            let city = countryList.filter((item) => {return item.country === e.target.value})
            cityArray[index] = {key: index, data: city[0]?.city}
            setCityList(cityArray);
        }

        // if(e.target.name === `college-name-${index}`){ 
        //     array[index].name = e.target.value;
        //     setFormInformation({...formInformation, colleges: [...array]});
        // }
        // if(e.target.name === `address-${index}`){ 
        //     array[index].address = e.target.value;
        //     setFormInformation({...formInformation, colleges: [...array]});
        // }
        // if(e.target.name === `city-${index}`){ 
        //     array[index].city = e.target.value;
        //     setFormInformation({...formInformation, colleges: [...array]});
        // }
        // if(e.target.name === `country-${index}`){ 
        //     array[index].country = e.target.value;
        //     setFormInformation({...formInformation, colleges: [...array]});
        // }
        
    }

    const removeWorkProof = (index) => {
        let array = [...formInformation.proof_of_work];
        array.splice(index, 1);
        setFormInformation({...formInformation, proof_of_work: [...array]});
    }

    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);

    return (
        <Box sx={{p: 3}}>
            <section style={{
                marginTop: '120px',
                marginBottom: '4%',
            }} className="header-carousel request-form-header">
                <Carousel>
                    <div style={{position: 'relative'}} className="paddingBot">
                        <img 
                            src={HeaderImage} 
                            alt="HeroImage" 
                            style={{width: '100%'}} />
                        <div style={{
                            position: 'absolute',
                            zIndex: 1,
                            top: 0
                            }} className="carousel-card">
                            <Typography component="h2" variant="h2" 
                            className="page-heading" sx={{
                                textAlign: 'left',
                            }}>
                                Get more admissions to <br/> your Coaching
                            </Typography>
                            <Typography 
                                component="p" 
                                variant="p" 
                                className="header-small-text">
                                Find talented students from India looking for admissions, 
                                and list your university and coaching to get more 
                                potential students for free
                            </Typography>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'flex-start'
                            }}>
                                <HashLink
                                    to="/partner-request-form/#InstituteRequestForm"
                                    className={`${classes.requestButton} req-button`} 
                                    // onClick={requestBtnClicked}
                                    style={{backgroundColor: 'var(--tertiary-color)',
                                    fontWeight: 'bold',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'}}>
                                    Request Form
                                </HashLink>
                            </div>
                        </div>
                    </div>
                </Carousel>
            </section>
            <section style={{
                marginBottom: '4%'
            }} className="why-choose-us-aria">
                <Typography component="h2" variant="h2" className="page-heading">
                    Why Choose Us ?
                </Typography>
                <div className="why-us-box">
                    <div className="why-us-image-box">
                        <img 
                            src={whyUsImage} 
                            alt="whyUsImage" 
                            className="why-us-image" />
                    </div>
                    <Typography component="p" variant="p" className="why-us-text">
                        PrepClue offers guaranteed college admissions in top colleges 
                        onboarded on our portal. Being in the market from the last 15 
                        years we have built a tremendous reach and trust among the 
                        students and this is what makes us a perfect choice to join 
                        hands with any university or college in India or abroad.<br/>
                        We have an easy listing process for valid and verified colleges 
                        with more than 200 universities onboarded already. It helps 
                        students to choose from a wide range of courses and universities 
                        across the world, giving them a plethora of options to choose 
                        from. That’s why best colleges are interested to have their 
                        visibility on PrepClue.<br/>
                        With PrepClue, any college or university gets a wider 
                        audience enabling them to reach students from all over India who 
                        are looking for colleges with simple and transparent process 
                        where universities can track the journey of students individually 
                        in a personalized manner.
                    </Typography>
                </div>
            </section>
            <section>
                <Typography component="h2" variant="h2" className="page-heading benefit-heading">
                    Benefits of joining the PrepClue family
                </Typography>
                <Typography component="p" variant="p" className="benefit-text">
                    List your Coaching for free
                </Typography>
                <div style={{
                    marginTop: '5%',
                    marginBottom: '4%'
                }} className="benefit-list-box ben-list-box-need">
                    <div>
                        <div className={classes.benefitSingleBox}>
                            <div className="benefit-icon-box ben-icon-size">
                                <img src={BenefitPointIcon} alt="benefit-point-icon" />
                            </div>
                            <div>
                                <Typography component="h3" variant="h3" className="benefit-point-title" sx={{marginBottom: '6px'}}>
                                    Reach Wider Audience
                                </Typography>
                                <Typography 
                                    component="p" 
                                    variant="p" 
                                    className="benefit-point-text text-width">
                                    Promote your college/university to a wider audience 
                                    of students all over the world. We have organically 
                                    reached more than 65 lakh students in last 15 months. 
                                    Join us to convert more leads now.
                                </Typography>
                            </div>
                        </div>
                        <div className={classes.benefitSingleBox}>
                            <div className="benefit-icon-box ben-icon-size">
                                <img src={BenefitPointIcon} alt="benefit-point-icon" />
                            </div>
                            <div>
                                <Typography component="h3" variant="h3" className="benefit-point-title" sx={{marginBottom: '6px'}}>
                                    Personalized Learning Management System 
                                </Typography>
                                <Typography 
                                    component="p" 
                                    variant="p" 
                                    className="benefit-point-text text-width">
                                    Get a personalized LMS loaded with all the latest 
                                    and advanced features you need to conduct online 
                                    classes in your College/University along with 
                                    top-notch comprehensive teaching materials.
                                </Typography>
                            </div>
                        </div>
                    </div>
                    <div className="margin-need">
                        <div className={classes.benefitSingleBox}>
                            <div className="benefit-icon-box ben-icon-size">
                                <img src={BenefitPointIcon} alt="benefit-point-icon" />
                            </div>
                            <div>
                                <Typography component="h3" variant="h3" className="benefit-point-title" sx={{marginBottom: '6px'}}>
                                    Transparent Application Process
                                </Typography>
                                <Typography 
                                    component="p" 
                                    variant="p" 
                                    className="benefit-point-text text-width">
                                    We ensure an efficient, easy and transparent 
                                    application process for all stakeholders involved in 
                                    an admission process. Coachings can easily track the 
                                    progress of students’ application.
                                </Typography>
                            </div>
                        </div>
                        <div className={classes.benefitSingleBox}>
                            <div className="benefit-icon-box ben-icon-size">
                                <img src={BenefitPointIcon} alt="benefit-point-icon" />
                            </div>
                            <div>
                                <Typography component="h3" variant="h3" className="benefit-point-title" sx={{marginBottom: '6px'}}>
                                    One-stop solution to connect with your students 
                                </Typography>
                                <Typography 
                                    component="p" 
                                    variant="p" 
                                    className="benefit-point-text text-width">
                                    We enable our partner coachings to stay connected 
                                    with their students using our high-tech platform and 
                                    also provide their students with recommended study 
                                    materials and add-on courses to help students succeed.
                                </Typography>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <Typography component="h2" variant="h2" className="page-heading benefit-heading">
                    How it works
                </Typography>
                <Typography component="p" variant="p" className="benefit-text">
                    Here are the steps to list your college on PrepClue portal.<br/>
                    You can also sell your courses and materials by selecting our 
                    paid packages.
                </Typography>
                <div style={{
                    marginTop: '5%',
                    marginBottom: '4%'
                }} className="benefit-list-box">
                    <div className="how-it-work-two-box">
                        <div className={classes.howItWorkSingleBox + ' margin-r-r' }>
                            <div className="benefit-icon-box">
                                <img src={SearchIcon} alt="benefit-point-icon" />
                            </div>
                            <div>
                                <Typography component="h3" variant="h3" className="benefit-point-title" 
                                    sx={{color: 'var(--secondary-color)', margin: '16px 0 10px 0'}}>
                                    1. Fill request form
                                </Typography>
                                <Typography component="p" variant="p" className="benefit-point-text how-work-point-text">
                                    Fill out the request form below with the correct 
                                    details and official coaching email. You need to 
                                    upload a valid proof of document.
                                </Typography>
                            </div>
                        </div>
                        <div className={classes.howItWorkSingleBox}>
                            <div className="benefit-icon-box">
                                <img src={SearchIcon} alt="benefit-point-icon" />
                            </div>
                            <div>
                                <Typography component="h3" variant="h3" className="benefit-point-title" 
                                    sx={{color: 'var(--secondary-color)', margin: '16px 0 10px 0'}}>
                                    2. Get verified
                                </Typography>
                                <Typography component="p" variant="p" className="benefit-point-text how-work-point-text">
                                    You will get your login. Our team will verify your 
                                    details. The status will be available on your 
                                    coaching dashboard.
                                </Typography>
                            </div>
                        </div>
                    </div>
                    <div className="how-it-work-two-box">
                        <div className={classes.howItWorkSingleBox + ' margin-r-r'}>
                            <div className="benefit-icon-box">
                                <img src={SearchIcon} alt="benefit-point-icon" />
                            </div>
                            <div>
                                <Typography component="h3" variant="h3" className="benefit-point-title" 
                                    sx={{color: 'var(--secondary-color)', margin: '16px 0 10px 0'}}>
                                    3. Register and upload documents
                                </Typography>
                                <Typography component="p" variant="p" className="benefit-point-text how-work-point-text">
                                    Once approved, you can fill the coaching details 
                                    with valid documents as per the requirement. You can 
                                    opt-in for paid services to get more conversions 
                                    assured.
                                </Typography>
                            </div>
                        </div>
                        <div className={classes.howItWorkSingleBox}>
                            <div className="benefit-icon-box">
                                <img src={SearchIcon} alt="benefit-point-icon" />
                            </div>
                            <div>
                                <Typography component="h3" variant="h3" className="benefit-point-title" 
                                    sx={{color: 'var(--secondary-color)', margin: '16px 0 10px 0'}}>
                                    4. Enjoy the benefits
                                </Typography>
                                <Typography component="p" variant="p" className="benefit-point-text how-work-point-text">
                                    Our team will verify and approve your listing, 
                                    products and add-ons, it will be accessible to your 
                                    students. They can also make purchases to paid 
                                    material.
                                </Typography>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="InstituteRequestForm">
                <Typography component="h2" variant="h2" className="page-heading benefit-heading">
                    Partner Registration Form
                </Typography>
                <Typography component="p" variant="p" className="benefit-text">
                    We have the top reputed coaching across the globe on our portal.<br/> 
                    Thank you for showing your interest Use your official email to fill <br/>
                    the request form and start your coaching listing process.
                </Typography>
                <div style={{
                    marginTop: '5%',
                    display: 'flex',
                }}>
                    <div className="form-image">
                        <img src={PartnerImage} alt="formImage" />
                    </div>
                    <div style={{
                        backgroundColor: 'rgba(98, 85, 164, 0.11)',
                    }} className="request-form">
                        <div>
                            <form>
                                <div className="tow-input-form">
                                    <div className={`${classes.marginBot24} div-width`}>
                                        <InputLabel className={classes.formInputLabel}>
                                            Name
                                        </InputLabel>
                                        <TextField 
                                            placeholder="" 
                                            type="text" 
                                            variant="outlined" 
                                            name="name" 
                                            sx={{
                                                width: '100%',
                                                height: '62px'
                                            }} 
                                            onChange={handleChange} 
                                            value={formInformation.name} 
                                            required/>
                                    </div>
                                    <div className={`${classes.marginBot24} div-width`}>
                                        <InputLabel className={classes.formInputLabel}>
                                            Individual or Company
                                        </InputLabel>
                                        <Select
                                            name="individual_or_company"
                                            value={formInformation.individual_or_company}
                                            onChange={handleChange}
                                            placeholder="Select"
                                            autoWidth
                                            required
                                            sx={{
                                                width: '100%',
                                                height: '56px',
                                                position: 'relative',
                                            }}>
                                                <MenuItem value="individual">
                                                    Individual
                                                </MenuItem>
                                                <MenuItem value="company">
                                                    Company
                                                </MenuItem>
                                        </Select>
                                    </div>
                                </div>
                                <div className="tow-input-form">
                                    <div className={`${classes.marginBot24} div-width`}>
                                        <InputLabel className={classes.formInputLabel}>
                                            Experience{" "}
                                            <span style={{
                                                fontSize: '12px',
                                                color: 'rgb(0, 0, 0, 0.5)'
                                            }}>(In Year)</span>
                                        </InputLabel>
                                        <div>
                                            <Select
                                                name="experience"
                                                className="div-width"
                                                value={formInformation.experience_in_years}
                                                onChange={handleChange}
                                                placeholder="Select"
                                                autoWidth
                                                required
                                                sx={{
                                                    width: '100%',
                                                    height: '56px',
                                                    position: 'relative',
                                                }}
                                                >
                                                    {experienceData && experienceData?.map((item, index) => (
                                                        <MenuItem value={item} key={index}>
                                                            {item}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </div>
                                    </div>
                                    <div className={`${classes.marginBot24} div-width`}>
                                        <InputLabel className={classes.formInputLabel}>
                                            Type
                                        </InputLabel>
                                        <div className="form-two-inputs">
                                            <Select
                                                name="type"
                                                value={formInformation.partner_type}
                                                onChange={handleChange}
                                                placeholder="Select"
                                                autoWidth
                                                required
                                                sx={{
                                                    width: '100%',
                                                    height: '56px',
                                                    position: 'relative',
                                                }}>
                                                    {partnerType && partnerType?.map((item, index) => (
                                                        <MenuItem value={item.value} key={index}>
                                                            {item.name}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </div>
                                    </div>
                                </div>
                                <div className="tow-input-form">
                                    <div className={`${classes.marginBot24} div-width`}>
                                        <InputLabel className={classes.formInputLabel}>
                                            Domain of consultancy
                                        </InputLabel>
                                        <div className="form-two-inputs">
                                            <Select
                                                name="consultancy_domain"
                                                value={formInformation.consultancy_domain}
                                                onChange={handleChange}
                                                placeholder="Select"
                                                autoWidth
                                                required
                                                sx={{
                                                    width: '100%',
                                                    height: '56px',
                                                    position: 'relative',
                                                }}>
                                                    {consDomain && consDomain?.map((item, index) => (
                                                        <MenuItem value={item.value} key={index}>
                                                            {item.name}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </div>
                                    </div>
                                    <div className={`${classes.marginBot24} div-width`}>
                                        <InputLabel className={classes.formInputLabel}>
                                            Level
                                        </InputLabel>
                                        <div className="form-two-inputs">
                                            <Select
                                                name="level"
                                                value={formInformation.level}
                                                onChange={handleChange}
                                                placeholder="Select"
                                                autoWidth
                                                required
                                                sx={{
                                                    width: '100%',
                                                    height: '56px',
                                                    position: 'relative',
                                                }}
                                                >
                                                    {levelData && levelData?.map((item, index) => (
                                                        <MenuItem value={item} key={index}>
                                                            {item}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </div>
                                    </div>
                                </div>
                                <div className="tow-input-form">
                                    <div className={`${classes.marginBot24} div-width`}>
                                        <InputLabel className={classes.formInputLabel}>
                                            Category domain{" "}
                                            <span className="optional-text-style">(Optional)</span>
                                        </InputLabel>
                                        <div className="form-two-inputs">
                                            <Select
                                                name="category_domain"
                                                value={formInformation.category_domain}
                                                onChange={handleChange}
                                                placeholder="Select"
                                                autoWidth
                                                required
                                                sx={{
                                                    width: '100%',
                                                    height: '56px',
                                                    position: 'relative',
                                                }}
                                                >
                                                    {domainCatData && domainCatData?.map((item, index) => (
                                                        <MenuItem value={item} key={index}>
                                                            {item}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </div>
                                    </div>
                                    <div className={`${classes.marginBot24} div-width`}>
                                        <InputLabel className={classes.formInputLabel}>
                                            Total coachings{" "}
                                            <span className="optional-text-style">(Optional)</span>
                                        </InputLabel>
                                        <div className="form-two-inputs">
                                            <Select
                                                name="number_of_colleges"
                                                value={formInformation.number_of_colleges}
                                                onChange={handleChange}
                                                placeholder="Select"
                                                autoWidth
                                                required
                                                sx={{
                                                    width: '100%',
                                                    height: '56px',
                                                    position: 'relative',
                                                }}>
                                                    {experienceData && experienceData?.map((item, index) => (
                                                        <MenuItem value={item} key={index}>
                                                            {item}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </div>
                                    </div>
                                </div>
                                {/* <p style={{
                                    margin: '0 0 12px 0',
                                    color: 'rgb(0, 0, 0, 0.5)'
                                }}>
                                    COLLEGES
                                </p> */}
                                {/* {formInformation?.colleges?.map((item, index) => (
                                <div key={index}>
                                    <div className={classes.marginBot24}>
                                        <InputLabel className={classes.formInputLabel}>
                                            Name of College {index+1}
                                        </InputLabel>
                                        <TextField 
                                            placeholder="" 
                                            type="text" 
                                            variant="outlined" 
                                            sx={{
                                                width: '100%',
                                                height: '62px'
                                            }} 
                                            name={`college-name-${index}`} 
                                            onChange={(e) => handleCollegeInput(e, index)} 
                                            value={item.name} 
                                            required/>
                                    </div>
                                    <div className={classes.marginBot24}>
                                        <InputLabel className={classes.formInputLabel}>
                                            Full Address
                                        </InputLabel>
                                        <TextField 
                                            placeholder="" 
                                            type="text" 
                                            variant="outlined" 
                                            sx={{
                                                width: '100%',
                                                height: '62px'
                                            }}
                                            name={`address-${index}`} 
                                            onChange={(e) => handleCollegeInput(e, index)} 
                                            value={item.address} 
                                            required/>
                                    </div>
                                    <div className="tow-input-form">
                                    <div className={`${classes.marginBot24} div-width`}>
                                            <InputLabel className={classes.formInputLabel}>
                                                Country
                                            </InputLabel>
                                            <div className="form-two-inputs">
                                                <Select
                                                    // labelId="demo-simple-select-autowidth-label"
                                                    // id="demo-simple-select-autowidth"
                                                    name={`country-${index}`}
                                                    value={item.country}
                                                    onChange={(e) => handleCollegeInput(e, index)}
                                                    placeholder="Select"
                                                    autoWidth
                                                    required
                                                    sx={{
                                                        width: '100%',
                                                        height: '56px',
                                                        position: 'relative',
                                                    }}
                                                    >
                                                        {countryList && countryList?.map((item, index) => (
                                                            <MenuItem value={item?.country} key={index}>
                                                                {item?.country}
                                                            </MenuItem>
                                                        ))}
                                                </Select>
                                            </div>
                                        </div>
                                        <div className={`${classes.marginBot24} div-width`}>
                                            <InputLabel className={classes.formInputLabel}>
                                                City
                                            </InputLabel>
                                            <div className="form-two-inputs">
                                                <Select
                                                    name={`city-${index}`}
                                                    value={item.city}
                                                    onChange={(e) => handleCollegeInput(e, index)}
                                                    placeholder="Select"
                                                    autoWidth
                                                    required
                                                    sx={{
                                                        width: '100%',
                                                        height: '56px',
                                                        position: 'relative',
                                                    }}
                                                    >
                                                        {cityList && cityList?.map((item) => (
                                                            item?.key === index &&
                                                            item?.data?.map((itm, i) => (
                                                                <MenuItem value={itm} key={i}>
                                                                    {itm}
                                                                </MenuItem>
                                                            ))
                                                        ))}
                                                </Select>
                                            </div>
                                        </div>
                                    </div>
                                </div>))} */}
                                {/* <Button sx={{
                                    backgroundColor: 'var(--primary-color)',
                                    color: '#fff',
                                    marginBottom: '12px',
                                    textTransform: 'capitalize',
                                    '&:hover':{
                                        backgroundColor: 'var(--primary-color)',
                                    }
                                }} onClick={addMoreColleges}>
                                    + add more colleges
                                </Button> */}
                                {/* <div className={classes.marginBot24}>
                                    <InputLabel className={classes.formInputLabel}>
                                        Phone Number
                                    </InputLabel>
                                    <div style={{
                                        display: 'flex'
                                    }}>
                                        <div style={{
                                            width: '34%',
                                            marginRight: '12px'
                                        }}>
                                            <Select
                                                disabled={(coachingByToken?.data?.phone_country_code || 
                                                    universityByToken?.data?.phone_country_code) && true}
                                                value={formInformation?.phone_country_code}
                                                onChange={handleChange}
                                                placeholder="+91"
                                                name="phone-country-code"
                                                MenuProps={{ PaperProps: { sx: { maxHeight: 250 } } }}
                                                autoWidth
                                                required
                                                sx={{
                                                    width: '100%',
                                                    height: '56px',
                                                    position: 'relative',
                                                }}
                                                >
                                                {countryPhoneCode?.map((item, index) => (
                                                    <MenuItem value={item?.phonecode} key={index} selected={index === 1 && true}>
                                                        {item?.phonecode}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </div>
                                        <div style={{
                                            width: '100%',
                                            marginRight: '12px',
                                            position: 'relative',
                                        }}>
                                            <TextField 
                                                placeholder="" 
                                                type="number" 
                                                variant="outlined" 
                                                sx={{
                                                    width: '100%',
                                                    height: '62px'
                                                }} 
                                                disabled={(coachingByToken?.data?.phone || universityByToken?.data?.phone) && true}
                                                name="phone-number" 
                                                onChange={handleChange} 
                                                value={formInformation.phone} 
                                                required/>
                                            {!isMobileVerified ? 
                                            <Button 
                                                sx={{color: '#DC0707'}} 
                                                className={classes.buttonStyle}
                                                onClick={() => {setIsPhoneOtpField(true)}}>
                                                Verify
                                            </Button>: 
                                            <Button sx={{color: '#07AB35'}} className={classes.buttonStyle}>
                                                Verified
                                            </Button>}
                                        </div>
                                        <div style={{
                                            display: 'none',
                                            width: '100%',
                                        }}>
                                            <TextField 
                                                placeholder="" 
                                                type="number" 
                                                variant="outlined" 
                                                sx={{
                                                    width: '100%',
                                                    height: '62px'
                                                }} 
                                                name="whatsapp-number" 
                                                onChange={handleChange} 
                                                disabled={(coachingByToken?.data?.whatsapp_number || 
                                                    universityByToken?.data?.whatsapp_number) && true}
                                                value={formInformation.whatsapp_number} 
                                                required/>
                                        </div>
                                    </div>
                                    {isPhoneOtpField && 
                                    <div>
                                        <OtpAria 
                                            otp={otp} 
                                            type="Mobile"
                                            loading={mobileLoading}
                                            title="OTP send on your above mention phone number"
                                            btnTitle="Verify Phone"
                                            verifyClicked={(type) => verifyFunc(type)}
                                            handleOChange={handleOtpChange} />
                                    </div>}
                                </div> */}
                                <div className={classes.marginBot24}>
                                    <InputLabel className={classes.formInputLabel}>
                                        Email ID
                                    </InputLabel>
                                    <div style={{
                                            position: 'relative',
                                        }}>
                                        <TextField 
                                            placeholder="" 
                                            type="email" 
                                            variant="outlined" 
                                            sx={{
                                                width: '100%',
                                                height: '62px'
                                            }} 
                                            disabled={partnerByToken?.data?.email}
                                            name="email" 
                                            onChange={handleChange} 
                                            value={formInformation.email} 
                                            required/>
                                        {/* {isEmailVerified ? 
                                            <Button 
                                                sx={{color: '#07AB35'}} 
                                                className={classes.buttonStyle}>
                                                Verified
                                            </Button>: 
                                            <Button 
                                                sx={{color: '#DC0707'}} 
                                                className={classes.buttonStyle}
                                                onClick={() => {setIsEmailOtpField(true)}}>
                                                Verify
                                            </Button> } */}
                                    </div>
                                    {/* {isEmailOtpField && 
                                    <div>
                                        <OtpAria 
                                            otp={otp} 
                                            type="Email"
                                            title="OTP send on your above mention email ID"
                                            loading={emailLoading}
                                            btnTitle="Verify Email"
                                            verifyClicked={(type) => verifyFunc(type)}
                                            handleOChange={handleOtpChange} />
                                    </div>} */}
                                </div>
                                {!partnerByToken &&
                                <div className={classes.marginBot24}>
                                    <InputLabel className={classes.formInputLabel}>Password</InputLabel>
                                    <TextField
                                        id="password"
                                        name="password"
                                        disabled={(coachingByToken?.data?.password || universityByToken?.data?.password) && true}
                                        type={showPassword ? "text" : "password"}
                                        value={formInformation.password}
                                        onChange={handleChange}
                                        autoComplete="current-password"
                                        margin="normal"
                                        required
                                        InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                            </InputAdornment>
                                        ),
                                        }}
                                        fullWidth
                                        sx={{
                                            width: '100%',
                                            height: '62px'
                                        }}
                                    />
                                </div>}
                                <div className={classes.marginBot24 +' upload-addr-box'}>
                                    <div>
                                        <InputLabel className={classes.formInputLabel}>
                                            Proof of work{" "}
                                            <span className="optional-text-style">(Optional)</span>
                                        </InputLabel>
                                        <InputLabel sx={{
                                            fontSize: '12px',
                                            marginBottom: '12px',
                                            color: 'rgb(0, 0, 0, 0.6)'
                                        }}>
                                            (Coaching admission proof in last 6 months.)
                                        </InputLabel>
                                    </div>
                                    <div className="upload-addr-btn-box" 
                                        style={{position: 'relative'}}>
                                        <label htmlFor="workout-images">
                                            <Input
                                                accept="image/*"
                                                id="workout-images"
                                                name="image-upload"
                                                type="file"
                                                sx={{display: 'none'}}
                                                onChange={handleChange}
                                            />
                                            <Button component="div" className="upload-addr-btn">
                                                <FileUploadOutlinedIcon />
                                                {loading ? <img src={Loader} width="24" alt="Loader" /> : 
                                                    <span>{formInformation?.address_proof ? 
                                                            formInformation?.address_proof?.split("-")[3] : 'Upload Image'}</span>}
                                            </Button>
                                        </label>
                                    </div>
                                </div>
                                {formInformation?.proof_of_work &&
                                <div style={{
                                    marginBottom: '12px'
                                }}>
                                    {formInformation?.proof_of_work.map((item, index) => (
                                        <p key={index} style={{
                                            marginBottom: 0,
                                            marginTop: 0,
                                            fontSize: '12px',
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}>
                                            {item.split("-")[3]}
                                            <IconButton sx={{
                                                backgroundColor: 'red',
                                                marginLeft: '12px',
                                                height: '18px',
                                                width: '18px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                borderRadius: '50%',
                                                '&:hover': {
                                                    backgroundColor: 'red'
                                                }
                                            }} onClick={() => removeWorkProof(index)}>
                                                <ClearIcon 
                                                    sx={{
                                                        fontSize: '12px',
                                                        color: 'white',
                                                    }}
                                                    
                                                />
                                            </IconButton>
                                        </p>
                                    ))}
                                </div>}
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}>
                                    <Button 
                                        className={classes.requestButton} 
                                        onClick={requestBtnClicked}
                                        sx={requestLoading ? {backgroundColor: 'var(--disable-color)'} : 
                                            {backgroundColor: isWebsiteFor === "coaching" ? 
                                            'var(--tertiary-color)' : 'var(--secondary-color)'}}
                                        disabled={requestLoading}>
                                        {requestLoading ? <LoaderIcon /> : 
                                        (partnerByToken ? 'Update' : 'Send Request')}
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </Box>
    );
}

const OtpAria = (props) => {
    const classess = useStyle()
    return (
        <>
            <div style={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center'
            }}>
                <Typography sx={{
                    color: '#EF4840',
                    fontSize: '12px'
                }} component="p" variant="p">
                    {props.title}
                </Typography>
                <Button sx={{
                    textTransform: 'capitalize',
                    fontSize: '12px',
                    color: '#000',
                    background: 'none'
                }}>
                    Resend OTP
                </Button>
            </div>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '12px'
            }}>
                <OtpInput
                    value={props.otp}
                    onChange={props.handleOChange}
                    numInputs={4}
                    inputStyle={{
                        width: '80px',
                        height: '36px',
                        borderTop: 'none',
                        borderRight: 'none',
                        borderLeft: 'none',
                        "&:focusVisible":{
                            borderTop: 'none',
                            borderRight: 'none',
                            borderLeft: 'none',
                        }
                    }}
                    isInputNum={true}
                    separator={<span>&nbsp;&nbsp;&nbsp;</span>}
                />
            </div>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
            }}>
                <Button 
                    className={classess.verifySubmit} 
                    disabled={props.loading}
                    sx={props.loading ? {backgroundColor: 'var(--disable-color)'} : {backgroundColor: '#DC0707'}}
                    onClick={() => props.verifyClicked(props.type === "Mobile" ? "Mobile" : "Email")}>
                    {props.loading ? 
                        <LoaderIcon />
                     : 
                    props.btnTitle}
                </Button>
            </div>
        </>
    )
} 
