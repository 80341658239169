import * as React from 'react';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import { Box, InputLabel, Select, MenuItem } from '@mui/material';
import TextField from '@mui/material/TextField';
import HomeLayout from "../homeLayout/HomeLayout";
import LoaderIcon from "../../components/Loader/Loader";
import BudgetIcon from "../../assest/images/budget_icon.png";
import InputAdornment from '@mui/material/InputAdornment';
import PersonIcon from '@mui/icons-material/Person';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import CakeIcon from '@mui/icons-material/Cake';
import SchoolIcon from '@mui/icons-material/School';
import CurrentQual from  "../../assest/images/coaching_grey.png";
import CourseIcon from "../../assest/images/course_icon.png";
import validator from "validator";
import Moment from "moment";
import { toast } from "react-toastify";
import { makeStyles } from "@mui/styles";
import { postLeadGeneration } from "../../redux/requestForm/requestFormSlice";
import routes from '../../react_routes_enum';

const useStyle = makeStyles({
  addMore:{
    height: '36px',
    textTransform: 'capitalize !important',
    fontWeight: 'bold !important',
    color: '#fff !important',
    '&:hover':{
      backgroundColor: 'var(--primary-color) !important',
    }
  },
  addQuestion:{
    color: '#fff !important',
    borderRadius: '8px !important',
    fontWeight: 'bold !important',
    textTransform: 'capitalize !important',
    '&:hover':{
      backgroundColor: '#3C1CDE !important',
    }
  }
})

const budgetArray = [
  "Less than 500000", 
  "Between 500000-1000000", 
  "Between 1000000-2000000",
  "Above 2000000"
]

const leadData = {
  email: "",
  address: "",
  name: "",
  dob: "2011-04-14",
  phone: "",
  phone_country_code: "+91",
  current_school: "",
  current_qualification: "",
  courses: "",
  budget_in_lakhs: "",
  location: "",
  parent: {
    name: "",
    phone:"",
    phone_country_code:  "+91"
  }
}

export default function NewLead() {
  const classes = useStyle();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [btnLoader, setBtnLoader] = React.useState(false);
  const [leadBody, setLeadBody] = React.useState(leadData);

  const { leadById } = useSelector((state) => state.form);

  const handleChange = (e) => {
    e.target.name === "name" && setLeadBody({...leadBody, name: e.target.value});
    e.target.name === "address" && setLeadBody({...leadBody, address: e.target.value});
    e.target.name === "dob" && setLeadBody({...leadBody, dob: e.target.value});
    e.target.name === "email" && setLeadBody({...leadBody, email: e.target.value});
    e.target.name === "phone_country_code" && setLeadBody({...leadBody, phone_country_code: e.target.value});
    e.target.name === "phone_number" && setLeadBody({...leadBody, phone: e.target.value});
    e.target.name === "current_college" && setLeadBody({...leadBody, current_school: e.target.value});
    e.target.name === "current_qualification" && setLeadBody({...leadBody, current_qualification: e.target.value});
    e.target.name === "budget" && setLeadBody({...leadBody, budget_in_lakhs: e.target.value});
    e.target.name === "location" && setLeadBody({...leadBody, location: e.target.value});
    e.target.name === "course" && setLeadBody({...leadBody, courses: e.target.value});
    e.target.name === "p_name" && setLeadBody({
      ...leadBody, parent: {...leadBody.parent, name: e.target.value}});
    e.target.name === "p_phone_country_code" && setLeadBody({
      ...leadBody, parent: {...leadBody.parent, phone_country_code: e.target.value}});
    e.target.name === "p_phone_number" && setLeadBody({
      ...leadBody, parent: {...leadBody.parent, phone: e.target.value}});
  }

  const sendLead = () => {
    if(validate().isValid){
      let filteredObj = {}
      for (const key in leadBody) {
        if (leadBody.hasOwnProperty(key) && 
          leadBody[key] !== null && 
          leadBody[key] !== undefined && 
          leadBody[key] !== '') {
          filteredObj[key] = leadBody[key];
        }
      }
      setBtnLoader(true);
      const res = dispatch(postLeadGeneration(filteredObj));
      res?.then((r) => {
        setBtnLoader(false);
        if(r?.payload?.statusCode === 200){
          toast.success("Lead Created Successfully")
          navigate(routes.LGS)
        }else{
          toast.error(r?.payload?.message)
        }
      })

    }else{
      toast.error(validate().message);
    }
  }

  const validate = () => {
    let status = {isValid: true, message: ""}
    if(leadBody?.name === ""){
      status.isValid = false;
      status.message = "Name required";
      return status;
    }
    if(leadBody?.address === ""){
      status.isValid = false;
      status.message = "Address required";
      return status;
    }
    if(leadBody?.dob === ""){
      status.isValid = false;
      status.message = "DOB required";
      return status;
    }
    if(leadBody?.email === ""){
      status.isValid = false;
      status.message = "Email required";
      return status;
    }
    if(!validator.isEmail(leadBody?.email)){
      status.isValid = false;
      status.message = "Invalid email entered";
      return status;
    }
    if(leadBody?.phone_country_code === ""){
      status.isValid = false;
      status.message = "Phone country code required";
      return status;
    }
    if(leadBody?.phone === ""){
      status.isValid = false;
      status.message = "Phone number required";
      return status;
    }
    if(leadBody?.phone?.length !== 10){
      status.isValid = false;
      status.message = "Invalid phone number";
      return status;
    }
    if(leadBody?.current_qualification === ""){
      status.isValid = false;
      status.message = "Current qualification required";
      return status;
    }
    if(leadBody?.courses === ""){
      status.isValid = false;
      status.message = "Course required";
      return status;
    }
    if(leadBody?.budget_in_lakhs === ""){
      status.isValid = false;
      status.message = "Budget required";
      return status;
    }
    if(leadBody?.location === ""){
      status.isValid = false;
      status.message = "Location required";
      return status;
    }
    if(leadBody?.parent?.name === ""){
      status.isValid = false;
      status.message = "Parent name required";
      return status;
    }
    if(leadBody?.parent?.phone_country_code === ""){
      status.isValid = false;
      status.message = "Parent phone country code required";
      return status;
    }
    if(leadBody?.parent?.phone === ""){
      status.isValid = false;
      status.message = "Parent phone number required";
      return status;
    }
    if(leadBody?.parent?.phone?.length !== 10){
      status.isValid = false;
      status.message = "Invalid parent phone number";
      return status;
    }
    return status;
  }

  React.useEffect(() => {
    if(location.pathname === "/view-lead"){
      leadById && setLeadBody(leadById)
    }
  },[leadById]);
  
  return (
    <>
      <HomeLayout />
      <div className="content-box table-box question-box-in">
        <form className="register-form-control">
          <div className="two-input-aria">
            <div className="input-box-mobile input-box-aria">
              <InputLabel className="input-label-text">Name</InputLabel>
                <TextField 
                  placeholder="" 
                  type="text" 
                  variant="outlined" 
                  name="name"
                  value={leadBody?.name}
                  className="name-field" 
                  onChange={handleChange}
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonIcon fontSize='small' />
                      </InputAdornment>
                    ),
                  }}
                />                     
            </div>
            <div className="input-box-mobile marginLeftIn input-box-aria">
              <InputLabel className="input-label-text">Address</InputLabel>
                <TextField 
                  placeholder="" 
                  type="text" 
                  variant="outlined" 
                  name="address" 
                  className="name-field"
                  onChange={handleChange}
                  value={leadBody?.address}
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LocationOnIcon fontSize='small' />
                      </InputAdornment>
                    ),
                  }}
                  />
            </div>
          </div>
          <div className="two-input-aria">
            <div className="input-box-mobile input-box-aria">
              <InputLabel className="input-label-text">Date of Birth</InputLabel>
                <TextField 
                  placeholder="" 
                  type="date" 
                  inputProps={{
                    max: "2011-04-14"
                  }}
                  variant="outlined" 
                  name="dob"
                  className="name-field" 
                  onChange={handleChange}
                  value={Moment(leadBody?.dob).format("YYYY-MM-DD")}
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CakeIcon fontSize='small' />
                      </InputAdornment>
                    ),
                  }}
                />                     
            </div>
            <div className="input-box-mobile marginLeftIn input-box-aria">
              <InputLabel className="input-label-text">Email Id</InputLabel>
                <TextField 
                  placeholder="" 
                  type="email" 
                  variant="outlined" 
                  name="email" 
                  onChange={handleChange}
                  className="name-field"
                  value={leadBody?.email}
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailIcon fontSize='small' />
                      </InputAdornment>
                    ),
                  }}
                />
            </div>
          </div>
          <div className="two-input-aria">
            <div className="input-box-mobile input-box-aria">
              <InputLabel className="input-label-text">Phone Number</InputLabel>
              <div style={{display: 'flex'}}>
                <TextField 
                  className="country-code" 
                  value={leadBody?.phone_country_code}
                  name="phone_country_code"
                  onChange={handleChange}
                  sx={{
                    color: 'rgb(0, 0, 0)',
                    marginRight: '6px'}}>
                </TextField>
                <TextField 
                  placeholder="" 
                  type="number" 
                  variant="outlined" 
                  name="phone_number"
                  onChange={handleChange}
                  className="name-field phoneMargin" 
                  value={leadBody?.phone}
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PhoneIcon fontSize='small' />
                      </InputAdornment>
                    ),
                  }}
                />   
              </div>                  
            </div>
            <div className="input-box-mobile marginLeftIn input-box-aria">
              <InputLabel className="input-label-text">Currently School/College
              <span style={{color:'#00000080', fontSize: '12px'}}>(Optional)</span></InputLabel>
                <TextField 
                  placeholder="" 
                  type="text" 
                  variant="outlined" 
                  name="current_college" 
                  className="name-field"
                  onChange={handleChange}
                  value={leadBody?.current_school}
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SchoolIcon fontSize='small' />
                      </InputAdornment>
                    ),
                  }}
                />
            </div>
          </div>
          <div className="two-input-aria">
            <div className="input-box-mobile input-box-aria">
              <InputLabel className="input-label-text">Current Qualification</InputLabel>
                <TextField 
                  placeholder="" 
                  type="text" 
                  variant="outlined" 
                  name="current_qualification"
                  className="name-field" 
                  value={leadBody?.current_qualification}
                  onChange={handleChange}
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={CurrentQual} alt="" width="18" />
                      </InputAdornment>
                    ),
                  }}
                />                     
            </div>
            <div className="input-box-mobile marginLeftIn input-box-aria">
              <InputLabel className="input-label-text">Courses</InputLabel>
                <TextField 
                  placeholder="" 
                  type="text" 
                  variant="outlined" 
                  name="course" 
                  value={leadBody?.courses}
                  className="name-field"
                  onChange={handleChange}
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={CourseIcon} alt="" width="18" />
                      </InputAdornment>
                    ),
                  }}
                />
            </div>
          </div>
          <div className="two-input-aria">
            <div className="input-box-mobile input-box-aria">
              <InputLabel className="input-label-text">
                Budget{" "}
                <span style={{color:'#00000080', fontSize: '12px'}}>(in lakh)</span>
              </InputLabel>
              <Select
                className="name-field"
                name="budget"
                defaultValue=""
                displayEmpty
                value={leadBody?.budget_in_lakhs}
                onChange={handleChange}
                renderValue={(value) => {
                  return (
                    <Box sx={{ 
                      display: "flex", 
                      gap: 1, 
                      alignItems: 'center'}}>
                      <img src={BudgetIcon} alt="" width="16"
                        style={value ? {marginTop: 0} : {marginTop: '8px'}}/>
                      {value}
                    </Box>
                  );
                }}
              >
                {budgetArray.map((item, index) => (
                <MenuItem value={item} key={index}>
                  {item}
                </MenuItem>))}
              </Select>
            </div>
            <div className="input-box-mobile marginLeftInP input-box-aria">
              <InputLabel className="input-label-text">Location</InputLabel>
                <TextField 
                  placeholder="" 
                  type="text" 
                  variant="outlined" 
                  name="location" 
                  value={leadBody?.location}
                  className="name-field"
                  onChange={handleChange}
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LocationOnIcon fontSize='small' />
                      </InputAdornment>
                    ),
                  }}
                />
            </div>
          </div>
          <div className="two-input-aria">
            <div className="input-box-mobile input-box-aria">
              <InputLabel className="input-label-text">Parent's Name</InputLabel>
                <TextField 
                  placeholder="" 
                  type="text" 
                  variant="outlined" 
                  name="p_name" 
                  value={leadBody?.parent?.name}
                  className="name-field"
                  onChange={handleChange}
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonIcon fontSize='small' />
                      </InputAdornment>
                    ),
                  }}
                />
            </div>
            <div className={`input-box-mobile input-box-aria marginLeftInP`}>
              <InputLabel className="input-label-text">Parent's Contact Number</InputLabel>
              <div style={{display: 'flex'}}>
                <TextField 
                  className="country-code" 
                  value={leadBody?.parent?.phone_country_code} 
                  name="p_phone_country_code"
                  onChange={handleChange}
                  sx={{
                    color: 'rgb(0, 0, 0)',
                    marginRight: '6px'}} />
                <TextField 
                  placeholder="" 
                  type="number" 
                  variant="outlined" 
                  name="p_phone_number"
                  value={leadBody?.parent?.phone}
                  className="name-field phoneMargin" 
                  onChange={handleChange}
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PhoneIcon fontSize='small' />
                      </InputAdornment>
                    ),
                  }}
                />   
              </div>                  
            </div>
          </div>
          <div className="submit-btn-aria">
            <p>
              
            </p>
            {location.pathname !== "/view-lead" &&
            <Button 
              onClick={sendLead} 
              variant="contained" 
              className="apply-now-submit"
              sx={{
                backgroundColor: '#F69220',
                textTransform: 'capitalize',
                fontWeight: 'bold',
              }}
              disabled={btnLoader}
            >
              {btnLoader ? <LoaderIcon /> : 'Submit'}
            </Button>}
          </div>
        </form>
      </div>
    </>
  );
}