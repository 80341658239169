import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Typography
} from "@mui/material";
import routes from "../../react_routes_enum";
import { makeStyles } from "@mui/styles";
import HeaderBackground from "../../assest/images/header-background.jpg";
import HeaderIcon from "../../assest/images/header-icon.png";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useNavigate } from "react-router-dom";
import { updateData, 
    getPartnerByToken,
    getUniversityByToken, 
    getCoachingByToken } from "../../redux/requestForm/requestFormSlice";
import LoaderIcon from "../../components/Loader/Loader";
import Response2Image from "../../assest/images/response_2.jpg";
import SuccessImage from "../../assest/images/success_image.png";
import { useState } from "react";

const useStyle = makeStyles({
    dot: {
        height: '16px',
        width: '16px',
        borderRadius: '50%',
    },
    line: {
        height: '4px',
        backgroundColor: 'rgb(246, 146, 32, 0.4)'
    },
    changesButton: {
        color: '#fff !important',
        textTransform: 'capitalize !important',
        fontSize: '16px !important',
        borderRadius: '24px !important',
        boxShadow: '0 2px 5px rgb(0, 0, 0, 0.5)',
        "&:hover":{
            backgroundColor: 'var(--secondary-color) !important',
        }
    },
    cancelButton: {
        backgroundColor: '#fff !important',
        color: 'var(--secondary-color) !important',
        textTransform: 'capitalize !important',
        width: '140px',
        height: '40px',
        fontSize: '16px !important',
        borderRadius: '24px !important',
        borderColor: 'var(--secondary-color) !important',
        "&:hover":{
            backgroundColor: '#fff',
        }
    },
    header: {
        marginTop: '100px',
        marginBottom: '4%',
        backgroundImage: `url(${HeaderBackground})`,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    formIconsBox: {
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        marginTop: '5%',
        marginRight: '14%',
    },
    iconBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    horizontalLine: {
        height: '4px',
        backgroundColor: 'var(--primary-color)',
    },
    iconBox2: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'absolute', 
    },
    iconStyle: {
        backgroundColor: 'var(--primary-color)',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    iconText: {
        color: 'var(--primary-color)',
        marginTop: '8px !important',
    },
    icon: {
        color: '#fff',
    },
    leftBox: {
        height: '200px',
        width: '200px',
        borderRadius: '8px',
        border: '2px dashed rgb(0, 0, 0, 0.5)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    upload: {
        border: '1px solid rgb(0, 0, 0, 0.2) !important',
        fontSize: '15px !important',
        color: 'rgb(0, 0, 0, 0.4) !important',
        textTransform: 'capitalize !important',
        width: '122px',
        height: '34px'
    },
    checkBox: {
        color: 'var(--primary-color)',
        '&.Mui-checked': {
            color: 'var(--primary-color)',
        }
    },
    tableNew: {
        border: '1px solid rgb(0, 0, 0, 0.2)',
        borderRadius: '8px',
        borderCollapse: 'collapse',
        width: '100%',
        marginLeft: '0.8%' 
    },
    tableTh: {
        borderBottom: '1px solid rgb(0, 0, 0, 0.2)',
        display: 'flex',
        justifyContent: 'space-between',
        fontWeight: 'bold',
    },
    tableTd: {
        borderRight: '1px solid rgb(0, 0, 0, 0.2)',
        width: '14.5%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '12px',
    }
})

export default function RequestRes() {
    const isWebsiteFor = localStorage.getItem("is-website-for");
    const [loader, setLoader] = useState(false);
    const {
        universityByToken,
        coachingByToken } = useSelector((state) => state.form);
    const navigate = useNavigate();
    const classes = useStyle();
    const dispatch = useDispatch();

    React.useEffect(() => {
        isWebsiteFor === "coaching" && dispatch(getCoachingByToken());
        isWebsiteFor === "university" && dispatch(getUniversityByToken());
        isWebsiteFor === "partner" && dispatch(getPartnerByToken())
        statusCheck();
    },[])
    const statusCheck = () => {
        let interval = setInterval(function() {
            isWebsiteFor === "coaching" && dispatch(getCoachingByToken());
            isWebsiteFor === "university" && dispatch(getUniversityByToken());
            isWebsiteFor === "partner" && dispatch(getPartnerByToken())
        },1000)
    }
    const continueClicked = () => {
        setLoader(true)
        let res = dispatch(updateData({isWebsiteFor, body: {welcome_screen_check: 1}}));
        res.then((r) =>{
            localStorage.setItem("isRefresh", true);
            r?.payload.statusCode === 200 && navigate(routes.PRODUCTS);
        })
    }
    const requestChange = () => {
        localStorage.setItem("isRefresh", true);
        isWebsiteFor === "coaching" ? navigate(routes.COACHING_INFORMATION) : navigate(routes.UNIVERSITY_INFORMATION);
    }
    return (
        <Box>
            <section className={classes.header + ' header-set'}>
                <div className="header-icon-box">
                    <img src={HeaderIcon} alt="header-icon" style={{width: '100%'}} />
                </div>
                <Typography component="h2" variant="h2" className="header-heading">
                    {isWebsiteFor === "university" && 'UNIVERSITY - LISTING'}
                    {isWebsiteFor === "coaching" && 'COACHING - LISTING'}
                    {isWebsiteFor === "partner" && "PARTNER'S ONBOARDING"}
                </Typography>
                <Typography component="p" variant="p" className="small-header-heading">
                {isWebsiteFor === "university" && 'University listing is in progress'}
                    {isWebsiteFor === "coaching" && 'Coaching listing is in progress'}
                </Typography>
            </section>
            <Box sx={{p: 3}}>
                <section>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginBottom: '8px'
                        }} className="progr-lin-box">
                            <div className={classes.dot} style={{
                                background: 'none',
                                }}>
                                <CheckCircleOutlineIcon sx={{width: '100%', color: '#07AB35'}} />
                            </div>
                            <div className={classes.line + ' progress-line'} style={{background: 'none'}}>

                            </div>
                            <div className={classes.dot} style={{
                                background: 'none',
                                visibility: 'visible'
                                }}>
                                <CheckCircleOutlineIcon sx={{
                                    width: '100%', 
                                    color: '#07AB35',
                                    }} />
                            </div>
                            <div className={classes.line + ' progress-line'} style={{background: 'none'}}>

                            </div>
                            <div className={classes.dot} style={{
                                background: 'none',
                                visibility: ((universityByToken?.data?.information_check && universityByToken?.data?.uploads_check &&
                                    universityByToken?.data?.courses_offered_check && universityByToken?.data?.placement_records_check
                                    && universityByToken?.data?.request_status === "accepted") ||
                                    (coachingByToken?.data?.information_check && 
                                        coachingByToken?.data?.uploads_check &&
                                        coachingByToken?.data?.faculties_details && 
                                        coachingByToken?.data?.request_status === "accepted")) ? 
                                    'visible' : 'hidden'
                                }}> 
                                <CheckCircleOutlineIcon sx={{width: '100%', color: '#07AB35'}} />
                            </div>
                        </div>
                    </div>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }} className="progr-lin-box">
                            <div className={classes.dot} style={{backgroundColor: 'var(--secondary-color)'}}>
                                
                            </div>
                            <div className={classes.line + ' progress-line'} style={{backgroundColor: 'var(--secondary-color)'}}>

                            </div>
                            <div className={classes.dot} style={{backgroundColor: 'var(--secondary-color)'}}>

                            </div>
                            <div className={classes.line + ' progress-line'} style={{
                                backgroundColor: ((universityByToken?.data?.information_check && 
                                    universityByToken?.data?.uploads_check &&
                                    universityByToken?.data?.courses_offered_check && 
                                    universityByToken?.data?.placement_records_check &&
                                    universityByToken?.data?.request_status === "accepted") ||
                                    (coachingByToken?.data?.information_check && 
                                        coachingByToken?.data?.uploads_check &&
                                        coachingByToken?.data?.faculties_details && 
                                        coachingByToken?.data?.request_status === "accepted")) ? 
                                    'var(--secondary-color)' :
                                    'rgb(246, 146, 32, 0.4)'
                            }}>

                            </div>
                            <div className={classes.dot} style={{backgroundColor: ((
                                    universityByToken?.data?.information_check && 
                                    universityByToken?.data?.uploads_check &&
                                    universityByToken?.data?.courses_offered_check && 
                                    universityByToken?.data?.placement_records_check &&
                                    universityByToken?.data?.request_status === "accepted") ||
                                    (coachingByToken?.data?.information_check && 
                                    coachingByToken?.data?.uploads_check &&
                                    coachingByToken?.data?.faculties_details && 
                                    coachingByToken?.data?.request_status === "accepted")) ? 
                                    'var(--secondary-color)' :
                                    'rgb(246, 146, 32, 0.4)'}}>

                            </div>
                        </div>
                    </div>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '12px'
                    }}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            justifyContent: 'space-between',
                        }} className="progr-text-box">
                            <Typography component="h2" variant="h2" className="progr-text">
                                Fill the Request Form
                            </Typography>
                            <Typography component="h2" variant="h2" className="progr-text">
                                Fill the Listing Registration Form
                            </Typography>
                            <Typography component="h2" variant="h2" className="progr-text">
                                LIVE Listing
                            </Typography>
                        </div>
                    </div>
                </section>
                {(universityByToken?.data?.request_status === "registration" || 
                    universityByToken?.data?.request_status === "review" || 
                    coachingByToken?.data?.request_status === "registration" || 
                    coachingByToken?.data?.request_status === "review") &&
                    <>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: '48px',
                        }}>
                            <div style={{
                                width: '120px'
                            }}>
                                <img src={Response2Image} alt="univ-response-icon" style={{width: '100%'}} />
                            </div>
                        </div>
                        {((universityByToken?.data?.information_check && universityByToken?.data?.uploads_check && 
                                universityByToken?.data?.courses_offered_check && universityByToken?.data?.placement_records_check) ||
                                (coachingByToken?.data?.information_check && coachingByToken?.data?.uploads_check && 
                                coachingByToken?.data?.faculties_details_check)) ?
                            <Typography variant="h3" component="h3" className="request-res-main-text">
                                Your Review Pending
                            </Typography> :
                            <>
                                <Typography variant="h3" component="h3" className="request-res-main-text">
                                    Your Request form required <br/>some changes
                                </Typography> 
                                <div style={{
                                    marginTop: '48px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}>
                                    <div style={{
                                        width: '400px'
                                    }}>
                                        <Typography variant="p" component="p" sx={{
                                            fontSize: '18px',
                                        }}>
                                            CHANGES:
                                        </Typography>
                                        <ul style={{
                                            listStyleType: 'numeric',
                                            paddingLeft: '16px',
                                        }}>
                                            {isWebsiteFor === "university" && 
                                            universityByToken?.data?.requested_changes.map((item, index) => (
                                                <li key={index}>
                                                    {item}
                                                </li>
                                            ))}
                                            {isWebsiteFor === "coaching" && 
                                            coachingByToken?.data?.requested_changes.map((item, index) => (
                                                <li key={index}>
                                                    {item}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        marginTop: '48px',
                                        marginBottom: '52px'
                                    }}>
                                        <Button className={classes.changesButton} sx={{
                                            backgroundColor: isWebsiteFor === "coaching" ? 'var(--tertiary-color)' : 
                                            'var(--secondary-color)',
                                            width: '140px'
                                        }} onClick={requestChange}>
                                            Edit
                                        </Button>
                                    </div>
                                </div>
                            </>
                            
                        }
                    </>
                }
                {(universityByToken?.data?.request_status === "accepted" || 
                    coachingByToken?.data?.request_status === "accepted") &&
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <div>
                            <img src={SuccessImage} alt="successImage"className="succes-img" />
                        </div>
                        <Typography variant="h3" component="h3" className="request-res-main-text">
                            Success
                        </Typography>
                        <Typography variant="h3" component="h3" sx={{
                            color: isWebsiteFor === "coaching" ? 'var(--tertiary-color)' : 'var(--secondary-color)'
                        }} className="request-res-main-text">
                            Your Listing Is LIVE
                        </Typography>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: '48px',
                            marginBottom: '52px'
                        }}>
                            <Button className={classes.changesButton} sx={{
                                backgroundColor: loader ? 'var(--disable-color)' : (isWebsiteFor === "coaching" ? 'var(--tertiary-color)' : 
                                'var(--secondary-color)'),
                                width: '180px',
                                height: '40px',
                            }} 
                            disabled={loader}
                            onClick={continueClicked}>
                                {loader ? <LoaderIcon/> :"Go to Dashboard"}
                            </Button>
                        </div>
                    </div>
                }
            </Box>
        </Box>
    );
}
