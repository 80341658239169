import axios from "axios";
import endpoint from "../../index.routes";

const getUniversityByToken = async() => {
    const response = await axios.get(endpoint.UNIVERISTY_BY_TOKEN, {
        headers: { token: localStorage.getItem("cc_token") },
    });
    return response.data;
}

const getCoachingByToken = async() => {
    const response = await axios.get(endpoint.COACHING_BY_TOKEN, {
        headers: { token: localStorage.getItem("cc_token") },
    });
    return response.data;
}

const getPartnerByToken = async() => {
    const response = await axios.get(endpoint.PARTNER_BY_TOKEN, {
        headers: { token: localStorage.getItem("cc_token") },
    });
    return response.data;
}

const getStudentByToken = async() => {
    const response = await axios.get(endpoint.STUDENT_BY_TOKEN, {
        headers: { token: localStorage.getItem("cc_token") },
    });
    localStorage.setItem("cc_user", JSON.stringify({
        token: response.data.data.token, 
        student: response.data.data}));
    return response.data;
}

const verify = async (params) => {
    const { data } = params;
    const response = await axios.post(endpoint.VEIRY, params, { 
        headers: { token: localStorage.getItem("cc_token") },
    });
    return response.data;
};

const postSignUpRequest = async (params) => {
    let body = {};
    let path = "";
    if(params?.isWebsiteFor === "coaching"){
        path = endpoint.COACHING_SIGNUP_REQUEST;
        body = {
            email: params.email,
            password: params.password,
            owner_name: params.university_name,
            coaching_name: params.coaching_name,
            address: params.address,
            phone: params.phone,
            whatsapp_number: params.whatsapp_number,
            address_proof: params.address_proof,
            phone_country_code: params.phone_country_code,
            verification_id_email: params.verification_id_email,
        }
    }
    else if(params?.isWebsiteFor === "partner"){
        path = endpoint.PARTNER_REQUEST;
        body = {
            email: params.email,
            password: params.password,
            name: params.name,
            individual_or_company: params.individual_or_company,
            experience_in_years: params.experience_in_years,
            partner_type: params.partner_type,
            consultancy_domain: params.consultancy_domain,
            level: params.level,
            category_domain: params.category_domain,
            number_of_colleges: params.number_of_colleges,
            colleges: params.colleges,
            proof_of_work: params.proof_of_work
        }
    }
    else{
        path = endpoint.UNIVERSITY_SIGNUP_REQUEST;
        body = {
            email: params.email,
            password: params.password,
            name: params.name,
            university_name: params.university_name,
            designation: params.designation,
            address: params.address,
            phone: params.phone,
            whatsapp_number: params.whatsapp_number,
            address_proof: params.address_proof,
            phone_country_code: params.phone_country_code,
            verification_id_email: params.verification_id_email,
        }
    }
    const response = await axios.post(path, body);
    return response.data;
};

const studentSignup = async (params) => {
    const response = await axios.post(endpoint.STUDENT_SIGNUP, params);
    return response.data;
};

const sendOTP = async (params) => {
    const response = await axios.post(endpoint.SEND_OTP_EMAIL, params);
    return response.data;
};

const postLeadGeneration = async (params) => {
    const response = await axios.post(endpoint.PARTNER_LEAD, params, {
        headers: { token: localStorage.getItem("cc_token") },
    });
    return response.data;
};

const getLeadGeneration = async (params) => {
    let {limit, offset, searchText} = params
    const response = await axios.get(endpoint.PARTNER_LEAD, {
        params: { limit, offset, searchText },
        headers: { token: localStorage.getItem("cc_token") },
    });
    return response.data;
};

const getLeadById = async (params) => {
    const response = await axios.get(endpoint.PARTNER_LEAD + "/" + params.id, {
        headers: { token: localStorage.getItem("cc_token") },
    });
    return response.data;
};

const updateData = async (params) => {
    let path = ""
    params?.isWebsiteFor === "coaching" && (path = endpoint.UPDATE_COACHING_DATA)
    params?.isWebsiteFor === "university" && (path = endpoint.UPDATE_UNIVERSITY_DATA)
    params?.isWebsiteFor === "partner" && (path = (params?.body?.email || params?.type === "edit") ? endpoint.UPDATE_PARTNER : endpoint.PARTNER)
    const response = await axios.put(path, params?.body, {
        headers: { token: localStorage.getItem("cc_token") },
    });
    return response.data;
}

const postServices = { 
    postSignUpRequest,
    verify,
    updateData,
    getUniversityByToken,
    getCoachingByToken,
    studentSignup,
    getStudentByToken,
    getPartnerByToken,
    postLeadGeneration,
    getLeadGeneration,
    getLeadById,
    sendOTP
};

export default postServices;
