import React from "react";
import LoaderGif from "../../assest/images/preloader.gif";
const Loader = () => {
    return (
        <div style={{
            width: '24px',
            display: 'flex',
            }}>
            <img src={LoaderGif} alt="Loader" style={{width: '100%'}} />
        </div>
    )
}

export default Loader;