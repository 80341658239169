import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Rating,
  Radio,
  RadioGroup
} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import debounce from "lodash.debounce";
import routes from "../../react_routes_enum";
import { useNavigate } from "react-router-dom";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import InstituteCard from '../../components/instituteCard/InstituteCard';
import BlankUniv from "../../assest/images/blank_univ.png";
import SecondaryHead from "../../components/SecondaryHead/SecondaryHead";
import { getConfigDetails } from "../../redux/configurationDetails/configDetailsSlice";
import {
    getStudentUniversity, 
    getInstituteById,
    setSearchText,
    setInstituteCat,
    setInstituteId} from "../../redux/students/studentSlice";
import FilterListIcon from '@mui/icons-material/FilterList';
import Loader from "../../assest/images/preloader.gif";
import Spinner from "../../components/Spinner/Spinner";
import "./instituteListing.css";

const gradFilter = ["Under Graduation", "Post Graduation"]
const ratingReviewsFilter = ["5", "4.5", "3.0", "2.0", "1.0"]
export default function InstituteListing() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [limit, setLimit] = React.useState(10);
    const [offset, setOffset] = React.useState(0);
    const [catArray, setCatArray] = React.useState([]);
    const [cityArray, setCityArray] = React.useState([]);
    const [countryArray, setCountryArray] = React.useState([]);
    const [ratingArray, setRatingArray] = React.useState([]);
    const [instSubCat, setInstSubCat] = React.useState([]);
    const [isViewDetailClicked, setIsViewDetailClicked] = React.useState(false);
    const [underGraduation, setUnderGraduation] = React.useState(undefined);
    const [postGraduation, setPostGraduation] = React.useState(undefined);
    const [moreCatFilter, setMoreCatFilter] = React.useState(false);
    const [countryFilter, setCountryFilter] = React.useState([]);
    const [moreCountryFilter, setMoreCountryFilter] = React.useState(false);
    const [moreCityFilter, setMoreCityFilter] = React.useState(false);
    const [buttonLoader, setButtonLoader] = React.useState(false);
    const [dataLoader, setDataLoader] = React.useState(false);
    const [cityFilter, setCityFilter] = React.useState([]);
    const [instituteCat, setInstituteCats] = React.useState([]);
    const [isSearchByCat, setIsSearchByCat] = React.useState(false);

    const showMoreOptions = (type, value) => {
        type === "cat" && setMoreCatFilter(value);
        type === "country" && setMoreCountryFilter(value);
        type === "city" && setMoreCityFilter(value);
    }
    const { 
        isLoading, 
        isError, 
        totalCount, 
        message, 
        searchText,
        instituteData,
        instCat
    } = useSelector((state) => state.student);

    const handleChange = (e, item, index, type) => {
        setIsSearchByCat(true);
        if(limit === 10 && offset === 0){
            setDataLoader(true);
            if(type === 'cat'){
                let array = catArray;
                let i = array.indexOf(item);
                if(i > -1){
                    array.splice(i, 1);
                }else{
                    e.target.checked && array.push(item);
                }
                setCatArray([...array]);
            }
            if(type === 'grad'){
                item === "Under Graduation" ? (e.target.checked ? setUnderGraduation(1) : setUnderGraduation(0) ) :
                    (e.target.checked ? setPostGraduation(1) : setPostGraduation(0))
            }
            if(type === 'rating'){
                let array = ratingArray;
                let i = array.indexOf(Number(item));
                if(i > -1){
                    array.splice(i, 1);
                }else{
                    e.target.checked && array.push(Number(item));
                }
                setRatingArray([...array]);
            }
            if(type === 'country'){
                let array = countryArray;
                array[0] = item;
                setCountryArray([...array]);
            }
            if(type === 'city'){
                let array = cityArray;
                let i = array.indexOf(item);
                if(i > -1){
                    array.splice(i, 1);
                }else{
                    e.target.checked && array.push(item);
                }
                setCityArray([...array]);
            }
        }else{
            resetStateForPaginationOfDifferentView()
        }
    }

    React.useEffect(() => {
        const res = dispatch(getStudentUniversity({
            limit, 
            offset, 
            searchText, 
            city: cityArray, 
            category: instCat,
            country: countryArray, 
            branches: catArray,
            rating: ratingArray,
            under_graduation: underGraduation,
            post_graduation: postGraduation
        }))

        res?.then((r) =>{
            if(r?.payload?.statusCode === 200){
                setButtonLoader(false);
                setDataLoader(false);
                setIsViewDetailClicked(false);
            }
        })
    },[limit, offset, searchText, cityArray, instCat, countryArray, 
        ratingArray, underGraduation, postGraduation, catArray])

    const instituteDetail = (id) => {
        dispatch(setInstituteId(id))
        setIsViewDetailClicked(true);
        const res = dispatch(getInstituteById({id, type: 'university'}));
        res.then((r) => {
            setIsViewDetailClicked(false);
            r?.payload?.statusCode === 200 && navigate(routes.UNIVERSITY_DETAIL)
        })
    }

    const searchUniversity = (e) => {
        setIsSearchByCat(true);
        if(limit === 10 && offset === 0){
            setDataLoader(true);
            debouncedSave(e.target.value !== "" ? e.target.value : undefined)
        }else{
            resetStateForPaginationOfDifferentView()
        }
    }

    const debouncedSave = React.useCallback(
        debounce((newValue) => dispatch(setSearchText(newValue)), 2000),
        []
    );
    
    const loadMore = () => {
        setButtonLoader(true);
        setLimit(limit+10);
    }
    const resetStateForPaginationOfDifferentView = () => {
        setLimit(10);
        setOffset(0);
    };
    
    React.useEffect(() => {
        const res = dispatch(getConfigDetails());
        res.then((r) => {
            if(r?.payload?.statusCode === 200){
                r?.payload?.data?.map((item) => {
                    if(item?.configuration_type === "university_category"){
                        setInstituteCats(item?.data);
                        item?.data?.map((itm) => {
                            itm?.cat === instCat && setInstSubCat(itm?.subCat);
                        })
                    }
                    if(item?.configuration_type === "university_countries"){
                        item?.data?.map((cntry) => {
                            if(countryArray[0] && (cntry?.country === countryArray[0])){
                                setCityFilter(cntry?.city);
                            }
                        })
                        setCountryFilter(item?.data)
                    }
                })
            }
        })
    },[countryArray[0]])

    const getSelectedCategory = (value) => {
        setIsSearchByCat(false);
        setIsViewDetailClicked(true);
        dispatch(setInstituteCat(value));
        instituteCat?.map((item) => {
            item?.cat === value && setInstSubCat(item?.subCat);
        })
    }

    const allCatData = () => {
        setIsSearchByCat(false);
        setRatingArray([]);
        setCityFilter([]);
        setCountryArray([]);
        setIsViewDetailClicked(true);
        setUnderGraduation(undefined);
        setPostGraduation(undefined);
        dispatch(setInstituteCat(null));
        dispatch(setSearchText(null));
    }

    if (isLoading && isViewDetailClicked && !isSearchByCat) return <Spinner />;
    if (isError) return <h1>{message}</h1>;
    return (
        <>
            <SecondaryHead heading={'Top Universities'} />
            <Box sx={{p: 3}}>
                <section style={{
                    marginTop: '24px'
                }}>
                    <div className="category-aria">
                        <p className="we-found">
                            We found <span style={{color: '#000', fontWeight: 'bold'}}>{totalCount}</span> universities available for you
                        </p>
                        <Button type="button" className="btn-all-cat">
                            <span onClick={allCatData} style={{
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                All Category <ArrowDropDownIcon />
                            </span>
                            <div className="category-drop-in-listing">
                                <ul>
                                    {instituteCat?.length > 0 && 
                                        instituteCat.map((item, index) => (
                                        <li 
                                            key={index} 
                                            onClick={() => getSelectedCategory(item?.cat)}
                                            style={{
                                                backgroundColor: instCat === item?.cat ? 'var(--primary-color)' : '#fff',
                                                color: instCat === item?.cat ? '#fff' : '#000'
                                            }}>
                                            {item?.cat}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </Button>
                    </div>
                </section>
                <section style={{
                    marginTop: '24px'
                }} className="mobile-filter">
                    <div className="category-aria" style={{boxShadow: 'none'}}>
                        <div>
                            <FilterListIcon />
                        </div>
                        <div style={{
                            position: 'relative',
                        }}>
                            <TextField placeholder="Search University" 
                                sx={{
                                    width: '160px',
                                    height: '36px',
                                }} />
                            <div style={{
                                position: 'absolute',
                                right: 10,
                                top: 8,
                                zIndex: 1,
                                backgroundColor: 'white'
                            }}>
                                <SearchIcon fontSize="small" sx={{
                                    color: 'rgb(0, 0, 0, 0.5)'
                                }} />
                            </div>
                        </div>
                    </div>
                </section>
                <section style={{
                    marginTop: '24px'
                }}>
                    <div className="listing-section">
                        <div className="filter-divs">
                            <div className="search-filter">
                                <div style={{
                                    position: 'relative',
                                }}>
                                    <h2>Search Field</h2>
                                    <div className="line"></div>
                                </div>
                                <div style={{
                                    position: 'relative',
                                }}>
                                    <TextField placeholder="Search University" sx={{
                                        width: '100%'
                                    }} onChange={searchUniversity} />
                                    <div style={{
                                        position: 'absolute',
                                        right: 14,
                                        top: 12,
                                        zIndex: 1,
                                        backgroundColor: 'white'
                                    }}>
                                        <SearchIcon fontSize="large" sx={{
                                            color: 'rgb(0, 0, 0, 0.5)'
                                        }} />
                                    </div>
                                </div>
                            </div>
                            {instSubCat?.length > 0 && 
                            <div className="search-filter">
                                <div style={{
                                    position: 'relative',
                                }}>
                                    <h2>Categories</h2>
                                    <div className="line"></div>
                                </div>
                                <div>
                                    {moreCatFilter ? 
                                    <>
                                        {instSubCat?.map((item, index) => (
                                            <div key={index}>
                                                <FormControlLabel 
                                                control={<Checkbox 
                                                    onChange={(e) => handleChange(e, item, index, "cat")} />} 
                                                label={item} 
                                                sx={{
                                                    color: 'rgb(0, 0, 0, 0.5)',
                                                    fontSize: '14px'
                                                }} />
                                            </div>
                                        ))}
                                    </> :
                                    <>
                                        {instSubCat?.map((item, index) => (
                                            index < 6 &&
                                            <div key={index}>
                                                <FormControlLabel 
                                                    control={<Checkbox 
                                                        onChange={(e) => handleChange(e, item, index, "cat")} />} 
                                                label={item} 
                                                sx={{
                                                    color: 'rgb(0, 0, 0, 0.5)',
                                                    fontSize: '14px'
                                                }} />
                                            </div>
                                        ))}
                                    </>}
                                    
                                    {instSubCat && instSubCat?.length > 6 &&
                                    <Button sx={{
                                        fontSize: '12px',
                                        textTransform: 'capitalize',
                                        color: 'rgb(0, 0, 0, 0.5)',
                                        padding: 0
                                    }} onClick={() => {showMoreOptions("cat", !moreCatFilter)}}>
                                        {moreCatFilter ? <>Show Less <ArrowDropUpIcon /></>: <>Show More <ArrowDropDownIcon /></>}
                                    </Button>}
                                </div>
                            </div>}
                            <div className="search-filter">
                                <div style={{
                                    position: 'relative',
                                }}>
                                    <h2>Graduation</h2>
                                    <div className="line"></div>
                                </div>
                                <div>
                                    {gradFilter.map((item, index) => (
                                        <div key={index}>
                                            <FormControlLabel control={<Checkbox onChange={(e) => handleChange(e, item, index, "grad")} />} 
                                            label={item} sx={{
                                                color: 'rgb(0, 0, 0, 0.5)',
                                                fontSize: '14px'
                                            }} />
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="search-filter">
                                <div style={{
                                    position: 'relative',
                                }}>
                                    <h2>Rating And Reviews</h2>
                                    <div className="line"></div>
                                </div>
                                <div>
                                    {ratingReviewsFilter.map((item, index) => (
                                        <div key={index}>
                                            
                                            <FormControlLabel control={
                                                <>
                                                    <Checkbox onChange={(e) => handleChange(e, item, index, "rating")} />
                                                    <Rating
                                                        name="simple-controlled"
                                                        value={5}
                                                        readOnly
                                                        size="small"
                                                        sx={{marginRight: '8px'}}
                                                    />
                                                </>
                                            } label={index !== 0 ? `${item} & up` : item} sx={{
                                                color: 'rgb(0, 0, 0)',
                                                fontSize: '10px',
                                            }} />
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="search-filter">
                                <div style={{
                                    position: 'relative',
                                }}>
                                    <h2>Country</h2>
                                    <div className="line"></div>
                                </div>
                                <div>{moreCountryFilter ?
                                    <>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue="country"
                                            name="radio-buttons-group"
                                        >
                                        {countryFilter && countryFilter?.map((item, index) => (
                                            <div key={index}>
                                                <FormControlLabel 
                                                    value={item?.country}
                                                    control={<Radio
                                                        value={item?.country}
                                                        checked={countryArray[0] === item?.country}
                                                        onChange={(e) => handleChange(e, item?.country, index, "country")} />} 
                                                        label={item?.country} 
                                                        sx={{
                                                            color: 'rgb(0, 0, 0, 0.5)',
                                                            fontSize: '14px'
                                                        }} />
                                            </div>
                                        ))}
                                        </RadioGroup>
                                    </>
                                    :
                                    <>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue="country"
                                            name="radio-buttons-group"
                                        >
                                        {countryFilter && countryFilter?.map((item, index) => (
                                            index < 6 && 
                                            <div key={index}>
                                                <FormControlLabel 
                                                    value={item?.country}
                                                    control={
                                                    <Radio 
                                                        checked={countryArray[0] === item?.country}
                                                        value={item?.country}
                                                        onChange={(e) => handleChange(e, item?.country, index, "country")} />} 
                                                        label={item?.country} sx={{
                                                            color: 'rgb(0, 0, 0, 0.5)',
                                                            fontSize: '14px'
                                                        }} />
                                            </div>
                                        ))}
                                        </RadioGroup>
                                    </>
                                    }
                                    {countryFilter?.length >= 6 &&
                                    <Button sx={{
                                        fontSize: '12px',
                                        textTransform: 'capitalize',
                                        color: 'rgb(0, 0, 0, 0.5)',
                                        padding: 0
                                    }} onClick={() => {showMoreOptions("country", !moreCountryFilter)}}>
                                        {moreCountryFilter ? <>Show Less <ArrowDropUpIcon /></> : <>Show More <ArrowDropDownIcon /></>}
                                    </Button>}
                                </div>
                            </div>
                            {cityFilter?.length > 0 &&
                            <div className="search-filter">
                                <div style={{
                                    position: 'relative',
                                }}>
                                    <h2>{'City'}</h2>
                                    <div className="line"></div>
                                </div>
                                <div>
                                    {moreCityFilter ?
                                    <>
                                        {cityFilter.map((item, index) => (
                                            <div key={index}>
                                                <FormControlLabel control={<Checkbox onChange={(e) => handleChange(e, item, index, "city")} />} 
                                                label={item} sx={{
                                                    color: 'rgb(0, 0, 0, 0.5)',
                                                    fontSize: '14px'
                                                }} />
                                            </div>
                                        ))}
                                    </>
                                    :
                                    <>
                                        {cityFilter.map((item, index) => (
                                            index < 6 && 
                                            <div key={index}>
                                                <FormControlLabel control={<Checkbox onChange={(e) => handleChange(e, item, index, "city")} />} 
                                                label={item} sx={{
                                                    color: 'rgb(0, 0, 0, 0.5)',
                                                    fontSize: '14px'
                                                }} />
                                            </div>
                                        ))}
                                    </>
                                    }
                                    {cityFilter?.length >= 6 && 
                                    <Button sx={{
                                        fontSize: '12px',
                                        textTransform: 'capitalize',
                                        color: 'rgb(0, 0, 0, 0.5)',
                                        padding: 0
                                    }} onClick={() => {showMoreOptions("city", !moreCityFilter)}}>
                                        {moreCityFilter ? <>Show Less <ArrowDropUpIcon /></>: <>Show More <ArrowDropDownIcon /></>}
                                    </Button>}
                                </div>
                            </div>}
                        </div>
                        <div className="card-aria">
                            {dataLoader ?
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginTop: '24px'
                                }}>
                                    <img src={Loader} alt="loader" width="48" height="48" />
                                </div> :
                                <>
                                    {instituteData && instituteData?.length > 0 ?
                                        <>
                                            {instituteData?.map((item, index) => (
                                                <InstituteCard 
                                                    data={item} 
                                                    key={index}
                                                    viewDetail={(id) => {instituteDetail(id)}} />
                                                ))}
                                                {(instituteData.length < totalCount) && <div style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}>
                                                    <Button className="load-more-button" onClick={loadMore} disabled={buttonLoader}>
                                                        {buttonLoader ?  <img src={Loader} height="24" width="24" alt="loader" /> : 'Load More'}
                                                    </Button>
                                                </div>}
                                        </>
                                        :
                                        <div style={{ 
                                            display: 'flex', 
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center'}} className="blank-data">
                                                <img src={BlankUniv} alt="blankData" width="145" height="216" />
                                                <p style={{
                                                    color: 'rgba(0, 0, 0, 0.5)'
                                                }}>No Data Found</p>
                                        </div>
                                    }
                                </>
                            }
                        </div>
                    </div>
                </section>
            </Box>
        </>
    );
}
