import * as React from 'react';
import Button from '@mui/material/Button';
import { InputLabel, Select, MenuItem } from '@mui/material';
import TextField from '@mui/material/TextField';
import { useDispatch, useSelector } from "react-redux";
import { 
    addContent,
    getContent, 
    updateContent } from '../../redux/product/productSlice';
import HomeLayout from "../homeLayout/HomeLayout";
import Loader from "../../assest/images/preloader.gif";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import UploadBox from '../../components/UploadBox/uploadBox';
import { makeStyles } from "@mui/styles";
import routes from "../../react_routes_enum";
import "./question.css";

const useStyle = makeStyles({
  addMore:{
    height: '36px',
    textTransform: 'capitalize !important',
    fontWeight: 'bold !important',
    color: '#fff !important',
    '&:hover':{
      backgroundColor: 'var(--primary-color) !important',
    }
  },
  addQuestion:{
    color: '#fff !important',
    borderRadius: '8px !important',
    fontWeight: 'bold !important',
    textTransform: 'capitalize !important',
    '&:hover':{
      backgroundColor: '#3C1CDE !important',
    }
  },
  upload:{
    fontSize: '10px !important',
    color: 'rgb(0, 0, 0, 0.4) !important',
    textTransform: 'capitalize !important',
  }
})

let courseData = {
    name: "",
    type: "",
    content_url: ""
}

const material = ["Notes", "Study Material"]
export default function Question() {
    const classes = useStyle();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isWebsiteFor = localStorage.getItem('is-website-for');
    const [courseBody, setCourseBody] = React.useState(courseData);
    const [btnLoader, setBtnLoader] = React.useState(false);
    const { actionType, contentById } = useSelector(state => state.product);

    const handleChange = (e) => {
        e.target.name === "title" && setCourseBody({...courseBody, name: e.target.value})
        e.target.name === "type" && setCourseBody({...courseBody, type: e.target.value})
    }

    const submitCourse = () => {
        if(validate().isValid){
            setBtnLoader(true);
            const res = ( actionType === "Edit" ? 
                dispatch(updateContent({type: 'coaching', body: courseBody, id: contentById?._id}))
                : dispatch(addContent({type: 'coaching', body: courseBody})))
            res?.then((r) => {
                setBtnLoader(false);
                if(r?.payload?.statusCode === 200){
                    actionType === "Edit" ? toast.success("Content updated successfully") : 
                    toast.success("Content submitted successfully");
                    dispatch(getContent({type: 'coaching'}));
                    navigate(routes.CONTENT)
                }else{
                    toast.error(r?.payload?.message);
                }
            })
        }else{
            toast.error(validate().message);
        }
    }

    const validate = () => {
        let status = {isValid: true, message: ""};
        if(courseBody?.name === ""){
            status.isValid = false;
            status.message = "Please enter name";
            return status;
        }
        if(courseBody?.type === ""){
            status.isValid = false;
            status.message = "Please enter type";
            return status;
        }
        if(courseBody?.content_url === ""){
            status.isValid = false;
            status.message = "Please upload a file";
            return status;
        }
        
        return status;
    }

    React.useEffect(() => {
        if(contentById && (actionType === "View" || actionType === "Edit")){
            setCourseBody({
                name: contentById?.name,
                type: contentById?.type,
                content_url: contentById?.content_url
            })
        }
    },[]);

    const handleImageUploads = (name, value) => setCourseBody({...courseBody, content_url: value})
    return (
        <>
        <HomeLayout />
            <div>
                <div className="content-box table-box dashboard-design" style={{
                    overflow: 'hidden',
                }}>
                    <div className="dasboard-two-input">
                        <div style={{
                            width: '100%',
                        }}>
                            <InputLabel className="input-label-text">
                                Title
                            </InputLabel>
                            <TextField 
                                placeholder="" 
                                name="title"
                                type="text" 
                                variant="outlined" 
                                className="inputShadow-2 t-width"
                                value={courseBody?.name}
                                sx={{
                                    width: '100%',
                                    height: '62px'
                                }} 
                                onChange={handleChange} 
                                required />
                        </div>
                    </div>
                    <div>
                        <InputLabel className="input-label-text">
                            Type
                        </InputLabel>
                        <Select
                            required
                            name="type"
                            className="select-class"
                            value={courseBody?.type}
                            onChange={handleChange}
                            placeholder="course"
                            autoWidth
                            sx={{
                                width: '100%',
                                height: '56px',
                                position: 'relative',
                                marginRight: '12px',
                                border: '1px solid rgba(0,0,0,0.4)'
                            }}>
                            {material.map((item, index) => (
                                <MenuItem value={item} key={index}>
                                    {item}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                    <InputLabel 
                    sx={{
                        marginTop: '12px'
                    }}
                    className="input-label-text">
                        Upload File
                    </InputLabel>
                    <div className="product-upload-box">
                        <UploadBox 
                            name="syllabus"
                            value={courseBody?.content_url}
                            handleUpload={(name, value) => handleImageUploads(name, value)}
                            />
                    </div>
                </div>
            </div>
        {actionType !== "View" &&
        <div className="add-question-box" style={{
            marginBottom: '24px'
        }}>
            <Button 
                sx={{
                    backgroundColor: btnLoader ? 'rgb(0, 0, 0, 0.2)' : '#3C1CDE'
                }}
                disabled={btnLoader}
                className={`${classes.addQuestion} add-question`} 
                onClick={submitCourse}>
            {btnLoader ? <img src={Loader} width="24" alt="Loader" /> : 
            (actionType === "Edit" ? 'Update' : 'Submit Content')}
            </Button>
        </div>}
        </>
    );
}