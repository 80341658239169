import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Typography, Rating } from '@mui/material';
import TextField from '@mui/material/TextField';
import Loader from "../../assest/images/preloader.gif";
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PersonIcon from '@mui/icons-material/Person';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ReactPlayer from 'react-player'
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { addReview, getCourseById, courseProgress } from "../../redux/students/studentSlice";
import { logout, reset } from "../../redux/auth/authSlice";
import { makeStyles } from "@mui/styles";
import "./recordedCourse.css";
import { toast } from "react-toastify";
import routes from '../../react_routes_enum';

const ratingData = {
    message: "",
    rating: 0,
}

export default function RecordedCourse() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userData, setUserData] = React.useState();
  const [tabValue, setTabValue] = React.useState("1");
  const [ratingBody, setRatingBody] = React.useState(ratingData);
  const [btnLoader, setBtnLoader] = React.useState(false);
  const [videoLink, setVideoLink] = React.useState("");
  const [videoThumbnail, setVideoThumbnail] = React.useState("");
  const [videoIndex, setVideoIndex] = React.useState("");
  const [sectionIndex, setSectionIndex] = React.useState("")

  const { courseById } = useSelector((state) => state.student);

  React.useEffect(() => {
    setUserData(JSON.parse(localStorage.getItem('cc_user')));
    const res = dispatch(getCourseById({id: "64ac4271bef42f6e8b7eef45"}));
    res?.then((r) => {
        if(r?.payload?.statusCode === 200){
            if(!r?.payload?.data?.isComplete){
                getSectionToPlay(r?.payload?.data?.progress);
            }
        }
    })
  }, []);

  const getSectionToPlay = (data) => {
    let isFound = false;
    data?.map((item, index) => {
        if(!item?.isCompleted && !isFound){
            let isVideo = false;
            item?.items?.map((itm, ind) => {
                if(!itm?.isCompleted && !isVideo){
                    setVideoLink(itm?.item_id?.video_url);
                    setVideoIndex(ind);
                    setSectionIndex(index);
                    setVideoThumbnail(itm?.item_id?.video_thumbnail);
                    isVideo = true;
                }
            })
            isFound = true;
        }
    })
  }

  const handleLogout = () => {
    dispatch(logout());
    dispatch(reset());
    navigate('/login')
  };

  const handleTab = (event, value) => {
    setTabValue(value);
  }

  const addRating = () => {
    if(ratingBody?.rating === 0){
        toast.error("Star rating required");
        return;
    }
    if(ratingBody?.message === ""){
        toast.error("Message required");
        return;
    }
    let body = {
        name: userData?.student?.name,
        email: userData?.student?.email,
        message: ratingBody?.message,
        rating: ratingBody?.rating,
        course_id: "64abfd251cd7b06a152a7b68"
    }

    setBtnLoader(true);
    const res = dispatch(addReview(body));
    res?.then((r) => {
        setBtnLoader(false);
        if(r?.payload?.statusCode === 200){
            setRatingBody({rating: 0, message: ""});
            toast.success("Review submitted successfully");
        }else{
            toast.error(r?.payload?.message);
        }
    })
  }

  const getVideoUrl = (sectionDetail, videoDetail) => {
    setVideoLink(videoDetail?.item_id?.video_url);
    setVideoThumbnail(videoDetail?.item_id?.video_thumbnail);
  }

  const videoEnded = () => {
    let section = courseById?.progress[sectionIndex];
    let video = section?.items[videoIndex + 1];
    let prevVideo = section?.items[videoIndex];

    let body = {
        course_id: "64ac4271bef42f6e8b7eef45",
        section_id: section?.section_id?._id,
        item_id: prevVideo?.item_id?._id,
        type: "item"
    }
    const res = dispatch(courseProgress(body));
    res?.then((r) => {
        if(r?.payload?.statusCode === 200){
            if(video){
                setVideoLink(video?.item_id?.video_url);
                setVideoThumbnail(video?.item_id?.video_thumbnail);
                setVideoIndex(videoIndex + 1);
            }else{
                let nextSection = courseById?.progress[sectionIndex + 1];
                let body = {
                    course_id: "64ac4271bef42f6e8b7eef45",
                    section_id: section?.section_id?._id,
                    type: "section"
                }
                const res = dispatch(courseProgress(body));
                res?.then((r) => {
                    if(r?.payload?.statusCode === 200){
                        if(nextSection){
                            let nextVideo = nextSection?.items[0];
                            setVideoLink(nextVideo?.item_id?.video_url);
                            setVideoThumbnail(nextVideo?.item_id?.video_thumbnail);
                            setVideoIndex(0);
                            setSectionIndex(sectionIndex + 1);
                        }else{
                            let finalBody = {
                                course_id: "64ac4271bef42f6e8b7eef45",
                                type: "course"
                            }
                            const finalRes = dispatch(courseProgress(finalBody));
                            finalRes?.then((resp) => {
                                if(resp?.payload?.statusCode === 200){
                                    navigate(routes.COURSE_COMPLETED);
                                }
                            })
                        }
                    }
                });
                
            }
        }
    })
  }

  return (
    <>
        <div className="right-menu-top-header" style={{
                borderBottom: '2px solid rgba(0, 0, 0, 0.2)',
                position: 'fixed',
                background: '#fff',
                zIndex: 1,
                width: '100%'
            }}>
            <div>
                <ArrowBackIosIcon />
            </div>
            <div className="course-t">
                {courseById?.course_id?.title} / Recorded Session
            </div>
            <div style={{
                marginRight: '36px'
            }}>
                <div className="user-aria">
                    <div className="notification-icon" 
                    style={{
                        position: 'relative',
                    }}>
                        <NotificationsNoneIcon fontSize="large" sx={{
                        color: 'rgb(0, 0, 0, 0.4)'
                        }} />
                        <div style={{
                            backgroundColor: '#EF0000',
                            height: '12px',
                            width: '12px',
                            borderRadius: '50%',
                            position: 'absolute',
                            top: '4px',
                            right: '2px'
                        }}>

                        </div>
                    </div>
                    <div style={{
                        width: '48px',
                        height: '48px',
                        borderRadius: '50%',
                        backgroundColor: 'grey',
                        margin: '0 12px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer'
                    }} 
                    // onClick={goToProfile}
                    >
                        {userData?.student?.profile_picture ? 
                            <img src={userData?.student?.profile_picture} 
                                alt="logo" style={{width: '100%'}} /> : 
                            <PersonIcon fontSize='large' sx={{color: 'white'}} />}
                    </div>
                    <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    position: 'relative',
                    }} className="user-detail">
                    <Typography 
                        component="p" 
                        variant="p" 
                        sx={{fontWeight: 'bold'}} 
                        className="user-name">
                        {userData?.student?.name && 
                        (userData?.student?.name?.length > 6 ? 
                        `${userData?.student?.name?.substr(0, 6)}...` : 
                        `${userData?.student?.name?.substr(0, 6)}`)}
                    </Typography>
                    <div>
                        <KeyboardArrowDownIcon sx={{color: 'rgb(0, 0, 0, 0.4)'}} />
                    </div>
                    <div className="user-detail-options">
                        <Button type="button" onClick={handleLogout}>
                        Logout
                        </Button>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="video-list">
            <p className="course-t" style={{color: '#000'}}>
                {courseById?.course_id?.title} / Recorded Session
            </p>
            <div className="video-list-section">
                <div className="video-section">
                    <div className="video-frame">
                        <ReactPlayer 
                            light={<img 
                                src={videoThumbnail} 
                                style={{width: '100%', height: '100%'}} 
                                alt="" />}
                            url={videoLink}
                            width="100%"
                            height="100%"
                            onEnded={videoEnded}
                            controls
                            />
                    </div>
                    <div className='overview-aria'>
                        <TabContext value={tabValue}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleTab} aria-label="lab API tabs example">
                                <Tab label="Overview" value="1" />
                                <Tab label="Review" value="2" />
                            </TabList>
                            </Box>
                            <TabPanel value="1">
                                <Typography 
                                    component="p" 
                                    variant="p" 
                                    className="course-des-title">
                                    Course Description
                                </Typography>
                                <span class="video-overview">
                                    {courseById?.course_id?.description}
                                </span>
                            </TabPanel>
                            <TabPanel value="2">
                                <Typography 
                                    component="p" 
                                    variant="p" 
                                    className="course-des-title">
                                    How do you like the course?   
                                </Typography>
                                <Rating
                                    className="rating-controlled"
                                    name="rating"
                                    value={parseInt(ratingBody?.rating)}
                                    onChange={
                                        (e) => setRatingBody({
                                            ...ratingBody, 
                                            rating: e.target.value})
                                    }/>
                                <TextField 
                                    placeholder="Write your reviews here!" 
                                    type="text" 
                                    variant="outlined" 
                                    name="message" 
                                    className="message"
                                    sx={{
                                        marginTop: '12px'
                                    }}
                                    value={ratingBody?.message}
                                    onChange={
                                        (e) => setRatingBody({
                                            ...ratingBody, 
                                            message: e.target.value})}
                                    rows={6}
                                    multiline
                                    required/>
                                    <Button 
                                        className="submit-rating-button"
                                        sx={{
                                            background: btnLoader ? "rgb(0, 0, 0, 0.2)" : 
                                            "var(--primary-color)",
                                            "&:hover":{
                                                background: "var(--primary-color)"
                                            }
                                        }}
                                        onClick={addRating}
                                        disabled={btnLoader}>
                                        {btnLoader ? 
                                        <img src={Loader} width="24" alt="Loader" /> :
                                        "Submit"}
                                    </Button>
                            </TabPanel>
                        </TabContext>
                    </div>
                </div>
                <div className="accordion-list">
                    <Typography className="course-content-t">
                        Course Content
                    </Typography>
                    {courseById?.progress?.map((item, index) => (
                        <Accordion key={index}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className="expand-more-icon" />}
                                aria-controls="panel1a-content"
                                id="panel1a-header">
                                <Typography className="chapter-title">
                                    Chapter {index+1}: {item?.section_id?.name}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <ul>
                                    {item?.items?.map((itm, ind) => (
                                        <li key={`_${index}${ind}`} 
                                            className="video-box"
                                            style={{
                                                background: videoLink === itm?.item_id?.video_url ? 
                                                    'var(--primary-color)' : 'none',
                                                borderRadius: '8px',
                                                cursor: 'pointer'
                                            }} 
                                            onClick={() => getVideoUrl(item, itm)}>
                                            <img src={itm?.item_id?.video_thumbnail} 
                                                alt={`${itm?.title}`} 
                                                style={{
                                                    width: 108,
                                                    height: 72,
                                                    borderRadius: 8,
                                                    }} />
                                            <div className="v-detail">
                                                <p className="video-tle" style={{
                                                        color: videoLink === itm?.item_id?.video_url ? "#fff" : '#000'
                                                    }}>
                                                    {ind+1}.
                                                </p>
                                                <div style={{
                                                    marginLeft: '4px'
                                                }}>
                                                    <p 
                                                        className="video-tle" 
                                                        style={{
                                                            color: videoLink === itm?.item_id?.video_url ? "#fff" : '#000'
                                                        }}>
                                                        {itm?.item_id?.name}
                                                    </p>
                                                    <p className="video-des" style={{
                                                            color: videoLink === itm?.item_id?.video_url ? "rgb(255, 255, 255, 0.7)" : 'rgb(0, 0, 0, 0.5)'
                                                        }}>
                                                        {itm?.item_id?.description}
                                                    </p>
                                                    {/* <p className="video-duration">
                                                        Duration: {item?.duration}min
                                                    </p> */}
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </div>
            </div>
        </div>
    </>
  );
}