
import * as React from 'react';
import {
    Select,
    MenuItem,
  } from "@mui/material";

const SelectView = (props) => {
    return (
        <>
            <Select
                disabled={props.isDisabled}
                multiple={props.isMultiple}
                name={props.name}
                value={props.value}
                onChange={props.handleSelect}
                autoWidth
                required
                sx={{
                    width: '100%',
                    height: '56px',
                    position: 'relative',
                    marginRight: '12px'
                }}
                >
                    {props.from === "test" ?
                        props?.option?.map((item, index) => (
                        <MenuItem value={item?._id} key={index}>
                            {item?.title || item?.name}
                        </MenuItem>
                    )) :
                    props?.option?.map((item, index) => (
                        <MenuItem value={item} key={index}>
                            {item}
                        </MenuItem>
                    ))}
            </Select>
        </>
    )

}
export default SelectView;