import * as React from 'react';
import HomeLayout from "../homeLayout/HomeLayout";
import ProductTable from "../../components/BasicTable/BasicTable";
import Pagination from "../../components/Pagination/Pagination";
import { useDispatch, useSelector } from "react-redux";
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { useNavigate, Link } from "react-router-dom";
import VisibilityIcon from '@mui/icons-material/Visibility';
import Dialog from "@mui/material/Dialog";
import { 
    getLeadById,
    getLeadGeneration } from "../../redux/requestForm/requestFormSlice";
import { getProduct, 
    getProductById, 
    deleteProduct, 
    setProductDetailsById } from '../../redux/product/productSlice';
import Spinner from "../../components/Spinner/Spinner";
import routes from "../../react_routes_enum";
import { toast } from 'react-toastify';
import {
    Button,
    IconButton,
    Typography,
  } from "@mui/material";
import "./LGS.css";

export default function LGS() {
    const [limit, setLimit] = React.useState(10);
    const [offset, setOffset] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [isAddProduct, setIsAddProduct] = React.useState(false);
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);
    const [deleteId, setDeleteId] = React.useState(false);
    const [isWebsiteFor, setIsWebsiteFor] = React.useState(localStorage.getItem("is-website-for"));
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const colR = ["SNo", "Name", "Email Id", "Phone", "DOB", "Status", "view"];

    const { 
        isLoading, 
        isError,
        totalCount,
        searchText,
        leadGenerationData,
        message } = useSelector((state) => state.form);
    
    React.useEffect(() => {
        dispatch(getLeadGeneration({limit, offset, searchText}));
    },[limit, offset, searchText])

    const viewLead = (id) => {
        const res = dispatch(getLeadById({id}));
        res?.then((r) => {
            r?.payload?.statusCode === 200 && navigate(routes.VIEW_LEAD)
            r?.payload?.statusCode !== 200 && toast.error(r?.payload?.message);
        },[])
    }

    const deleteProductById = () => {
        const res = dispatch(deleteProduct({id: deleteId, isWebsiteFor}));
        res.then((r) => {
            if(r.payload.statusCode === 200){
                toast.success(r.payload.message);
                setIsDialogOpen(false);
                dispatch(getProduct({limit, offset, isWebsiteFor}));
            }else{
                toast.error(r.payload.message);
            }
        })
    }

    const resetStateForPaginationOfDifferentView = () => {
        setLimit(10);
        setOffset(0);
        setPage(0);
    };

    const handleSearchText = (e) => {
        resetStateForPaginationOfDifferentView();
        if (limit === 10 && offset === 0) {
            dispatch(getProduct({limit, offset, searchText}));
        }
    };

    const createNewLead = () => {
        navigate(routes.CREATE_LEAD);
    }
    const handleClose = () => {
        setIsDialogOpen(false);
    };

    if (isLoading && !isAddProduct) return <Spinner />;
    if (isError) return <h1>{message}</h1>;
    return (
      <>
        <Dialog maxWidth="xs" open={isDialogOpen} onClose={handleClose} fullWidth>
            <div>
            <Typography component="p" variant="p" 
                sx={{
                textAlign: 'center',
                fontWeight: 'bold',
                margin: '28px 0 48px'}}>
                Are you sure you want to<br/> delete the product ?
            </Typography>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'row-reverse',
                padding: '0px 16px 28px 16px'
            }}>
                <Button onClick={() => {setIsDialogOpen(false)}} variant="outlined" sx={{
                textTransform: 'capitalize'
                }}>
                    Cancel
                </Button>
                <Button onClick={() => {deleteProductById()}} variant="contained" sx={{
                    backgroundColor: 'var(--primary-color)',
                    width: '84px',
                    marginRight: '12px',
                    textTransform: 'capitalize'
                    }}>
                    Yes
                </Button>
            </div>
            </div>
        </Dialog>
        <HomeLayout />
        <div className="pagination-addPost partner-new-box">
            <div className="add-product-button-2 newClass">
                <Button variant="contained" onClick={createNewLead}
                sx={{
                    backgroundColor: (isWebsiteFor === "university" ||
                    isWebsiteFor === "partner") ? '#F69220' : 'var(--tertiary-color)',
                    textTransform: 'capitalize',
                    borderRadius: '8px'
                    }}>
                    <ControlPointIcon sx={{marginRight: '4px'}} />
                    Create Lead
                </Button>
            </div>
            <Pagination
                totalCount={totalCount}
                limit={limit}
                page={page}
                changeLimit={setLimit}
                changeOffset={setOffset}
                changePage={setPage}
            />
        </div>
        <div className="content-box table-box" style={{
            marginTop: '0px',
            overflowX: 'scroll',
            overflowY: 'hidden'
        }}>
            <ProductTable
                columns={colR}
                where="recipes"
                rows={leadGenerationData && leadGenerationData?.map((item) => {
                    const res = {
                    ...item,
                    Status: (
                        <Typography component="p" variant="p" 
                            sx={[{
                                fontSize: '18px',
                                fontWeight: 'bold',
                                textTransform: 'capitalize'
                                },
                                item?.status === "pending" && {color: '#ECAB2D'},
                                item?.status === "rejected" && {color: '#EF4840'},
                                item?.status === "accepted" && {color: '#07AB35'}
                                ]}>
                            {item?.status}
                        </Typography>
                    ),
                    view: (
                        <div>
                            <IconButton
                                color="inherit"
                                edge="start"
                                onClick={() => {viewLead(item._id)}}>
                                <div>
                                    <VisibilityIcon sx={{color: '#3C1CDE'}} />
                                    <Typography component="p" variant="p" 
                                    sx={{
                                        color: '#3C1CDE',
                                        fontSize: '18px',
                                        fontWeight: 'bold'
                                        }}>
                                    View
                                    </Typography>
                                </div>
                            </IconButton>
                        </div>
                    )};
                    return res;
                })
                }
            />
        </div>
      </>
    );
  }