import * as React from "react";
import Backdrop from '@mui/material/Backdrop';
import "./notification.css";

const Notification = (props) => {

    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={props.open}
            onClick={() => props.handleClick()}
        >
            <div className="notification-box">
                <h3>
                    Notifications
                </h3>
                <ul>
                    <li style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        }}>
                        <div style={{
                            height: '8px',
                            width: '12px',
                            borderRadius: '50%',
                            backgroundColor: 'rgb(0, 0, 0, 0.5)',
                            marginRight: '4px',
                            marginTop: '4px'
                        }}></div>
                        Purus sit amet luctus venenatis, lectus magna fringilla urna, 
                        porttitor purus sit amet luctus
                    </li>
                </ul>
            </div>
        </Backdrop>
    )
}

export default Notification;