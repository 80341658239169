import * as React from 'react';
import HomeLayout from "../homeLayout/HomeLayout";
import "./student.css";
import { useDispatch, useSelector } from "react-redux";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import {
    InputLabel,
    TextField,
    Button
} from "@mui/material";
import Moment from "moment";
import Dialog from "@mui/material/Dialog";
import InputAdornment from '@mui/material/InputAdornment';
import PersonIcon from '@mui/icons-material/Person';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import CakeIcon from '@mui/icons-material/Cake';
import SchoolIcon from '@mui/icons-material/School';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import RoomIcon from '@mui/icons-material/Room';
import CreateIcon from '@mui/icons-material/Create';
import PublicIcon from '@mui/icons-material/Public';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import { makeStyles } from "@mui/styles";
import { 
  getInstituteApplicationById
} from '../../redux/instituteStudent/instituteStudentSlice';
import { 
  getInstituteById
} from '../../redux/students/studentSlice';
import Spinner from "../../components/Spinner/Spinner";

const useStyle = makeStyles({
  addMore:{
    height: '36px',
    textTransform: 'capitalize !important',
    fontWeight: 'bold !important',
  },
  changesButton: {
    color: '#fff !important',
    textTransform: 'capitalize !important',
    width: '105px',
    height: '40px',
    fontSize: '16px !important',
    borderRadius: '4px !important',
    boxShadow: '0 2px 5px rgb(0, 0, 0, 0.5)',
    "&:hover":{
        backgroundColor: 'var(--secondary-color) !important',
    },
  },
  formInputLabel: {
    color: '#000 !important',
    fontSize: '14px !important',
    marginBottom: '4px',
    fontWeight: 'bold !important'
  },
  marginBot24: {
    marginBottom: '24px'
  },
})

export default function RegisterNowToApply(){
  const isWebsiteFor = localStorage.getItem("is-website-for");
  const classes = useStyle();
  const dispatch = useDispatch();
  const [instData, setInstData] = React.useState();
  const [open, setOpen] = React.useState(false);
  const [imageUrl, setImageUrl] = React.useState("")
  const { 
    isLoading,
    applicationById,
    instituteId,  
  }= useSelector((state) => state.instituteStudent);

  React.useEffect(() => {
    const res = dispatch(getInstituteApplicationById({id: instituteId, isWebsiteFor}));
    res.then((r) => {
      if(r?.payload?.statusCode === 200){
        getInstituteDetails(r?.payload?.data);
      }
    })
  },[])

  const getInstituteDetails = (data) => {
    let id = data?.coaching_id || data?.university_id;
    const res = dispatch(getInstituteById({id, type: isWebsiteFor}));
    res.then((r) => {
      r?.payload?.statusCode === 200 && setInstData(r?.payload?.data);
    })
  }
  const showDocument = (url) => {
    setImageUrl(url);
    setOpen(true);
  }
  const handleClose = () => {
    setOpen(false);
  }
  if (isLoading) return <Spinner />;

  return(
    <>
      <Dialog maxWidth={"md"} open={open} onClose={handleClose} fullWidth>
        <div>
          <img src={imageUrl} alt="document" />
        </div>
      </Dialog>
      <HomeLayout />
      <div className="" style={{marginTop: '100px'}}>
        <div className="top-heading">
            <div className="logo-university">
                <img src={instData?.uploads?.coaching_logo || instData?.uploads?.university_logo} 
                  alt="university logo"/>
            </div>
            <div className="register-text">
                <p className="register-title">Register now to applydsss</p>
                {instData && <p className="university-name">
                  {instData?.coaching_name || instData?.university_name}, {instData?.information?.city}
                </p>}
            </div>
        </div>
        <div className="registration-form for-input">
            <form>
              <div className="two-input-aria">
                <div className="input-box-mobile">
                  <InputLabel className="input-label-text">Name</InputLabel>
                    <TextField 
                      placeholder="" 
                      type="text" 
                      variant="outlined" 
                      name="addr"
                      className="name-field widthSet" 
                      value={applicationById?.name}
                      required
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PersonIcon fontSize='small' />
                          </InputAdornment>
                        ),
                      }}
                      />                     
                </div>
                <div className="input-box-mobile marginLeftIn">
                  <InputLabel className="input-label-text">Address</InputLabel>
                    <TextField 
                      placeholder="" 
                      type="text" 
                      variant="outlined" 
                      name="email" 
                      className="email-id widthSet"
                      value={applicationById?.address}
                      required
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LocationOnIcon fontSize='small' />
                          </InputAdornment>
                        ),
                      }}
                      />
                </div>
              </div>
              {applicationById?.status === "enrolled" && 
              <div className="two-input-aria">
                <div className="input-box-mobile">
                  <InputLabel className="input-label-text">Phone Number</InputLabel>
                  <div style={{display: 'flex'}}>
                    <TextField className="country-code" value="+91" sx={{color: 'rgb(0, 0, 0)'}}></TextField>
                    <TextField 
                      placeholder="" 
                      type="text" 
                      variant="outlined" 
                      name="addr"
                      className="name-field phoneWidthSet" 
                      value={applicationById?.phone_number}
                      required
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PhoneIcon fontSize='small' />
                          </InputAdornment>
                        ),
                      }}
                    />   
                  </div>                  
                </div>
                <div className="input-box-mobile marginLeftIn">
                  <InputLabel className="input-label-text">Email Id</InputLabel>
                    <TextField 
                      placeholder="" 
                      type="text" 
                      variant="outlined" 
                      name="email" 
                      className="email-id widthSet"
                      value={applicationById?.email}
                      required
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <EmailIcon fontSize='small' />
                          </InputAdornment>
                        ),
                      }}
                    />
                </div>
              </div>}
              <div className="two-input-aria">
                <div className="input-box-mobile">
                  <InputLabel className="input-label-text">Date of Birth</InputLabel>
                    <TextField 
                      placeholder="" 
                      type="text" 
                      variant="outlined" 
                      name="addr"
                      className="name-field widthSet" 
                      value={Moment(applicationById?.dob).format("DD MMMM YYYY")}
                      required
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <CakeIcon fontSize='small' />
                          </InputAdornment>
                        ),
                      }}
                    />                     
                </div>
                <div className="input-box-mobile marginLeftIn">
                  <InputLabel className="input-label-text">Currently School/College
                  <span style={{color:'#00000080', fontSize: '12px'}}>(Optional)</span></InputLabel>
                    <TextField 
                      placeholder="" 
                      type="text" 
                      variant="outlined" 
                      name="email" 
                      className="email-id widthSet"
                      value={applicationById?.current_college}
                      required
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SchoolIcon fontSize='small' />
                          </InputAdornment>
                        ),
                      }}
                    />
                </div>
              </div>
              <div className="two-input-aria">
                <div className="input-box-mobile">
                  <InputLabel className="input-label-text">Current Qualification</InputLabel>
                    <TextField 
                      placeholder="" 
                      type="text" 
                      variant="outlined" 
                      name="addr"
                      className="name-field widthSet" 
                      value={applicationById?.current_qualification}
                      required
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <CakeIcon fontSize='small' />
                          </InputAdornment>
                        ),
                      }}
                    />                     
                </div>
                <div className="input-box-mobile marginLeftIn">
                  <InputLabel className="input-label-text">Courses</InputLabel>
                    <TextField 
                      placeholder="" 
                      type="text" 
                      variant="outlined" 
                      name="email" 
                      className="email-id widthSet"
                      value={applicationById?.courses?.toString()}
                      required
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LibraryBooksIcon fontSize='small' />
                          </InputAdornment>
                        ),
                      }}
                    />
                </div>
              </div>
              <div className="two-input-aria">
                <div className="input-box-mobile">
                  <InputLabel className="input-label-text">Budget
                    <span style={{color:'#00000080', fontSize:'12px'}}>(as per course selection)</span></InputLabel>
                    <TextField 
                      placeholder="" 
                      type="text" 
                      variant="outlined" 
                      name="addr"
                      className="name-field widthSet" 
                      value={applicationById?.budget}
                      required
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <CakeIcon fontSize='small' />
                          </InputAdornment>
                        ),
                      }}
                    />                     
                </div>
                <div className="input-box-mobile marginLeftIn">
                  <InputLabel className="input-label-text">Location</InputLabel>
                    <TextField 
                      placeholder="" 
                      type="text" 
                      variant="outlined" 
                      name="email" 
                      className="email-id widthSet"
                      value={applicationById?.location}
                      required
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LocationOnIcon fontSize='small' />
                          </InputAdornment>
                        ),
                      }}
                    />
                </div>
              </div>
              <div className="two-input-aria">
                <div className="input-box-mobile">
                  <InputLabel className="input-label-text">Parent's Name</InputLabel>
                    <TextField 
                      placeholder="" 
                      type="text" 
                      variant="outlined" 
                      name="email" 
                      className="email-id widthSet"
                      value={applicationById?.parent_name}
                      required
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PersonIcon fontSize='small' />
                          </InputAdornment>
                        ),
                      }}
                    />
                </div>
                {applicationById?.status === "enrolled" &&
                <div className="input-box-mobile marginLeftInP">
                  <InputLabel className="input-label-text">Parent's Contact Number</InputLabel>
                  <div style={{display: 'flex'}}>
                    <TextField className="country-code" value="+91" sx={{color: 'rgb(0, 0, 0)'}}></TextField>
                    <TextField 
                      placeholder="" 
                      type="text" 
                      variant="outlined" 
                      name="addr"
                      className="name-field parentsPhone" 
                      value={applicationById?.parent_phone_number}
                      required
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PhoneIcon fontSize='small' />
                          </InputAdornment>
                        ),
                      }}
                    />   
                  </div>                  
                </div>}
              </div>
              {applicationById?.documents?.length > 0 &&
                <div>
                  <InputLabel className="input-label-text">Documents</InputLabel>
                  {applicationById?.documents.map((item, i) => (
                    <div key={i} style={{
                      width: '214px',
                      height: '214px',
                      display: 'inline-block',
                      border: '1px solid rgba(0, 0, 0, 0.5)',
                      borderRadius: '8px',
                      margin: '8px'
                    }}>
                      <img src={item?.link} alt={`${item?.title}`} style={{
                        width: '100%',
                        height: '100%',
                        borderRadius: '8px',
                        cursor: 'pointer'
                      }} onClick={() => showDocument(item?.link)} />
                    </div>
                  ))}
                </div>}
            </form>
            {applicationById?.status === "enrolled" &&
            <div className="rsponsive-billing-box" style={{
              marginTop: '12px'
            }}>
              <div className="billing-box-1">
                <div className="billing-form-box" style={{marginTop: '0'}}>
                  <div className="total-heading">
                      <div style={{
                        position: 'relative',
                      }}>
                        <h2>Billing Details</h2>
                        <div className="line"></div>
                      </div>
                      <div className={classes.marginBot24} style={{marginTop: '28px'}}>
                        <InputLabel className={classes.formInputLabel}>
                          Name
                        </InputLabel>
                        <TextField placeholder="" type="text" variant="outlined" name="name" 
                            InputProps={{
                              startAdornment: <PersonIcon position="start" fontSize='small'
                              sx={{color: 'rgb(0, 0, 0, 0.4)'}}></PersonIcon>,
                            }}
                            sx={{
                              width: '100%',
                              height: '35px'
                            }}value="" required/>
                      </div>
                      <div className={classes.marginBot24}>
                        <InputLabel className={classes.formInputLabel}>
                            Email ID
                        </InputLabel>
                        <div style={{
                                position: 'relative',
                            }}>
                            <TextField 
                              placeholder="" 
                              type="email" 
                              variant="outlined" 
                              sx={{
                                  width: '100%',
                                  height: '35px'
                              }} 
                              InputProps={{
                                  startAdornment: <EmailIcon position="start" fontSize='small'
                                  sx={{color: 'rgb(0, 0, 0, 0.4)'}}></EmailIcon>,
                              }}
                              name="email" 
                              value=""
                              required/>
                        </div>
                      </div>
                      <div className={classes.marginBot24}>
                          <InputLabel className={classes.formInputLabel}>Phone number</InputLabel>
                          <div style={{
                              display: 'flex'
                          }}>
                              <div style={{
                                  width: '100%',
                                  position: 'relative',
                                  display: 'flex',
                              }} className="input-box-appl-detail">
                                  <TextField 
                                      variant="outlined" 
                                      value="+91"
                                      sx={{
                                          width: '12%',
                                          height: '35px',
                                          borderRadius: 'none'
                                      }}/>
                                  <TextField 
                                      type="number" 
                                      variant="outlined" 
                                      sx={{
                                          width: '88%',
                                          height: '35px',
                                          borderRadius: 'none'
                                      }} 
                                      InputProps={{
                                          startAdornment: <LocalPhoneIcon position="start" fontSize='small'
                                          sx={{color: 'rgb(0, 0, 0, 0.4)'}}></LocalPhoneIcon>,
                                      }}
                                      name="phone-number" 
                                      value=""
                                      required/>
                              </div>
                          </div>
                      </div>
                      <div className={classes.marginBot24}>
                        <InputLabel className={classes.formInputLabel}>
                            Address
                        </InputLabel>
                        <TextField 
                            InputProps={{
                                startAdornment: <RoomIcon position="start" fontSize='small'
                                sx={{color: 'rgb(0, 0, 0, 0.4)'}}></RoomIcon>,
                            }}
                            type="text" 
                            variant="outlined" 
                            sx={{
                                width: '100%',
                                height: '35px'
                            }} 
                            className="input-box-appl-detail"
                            value=""/>
                      </div>
                      <div className="count-city-box">
                        <div className={classes.marginBot24}>
                          <InputLabel className={classes.formInputLabel}>
                              Country
                          </InputLabel>
                          <TextField 
                              InputProps={{
                                startAdornment: <PublicIcon position="start" fontSize='small'
                                sx={{color: 'rgb(0, 0, 0, 0.4)'}}></PublicIcon>,
                              }}
                              type="text" 
                              variant="outlined" 
                              sx={{
                                width: '100%',
                                height: '35px'
                              }} 
                              className="input-box-appl-detail"
                              value=""/>
                        </div>
                        <div className={classes.marginBot24} style={{marginLeft: '12px'}}>
                          <InputLabel className={classes.formInputLabel}>
                              City
                          </InputLabel>
                          <TextField 
                              InputProps={{
                                  startAdornment: <HomeWorkIcon position="start" fontSize='small'
                                  sx={{color: 'rgb(0, 0, 0, 0.4)'}}></HomeWorkIcon>,
                              }}
                              type="text" 
                              variant="outlined" 
                              sx={{
                                  width: '100%',
                                  height: '35px'
                              }} 
                              className="input-box-appl-detail"
                              value=""/>
                        </div>
                      </div>
                      <div className="count-city-box">
                        <div className={classes.marginBot24}>
                          <InputLabel className={classes.formInputLabel}>
                              Zipcode
                          </InputLabel>
                          <TextField 
                              InputProps={{
                                startAdornment: <CreateIcon position="start" fontSize='small'
                                sx={{color: 'rgb(0, 0, 0, 0.4)'}}></CreateIcon>,
                              }}
                              type="text" 
                              variant="outlined" 
                              sx={{
                                width: '100%',
                                height: '35px'
                              }} 
                              className="input-box-appl-detail"
                              value=""/>
                        </div>
                        <div className={classes.marginBot24} style={{marginLeft: '12px'}}>
                          
                        </div>
                      </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="billing-box-2" style={{marginTop: '0'}}>
                  <div className="total-heading">
                      <div style={{
                          position: 'relative',
                      }}>
                          <h2>Order Summary</h2>
                          <div className="line"></div>
                      </div>
                  </div>
                  <div style={{
                      borderBottom: '1px solid rgba(0, 0, 0, 0.15)'
                  }} className="fees-ariaa">
                      <div className="product-value-count">
                          <p>Subtotal:</p>
                          <p>Rs {applicationById?.fee_payment?.fees}</p>
                      </div>
                      <div className="product-value-count">
                          <p>Discount (-):</p>
                          <p>
                            {applicationById?.fee_payment?.discount > 0 ?
                              (applicationById?.is_percentage_discount
                                ? `${applicationById?.fee_payment?.discount}%` : 
                                `Rs ${applicationById?.fee_payment?.discount}`)
                            : `Rs 0`}
                          </p>
                      </div>
                      <div className="product-value-count">
                          <p>
                            GST {applicationById?.fee_payment?.gst_percent}% (+):
                          </p>
                          <p>{applicationById?.fee_payment?.gst_percent > 0 ?
                              (`${applicationById?.fee_payment?.gst_percent}%`)
                            : 0}</p>
                      </div>
                  </div>
                  <div className="product-value-count grand-total">
                      <p>Total:</p>
                      <p>Rs {applicationById?.fee_payment?.total_amount}</p>
                  </div>
                  <p className="special-text">
                      Lorem ipsum dolor sit amet consectetur. Sollicitudin sed elementum dolor diam id neque.Lorem ipsum dolor sit amet consectetur. Sollicitudin 
                  </p>
                  <div style={{display: 'flex', justifyContent: 'center', marginTop: '24px'}}>
                      <Button 
                        type="button" 
                        sx={{
                          textTransform: 'capitalize',
                          color: 'white',
                          backgroundColor: '#07AB35',
                          '&:hover':{
                            backgroundColor: '#07AB35',
                          }
                        }}
                        className="checkout-btn pay-now">
                          Paid
                      </Button>
                  </div>
                </div>
              </div>
            </div>}
        </div>
      </div>
    </>)
}