import * as React from 'react';
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Typography } from '@mui/material';
import HomeLayout from "../homeLayout/HomeLayout";
import parse from 'html-react-parser';
import ScheduleIcon from '@mui/icons-material/Schedule';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import './student.css';

export default function ApplicationDetails() {
  const { studentApplicationById } = useSelector((state) => state.student);
  
  return (
    <>
      <HomeLayout />
      <div className="application-detail-box" style={{overflow: 'hidden'}}>
          <Typography component="h2" variant="h2" sx={{
            color: 'var(--primary-color)',
            fontWeight: 'bold'
          }} className="fontSizeName">
            {studentApplicationById?.add_on_id?.name}
            {studentApplicationById?.sub_product_id?.title}
          </Typography>
          <div style={{
            display: 'flex',
            marginTop: '24px',
            marginBottom: '12px'
          }}>
            {studentApplicationById?.sub_product_id?.duration &&
            <div style={{
              color: '#07AB35',
              display: 'flex',
              alignItems: 'center',
              fontSize: '18px'
            }}>
              <ScheduleIcon />
              <span style={{marginLeft: '8px'}}>
                {studentApplicationById?.sub_product_id?.duration} Years
              </span>
            </div>}
            <div style={{
              color: '#3C9E98',
              display: 'flex',
              alignItems: 'center',
              fontSize: '18px',
              marginLeft: '24px'
            }}>
              <ImportContactsIcon />
              <span style={{marginLeft: '8px'}}>
                {studentApplicationById?.courses?.toString()}
              </span>
            </div>
          </div>
          <div>
            <div style={{
              display: 'flex',
              alignItems: 'center',
              fontSize: '18px'
            }}>
              <span style={{color: 'rgb(0, 0, 0, 0.5)'}}>Category</span>
              <span style={{
                marginLeft: '8px',
                color: '#ECAB2D'
                }}>Medical</span>
            </div>
            <div style={{
              display: 'flex',
              alignItems: 'center',
              fontSize: '18px',
              marginTop: '8px'
            }}>
              <span style={{color: 'rgb(0, 0, 0, 0.5)'}}>Type :</span>
              <span style={{
                marginLeft: '8px',
                color: '#ECAB2D'
                }}>{studentApplicationById?.add_on_id?.product_type}</span>
            </div>
          </div>
          <Typography component="p" variant="p" className='c-d'>
            {studentApplicationById?.sub_product_id && 
              parse(studentApplicationById?.sub_product_id?.description)}
            {studentApplicationById?.add_on_id && 
              parse(studentApplicationById?.add_on_id?.description)}
          </Typography>
          <div>
            <div style={{
              display: 'flex',
              alignItems: 'center',
              fontSize: '18px'
            }}>
              <span style={{color: '#07AB35'}}>Completed on: </span>
              <span style={{
                marginLeft: '8px',
                fontWeight: 'bold',
                }}>2nd Aprril, 2023</span>
            </div>
            <div style={{
              display: 'flex',
              alignItems: 'center',
              fontSize: '18px',
              marginTop: '8px'
            }}>
              <span style={{fontWeight: 'bold'}}>
                Online Classroom Link:
              </span>
              <Link to="" style={{
                marginLeft: '8px',
                color: '#3C1CDE',
                fontWeight: 'bold',
                }} className='tdu'>Join Classroom</Link>
            </div>
          </div>
      </div>
    </>
  );
}