import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import parse from 'html-react-parser';
import { Link } from "react-router-dom";
import routes from "../../react_routes_enum";
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import Dialog from "@mui/material/Dialog";
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CloseIcon from '@mui/icons-material/Close';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import EastIcon from '@mui/icons-material/East';
import Spinner from "../../components/Spinner/Spinner";
import EmptyCart from "../../assest/images/empty_cart.png";
import LoaderIcon from "../../components/Loader/Loader";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { 
    deletFromCart,
    addToCart,
    decrementFromCart,
    getFromCart } from "../../redux/students/studentSlice";
import "./cart.css";
import { toast } from "react-toastify";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#E1DFEE',
      color: '#000000',
      fontWeight: 'bold',
      fontSize: '20px'
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));

export default function Cart() {
    const [isBodyLoader, setIsBodyLoader] = React.useState(false);
    const {isLoading, cartData } = useSelector((state) => state.student);
    const [btnLoader, setBtnLoader] = React.useState(false);
    const [orderId, setOrderId] = React.useState("");
    const [paymentId, setPaymentId] = React.useState("");
    const [userData, setUserData] = React.useState({});
    const [isOpen, setIsOpen] = React.useState(false);
    const [paymentError, setPaymentError] = React.useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const quantityChange = (type, id, itemType) => {
        if(type === "-"){
            const res = dispatch(decrementFromCart({id}))
            res.then((r) => {
                if(r?.payload?.statusCode === 200) {
                    dispatch(getFromCart());
                    toast.success("Quantity Decreased")
                }else{
                    toast.error(r?.payload?.message);
                }
            })
        }else{
            const res = itemType === "addons" ? dispatch(addToCart({add_on_id: id})) : 
                dispatch(addToCart({sub_product_id: id}))
            res.then((r) => {
                if(r?.payload?.statusCode === 200) {
                    dispatch(getFromCart());
                    toast.success("Quantity Increased")
                }else{
                    toast.error(r?.payload?.message);
                }
            })
        }
    }
    const removeItem = (id) => {
        setIsBodyLoader(true);
        const res = dispatch(deletFromCart({id}));
        res.then((r) => {
            setIsBodyLoader(false);
            if(r?.payload?.statusCode === 200){
                dispatch(getFromCart());
                toast.success("Item removed successfully");
            }else{
                toast.error(r?.payload?.message);
            }
        })
    }
    const checkout = () => {
        if(cartData === null || cartData?.length === 0){
            toast.error("Your cart is empty");
            return;
        }
        navigate(routes.CHECKOUT);
    }
    const handleClose = () => {
        setIsOpen(false);
    }
    const totalPrice = (data) => {
        let subTotal = data?.reduce((total, item) => {
            return total + (item?.price*item?.qty);
        }, 0);

        let discount = cartData?.reduce((total, item) => {
            return total + (item?.discount*item?.qty)
        }, 0);
        let gst = cartData?.reduce((total, item) => {
            return total + (item?.gst_percent*item?.qty)
        }, 0);
        let total = parseInt(subTotal) - parseInt(discount) + parseInt(gst);
        return {subTotal, discount, gst, total};
    
    }
    React.useEffect(() => {
        setUserData(JSON.parse(localStorage.getItem('cc_user')));
        if(localStorage.getItem('fromBuy') === "buy"){
            checkout();
        }
    },[])

    const getInstituteName = (id) => {
        let universityName = ""
        return universityName;
    }

    if (isLoading && isBodyLoader) return <Spinner />;

    return (
        <>
            <Dialog maxWidth="sm" open={isOpen} onClose={handleClose} fullWidth>
                <div className="payment-message-box">
                    {paymentError ?
                    <ErrorIcon sx={{
                        fontSize: '100px',
                        color: 'red'}}
                    /> :
                    <CheckCircleIcon sx={{
                        fontSize: '100px',
                        color: '#07ac07'}} />}
                    <p className="payment-success">
                        {paymentError ? 'Your Payment Faild' : 
                        'Your Payment Was Successful'}
                    </p>
                    <p className="payment-id">
                        Payment Id: {paymentId}
                    </p>
                    <p className="payment-id">
                        Order Id: {orderId}
                    </p>
                    <p className="payment-id" style={{
                        marginTop: '12px'
                    }}>
                        Thank you for your payment.
                    </p>
                </div>
            </Dialog>
            <Box sx={{p: 3}}>
                <div style={{
                    marginTop: '140px'
                }}>
                    <h4 className="add-to-cart">Add To Cart</h4>
                    <div className="cart-nav">
                        <p>Your Cart &nbsp;&nbsp;&nbsp; {'>'} &nbsp;&nbsp;&nbsp; Check Out &nbsp;&nbsp;&nbsp; {'>'} &nbsp;&nbsp;&nbsp; Pay Now &nbsp;&nbsp;&nbsp;</p>
                    </div>
                    {cartData === null || cartData?.length === 0 ?
                        <div style={{
                            textAlign: 'center', 
                            marginTop: '64px',
                            marginBottom: '64px'}}>
                            <img src={EmptyCart} alt="EmptyCart" />
                            <h4 className="your-cart">Your Cart Is Empty</h4>
                            <Link to="/" className="explore-link">Explore The Product</Link>
                        </div>
                    :
                        <div className="cart-content-box">
                            <div className="cart-table-width">
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell>Image</StyledTableCell>
                                                <StyledTableCell>Product Details</StyledTableCell>
                                                <StyledTableCell>Price</StyledTableCell>
                                                <StyledTableCell>Quantity</StyledTableCell>
                                                <StyledTableCell>Action</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {cartData && cartData?.map((row, index) => (
                                                <StyledTableRow key={index}>
                                                    <StyledTableCell component="th" scope="row">
                                                        <div className="cart-item-image">
                                                            {(row?.add_on_id?.images_url && 
                                                                row?.add_on_id?.images_url?.length > 0) &&
                                                            <img 
                                                                src={row?.add_on_id?.images_url[0]} 
                                                                alt="productImageName" 
                                                                className="product-image" 
                                                                />}
                                                        </div>
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        <h3 className="table-product-name">
                                                            {row?.add_on_id && row?.add_on_id?.name}
                                                            {row?.sub_product_id && row?.sub_product_id?.title}
                                                        </h3>
                                                        <p className="table-product-type">
                                                            {row?.add_on_id && row?.add_on_id?.product_type}
                                                            {row?.sub_product_id && 
                                                                row?.sub_product_id?.subjects?.map((item, ind) => (
                                                                    `${item}${(ind !== row?.sub_product_id?.subjects?.length - 1) ? ', ' : ''}`
                                                                ))}
                                                            <span>
                                                                {getInstituteName(
                                                                    row?.sub_product_id?.university_id ||
                                                                    row?.add_on_id?.university_id ||
                                                                    row?.sub_product_id?.coaching_id ||
                                                                    row?.add_on_id?.coaching_id
                                                                    )}
                                                            </span>
                                                        </p>
                                                        <p className="table-product-dec">
                                                        {row?.add_on_id?.description && 
                                                            parse(row?.add_on_id?.description)}
                                                        {row?.sub_product_id?.description && 
                                                            parse(row?.sub_product_id?.description)}
                                                        </p>
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        <p className="table-product-price">
                                                            Rs {row?.total_payable}
                                                        </p>
                                                        <del className="deleted-price">
                                                            Rs {row?.price}
                                                        </del>
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        <div style={{
                                                            display: 'flex',
                                                            alignItems: 'center'
                                                        }}>
                                                            <button type="button" className="btn-quant" 
                                                                onClick={() => {
                                                                    quantityChange(
                                                                        "-", 
                                                                        row?._id,
                                                                        row?.add_on_id ? "addons" : "subproduct")
                                                                    }}>-</button>
                                                            <span className="table-product-quantity">{row.qty}</span>
                                                            <button type="button" className="btn-quant"
                                                                onClick={() => {
                                                                    quantityChange(
                                                                        "+", 
                                                                        row?.add_on_id ? row?.add_on_id?._id : row?.sub_product_id?._id, 
                                                                        row?.add_on_id ? "addons" : "subproduct")}}>+</button>
                                                        </div>
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        <button type="button" onClick={() => {removeItem(row?._id)}} className="product-btn-remove">
                                                            <CloseIcon fontSize="large" />
                                                        </button>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                            <div className="total-box">
                                <div className="total-aria">
                                    <div className="total-heading">
                                        <div style={{
                                            position: 'relative',
                                        }}>
                                            <h2>Cart Total</h2>
                                            <div className="line"></div>
                                        </div>
                                    </div>
                                    <div style={{
                                        borderBottom: '1px solid rgba(0, 0, 0, 0.15)'
                                    }}>
                                        <div className="product-value-count">
                                            <p>Subtotal:</p>
                                            <p>Rs&nbsp;
                                                <span id="subtotal_price">
                                                    {totalPrice(cartData)?.subTotal}
                                                </span>
                                            </p>
                                        </div>
                                        <div className="product-value-count">
                                            <p>Discount (-):</p>
                                            <p>Rs&nbsp;
                                                <span id="discount_amount">
                                                    {totalPrice(cartData)?.discount}
                                                </span>
                                            </p>
                                        </div>
                                        <div className="product-value-count">
                                            <p>GST % (+):</p>
                                            <p>Rs&nbsp;
                                                <span id="gst_amount">
                                                    {totalPrice(cartData)?.gst}
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="product-value-count grand-total">
                                        <p>Total:</p>
                                        <p>Rs&nbsp;
                                            <span id="total">
                                                {totalPrice(cartData)?.total}
                                            </span>
                                        </p>
                                    </div>
                                    <div>
                                        <button 
                                            type="button" 
                                            className="checkout-btn" 
                                            disabled={btnLoader}
                                            style={
                                                btnLoader ? {
                                                    background: 'rgb(0, 0, 0, 0.4)'
                                                } : {background: '#EF0000'}}
                                            onClick={checkout}>
                                            {btnLoader ? <LoaderIcon /> :
                                            <>Checkout &nbsp; <EastIcon /></>}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </Box>
        </>
    );
}
