import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Box,
  InputLabel,
  TextField,
} from "@mui/material";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import useRazorpay from "react-razorpay";
import PersonIcon from '@mui/icons-material/Person';
import routes from "../../react_routes_enum";
import { makeStyles } from "@mui/styles";
import EmailIcon from '@mui/icons-material/Email';
import PublicIcon from '@mui/icons-material/Public';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import CreateIcon from '@mui/icons-material/Create';
import validator from "validator";
import parse from 'html-react-parser';
import EastIcon from '@mui/icons-material/East';
import LoaderIcon from "../../components/Loader/Loader";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { addOrder, verifyOrder } from "../../redux/payment/paymentSlice";
import { toast } from "react-toastify";
import { getFromCart } from "../../redux/students/studentSlice";
import "./cart.css";

const useStyle = makeStyles({
    formInputLabel: {
        color: '#000 !important',
        fontSize: '14px',
        marginBottom: '4px'
    }
})

export default function Checkout() {
    const classes = useStyle();
    const dispatch = useDispatch();
    const Razorpay = useRazorpay();
    const navigate = useNavigate();
    const [btnLoader, setBtnLoader] = React.useState(false);
    const [userData, setUserData] = React.useState({});
    const [billingBody, setBillingBody] = React.useState({});
    const [orderId, setOrderId] = React.useState("");
    const [paymentId, setPaymentId] = React.useState("");
    const [isOpen, setIsOpen] = React.useState(false);
    const [paymentError, setPaymentError] = React.useState(false);
    const {isLoading, cartData, fromBuy } = useSelector((state) => state.student);
    React.useEffect(() => {
        const res = dispatch(getFromCart());
        res?.then((r) => {
            if(r?.payload?.statusCode === 200){
                return;
            }
            (r?.payload?.message === "Session Expired" || 
            r?.payload?.message === "Unauthorized") ?
            toast.error("Your session expired, please login"):
            toast.error(r?.payload?.message)
        })
    },[])

    React.useEffect(() => {
        setUserData(JSON.parse(localStorage.getItem('cc_user')));
        setBillingBody({
            name: JSON.parse(localStorage.getItem('cc_user'))?.student?.name,
            phone: JSON.parse(localStorage.getItem('cc_user'))?.student?.phone,
            email: JSON.parse(localStorage.getItem('cc_user'))?.student?.email,
            address: JSON.parse(localStorage.getItem('cc_user'))?.student?.address,
            country: "",
            city: "",
            zip_code: "",
        });
        if(fromBuy === "buy"){
            payNow();
        }
    },[])

    const payNow = () => {
        if(verifyUserData().isValid || (fromBuy === "buy")){
            let total = document.getElementById("total")?.innerText;
            setBtnLoader(true);
            const res = fromBuy === "buy" ? dispatch(addOrder()) : 
                dispatch(addOrder(billingBody));
            res?.then((order) => {
                if(order?.payload?.statusCode === 200){
                    const options = {
                        key: process.env.REACT_APP_RAZORPAY_KEY,
                        amount: parseInt(total)*100,
                        currency: "INR",
                        name: "College Clue",
                        description: "Transaction",
                        image: "https://college-clue-s3.s3.amazonaws.com/3KSG1684138059984-cclogo.svg",
                        order_id: order?.payload?.data?.order_id,
                        modal: {
                        ondismiss: async function () {
                            setBtnLoader(false);
                        },
                        },
                        handler: async function (response) {
                            let verificationDetail = {
                                razorpay_payment_id: response.razorpay_payment_id,
                                order_id: order?.payload?.data?.order_id,
                            };
                            setOrderId(order?.payload?.data?.order_id);
                            setPaymentId(response.razorpay_payment_id);
                            const verifyRes = dispatch(verifyOrder(verificationDetail));
                            verifyRes?.then((verify) => {
                                setBtnLoader(false);
                                if(verify?.payload?.statusCode === 200) {
                                    navigate(routes.CART);
                                    setPaymentError(false);
                                    setIsOpen(true);
                                    dispatch(getFromCart());
                                    toast.success("Your order successful");
                                }else{
                                    setPaymentError(true);
                                    setIsOpen(true);
                                    toast.error(verify.payload.message);
                                }
                            })
                        },
                        prefill: {
                            name: userData?.student?.name,
                            email: userData?.student?.email,
                            contact: userData?.student?.phone,
                        },
                        notes: {
                            address: "Razorpay Corporate Office",
                        },
                        theme: {
                            color: "#3399cc",
                        },
                    };

                    const razorpay = new Razorpay(options);
                    razorpay.on("payment.failed", function (response) {
                        toast.error("Payment Failed");
                    });
                    razorpay.open();
                }else{
                    setBtnLoader(false);
                    (order?.payload?.message === "Session Expired" || 
                    order?.payload?.message === "Unauthorized") ?
                    toast.error("Your session expired, please login"):
                    toast.error(order?.payload?.message)
                }
            })
        }else{
            toast.error(verifyUserData()?.message);
        }
    }

    const verifyUserData = () => {
        let status = {isValid: true, message: ""} 
        if(!billingBody?.name || billingBody?.name === ""){
            status.isValid = false;
            status.message = "Name Required";
            return status;
        }
        if(!billingBody?.email || billingBody?.email === ""){
            status.isValid = false;
            status.message = "Email Required";
            return status;
        }
        if(!validator.isEmail(billingBody?.email)){
            status.isValid = false;
            status.message = "Invalid email";
            return status;
        }
        if(!billingBody?.phone || billingBody?.phone === ""){
            status.isValid = false;
            status.message = "Phone Number Required";
            return status;
        }
        if(billingBody?.phone?.length !== 10){
            status.isValid = false;
            status.message = "Invalid Phone Number";
            return status;
        }
        if(!billingBody?.address || billingBody?.address === ""){
            status.isValid = false;
            status.message = "Address Required";
            return status;
        }
        if(!billingBody?.country || billingBody?.country === ""){
            status.isValid = false;
            status.message = "Country required";
            return status;
        }
        if(!billingBody?.city || billingBody?.city === ""){
            status.isValid = false;
            status.message = "City required";
            return status;
        }
        if(!billingBody?.zip_code || billingBody?.zip_code === ""){
            status.isValid = false;
            status.message = "Zipcode required";
            return status;
        }
        return status;
    }

    const totalPrice = (data) => {
        let subTotal = data?.reduce((total, item) => {
            return total + (item?.price*item?.qty);
        }, 0);

        let discount = cartData?.reduce((total, item) => {
            return total + (item?.discount*item?.qty)
        }, 0);
        let gst = cartData?.reduce((total, item) => {
            return total + (item?.gst_percent*item?.qty)
        }, 0);
        let total = parseInt(subTotal) - parseInt(discount) + parseInt(gst);
        return {subTotal, discount, gst, total};
    
    }

    const handleChange = (e) => {
        e.target.name === "name" && setBillingBody({...billingBody, name: e.target.value})
        e.target.name === "email" && setBillingBody({...billingBody, email: e.target.value})
        e.target.name === "phone" && setBillingBody({...billingBody, phone: e.target.value})
        e.target.name === "address" && setBillingBody({...billingBody, address: e.target.value})
        e.target.name === "country" && setBillingBody({...billingBody, country: e.target.value})
        e.target.name === "city" && setBillingBody({...billingBody, city: e.target.value})
        e.target.name === "zip_code" && setBillingBody({...billingBody, zip_code: e.target.value})
        e.target.name === "phone_country_code" && setBillingBody({...billingBody, phone_country_code: e.target.value})
    }
    return (
        <>
            <Box sx={{p: 3}}>
                <div style={{
                    marginTop: '140px',
                    marginBottom: '40px'
                }}>
                    <h4 className="add-to-cart">Checkout</h4>
                    <div className="cart-nav">
                        <pre><span style={{color: 'var(--secondary-color)'}}>Your Cart     {'>'}    Check Out    {'>'}</span>    Pay Now</pre>
                    </div>
                    <div className="checkout-content-box">
                        <div>
                            <div className="total-gen-box-s">
                                <div className="total-heading">
                                    <div style={{
                                        position: 'relative',
                                    }}>
                                        <h2>Billing Details</h2>
                                        <div className="line"></div>
                                    </div>
                                </div>
                                <div>
                                    <form>
                                        <div className="input-aria">
                                            <InputLabel className={classes.formInputLabel}>
                                                Name
                                            </InputLabel>
                                            <TextField 
                                                placeholder="" 
                                                type="text" 
                                                variant="outlined" 
                                                name="name" 
                                                InputProps={{
                                                    startAdornment: <PersonIcon 
                                                    fontSize="small" position="start" 
                                                    sx={{
                                                        color: 'rgb(0, 0, 0, 0.4)',
                                                        marginRight: '4px'
                                                    }}></PersonIcon>,
                                                }}
                                                sx={{
                                                    width: '100%',
                                                    height: '35px',
                                                }} 
                                                value={billingBody?.name}
                                                onChange={handleChange}
                                                required/>
                                        </div>
                                        <div className="input-aria">
                                            <InputLabel className={classes.formInputLabel}>
                                                Email
                                            </InputLabel>
                                            <TextField 
                                                placeholder="" 
                                                type="text" 
                                                variant="outlined" 
                                                InputProps={{
                                                    startAdornment: <EmailIcon 
                                                    fontSize="small" 
                                                    position="start" 
                                                    sx={{
                                                        color: 'rgb(0, 0, 0, 0.4)',
                                                        marginRight: '4px'
                                                    }}></EmailIcon>,
                                                }}
                                                sx={{
                                                    width: '100%',
                                                    height: '35px',
                                                }} 
                                                name="email" 
                                                value={billingBody?.email}
                                                onChange={handleChange}
                                                required/>
                                        </div>
                                        <div className="input-aria">
                                            <InputLabel className={classes.formInputLabel}>Phone number</InputLabel>
                                            <div style={{
                                                display: 'flex'
                                            }}>
                                                <div style={{
                                                    width: '100%',
                                                    position: 'relative',
                                                    display: 'flex',
                                                }} className="input-box-appl-detail">
                                                    {/* <TextField 
                                                        variant="outlined" 
                                                        value="+91"
                                                        sx={{
                                                            width: '12%',
                                                            height: '35px',
                                                            borderRadius: 'none'
                                                        }}/> */}
                                                    <TextField 
                                                        type="number" 
                                                        variant="outlined" 
                                                        sx={{
                                                            width: '100%',
                                                            height: '36px',
                                                            borderRadius: 'none'
                                                        }} 
                                                        InputProps={{
                                                            startAdornment: <LocalPhoneIcon fontSize="small" position="start" 
                                                            sx={{
                                                                color: 'rgb(0, 0, 0, 0.4)',
                                                                marginRight: '4px'
                                                            }}></LocalPhoneIcon>,
                                                        }}
                                                        name="phone" 
                                                        value={billingBody?.phone}
                                                        onChange={handleChange}
                                                        required/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="input-aria">
                                            <InputLabel className={classes.formInputLabel}>
                                                Address
                                            </InputLabel>
                                            <TextField 
                                                placeholder="" 
                                                type="text" 
                                                variant="outlined" 
                                                name="address"
                                                InputProps={{
                                                    startAdornment: <LocationOnIcon 
                                                    fontSize="small" 
                                                    position="start" 
                                                    sx={{
                                                        color: 'rgb(0, 0, 0, 0.4)',
                                                        marginRight: '4px'
                                                    }}></LocationOnIcon>,
                                                }}
                                                sx={{
                                                    width: '100%',
                                                    height: '35px',
                                                }} 
                                                value={billingBody?.address}
                                                onChange={handleChange}
                                                required/>
                                        </div>
                                        <div className="count-city">
                                            <div className="input-aria city-aria-common">
                                                <InputLabel className={classes.formInputLabel}>
                                                    Country
                                                </InputLabel>
                                                <TextField 
                                                    placeholder="" 
                                                    type="text" 
                                                    variant="outlined" 
                                                    InputProps={{
                                                        startAdornment: <PublicIcon 
                                                        fontSize="small" 
                                                        position="start" 
                                                        sx={{
                                                            color: 'rgb(0, 0, 0, 0.4)',
                                                            marginRight: '4px'
                                                        }}></PublicIcon>,
                                                    }}
                                                    sx={{
                                                        width: '100%',
                                                        height: '35px',
                                                    }}
                                                    name="country"
                                                    value={billingBody?.country}
                                                    onChange={handleChange}
                                                    required/>
                                            </div>
                                            <div className="input-aria city-aria city-aria-common">
                                                <InputLabel className={classes.formInputLabel}>
                                                    City
                                                </InputLabel>
                                                <TextField 
                                                    placeholder="" 
                                                    type="text" 
                                                    variant="outlined"
                                                    InputProps={{
                                                        startAdornment: <HomeWorkIcon 
                                                        fontSize="small" 
                                                        position="start" 
                                                        sx={{
                                                            color: 'rgb(0, 0, 0, 0.4)',
                                                            marginRight: '4px'
                                                        }}></HomeWorkIcon>,
                                                    }}
                                                    sx={{
                                                        width: '100%',
                                                        height: '35px',
                                                    }} 
                                                    name="city"
                                                    value={billingBody?.city}
                                                    onChange={handleChange}
                                                    required/>
                                            </div>
                                        </div>
                                        <div className="input-aria city-aria-common">
                                            <InputLabel className={classes.formInputLabel}>
                                                Zip Code
                                            </InputLabel>
                                            <TextField 
                                                placeholder="" 
                                                type="number" 
                                                variant="outlined"
                                                InputProps={{
                                                    startAdornment: <CreateIcon 
                                                    fontSize="small" 
                                                    position="start" 
                                                    sx={{
                                                        color: 'rgb(0, 0, 0, 0.4)',
                                                        marginRight: '4px'
                                                    }}></CreateIcon>,
                                                }}
                                                sx={{
                                                    width: '100%',
                                                    height: '35px',
                                                }} 
                                                name="zip_code"
                                                value={billingBody?.zip_code}
                                                onChange={handleChange}
                                                required/>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="checkout-two-boxes">
                            <div className="total-gen-box">
                                <div className="total-heading">
                                    <div style={{
                                        position: 'relative',
                                    }}>
                                        <h2>Order Details</h2>
                                        <div className="line"></div>
                                    </div>
                                </div>
                                <div>
                                    {cartData && cartData?.map((item, index) => (
                                        <div key={index} style={{display: 'flex', marginBottom: '12px'}}>
                                            <div className="cart-item-image">
                                                {(item?.add_on_id?.images_url && 
                                                    item?.add_on_id?.images_url?.length > 0) &&
                                                <img 
                                                    src={item?.add_on_id?.images_url[0]} 
                                                    alt="productImageName" 
                                                    className="item-image"
                                                    style={{
                                                        width: '100%',
                                                        height: '100%',
                                                        borderRadius: '5px'
                                                    }} 
                                                    />}
                                            </div>
                                            <div style={{marginLeft: '8px'}}>
                                                <h3 className="table-product-name item-name">
                                                    {item?.add_on_id && item?.add_on_id?.name}
                                                    {item?.sub_product_id && item?.sub_product_id?.title}
                                                </h3>
                                                <p className="table-product-type item-type">
                                                    {item?.add_on_id && item?.add_on_id?.product_type}
                                                    {item?.sub_product_id && 
                                                        item?.sub_product_id?.subjects?.map((itm, ind) => (
                                                        `${itm}${(ind !== item?.sub_product_id?.subjects?.length - 1) ? ', ' : ''}`
                                                    ))}
                                                </p>
                                                <p className="table-product-dec item-desc">
                                                    {item?.add_on_id?.description && 
                                                        parse(item?.add_on_id?.description)}
                                                    {item?.sub_product_id?.description && 
                                                        parse(item?.sub_product_id?.description)}
                                                </p>
                                                <p className="table-product-price item-price">
                                                    <p className="table-product-price" style={{fontSize: '12px'}}>
                                                        Rs {item?.total_payable}
                                                    </p>
                                                </p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="total-gen-box">
                                <div className="total-heading">
                                    <div style={{
                                        position: 'relative',
                                    }}>
                                        <h2>Order Summary</h2>
                                        <div className="line"></div>
                                    </div>
                                </div>
                                <div style={{
                                    borderBottom: '1px solid rgba(0, 0, 0, 0.15)'
                                }}>
                                    <div className="product-value-count">
                                        <p>Subtotal:</p>
                                        <p>
                                            Rs {totalPrice(cartData)?.subTotal}
                                        </p>
                                    </div>
                                    <div className="product-value-count">
                                        <p>Discount (-):</p>
                                        <p>
                                            Rs {totalPrice(cartData)?.discount}
                                        </p>
                                    </div>
                                    <div className="product-value-count">
                                        <p>GST % (+):</p>
                                        <p>
                                            Rs {totalPrice(cartData)?.gst}
                                        </p>
                                    </div>
                                </div>
                                <div className="product-value-count grand-total">
                                    <p>Total:</p>
                                    <p>Rs&nbsp;
                                        <span id="total">
                                            {totalPrice(cartData)?.total}
                                        </span>
                                    </p>
                                </div>
                                <p className="special-text">
                                    Lorem ipsum dolor sit amet consectetur. Sollicitudin sed elementum dolor diam id neque.Lorem ipsum dolor sit amet consectetur. Sollicitudin 
                                </p>
                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                    <button 
                                        type="button" 
                                        disabled={btnLoader}
                                        style={
                                            btnLoader ? {
                                                background: 'rgb(0, 0, 0, 0.4)'
                                            } : {background: '#07AB35'}}
                                        className="checkout-btn pay-now" 
                                        onClick={payNow}>
                                        {btnLoader ? <LoaderIcon /> :
                                            <>Pay Now &nbsp; <EastIcon /></>}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Box>
        </>
    );
}
