import * as React from 'react';
import Carousel from 'react-elastic-carousel';
import Moment from "moment";
import parse from 'html-react-parser';
import "../instituteCard/instituteCard.css";
import "./newCarousel.css"

const BlogCarousel = (props) => {

    return (
        <Carousel breakPoints={props.reviewBreakPoints} className="new-carousel">
          {props.data.map((item, index) => (
            <div key={index} className="review-box review-box2" style={{
                height: '592px',
                width: '405px',
                paddingTop: 0,
                borderRadius: '4px',
                border: 'none',
            }}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                    <div style={{width: '100%'}}>
                        <img src={item?.image} alt="icon" style={{
                            width: '100%',
                            height: '260px',
                            borderTopLeftRadius: '4px',
                            borderTopRightRadius: '4px'
                        }} />
                    </div>
                    <div className='blog-detail-aria'>
                        <h4>{item?.title}</h4>
                        <div style={{
                            marginTop: '12px',
                            height: '128px',
                            color: 'rgba(0, 0, 0, 0.5)',
                            overflowY: 'scroll'
                        }} className="blog-detail-desc">
                            {parse(item?.text)}
                            {/* {item?.text?.length >= 180 ?
                            <>
                                {itemIndex === index ? parse(item?.text) : 
                                parse(item?.text)}
                                {itemIndex !== index ?
                                <span style={{
                                    color: '#C32248',
                                    cursor: 'pointer'
                                }} onClick={() => setItemIndex(index)}>
                                    {" "}read more...
                                </span>
                                :
                                <span style={{
                                    color: '#C32248',
                                    cursor: 'pointer'
                                }} onClick={() => setItemIndex(-1)}>
                                    {" "}read less...
                                </span>}
                            </>
                            :
                            <>
                                {parse(item?.text)}
                            </>
                            } */}
                        </div>
                        <div style={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: '28px'
                        }} className="blog-auth-info">
                            <div style={{
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                {item?.author_image &&
                                <img src={item?.image} style={{
                                    borderRadius: '50%'
                                }} />}
                                {item?.author_name &&
                                <p style={{
                                    margin: 0,
                                    marginLeft: '12px',
                                    color: 'rgba(0, 0, 0, 0.5)'
                                }}>John Cameron</p>}
                            </div>
                            <p style={{
                                margin: 0,
                                color: 'rgba(0, 0, 0, 0.5)',
                                }}>
                                {Moment(item?.date).format('Do MMMM YYYY')}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
          ))}
        </Carousel>
    )
}

export default BlogCarousel;