import * as React from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  Typography,
  InputLabel,
  Button,
  TextField,
} from "@mui/material";
import validator from "validator";
import Dialog from "@mui/material/Dialog";
import { validateUrl } from "../../utils/validateUrl";
import { logout, reset } from "../../redux/auth/authSlice";
import PartnerWithUs from "../../assest/images/partner-with-us.png";
import InputAdornment from '@mui/material/InputAdornment';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import PersonIcon from '@mui/icons-material/Person';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { HashLink } from "react-router-hash-link/dist/react-router-hash-link.cjs.development";
import PartnerWithUsBack from "../../assest/images/partner-with-head-back.png";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import PartnerFormImage from "../../assest/images/partnerFormImage.jpeg";
import SecondaryHead from "../../components/SecondaryHead/SecondaryHead";
import whyUsImage from "../../assest/svgs/why-choose-us.svg";
import VideoImage from "../../assest/images/video-image.png";
import CoachingImage from "../../assest/images/coaching-partner.png";
import UniversityImage from "../../assest/images/univ-partner.png";
import ConsultyUniv from "../../assest/images/consulty_univ.png";
import EnabledIcon from "../../assest/svgs/enabled_icon.svg";
import NetworkChallengesIcon from "../../assest/svgs/network_challenges.svg";
import CommissionIcon from "../../assest/svgs/commission_icon.svg";
import Supporticon from "../../assest/svgs/support_icon.svg";
import ApplicationIcon from "../../assest/svgs/application_icon.svg";
import TickIcon from "../../assest/svgs/tick_icon.svg";

import PrepClueIcon from "../../assest/svgs/prep_clue_icon.svg";
import LmsIcon from "../../assest/svgs/lms_icon.svg";
import StudyMaterialIcon from "../../assest/svgs/study_material_icon.svg";
import OnlineOfflineBatchIcon from "../../assest/svgs/online_offline_batch_icon.svg";
import StudentCoachIcon from "../../assest/svgs/student_coach_icon.svg";
import UploadBox from "../../components/UploadBox/uploadBox";
import BenifitImage from "../../assest/images/benifit-image.png";
import PartnerWithCoach from "../../assest/images/partner-with-coach.png";
import PartnerWithImage1 from "../../assest/images/partner-with-image-1.png";
import PartnerWithImage2 from "../../assest/images/partner-with-image-2.png";
import {addContactUs} from "../../redux/students/studentSlice";
import LoaderIcon from "../../components/Loader/Loader";
import "./contact.css";
import { toast } from "react-toastify";
import routes from "../../react_routes_enum";

const sliderCont = [
    {
        image: PartnerWithUs,
        text: ['Become', 'Prepclue', 'Partner']
    }
]

const helpContent = [
    {
        icon: NetworkChallengesIcon,
        text: "Wide Network of Coaching and Consultancies",
        textColor: '#20C997'
    },
    {
        icon: EnabledIcon,
        text: "Tech-Enabled Platform",
        textColor: '#339AF0'

    },
    {
        icon: ApplicationIcon,
        text: "Transparent Application Process",
        textColor: '#ECAB2D'
    },
    {
        icon: Supporticon,
        text: "Reach Students all over India with 24X7 support",
        textColor: '#CC5DE8'
    },
    {
        icon: CommissionIcon,
        text: "Increrase Revenue x 10",
        textColor: '#117C18'
    }
]

const helpCoachContent = [
    {
        icon: StudentCoachIcon,
        text: "Connect with students all over India",
        textColor: '#FA5252'
    },
    {
        icon: OnlineOfflineBatchIcon,
        text: "Sell online and offline batch course",
        textColor: '#94D82D'

    },
    {
        icon: StudyMaterialIcon,
        text: "Make your study material reach crores of students",
        textColor: '#1B499A'
    },
    {
        icon: LmsIcon,
        text: "Learning management system for everyone",
        textColor: '#E3760B'
    },
    {
        icon: PrepClueIcon,
        text: "Increase Revenue X 10",
        textColor: '#117C18'
    }
]

const UnivbenifitList = [
    "Free University Listing",
    "Promote the colleges and Universities",
    "Universities can sell their courses",
    "Personalized LMS for online courses and curriculum",
    "Record student attendance",
    "Take classes and give recorded lectures to students",
    "Conduct online exams, assessments and events",
    "Customized dashboards to measure students performances",
    "One-stop solution to connect with your students",
    "Admission portal for consultancies and colleges"
]

const CoachbenifitList = [
    "Free coaching center listing",
    "Comprehensive coaching materials",
    "Customizable technology-enabled solutions",
    "Marketing and promotional support",
    "Record student attendance",
    "Competitive and Affordable pricing",
    "Conduct online tests and quizes",
    "Wide network of teachers and tutors",
    "Make your institute go digital with our solutions",
]

const submitBody = {
    name: "",
    email: "",
    phone: "",
    office_location: "",
    company_name: "",
    company_website: "",
    id_proof: "",
    firm_document: ""
}

export default function Partner() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = React.useState(false);
    const [btnLoader, setBtnLoader] = React.useState(false);
    const [formBody, setFormBody] = React.useState(submitBody);
    
    const submitForm = () => {
        if(validate()?.isValid){
            setBtnLoader(true);
            const res = dispatch(addContactUs(formBody));
            res?.then((r) =>{
                setBtnLoader(false);
                if(r?.payload?.statusCode === 200){
                    setFormBody({...formBody,
                        name: "",
                        email: "",
                        phone: "",
                        office_location: "",
                        company_name: "",
                        company_website: "",
                        id_proof: "",
                        firm_document: ""
                    });
                    toast.success("Message sent successfully");
                }else{
                    toast.error(r?.payload?.message);
                }
            })
        }
        else{
            toast.error(validate()?.message);
        }
    }

    const handleChange = (name, value) => {
        name === 'id_proof' && setFormBody({...formBody, id_proof: value});
        name === 'syllabus' && setFormBody({...formBody, firm_document: value});
    }
    const handleInputChange = (e) => {
        e.target.name === 'name' && setFormBody({...formBody, name: e.target.value});
        e.target.name === 'email' && setFormBody({...formBody, email: e.target.value});
        e.target.name === 'phone' && setFormBody({...formBody, phone: e.target.value});
        e.target.name === 'company_name' && setFormBody({...formBody, company_name: e.target.value});
        e.target.name === 'office_location' && setFormBody({...formBody, office_location: e.target.value});
        e.target.name === 'company_website' && setFormBody({...formBody, company_website: e.target.value});
    }
    const validate = () => {
        let status = {isValid: true, message: ""};
        if(formBody?.name === ""){
            status.message = 'Name Required';
            status.isValid = false;
            return status;
        }
        if(formBody?.email === ""){
            status.message = 'Email Required';
            status.isValid = false;
            return status;
        }
        if(!validator.isEmail(formBody?.email)){
            status.message = 'Invalid Email Entered';
            status.isValid = false;
            return status;
        }
        if(formBody?.phone === ""){
            status.message = 'Phone Required';
            status.isValid = false;
            return status;
        }
        if(formBody?.phone?.length !== 10){
            status.message = 'Invalid Phone Number';
            status.isValid = false;
            return status;
        }
        if(formBody?.company_name === ""){
            status.message = 'Company Name Required';
            status.isValid = false;
            return status;
        }
        if(formBody?.office_location === ""){
            status.message = 'Office Location Required';
            status.isValid = false;
            return status;
        }
        if(formBody?.company_website === ""){
            status.message = 'Company Website Link Required';
            status.isValid = false;
            return status;
        }
        if(!validateUrl(formBody?.company_website)){
            status.message = 'Invalid Link Entered';
            status.isValid = false;
            return status;
        }
        if(formBody?.id_proof === ""){
            status.message = 'Id Proof Required';
            status.isValid = false;
            return status;
        }
        if(formBody?.firm_document === ""){
            status.message = 'Firm Document Required';
            status.isValid = false;
            return status;
        }
        return status;
    }

    const goToPartner = () => {
        let user = JSON.parse(localStorage.getItem("cc_user"));
        if(user){
            setIsOpen(true);
        }else{
            localStorage.setItem("is-website-for", "partner");
            navigate(routes.PARTNER_REQUEST_FORM);
        }
    }

    const goToPartnerRequest = () => {
        dispatch(logout());
        dispatch(reset());
        localStorage.setItem("isRefresh", true);
        localStorage.setItem("is-website-for", "partner");
        navigate(routes.PARTNER_REQUEST_FORM);
    }
    
    return (
        <>
            <SecondaryHead 
                backImage={PartnerWithUsBack}
                headingH={'520px'}
                isDotted={false}
                isSlider={true}
                sliderContent={sliderCont}
                />
            <Box sx={{p: 3}}>
                <section className="about-college-clue">
                    <Typography component="h2" variant="h2" className="page-heading-2" style={{
                        textAlign: 'left',
                        position: 'relative'
                    }}>
                        About PrepClue
                        <div style={{
                            backgroundColor: 'rgb(246, 146, 32)',
                            borderRadius: '6px',
                            position: 'absolute',
                            zIndex: -1,
                        }} className="heading-bottom-line"></div>
                    </Typography>
                    <div className="why-us-box">
                        <div>
                            <Typography component="p" variant="p" className="why-us-text" style={{
                                color: 'rgb(0, 0, 0, 0.5)',
                                fontWeight: 'bold'
                            }}>
                                PrepClue strives to empower students in India by providing 
                                them with comprehensive and effective coaching for entrance 
                                exams, competitive exams. Our goal is to help students achieve 
                                their dreams and unlock their full potential.<br/>

                                With years of experience in the education industry, we 
                                understand the unique challenges that students face when 
                                preparing for exams. That's why we have created a robust 
                                curriculum, coupled with personalized coaching and 
                                mentorship, to help students achieve their goals.
                            </Typography>
                        </div>
                        <div className="why-us-image-box">
                            <img src={whyUsImage} alt="whyUsImage" className="why-us-image" />
                            <img src={VideoImage} alt="videoImage" className="video-image" />
                        </div>
                    </div>
                </section>
                <section className="uni-co-aria">
                    <HashLink to="/partner-with-us/#CoachingAria" style={{
                        width: '50%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        borderRight: '2px solid rgba(0, 0, 0, 0.3)',
                        color: '#000'
                    }}>
                        <img src={CoachingImage} alt="coachingImage" />
                        <p>Coaching Center</p>
                    </HashLink>
                    <Button onClick={goToPartner} style={{
                        width: '50%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        color: '#000',
                        textTransform: 'capitalize'
                    }} className="univ-icon-aria">
                        <img src={UniversityImage} alt="universityImage" />
                        <p>Channel Partners</p>
                    </Button>
                </section>
                {/* <section className="partner-consulty-big-box extr" 
                    id="UniversityAria">
                    <div>
                        <h2 className="partner-small-heading mobile-head">
                            Universities and Consultancies
                        </h2>
                        <img src={ConsultyUniv} alt="consultyImage" />
                    </div>
                    <div className="partner-consulty-box">
                        <h2 className="partner-small-heading laptop-head">
                            Universities and Consultancies
                        </h2>
                        <p>
                            We're thrilled that you're considering partnering with us to 
                            expand your reach and help more students achieve their dreams 
                            of studying abroad or at top domestic colleges.<br/>
                            We have been providing expert guidance and support to students 
                            for many years, and we've built a reputation for excellence 
                            in the industry. We understand the challenges that students 
                            face when seeking admission to top colleges and universities 
                            around the world, and we're committed to providing 
                            personalized assistance to help them succeed.<br/>
                            As a partner with us, you'll have access to our extensive 
                            network of admissions officers and educational institutions, 
                            as well as our team of experienced consultants who can provide 
                            guidance on everything from application essays to financial 
                            aid. You'll also benefit from our cutting-edge technology and 
                            tools that make the admission process more efficient and 
                            streamlined for you and your clients.<br/>
                            Partnering with us will enable you to provide your clients and 
                            students with an unparalleled level of support and expertise, 
                            helping them to make informed decisions about their 
                            educational future and achieve their academic and career 
                            goals. Plus, with our competitive commission structure, 
                            you'll be able to earn more revenue while providing a higher 
                            level of service to your clients.
                        </p>
                    </div>
                </section> */}
                {/* <section>
                    <h2 className="partner-small-heading" style={{
                        textAlign: 'center',
                    }}>
                        How we can help you
                    </h2>
                    <div>
                        <div className="help-box">
                            {helpContent.map((item, index) => (
                                <div key={index} style={{
                                    display: 'inline-block',
                                    margin: '12px 12px'
                                }} className="help-box-item">
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}>
                                        <img src={item.icon} alt="Icon" />
                                        <p style={{
                                            color: item.textColor,
                                            width: index === 1 && '140px'
                                        }} 
                                        className={`${index === 3 && 'width160'}`}>
                                            {item.text}
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section> */}
                {/* <section className="benifit-box">
                    <div className="benifit-image-aria">
                        <img src={BenifitImage} alt="BenifitImage" />
                    </div>
                    <div>
                        <h2 className="partner-small-heading" style={{
                            textAlign: 'center',
                        }}>
                            Benefits being Partner <br className="line-break" /> 
                            with PrepClue
                        </h2>
                        <p className="benifit-small-text">
                            Following are the benefits you will get after becoming 
                            partner with PrepClue.</p>
                        <ul>
                            {UnivbenifitList.map((item, index) => (
                                <li key={index}>
                                    <img src={TickIcon} alt="icon"/><span
                                    style={{
                                        marginLeft: '8px',
                                    }}>{item}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                </section> */}
                <section className="coaching-cent coaching-row-reverse" 
                    id="CoachingAria">
                    <div className="for-mob">
                        <h2 className="partner-small-heading mobile-head">
                            Coaching Center
                        </h2>
                        <img src={PartnerWithCoach} alt="consultyImage" />
                    </div>
                    <div className="for-web">
                        <div className="image-1">
                            <img src={PartnerWithImage1} alt="partnerImage" />
                        </div>
                        <div className="image-2">
                            <img src={PartnerWithImage2} alt="partnerImage" />
                        </div>
                    </div>
                    <div className="partner-consulty-box">
                        <h2 className="partner-small-heading laptop-head">
                            Coaching Center
                        </h2>
                        <p>
                            We are specialized in entrance coaching for competitive exams. 
                            Our goal is to provide students with the tools they need to 
                            succeed on these rigorous exams and gain admission to the 
                            schools, universities and jobs of their dreams.<br />
                            We believe that partnerships are key to achieving this goal. 
                            That's why we're looking to partner with coaching centers, 
                            institutes, and individuals who share our commitment to 
                            helping students succeed in different parts of India who 
                            don’t have access to high end technology.<br />
                            We are now expanding our services and are looking for partners 
                            who share our vision and commitment to excellence. If you are 
                            a coaching institute or consultancy looking to offer quality 
                            entrance coaching to your students, we would love to partner 
                            with you.<br />
                            Partnering with us will enable you to offer your students 
                            access to the best coaching materials and expertise available. 
                            Our goal is to help your consultancy stand out from the 
                            competition and achieve greater success.
                        </p>
                    </div>
                </section>
                <section className="help-big-box">
                    <h2 className="partner-small-heading" style={{
                        textAlign: 'center',
                    }}>
                        How we can help you
                    </h2>
                    <div>
                        <div className="help-box">
                            {helpCoachContent.map((item, index) => (
                                <div key={index} style={{
                                    display: 'inline-block',
                                    margin: '12px 12px'
                                }} className="help-box-item">
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}>
                                        <img src={item.icon} alt="Icon" />
                                        <p style={{
                                            color: item.textColor,
                                            width: index === 1 && '200px'
                                        }} 
                                        className={`${index === 3 && 'width160'}`}>
                                            {item.text}
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
                <section className="benifit-box">
                    <div className="benifit-image-aria">
                        <img src={BenifitImage} alt="BenifitImage" />
                    </div>
                    <div>
                        <h2 className="partner-small-heading" style={{
                            textAlign: 'center',
                        }}>
                            Benefits being Partner <br className="line-break" /> 
                            with PrepClue
                        </h2>
                        <p className="benifit-small-text">
                            Following are the benefits you will get after becoming 
                            partner with PreClue.</p>
                        <ul>
                            {CoachbenifitList.map((item, index) => (
                                <li key={index}>
                                    <img src={TickIcon} alt="icon"/><span
                                    style={{
                                        marginLeft: '8px',
                                    }}>{item}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                </section>
            </Box>
            <div className="partner-with-form">
                <div className="img-box">
                    <img src={PartnerFormImage} alt="FormImage" />
                    <p>
                        Procedure to Become a Partner with PrepClue
                    </p>
                </div>
                <div className="partner-form-box">
                    <div className="two-input-aria">
                        <div className="input-box-mobile input-box-aria">
                        <InputLabel className="input-label-text">Name</InputLabel>
                            <TextField 
                                placeholder="" 
                                type="text" 
                                variant="outlined" 
                                name="name"
                                className="name-field" 
                                onChange={handleInputChange}
                                value={formBody?.name}
                                required
                                InputProps={{
                                    startAdornment: (
                                    <InputAdornment position="start">
                                        <PersonIcon fontSize='small' />
                                    </InputAdornment>
                                    ),
                                }}
                            />                     
                        </div>
                        <div className="input-box-mobile marginLeftIn input-box-aria">
                        <InputLabel className="input-label-text">Email ID</InputLabel>
                            <TextField 
                            placeholder="" 
                            type="email" 
                            variant="outlined" 
                            name="email" 
                            className="name-field"
                            onChange={handleInputChange}
                            value={formBody?.email}
                            required
                            InputProps={{
                                startAdornment: (
                                <InputAdornment position="start">
                                    <EmailIcon fontSize='small' />
                                </InputAdornment>
                                ),
                            }}
                            />
                        </div>
                    </div>
                    <div className="two-input-aria">
                        <div className="input-box-mobile input-box-aria">
                        <InputLabel className="input-label-text">Contact Number</InputLabel>
                        <div style={{display: 'flex'}}>
                            {/* <TextField 
                                className="country-code" 
                                value="+91" 
                                sx={{color: 'rgb(0, 0, 0)'}}>
                            </TextField> */}
                            <TextField 
                                placeholder="" 
                                type="number" 
                                variant="outlined" 
                                name="phone"
                                onChange={handleInputChange}
                                className="name-field phoneMargin" 
                                value={formBody?.phone}
                                required
                                InputProps={{
                                    startAdornment: (
                                    <InputAdornment position="start">
                                        <PhoneIcon fontSize='small' />
                                    </InputAdornment>
                                    ),
                                }}
                                />   
                        </div>                  
                        </div>
                        <div className="input-box-mobile marginLeftIn input-box-aria">
                            <InputLabel className="input-label-text">Company Name</InputLabel>
                            <TextField 
                                placeholder="" 
                                type="text" 
                                variant="outlined" 
                                name="company_name" 
                                onChange={handleInputChange}
                                className="name-field"
                                value={formBody?.company_name}
                                required
                                InputProps={{
                                    startAdornment: (
                                    <InputAdornment position="start">
                                        <PersonIcon fontSize='small' />
                                    </InputAdornment>
                                    ),
                                }}
                                />
                        </div>
                    </div>
                    <div className="two-input-aria">
                        <div className="input-box-mobile input-box-aria">
                            <InputLabel className="input-label-text">
                                Office Location
                            </InputLabel>
                            <TextField 
                                placeholder="" 
                                type="text" 
                                variant="outlined" 
                                name="office_location"
                                className="name-field" 
                                onChange={handleInputChange}
                                value={formBody?.office_location}
                                required
                                InputProps={{
                                    startAdornment: (
                                    <InputAdornment position="start">
                                        <LocationOnIcon fontSize='small' />
                                    </InputAdornment>
                                    ),
                                }}
                                />                     
                        </div>
                        <div className="input-box-mobile marginLeftIn input-box-aria">
                            <InputLabel className="input-label-text">
                                Company Website
                            </InputLabel>
                            <TextField 
                                placeholder="" 
                                type="text" 
                                variant="outlined" 
                                name="company_website" 
                                className="name-field"
                                onChange={handleInputChange}
                                value={formBody?.company_website}
                                required
                                InputProps={{
                                    startAdornment: (
                                    <InputAdornment position="start">
                                        <PersonIcon fontSize='small' />
                                    </InputAdornment>
                                    ),
                                }}
                                />
                        </div>
                    </div>
                    <div className="two-uploads">
                        <div>
                            <InputLabel className="input-label-text">
                                Id Proof
                            </InputLabel>
                            <UploadBox
                                name={`id_proof`}
                                height={'190px'}
                                width={'216px'}
                                value={formBody?.id_proof ? formBody?.id_proof : ''}
                                handleUpload={(name, value) => handleChange(name, value)} />
                        </div>
                        <div className="firm-box">
                            <InputLabel className="input-label-text firm-text">
                                Firm Document
                            </InputLabel>
                            <UploadBox
                                name={`syllabus`}
                                height={'190px'}
                                width={'216px'}
                                value={formBody?.firm_document ? formBody?.firm_document :''}
                                handleUpload={(name, value) => handleChange(name, value)} />
                        </div>
                    </div>
                    <div className="submit-btn-box">
                        <Button 
                            disabled={btnLoader}
                            onClick={submitForm} sx={{
                            backgroundColor: btnLoader ? 'rgb(0, 0, 0, 0.3)' : 
                                'var(--primary-color)',
                            textTransform: 'capitalize',
                            width: '96px',
                                    height: '32px',
                            padding: '4px 16px',
                            color: '#fff',
                            "&:hover":{
                                backgroundColor: btnLoader ? 'rgb(0, 0, 0, 0.3)' :
                                 'var(--primary-color)',
                            }
                        }}>
                            {btnLoader ? <LoaderIcon /> : 'Submit'}
                        </Button>
                    </div>
                </div>
            </div>
            <Dialog 
                maxWidth="xs" 
                open={isOpen} 
                onClose={() => setIsOpen(false)} 
                fullWidth>
                <div>
                    <Typography component="p" variant="p" 
                        sx={{
                        textAlign: 'center',
                        fontWeight: 'bold',
                        margin: '28px 0 48px'}}>
                        You will be logged out and sent to partner screen <br/> 
                        Click 'Yes' to continue
                    </Typography>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'row-reverse',
                        padding: '0px 16px 28px 16px'
                    }}>
                        <Button onClick={() => {setIsOpen(false)}} variant="outlined" sx={{
                        textTransform: 'capitalize',
                        color: 'var(--tertiary-color)',
                        borderColor: 'var(--tertiary-color)'
                        }}>
                            Cancel
                        </Button>
                        <Button variant="contained" sx={{
                            backgroundColor: 'var(--tertiary-color)',
                            width: '84px',
                            marginRight: '12px',
                            textTransform: 'capitalize'
                            }} onClick={goToPartnerRequest}>
                            Yes
                        </Button>
                    </div>
                </div>
            </Dialog>
        </>
    );
}
