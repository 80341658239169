import axios from "axios";
import endpoint from "../../index.routes";

const addQuestion = async (params) => {
  let path = "";
  params.isWebsiteFor === "coaching" ? 
    (path = endpoint.COACHING_QUESTION) : (path = endpoint.UNIVERSITY_QUESTION)
  const response = await axios.post(path, params.body, {
    headers: { token: localStorage.getItem("cc_token") },
  });

  return response.data;
};

const addRecordedCourse = async (params) => {
  let path = "";
  params.isWebsiteFor === "coaching" ? 
    (path = endpoint.COACHING_COURSE) : (path = endpoint.UNIVERSITY_COURSE)
  const response = await axios.post(path, params.body, {
    headers: { token: localStorage.getItem("cc_token") },
  });

  return response.data;
};

const addTest = async (params) => {
  let path = "";
  params.isWebsiteFor === "coaching" ? 
    (path = endpoint.COACHING_TEST) : (path = endpoint.UNIVERSITY_TEST)
  const response = await axios.post(path, params.body, {
    headers: { token: localStorage.getItem("cc_token") },
  });

  return response.data;
};

const updateTest = async (params) => {
  let path = "";
  params.isWebsiteFor === "coaching" ? 
    (path = endpoint.COACHING_TEST) : (path = endpoint.UNIVERSITY_TEST)
  const response = await axios.put(path+"/"+params.id, params.body, {
    headers: { token: localStorage.getItem("cc_token") },
  });

  return response.data;
};

const getTests = async(params) => {
  let path = "";
  let {limit, offset, searchText, isWebsiteFor} = params
  isWebsiteFor === "coaching" ? 
    (path = endpoint.COACHING_TEST) : (path = endpoint.UNIVERSITY_TEST)
  const response = await axios.get(path, {
      params: { limit, offset, searchText },
      headers: { token: localStorage.getItem("cc_token") },
  });
  return response.data;
}

const getStudentTestById = async(params) => {
  const response = await axios.get(endpoint.STUDENT_TEST+"/"+params.id,{
    headers: { token: localStorage.getItem("cc_token") },
  })
  return response.data;
}

const getTestResult = async(params) => {
  const response = await axios.post(endpoint.STUDENT_TEST+"/"+params.id, params.body,
  {
    headers: { token: localStorage.getItem("cc_token") },
  })
  return response.data;
}

const getQuestion = async(params) => {
    let path = "";
    let {limit, offset, searchText, isWebsiteFor} = params
    isWebsiteFor === "coaching" ? 
      (path = endpoint.COACHING_QUESTION) : (path = endpoint.UNIVERSITY_QUESTION)
    const response = await axios.get(path, {
        params: { limit, offset, searchText },
        headers: { token: localStorage.getItem("cc_token") },
    });
    return response.data;
}

const deleteQuestion = async(params) => {
  let path = "";
  params.isWebsiteFor === "coaching" ? 
    (path = `${endpoint.COACHING_QUESTION}/${params.id}`) : 
    (path = `${endpoint.UNIVERSITY_QUESTION}/${params.id}`)
  const response = await axios.delete(path, {
    headers: { token: localStorage.getItem("cc_token") },
  });
  return response.data;
}

const updateQuestion = async (params) => {
  let path = "";
  params.isWebsiteFor === "coaching" ? 
    (path = endpoint.COACHING_QUESTION) : (path = endpoint.UNIVERSITY_QUESTION)
  const response = await axios.put(path+"/"+params.id, params.body, {
    headers: { token: localStorage.getItem("cc_token") },
  });

  return response.data;
};

const getTestById = async(params) => {
  let path = "";
  params.isWebsiteFor === "coaching" ? 
    (path = `${endpoint.COACHING_TEST}/${params.id}`) : 
    (path = `${endpoint.UNIVERSITY_TEST}/${params.id}`)
  const response = await axios.get(path, {
    headers: { token: localStorage.getItem("cc_token") },
  });
  return response.data;
}

const deleteTest = async(params) => {
  let path = "";
  params.isWebsiteFor === "coaching" ? 
    (path = `${endpoint.COACHING_TEST}/${params.id}`) : 
    (path = `${endpoint.UNIVERSITY_TEST}/${params.id}`)
  const response = await axios.delete(path, {
    headers: { token: localStorage.getItem("cc_token") },
  });
  return response.data;
}

const getQuestionById = async(params) => {
    let path = "";
    params.isWebsiteFor === "coaching" ? 
      (path = `${endpoint.COACHING_QUESTION}/${params.id}`) : 
      (path = `${endpoint.UNIVERSITY_QUESTION}/${params.id}`)
    const response = await axios.get(path, {
      headers: { token: localStorage.getItem("cc_token") },
    });
    return response.data;
  }

const productService = { 
    addQuestion,
    getTests,
    deleteTest,
    getTestById,
    getQuestion,
    addTest,
    updateTest,
    deleteQuestion,
    getQuestionById,
    getStudentTestById,
    getTestResult,
    updateQuestion,
    addRecordedCourse
};

export default productService;