import * as React from 'react';
import HomeLayout from "../homeLayout/HomeLayout";
import ProductTable from "../../components/BasicTable/BasicTable";
import Pagination from "../../components/Pagination/Pagination";
import { useDispatch, useSelector } from "react-redux";
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { Link } from "react-router-dom";
import VisibilityIcon from '@mui/icons-material/Visibility';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from "@mui/material/Dialog";
import { setRightHeaderText } from "../../redux/requestForm/requestFormSlice";
import { 
    getContent,
    getProduct, 
    getProductById, 
    deleteProduct, 
    setProductsFilterDetails,
    setProductDetailsById } from '../../redux/product/productSlice';
import Spinner from "../../components/Spinner/Spinner";
import AddProduct from './AddProduct';
import routes from "../../react_routes_enum";
import { toast } from 'react-toastify';
import { getCourses } from '../../redux/product/productSlice';
import { getTests } from '../../redux/test/testSlice';
import {
    Button,
    IconButton,
    Typography,
  } from "@mui/material";

export default function Products() {
    const [limit, setLimit] = React.useState(10);
    const [offset, setOffset] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [isAddProduct, setIsAddProduct] = React.useState(false);
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);
    const [deleteId, setDeleteId] = React.useState(false);
    const [isWebsiteFor, setIsWebsiteFor] = React.useState(localStorage.getItem("is-website-for"));
    const dispatch = useDispatch();
    const colR = ["SNo", "Name of Product", "Category", "type", "view","edit","delete"];

    const { 
        isLoading, 
        isError, 
        totalCount, 
        message, 
        searchText,
        productData,  
    } = useSelector((state) => state.product);
    const addProductCLicked = () => {
        setIsAddProduct(true);
        dispatch(setProductDetailsById(null));
        dispatch(setRightHeaderText("Products/Add Product"));
    }
    React.useEffect(() => {
        dispatch(getProduct({limit, offset, searchText, isWebsiteFor}));
    },[limit, offset, searchText])

    React.useEffect(() => {
        dispatch(getContent({type: 'coaching'}));
        dispatch(getTests({isWebsiteFor}));
        dispatch(getCourses({isWebsiteFor}));
        dispatch(setProductsFilterDetails(null));
    },[])

    const viewProduct = (id) => {
        dispatch(getProductById({id, isWebsiteFor}));
        
    }
    const editProduct = (id) => {
        dispatch(getProductById({id, isWebsiteFor}));
    }
    const deleteProductById = () => {
        const res = dispatch(deleteProduct({id: deleteId, isWebsiteFor}));
        res.then((r) => {
            if(r.payload.statusCode === 200){
                toast.success(r.payload.message);
                setIsDialogOpen(false);
                dispatch(getProduct({limit, offset, isWebsiteFor}));
            }else{
                toast.error(r.payload.message);
            }
        })
    }
    const resetStateForPaginationOfDifferentView = () => {
        setLimit(10);
        setOffset(0);
        setPage(0);
    };

    const handleSearchText = (e) => {
        resetStateForPaginationOfDifferentView();
        if (limit === 10 && offset === 0) {
            dispatch(getProduct({limit, offset, searchText}));
        }
    };
    const handleClose = () => {
        setIsDialogOpen(false);
      };

    if (isLoading && !isAddProduct) return <Spinner />;
    if (isError) return <h1>{message}</h1>;
    return (
      <>
        <Dialog maxWidth="xs" open={isDialogOpen} onClose={handleClose} fullWidth>
            <div>
            <Typography component="p" variant="p" 
                sx={{
                textAlign: 'center',
                fontWeight: 'bold',
                margin: '28px 0 48px'}}>
                Are you sure you want to<br/> delete the product ?
            </Typography>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'row-reverse',
                padding: '0px 16px 28px 16px'
            }}>
                <Button onClick={() => {setIsDialogOpen(false)}} variant="outlined" sx={{
                textTransform: 'capitalize'
                }}>
                    Cancel
                </Button>
                <Button onClick={() => {deleteProductById()}} variant="contained" sx={{
                    backgroundColor: 'var(--primary-color)',
                    width: '84px',
                    marginRight: '12px',
                    textTransform: 'capitalize'
                    }}>
                    Yes
                </Button>
            </div>
            </div>
        </Dialog>
        <HomeLayout />
        <div className="add-product-button">
            {!isAddProduct &&
            <Button 
                variant="contained" 
                onClick={addProductCLicked}
                sx={{
                    backgroundColor: isWebsiteFor === "university" ? 
                        '#F69220' : 'var(--tertiary-color)',
                    textTransform: 'capitalize',
                    borderRadius: '8px'
                    }}>
                <ControlPointIcon sx={{marginRight: '4px'}} />
                Add Product
            </Button>}
        </div>
        <div className="pagination-addPost">
            {!isAddProduct &&
            <Pagination
                totalCount={totalCount}
                limit={limit}
                page={page}
                changeLimit={setLimit}
                changeOffset={setOffset}
                changePage={setPage}
            />}
            {!isAddProduct &&
            <div className="add-product-button-2">
                <Button variant="contained" onClick={addProductCLicked}
                sx={{
                    backgroundColor: isWebsiteFor === "university" ? '#F69220' : 'var(--tertiary-color)',
                    textTransform: 'capitalize',
                    borderRadius: '8px'
                    }}>
                <ControlPointIcon sx={{marginRight: '4px'}} />
                    Add Product
                </Button>
            </div>}
        </div>
        <div className="content-box table-box" style={{
            marginTop: '0px',
            overflowX: isAddProduct ? 'hidden' : 'scroll',
            overflowY: 'hidden'
        }}>
            {isAddProduct ?
            <>
                <AddProduct 
                    isDisable={false} 
                    setIsAddProduct={
                        (arg) => setIsAddProduct(arg)
                    }/>
            </>:
            <>
                <ProductTable
                    columns={colR}
                    where="recipes"
                    rows={productData && productData?.map((item) => {
                        const res = {
                        ...item,
                        view: (
                            <div>
                                <IconButton
                                    color="inherit"
                                    edge="start"
                                    onClick={() => {viewProduct(item._id)}}>
                                    <Link to={routes.PRODUCT_VIEW}>
                                        <VisibilityIcon sx={{color: '#3C1CDE'}} />
                                        <Typography component="p" variant="p" 
                                        sx={{
                                            color: '#3C1CDE',
                                            fontSize: '18px',
                                            fontWeight: 'bold'
                                            }}>
                                        View
                                        </Typography>
                                    </Link>
                                </IconButton>
                            </div>
                        ),
                        edit: (
                            <div>
                                <IconButton
                                color="inherit"
                                edge="start"
                                onClick={() => {editProduct(item._id)}}
                                >
                                <Link to={routes.PRODUCT_EDIT}>
                                    <BorderColorIcon sx={{color: 'var(--secondary-color)'}} />
                                    <Typography component="p" variant="p" 
                                    sx={{
                                        color: 'var(--secondary-color)',
                                        fontSize: '18px',
                                        fontWeight: 'bold'
                                        }}>
                                    Edit
                                    </Typography>
                                </Link>
                                </IconButton>
                            </div>
                        ),
                        delete: (
                            <div>
                                <IconButton
                                color="inherit"
                                edge="start"
                                onClick={() => {
                                    setDeleteId(item?._id)
                                    setIsDialogOpen(true)
                                }}
                                >
                                <Link to={''}>
                                    <DeleteIcon sx={{color: '#EF4840'}} />
                                    <Typography component="p" variant="p" 
                                    sx={{
                                        color: '#EF4840',
                                        fontSize: '18px',
                                        fontWeight: 'bold'
                                        }}>
                                    Delete
                                    </Typography>
                                </Link>
                                </IconButton>
                            </div>
                        )
                        };
                        return res;
                    })
                    }
                />
            </>}
        </div>
      </>
    );
  }