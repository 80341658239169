import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Typography,
} from "@mui/material";
import routes from "../../react_routes_enum";
import { makeStyles } from "@mui/styles";
import UnivResIcon from "../../assest/images/univ_res_icon.jpg";
import CoachingResIcon from "../../assest/images/coaching_res_icon.png";
import HeaderBackground from "../../assest/images/header-background.jpg";
import HeaderIcon from "../../assest/images/header-icon.png";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useNavigate } from "react-router-dom";
import Loader from "../../assest/images/preloader.gif";
import { 
    getUniversityByToken, 
    getCoachingByToken,
    getPartnerByToken } from "../../redux/requestForm/requestFormSlice";
import { logout, reset } from "../../redux/auth/authSlice";

const useStyle = makeStyles({
    dot: {
        height: '16px',
        width: '16px',
        borderRadius: '50%',
    },
    line: { 
        height: '4px',
        backgroundColor: 'rgb(246, 146, 32, 0.4)'
    },
    changesButton: {
        color: '#fff !important',
        textTransform: 'capitalize !important',
        width: '140px',
        height: '40px',
        fontSize: '16px !important',
        borderRadius: '24px !important',
        boxShadow: '0 2px 5px rgb(0, 0, 0, 0.5)',
        "&:hover":{
            backgroundColor: 'var(--secondary-color) !important',
        }
    },
    cancelButton: {
        backgroundColor: '#fff !important',
        color: 'var(--secondary-color) !important',
        textTransform: 'capitalize !important',
        width: '140px',
        height: '40px',
        fontSize: '16px !important',
        borderRadius: '24px !important',
        borderColor: 'var(--secondary-color) !important',
        "&:hover":{
            backgroundColor: '#fff',
        }
    },
    header: {
        marginTop: '100px',
        marginBottom: '4%',
        backgroundImage: `url(${HeaderBackground})`,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    formIconsBox: {
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        marginTop: '5%',
        marginRight: '14%',
    },
    iconBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    horizontalLine: {
        height: '4px',
        backgroundColor: 'var(--primary-color)',
    },
    iconBox2: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'absolute', 
    },
    iconStyle: {
        backgroundColor: 'var(--primary-color)',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    iconText: {
        color: 'var(--primary-color)',
        marginTop: '8px !important',
    },
    icon: {
        color: '#fff',
    },
    leftBox: {
        height: '200px',
        width: '200px',
        borderRadius: '8px',
        border: '2px dashed rgb(0, 0, 0, 0.5)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    upload: {
        border: '1px solid rgb(0, 0, 0, 0.2) !important',
        fontSize: '15px !important',
        color: 'rgb(0, 0, 0, 0.4) !important',
        textTransform: 'capitalize !important',
        width: '122px',
        height: '34px'
    },
    checkBox: {
        color: 'var(--primary-color)',
        '&.Mui-checked': {
            color: 'var(--primary-color)',
        }
    },
    tableNew: {
        border: '1px solid rgb(0, 0, 0, 0.2)',
        borderRadius: '8px',
        borderCollapse: 'collapse',
        width: '100%',
        marginLeft: '0.8%' 
    },
    tableTh: {
        borderBottom: '1px solid rgb(0, 0, 0, 0.2)',
        display: 'flex',
        justifyContent: 'space-between',
        fontWeight: 'bold',
    },
    tableTd: {
        borderRight: '1px solid rgb(0, 0, 0, 0.2)',
        width: '14.5%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '12px',
    }
})

export default function RequestRes() {
    const {
        universityByToken,
        coachingByToken,
        partnerByToken } = useSelector((state) => state.form);
    const [btnLoader, setBtnLoader] = React.useState(false);
    const isWebsiteFor = localStorage.getItem("is-website-for");
    const navigate = useNavigate();
    const classes = useStyle();
    const dispatch = useDispatch();

    const changesClicked = () => {
        let res = null;
        setBtnLoader(true);
        isWebsiteFor === "coaching" && (res = dispatch(getCoachingByToken()))
        isWebsiteFor === "university" && (res = dispatch(getUniversityByToken()))
        isWebsiteFor === "partner" && (res = dispatch(getPartnerByToken()))
        res.then((r) =>{
            setBtnLoader(false);
            if(r.payload.statusCode === 200) {
                localStorage.setItem("isRefresh", true);
                (isWebsiteFor === "coaching" || isWebsiteFor === "university") && 
                    navigate(routes.REQUEST_FORM);
                isWebsiteFor === "partner" &&  navigate(routes.PARTNER_REQUEST_FORM);
            }
        })
    }

    React.useEffect(() => {
        let res = null
        isWebsiteFor === "coaching" && (res = dispatch(getCoachingByToken()));
        isWebsiteFor === "university" && (res = dispatch(getUniversityByToken()));
        isWebsiteFor === "partner" && (res = dispatch(getPartnerByToken()));
        res.then((r) => {
            if (r?.payload?.statusCode === 200){
                if(r?.payload?.data?.request_status === "registration"){
                    isWebsiteFor === "coaching" && navigate(routes.COACHING_INFORMATION);
                    isWebsiteFor === "university" && navigate(routes.UNIVERSITY_INFORMATION);
                    isWebsiteFor === "partner" && navigate(routes.PARTNER_DOCUMENT);
                }
            }
        })
        statusCheck();
    },[])

    const statusCheck = () => {
        let x = setInterval(function() {
            if(isWebsiteFor === "coaching"){
                const res = dispatch(getCoachingByToken());
                res.then((r) => {
                    if(r.payload.statusCode === 200){
                        if(r.payload.data.request_status === "registration") {
                            clearInterval(x);
                            navigate(routes.COACHING_INFORMATION)
                        }
                    }
                })
            }
            if(isWebsiteFor === "partner"){
                const res = dispatch(getPartnerByToken());
                res.then((r) => {
                    if(r.payload.statusCode === 200){
                        if(r.payload.data.request_status === "registration") {
                            clearInterval(x);
                            navigate(routes.PARTNER_DOCUMENT);
                        }
                    }
                })
            }
            else{
                const res = dispatch(getUniversityByToken());
                res.then((r) => {
                    if(r?.payload?.statusCode === 200){
                        if(r?.payload?.data.request_status === "registration"){
                            clearInterval(x);
                            navigate(routes.UNIVERSITY_INFORMATION)
                        }
                    }
                })
            }
        },1000)
    }
    
    const continueBack = () => {
        dispatch(logout());
        dispatch(reset());
        isWebsiteFor === "partner" ? navigate(routes.PARTNER_REQUEST_FORM) : 
            navigate(routes.REQUEST_FORM)
    }
    return (
        <Box>
            <section className={classes.header + ' header-set'}>
                <div className="header-icon-box">
                    <img src={HeaderIcon} alt="header-icon" style={{width: '100%'}} />
                </div>
                <Typography component="h2" variant="h2" className="header-heading">
                    {isWebsiteFor === "university" && 'UNIVERSITY - LISTING'}
                    {isWebsiteFor === "coaching" && 'COACHING - LISTING'}
                    {isWebsiteFor === "partner" && "PARTNER'S ONBOARDING"}
                </Typography>
                <Typography component="p" variant="p" className="small-header-heading">
                    {isWebsiteFor === "university" && 'University listing is in progress'}
                    {isWebsiteFor === "coaching" && 'Coaching listing is in progress'}
                </Typography>
            </section>
            <Box sx={{p: 3}}>
                <section>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginBottom: '8px'
                        }} className="progr-lin-box">
                            <div className={classes.dot} style={{
                                background: 'none',
                                }}>
                                <CheckCircleOutlineIcon sx={{width: '100%', color: '#07AB35'}} />
                            </div>
                            <div className={classes.line + ' progress-line'} style={{background: 'none'}}>

                            </div>
                            <div className={classes.dot} style={{
                                background: 'none',
                                visibility: (universityByToken?.data?.information_check && universityByToken?.data?.uploads_check &&
                                    universityByToken?.data?.courses_offered_check && universityByToken?.data?.placement_records_check
                                    ) ? 
                                    'visible' : 'hidden'
                                }}>
                                <CheckCircleOutlineIcon sx={{
                                    width: '100%', 
                                    color: '#07AB35',
                                    }} />
                            </div>
                            <div className={classes.line + ' progress-line'} style={{background: 'none'}}>

                            </div>
                            <div className={classes.dot} style={{
                                background: 'none',
                                visibility: (universityByToken?.data?.information_check && universityByToken?.data?.uploads_check &&
                                    universityByToken?.data?.courses_offered_check && universityByToken?.data?.placement_records_check
                                    && universityByToken?.data?.request_status === "accepted") ? 
                                    'visible' : 'hidden'
                                }}> 
                                <CheckCircleOutlineIcon sx={{width: '100%', color: '#07AB35'}} />
                            </div>
                        </div>
                    </div>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }} className="progr-lin-box">
                            <div className={classes.dot} style={{backgroundColor: 'var(--secondary-color)'}}>
                                
                            </div>
                            <div className={classes.line + ' progress-line'} style={{
                                backgroundColor: (universityByToken?.data?.information_check && 
                                    universityByToken?.data?.uploads_check &&
                                    universityByToken?.data?.courses_offered_check && 
                                    universityByToken?.data?.placement_records_check) ? 'var(--secondary-color)' :
                                    'rgb(246, 146, 32, 0.4)'
                            }}>

                            </div>
                            <div className={classes.dot} style={{backgroundColor: (universityByToken?.data?.information_check && 
                                    universityByToken?.data?.uploads_check &&
                                    universityByToken?.data?.courses_offered_check && 
                                    universityByToken?.data?.placement_records_check) ? 'var(--secondary-color)' :
                                    'rgb(246, 146, 32, 0.4)'}}>

                            </div>
                            <div className={classes.line + ' progress-line'} style={{
                                backgroundColor: (universityByToken?.data?.information_check && 
                                    universityByToken?.data?.uploads_check &&
                                    universityByToken?.data?.courses_offered_check && 
                                    universityByToken?.data?.placement_records_check &&
                                    universityByToken?.data?.request_status === "accepted") ? 'var(--secondary-color)' :
                                    'rgb(246, 146, 32, 0.4)'
                            }}>

                            </div>
                            <div className={classes.dot} style={{backgroundColor: (universityByToken?.data?.information_check && 
                                    universityByToken?.data?.uploads_check &&
                                    universityByToken?.data?.courses_offered_check && 
                                    universityByToken?.data?.placement_records_check &&
                                    universityByToken?.data?.request_status === "accepted") ? 'var(--secondary-color)' :
                                    'rgb(246, 146, 32, 0.4)'}}>

                            </div>
                        </div>
                    </div>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '12px'
                    }}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            justifyContent: 'space-between',
                        }} className="progr-text-box">
                            <Typography component="h2" variant="h2" className="progr-text">
                                Fill the Request Form
                            </Typography>
                            <Typography component="h2" variant="h2" className="progr-text">
                                {isWebsiteFor === "partner" ? 
                                "Upload the documents"
                                :
                                "Fill the Listing Registration Form"
                                }
                            </Typography>
                            <Typography component="h2" variant="h2" className="progr-text">
                                {isWebsiteFor === "partner" ? 
                                "Success"
                                :
                                "LIVE Listing"
                                }
                            </Typography>
                        </div>
                    </div>
                </section>
                <section>
                    <>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                        }} className="request-main-res-box">
                            <div style={{
                                    width: '120px'
                            }}>
                                <img src={isWebsiteFor === "coaching" ? CoachingResIcon : UnivResIcon} alt="response-icon" 
                                    style={{width: '100%'}} />
                            </div>
                            {(coachingByToken?.data?.request_status === "pending" ||
                                partnerByToken?.data?.request_status === "pending" ||
                                universityByToken?.data?.request_status === "pending") && 
                                <Typography variant="h3" component="h3" className="request-res-main-text">
                                    Your Request Is Pending
                                </Typography>
                            }
                            {(coachingByToken?.data?.request_status === "review" ||
                                partnerByToken?.data?.request_status === "review" ||
                                universityByToken?.data?.request_status === "review") && 
                                <>
                                    <Typography variant="h3" component="h3" className="request-res-main-text">
                                        Your Request form required <br/>some changes
                                    </Typography> 
                                    <div style={{
                                        marginTop: '48px'
                                    }}>
                                        <Typography variant="p" component="p" sx={{
                                            fontSize: '18px',
                                        }}>
                                            CHANGES:
                                        </Typography>
                                        <ul style={{
                                            listStyleType: 'numeric',
                                            paddingLeft: '16px'
                                        }}>

                                            {isWebsiteFor === "university" && 
                                                universityByToken?.data?.requested_changes.map((item, index) => (
                                                    <li key={index}>
                                                        {item}
                                                    </li>
                                                ))
                                            }
                                            {isWebsiteFor === "coaching" && 
                                                coachingByToken?.data?.requested_changes.map((item, index) => (
                                                    <li key={index}>
                                                        {item}
                                                    </li>
                                                ))
                                            }
                                            {isWebsiteFor === "partner" && 
                                                partnerByToken?.data?.requested_changes.map((item, index) => (
                                                    <li key={index}>
                                                        {item}
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            marginTop: '48px',
                                            marginBottom: '52px'
                                        }}>
                                            <Button 
                                                className={classes.changesButton} 
                                                sx={{
                                                    backgroundColor: isWebsiteFor === "coaching" ? 'var(--tertiary-color)' : 
                                                    'var(--secondary-color)'
                                                }} 
                                                disabled={btnLoader}
                                                onClick={changesClicked}>
                                                {btnLoader ? <img src={Loader} width="24" alt="Loader" /> :
                                                    "Edit"} 
                                            </Button>
                                        </div>
                                    </div>
                                </>
                            }
                            {(coachingByToken?.data?.request_status === "rejected" ||
                                partnerByToken?.data?.request_status === "rejected" ||
                                universityByToken?.data?.request_status === "rejected") && 
                            <>
                                <Typography variant="h3" component="h3" className="request-res-main-text">
                                    Your Request Is Rejected
                                </Typography>
                                <div style={{
                                    marginTop: '48px'
                                }}>
                                    <Typography variant="p" component="p" sx={{
                                        fontSize: '18px',
                                    }}>
                                        REASON:
                                    </Typography>
                                    <Typography variant="p" component="p" sx={{
                                        fontSize: '16px',
                                    }}>
                                        {isWebsiteFor === "coaching" && coachingByToken?.data?.reject_reason}
                                        {isWebsiteFor === "partner" && partnerByToken?.data?.reject_reason}
                                        {isWebsiteFor === "university" && universityByToken?.data?.reject_reason}
                                    </Typography>
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        marginTop: '48px',
                                        marginBottom: '52px'
                                    }}>
                                        <Button className={classes.changesButton} sx={{
                                            backgroundColor: isWebsiteFor === "coaching" ? 'var(--tertiary-color)' : 
                                                'var(--secondary-color)'
                                        }} onClick={continueBack}>
                                            Continue
                                        </Button>
                                    </div>
                                </div>
                            </>
                            }
                        </div>
                    </>
                </section>
            </Box>
        </Box>
    );
}
