import * as React from 'react';
import TotalStudentIcon from "../../assest/svgs/total_students.svg";
import TotalRevenueIcon from "../../assest/svgs/total_revenue.svg";
import TotalProductsIcon from "../../assest/svgs/total_products.svg";
import TotalBlogsIcon from "../../assest/svgs/total_blogs.svg";
import routes from "../../react_routes_enum";
import { Link } from "react-router-dom";

const totalData = [
    {
      icon: TotalStudentIcon,
      name: 'Students',
      count: 20,
      bgColor: '#E8E6FB'
    },
    {
      icon: TotalProductsIcon,
      name: 'Products',
      count: 30,
      bgColor: '#FAE5EA'
    },
    {
      icon: TotalBlogsIcon,
      name: 'Blogs',
      count: 40,
      bgColor: '#C1FFD2'
    },
    {
      icon: TotalRevenueIcon,
      name: 'Revenue from students',
      revenue: 50000,
      bgColor: '#C1FFD2'
    }
]

const UniversityDash = () => {
    return (
      <>
        <div style={{
          display: 'inline-block',
          }} className="dash-board-card-big-box">
            {totalData?.map((item, index) => (
                <div key={index} className="dash-board-card-box">
                    <div className="dash-board-top-card" style={{
                        backgroundColor: item?.bgColor
                    }}>
                        <img src={item?.icon} alt={item?.name} />
                        <p style={{
                          fontWeight: 'bold',
                          padding: '0 12px',
                          textAlign: 'center',
                          marginBottom: 0
                        }}>{item?.name}</p>
                        {item?.count && <p style={{
                          fontSize: '22px',
                          fontWeight: 'bold',
                          marginBottom: 0,
                        }}>{item?.count}</p>}
                        {item?.revenue && <p style={{
                          fontSize: '22px',
                          fontWeight: 'bold',
                          marginBottom: 0,
                        }}>Rs {item?.revenue}</p>}
                    </div>
                </div>
            ))}
        </div>
        <div className="student-app-box">
          <p style={{
            fontSize: '20px',
            fontWeight: 'bold',
          }}>Student Applications</p>
          <div className="student-app-box-in">
            <div className="student-app-box-n">
              <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '8px'
              }}>
                <p style={{
                  fontSize: '18px',
                  fontWeight: 'bold',
                  textDecoration: 'underline',
                  color: 'var(--primary-color)'
                }}>Processing applications</p>
                <Link to="" className='show-more-app'>Show more</Link>
              </div>
            </div>
            <div className="student-app-box-n" style={{
              marginLeft: '32px'
            }}>
              <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '8px'
              }}>
                <p style={{
                  fontSize: '18px',
                  fontWeight: 'bold',
                  textDecoration: 'underline',
                  color: 'var(--primary-color)'
                }}>Enrolled applications</p>
                <Link to="" className='show-more-app'>Show more</Link>
              </div>
            </div>
          </div>
        </div>
        <div className="student-app-box">
          <p style={{
            fontSize: '20px',
            fontWeight: 'bold',
          }}>Pending To Action</p>
          <div className="pending-to-action">
            <ul>
              <li style={{marginTop: '12px'}}>
                Complete the uploading of Test Questions for Microbiology. 
                <Link to={routes.TEST_QUESTION} 
                  style={{
                    color: '#07AB35',
                    fontWeight: 'bold',
                    marginLeft: '4px',
                    marginTop: '2px'
                    }}>START UPLOADING</Link>
              </li>
              <li>
                Complete the uploading of online recorded sessions for Microbiology. 
                <Link to={routes.RECORDED_COURSE}
                  style={{
                    color: '#07AB35',
                    fontWeight: 'bold',
                    marginLeft: '4px',
                    marginTop: '2px'
                    }}>START UPLOADING</Link>
              </li>
            </ul>
          </div>
        </div>
      </>
    )
}

export default UniversityDash;