import * as React from 'react';
import useRazorpay from "react-razorpay";
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { Typography, InputLabel } from '@mui/material';
import TextField from '@mui/material/TextField';
import PersonIcon from '@mui/icons-material/Person';
import HomeLayout from "../homeLayout/HomeLayout";
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import UploadBox from '../../components/UploadBox/uploadBox';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import UnivResIcon from "../../assest/images/univ_res_icon.jpg";
import { makeStyles } from "@mui/styles";
import { toast } from "react-toastify";
import EastIcon from '@mui/icons-material/East';
import EmailIcon from '@mui/icons-material/Email';
import RoomIcon from '@mui/icons-material/Room';
import PublicIcon from '@mui/icons-material/Public';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import CreateIcon from '@mui/icons-material/Create';
import validator from "validator";
import LoaderIcon from "../../components/Loader/Loader";
import { 
  getStudentApplicationById,
  payRegistrationFee,
  updateStudentApplication,
  payFinalFee
} from "../../redux/students/studentSlice";
import { verifyOrder } from "../../redux/payment/paymentSlice";
import routes from '../../react_routes_enum';
import './student.css';

const useStyle = makeStyles({
  addMore:{
    height: '36px',
    textTransform: 'capitalize !important',
    fontWeight: 'bold !important',
  },
  changesButton: {
    color: '#fff !important',
    textTransform: 'capitalize !important',
    width: '105px',
    height: '40px',
    fontSize: '16px !important',
    borderRadius: '4px !important',
    boxShadow: '0 2px 5px rgb(0, 0, 0, 0.5)',
    "&:hover":{
        backgroundColor: 'var(--secondary-color) !important',
    },
  },
  formInputLabel: {
    color: '#000 !important',
    fontSize: '14px !important',
    marginBottom: '4px',
    fontWeight: 'bold !important'
  },
  marginBot24: {
    marginBottom: '24px'
  },
})

export default function Dashboard() {
  const classes = useStyle();
  const Razorpay = useRazorpay();
  const location = useLocation();
  const [isUploadDocument, setIsUploadDocument] = React.useState(false);
  const [buttonLoader, setButtonLoader] = React.useState(false);
  const [isBilling, setIsBilling] = React.useState(false);
  const [orderId, setOrderId] = React.useState("");
  const [paymentId, setPaymentId] = React.useState("");
  const [finalOrderId, setFinalOrderId] = React.useState("");
  const [finalPaymentId, setFinalPaymentId] = React.useState("");
  const [billingBody, setBillingBody] = React.useState({});
  const [userData, setUserData] = React.useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { studentApplicationById } = useSelector((state) => state.student);
  const [document, setDocument] = React.useState(studentApplicationById?.documents);
  let isUploading = false;
  
  const registrationFee = (id) => {
    setButtonLoader(true);
    const res = dispatch(payRegistrationFee({id}));
    res.then((r) => {
        setButtonLoader(true);
        if(r?.payload?.statusCode === 200){
            const options = {
                key: process.env.REACT_APP_RAZORPAY_KEY,
                amount: parseInt(r?.payload?.data?.total_payable),
                currency: "INR",
                name: "College Clue",
                description: "Transaction",
                image: "https://college-clue-s3.s3.amazonaws.com/3KSG1684138059984-cclogo.svg",
                order_id: r?.payload?.data?.order_id,
                modal: {
                ondismiss: async function () {
                  setButtonLoader(false);
                },
                },
                handler: async function (response) {
                    let verificationDetail = {
                        razorpay_payment_id: response.razorpay_payment_id,
                        order_id: r?.payload?.data?.order_id,
                    };
                    setOrderId(r?.payload?.data?.order_id);
                    setPaymentId(response.razorpay_payment_id);
                    const verifyRes = dispatch(verifyOrder(verificationDetail));
                    verifyRes?.then((verify) => {
                        setButtonLoader(false);
                        console.log("verify===========",verify?.payload);
                        if(verify?.payload?.statusCode === 200) {
                            toast.success("Your payment successful");
                        }else{
                            toast.error(verify.payload.message);
                        }
                    })
                },
                prefill: {
                    name: userData?.student?.name,
                    email: userData?.student?.email,
                    contact: userData?.student?.phone,
                },
                notes: {
                    address: "Razorpay Corporate Office",
                },
                theme: {
                    color: "#3399cc",
                },
            };

            const razorpay = new Razorpay(options);
            razorpay.on("payment.failed", function (response) {
                let verificationDetail = {
                    razorpay_payment_id: response?.error?.metadata?.payment_id,
                    order_id: response?.error?.metadata?.order_id,
                };
                setOrderId(response.error.metadata.order_id);
                setPaymentId(response.error.metadata.payment_id);
                options.modal.ondismiss(verificationDetail);
                const verifyRes = dispatch(verifyOrder(verificationDetail));
                verifyRes?.then((verify) => {
                    setButtonLoader(false);
                    if(verify?.payload?.statusCode === 200) {
                        toast.success("Your order successful");
                    }else{
                        toast.error(verify.payload.message);
                    }
                })
            });
            razorpay.open();
        }else{
            toast.error(r?.payload?.message);
        }
    })
  }
  const addMore = () => {
    let array = [...document];
    array.push({title: 'extraDocument'});
    setDocument([...array]);
  }

  React.useEffect(() => {
    localStorage.setItem("isRefreshPage", true);
    setUserData(JSON.parse(localStorage.getItem('cc_user')));
    setBillingBody({
      name: JSON.parse(localStorage.getItem('cc_user'))?.student?.name,
      phone: JSON.parse(localStorage.getItem('cc_user'))?.student?.phone,
      email: JSON.parse(localStorage.getItem('cc_user'))?.student?.email,
      address: JSON.parse(localStorage.getItem('cc_user'))?.student?.address,
      country: "",
      city: "",
      zip_code: "",
    });
    getApplication();
  },[])
  const getApplication = () => {
    let interval = setInterval(function() {
      const res = dispatch(getStudentApplicationById({id: localStorage.getItem("application_id")}));
      res.then((r) => {
        if(r?.payload?.statusCode === 200){
          if(r?.payload?.data?.status === "target_upload_requested" && !isUploading){
            setDocument(r?.payload?.data?.documents);
            isUploading = true;
          }
        }
      })
    },2000)
  }

  const handleImageUploads = (name, value, index) => {
    let array = document;
    let localDocument = [];
    localDocument = array.map((element, i) => {
      return i === index ? {...element, 
        ...(name === `gallary-upload-${index}` && {link: value})} : element
    })
    setDocument([...localDocument]);
  }

  const documentSubmit = (id) => {
    let error = false;
    document?.map((item) => {
      if(!item?.link || item?.link === ""){
        error = true;
      }
    })
    if(error){
      toast.error("All documents required");
      return;
    }
    let body = {
      status: "target_uploaded",
      documents: document
    }
    setButtonLoader(true);
    const res = dispatch(updateStudentApplication({id, body}));
    res.then((r) => {
      setButtonLoader(false);
      if(r?.payload?.statusCode === 200){
        isUploading = false;
        toast.success("Document submitted successfully");
      }else{
        toast.error(r?.payload?.message);
      }
    })
  }

  const editDocument = (id) => {
    let body = {
      status: 'target_upload_requested'
    }
    setButtonLoader(true);
    const res = dispatch(updateStudentApplication({id, body}));
    res.then((r) => {
      setButtonLoader(false);
      if(r?.payload?.statusCode === 200){

        isUploading = false;
      }else{
        toast.error(r?.payload?.message);
      }
    })
  }

  const formValidate = () => {
    let status = {isValid: true, message: ''}
    if(billingBody?.name === ""){
      status.isValid = false;
      status.message = "Name required";
      return status;
    }
    if(billingBody?.email === ""){
      status.isValid = false;
      status.message = "Email required";
      return status;
    }
    if(!validator.isEmail(billingBody?.email)){
      status.isValid = false;
      status.message = "Invalid email";
      return status;
    }
    if(billingBody?.phone === ""){
      status.isValid = false;
      status.message = "Mobile number required";
      return status;
    }
    if(billingBody?.phone?.length !== 10){
      status.isValid = false;
      status.message = "Invalid mobile number";
      return status;
    }
    if(billingBody?.address === ""){
      status.isValid = false;
      status.message = "Address required";
      return status;
    }
    if(billingBody?.country === ""){
      status.isValid = false;
      status.message = "Country required";
      return status;
    }
    if(billingBody?.city === ""){
      status.isValid = false;
      status.message = "city required";
      return status;
    }
    if(billingBody?.zip_code === ""){
      status.isValid = false;
      status.message = "Zipcode required";
      return status;
    }
    return status;
  }

  const finalFeePayment = (id) => {
    let status = formValidate();
    if(status?.isValid){
      setButtonLoader(true);
      const res = dispatch(payFinalFee({billingBody, id}));
      res.then((r) => {
        setButtonLoader(false);
        if(r?.payload?.statusCode === 200){ 
          const options = {
            key: process.env.REACT_APP_RAZORPAY_KEY,
            amount: parseInt(r?.payload?.data?.total_payable),
            currency: "INR",
            name: "College Clue",
            description: "Transaction",
            image: "https://college-clue-s3.s3.amazonaws.com/3KSG1684138059984-cclogo.svg",
            order_id: r?.payload?.data?.order_id,
            modal: {
            ondismiss: async function () {
              setButtonLoader(false);
            },
            },
            handler: async function (response) {
                let verificationDetail = {
                    razorpay_payment_id: response.razorpay_payment_id,
                    order_id: r?.payload?.data?.order_id,
                };
                setFinalOrderId(r?.payload?.data?.order_id);
                setFinalPaymentId(response.razorpay_payment_id);
                const verifyRes = dispatch(verifyOrder(verificationDetail));
                verifyRes?.then((verify) => {
                    setButtonLoader(false);
                    if(verify?.payload?.statusCode === 200) {
                        toast.success("Your payment successful");
                        location.pathname === "/coaching/application" ? 
                          navigate(routes.STUDENT_COACHING) : 
                          navigate(routes.STUDENT_UNIVERSITY);
                    }else{
                        toast.error(verify.payload.message);
                    }
                })
            },
            prefill: {
                name: userData?.student?.name,
                email: userData?.student?.email,
                contact: userData?.student?.phone,
            },
            notes: {
                address: "Razorpay Corporate Office",
            },
            theme: {
                color: "#3399cc",
            },
        };

        const razorpay = new Razorpay(options);
        razorpay.on("payment.failed", function (response) {
            let verificationDetail = {
                razorpay_payment_id: response?.error?.metadata?.payment_id,
                order_id: response?.error?.metadata?.order_id,
            };
            setFinalOrderId(response.error.metadata.order_id);
            setFinalPaymentId(response.error.metadata.payment_id);
            options.modal.ondismiss(verificationDetail);
            const verifyRes = dispatch(verifyOrder(verificationDetail));
            verifyRes?.then((verify) => {
                setButtonLoader(false);
                if(verify?.payload?.statusCode === 200) {
                    toast.success("Your order successful");
                }else{
                    toast.error(verify.payload.message);
                }
            })
        });
        razorpay.open();
        }else{
          toast.error(r?.payload?.message);
        }
      })
    }else{
      toast.error(status?.message);
    }
  }

  const continueApplication = (id, status) => {
    let body = {
      status: status
    }
    setButtonLoader(true);
    const res = dispatch(updateStudentApplication({id, body}));
    res.then((r) => {
      setButtonLoader(false);
      if(r?.payload?.statusCode === 200){
        isUploading = false;
        status !== "target_fee_pending" ? toast.success("Application sent to institute") : 
        toast.success("Application ready for final payment")
      }else{
        toast.error(r?.payload?.message);
      }
    })
  }

  const handleChange = (e) => {
    e.target.name === "name" && setBillingBody({...billingBody, name: e.target.value})
    e.target.name === "email" && setBillingBody({...billingBody, email: e.target.value})
    e.target.name === "phone" && setBillingBody({...billingBody, phone: e.target.value})
    e.target.name === "address" && setBillingBody({...billingBody, address: e.target.value})
    e.target.name === "country" && setBillingBody({...billingBody, country: e.target.value})
    e.target.name === "city" && setBillingBody({...billingBody, city: e.target.value})
    e.target.name === "zip_code" && setBillingBody({...billingBody, zip_code: e.target.value})
    e.target.name === "phone_country_code" && setBillingBody({...billingBody, phone_country_code: e.target.value})
  }

  return (
    <>
      <HomeLayout />
      <div className="application-content-box">
        <ProgressBar progress={studentApplicationById?.status} />
        <div className='application-box' style={{
          marginTop: (isUploadDocument || isBilling) && '112px'
        }}>
          {studentApplicationById?.status === "pending" &&
          <>
            <img 
              src={UnivResIcon} 
              alt="response-icon" 
              style={{
                width: '65px', 
                height: '77px',
                marginTop: '64px'
            }}/>
            <Typography variant="h3" component="h3" className="application-request-pending">
              Your Request Is Pending
            </Typography>
            <Typography variant="p" component="p" className="application-request-text">
              Your request is reviewing by collegeclue.
            </Typography>
          </>
          }
          {studentApplicationById?.status === "rejected" &&
          <>
            <DoDisturbIcon fontSize="large" sx={{
              color: '#EF0000',
              marginTop: '64px'
              }} />
            <Typography variant="h3" component="h3" className="application-request-pending res-text" sx={{
              color: '#EF0000'
            }}>
              Your Application Is Rejected By CollegeClue
            </Typography>
            <Typography variant="p" component="p" className="application-request-text res-big-text">
              {studentApplicationById?.reject_reason}
            </Typography>
          </>
          }
          {console.log("===========>",studentApplicationById)}
          {studentApplicationById?.status === "college_clue_accepted" &&
          <>
            <TaskAltIcon fontSize='large' sx={{
              marginTop: '64px'
            }} />
            <Typography variant="h3" component="h3" className="application-request-pending res-text" sx={{
              color: '#07AB35'
            }}>
              Your Request Is Accepted By The Collegeclue
            </Typography>
            <Typography variant="p" component="p" className="application-request-text">
              Next step is to pay the Registration Fee.
            </Typography>
            <Button 
              className="btn-pay-registration-fee" 
              disabled={buttonLoader}
              sx={{
                background: buttonLoader ? 'rgb(0, 0, 0, 0.2)' : '#07AB35',
                "&:hover":{
                  background: '#07AB35'
                }
              }}
              onClick={() => registrationFee(studentApplicationById?._id)}>
              {buttonLoader ? <LoaderIcon /> : 'Pay Registration Fee'}
            </Button>
          </>
          }
          {studentApplicationById?.status === "registration_fee_paid" &&
          <>
            <TaskAltIcon fontSize='large' sx={{
              marginTop: '64px'
            }} />
            <Typography variant="h3" component="h3" className="application-request-pending" sx={{
              color: '#07AB35'
            }}>
              Registration payment is successfull
            </Typography>
            <Typography variant="p" component="p" className="application-request-text">
              Now your Request has been send to the university
            </Typography>
            <Button 
              sx={{
                backgroundColor: buttonLoader ? 'rgb(0, 0, 0, 0.3)' : '#F69220',
                "&:hover":{
                  backgroundColor: '#F69220'
                }
              }}
              disabled={buttonLoader}
              className="btn-appl-continue" 
              onClick={() => {
                continueApplication(studentApplicationById?._id, 'target_pending')
                }}>
                {buttonLoader ? <LoaderIcon /> : 'Continue'}
            </Button>
          </>
          }
          {(studentApplicationById?.status === "target_pending") &&
          <>
            <img 
              src={UnivResIcon} 
              alt="response-icon" 
              style={{
                width: '65px', 
                height: '77px',
                marginTop: '64px'}} />
            <Typography variant="h3" component="h3" className="application-request-pending">
              Your Request Is Pending
            </Typography>
            <Typography variant="p" component="p" className="application-request-text">
              Your request has send to the Institute.
            </Typography>
          </>
          }
          {studentApplicationById?.status === "target_rejected" &&
          <>
            <DoDisturbIcon 
              fontSize="large" 
              sx={{
                color: '#EF0000',
                marginTop: '64px'}} />
            <Typography variant="h3" component="h3" className="application-request-pending res-text" sx={{
              color: '#EF0000'
            }}>
              Your Application Is Rejected By The University
            </Typography>
            <Typography variant="p" component="p" className="application-request-text res-big-text">
              {studentApplicationById?.reject_reason}
            </Typography>
          </>
          }
          {(studentApplicationById?.status === "target_upload_requested" && !isUploadDocument) &&
          <>
            <TaskAltIcon fontSize='large' sx={{
              marginTop: '64px'
            }} />
            <Typography variant="h3" component="h3" className="application-request-pending res-text" sx={{
              color: '#07AB35'
            }}>
              Your Application Is Accepted By The {studentApplicationById?.coaching_id 
              ? 'Coaching' : 'University'}
            </Typography>
            <Typography variant="p" component="p" className="application-request-text">
              Click on continue
            </Typography>
            <Button 
              sx={{
                backgroundColor: buttonLoader ? 'rgb(0, 0, 0, 0.3)' : '#F69220',
                "&:hover":{
                  backgroundColor: '#F69220'
                }
              }}
              className="btn-appl-continue" 
              onClick={() => setIsUploadDocument(true)}>
              Continue
            </Button>
          </>
          }
          {(studentApplicationById?.status === "target_upload_requested" && isUploadDocument) &&
          <>
            <div className="upload-doc-box">
              <div className="upload-inner-box">
                <Typography component="h2" variant="h2" className="input-label-text">
                  Upload Document
                </Typography>
                <div style={{
                    display: 'inline-block',
                }}>
                    {document && document?.map((item, index) => (
                        <div key={index} style={{
                            margin: '8px 12px 16px 0',
                            display: 'inline-block'
                        }}>
                            <UploadBox
                                name={`gallary-upload-${index}`}
                                value={item?.link ? item?.link : ''}
                                handleUpload={
                                  (name, value) => handleImageUploads(name, value, index)
                                } 
                            />
                        </div>
                    ))}
                    <div className="add-more-btn" style={{
                        display: 'inline-block',
                    }}>
                        <Button className={classes.addMore} 
                            sx={{backgroundColor: 'var(--primary-color)'}}
                            onClick={() => addMore()} 
                            variant="contained">
                            + Add More
                        </Button>
                    </div>
                </div>
                <div className='continue-btn-box'>
                  <Button 
                    className="btn-appl-continue" 
                    sx={{
                      backgroundColor: buttonLoader ? 'rgb(0, 0, 0, 0.3)' : '#F69220',
                      "&:hover":{
                        backgroundColor: '#F69220'
                      }
                    }}
                    disabled={buttonLoader}
                    onClick={() => documentSubmit(studentApplicationById?._id)}>
                      {buttonLoader ? <LoaderIcon /> : 'Continue'}
                    </Button>
                </div>
              </div>
              <div className="upload-doc-right">
                <Typography component="h2" variant="h2" className="input-label-text">
                  Documents that has to be upload:
                </Typography>
                <ul style={{
                  listStyleType: 'none',
                  padding: '0'
                }}>
                  {studentApplicationById?.documents?.map((item, index) => (
                    <li key={index}>
                      <span style={{
                        fontWeight: 'bold',
                      }}>Document {index+1} :</span> <span style={{
                        color: 'var(--primary-color)'
                      }}>
                        {item?.title}
                      </span>
                  </li>))}
                </ul>
              </div>
            </div>
          </>
          }
          {studentApplicationById?.status === "target_uploaded" &&
            <>
              <img src={UnivResIcon} alt="response-icon" 
                style={{
                  width: '65px', 
                  height: '77px',
                  marginTop: '64px'}} />
              <Typography variant="h3" component="h3" className="application-request-pending">
                Your Request Is Pending
              </Typography>
              <Typography variant="p" component="p" className="application-request-text">
                University is reviewing your Documents
              </Typography>
            </>
          }
          {studentApplicationById?.status === "target_changes_requested" &&
            <>
              <img src={UnivResIcon} alt="response-icon" style={{width: '65px', height: '77px'}} />
              <Typography variant="h3" component="h3" className="application-request-pending">
                Your Documents Require some changes
              </Typography>
              <Typography variant="p" component="p" sx={{
                  fontSize: '18px',
                  color: 'rgb(0, 0, 0, 0.5)',
              }}>
                  CHANGES:
              </Typography>
              <ul style={{
                listStyleType: 'numeric',
                paddingLeft: '16px'
              }}>
                {studentApplicationById?.requested_changes?.map((item, index) => (
                  <li key={index}>
                  {item}
                </li>))}
              </ul>
              <div style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '24px',
                marginBottom: '52px'
              }}>
                <Button 
                  className={classes.changesButton} 
                  onClick={() => {editDocument(studentApplicationById?._id)}}
                  disabled={buttonLoader}
                  sx={{
                    backgroundColor: buttonLoader ? 'rgb(0, 0, 0, 0.3)' : '#F69220',
                    "&:hover":{
                      backgroundColor: '#F69220'
                    }
                  }}>
                    {buttonLoader ? <LoaderIcon /> : 'Edit'} 
                </Button>
            </div>
            </>
          }
          {studentApplicationById?.status === "target_accepted" &&
            <>
              <TaskAltIcon fontSize='large' sx={{
                marginTop: '64px'
              }} />
              <Typography variant="h3" component="h3" className="application-request-pending res-text" sx={{
                color: '#07AB35'
              }}>
                Your Documents Are Accepted By The {studentApplicationById?.coaching_id ? 
                'Coaching' : 'University'}
              </Typography>
              <Typography variant="p" component="p" className="application-request-text">
                Click on continue
              </Typography>
              <Button 
                sx={{
                  backgroundColor: buttonLoader ? 'rgb(0, 0, 0, 0.3)' : '#F69220',
                  "&:hover":{
                    backgroundColor: '#F69220'
                  }
                }}
                disabled={buttonLoader}
                className="btn-appl-continue" 
                onClick={() => {
                  continueApplication(studentApplicationById?._id, "target_fee_pending")
                  }}>
                  {buttonLoader ? <LoaderIcon /> : 'Continue'}
              </Button>
            </>
          }
          {studentApplicationById?.status === "target_fee_pending" &&
          <>
            <div className="rsponsive-billing-box">
              <div className="billing-box-1">
                <div className="billing-form-box" style={{marginTop: '0'}}>
                  <div className="total-heading">
                      <div style={{
                        position: 'relative',
                      }}>
                        <h2>Billing Details</h2>
                        <div className="line"></div>
                      </div>
                      <div className={classes.marginBot24} style={{marginTop: '28px'}}>
                        <InputLabel className={classes.formInputLabel}>
                          Name
                        </InputLabel>
                        <TextField 
                          placeholder="" 
                          type="text" 
                          variant="outlined" 
                          name="name" 
                          InputProps={{
                            startAdornment: <PersonIcon position="start" fontSize='small'
                            sx={{
                              color: 'rgb(0, 0, 0, 0.4)',
                              marginRight: '4px'
                            }}></PersonIcon>,
                          }}
                          sx={{
                            width: '100%',
                            height: '35px'
                          }} 
                          value={billingBody?.name}
                          onChange={handleChange}
                          required/>
                      </div>
                      <div className={classes.marginBot24}>
                        <InputLabel className={classes.formInputLabel}>
                            Email ID
                        </InputLabel>
                        <div style={{
                                position: 'relative',
                            }}>
                            <TextField 
                              placeholder="" 
                              type="email" 
                              variant="outlined" 
                              sx={{
                                  width: '100%',
                                  height: '35px'
                              }} 
                              InputProps={{
                                  startAdornment: <EmailIcon position="start" fontSize='small'
                                  sx={{
                                    color: 'rgb(0, 0, 0, 0.4)',
                                    marginRight: '4px'
                                  }}></EmailIcon>,
                              }}
                              name="email" 
                              value={billingBody?.email}
                              onChange={handleChange}
                              required/>
                        </div>
                      </div>
                      <div className={classes.marginBot24}>
                          <InputLabel className={classes.formInputLabel}>Phone number</InputLabel>
                          <div style={{
                              display: 'flex'
                          }}>
                              <div style={{
                                  width: '100%',
                                  position: 'relative',
                                  display: 'flex',
                              }} className="input-box-appl-detail">
                                  {/* <TextField 
                                    variant="outlined" 
                                    name="phone_country_code"
                                    value={billingBody?.phone_country_code}
                                    onChange={handleChange}
                                    sx={{
                                        width: '12%',
                                        height: '35px',
                                        borderRadius: 'none'
                                    }}/> */}
                                  <TextField 
                                      type="number" 
                                      variant="outlined" 
                                      sx={{
                                          width: '100%',
                                          height: '35px',
                                          borderRadius: 'none'
                                      }} 
                                      InputProps={{
                                          startAdornment: <LocalPhoneIcon position="start" fontSize='small'
                                          sx={{
                                            color: 'rgb(0, 0, 0, 0.4)',
                                            marginRight: '4px'
                                          }}></LocalPhoneIcon>,
                                      }}
                                      name="phone" 
                                      value={billingBody?.phone}
                                      onChange={handleChange}
                                      required/>
                              </div>
                          </div>
                      </div>
                      <div className={classes.marginBot24}>
                        <InputLabel className={classes.formInputLabel}>
                            Address
                        </InputLabel>
                        <TextField 
                            InputProps={{
                                startAdornment: <RoomIcon position="start" fontSize='small'
                                sx={{
                                  color: 'rgb(0, 0, 0, 0.4)',
                                  marginRight: '4px'
                                }}></RoomIcon>,
                            }}
                            type="text" 
                            variant="outlined" 
                            sx={{
                                width: '100%',
                                height: '35px'
                            }} 
                            name="address"
                            className="input-box-appl-detail"
                            value={billingBody?.address}
                            onChange={handleChange} />
                      </div>
                      <div className="count-city-box">
                        <div className={classes.marginBot24}>
                          <InputLabel className={classes.formInputLabel}>
                              Country
                          </InputLabel>
                          <TextField 
                              InputProps={{
                                startAdornment: <PublicIcon position="start" fontSize='small'
                                sx={{
                                  color: 'rgb(0, 0, 0, 0.4)',
                                  marginRight: '4px'
                                }}></PublicIcon>,
                              }}
                              type="text" 
                              variant="outlined" 
                              sx={{
                                width: '100%',
                                height: '35px'
                              }} 
                              name="country"
                              className="input-box-appl-detail"
                              value={billingBody?.country}
                              onChange={handleChange} />
                        </div>
                        <div className={classes.marginBot24} style={{marginLeft: '12px'}}>
                          <InputLabel className={classes.formInputLabel}>
                              City
                          </InputLabel>
                          <TextField 
                              InputProps={{
                                  startAdornment: <HomeWorkIcon position="start" fontSize='small'
                                  sx={{
                                    color: 'rgb(0, 0, 0, 0.4)',
                                    marginRight: '4px'
                                  }}></HomeWorkIcon>,
                              }}
                              type="text" 
                              variant="outlined" 
                              sx={{
                                  width: '100%',
                                  height: '35px'
                              }} 
                              name="city"
                              className="input-box-appl-detail"
                              value={billingBody?.city}
                              onChange={handleChange} />
                        </div>
                      </div>
                      <div className="count-city-box">
                        <div className={classes.marginBot24}>
                          <InputLabel className={classes.formInputLabel}>
                              Zipcode
                          </InputLabel>
                          <TextField 
                              InputProps={{
                                startAdornment: <CreateIcon 
                                  position="start" 
                                  fontSize='small'
                                  sx={{
                                    color: 'rgb(0, 0, 0, 0.4)',
                                    marginRight: '4px'
                                  }}></CreateIcon>,
                              }}
                              type="number" 
                              variant="outlined" 
                              sx={{
                                width: '100%',
                                height: '35px'
                              }} 
                              name="zip_code"
                              className="input-box-appl-detail"
                              value={billingBody?.zip_code}
                              onChange={handleChange} />
                        </div>
                        <div className={classes.marginBot24} style={{marginLeft: '12px'}}>
                          
                        </div>
                      </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="billing-box-2" style={{marginTop: '0'}}>
                  <div className="total-heading">
                      <div style={{
                          position: 'relative',
                      }}>
                          <h2>Order Summary</h2>
                          <div className="line"></div>
                      </div>
                  </div>
                  <div style={{
                      borderBottom: '1px solid rgba(0, 0, 0, 0.15)'
                  }}>
                      <div className="product-value-count">
                          <p>Subtotal:</p>
                          <p>Rs {studentApplicationById?.fee_payment?.fees}</p>
                      </div>
                      <div className="product-value-count">
                          <p>Discount (-):</p>
                          <p>
                            {studentApplicationById?.fee_payment?.discount > 0 ?
                              (studentApplicationById?.is_percentage_discount
                                ? 
                                `Rs ${
                                  (studentApplicationById?.fee_payment?.fees/100)*
                                  studentApplicationById?.fee_payment?.discount}` : 
                                `Rs ${studentApplicationById?.fee_payment?.discount}`)
                            : `Rs 0`}
                          </p>
                      </div>
                      <div className="product-value-count">
                          <p>
                            GST {studentApplicationById?.fee_payment?.gst_percent}% (+):
                          </p>
                          <p>Rs {studentApplicationById?.fee_payment?.gst_percent > 0 ?
                              (((studentApplicationById?.fee_payment?.fees - (
                                studentApplicationById?.fee_payment?.discount > 0 ?
                                (studentApplicationById?.fee_payment?.is_percentage_discount ?
                                  parseInt((studentApplicationById?.fee_payment?.fees/100)*
                                  studentApplicationById?.fee_payment?.discount) :
                                    studentApplicationById?.fee_payment?.discount
                                  ) :
                                0 
                              ))/100)*
                              studentApplicationById?.fee_payment?.gst_percent)
                            : 0}</p>
                      </div>
                  </div>
                  <div className="product-value-count grand-total">
                      <p>Total:</p>
                      <p>Rs {studentApplicationById?.fee_payment?.total_amount}</p>
                  </div>
                  <p className="special-text">
                      Lorem ipsum dolor sit amet consectetur. Sollicitudin sed elementum dolor diam id neque.Lorem ipsum dolor sit amet consectetur. Sollicitudin 
                  </p>
                  <div style={{display: 'flex', justifyContent: 'center', marginTop: '24px'}}>
                      <Button 
                        type="button" 
                        disabled={buttonLoader}
                        sx={{
                          textTransform: 'capitalize',
                          color: 'white',
                          backgroundColor: buttonLoader ? 'rgb(0, 0, 0, 0.3)' : '#EF0000',
                          '&:hover':{
                            backgroundColor: '#EF0000',
                          }
                        }}
                        onClick={() => finalFeePayment(studentApplicationById?._id)}
                        className="checkout-btn pay-now">
                          {buttonLoader ? <LoaderIcon /> : 
                            <>Pay Now &nbsp; <EastIcon /></>}
                      </Button>
                  </div>
                </div>
              </div>
              <div className="appl-inst-detail">
                <div className="inst-logo">
                  {studentApplicationById?.university_id?.uploads?.university_logo && 
                  <img 
                    src={studentApplicationById?.university_id?.uploads?.university_logo}
                    alt="InstLogo" />}
                  {studentApplicationById?.coaching_id?.uploads?.coaching_logo && 
                  <img 
                    src={studentApplicationById?.coaching_id?.uploads?.coaching_logo}
                    alt="InstLogo" />}
                </div>
                <p>
                  {studentApplicationById?.university_name || 
                    studentApplicationById?.coaching_name}
                </p>
              </div>
            </div>
          </>
          }
        </div>
      </div>
      {studentApplicationById?.status !== "target_fee_pending" &&
      <div className="appl-inst-detail">
        <div className="inst-logo">
          {studentApplicationById?.university_id?.uploads?.university_logo && 
          <img 
            src={studentApplicationById?.university_id?.uploads?.university_logo}
            alt="InstLogo" />}
          {studentApplicationById?.coaching_id?.uploads?.coaching_logo && 
          <img 
            src={studentApplicationById?.coaching_id?.uploads?.coaching_logo}
            alt="InstLogo" />}
        </div>
        <p>
          {studentApplicationById?.university_name || 
            studentApplicationById?.coaching_name}
        </p>
      </div>}
    </>
  );
}