import * as React from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  Button,
  InputLabel,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import ContaactBackImage from "../../assest/images/contact-us.png";
import PhoneIcon from "../../assest/images/phone-icon.png";
import ContactDetailIcon from "../../assest/images/contact-detail-icon.png";
import validator from "validator";
import LoaderIcon from "../../components/Loader/Loader";
import SecondaryHead from "../../components/SecondaryHead/SecondaryHead";
import {addContactUs} from "../../redux/students/studentSlice";
import { toast } from "react-toastify";
import "./contact.css";
import { useState } from "react";
const useStyle = makeStyles({
    input: {
        "&::placeholder": {
          fontSize: '12px',
        },
    },
})

const contactData = [
    {
        icon: ContactDetailIcon,
        title: 'MAIN OFFICE LOCATION',
        description: 'Gopala Tower, Govind Lal Sikka Marg, Prasad Nagar, Patel Nagar, New Delhi-08'
    },
    {
        icon: PhoneIcon,
        title: 'CUSTOMER SUPPORT',
        description: '011-43256789'
    },
    {
        icon: PhoneIcon,
        title: 'CONTACT US',
        description: '+91-9876543210 info@prepclue.com'
    }
]

let formData = {
    name: "",
    email: "",
    phone: "",
    subject: "",
    company_name: "",
    message: ""
}

export default function ConactUs() {
    const dispatch = useDispatch();
    const [btnLoader, setBtnLoader] = useState(false);
    const [submitData, setSubmitData] = useState(formData);

    const handleChange = (e, name) => {
        if(name === "name"){
            setSubmitData({...submitData, name: e.target.value})
        }
        if(name === "email"){
            setSubmitData({...submitData, email: e.target.value})
        }
        if(name === "phone_number"){
            setSubmitData({...submitData, phone: e.target.value})
        }
        if(name === "company_name"){
            setSubmitData({...submitData, company_name: e.target.value})
        }
        if(name === "subject"){
            setSubmitData({...submitData, subject: e.target.value})
        }
        if(name === "message"){
            setSubmitData({...submitData, message: e.target.value})
        }
    }
    const submit = () => {
        if(validate().isValid){
            setBtnLoader(true);
            const res = dispatch(addContactUs(submitData));
            res?.then((r) =>{
                setBtnLoader(false);
                if(r?.payload?.statusCode === 200){
                    setSubmitData({...submitData,
                        name: "",
                        email: "",
                        phone: "",
                        subject: "",
                        company_name: "",
                        message: ""
                    });
                    toast.success("Message sent successfully");
                }else{
                    toast.error(r?.payload?.message);
                }
            })
        }else{
            toast.error(validate().message)
        }
    }

    const validate = () => {
        let status = {isValid: true, message: ""}
        if(submitData.name === ""){
            status.isValid = false;
            status.message = "Name required";
            return status;
        }
        if(submitData.email === ""){
            status.isValid = false;
            status.message = "Email required";
            return status;
        }
        if(!validator.isEmail(submitData.email)){
            status.isValid = false;
            status.message = "Invalid email entered";
            return status;
        }
        if(submitData.phone === ""){
            status.isValid = false;
            status.message = "Phone required";
            return status;
        }
        if(submitData.phone.length !== 10){
            status.isValid = false;
            status.message = "Invalid phone entered";
            return status;
        }
        if(submitData.company_name === ""){
            status.isValid = false;
            status.message = "Company name required";
            return status;
        }
        if(submitData.subject === ""){
            status.isValid = false;
            status.message = "Subject required";
            return status;
        }
        if(submitData.message === ""){
            status.isValid = false;
            status.message = "Message required";
            return status;
        }
        return status;

    }
    return (
        <>
            <SecondaryHead 
                heading={'Contact'} 
                headingH="370px"
                backImage={ContaactBackImage}
            />
            <Box sx={{p: 3}}>
                <section className="get-in-touch-section">
                    <h2 className="get-in-touch-text">
                        GET IN TOUCH
                    </h2>
                    <p className="get-in-touch-small-text">
                        If you have got questions or ideas you would like to share, send 
                        a message. For anything more specific, please use one of the 
                        addresses listed below.
                    </p>
                </section>
                <section className="contact-detail-section">
                    <div className="contact-detail-cards">
                        {contactData.map((item, index) => (
                        <div key={index} 
                            className="contact-detail-item" >
                            <img src={item?.icon} alt={`${item.title}Icon`} />
                            <h4 className={(index === 1 || index === 2) ? 'marginTop14' : ''}>
                                {item.title}
                            </h4>
                            {item.title !== "CONTACT US" ?
                                <p>{item.description}</p>:
                                <div style={{padding: '16px 0 24px 0'}}>
                                    <p style={{margin: 0, height: 'auto'}}>+91-9876543210</p>
                                    <p style={{margin: 0, height: 'auto'}}>info@prepclue.com</p>
                                </div>
                            }
                        </div>))}
                    </div>
                </section>
                <section className="get-in-touch-section">
                    <h2 className="get-in-touch-text">
                        LETS TALK ABOUT YOUR PROJECT
                    </h2>
                    <p className="get-in-touch-small-text">
                        After we get some information from you, we’ll setup a time to 
                        discuss your project in further detail.
                    </p>
                    <div className="dotted-line" style={{
                        margin: '12px 0 6px 0'
                    }}></div>
                    <div className="dotted-line"></div>
                </section>
                <section className="contact-form-boxx">
                    <div>
                        <iframe 
                            title="Google Map" 
                            src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d14326.268554658482!2d85.4065899!3d26.14565305!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1682530749233!5m2!1sen!2sin" 
                            className="map-aria"
                            style={{border: 0}} 
                            allowfullscreen="" 
                            loading="lazy" 
                            allow="fullscreen"
                            referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                    <form onSubmit={submit}>
                        <div className="contact-us-form">
                            <div className="contact-us-input-box">
                                <InputLabel className="input-label-text">Name</InputLabel>
                                <TextField 
                                    placeholder="" 
                                    type="text" 
                                    variant="outlined" 
                                    name="name"
                                    className="name-field" 
                                    onChange={(e) => handleChange(e, "name")}
                                    // value={applicationById?.name}
                                    required
                                />
                            </div>
                            <div className="contact-us-input-box">
                                <InputLabel className="input-label-text">Email</InputLabel>
                                <TextField 
                                    placeholder="" 
                                    type="email" 
                                    variant="outlined" 
                                    name="email"
                                    className="name-field" 
                                    onChange={(e) => handleChange(e, "email")}
                                    // value={applicationById?.name}
                                    required
                                />
                            </div>
                            <div className="contact-us-input-box">
                                <InputLabel className="input-label-text">
                                    Phone Number
                                </InputLabel>
                                <div style={{display: 'flex'}}>
                                    {/* <Select 
                                        onChange={handleChange}
                                        className="country-code" 
                                        value="+91" 
                                        sx={{
                                            color: 'rgb(0, 0, 0)',
                                            height: '40px',
                                            width: '100px',
                                            boxShadow: '0px 2px 10px 2px rgba(103, 47, 143, 0.3)',
                                            borderRadius: '4px'
                                        }}>
                                        {country.map((item, index) => (
                                            <MenuItem value={item.phonecode} key={index}>
                                                {item.phonecode}
                                            </MenuItem>
                                        ))}
                                    </Select> */}
                                    <TextField 
                                        placeholder="" 
                                        type="number" 
                                        variant="outlined" 
                                        name="phone_number"
                                        onChange={(e) => handleChange(e, "phone_number")}
                                        className="name-field" 
                                        // value={applicationById?.phone_number}
                                        required
                                    />   
                                </div>
                            </div>
                            <div className="contact-us-input-box">
                                <InputLabel className="input-label-text">
                                    Company Name
                                </InputLabel>
                                <TextField 
                                    placeholder="" 
                                    type="text" 
                                    variant="outlined" 
                                    name="company_name"
                                    className="name-field" 
                                    onChange={(e) => handleChange(e, "company_name")}
                                    // value={applicationById?.name}
                                    required
                                />
                            </div>
                            <div className="contact-us-input-box">
                                <InputLabel className="input-label-text">
                                    Subject
                                </InputLabel>
                                <TextField 
                                    placeholder="" 
                                    type="text" 
                                    variant="outlined" 
                                    name="subject"
                                    className="name-field" 
                                    onChange={(e) => handleChange(e, "subject")}
                                    // value={applicationById?.name}
                                    required
                                />
                            </div>
                            <div className="contact-us-message-box">
                                <InputLabel className="input-label-text">
                                    Message
                                </InputLabel>
                                <TextField 
                                    placeholder="Write Message" 
                                    type="text" 
                                    variant="outlined" 
                                    name="message" 
                                    className="message"
                                    // value={reviewData?.message}
                                    onChange={(e) => handleChange(e, "message")}
                                    rows={4}
                                    multiline
                                    required/>
                            </div>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                marginTop: '20px'
                            }}>
                                <Button 
                                disabled={btnLoader}
                                sx={{
                                    textTransform: 'capitalize',
                                    width: '96px',
                                    height: '32px',
                                    backgroundColor: btnLoader ? 'rgb(0, 0, 0, 0.3)' : 
                                                    'var(--primary-color)',
                                    color: '#fff',
                                    "&:hover":{
                                        backgroundColor: btnLoader ? 'rgb(0, 0, 0, 0.3)' :
                                         'var(--primary-color)',
                                    }
                                }} onClick={submit}>
                                    {btnLoader ? <LoaderIcon /> : 'Submit'}
                                </Button>
                            </div>
                        </div>
                    </form>
                </section>
            </Box>
        </>
    );
}
