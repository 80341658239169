import * as React from 'react';
import { makeStyles } from "@mui/styles";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import upload from '../../assest/svgs/upload.svg';
import UploadPinkIcon from '../../assest/svgs/upload-pink-icon.svg';
import getImageUrls from "../../api/uploadImageOnS3";
import LoaderIcon from "../../components/Loader/Loader";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import "./progressBar.css"
import { toast } from "react-toastify";
import {
    Button,
    Input,
    Typography,
  } from "@mui/material";

const useStyle = makeStyles({
    formIconsBox: {
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        marginTop: '5%',
        marginRight: '14%',
    },
    iconBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    horizontalLine: {
        height: '4px',
        backgroundColor: 'var(--primary-color)',
    },
    iconBox2: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'absolute', 
    },
    iconStyle: {
        backgroundColor: 'var(--primary-color)',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    iconText: {
        color: 'var(--primary-color)',
        marginTop: '8px !important',
        fontWeight: 'bold !important',
    },
    icon: {
        color: '#fff',
    }
})
const ProgressBar = (props) => {
    const classes = useStyle();
    
    return (
        <div className={classes.formIconsBox + ' reg-form-box'}>
            <div className={classes.iconBox+' icon-box-2'}>
                <div style={{position: 'relative'}}>
                    <div className={classes.horizontalLine+' horizontal-line-2'}></div>
                    <div className={classes.iconBox2+' icon-1 icon-1-1'}>
                        <div className={classes.iconStyle+' icon-style-2'} 
                            style={{
                                color: '#fff', 
                                fontSize: '20px'
                                }}>
                            i
                        </div>
                        <Typography component="h2" variant="h2" className={classes.iconText+' icon-text2'}>
                            Application Form
                        </Typography>
                    </div>
                </div>
                <div style={{position: 'relative'}}>
                    <div className={classes.horizontalLine+' horizontal-line-2'} 
                        style={
                            (props?.progress === "target_pending" || 
                            props?.progress === "target_rejected" ||
                            props?.progress === "target_upload_requested" ||
                            props?.progress === "target_uploaded" ||
                            props?.progress === "target_changes_requested" ||
                            props?.progress === "target_accepted" ||
                            props?.progress === "enrolled" ||
                            props?.progress === "target_fee_pending" ||
                            props?.progress === "registration_fee_paid") ?
                            {backgroundColor: 'var(--primary-color)'} :
                            {backgroundColor: 'var(--disable-color)'}
                        }></div>
                    <div className={classes.iconBox2+' icon-2 icon-2-1'}>
                        <div className={classes.iconStyle+' icon-style-2'} 
                        style={
                            (props?.progress === "target_pending" || 
                            props?.progress === "target_rejected" ||
                            props?.progress === "target_upload_requested" ||
                            props?.progress === "target_uploaded" ||
                            props?.progress === "target_changes_requested" ||
                            props?.progress === "target_accepted" ||
                            props?.progress === "enrolled" ||
                            props?.progress === "target_fee_pending" ||
                            props?.progress === "registration_fee_paid") ?
                            {backgroundColor: 'var(--primary-color)'} :
                            {backgroundColor: 'var(--disable-color)'}
                            }>
                            <CurrencyRupeeIcon className={classes.icon} fontSize="small" />
                        </div>
                        <Typography component="h2" variant="h2" className={classes.iconText+' icon-text2'} 
                        style={
                            (props?.progress === "target_pending" || 
                            props?.progress === "target_rejected" ||
                            props?.progress === "target_upload_requested" ||
                            props?.progress === "target_uploaded" ||
                            props?.progress === "target_changes_requested" ||
                            props?.progress === "target_accepted" ||
                            props?.progress === "target_fee_pending" ||
                            props?.progress === "enrolled" ||
                            props?.progress === "registration_fee_paid") ?
                            {color: 'var(--primary-color)'} :
                            {color: 'var(--disable-color)'}
                            }>
                            Registration Fee
                        </Typography>
                    </div>
                </div>
                <div style={{position: 'relative'}}>
                    <div className={classes.horizontalLine+' horizontal-line-2'} 
                        style={
                            (props?.progress === "target_upload_requested" ||
                            props?.progress === "target_uploaded" ||
                            props?.progress === "target_changes_requested" ||
                            props?.progress === "target_accepted" ||
                            props?.progress === "target_fee_pending" ||
                            props?.progress === "enrolled") ?
                            {backgroundColor: 'var(--primary-color)'} :
                            {backgroundColor: 'var(--disable-color)'}
                        }></div>
                    <div className={classes.iconBox2+' icon-3-p'}>
                        <div className={classes.iconStyle+' icon-style-2'} 
                            style={
                                (props?.progress === "target_upload_requested" ||
                                props?.progress === "target_uploaded" ||
                                props?.progress === "target_changes_requested" ||
                                props?.progress === "target_accepted" ||
                                props?.progress === "target_fee_pending" ||
                                props?.progress === "enrolled") ?
                                {backgroundColor: 'var(--primary-color)'} :
                                {backgroundColor: 'var(--disable-color)'}
                                }>
                            <CameraAltIcon className={classes.icon} fontSize="small" />
                        </div>
                        <Typography component="h2" variant="h2" className={classes.iconText+' icon-text2'} 
                        style={
                            (props?.progress === "target_upload_requested" ||
                            props?.progress === "target_uploaded" ||
                            props?.progress === "target_changes_requested" ||
                            props?.progress === "target_accepted" ||
                            props?.progress === "target_fee_pending" ||
                            props?.progress === "enrolled") ?
                            {color: 'var(--primary-color)'} :
                            {color: 'var(--disable-color)'}
                            }>
                            Upload Documents
                        </Typography>
                    </div>
                </div>
                <div style={{position: 'relative'}}>
                    <div className={classes.horizontalLine+' horizontal-line-2'} 
                        style={
                            (props?.progress === "target_fee_pending" ||
                            props?.progress === "enrolled") ?
                            {backgroundColor: 'var(--primary-color)'} :
                            {backgroundColor: 'var(--disable-color)'}
                            }></div>
                    <div className={classes.iconBox2+' icon-4-p'}>
                        <div className={classes.iconStyle+' icon-style-2'} 
                            style={
                                (props?.progress === "target_fee_pending" ||
                                props?.progress === "enrolled") ?
                                {backgroundColor: 'var(--primary-color)'} :
                                {backgroundColor: 'var(--disable-color)'}
                                }>
                            <BusinessCenterIcon className={classes.icon} fontSize="small" />
                        </div>
                        <Typography component="h2" variant="h2" className={classes.iconText+' icon-text2'} 
                        style={
                            (props?.progress === "target_fee_pending" ||
                            props?.progress === "enrolled") ?
                            {color: 'var(--primary-color)'} :
                            {color: 'var(--disable-color)'}
                            }>
                            University Payment
                        </Typography>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProgressBar;