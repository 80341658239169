import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Typography,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import routes from "../../../react_routes_enum";
import { makeStyles } from "@mui/styles";
import HeaderBackground from "../../../assest/images/header-background.jpg";
import HeaderIcon from "../../../assest/images/header-icon.png";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import Checkbox from '@mui/material/Checkbox';
import { useNavigate } from "react-router-dom";
import { getConfigDetails } from "../../../redux/configurationDetails/configDetailsSlice";
import { updateData, 
    getUniversityByToken, 
    getCoachingByToken } from "../../../redux/requestForm/requestFormSlice";
import { toast } from "react-toastify";
import LoaderIcon from "../../../components/Loader/Loader";
import Table from '../../../components/OtherTable/OtherTable';
import { logout, reset } from "../../../redux/auth/authSlice";

const useStyle = makeStyles({
    dot: {
        height: '16px',
        width: '16px',
        borderRadius: '50%',
    },
    line: {
        height: '4px',
        backgroundColor: 'rgb(246, 146, 32, 0.4)'
    },
    changesButton: {
        color: '#fff !important',
        textTransform: 'capitalize !important',
        width: '140px',
        height: '40px',
        fontSize: '16px !important',
        borderRadius: '24px !important',
        boxShadow: '0 2px 5px rgb(0, 0, 0, 0.5)',
        "&:hover":{
            backgroundColor: 'var(--secondary-color) !important',
        }
    },
    cancelButton: {
        backgroundColor: '#fff !important',
        textTransform: 'capitalize !important',
        width: '140px',
        height: '40px',
        fontSize: '16px !important',
        borderRadius: '24px !important',
        "&:hover":{
            backgroundColor: '#fff',
        }
    },
    header: {
        marginTop: '100px',
        marginBottom: '4%',
        backgroundImage: `url(${HeaderBackground})`,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    formIconsBox: {
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        marginTop: '5%',
        marginRight: '14%',
    },
    iconBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    horizontalLine: {
        height: '4px',
        backgroundColor: 'var(--primary-color)',
    },
    iconBox2: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'absolute', 
    },
    iconStyle: {
        backgroundColor: 'var(--primary-color)',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    iconText: {
        color: 'var(--primary-color)',
        marginTop: '8px !important',
    },
    icon: {
        color: '#fff',
    },
    tableNew: {
        border: '1px solid rgb(0, 0, 0, 0.2)',
        borderRadius: '8px',
        borderCollapse: 'collapse',
        overflowX: 'auto',
        width: '800px',
        boxShadow: '0px 2px 10px 2px rgba(103, 47, 143, 0.3)'
    },
    tableTh: {
        borderBottom: '1px solid rgb(0, 0, 0, 0.2)',
        display: 'flex',
        fontWeight: 'bold',
    },
    tableTd: {
        borderRight: '1px solid rgb(0, 0, 0, 0.2)',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center',
        height: '70px',
        width: '152px'
    },
    upload: {
        border: '1px solid rgb(0, 0, 0, 0.2) !important',
        fontSize: '10px !important',
        color: 'rgb(0, 0, 0, 0.4) !important',
        textTransform: 'capitalize !important',
        width: '80px',
        height: '20px',
        marginBottom: '4px'
    },
})

let courseOffered = {
    courses_offered_check: 1,
    courses_offered: {
        under_graduation: 1,
        post_graduation: 1,
        courses: [],
        fee_structure: [
            {
                course: "course 1",
                year: 1,
                fee: 0
            },
            {
                course: "course 1",
                year: 2,
                fee: 0
            },
            {
                course: "course 1",
                year: 3,
                fee: 0
            },
            {
                course: "course 1",
                year: 4,
                fee: 0
            },
            {
                course: "course 1",
                year: 5,
                fee: 0
            },
            {
                course: "course 2",
                year: 1,
                fee: 0
            },
            {
                course: "course 2",
                year: 2,
                fee: 0
            },
            {
                course: "course 2",
                year: 3,
                fee: 0
            },
            {
                course: "course 2",
                year: 4,
                fee: 0
            },
            {
                course: "course 2",
                year: 5,
                fee: 0
            },
            {
                course: "course 3",
                year: 1,
                fee: 0
            },
            {
                course: "course 3",
                year: 2,
                fee: 0
            },
            {
                course: "course 3",
                year: 3,
                fee: 0
            },
            {
                course: "course 3",
                year: 4,
                fee: 0
            },
            {
                course: "course 3",
                year: 5,
                fee: 0
            },
        ]
    },
}

let facultyDetails = {
    faculties_details_check: 1,
    faculties_details: [
        {
            name: "",
            experience_years: "",
            profile_picture: ""
        },
        {
            name: "",
            experience_years: "",
            profile_picture: ""
        },
        {
            name: "",
            experience_years: "",
            profile_picture: ""
        },
        {
            name: "",
            experience_years: "",
            profile_picture: ""
        },
        {
            name: "",
            experience_years: "",
            profile_picture: ""
        }
    ]
}

const topFacultyTitle =  ["Name of faculty", "Rank", "Year of experience", "Upload profile picture"]

export default function RequestRes() {
    const isWebsiteFor = localStorage.getItem("is-website-for");
    const [btnLoader, setBtnLoader] = React.useState(false);
    const [names, setNames] = React.useState(["", "", "", "", ""]);
    const [rank, setRank] = React.useState(["", "", "", "", ""]);
    const [epxerience, setExperience] = React.useState(["", "", "", "", ""]);
    const [profilePic, setProfilePic] = React.useState(["", "", "", "", ""]);
    const [coursesList, setCoursesList] = React.useState([]);
    const navigate = useNavigate();
    const classes = useStyle();
    const dispatch = useDispatch();
    const {
        universityByToken } = useSelector((state) => state.form);
    const [courseDetail, setCourseDetail] = React.useState((universityByToken?.data?.courses_offered_check) ? 
        {
            courses_offered_check: 1,
            courses_offered: {
                under_graduation: universityByToken?.data?.courses_offered?.under_graduation ? 1 : 0,
                post_graduation: universityByToken?.data?.courses_offered?.post_graduation ? 1 : 0,
                courses: universityByToken?.data?.courses_offered?.courses,
                fee_structure: universityByToken?.data?.courses_offered?.fee_structure
            }
        } : courseOffered);
    
    const saveAndNext = () => {
        if(isWebsiteFor === "coaching"){
            if(wrapperData().status){
                toast.error(wrapperData().message);
            }else{
                let facData = {
                    faculties_details_check: 1,
                    faculties_details: []
                };
                let newError = false;
                wrapperData().faculty?.faculties_details?.map((item, index) => {
                    if((item?.name !== "" && 
                        item?.experience_years !== "" && 
                        item?.profile_picture !== "")){
                            facData.faculties_details.push(item);
                    }else{
                        if(item?.name === "" && 
                        item?.experience_years === "" && 
                        item?.profile_picture === ""){

                        }else{
                            newError = true;
                        }
                    }
                })
                if(newError){
                    toast.error("Missing Relevant Field");
                    return;
                }
                setBtnLoader(true);
                let res = dispatch(updateData({isWebsiteFor, body: facData}));
                res.then((r) => {
                    setBtnLoader(false);
                    if(r.payload.statusCode === 200){
                        toast.success(r.payload.message);
                        dispatch(getCoachingByToken());
                        navigate(routes.COACHING_REGISTRATION_RESPONSE)
                    }else{
                        toast.error(r.payload.message);
                    }
                })
            }
        }
        else{
            if(courseDetail.courses_offered.courses.length === 0){
                return toast.error("Select Course");
            }
            if(courseDetail.courses_offered.fee_structure){
                let count = 0;
                courseDetail.courses_offered.fee_structure.map((item) => {
                    item.fee !== 0 && (count = count + 1); 
                })
                if(count === 0){
                    return toast.error("Fill Fee Structure");
                }
            }
            setBtnLoader(true);
            let res = dispatch(updateData({isWebsiteFor, body: courseDetail}));
            res.then((r) => {
                setBtnLoader(false);
                if(r.payload.statusCode === 200){
                    toast.success(r.payload.message);
                    dispatch(getUniversityByToken());
                    navigate(routes.UNIVERSITY_PLACEMENT)
                }else{
                    toast.error(r.payload.message);
                }
            })
        }
    }

    const wrapperData = () => {
        let data = {status: false, message: "", faculty: facultyDetails}
        facultyDetails.faculties_details.map((item, index) => {
            // if(names[index] && epxerience[index] && profilePic[index]){
                item.name = names[index];
                item.experience_years = epxerience[index];
                item.profile_picture = profilePic[index];
            // }else{
            //     data = {status: true, message: "Relevant data required", faculty: facultyDetails}
            // }
        })
        return data;
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    React.useEffect(() => {
        const res = isWebsiteFor === "coaching" ? dispatch(getCoachingByToken()) : dispatch(getUniversityByToken());
        res.then((r) => {
            if(r?.payload?.statusCode === 200) {
                if(isWebsiteFor === "coaching"){
                    if(r.payload?.data?.faculties_details_check){
                        let nameFrom = [];
                        let experienceFrom = [];
                        let profilePicFrom = [];
                        [1, 2, 3, 4, 5]?.map((item, index) => {
                            nameFrom[index] = r.payload?.data?.faculties_details[index]?.name ? 
                                r.payload?.data?.faculties_details[index]?.name : '';
                            experienceFrom[index] = r.payload?.data?.faculties_details[index]?.experience_years ? 
                                r.payload?.data?.faculties_details[index]?.experience_years : '';
                            profilePicFrom[index] = r.payload?.data?.faculties_details[index]?.profile_picture ? 
                                r.payload?.data?.faculties_details[index]?.profile_picture : '';
                        })
                        // r.payload?.data?.faculties_details?.map((item, index) => {
                        //     nameFrom[index] = item?.name;
                        //     rankFrom[index] = item?.rank;
                        //     experienceFrom[index] = item?.experience_years;
                        //     profilePicFrom[index] = item?.profile_picture;
                        // })
                        setNames([...nameFrom]);
                        setExperience([...experienceFrom]);
                        setProfilePic([...profilePicFrom]);
                    }
                }else{
                    if(r.payload?.data?.courses_offered_check){
                        setCourseDetail({
                            courses_offered_check: 1,
                            courses_offered: {
                                under_graduation: r.payload?.data?.courses_offered?.under_graduation ? 1 : 0,
                                post_graduation: r.payload?.data?.courses_offered?.post_graduation ? 1 : 0,
                                courses: r.payload?.data?.courses_offered?.courses,
                                fee_structure: r.payload?.data?.courses_offered?.fee_structure
                            }
                        })
                    }
                }
            }
        })
    },[])
    const courseChangeData = (e) => {
        if(e.target.name === "select_course"){
            if(e.target.value.length > 3){
                toast.error("Max 3 Courses Allowed")
                return;
            }
            setCourseDetail({
            ...courseDetail, 
            courses_offered: {
                ...courseDetail.courses_offered,
                courses: e.target.value
            }
        })}
        e.target.name === "under_grad" && (setCourseDetail({
            ...courseDetail, 
            courses_offered: {
                ...courseDetail.courses_offered,
                under_graduation: e.target.checked ? 1 : 0
            }
        }))
        e.target.name === "post_grad" && (setCourseDetail({
            ...courseDetail, 
            courses_offered: {
                ...courseDetail.courses_offered,
                post_graduation: e.target.checked ? 1 : 0
            }
        }))
    }
    const courseChange = (e, index, year, course) => {
        let localArray = [];
        let array = [...courseDetail?.courses_offered?.fee_structure]
        localArray = array.map((element, i) => { 
            return i === index ? {...element, 
            ...(e.target.name === `${course}_${year}` && {fee: Number(e.target.value)})
            }: element
        })
        setCourseDetail({
            ...courseDetail, 
            courses_offered: {
                ...courseDetail.courses_offered,
                fee_structure: localArray
            }
        })
    }

    const cancelClicked = () => {
        // navigate(routes.REQUEST_FORM);
        setBtnLoader(false);
        dispatch(logout());
        dispatch(reset());
        navigate("/login");
    }
    const sumFunction = (array, course) => {
        let total = 0;
        array.map((item) => {
            if(item.course === course){
                total = total + item.fee
            }
        });
        return total;
    }
    // eslint-disable-next-line react-hooks/rules-of-hooks
    React.useEffect(() => {
        const res = dispatch(getConfigDetails());
        res?.then((r) => {
            if(r?.payload?.statusCode === 200){
                console.log(r?.payload?.data)
                r?.payload?.data?.map((d) => {
                    d?.configuration_type === "university_courses" &&
                    setCoursesList(d?.data)
                })
            }
        })
    }, [])
    return (
        <Box>
            <section className={classes.header + ' header-set'}>
                <div className="header-icon-box">
                    <img src={HeaderIcon} alt="header-icon" style={{width: '100%'}} />
                </div>
                <Typography component="h2" variant="h2" className="header-heading">
                    UNIVERSITY - LISTING
                </Typography>
                <Typography component="p" variant="p" className="small-header-heading">
                    Lorem ipsum dolor sit amet consectetur.
                </Typography>
            </section>
            <Box sx={{p: 3}}>
                <section>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginBottom: '8px'
                        }} className="progr-lin-box">
                            <div className={classes.dot} style={{
                                background: 'none',
                                }}>
                                <CheckCircleOutlineIcon sx={{width: '100%', color: '#07AB35'}} />
                            </div>
                            <div className={classes.line + ' progress-line'} style={{background: 'none'}}>

                            </div>
                            <div className={classes.dot} style={{
                                background: 'none',
                                visibility: (universityByToken?.data?.information_check && universityByToken?.data?.uploads_check &&
                                    universityByToken?.data?.courses_offered_check && universityByToken?.data?.placement_records_check
                                    ) ? 
                                    'visible' : 'hidden'
                                }}>
                                <CheckCircleOutlineIcon sx={{
                                    width: '100%', 
                                    color: '#07AB35',
                                    }} />
                            </div>
                            <div className={classes.line + ' progress-line'} style={{background: 'none'}}>

                            </div>
                            <div className={classes.dot} style={{
                                background: 'none',
                                visibility: (universityByToken?.data?.information_check && universityByToken?.data?.uploads_check &&
                                    universityByToken?.data?.courses_offered_check && universityByToken?.data?.placement_records_check
                                    && universityByToken?.data?.request_status === "accepted") ? 
                                    'visible' : 'hidden'
                                }}> 
                                <CheckCircleOutlineIcon sx={{width: '100%', color: '#07AB35'}} />
                            </div>
                        </div>
                    </div>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }} className="progr-lin-box">
                            <div className={classes.dot} style={{backgroundColor: 'var(--secondary-color)'}}>
                                
                            </div>
                            <div className={classes.line + ' progress-line'} style={{
                                backgroundColor: (universityByToken?.data?.information_check && 
                                    universityByToken?.data?.uploads_check &&
                                    universityByToken?.data?.courses_offered_check && 
                                    universityByToken?.data?.placement_records_check) ? 'var(--secondary-color)' :
                                    'rgb(246, 146, 32, 0.4)'
                            }}>

                            </div>
                            <div className={classes.dot} style={{backgroundColor: (universityByToken?.data?.information_check && 
                                    universityByToken?.data?.uploads_check &&
                                    universityByToken?.data?.courses_offered_check && 
                                    universityByToken?.data?.placement_records_check) ? 'var(--secondary-color)' :
                                    'rgb(246, 146, 32, 0.4)'}}>

                            </div>
                            <div className={classes.line + ' progress-line'} style={{
                                backgroundColor: (universityByToken?.data?.information_check && 
                                    universityByToken?.data?.uploads_check &&
                                    universityByToken?.data?.courses_offered_check && 
                                    universityByToken?.data?.placement_records_check &&
                                    universityByToken?.data?.request_status === "accepted") ? 'var(--secondary-color)' :
                                    'rgb(246, 146, 32, 0.4)'
                            }}>

                            </div>
                            <div className={classes.dot} style={{backgroundColor: (universityByToken?.data?.information_check && 
                                    universityByToken?.data?.uploads_check &&
                                    universityByToken?.data?.courses_offered_check && 
                                    universityByToken?.data?.placement_records_check &&
                                    universityByToken?.data?.request_status === "accepted") ? 'var(--secondary-color)' :
                                    'rgb(246, 146, 32, 0.4)'}}>
                            </div>
                        </div>
                    </div>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '12px'
                    }}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            justifyContent: 'space-between',
                        }} className="progr-text-box">
                            <Typography component="h2" variant="h2" className="progr-text">
                                Fill the Request Form
                            </Typography>
                            <Typography component="h2" variant="h2" className="progr-text">
                                Fill the Listing Registration Form
                            </Typography>
                            <Typography component="h2" variant="h2" className="progr-text">
                                LIVE Listing
                            </Typography>
                        </div>
                    </div>
                </section>
                <section>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    }} className="request-main-res-box">
                        <div style={{
                            width: '100%',
                        }}>
                            <div className={classes.formIconsBox + ' reg-form-box'}>
                                <div className={classes.iconBox+' icon-box-2'}>
                                    <div style={{position: 'relative'}}>
                                        <div className={classes.horizontalLine+' horizontal-line-2'}></div>
                                        <div className={classes.iconBox2+' icon-1'}>
                                            <div className={classes.iconStyle+' icon-style-2'} 
                                                style={{
                                                    color: '#fff', 
                                                    fontSize: '20px'
                                                    }}>
                                                i
                                            </div>
                                            <Typography component="h2" variant="h2" className={classes.iconText+' icon-text'}>
                                                Information
                                            </Typography>
                                        </div>
                                    </div>
                                    <div style={{position: 'relative'}}>
                                        <div className={classes.horizontalLine+' horizontal-line-2'} 
                                            style={{backgroundColor: 'var(--primary-color)'}}></div>
                                        <div className={classes.iconBox2+' icon-2'}>
                                            <div className={classes.iconStyle+' icon-style-2'} 
                                                style={{backgroundColor: 'var(--primary-color)'}}>
                                                <CameraAltIcon className={classes.icon} fontSize="small" />
                                            </div>
                                            <Typography component="h2" variant="h2" className={classes.iconText+' icon-text'} 
                                            style={{color: 'var(--primary-color)'}}>
                                                Uploads
                                            </Typography>
                                        </div>
                                    </div>
                                    <div style={{position: 'relative'}}>
                                        <div className={classes.horizontalLine+' horizontal-line-2'} 
                                            style={{backgroundColor: 'var(--primary-color)'}}></div>
                                        <div className={classes.iconBox2+' icon-3'}>
                                            <div className={classes.iconStyle+' icon-style-2'} 
                                                style={{backgroundColor: 'var(--primary-color)'}}>
                                                <MenuBookIcon className={classes.icon} fontSize="small" />
                                            </div>
                                            <Typography component="h2" variant="h2" className={classes.iconText+' icon-text'} 
                                            style={{color: 'var(--primary-color)'}}>
                                                {isWebsiteFor === "university" ? "Courses" : "Faculties"}
                                            </Typography>
                                        </div>
                                    </div>
                                    {isWebsiteFor === "university" && 
                                        <div style={{position: 'relative'}}>
                                            <div className={classes.horizontalLine+' horizontal-line-2'} 
                                                style={{backgroundColor: 'var(--disable-color)'}}></div>
                                            <div className={classes.iconBox2+' icon-4'}>
                                                <div className={classes.iconStyle+' icon-style-2'} 
                                                    style={{backgroundColor: 'var(--disable-color)'}}>
                                                    <BusinessCenterIcon className={classes.icon} fontSize="small" />
                                                </div>
                                                <Typography component="h2" variant="h2" className={classes.iconText+' icon-text'} 
                                                style={{color: 'var(--disable-color)', textAlign: 'center'}}>
                                                    Placement Records
                                                </Typography>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            {isWebsiteFor === "university" && 
                            <>
                                <div style={{
                                    marginTop: '130px'
                                }} className="upload-text-box">
                                    <Typography component="h2" variant="h2" className="upload-doc-text">
                                        Courses Offered
                                    </Typography>
                                    <Typography component="p" variant="p" className="upload-doc-small-text" 
                                        sx={{
                                            fontSize: '14px',
                                            color: 'rgb(0, 0, 0, 0.5)'
                                            }}>
                                        Let us know what type of courses you offer
                                    </Typography>
                                </div>
                                <div className="course-detail-aria">
                                    <div className="checkbox-box">
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            <Checkbox className={classes.checkBox} name="under_grad" onChange={courseChangeData} 
                                                checked={courseDetail?.courses_offered?.under_graduation ? true : false}/>
                                            <InputLabel className="input-label-text">
                                                Under Graduation
                                            </InputLabel>
                                        </div>
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            <Checkbox className={classes.checkBox} name="post_grad" onChange={courseChangeData} 
                                                checked={courseDetail?.courses_offered?.post_graduation ? true : false}/>
                                            <InputLabel className="input-label-text">Post Graduation</InputLabel>
                                        </div>
                                    </div>
                                    <div style={{
                                        marginLeft: '0.8%',
                                        marginTop: '20px',
                                    }}>
                                        <InputLabel className="input-label-text">Courses</InputLabel>
                                        <Select
                                            multiple
                                            name="select_course"
                                            className="select-class select-c"
                                            value={courseDetail?.courses_offered?.courses}
                                            onChange={courseChangeData}
                                            placeholder="course"
                                            autoWidth
                                            sx={{
                                                height: '56px',
                                                position: 'relative',
                                                marginRight: '12px',
                                                boxShadow: '0px 2px 10px 2px rgba(103, 47, 143, 0.3)',
                                                border: 'none'
                                            }}
                                            >
                                                {coursesList?.map((item, index) => (
                                                    <MenuItem 
                                                    key={index}
                                                    value={item}>{item}</MenuItem>
                                                ))}
                                        </Select>
                                    </div>
                                    <Typography component="h2" variant="h2" 
                                        sx={{
                                            margin: '3% 0 1% 0.8%'
                                        }} className="input-label-text">
                                            Fees structure for courses
                                    </Typography>
                                    <div className="table-aria-fee">
                                        <div className={classes.tableNew}>
                                            <div className={classes.tableTh}>
                                                <div className={classes.tableTd+' table-td'}>Fees for calender year 2023</div>
                                                <div className={classes.tableTd+' table-td'}>Year 1</div>
                                                <div className={classes.tableTd+' table-td'}>Year 2</div>
                                                <div className={classes.tableTd+' table-td'}>Year 3</div>
                                                <div className={classes.tableTd+' table-td'}>Year 4</div>
                                                <div className={classes.tableTd+' table-td'}>year 5</div>
                                                <div className={classes.tableTd+' table-td'} style={{borderRight: 'none'}}>Total Fees</div>
                                            </div>
                                            <div className={classes.tableTh}>
                                                <div className={classes.tableTd+' table-td'}>
                                                    {courseDetail?.courses_offered?.courses[0] ?
                                                    courseDetail?.courses_offered?.courses[0] : 'Course 1'}
                                                </div>
                                                {courseDetail?.courses_offered?.fee_structure.map((item, index) => (
                                                    item.course === "course 1" &&
                                                        <div className={classes.tableTd} style={{
                                                            padding: 0,
                                                        }} key={index}>
                                                            <input type="number" 
                                                                value={item.fee === 0 ? '' : item.fee}
                                                                onChange={(e) => {courseChange(e, index, item.year, "course_1")}}
                                                                name={`course_1_${item.year}`}
                                                                style={{
                                                                    width: '100%',
                                                                    height: '100%',
                                                                    border: 'none'}} />
                                                        </div>
                                                ))}
                                                <div className={classes.tableTd} style={{
                                                    borderRight: 'none',
                                                    padding: 0}}>
                                                    {sumFunction(courseDetail?.courses_offered?.fee_structure, "course 1")}
                                                </div>
                                            </div>
                                            <div className={classes.tableTh}>
                                                <div className={classes.tableTd+' table-td'}>
                                                    {courseDetail?.courses_offered?.courses[1] ?
                                                        courseDetail?.courses_offered?.courses[1] : 'Course 2'}
                                                </div>
                                                {courseDetail?.courses_offered?.fee_structure.map((item, index) => (
                                                    item.course === "course 2" &&
                                                        <div className={classes.tableTd} style={{
                                                            padding: 0,
                                                        }} key={index}>
                                                            <input type="number" 
                                                                value={item.fee === 0 ? '' : item.fee}
                                                                onChange={(e) => {courseChange(e, index, item.year, "course_2")}}
                                                                name={`course_2_${item.year}`}
                                                                style={{
                                                                    width: '100%',
                                                                    height: '100%',
                                                                    border: 'none'}} />
                                                        </div>
                                                ))}
                                                <div className={classes.tableTd} style={{
                                                    borderRight: 'none',
                                                    padding: 0}}>
                                                    {sumFunction(courseDetail?.courses_offered?.fee_structure, "course 2")}
                                                </div>
                                            </div>
                                            <div className={classes.tableTh} style={{border: 'none'}}>
                                                <div className={classes.tableTd+' table-td'}>
                                                    {courseDetail?.courses_offered?.courses[2] ?
                                                    courseDetail?.courses_offered?.courses[2] : 'Course 3'}
                                                </div>
                                                {courseDetail?.courses_offered?.fee_structure.map((item, index) => (
                                                    item.course === "course 3" &&
                                                        <div className={classes.tableTd} style={{
                                                            padding: 0,
                                                        }} key={index}>
                                                            <input type="number" 
                                                                value={item.fee === 0 ? '' : item.fee}
                                                                onChange={(e) => {courseChange(e, index, item.year, "course_3")}}
                                                                name={`course_3_${item.year}`}
                                                                style={{
                                                                    width: '100%',
                                                                    height: '100%',
                                                                    border: 'none'}} />
                                                        </div>
                                                ))}
                                                <div className={classes.tableTd} style={{
                                                    borderRight: 'none',
                                                    padding: 0}}>
                                                    {sumFunction(courseDetail?.courses_offered?.fee_structure, "course 3")}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>}
                            {isWebsiteFor === "coaching" &&
                            <div className="course-detail-aria" style={{
                                marginTop: '120px'
                            }}>
                                <Table 
                                    profilePic={profilePic}
                                    experience={epxerience}
                                    setExperience={(exp) => setExperience([...exp])}
                                    setNames={(name) => setNames([...name])}
                                    rank={rank}
                                    setRank={(rank) => setRank([...rank])}
                                    names={names}
                                    tableWidth="100%"
                                    uploadPicFor="faculties"
                                    title="Top Faculties In Your Institute"
                                    subTitle={topFacultyTitle}
                                    isOptionalText={true}  />
                            </div>
                            }
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                marginTop: '48px',
                                marginBottom: '52px'
                            }}>
                                <Button className={classes.changesButton} onClick={saveAndNext} disabled={btnLoader} sx={{
                                    backgroundColor: btnLoader ? 'var(--disabled-color)' : (
                                        isWebsiteFor === 'coaching' ? 'var(--tertiary-color)' : 'var(--secondary-color)')
                                }}>
                                    {btnLoader ? <LoaderIcon/> : "Save & Next"}
                                </Button>
                                <Button 
                                    variant="outlined" 
                                    className={classes.cancelButton} 
                                    onClick={cancelClicked}
                                    sx={{
                                        color: isWebsiteFor === 'coaching' ? 'var(--tertiary-color)' : 'var(--secondary-color)',
                                        borderColor: isWebsiteFor === 'coaching' ? 'var(--tertiary-color)' : 'var(--secondary-color)',
                                        marginLeft: '16px'
                                    }}>
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </div>
                </section>
            </Box>
        </Box>
    );
}
