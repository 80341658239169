import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import studentsServices from "./studentServices";

const initialState = {
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  instituteData: null,
  instituteDataById: null,
  totalCount: 0,
  isLoadingforSearch: false,
  isErrorforSearch: false,
  messageforSearch: false,
  searchText: undefined,
  studentById: null,
  studentId: null,
  reviewsData: null,
  productData: null,
  instituteId: null,
  addReviewResponse: null,
  productById: null,
  addonsByProductId: null,
  addToCartRes: null,
  subproductByProductId: null,
  postStudentApplicationRes: null,
  reviewStats: null,
  cartData: null,
  studentApplication: null,
  studentApplicationById: null,
  registrationFeeRes: null,
  aaplicationUpdateRes: null,
  payFinalFeeRes: null,
  blogById: null,
  instCat: null,
  fromBuy: null,
  courseById: null,
  purchaseList: null,
};

export const getStudentUniversity = createAsyncThunk(
    "students/getStudentUniversity",
    async (params, thunkAPI) => {
      try {
        return await studentsServices.getStudentUniversity(params);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
);

export const getStudentCoaching = createAsyncThunk(
  "students/getStudentCoaching",
  async (params, thunkAPI) => {
    try {
      return await studentsServices.getStudentCoaching(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getInstituteById = createAsyncThunk(
  "students/getInstituteById",
  async (params, thunkAPI) => {
    try {
      return await studentsServices.getInstituteById(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getProductById = createAsyncThunk(
  "students/getProductById",
  async (params, thunkAPI) => {
    try {
      return await studentsServices.getProductById(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const addReview = createAsyncThunk(
  "students/addReview",
  async (params, thunkAPI) => {
    try {
      return await studentsServices.addReview(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getReviews = createAsyncThunk(
  "students/getReviews",
  async (params, thunkAPI) => {
    try {
      return await studentsServices.getReviews(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getInstProducts = createAsyncThunk(
  "students/getInstProducts",
  async (params, thunkAPI) => {
    try {
      return await studentsServices.getInstProducts(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAddonsByProductId = createAsyncThunk(
  "students/getAddonsByProductId",
  async (params, thunkAPI) => {
    try {
      return await studentsServices.getAddonsByProductId(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getSubProductByProductId = createAsyncThunk(
  "students/getSubProductByProductId",
  async (params, thunkAPI) => {
    try {
      return await studentsServices.getSubProductByProductId(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const payRegistrationFee = createAsyncThunk(
  "students/payRegistrationFee",
  async (params, thunkAPI) => {
    try {
      return await studentsServices.payRegistrationFee(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const payFinalFee = createAsyncThunk(
  "students/payFinalFee",
  async (params, thunkAPI) => {
    try {
      return await studentsServices.payFinalFee(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getReviewStats = createAsyncThunk(
  "students/getReviewStats",
  async (params, thunkAPI) => {
    try {
      return await studentsServices.getReviewStats(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getFromCart = createAsyncThunk(
  "students/getFromCart",
  async (params, thunkAPI) => {
    try {
      return await studentsServices.getFromCart(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deletFromCart = createAsyncThunk(
  "students/deletFromCart",
  async (params, thunkAPI) => {
    try {
      return await studentsServices.deletFromCart(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getStudentApplication = createAsyncThunk(
  "students/getStudentApplication",
  async (params, thunkAPI) => {
    try {
      return await studentsServices.getStudentApplication(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateStudentApplication = createAsyncThunk(
  "students/updateStudentApplication",
  async (params, thunkAPI) => {
    try {
      return await studentsServices.updateStudentApplication(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getStudentApplicationById = createAsyncThunk(
  "students/getStudentApplicationById",
  async (params, thunkAPI) => {
    try {
      return await studentsServices.getStudentApplicationById(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getCourseById = createAsyncThunk(
  "students/getCourseById",
  async (params, thunkAPI) => {
    try {
      return await studentsServices.getCourseById(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const addToCart = createAsyncThunk(
  "students/addToCart",
  async (params, thunkAPI) => {
    try {
      return await studentsServices.addToCart(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const decrementFromCart = createAsyncThunk(
  "students/decrementFromCart",
  async (params, thunkAPI) => {
    try {
      return await studentsServices.decrementFromCart(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const postStudentApplication = createAsyncThunk(
  "students/postStudentApplication",
  async (params, thunkAPI) => {
    try {
      return await studentsServices.postStudentApplication(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const addContactUs = createAsyncThunk(
  "students/addContactUs",
  async (params, thunkAPI) => {
    try {
      return await studentsServices.addContactUs(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getBlogs = createAsyncThunk(
  "students/getBlogs",
  async (params, thunkAPI) => {
    try {
      return await studentsServices.getBlogs(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getBlogById = createAsyncThunk(
  "students/getBlogById",
  async (params, thunkAPI) => {
    try {
      return await studentsServices.getBlogById(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getPurchase = createAsyncThunk(
  "students/getPurchase",
  async (params, thunkAPI) => {
    try {
      return await studentsServices.getPurchase(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateStudentProfile = createAsyncThunk(
  "students/updateStudentProfile",
  async (params, thunkAPI) => {
    try {
      return await studentsServices.updateStudentProfile(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const courseProgress = createAsyncThunk(
  "students/courseProgress",
  async (params, thunkAPI) => {
    try {
      return await studentsServices.courseProgress(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const studentsSlice = createSlice({
  name: "posts",
  initialState,
  reducers: {
    setSearchText: (state, action) => {
        state.searchText = action.payload;
    },
    setStudentsById: (state, action) => {
        state.studentId = action.payload;
    },
    setInstituteId: (state, action) => {
      state.instituteId = action.payload;
    },
    setInstituteCat: (state, action) => {
      state.instCat = action.payload;
    },
    setFromBuy: (state, action) => {
      state.fromBuy = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStudentUniversity.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getStudentUniversity.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getStudentUniversity.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = action.payload.message;
        state.totalCount = action.payload.data?.totalCount;
        state.instituteData = action.payload.data?.result;
      })
      .addCase(getStudentCoaching.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getStudentCoaching.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getStudentCoaching.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = action.payload.message;
        state.totalCount = action.payload.data?.totalCount;
        state.instituteData = action.payload.data?.result;
      })
      .addCase(getInstituteById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getInstituteById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getInstituteById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = action.payload.message;
        state.instituteDataById = action.payload.data;
        localStorage.setItem("coachingDetail", JSON.stringify(action.payload.data));
      })
      .addCase(getReviews.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getReviews.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getReviews.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = action.payload.message;
        state.reviewsData = action.payload.data.result;
      })
      .addCase(getInstProducts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getInstProducts.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getInstProducts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = action.payload.message;
        state.productData = action.payload.data;
      })
      .addCase(addReview.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addReview.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(addReview.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.addReviewResponse = action.payload;
      })
      .addCase(getProductById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProductById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getProductById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.productById = action.payload.data;
      })
      .addCase(getAddonsByProductId.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAddonsByProductId.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getAddonsByProductId.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.addonsByProductId = action.payload.data.result;
      })
      .addCase(addToCart.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addToCart.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(addToCart.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.addToCartRes = action.payload.data;
      })
      .addCase(decrementFromCart.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(decrementFromCart.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(decrementFromCart.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(getSubProductByProductId.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSubProductByProductId.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getSubProductByProductId.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.subproductByProductId = action.payload.data.result;
      })
      .addCase(postStudentApplication.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postStudentApplication.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(postStudentApplication.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.postStudentApplicationRes = action.payload.data;
      })
      .addCase(getReviewStats.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getReviewStats.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getReviewStats.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.reviewStats = action.payload.data.result;
      })
      .addCase(getFromCart.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getFromCart.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getFromCart.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.totalCount = action.payload.data?.result?.length;
        state.cartData = action.payload.data.result;
      })
      .addCase(getStudentApplication.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getStudentApplication.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getStudentApplication.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.totalCount = action.payload.data.totalCount;
        state.studentApplication = action.payload.data.result;
      })
      .addCase(getStudentApplicationById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getStudentApplicationById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getStudentApplicationById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.studentApplicationById = action.payload.data;
      })
      .addCase(payRegistrationFee.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(payRegistrationFee.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(payRegistrationFee.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.registrationFeeRes = action.payload.data;
      })
      .addCase(updateStudentApplication.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateStudentApplication.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateStudentApplication.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.aaplicationUpdateRes = action.payload.data;
      })
      .addCase(payFinalFee.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(payFinalFee.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(payFinalFee.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.payFinalFeeRes = action.payload.data;
      })
      .addCase(deletFromCart.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deletFromCart.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deletFromCart.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.payFinalFeeRes = action.payload.data;
      })
      .addCase(getBlogs.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBlogs.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getBlogs.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.totalCount = action.payload.data.totalCount;
        state.blogsData = action.payload.data.result;
      })
      .addCase(getBlogById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBlogById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getBlogById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.blogById = action.payload.data;
      })
      .addCase(addContactUs.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addContactUs.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(addContactUs.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = action.payload;
      })
      .addCase(updateStudentProfile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateStudentProfile.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateStudentProfile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = action.payload;
      })
      .addCase(getCourseById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCourseById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getCourseById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = action.payload;
        state.courseById = action.payload.data;
      })
      .addCase(courseProgress.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(courseProgress.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(courseProgress.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = action.payload;
      })
      .addCase(getPurchase.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPurchase.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getPurchase.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = action.payload;
        state.purchaseList = action.payload.data;
        state.totalCount = action.payload.data.totalCount;
      })
  },
});

// export const { setPostsFilterDetails } = addTrainerSlice.actions;
export const { 
    setSearchText, 
    setStudentsById,
    setInstituteId,
    setInstituteCat,
    setFromBuy } = studentsSlice.actions;

export default studentsSlice.reducer;
