import * as React from "react";
import {
  Box,
  Button,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import SchoolIcon from '@mui/icons-material/School';
import StyleIcon from '@mui/icons-material/Style';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import HeaderImage from "../../assest/images/landing-head-mage.png";
import whyUsImage from "../../assest/svgs/why-choose-us.svg";
import NewCarousel from "../../components/Carousel/NewCarousel";
import TopUniv from "../../assest/svgs/top_univ.svg";
import StudentsGlo from "../../assest/svgs/students_glo.svg";
import CoachingCenters from "../../assest/svgs/coaching_centers.svg";
import VideoImage from "../../assest/images/video-image.png";
import ListOfColleges from "../../assest/images/list_of_colleges.png";
import LoanIcon from "../../assest/images/loan.png";
import AccomodationIcon from "../../assest/images/accomodation_icon.png";
import ExamsCoverage from "../../assest/images/exams_coverage.png";
import CareerGuidance from "../../assest/images/career_guidance.png";
import StudyMaterial from "../../assest/images/study_material.png";
import CollegeAndStudy from "../../assest/images/college_and_study_material.png";
import Personalisedattention from "../../assest/images/personalisedattention.png";
import Carousel from 'react-elastic-carousel';
import routes from "../../react_routes_enum";
import { getConfigDetails } from "../../redux/configurationDetails/configDetailsSlice";
import "react-alice-carousel/lib/alice-carousel.css";
import { useEffect, useState } from "react";
import Spinner from "../../components/Spinner/Spinner";
import {
    getStudentUniversity, 
    getStudentCoaching,
    getInstituteById,
    getReviews,
    setInstituteCat} from "../../redux/students/studentSlice";

const useStyle = makeStyles({
    exploreMoreButton: {
        width: '240px',
        height: '48px',
        borderRadius: '100px !important',
        color: '#FFFFFF !important',
        backgroundColor: 'var(--tertiary-color) !important'
    },
    offerIcon: {
        height: '120px',
        width: '120px',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    offerInBox: {
        height: '84px',
        width: '84px',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    howItWorkSingleBox: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginBottom: '24px',
        alignItems: "center",
    },
    coachDataBox: {
        height: '124px',
        width: '140px',
        backgroundColor: 'rgba(239, 0, 0, 0.08)',
        boxShadow: '0px 2px 5px 2px rgba(239, 0, 0, 0.2)',
        borderRadius: '10px',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        marginRight: '24px',
        marginBottom: '12px',
        cursor: 'pointer',
    },
    coachCatMain: {
        display: 'inline-block',
        float: 'left',
    },
    benefitSingleBox: {
        display: "flex",
        marginBottom: '24px',
        boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)',
        padding: '8px 12px',
        borderRadius: '10px',
    },
    collegeClueData: {
        borderRadius: '10px',
        boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    univCat: {
        height: '124px',
        width: '140px',
        backgroundColor: 'rgb(186, 104, 200, 0.14)',
        boxShadow: '0px 2px 5px 2px rgba(103, 47, 143, 0.25)',
        borderRadius: '10px',
        margin: '12px 0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
    }
})

const reviewBreakPoints = [
    {width: 400, itemsToShow: 1},
    {width: 540, itemsToShow: 2},
    {width: 768, itemsToShow: 3},
    {width: 1200, itemsToShow: 3},
    {width: 1500, itemsToShow: 4},
];

const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 520, itemsToShow: 3, itemsToScroll: 2 },
    { width: 768, itemsToShow: 4 },
    { width: 1200, itemsToShow: 5 }
];

const itemData = [
    {
        title1: "Sr. Full Stack Developer",
        title2: "Verizon Data Services",
        author: "Deepa Paikar",
    },
    {
        title1: "Software Engineer",
        title2: "Salesforce",
        author: "Anoosha Chandra",
    },
    {
        title1: "Software Engineer",
        title2: "Salesforce",
        author: "Anoosha Chandra",
    }
]

const collegeClueData = [
    {
        icon: TopUniv,
        title: 'EXAMS',
        count: '1'
    },
    {
        icon: StudentsGlo,
        title: 'STUDENTS GLOBALLY',
        count: '30'
    },
    {
        icon: CoachingCenters,
        title: 'COACHING CENTERS',
        count: '30'
    }
]
export default function LandingPage() {
    const classes = useStyle();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [universityCat, setUniversityCat] = useState([]);
    const [coachingCat, setCoachingCat] = useState([]);
    const [universityList, setUniversityList] = useState([]);
    const [coachingList, setCoachingList] = useState([]);
    const [reviewsArray, setReviewsArray] = useState([]);
    const [isViewDetailClicked, setIsViewDetailClicked] = React.useState(false);
    
    const getInstituteByCat = (cat, type) => {
        dispatch(setInstituteCat(cat));
        type === 'univ' ? navigate(routes.UNIVERSITY_LISTING) : navigate(routes.COACHING_LISTING)
    }

    useEffect(() => {
        const res = dispatch(getConfigDetails());
        res.then((r) => {
            if(r?.payload?.statusCode === 200){
                r?.payload?.data?.map((item) => {
                    item?.configuration_type === "university_category" && setUniversityCat(item?.data)
                    item?.configuration_type === "coaching_category" && setCoachingCat(item?.data)
                })
            }
        })
        getUniversity();
        getCoaching();
        getUserReview();

    },[]);
    const getUniversity = () => {
        const res = dispatch(getStudentUniversity({limit: 10, offset: 0}));
        res.then((r) => {
            r?.payload?.statusCode === 200 && setUniversityList(r?.payload?.data?.result)
        })
    }
    const getCoaching = () => {
        const res = dispatch(getStudentCoaching({limit: 10, offset: 0}));
        res.then((r) => {
            r?.payload?.statusCode === 200 && setCoachingList(r?.payload?.data?.result)
        })
    }
    const getDetail = (id, type) => {
        setIsViewDetailClicked(true);
        const res = dispatch(getInstituteById({id, type}));
            res.then((r) => {
                setIsViewDetailClicked(false);
                r?.payload?.statusCode === 200 && (type === "university" ? 
                navigate(routes.UNIVERSITY_DETAIL) : navigate(routes.COACHING_DETAIL))

        })
    }

    const getUserReview = () => {
        const res = dispatch(getReviews({}));
        res?.then((r) => {
            r?.payload?.statusCode === 200 && setReviewsArray(r?.payload?.data?.result)
        })
    }
    const exploreMore = () => {
        navigate(routes.COACHING_LISTING);
    }

    if (isViewDetailClicked) return <Spinner />;
    // if (isError) return <h1>{message}</h1>;
    return (
        <Box sx={{p: 3}}>
            <section className="home-section">
                <div style={{position: 'relative'}} className="home-container">
                    <div className="hero-image-box">
                        <img src={HeaderImage} alt="HeroImage" className="hero-image" />
                    </div>
                    <div className="lading-header-content">
                        <div>
                            <div className="background-cirlce"></div>
                            <div className="home-content-style">
                                <Typography component="h2" variant="h2" className="page-heading" sx={{
                                    textAlign: 'left',
                                }}>
                                    Get Expert <span style={{color: 'var(--tertiary-color)'}}>Coaching</span><br/>
                                    for Entrance and Competitive<br/>
                                    Exams <span style={{color: 'var(--tertiary-color)'}}>in India!</span>
                                </Typography>
                                <Typography component="p" variant="p" className="header-small-text smll-text-width" >
                                    Get complete access to the top tutors, coaching centers 
                                    and institutes of India.
                                </Typography>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'flex-start'
                                }}>
                                    <Button 
                                        className={classes.exploreMoreButton} 
                                        onClick={exploreMore}>
                                        Explore More
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <Typography component="h2" variant="h2" className="page-heading-2">
                    What We Offer
                </Typography>
                <div style={{
                    marginTop: '5%',
                    marginBottom: '4%'
                }} className="benefit-list-box">
                    <div className="how-it-work-two-box accomd2">
                        <div className={classes.howItWorkSingleBox + ' how-it-work-single-box how-it-work-width' }>
                            <div className={classes.offerIcon} style={{
                                border: '1px solid rgb(133, 229, 194, 0.5)'
                            }}>
                                <div className={classes.offerInBox} style={{
                                    backgroundColor: 'rgb(133, 229, 194, 0.3)'
                                }}>
                                    <img src={ListOfColleges} 
                                    alt="benefit-point-icon"
                                    style={{
                                        width: '32px'
                                    }} />
                                </div>
                            </div>
                            <div>
                                <Typography component="h3" variant="h3" className="benefit-point-title" 
                                    sx={{
                                        textAlign: 'center',
                                        color: 'var(--tertiary-color)', 
                                        margin: '16px 0 10px 0',
                                        height: '60px'}}>
                                    Comprehensive list of coaching
                                </Typography>
                                <Typography component="p" variant="p" 
                                className="how-work-point-text" style={{
                                    textAlign: 'center',
                                    color: 'rgb(0, 0, 0, 0.6)'
                                }}>
                                    PrepClue has more than 3000 coaching as our 
                                    partners from India. You can easily 
                                    apply to your coaching in just 
                                    few clicks.
                                </Typography>
                            </div>
                        </div>
                        <div className={classes.howItWorkSingleBox + ' how-it-work-width'}>
                            <div className={classes.offerIcon} style={{
                                border: '1px solid rgb(246, 146, 32, 0.5)'
                            }}>
                                <div className={classes.offerInBox} style={{
                                    backgroundColor: 'rgb(246, 146, 32, 0.3)'
                                }}>
                                    <img src={ExamsCoverage} alt="benefit-point-icon"
                                    style={{
                                        width: '32px'
                                    }} />
                                </div>
                            </div>
                            <div>
                                <Typography component="h3" variant="h3" className="benefit-point-title" 
                                    sx={{
                                        textAlign: 'center',
                                        color: 'var(--tertiary-color)', 
                                        margin: '16px 0 10px 0',
                                        height: '60px'}}>
                                    All exams coverage 
                                </Typography>
                                <Typography component="p" variant="p" 
                                    className="how-work-point-text" 
                                    style={{
                                        textAlign: 'center',
                                        color: 'rgb(0, 0, 0, 0.6)'
                                    }}>
                                    Our coaching-wing PrepClue covers a wide range of 
                                    entrance and competitive exams like JEE, NEET CUET, 
                                    UPSC etc. You will get complete access top coaching 
                                    institutes in India.
                                </Typography>
                            </div>
                        </div>
                    </div>
                    <div className="how-it-work-two-box accomd">
                        <div className={classes.howItWorkSingleBox + ' how-it-work-single-box how-it-work-width'}>
                            <div className={classes.offerIcon} style={{
                                border: '1px solid rgb(60, 28, 222, 0.5)'
                            }}>
                                <div className={classes.offerInBox} style={{
                                    backgroundColor: 'rgb(60, 28, 222, 0.3)'
                                }}>
                                    <img src={AccomodationIcon} 
                                    alt="benefit-point-icon"
                                    style={{
                                        width: '32px'
                                    }} />
                                </div>
                            </div>
                            <div>
                                <Typography component="h3" variant="h3" className="benefit-point-title" 
                                    sx={{
                                        textAlign: 'center',
                                        color: 'var(--tertiary-color)', 
                                        margin: '16px 0 10px 0',
                                        height: '60px'}}>
                                    Accommodation
                                </Typography>
                                <Typography component="p" variant="p" className="how-work-point-text" style={{
                                    textAlign: 'center',
                                    color: 'rgb(0, 0, 0, 0.6)'
                                }}>
                                    Whether you go to another city or country for your 
                                    studies, we have trusted, affordable and secure 
                                    accommodation facilities.
                                </Typography>
                            </div>
                        </div>
                        <div className={classes.howItWorkSingleBox + ' how-it-work-width'}>
                            <div className={classes.offerIcon} style={{
                                border: '1px solid rgb(0, 164, 162, 0.5)'
                            }}>
                                <div className={classes.offerInBox} style={{
                                    backgroundColor: 'rgb(0, 164, 162, 0.3)'
                                }}>
                                    <img src={LoanIcon} 
                                    alt="benefit-point-icon"
                                    style={{
                                        width: '32px'
                                    }} />
                                </div>
                            </div>
                            <div>
                                <Typography component="h3" variant="h3" className="benefit-point-title" 
                                    sx={{
                                        textAlign: 'center',
                                        color: 'var(--tertiary-color)', 
                                        margin: '16px 0 10px 0',
                                        height: '60px'}}>
                                    Application form fill up guidance
                                </Typography>
                                <Typography component="p" variant="p" className="how-work-point-text" style={{
                                    textAlign: 'center',
                                    color: 'rgb(0, 0, 0, 0.6)'
                                }}>
                                    We provide assistance & guidance to fill up the 
                                    application form for all exams.
                                </Typography>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="about-college-clue">
                <Typography component="h2" variant="h2" className="page-heading-2" style={{
                    textAlign: 'left',
                    position: 'relative'
                }}>
                    About PrepClue
                    <div style={{
                        backgroundColor: 'rgb(246, 146, 32)',
                        borderRadius: '6px',
                        position: 'absolute',
                        zIndex: -1,
                    }} className="heading-bottom-line"></div>
                </Typography>
                <div className="why-us-box">
                    <div>
                        <Typography component="p" variant="p" className="why-us-text" style={{
                            color: 'rgb(0, 0, 0, 0.5)',
                            fontWeight: 'bold'
                        }}>
                            PrepClue strives to empower students in India by providing 
                            them with comprehensive and effective coaching for entrance 
                            exams, competitive exams. Our goal is to help students achieve 
                            their dreams and unlock their full potential.<br />

                            With years of experience in the education industry, we 
                            understand the unique challenges that students face when 
                            preparing for exams. That's why we have created a robust curriculum, 
                            coupled with personalized coaching and mentorship, to help 
                            students achieve their goals.
                        </Typography>
                    </div>
                    <div className="why-us-image-box">
                        <img src={whyUsImage} alt="whyUsImage" className="why-us-image" />
                        <img src={VideoImage} alt="videoImage" className="video-image" />
                    </div>
                </div>
            </section>
            {/* <section style={{
                marginBottom: '72px'
            }} className="marginTopFor univ-cat-aria">
                <Typography component="h2" variant="h2" className="page-heading-2">
                    Explore Universities by Categories
                </Typography>
                <Typography component="p" variant="p" style={{
                    color: 'rgb(0, 0, 0, 0.5)',
                    fontWeight: 'bold',
                    textAlign: 'center'
                }}>
                    Browse top universities by browsing our category which will be more easy for you
                </Typography>
                <div className="univ-cat">
                    <Carousel breakPoints={univCatbreakPoints}>
                        {universityCat.map((item, index) => (
                            <div key={index} className={classes.univCat} 
                                onClick={() => getInstituteByCat(item?.cat, "univ")}>
                                <div style={{ 
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}>
                                    <img src={CategoriesOfUniversity} 
                                        alt="icon"
                                        style={{width: '24px'}} />
                                    <p style={{
                                        fontSize: '14px',
                                        margin: '8px 0 0 0'
                                    }}>{item?.cat}</p>
                                </div>
                            </div>
                        ))}
                        
                    </Carousel>
                </div>
            </section> */}
            <section style={{
                marginBottom: '72px',
                display: 'inline-block',
                width: '100%',
                marginTop: '72px'
            }}>
                <Typography component="h2" variant="h2" className="page-heading-2">
                    Explore Coaching Centers by Exams
                </Typography>
                <Typography component="p" variant="p" style={{
                    color: 'rgb(0, 0, 0, 0.5)',
                    fontWeight: 'bold',
                    textAlign: 'center'
                }}>
                    Browse coaching centers by browsing our category which will be more easy for you
                </Typography>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center'
                }}>
                    <div style={{
                        marginTop: '24px',
                        marginBottom: '24px',
                        display: 'inline-block',
                    }}>
                        {coachingCat.map((item, index) => (
                            <div key={index} className={classes.coachCatMain} 
                                onClick={() => getInstituteByCat(item, "coach")}>
                                <div className={classes.coachDataBox}>
                                    {item === "Medical" &&
                                        <MedicalServicesIcon 
                                            fontSize="large"
                                            sx={{color: 'var(--tertiary-color)'}}
                                        />
                                    }
                                    {item === "Engineering" &&
                                        <SchoolIcon 
                                            fontSize="large"
                                            sx={{color: 'var(--tertiary-color)'}}
                                        />
                                    }
                                    {item === "CUET" &&
                                        <StyleIcon 
                                            fontSize="large"
                                            sx={{color: 'var(--tertiary-color)'}}
                                        />
                                    }
                                    {item === "Law" &&
                                        <LocalPoliceIcon 
                                            fontSize="large"
                                            sx={{color: 'var(--tertiary-color)'}}
                                        />
                                    }
                                    {item === "Finance" &&
                                        <AttachMoneyIcon 
                                            fontSize="large"
                                            sx={{color: 'var(--tertiary-color)'}}
                                        />
                                    }
                                    {item === "Other" &&
                                        <AltRouteIcon 
                                            fontSize="large"
                                            sx={{color: 'var(--tertiary-color)'}}
                                        />
                                    }
                                    {/* <img src={CategoriesOfCoaching} 
                                        style={{width: '28px'}} alt="logo" /> */}
                                    <Typography component="p" variant="p" sx={{
                                        fontSize: '16px',
                                        textAlign: 'center',
                                        marginTop: '16px'
                                    }}>
                                        {item}
                                    </Typography>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            {/* <section style={{
                marginBottom: '72px',
            }} className="education-uni-cat">
                <Typography component="h2" variant="h2" className="page-heading-2">
                    Approved education universities
                </Typography>
                <div className="univ-cat">
                    <Carousel breakPoints={breakPoints}>
                        {universityList?.length > 0 && universityList?.map((item, index) => (
                            <div 
                                key={index} 
                                onClick={() => getDetail(item?._id, "university")} 
                                style={{cursor: 'pointer'}}>
                                <img 
                                    className="inst-newlogo"
                                    src={item.uploads?.university_logo} 
                                    alt="icon" />
                            </div>
                        ))}
                    </Carousel>
                </div>
            </section> */}
            <section style={{
                marginBottom: '72px',
            }}>
                <Typography component="h2" variant="h2" className="page-heading-2">
                    What makes us stand apart
                </Typography>
                <Typography component="p" variant="p" style={{
                    color: 'rgb(0, 0, 0, 0.5)',
                    fontWeight: 'bold',
                    textAlign: 'center'
                }}>
                    Following are the things which makes us different from others.
                </Typography>
                <div style={{
                    marginBottom: '4%',
                    marginTop: '20px'
                }} className="benefit-list-box">
                    <div>
                        <div className={`${classes.benefitSingleBox} card-height marginR`}>
                            <div className="bene-icon">
                                <img src={Personalisedattention} alt="benefit-point-icon" />
                            </div>
                            <div>
                                <Typography component="h3" variant="h3" className="benefit-point-title" sx={{marginBottom: '6px'}}>
                                    Personalized Attention 
                                </Typography>
                                <Typography component="p" variant="p" 
                                className="benefit-point-text benefit-point-text-2" sx={{
                                    color: 'rgb(0, 0, 0, 0.5)'
                                }}>
                                    We believe in providing individual attention to each 
                                    student, helping them identify their strengths and 
                                    weaknesses and devising a tailored study plan 
                                    accordingly.
                                </Typography>
                            </div>
                        </div>
                        <div className={`${classes.benefitSingleBox} card-height marginR`}>
                            <div className="bene-icon">
                                <img src={StudyMaterial} alt="benefit-point-icon" />
                            </div>
                            <div>
                                <Typography component="h3" variant="h3" className="benefit-point-title" sx={{marginBottom: '6px'}}>
                                    Extensive Study Material 
                                </Typography>
                                <Typography component="p" variant="p" 
                                className="benefit-point-text benefit-point-text-2" sx={{
                                    color: 'rgb(0, 0, 0, 0.5)'
                                }}>
                                    Our study material is curated by subject-matter 
                                    experts, providing students with in-depth knowledge 
                                    and practice exercises to improve their exam 
                                    performance.
                                </Typography>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className={`${classes.benefitSingleBox} card-height`}>
                            <div className="bene-icon">
                                <img src={CollegeAndStudy} alt="benefit-point-icon" />
                            </div>
                            <div>
                                <Typography component="h3" variant="h3" className="benefit-point-title" sx={{marginBottom: '6px'}}>
                                    Wide range of coaching and courses
                                </Typography>
                                <Typography component="p" variant="p" 
                                className="benefit-point-text benefit-point-text-2" sx={{
                                    color: 'rgb(0, 0, 0, 0.5)'
                                }}>
                                    We offer coaching admissions for a wide range of 
                                    courses and institute across globe, giving 
                                    students a plethora of options to choose from.
                                </Typography>
                            </div>
                        </div>
                        <div className={`${classes.benefitSingleBox} card-height`}>
                            <div className="bene-icon">
                                <img src={CareerGuidance} alt="benefit-point-icon" />
                            </div>
                            <div>
                                <Typography component="h3" variant="h3" className="benefit-point-title" sx={{marginBottom: '6px'}}>
                                    Career Guidance 
                                </Typography>
                                <Typography component="p" variant="p" 
                                className="benefit-point-text benefit-point-text-2" sx={{
                                    color: 'rgb(0, 0, 0, 0.5)'
                                }}>
                                    We provide career guidance and counseling to students,
                                    helping them make informed decisions about their 
                                    future and navigate the job market much better.
                                </Typography>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section style={{
                marginBottom: '72px',
            }} className="top-count-sction">
                <div className="top-counts">
                    {collegeClueData.map((item, index) => (
                        <div key={index} style={{display: 'inline-block'}}>
                            <div className="count-inside">
                                <div className={classes.collegeClueData + " icon-count"}>
                                    <img src={item.icon} alt="icon" />
                                </div>
                                <div style={{
                                    marginLeft: '12px'
                                }}>
                                    <p style={{
                                        fontWeight: 'bold',
                                        color: 'var(--secondary-color)',
                                        margin: '4px 0'
                                    }} className="count-text-1">{item.count}K+</p>
                                    <p style={{
                                        color: '#000000',
                                        margin: '4px 0'
                                    }} className="count-text-2">{item.title}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
            <section style={{
                marginBottom: '72px',
            }} className="approved-coaching-center">
                <Typography component="h2" variant="h2" className="page-heading-2">
                    Approved Coaching Centers
                </Typography>
                <div className="univ-cat">
                    <Carousel breakPoints={breakPoints}>
                        {coachingList?.length > 0 && coachingList?.map((item, index) => (
                            <div 
                                key={index} 
                                onClick={() => getDetail(item?._id, "coaching")} 
                                style={{
                                    cursor: 'pointer',
                                    height: '160px',
                                    width: '240px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}>
                                <img 
                                    // className="inst-newlogo"
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'contain'
                                    }}
                                    src={item?.uploads?.coaching_logo} 
                                    alt="icon" />
                            </div>
                        ))}
                    </Carousel>
                </div>
            </section>
            {console.log("============>",reviewsArray)}
            {reviewsArray?.length > 0 &&
            <section style={{
                marginBottom: '72px',
            }}>
                <Typography component="h2" variant="h2" className="page-heading-2">
                    What Students Say About Us
                </Typography>
                <div className="univ-cat">
                    <NewCarousel 
                        reviewBreakPoints={reviewBreakPoints} 
                        data={reviewsArray} 
                    />
                </div>
            </section>
            }
        </Box>
    );
}

const UniversityCat = (props) => { 
    <main className="main">
        <section className="container">
            <article className="review r-card">
                <div style={{position: 'relative'}}>
                    <div style={{
                        backgroundColor: 'var(--color-grey-box)',
                        borderRadius: '8px',
                        padding: '4px'
                    }} className="r-pic-aria">
                        <img src={props.data.pic} 
                        className='r-client-pic'
                        style={{
                            borderRadius: '8px'
                        }} />
                    </div>
                    <div className="revew-details">
                        <div style={{
                            borderLeft: '2px solid var(--color-black)',
                            paddingLeft: '20px'
                        }}>
                            <p className="r-description">"{props.data.overview}"</p>
                            <p style={{
                                fontWeight: 'bold',
                                color: 'var(--color-black)',
                                marginBottom: 0
                            }}>{props.data.title}</p>
                            <p style={{
                                fontSize: '12px'
                            }}>{props.data.destination}</p>
                        </div>
                    </div>
                </div>
            </article>
        </section>
    </main>
}
