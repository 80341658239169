import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from "@mui/styles";
import Moment from "moment";
import { Avatar } from "@mui/material";
import placeholder from "../../assest/images/download.jfif";

const useStyle = makeStyles({
  table: {
    borderCollapse: "separate !important",
    borderSpacing: "0px 10px !important",
  },
  tableHead: {
    backgroundColor: "rgba(239, 241, 243, 1)",
    borderRadius: "15px",
  },
  tableHeadCell: {
    fontWeight: "600 !important",
    padding: "20px",
  },
  tableRow: {
    borderRadius: "15px",
    "&:hover": {
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      cursor: "pointer",
      borderRadius: "15px !important",
    },
  },
});


export default function BasicTable({ rows, columns, where }) {
  const classes = useStyle();
  const makeFirstLetterCapital = (str) => {
    if (str.includes(" ")) {
      let a = str.split(" ");
      a = a.map((i) => makeFirstLetterCapital(i));
      return a.join(" ");
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const availableWheres = [
    "recipes",
    "ingredients",
    "workout",
    "posts",
    "shop",
  ];

  const handleOnClickRow = (id) => {
    if (availableWheres.find((w) => w === where)) {
      if (where === "recipes") {
        // navigate(routes.VIEW_EDIT_RECIPE);
        // dispatch(setRecipeById(id));
      }
      if (where === "ingredients") {
      }
      if (where === "workout") {
      }
      if (where === "posts") {
      }
      if (where === "shop") {
      }
    }
  };
  return (
    <>
      <Table sx={{ minWidth: 650 }} className={classes.table}>
        <TableHead className={classes.tableHead}>
          <TableRow>
            {columns.map((col, index) => (
              <TableCell className={`${classes.tableHeadCell} table-heading`} key={index}>
                {makeFirstLetterCapital(col.split("_").join(" "))}
                {/* {col.charAt(0).toUpperCase() + col.slice(1)} */}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {rows && rows.length > 0 ? (
          <TableBody>
            {rows.map((row, index) => (
              <TableRow className={classes.tableRow} key={index}>
                {columns.map((i) => (
                  <TableCell
                    className={`table-cell`}
                    key={i}
                    onClick={(e) => handleOnClickRow(row["_id"])}
                    sx={{
                      maxWidth: "200px",
                      overflow: "hidden",
                      maxHeight: "100px",
                    }}
                  >
                    {((i === "trainer_image" && row[i]) ||
                      (i === "recipe_image" && row[i]) ||
                      (i === "Ingredient_Image" && row[i]) ||
                      (i === "shop_image" && row[i]) ||
                      (i === "post_image" && row[i])) && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "row",
                        }}
                      >
                        {typeof row[i] === "object" && row[i].length > 1 ? (
                          <Avatar
                            src={row[i][0]}
                            style={{
                              width: 100,
                              height: 100,
                              display: "inline-flex",
                            }}
                            alt=""
                            variant="square"
                          >
                            <img
                              src={placeholder}
                              width="100"
                              height="100"
                              alt=""
                            />
                          </Avatar>
                        ) : (
                          <Avatar
                            src={row[i]}
                            style={{
                              width: 100,
                              height: 100,
                              display: "inline-flex",
                            }}
                            alt=""
                            variant="square"
                          >
                            <img
                              src={placeholder}
                              width="100"
                              height="100"
                              alt=""
                            />
                          </Avatar>
                        )}
                        {typeof row[i] === "object" && row[i].length > 1 && (
                          <span style={{ marginLeft: "10px" }}>
                            +{row[i].length - 1}
                          </span>
                        )}
                      </div>
                    )}
                    {i === "Tags" && row?.tags?.map((item, index) => (
                      index + 1 === row?.tags.length ? `${item}` : `${item}, `
                    ))}
                    {i === "Description" && row?.description}
                    {i === "Name" && row?.name}
                    {i === "Name of Institute" && (row?.university_name || row?.coaching_name)}
                    {i === "Category" && row?.category}
                    {i === "Title" && row?.title}
                    {i === "Title" && row?.name}
                    {i === "Duration(min)" && row?.duration_in_min}
                    {i === "Phone Number" && row?.phone_number}
                    {i === "Phone" && `${row?.phone_country_code}-${row?.phone}`}
                    {i === "Email Id" && row?.email}
                    {i === "DOB" && Moment(row?.dob).format("DD MMMM YYYY")}
                    {(i === "Name of Product" || i === "Name of Student") && row?.name}
                    {i === "type" && row?.product_type}
                    {i === "Type" && row?.type}
                    {i === "type" && (!row?.product_type && (
                      row?.sub_type === 'single_select' ? 
                      'Single Select' : 'Multi Select')
                      )}
                    {i === "Image" && <Avatar src={row[i]} />}
                    {(i === "id" || i === "Id" || i === "SNo") && index + 1}
                    {i.toLowerCase().includes("date") &&
                      Moment(row[i]).format("DD MMMM YYYY")}
                    {i.toLowerCase().includes("price") &&
                      (row?.price === "0" ?
                      "free" : row?.price)}
                    {
                      !(
                        i === "trainer_image" ||
                        i === "post_image" ||
                        i === "recipe_image" ||
                        i === "shop_image" ||
                        i === "Ingredient_Image"
                      ) &&
                        !i.toLowerCase().includes("date") &&
                        !(
                          i.toLowerCase().includes("price") && row[i] === "0"
                        ) &&
                        !(i === "Image") &&
                        (row[i]?.length > 45 ? (
                          <div>{`${row[i].substring(0, 45)}...`}</div>
                        ) : (
                          <p>{row[i]}</p>
                        ))
                      // row[i]
                    }
                    {!row[i] && i !== "Image" && i !== "id" && i !== "SNo" && i !== "Name of Product" && 
                    i !== "type" && i !== "Category" && i !== "Name of Student" && i !== "Phone Number" 
                    && i !== "Email Id" && i !== "DOB" && i !== "Name of Institute" && 
                    i !== "Title" && i !== "Tags" && i !== "Duration(min)" && i !== "Price" 
                    && i !== "Name" && i !== "Phone" && i !== "Description" && 
                    i !== "Type" && "-"}
                    {/* {row[i]} */}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        ) : (
          <caption>No Rows Found</caption>
        )}
      </Table>
    </>
  );
}
