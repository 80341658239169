const routes = Object.freeze({
  REQUEST_FORM: '/request-form',
  FORM_RESPONSE: "/form-response",
  DASHBOARD: '/dashboard',
  PRODUCTS: '/products',
  COURSE: '/course',
  PARTNER_REQUEST_FORM: '/partner-request-form',
  PARTNER_DOCUMENT: '/partner-document',
  UNIVERSITY_INFORMATION: '/universtiy/information',
  UNIVERSITY_UPLOAD: '/universtiy/uploads',
  UNIVERSITY_COURSE: '/universtiy/courses',
  UNIVERSITY_PLACEMENT: '/universtiy/placement-record',
  UNIVERSITY_REQUEST_RESPONSE: '/universtiy/request-response',
  COACHING_REQUEST_RESPONSE: '/coaching/request-response',
  PARTNER_REQUEST_RESPONSE: '/partner/request-response',
  UNIVERSITY_REGISTRATION_RESPONSE: '/universtiy/registration-response',
  COACHING_REGISTRATION_RESPONSE: '/coaching/registration-response',
  PARTNER_REGISTRATION_RESPONSE: '/partner/registration-response',
  COACHING_INFORMATION: '/coaching/information',
  COACHING_UPLOAD: '/coaching/uploads',
  COACHING_COURSE: '/coaching/courses',
  PRODUCT_VIEW: '/products/view',
  PRODUCT_EDIT: '/products/edit',
  STUDENTS: '/students',
  LANDING_PAGE: '/prep-clue',
  COACHING_LISTING: '/coaching-list',
  UNIVERSITY_LISTING: '/university-list',
  CART: '/cart',
  CHECKOUT: '/checkout',
  INSTITUTE_STUDENT: '/institute/student',
  STUDENT_DASHBOARD: '/dashboard',
  STUDENT_UNIVERSITY: '/university',
  STUDENT_COACHING: '/coaching',
  UNIVERSITY_APPLICATION: '/university/application',
  COACHING_APPLICATION: '/coaching/application',
  CONTACT_US: '/contact-us',
  PARTNER: '/partner-with-us',
  PURCHASES: '/purchase',
  TEST_SERIES: '/test-series',
  TESTS: '/tests',
  TEST_QUESTION: '/test-question',
  ADD_NEW_TEST: '/add-new-test',
  VIEW_TEST: '/test/view',
  EDIT_TEST: '/test/edit',
  VIEW_QUESTION: '/question/view',
  EDIT_QUESTION: '/question/edit',
  QUESTIONS: '/questions',
  CONTENT: '/content',
  ADD_CONTENT: '/add-content',
  ADD_QUESTIONS: '/add-questions',
  RECORDED_COURSE: '/online-recorded-course',
  HELP_SUPPORT: '/help-&-support',
  HELP_SUPPORT_DETAIL: '/help-&-support/detail',
  PROFILE: '/profile',
  STUDENT_RECORDED_COURSE: '/recorded-course',
  STUDENT_APP_DETAILS: '/application/detail',
  LGS: '/lead-generation-system',
  CREATE_LEAD: '/create-new-lead',
  VIEW_LEAD: '/view-lead',
  COURSE_COMPLETED: '/course-completed',
  COUNTRY_ADDON: '/country',
  EXAM_ADDON: '/exams',
  EXAM_DETAIL: '/exams-detail',

  COACHING_DETAIL:'/coaching-detail',
  UNIVERSITY_DETAIL:'/university-detail',

  REGISTER_TO_APPLY:'/students/register',
  STUDENT_PENDING: '/students/pending',
  STUDENT_DOCUMENT:'/students/document',
  APPROVED_STUDENT:'/students/approved',
  REJECTED_STUDENT:'/students/rejected',
  STUDENT_ENROLLED:'/students/enrolled',
  BLOG: '/blogs',
  VIEW_BLOG: '/blogs/view',
  CREATE_BLOG: '/blogs/create-new-blog',
  STUDENTS_BLOG: '/blogs-list',
  STUDENTS_BLOG_DETAIL: '/blog-detail',
  PRIVACY_POLICY: '/privacy-policy',
  TERMS_AND_CONDITIONS: '/terms-and-conditions',
  CACELLATION: '/refund-and-cancellation',
  FORGOT_PASSWORD: '/forgot-password',
});
export default routes;
