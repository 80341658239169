import * as React from "react";
import {
  Button,
  InputLabel,
  TextField,
} from "@mui/material";
import Moment from "moment";
import parse from 'html-react-parser';
import validator from "validator";
import { toast } from "react-toastify";
import InputAdornment from '@mui/material/InputAdornment';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import PersonIcon from '@mui/icons-material/Person';
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import PartnerFormImage from "../../assest/images/partnerFormImage.jpeg";
import NewCarousel from "../../components/Carousel/NewCarousel";
import BlogCarousel from "../../components/Carousel/BlogCarousel";
import TIcon1 from "../../assest/images/t_icon_1.png";
import TIcon2 from "../../assest/images/t_icon_2.png";
import LoaderIcon from "../../components/Loader/Loader";
import "react-alice-carousel/lib/alice-carousel.css";
import { useState } from "react";
import routes from "../../react_routes_enum";
import AddOnHeader from "../../components/Header/AddOnHeader";
import Spinner from "../../components/Spinner/Spinner";
import {
    addContactUs, 
    getReviews,
    getStudentCoaching} from "../../redux/students/studentSlice";
import { getCustomPagesById } from "../../redux/instituteStudent/instituteStudentSlice";
import "./addOn.css";

const useStyle = makeStyles({
    exploreMoreButton: {
        width: '240px',
        height: '48px',
        borderRadius: '100px !important',
        color: '#FFFFFF !important',
        backgroundColor: 'var(--secondary-color) !important'
    },
    offerIcon: {
        height: '120px',
        width: '120px',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    offerInBox: {
        height: '84px',
        width: '84px',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    howItWorkSingleBox: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginBottom: '24px',
        alignItems: "center",
    },
    coachDataBox: {
        height: '124px',
        width: '140px',
        backgroundColor: 'rgba(239, 0, 0, 0.08)',
        boxShadow: '0px 2px 5px 2px rgba(239, 0, 0, 0.2)',
        borderRadius: '10px',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        marginRight: '24px',
        marginBottom: '12px',
        cursor: 'pointer',
    },
    coachCatMain: {
        display: 'inline-block',
        float: 'left',
    },
    benefitSingleBox: {
        display: "flex",
        marginBottom: '24px',
        boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)',
        padding: '8px 12px',
        borderRadius: '10px',
    },
    collegeClueData: {
        borderRadius: '10px',
        boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    univCat: {
        height: '124px',
        width: '140px',
        backgroundColor: 'rgb(186, 104, 200, 0.14)',
        boxShadow: '0px 2px 5px 2px rgba(103, 47, 143, 0.25)',
        borderRadius: '10px',
        margin: '12px 0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
    },
    jobHeading: {
        width: '394px',
        height: '70px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px solid #EEBAF4',
        color: 'var(--primary-color)',
        fontWeight: 'bold',
        fontSize: '18px'
    },
    jobRow: {
        display: 'flex',
    },
    normalRow: {
        width: '394px',
        minHeight: '70px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px solid #EEBAF4',
        fontWeight: 'bold',
        padding: '8px 12px'
    },
    viewAll: {
        fontWeight: 'bold !important',
        color: '#000 !important',
    }
})

const reviewBreakPoints = [
    {width: 400, itemsToShow: 1},
    {width: 540, itemsToShow: 2},
    {width: 768, itemsToShow: 3},
    {width: 1200, itemsToShow: 3},
    {width: 1500, itemsToShow: 4},
];

const formData = {
    name: "",
    email: "",
    phone: "",
    message: ""
}

export default function FirstAddonPage() {
    const classes = useStyle();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loaderType2, setLoaderType2] = useState(false);
    const [btnLoader, setBtnLoader] = useState(false);
    const [reviewsArray, setReviewsArray] = useState([]);
    const [submitData, setSubmitData] = useState(formData);
    const [isViewDetailClicked, setIsViewDetailClicked] = React.useState(false);
    const { 
        isLoading,
        addonId,
        message,
        customPageById }= useSelector((state) => state.instituteStudent);
    
    React.useEffect(() => {
        getUserReview();
    },[])

    const getUserReview = () => {
        const res = dispatch(getReviews({}));
        res?.then((r) => {
            r?.payload?.statusCode === 200 && setReviewsArray(r?.payload?.data?.result)
        })
    }

    const handleChange = (e) => {
        if(e.target.name === "name"){
            setSubmitData({...submitData, name: e.target.value})
        }
        if(e.target.name === "email"){
            setSubmitData({...submitData, email: e.target.value})
        }
        if(e.target.name === "phone_number"){
            setSubmitData({...submitData, phone: e.target.value})
        }
        if(e.target.name === "message"){
            setSubmitData({...submitData, message: e.target.value})
        }
    }

    const validate = () => {
        let status = {isValid: true, message: ""}
        if(submitData.name === ""){
            status.isValid = false;
            status.message = "Name required";
            return status;
        }
        if(submitData.email === ""){
            status.isValid = false;
            status.message = "Email required";
            return status;
        }
        if(!validator.isEmail(submitData.email)){
            status.isValid = false;
            status.message = "Invalid email entered";
            return status;
        }
        if(submitData.phone === ""){
            status.isValid = false;
            status.message = "Phone required";
            return status;
        }
        if(submitData.phone.length !== 10){
            status.isValid = false;
            status.message = "Invalid phone entered";
            return status;
        }
        if(submitData.message === ""){
            status.isValid = false;
            status.message = "Message required";
            return status;
        }
        return status;

    }

    const submit = () => {
        if(validate().isValid){
            setBtnLoader(true);
            const res = dispatch(addContactUs(submitData));
            res?.then((r) =>{
                setBtnLoader(false);
                if(r?.payload?.statusCode === 200){
                    setSubmitData({...formData});
                    toast.success("Message sent successfully");
                }else{
                    toast.error(r?.payload?.message);
                }
            })
        }else{
            toast.error(validate().message)
        }
    }

    React.useEffect(() => {
        if(addonId || localStorage.getItem("addonId")){
            const res = dispatch(getCustomPagesById({id: addonId ? addonId : localStorage.getItem("addonId")}));
            res?.then((r) => {
                if(r?.payload?.statusCode === 200){
                    return;
                }
                if(r?.payload?.message === "Session Expired" || 
                    r?.payload?.message === "Unauthorized"){
                    toast.error("Your session expired, please login");
                    return;
                }
                toast.error(r?.payload?.message);
            })
        }
    },[addonId])

    const coachingDetail = (type, name) => {
        if(type === "Coaching"){
            setLoaderType2(true);
            const res = dispatch(getStudentCoaching({searchText: name}));
            res?.then((r) => {
                setLoaderType2(false);
                r?.payload?.statusCode === 200 ? navigate(routes.COACHING_DETAIL) :
                toast.error("Error while getting details")
            })
        }
    }

    if (isViewDetailClicked || isLoading || loaderType2) return <Spinner />;
    // if (isError) return <h1>{message}</h1>;
    return (
        <>
            {customPageById?.sections?.map((item, index) => (
                <div key={index}>
                    {item?.section_type === "Banner image" &&
                        <AddOnHeader 
                            heading={item?.heading}
                            subHeading={item?.text}
                            image={item?.image}
                            buttonName={item?.button_name}
                        />
                    }
                    {item?.section_type === "Info of country" &&
                        <div className="common-section section-responsive">
                            <div style={{
                                display: 'flex', 
                                justifyContent: 'center'}}>
                                <h1 style={{
                                    padding: '0 12px',
                                    marginTop: '36px',
                                    borderBottom: '4px solid var(--secondary-color)'
                                }} className="addon-heading">
                                    {item?.heading}
                                </h1>
                            </div>
                            {item?.options &&
                            <div className="admission-main-box">
                                <div style={{textAlign: 'center'}}
                                    className="admission-main-box-in">
                                    {item?.options?.map((itm, i) => (
                                        <div key={`${item?.section_type}${index}${i}`} 
                                            style={{display: 'inline-block'}}>
                                            <div className="country-info-card">
                                                <img 
                                                    src={itm?.image} 
                                                    width="40" 
                                                    height="40"
                                                    alt="icon" />
                                                <div style={{
                                                    marginLeft: '20px'
                                                }}>
                                                    <p style={{
                                                        margin: 0,
                                                        textTransform: 'capitalize',
                                                        color: 'rgb(0, 0, 0, 0.43)'
                                                    }}>{itm?.heading}</p>
                                                    <p style={{
                                                        margin: 0,
                                                        fontWeight: 'bold'
                                                    }}>{itm?.text}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>}
                        </div>
                    }
                    {item?.section_type === "Why study in" &&
                        <>
                            <div style={{
                                display: 'flex', 
                                justifyContent: 'center'}}>
                                <h1 style={{
                                    padding: '0 12px',
                                    marginTop: '36px',
                                    borderBottom: '4px solid var(--secondary-color)'
                                }} className="addon-heading">
                                    {item?.title}
                                </h1>
                            </div>
                            <section className="common-section section-responsive">
                                <h1 style={{
                                    padding: '0 12px',
                                    borderBottom: 'none',
                                    marginBottom: '12px'
                                }} className="addon-heading">
                                    {item?.heading}
                                </h1>
                                <div className="why-us-country r-about">
                                    {parse(item?.text)}
                                    <img src={item?.image} alt="" />
                                </div>
                            </section>
                        </>
                    }
                    {item?.section_type === "Top courses" &&
                        <section className="common-section section-responsive">
                            <div style={{
                                display: 'flex', 
                                justifyContent: 'center'
                                }}>
                                <h1 style={{
                                    padding: '0 12px'
                                }} className="addon-heading">
                                    {item?.title}
                                </h1>
                            </div>
                            {item?.options &&
                            <div className="admission-main-box">
                                <div className="admission-main-box-in">
                                    {item?.options?.map((itm, i) => (
                                        <div className="top-course-item" 
                                            key={`${item?.section_type}${index}${i}`}>
                                        <div>
                                            <div style={{
                                                display: 'flex',
                                                alignItems: 'center'
                                            }}>
                                                <div className="top-course-icon-box">
                                                    <img 
                                                        src={itm?.course_icon} 
                                                        width="72" 
                                                        height="48"
                                                        style={{
                                                            marginLeft: '36px',
                                                            marginTop: '12px'
                                                        }}
                                                        />
                                                </div>
                                                <div style={{
                                                    marginLeft: '36px'
                                                }}>
                                                    <h4 
                                                        style={{
                                                            textTransform: 'capitalize',
                                                            margin: 0}}>
                                                        {itm?.course_type}
                                                    </h4>
                                                    <p style={{
                                                        margin: 0
                                                    }}>
                                                        {itm?.total_colleges} Colleges
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="top-course-card-content">
                                                <div>
                                                    {itm?.course_name}
                                                </div>
                                                {/* <div>
                                                    Diploma in Engineering
                                                </div>
                                                <div>
                                                    M.E / M.Tech
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                    ))}
                                </div>
                            </div>}
                        </section>
                    }
                    {item?.section_type === "Admission requirement" &&
                        <section className="common-section section-responsive">
                            <div style={{
                                display: 'flex', 
                                justifyContent: 'center'
                                }}>
                                <h1 style={{
                                    padding: '0 12px'
                                }} className="addon-heading">
                                    {item?.title}
                                </h1>
                            </div>
                            {item?.options &&
                            <div className="admission-main-box">
                                <div className="admission-main-box-in">
                                    {item?.options?.map((itm, i) => (
                                        <div style={{
                                            display: 'inline-block',
                                            marginBottom: '12px',
                                            marginRight: '12px',
                                            float: 'left',
                                        }} 
                                            key={`${item?.section_type}${index}${i}`}>
                                        <div 
                                            className="admission-item"
                                            style={{
                                                borderColor: item.borderColor
                                            }}>
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                backgroundColor: item.background,
                                                width: '133px',
                                                height: '151px'
                                            }}>
                                                <img src={itm?.image} width="26" />
                                                <p style={{
                                                    fontSize: '14px',
                                                    textAlign: 'center',
                                                    color: 'rgba(0, 0, 0, 0.5)',
                                                    marginTop: '20px',
                                                    marginBottom: 0,
                                                    textTransform: 'capitalize'
                                                }}>{itm?.heading}</p>
                                            </div>
                                        </div>
                                    </div>
                                    ))}
                                </div>
                            </div>}
                        </section>
                    }
                    {item?.section_type === "Benefits" &&
                        <>
                            <div style={{
                                display: 'flex', 
                                justifyContent: 'center'}}>
                                <h1 style={{
                                    padding: '0 12px',
                                    marginTop: '36px',
                                    borderBottom: '4px solid var(--secondary-color)'
                                }} className="addon-heading">
                                    {item?.title}
                                </h1>
                            </div>
                            <div className="benefit-box">
                                <div className="benefit-in">
                                    {item?.options?.map((itm, i) => (
                                        <div 
                                            key={`${item?.section_type}${index}${i}`} 
                                            style={{
                                                borderTop: i%4 === 0 && '4px solid var(--tertiary-color)',
                                                borderLeft: i%4 === 1 && '4px solid #07AB35',
                                                borderBottom: i%4 === 2 && '4px solid #00A4A2',
                                                borderRight: i%4 === 3 && '4px solid #FFCC5C',

                                            }}
                                            className="benefit-item">
                                            <div style={{
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                                alignItems: 'flex-start',
                                                flexDirection: 'column',
                                            }}>
                                                <img src={itm?.image} width="60" height="40" />
                                                <h4>
                                                    {itm?.title}
                                                </h4>
                                                <p>
                                                    {itm?.text}
                                                </p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </>
                    }
                    {item?.section_type === "About course" &&
                        <>
                            <div style={{
                                display: 'flex', 
                                justifyContent: 'center'}}>
                                <h1 style={{
                                    padding: '0 12px',
                                    marginTop: '36px',
                                    borderBottom: '4px solid var(--secondary-color)'
                                }} className="addon-heading">
                                    {item?.title}
                                </h1>
                            </div>
                            <section className="common-section section-responsive">
                                <h1 style={{
                                    padding: '0 12px',
                                    borderBottom: 'none',
                                    marginBottom: '12px'
                                }} className="addon-heading">
                                    {item?.heading}
                                </h1>
                                <div className="why-us-country r-about">
                                    {parse(item?.text)}
                                    <img src={item?.image} alt="" />
                                </div>
                            </section>
                        </>
                    }
                    {item?.section_type === "Features" &&
                        <>
                            <div style={{
                                display: 'flex', 
                                justifyContent: 'center'}}>
                                <h1 style={{
                                    padding: '0 12px',
                                    marginTop: '36px',
                                    borderBottom: '4px solid var(--secondary-color)'
                                }} className="addon-heading">
                                    {item?.title}
                                </h1>
                            </div>
                            <div className="benefit-box">
                                <div className="benefit-in">
                                    {item?.options?.map((itm, i) => (
                                        <div 
                                            key={`${item?.section_type}${index}${i}`} 
                                            style={{
                                                borderTop: i === 0 && '4px solid var(--tertiary-color)',
                                                borderLeft: i === 1 && '4px solid #07AB35',
                                                borderBottom: i === 2 && '4px solid #00A4A2',
                                                borderRight: i === 3 && '4px solid #FFCC5C',
                                            }}
                                            className="benefit-item">
                                            <h4>
                                                {itm?.heading}
                                            </h4>
                                            <p>
                                                {itm?.text}
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </>
                    }
                    {item?.section_type === "Career & placements" &&
                        <div className="common-section section-responsive">
                            <div style={{
                                display: 'flex', 
                                justifyContent: 'center'}}>
                                <h1 style={{
                                    padding: '0 12px',
                                    marginTop: '36px',
                                    borderBottom: '4px solid var(--secondary-color)'
                                }} className="addon-heading">
                                    {item?.title}
                                </h1>
                            </div>
                            <div>
                                <h1 style={{
                                    padding: '0 12px',
                                    borderBottom: 'none',
                                    marginBottom: '12px'
                                }} className="addon-heading">
                                    {item?.heading}
                                </h1>
                                {parse(item?.text)}
                            </div>
                            {item?.options &&
                            <div>
                                <h1 style={{
                                    padding: '0 12px',
                                    borderBottom: 'none',
                                    marginBottom: '12px'
                                }} className="addon-heading">
                                    Career and Job Options
                                </h1>
                                <div className="job-table">
                                    <div>
                                        <div className={classes.jobRow}>
                                            <div className={classes.jobHeading}>
                                                Top Jobs
                                            </div>
                                            <div className={classes.jobHeading}>
                                                Description
                                            </div>
                                            <div className={classes.jobHeading}>
                                                Average Pay
                                            </div>
                                        </div>
                                        {item?.options?.map((itm, i) => (
                                            <div className={classes.jobRow} 
                                                key={`${item?.section_type}${index}${i}`}>
                                                <div className={classes.normalRow}>
                                                    {itm?.job_name}
                                                </div>
                                                <div className={classes.normalRow}>
                                                    {itm?.text}
                                                </div>
                                                <div className={classes.normalRow}>
                                                    Rs. {itm?.pay_per_annum}/annum
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>}
                        </div>
                    }
                    {(item?.section_type === "Top colleges" ||
                        item?.section_type === "Colleges" ||
                        item?.section_type === "Coaching") &&
                        <section className="common-section section-responsive">
                            <div style={{
                                display: 'flex', 
                                justifyContent: 'space-between'}}>
                                <div></div>
                                <h1 style={{
                                    padding: '0 12px'
                                }} className="addon-heading">
                                    {item?.title}
                                </h1>
                                <Button style={{
                                    textTransform: 'capitalize',
                                    fontSize: '18px',
                                    color: 'var(--primary-color)'
                                }}
                                onClick={() => navigate(routes.COACHING_LISTING)}>
                                    See All
                                </Button>
                            </div>
                            {item?.options &&
                            <div className="admission-main-box">
                                <div className="admission-main-box-in">
                                    {item?.options?.map((itm, i) => (
                                        <div 
                                            className={`top-college-card ${i === 1 && 'specialMargin'}`}
                                            key={`${item?.section_type}${index}${i}`}>
                                            <div className="logo-circle">
                                                <img 
                                                    src={itm?.college_logo || itm?.image} 
                                                    width="48" 
                                                    />
                                            </div>
                                            <div className="top-college-card-header">
                                                {itm?.college_name || itm?.title}, {itm?.city}
                                            </div>
                                            <div className="top-college-card-body">
                                                <div className="body-item" style={{
                                                    marginBottom: '12px'
                                                }}>
                                                    <img src={TIcon1} alt="Icon" />
                                                    <div>
                                                        <p>{itm.minimum_ielts}</p>
                                                        <h5>Minimum IELTS required</h5>
                                                    </div>
                                                </div>
                                                <div className="body-item">
                                                    <img src={TIcon2} alt="Icon" />
                                                    <div>
                                                        <p>{itm.ranking}</p>
                                                        <h5>Ranking</h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{
                                                position: 'absolute',
                                                bottom: 0,
                                                right: 0
                                            }}>
                                                <button 
                                                    onClick={() => coachingDetail(
                                                        item?.section_type,
                                                        itm?.college_name)}>
                                                    {item?.section_type === "Coaching" ? 
                                                        'View Details'
                                                        :
                                                        <>
                                                            {itm?.button_name}
                                                        </>
                                                    }
                                                </button>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>}
                        </section>
                    }
                    {item?.section_type === "Top study places" &&
                        <section className="common-section section-responsive">
                            <div style={{
                                display: 'flex', 
                                justifyContent: 'center'}}>
                                <h1 style={{
                                    padding: '0 12px'
                                }} className="addon-heading">
                                    {item?.title}
                                </h1>
                            </div>
                            {item?.options &&
                            <div style={{textAlign: 'center'}}
                                className="admission-main-box">
                                <div className="admission-main-box-in">
                                    {item?.options?.map((itm, i) => (
                                        <div 
                                            style={{
                                                display: 'inline-block',
                                            }}
                                            className="t-study-margin"
                                            key={`${item?.section_type}${index}${i}`}>
                                            <img src={itm?.image} width="100" />
                                            <p style={{
                                                fontWeight: 'bold',
                                                fontSize: '22px',
                                                marginTop: '8px',
                                                marginBottom: 0,
                                                textTransform: 'capitalize'
                                            }}>{itm.city}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>}
                        </section>
                    }
                    {(item?.section_type === "Testimonials" && 
                        item?.options && item?.options?.length > 0) &&
                        <section className="common-section section-responsive">
                            <div style={{
                                display: 'flex', 
                                justifyContent: 'center'}}>
                                <h1 style={{
                                    padding: '0 12px'
                                }} className="addon-heading">
                                    {item?.title}
                                </h1>
                            </div>
                            <NewCarousel 
                                from="exam"
                                reviewBreakPoints={reviewBreakPoints} 
                                data={item?.options} />
                        </section>
                    }
                    {(item?.section_type === "News & updates" &&
                        item?.options && item?.options?.length > 0) &&
                        <section className="common-section section-responsive">
                            <div style={{
                                display: 'flex', 
                                justifyContent: 'center'}}>
                                <h1 style={{
                                    padding: '0 24px'
                                }} className="addon-heading">
                                    {item?.title}
                                </h1>
                            </div>
                            <div style={{
                                marginTop: '12px'
                            }}>
                                {item?.options?.map((itm, i) => (
                                    <div key={`${item?.section_type}${index}${i}`}>
                                        <div className="news-update">
                                            <div style={{
                                                borderRight: '1px solid rgba(0, 0, 0, 0.3)',
                                                paddingRight: '12px',
                                                marginRight: '12px'
                                            }}>
                                                <h2>{Moment(itm?.date, 'YYY-MM-DD').format("D")}</h2>
                                                <p style={{color: 'rgba(0, 0, 0, 0.3)'}} 
                                                    className="news-update-month">
                                                    {Moment(itm?.date, 'YYY-MM-DD').format("MMM")}
                                                </p>
                                            </div>
                                            <div className="news-desc-box">
                                                <h3>{itm?.heading}</h3>
                                                <p style={{color: 'rgba(0, 0, 0, 0.3)'}}
                                                    className="news-update-desc">
                                                    {parse(itm?.text)}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="news-hor-line">

                                        </div>
                                    </div>
                                ))}
                            </div>
                        </section>
                    }
                    {(item?.section_type === "Latest blogs" &&
                        item?.options && item?.options?.length > 0) &&
                        <section className="common-section section-responsive">
                            <div style={{
                                display: 'flex', 
                                justifyContent: 'center'}}>
                                <h1 style={{
                                    padding: '0 12px'
                                }} className="addon-heading">
                                    {item?.title}
                                </h1>
                            </div>
                            <BlogCarousel 
                                reviewBreakPoints={reviewBreakPoints} 
                                data={item?.options}/>
                        </section>
                    }
                    {(item?.section_type === "FAQs" &&
                        item?.options && item?.options?.length > 0) &&
                        <section className="common-section section-responsive">
                            <div style={{
                                display: 'flex', 
                                justifyContent: 'center'}}>
                                <h1 style={{
                                    padding: '0 24px'
                                }} className="addon-heading">
                                    {item?.title}
                                </h1>
                            </div>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                marginTop: '12px'
                            }}>
                                {item?.options?.map((itm, i) => (
                                    <div key={`${item?.section_type}${index}${i}`}>
                                        <div className="news-update">
                                            <div style={{
                                                paddingBottom: '16px',
                                                marginBottom: '16px',
                                                borderBottom: '1.5px solid rgba(0, 0, 0, 0.3)'
                                            }}>
                                                <h3>{i+1}. {itm?.question}</h3>
                                                <p style={{color: 'rgba(0, 0, 0, 0.3)'}}
                                                    className="news-update-desc">
                                                    {itm?.answer}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </section>
                    }
                </div>

            ))}
            
            {/* <section className="common-section">
                <h1>
                    Information about Canada
                </h1>
                <div className="country-item-box">
                    {infoFromCountry.map((item, index) => (
                        <div 
                            key={index} 
                            style={{
                                background: item.backgroundColor
                            }}
                            className="add-country-item">
                            <div className="country-inner-content">
                                <img src={InfoIcon} width="36" />
                                <div style={{
                                    marginLeft: '12px'
                                }}>
                                    <p style={{
                                        color: 'rgba(0, 0, 0, 0.43)',
                                    }}>{item.title}</p>
                                    <p style={{
                                        fontWeight: 'bold'
                                    }}>{item.value}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </section> */}
            
            {/* <section className="common-section">
                <div style={{
                    display: 'flex', 
                    justifyContent: 'center'}}>
                    <h1 style={{
                        padding: '0 12px',
                    }}>
                        Benefits of studying in Canada
                    </h1>
                </div>
                <div className="benefit-box">
                    <div>
                        {benefitsData.map((item, index) => (
                            <div 
                                key={index} 
                                style={{
                                    borderTop: index === 0 && '4px solid var(--tertiary-color)',
                                    borderLeft: index === 1 && '4px solid #07AB35',
                                    borderBottom: index === 2 && '4px solid #00A4A2',
                                    borderRight: index === 3 && '4px solid #FFCC5C',
                                }}
                                className="benefit-item">
                                <img src={BenefitIcon} width="60" />
                                <h4>
                                    {item.title}
                                </h4>
                                <p>
                                    {item.description}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            </section> */}
            {/* <section className="common-section">
                <div style={{
                    display: 'flex', 
                    justifyContent: 'center'}}>
                    <h1 style={{
                        padding: '0 12px'
                    }}>
                        Top Courses in Canada
                    </h1>
                </div>
                <div style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    margin: '12px 0 12px 0'
                }}>
                    <a href="" className="view-all-btn">
                        View All
                        <ArrowForwardIcon 
                            sx={{
                                marginBottom: '4px'
                            }}
                        />
                    </a>
                </div>
                <div className="top-course-box-main-box">
                    <div className="top-course-box">
                        {topCourse.map((item, index) => (
                            <div className="top-course-item" key={index}>
                                <div>
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}>
                                        <div className="top-course-icon-box">
                                            <img 
                                                src={BenefitIcon} 
                                                width="72" 
                                                style={{
                                                    marginLeft: '36px',
                                                    marginTop: '12px'
                                                }}
                                                />
                                        </div>
                                        <div style={{
                                            marginLeft: '36px'
                                        }}>
                                            <h4>
                                                {item.title}
                                            </h4>
                                            <p>
                                                {item.colleges} Colleges
                                            </p>
                                        </div>
                                    </div>
                                    <div className="top-course-card-content">
                                        <div>
                                            B.E / B.Tech
                                        </div>
                                        <div>
                                            Diploma in Engineering
                                        </div>
                                        <div>
                                            M.E / M.Tech
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section> */}
            {/* <section className="common-section">
                <div style={{
                    display: 'flex', 
                    justifyContent: 'center'}}>
                    <h1 style={{
                        padding: '0 12px'
                    }}>
                        Admission Requirements for study in Canada
                    </h1>
                </div>
                <p style={{
                    textAlign: 'center',
                    color: 'rgba(0, 0, 0, 0.4)',
                    marginTop: 0
                }}>
                    Lorem ipsum dolor sit amet consectetur. Pharetra egestas 
                    eget ac donec nibh.
                </p>
                <div className="admission-main-box">
                    <div>
                        {admissionData.map((item, index) => (
                            <div 
                                style={{display: 'inline-block'}}
                                key={index}>
                                <div 
                                    className="admission-item"
                                    style={{
                                        borderColor: item.borderColor
                                    }}>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        backgroundColor: item.background,
                                        width: '133px',
                                        height: '151px'
                                    }}>
                                        <img src={item.icon} width="26" />
                                        <p style={{
                                            fontSize: '14px',
                                            textAlign: 'center',
                                            color: 'rgba(0, 0, 0, 0.5)',
                                            marginTop: '20px',
                                            marginBottom: 0
                                        }}>{item.title}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section> */}
            
            {/* <section className="common-section">
                <div style={{
                    display: 'flex', 
                    justifyContent: 'center'}}>
                    <h1 style={{
                        padding: '0 12px'
                    }}>
                        Top study places in Canada
                    </h1>
                </div>
                <div className="admission-main-box">
                    <div style={{
                        textAlign: 'center',
                    }}>
                        {topStudyCenter.map((item, index) => (
                            <div 
                                style={{
                                    display: 'inline-block',
                                }}
                                className="t-study-margin"
                                key={index}>

                                <img src={item.logo} width="100" />
                                <p style={{
                                    fontWeight: 'bold',
                                    fontSize: '22px',
                                    marginTop: '8px',
                                    marginBottom: 0
                                }}>{item.title}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </section> */}
            
            <section 
                className="common-section"
                style={{
                    paddingLeft: 0,
                    paddingRight: 0,
                    paddingBottom: 0,
                    paddingTop: '24px',
                }} id="contactUs">
                <div className="partner-with-form">
                    <div className="img-box">
                        <img src={PartnerFormImage} alt="FormImage" />
                    </div>
                    <div className="partner-form-box">
                        <h1 style={{
                            borderBottom: 0
                        }}>Contact Form</h1>
                        <div className="two-input-aria">
                            <div className="input-box-mobile input-box-aria">
                            <InputLabel className="input-label-text">Name</InputLabel>
                                <TextField 
                                    placeholder="" 
                                    type="text" 
                                    variant="outlined" 
                                    name="name"
                                    value={submitData?.name}
                                    onChange={handleChange}
                                    className="name-field" 
                                    required
                                    InputProps={{
                                        startAdornment: (
                                        <InputAdornment position="start">
                                            <PersonIcon fontSize='small' />
                                        </InputAdornment>
                                        ),
                                    }}
                                />                     
                            </div>
                            <div className="input-box-mobile marginLeftIn input-box-aria">
                            <InputLabel className="input-label-text">Email ID</InputLabel>
                                <TextField 
                                placeholder="" 
                                type="email" 
                                variant="outlined" 
                                name="email" 
                                value={submitData?.email}
                                onChange={handleChange}
                                className="name-field"
                                required
                                InputProps={{
                                    startAdornment: (
                                    <InputAdornment position="start">
                                        <EmailIcon fontSize='small' />
                                    </InputAdornment>
                                    ),
                                }}
                                />
                            </div>
                        </div>
                        <div className="two-input-aria">
                            <div className="input-box-mobile input-box-aria">
                                <InputLabel className="input-label-text">
                                    Contact Number
                                </InputLabel>
                                <div style={{display: 'flex'}}>
                                    <TextField 
                                        placeholder="" 
                                        type="number"
                                        value={submitData?.phone} 
                                        variant="outlined" 
                                        name="phone_number"
                                        onChange={handleChange}
                                        className="name-field" 
                                        required
                                        InputProps={{
                                            startAdornment: (
                                            <InputAdornment position="start">
                                                <PhoneIcon fontSize='small' />
                                            </InputAdornment>
                                            ),
                                        }}
                                        />   
                                </div>                  
                            </div>
                            <div className="input-box-mobile marginLeftIn input-box-aria">
                                
                            </div>
                        </div>
                        <div className="two-input-aria">
                            <div className="input-box-mobile input-box-aria">
                                <InputLabel className="input-label-text">
                                    Message
                                </InputLabel>
                                <div style={{display: 'flex'}}>
                                    <TextField 
                                        name="message"
                                        placeholder="Write description here..." 
                                        className="inputShadow"
                                        variant="outlined" 
                                        value={submitData?.message}
                                        onChange={handleChange}
                                        sx={{
                                            width: '100%',
                                            background: '#fff'
                                        }} 
                                        multiline rows="5"/> 
                                </div>                  
                            </div>
                            <div className="input-box-mobile marginLeftIn input-box-aria">
                                
                            </div>
                        </div>
                        <div className="submit-btn-box" style={{
                            justifyContent: 'flex-start',
                            marginTop: '4px'
                        }}>
                            <Button 
                                disabled={btnLoader}
                                sx={{
                                backgroundColor: btnLoader ? 'rgb(0, 0, 0, 0.3)' : 
                                    'var(--primary-color)',
                                textTransform: 'capitalize',
                                width: '96px',
                                height: '32px',
                                padding: '4px 16px',
                                color: '#fff',
                                "&:hover":{
                                    backgroundColor: btnLoader ? 'rgb(0, 0, 0, 0.3)' :
                                    'var(--primary-color)',
                                }
                            }} onClick={submit}>
                                {btnLoader ? <LoaderIcon /> : 'Submit'}
                            </Button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}