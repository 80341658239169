import * as React from 'react';
import HomeLayout from "../homeLayout/HomeLayout";

export default function Dashboard() {
  return (
    <>
      <HomeLayout />
      <div className="content-box">
        Students
      </div>
      
    </>
  );
}