import * as React from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Input,
  InputLabel,
  TextField,
} from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';
import routes from "../../react_routes_enum";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { useNavigate } from "react-router-dom";
import LoaderIcon from "../../components/Loader/Loader";
import { updateStudentProfile } from "../../redux/students/studentSlice";
import { 
    getPartnerByToken,
    getCoachingByToken,
    getUniversityByToken,
    getStudentByToken } from "../../redux/requestForm/requestFormSlice";
import Loader from "../../assest/images/preloader.gif";
import { toast } from "react-toastify";
import HomeLayout from "../homeLayout/HomeLayout";
import getImageUrls from "../../api/uploadImageOnS3";
import "./profile.css";
import { useState } from "react";

const studentData = {
    dob: "",
    address: ""
}

export default function ConactUs() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [documentList, setDocumentList] = useState(["","","",""]);
    const [userData, setUserData] = useState();
    const [isWebsiteFor, setIsWebsiteFor] = useState(localStorage.getItem("is-website-for"));
    const [btnLoader, setBtnLoader] = useState(false);
    const [studentBody, setStudentBody] = useState(studentData);
    const [loading, setLoading] = React.useState(false);
    const [errors, setErrors] = React.useState(false);

    React.useEffect(() => {
        if(localStorage.getItem("is-website-for") === "student"){
            dispatch(getStudentByToken());
            setUserData(JSON.parse(localStorage.getItem('cc_user')));
            setStudentBody({...studentBody,
                address: JSON.parse(localStorage.getItem('cc_user'))?.student?.address,
                dob: JSON.parse(localStorage.getItem('cc_user'))?.student?.dob,
            });
            if(JSON.parse(localStorage.getItem('cc_user'))?.student?.profile_picture){
                setStudentBody({
                    address: JSON.parse(localStorage.getItem('cc_user'))?.student?.address,
                    dob: JSON.parse(localStorage.getItem('cc_user'))?.student?.dob,
                    profile_picture: JSON.parse(localStorage.getItem('cc_user'))?.student?.profile_picture})
            }
        }else if(localStorage.getItem("is-website-for") === "university"){
            dispatch(getUniversityByToken());
            setUserData(JSON.parse(localStorage.getItem('cc_user')));
            setStudentBody({...studentBody,
                address: JSON.parse(localStorage.getItem('cc_user'))?.student?.address,
                dob: JSON.parse(localStorage.getItem('cc_user'))?.student?.dob,
            });
            if(JSON.parse(localStorage.getItem('cc_user'))?.student?.profile_picture){
                setStudentBody({
                    address: JSON.parse(localStorage.getItem('cc_user'))?.student?.address,
                    dob: JSON.parse(localStorage.getItem('cc_user'))?.student?.dob,
                    profile_picture: JSON.parse(localStorage.getItem('cc_user'))?.student?.profile_picture})
            }
        }
        else if(localStorage.getItem("is-website-for") === "coaching"){
            dispatch(getCoachingByToken());
            setUserData(JSON.parse(localStorage.getItem('cc_user')));
            setStudentBody({...studentBody,
                address: JSON.parse(localStorage.getItem('cc_user'))?.student?.address,
                dob: JSON.parse(localStorage.getItem('cc_user'))?.student?.dob,
            });
            if(JSON.parse(localStorage.getItem('cc_user'))?.student?.profile_picture){
                setStudentBody({
                    address: JSON.parse(localStorage.getItem('cc_user'))?.student?.address,
                    dob: JSON.parse(localStorage.getItem('cc_user'))?.student?.dob,
                    profile_picture: JSON.parse(localStorage.getItem('cc_user'))?.student?.profile_picture})
            }
        }
        else if(localStorage.getItem("is-website-for") === "partner"){
            dispatch(getPartnerByToken());
            setUserData(JSON.parse(localStorage.getItem('cc_user')));
            setStudentBody({...studentBody,
                address: JSON.parse(localStorage.getItem('cc_user'))?.student?.address,
                dob: JSON.parse(localStorage.getItem('cc_user'))?.student?.dob,
            });
            if(JSON.parse(localStorage.getItem('cc_user'))?.student?.profile_picture){
                setStudentBody({
                    address: JSON.parse(localStorage.getItem('cc_user'))?.student?.address,
                    dob: JSON.parse(localStorage.getItem('cc_user'))?.student?.dob,
                    profile_picture: JSON.parse(localStorage.getItem('cc_user'))?.student?.profile_picture})
            }
        }
    },[])

    const moreDocument = () => {
        let array = documentList;
        array.push("");
        setDocumentList([...array]);
    }

    const handleChange = (e) => {
        if(localStorage.getItem("is-website-for") === "student"){
            e.target.name === "address" && setStudentBody({
                ...studentBody, 
                address: e.target.value})
            e.target.name === "date" && setStudentBody({
                ...studentBody, 
                dob: `${e.target.value}T00:00:00.000Z`});
        }
    }

    const uploadImage = async(e) => {
        if(e.target.files[0]?.type === "image/png" || 
            e.target.files[0]?.type === "image/jpeg"){
            const response = await getImageUrls({
                image: e.target.files[0],
                setLoading,
                setErrors
            });
            if(localStorage.getItem("is-website-for") === "student"){
                setStudentBody({
                    ...studentBody, 
                    profile_picture: response?.data?.urls[0]});
            }
        }else{
            toast.error("Invalid file type");
            return;
        }
    }

    const applyNow = () => {
        if(localStorage.getItem("is-website-for") === "student"){
            setBtnLoader(true);
            const res = dispatch(updateStudentProfile(studentBody));
            res?.then((r) => {
                setBtnLoader(false);
                if(r?.payload?.statusCode === 200){
                    dispatch(getStudentByToken());
                    toast.success("Data updated successfully");
                }else{
                    (r?.payload?.message === "Session Expired" || 
                    r?.payload?.message === "Unauthorized") ?
                    toast.error("Your session expired, please login") :
                    toast.error(r?.payload?.message);
                }
            })
        }
    }

    const cancelCLicked = () => {
        if(localStorage.getItem("is-website-for") === "student"){
            navigate(routes.STUDENT_DASHBOARD);
        }else{
            navigate(routes.PRODUCTS);
        }
    }

    return (
        <>
            <HomeLayout />
            <div 
                className="content-box table-box" 
                style={{
                    marginTop: '100px', 
                    color: '#000',
                    overflow: 'hidden'
                }}>
                <div className="profile-pic-box">
                    <div></div>
                    <div>
                        <div className="profile-pic-out">
                            {isWebsiteFor === "student" &&
                            <div className="profile-pic-in">
                                {(studentBody?.profile_picture &&
                                    studentBody?.profile_picture !== "") ?
                                <img
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        borderRadius: '50%'
                                    }}
                                    src={studentBody?.profile_picture} 
                                    alt="ProfilePic" /> : 
                                <PersonIcon sx={{fontSize: '84px'}} />}
                            </div>}
                            {(isWebsiteFor === "university" ||
                            isWebsiteFor === "coaching" ||
                            isWebsiteFor === "partner") &&
                            <div className="profile-pic-in">
                                {(userData?.university?.uploads?.university_logo ||
                                userData?.coaching?.uploads?.coaching_logo) ?
                                <img
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        borderRadius: '50%'
                                    }}
                                    src={
                                        userData?.university?.uploads?.university_logo ||
                                        userData?.coaching?.uploads?.coaching_logo
                                    }
                                    alt="ProfilePic" /> : 
                                <PersonIcon sx={{fontSize: '84px'}} />}
                            </div>}
                            <p style={{textTransform: 'capitalize'}}>
                                {userData?.student?.name || 
                                userData?.university?.university_name ||
                                userData?.coaching?.coaching_name ||
                                userData?.partner?.name}</p>
                            <label htmlFor="profile-img">
                                <Input
                                    accept="image/*"
                                    id="profile-img"
                                    name="image-upload"
                                    type="file"
                                    sx={{display: 'none'}}
                                    onChange={uploadImage}
                                />
                                <Button component="div" className="prof-img-btn">
                                    <span style={{
                                        color: "#3C1CDE",
                                        textTransform: 'capitalize',
                                        marginRight: '4px'
                                    }}>change profile picture</span>
                                    {loading && <img src={Loader} alt="loader" width="18" />}
                                </Button>
                            </label>
                        </div>
                    </div>
                    <div className="apply-button-1">
                        <Button 
                            variant="contained" 
                            disabled={btnLoader}
                            sx={{
                                width: 80,
                                backgroundColor: btnLoader ? 'rgb(0, 0, 0, 0.3)' : '#F69220',
                                textTransform: 'capitalize',
                                '&:hover':{
                                    backgroundColor: '#F69220',
                                }
                            }} onClick={applyNow}>
                            {btnLoader ? <LoaderIcon /> : 'Apply'}
                        </Button>
                        <Button variant="outlined" sx={{
                            color: '#F69220',
                            textTransform: 'capitalize',
                            marginLeft: '8px'
                        }} onClick={cancelCLicked}>
                            Cancel
                        </Button>
                    </div>
                </div>
                <div className="profile-content-box inp">
                    <div className="profile-two-inp">
                        <div className="profile-in">
                            <InputLabel className="input-label-text">
                                {(isWebsiteFor === "student" ||
                                isWebsiteFor === "partner") && 'Email ID'}
                                {isWebsiteFor === "university" && 
                                    'Name of Chairperson or HODs'}
                                {isWebsiteFor === "coaching" && 'Name of Owner'}
                            </InputLabel>
                            <TextField 
                                placeholder="" 
                                type="text" 
                                variant="outlined" 
                                name="name"
                                className="name-field" 
                                value={userData?.university?.name ||
                                    userData?.student?.email ||
                                    userData?.coaching?.owner_name ||
                                    userData?.partner?.email
                                }
                                required
                            />
                        </div>
                        <div className="profile-in-add">
                            <InputLabel className="input-label-text">
                                Full Address <ModeEditIcon 
                                    fontSize="small" 
                                    sx={{color: 'rgb(0, 0, 0, 0.4)'}} />
                            </InputLabel>
                            <TextField 
                                placeholder="" 
                                type="text" 
                                variant="outlined" 
                                name="address"
                                className="name-field" 
                                value={userData?.university?.address ||
                                    studentBody?.address ||
                                    userData?.coaching?.address}
                                required
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="profile-two-inp">
                        <div className="profile-in">
                            <InputLabel className="input-label-text">
                                Phone Number
                            </InputLabel>
                            <TextField 
                                placeholder="" 
                                type="text" 
                                variant="outlined" 
                                name="name"
                                className="name-field" 
                                value={`${
                                    userData?.university?.phone_country_code ||
                                    userData?.student?.phone_country_code ||
                                    userData?.coaching?.phone_country_code ||
                                    ""
                                }-${
                                    userData?.university?.phone ||
                                    userData?.student?.phone ||
                                    userData?.coaching?.phone ||
                                    ""
                                }`}
                                required
                            />
                        </div>
                        <div className="profile-in">
                            <InputLabel className="input-label-text">
                                {(isWebsiteFor === "student" ||
                                isWebsiteFor === "partner") && 
                                <>Date of birth <ModeEditIcon 
                                fontSize="small" 
                                sx={{color: 'rgb(0, 0, 0, 0.4)'}} /></>}
                                {(isWebsiteFor === "university" ||
                                isWebsiteFor === "coaching") && 
                                    'Official Email Id'}
                            </InputLabel>
                            <TextField 
                                placeholder="" 
                                type={isWebsiteFor === "student" ? 'date' : 'email'}
                                variant="outlined" 
                                name={isWebsiteFor === "student" ? 'date' : 'email'}
                                className="name-field" 
                                onChange={handleChange}
                                value={userData?.university?.email || 
                                    (userData?.student && new Date(studentBody?.dob)?.toISOString()?.split('T')[0]) ||
                                    userData?.coaching?.email
                                }
                                required
                            />
                        </div>
                    </div>
                </div>
                {(isWebsiteFor === "university" || isWebsiteFor === "coaching") &&
                <div className="profile-content-box">
                    <InputLabel className="input-label-text" style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        Document List <ModeEditIcon sx={{
                            marginLeft: '6px',
                            marginBottom: '2px',
                            color: 'rgb(0, 0, 0, 0.3)'
                        }} fontSize="small" />
                    </InputLabel>
                    <div style={{
                        display: 'inline-block'
                    }}>
                        {documentList?.map((item, index) => (
                        <div key={index} className="document-item">
                            <div className="document-box-e">
                                <div className="document-text">Document {index+1}</div>
                                <TextField 
                                    placeholder="" 
                                    type="text" 
                                    variant="outlined" 
                                    name="document"
                                    className="name-field" />
                            </div>
                        </div>))}
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row-reverse',
                        }} className="add-doc">
                            <Button onClick={moreDocument} style={{
                                textTransform: 'capitalize',
                                color: '#3C1CDE'
                            }}>
                                + Add more documents
                            </Button>
                        </div>
                    </div>
                </div>}
                <div className="apply-button-2">
                    <Button 
                        variant="contained" 
                        disabled={btnLoader}
                        sx={{
                            width: 80,
                            backgroundColor: btnLoader ? 'rgb(0, 0, 0, 0.3)' : '#F69220',
                            textTransform: 'capitalize',
                            '&:hover':{
                                backgroundColor: '#F69220',
                            }
                        }} onClick={applyNow}>
                        {btnLoader ? <LoaderIcon /> : 'Apply'}
                    </Button>
                    <Button variant="outlined" sx={{
                        color: '#F69220',
                        textTransform: 'capitalize',
                        marginLeft: '8px'
                    }} onClick={cancelCLicked}>
                        Cancel
                    </Button>
                </div>
            </div>
        
        </>
    );
}
