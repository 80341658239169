import * as React from 'react';
import { Button } from "@mui/material";
import Rating from '@mui/material/Rating';
import EastIcon from '@mui/icons-material/East';
import "./instituteCard.css";

const InstituteCard = (props) => {
    
    return (
        <div className='card-box'>
            <div className="card-image">
                <img 
                    src={props?.data?.uploads?.photo_gallery[0]} 
                    alt="instituteImage" 
                    className="inst-card-image"/>
                <div 
                    className="logo"
                    style={{
                        height: '60px',
                        width: '80px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                    <img 
                        src={props?.data?.uploads?.coaching_logo || props?.data?.uploads?.university_logo} 
                        alt="InstituteLogo" style={{
                            width: '100%', 
                            height: '100%',
                            borderRadius: '5px',
                            objectFit: 'contain'
                        }}/>
                </div>
            </div>
            <div className="card-detail-aria">
                <h3 className="card-title">
                    {props?.data?.university_name && 
                        `${props?.data?.university_name.toUpperCase()}, ${props?.data?.information?.city.toUpperCase()}`}
                    {props?.data?.coaching_name && 
                        `${props?.data?.coaching_name.toUpperCase()}, ${props?.data?.information?.city.toUpperCase()}`}
                </h3>
                <div className="card-rating">
                    <Rating
                        name="simple-controlled"
                        value={props?.data?.rating > 0 ? props?.data?.rating : 4}
                        readOnly
                        size="small"
                    />
                </div>
                {props?.data?.university_name && <div className='card-number-aria'>
                    <div>
                        <h5>{props?.data?.information?.enrolled_students_count}</h5>
                        <p>Total number of Students</p>
                    </div>
                    <div className='card-placent-aria'>
                        <h5>{props?.data?.information?.placement_percentage}</h5>
                        <p>Placement %</p>
                    </div>
                    <div>
                        <h5>{props?.data?.information?.courses_count}</h5>
                        <p>Total Courses</p>
                    </div>
                </div>}
                {console.log("==============",props?.data)}
                {/* {props?.data?.coaching_name && 
                <div>
                    <p style={{
                        color: '#07AB35',
                        fontWeight: 'bold'
                    }} className="online-offline-txt">
                        ONLINE
                    </p>
                </div>} */}
            </div>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
            }} className="card-footer-x">
                 {/* <Button type="button" className="btn-apply-now">Apply Now</Button> */}
                 <Button type="button" className="btn-brochure view-inst-detail" onClick={() => {props.viewDetail(props?.data?._id)}}>
                    View Details
                    <EastIcon fontSize="small" sx={{
                        marginLeft: '4px',
                        marginBottom: '2px'
                    }}/>
                 </Button>
            </div>
        </div>
    )
}

export default InstituteCard;