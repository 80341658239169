import * as React from 'react';
import { makeStyles } from "@mui/styles";
import HeaderImage from "../../assest/images/header-image.png";
import Carousel from 'react-elastic-carousel';
import "./secondaryHead.css";

const useStyle = makeStyles({
    root:{
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        alignItems: 'flex-end',
        width: '100%',
    },
    centerHead: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        color: 'white',
        fontSize: '24px',
        textTransform: 'uppercase',
        fontWeight: 'bold',
    }
});

const SecondaryHead = (props) => {
    const classes = useStyle();
    
    return (
        <>
            <div style={{
                background: `url(${props?.backImage ? props?.backImage : HeaderImage}) center`,
                height: props.headingH ? props.headingH : '334px',
                minHeight: '334px'
            }} className={`${classes.root} mrT`}>
                {(props.from !== "blog" && props.heading) && <h4 style={{
                    fontSize: '38px',
                    color: 'white',
                    fontWeight: 'bold',
                    width: props.textWidth ? props.textWidth : '200px'
                }} className='header-hed-text'>{props.heading}</h4>}
                {props.from === "blog" &&
                <div className="blog-heading-box">
                    <div style={{
                        display: 'flex',
                    }}>
                        <h1>
                            Blog
                        </h1>
                        <h2>
                            Resource
                        </h2>
                    </div>
                    <p>
                        Read all the resources on CollegeClue blog to know more about 
                        Coaching admissions and Various Entrance Exams
                    </p>
                </div>
                }
                {props.isSlider &&
                <Carousel 
                    itemsToShow={1}
                    className={`secondary-header-carousel ${!props.isDotted && 'is-dotted'}`}
                    showArrows={false}>
                    {props.sliderContent.map((item, index) => (
                    <div key={index} style={{position: 'relative'}}>
                        <img src={item.image} alt="SliderImage" />
                        <div style={{
                            position: 'absolute',
                            top: 0,
                            width: '100%'
                        }}>
                            <div className={`${classes.centerHead} center-head`}>
                                <p style={{
                                    marginBottom: 0,
                                    marginTop: 0
                                }}>
                                    <span>{item.text[0]}</span>
                                    <span style={{
                                        color: '#ECAB2D',
                                        marginLeft: '12px'
                                    }}>{item.text[1]}</span>
                                </p>
                                <p style={{
                                    marginTop: '8px'
                                }}>
                                    {item.text[2]}
                                </p>
                            </div>
                        </div>
                    </div>))}
                </Carousel>}
            </div>
        </>
    )
}

export default SecondaryHead;