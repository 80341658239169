import axios from "axios";
import endpoint from "../../index.routes";

//Login User
const login = async (userData) => {
  let path = "";
  let body = {
    email: userData.email,
    password: userData.password,
  }
  if(userData.isLoginFor === "university"){ 
    path = endpoint.LOGIN
  }
  if(userData.isLoginFor === "coaching"){ 
    path = endpoint.COACHING_LOGIN
  }
  if(userData.isLoginFor === "student"){ 
    path = endpoint.STUDENT_LOGIN
  }
  if(userData.isLoginFor === "partner"){ 
    path = endpoint.PARTNER_LOGIN
  }
  const response = await axios.post(path, body);
  console.log("hello=====",response);
  if (response.status === 200) {
    localStorage.setItem("cc_user", JSON.stringify(response.data.data));
    localStorage.setItem("cc_token", response.data.data.token);
  }
  return response.data;
};

const forgotPassword = async (params) => {
  const { body, path } = params;
  const response = await axios.post(path, body);
  return response.data;
};

const resetPassword = async (params) => {
  const { body, path } = params;
  const response = await axios.post(path, body);
  return response.data;
};

const authServices = { 
  login,
  forgotPassword,
  resetPassword
};

export default authServices;
