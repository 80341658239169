import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Typography,
  TextField,
} from "@mui/material";
import routes from "../../../react_routes_enum";
import { makeStyles } from "@mui/styles";
import HeaderBackground from "../../../assest/images/header-background.jpg";
import HeaderIcon from "../../../assest/images/header-icon.png";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import { useNavigate } from "react-router-dom";
import { updateData, 
    getUniversityByToken, 
    getCoachingByToken } from "../../../redux/requestForm/requestFormSlice";
import { toast } from "react-toastify";
import LoaderIcon from "../../../components/Loader/Loader";
import { validateUrl } from "../../../utils/validateUrl";
import UploadBox from '../../../components/UploadBox/uploadBox';
import { logout, reset } from "../../../redux/auth/authSlice";

const useStyle = makeStyles({
    dot: {
        height: '16px',
        width: '16px',
        borderRadius: '50%',
    },
    line: {
        height: '4px',
        backgroundColor: 'rgb(246, 146, 32, 0.4)'
    },
    changesButton: {
        color: '#fff !important',
        textTransform: 'capitalize !important',
        width: '140px',
        height: '40px',
        fontSize: '16px !important',
        borderRadius: '24px !important',
        boxShadow: '0 2px 5px rgb(0, 0, 0, 0.5)',
        "&:hover":{
            backgroundColor: 'var(--secondary-color) !important',
        }
    },
    cancelButton: {
        backgroundColor: '#fff !important',
        color: 'var(--secondary-color) !important',
        textTransform: 'capitalize !important',
        width: '140px',
        height: '40px',
        fontSize: '16px !important',
        borderRadius: '24px !important',
        borderColor: 'var(--secondary-color) !important',
        "&:hover":{
            backgroundColor: '#fff',
        }
    },
    header: {
        marginTop: '100px',
        marginBottom: '4%',
        backgroundImage: `url(${HeaderBackground})`,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    formIconsBox: {
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        marginTop: '5%',
        marginRight: '14%',
    },
    iconBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    horizontalLine: {
        height: '4px',
        backgroundColor: 'var(--primary-color)',
    },
    iconBox2: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'absolute', 
    },
    iconStyle: {
        backgroundColor: 'var(--primary-color)',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    iconText: {
        color: 'var(--primary-color)',
        marginTop: '8px !important',
    },
    icon: {
        color: '#fff',
    },
    leftBox: {
        height: '200px',
        width: '200px',
        borderRadius: '8px',
        border: '2px dashed rgb(0, 0, 0, 0.5)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    upload: {
        border: '1px solid rgb(0, 0, 0, 0.2) !important',
        fontSize: '15px !important',
        color: 'rgb(0, 0, 0, 0.4) !important',
        textTransform: 'capitalize !important',
        width: '122px',
        height: '34px'
    },
    addMore:{
        height: '36px',
        textTransform: 'capitalize !important',
        fontWeight: 'bold !important',
    },
})

let uploadData = {
    uploads_check: 1,
    uploads: {
        university_logo: "",
        coaching_logo: "",
        introductory_video: "",
        photo_gallery: [""]
    },
}

export default function RequestRes() {
    const isWebsiteFor = localStorage.getItem("is-website-for");
    const [btnLoader, setBtnLoader] = React.useState(false);
    const {
        universityByToken,
        coachingByToken } = useSelector((state) => state.form);
    const navigate = useNavigate();
    const classes = useStyle();
    const dispatch = useDispatch();
    // const [uploadInfo, setUploadInfo] = React.useState((coachingByToken?.data?.uploads || universityByToken?.data?.uploads) ?
    //     {
    //         uploads_check: 1,
    //         uploads: isWebsiteFor === "coaching" ? universityByToken?.data?.uploads : universityByToken?.data?.uploads
    //     } : uploadData);
    let uploadDataInfo = coachingByToken?.data?.uploads_check ? coachingByToken?.data?.uploads : universityByToken?.data?.uploads

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [uploadInfo, setUploadInfo] = React.useState((coachingByToken?.data?.uploads_check || universityByToken?.data?.uploads_check) ?
        {
            uploads_check: 1, 
            uploads: (coachingByToken?.data?.uploads_check) ? (coachingByToken?.data?.uploads) : (universityByToken?.data?.uploads),
        }
        :
        uploadData
    );
    
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [gallaryPhotoNumber, setGallaryPhotoNumber] = React.useState((coachingByToken?.data?.uploads_check || 
        universityByToken?.data?.uploads_check) ? (coachingByToken?.data?.uploads_check ? coachingByToken?.data?.uploads?.photo_gallery : 
            universityByToken?.data?.uploads?.photo_gallery) : ['']);

    const cancelClicked = () => {
        // navigate(routes.REQUEST_FORM);
        setBtnLoader(false);
        dispatch(logout());
        dispatch(reset());
        navigate("/login");
    }

    const saveAndNext = () => {
        let body = {};
        if(uploadInfo.uploads.university_logo === "" && uploadInfo.uploads.coaching_logo === ""){
            return toast.error("Logo required");
        }
        if(uploadInfo.uploads.photo_gallery.length === 0 || uploadInfo.uploads.photo_gallery[0] === ""){
            return toast.error("Upload atleast 1 gallery photo");
        }
        if(uploadInfo.uploads.introductory_video !== ""){
            if(!validateUrl(uploadInfo.uploads.introductory_video)){
                return toast.error("Invalid video url");
            }
        }
        if(isWebsiteFor === "coaching"){
            uploadInfo.uploads.introductory_video !== "" ?
            body = {
                uploads_check: 1,
                uploads: {
                    coaching_logo: uploadInfo.uploads.coaching_logo,
                    introductory_video: uploadInfo.uploads.introductory_video,
                    photo_gallery: uploadInfo.uploads.photo_gallery
                }
            }:
            body = {
                uploads_check: 1,
                uploads: {
                    coaching_logo: uploadInfo.uploads.coaching_logo,
                    photo_gallery: uploadInfo.uploads.photo_gallery
                }
            }

        }else{
            uploadInfo.uploads.introductory_video !== "" ? 
            body = {
                uploads_check: 1,
                uploads: {
                    university_logo: uploadInfo.uploads.university_logo,
                    introductory_video: uploadInfo.uploads.introductory_video,
                    photo_gallery: uploadInfo.uploads.photo_gallery
                }
            }:
            body = {
                uploads_check: 1,
                uploads: {
                    university_logo: uploadInfo.uploads.university_logo,
                    photo_gallery: uploadInfo.uploads.photo_gallery
                }
            }
        }
        setBtnLoader(true);
        let res = dispatch(updateData({isWebsiteFor, body}));
        res.then((r) => {
            setBtnLoader(false);
            if(r.payload.statusCode === 200){
                toast.success(r.payload.message);
                isWebsiteFor === "coaching" ? dispatch(getCoachingByToken()) : dispatch(getUniversityByToken());
                isWebsiteFor === "coaching" ? navigate(routes.COACHING_COURSE) : navigate(routes.UNIVERSITY_COURSE);
            }else{
                toast.error(r.payload.message);
            }
        })
    }
    // eslint-disable-next-line react-hooks/rules-of-hooks
    React.useEffect(() => {
        const res = isWebsiteFor === "coaching" ? dispatch(getCoachingByToken()) : dispatch(getUniversityByToken());
        res.then((r) => {
            if(r?.payload?.statusCode === 200){
                if(r?.payload?.data?.uploads_check){
                    setUploadInfo({
                        uploads_check: 1,
                        uploads: r?.payload?.data?.uploads
                    })
                    let array = []
                    r?.payload?.data?.uploads?.photo_gallery?.map((item, index) => {
                        array[index] = item;
                    })
                    setGallaryPhotoNumber([...array])
                }
            }
        })
    },[])
    
    const handleImageUploads = (name, value) => {
        if(name === "logo-upload"){
            isWebsiteFor === "coaching" ? setUploadInfo({...uploadInfo, uploads: {...uploadInfo.uploads, coaching_logo: value}}) : 
                setUploadInfo({...uploadInfo, uploads: {...uploadInfo.uploads, university_logo: value}})
        }else{
            let array = gallaryPhotoNumber;
            array[parseInt(name.split("-")[2])] = value;
            setGallaryPhotoNumber([...array]);
            setUploadInfo({...uploadInfo, uploads: {...uploadInfo.uploads, photo_gallery: array}});
        }
    }
    const addMore = (i) => {
        if(gallaryPhotoNumber.length < 5){
            let array = gallaryPhotoNumber;
            array.push('')
            setGallaryPhotoNumber([...array]);
        }else{
            toast.error("Maximum 5 photo allowed")
        }
    }
    return (
        <Box>
            <section className={classes.header + ' header-set'}>
                <div className="header-icon-box">
                    <img src={HeaderIcon} alt="header-icon" style={{width: '100%'}} />
                </div>
                <Typography component="h2" variant="h2" className="header-heading">
                    UNIVERSITY - LISTING
                </Typography>
                <Typography component="p" variant="p" className="small-header-heading">
                    Lorem ipsum dolor sit amet consectetur.
                </Typography>
            </section>
            <Box sx={{p: 3}}>
                <section>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginBottom: '8px'
                        }} className="progr-lin-box">
                            <div className={classes.dot} style={{
                                background: 'none',
                                }}>
                                <CheckCircleOutlineIcon sx={{width: '100%', color: '#07AB35'}} />
                            </div>
                            <div className={classes.line + ' progress-line'} style={{background: 'none'}}>

                            </div>
                            <div className={classes.dot} style={{
                                background: 'none',
                                visibility: (universityByToken?.data?.information_check && universityByToken?.data?.uploads_check &&
                                    universityByToken?.data?.courses_offered_check && universityByToken?.data?.placement_records_check
                                    ) ? 
                                    'visible' : 'hidden'
                                }}>
                                <CheckCircleOutlineIcon sx={{
                                    width: '100%', 
                                    color: '#07AB35',
                                    }} />
                            </div>
                            <div className={classes.line + ' progress-line'} style={{background: 'none'}}>

                            </div>
                            <div className={classes.dot} style={{
                                background: 'none',
                                visibility: (universityByToken?.data?.information_check && universityByToken?.data?.uploads_check &&
                                    universityByToken?.data?.courses_offered_check && universityByToken?.data?.placement_records_check
                                    && universityByToken?.data?.request_status === "accepted") ? 
                                    'visible' : 'hidden'
                                }}> 
                                <CheckCircleOutlineIcon sx={{width: '100%', color: '#07AB35'}} />
                            </div>
                        </div>
                    </div>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }} className="progr-lin-box">
                            <div className={classes.dot} style={{backgroundColor: 'var(--secondary-color)'}}>
                                
                            </div>
                            <div className={classes.line + ' progress-line'} style={{
                                backgroundColor: (universityByToken?.data?.information_check && 
                                    universityByToken?.data?.uploads_check &&
                                    universityByToken?.data?.courses_offered_check && 
                                    universityByToken?.data?.placement_records_check) ? 'var(--secondary-color)' :
                                    'rgb(246, 146, 32, 0.4)'
                            }}>

                            </div>
                            <div className={classes.dot} style={{backgroundColor: (universityByToken?.data?.information_check && 
                                    universityByToken?.data?.uploads_check &&
                                    universityByToken?.data?.courses_offered_check && 
                                    universityByToken?.data?.placement_records_check) ? 'var(--secondary-color)' :
                                    'rgb(246, 146, 32, 0.4)'}}>

                            </div>
                            <div className={classes.line + ' progress-line'} style={{
                                backgroundColor: (universityByToken?.data?.information_check && 
                                    universityByToken?.data?.uploads_check &&
                                    universityByToken?.data?.courses_offered_check && 
                                    universityByToken?.data?.placement_records_check &&
                                    universityByToken?.data?.request_status === "accepted") ? 'var(--secondary-color)' :
                                    'rgb(246, 146, 32, 0.4)'
                            }}>

                            </div>
                            <div className={classes.dot} style={{backgroundColor: (universityByToken?.data?.information_check && 
                                    universityByToken?.data?.uploads_check &&
                                    universityByToken?.data?.courses_offered_check && 
                                    universityByToken?.data?.placement_records_check &&
                                    universityByToken?.data?.request_status === "accepted") ? 'var(--secondary-color)' :
                                    'rgb(246, 146, 32, 0.4)'}}>
                            </div>
                        </div>
                    </div>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '12px'
                    }}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            justifyContent: 'space-between',
                        }} className="progr-text-box">
                            <Typography component="h2" variant="h2" className="progr-text">
                                Fill the Request Form
                            </Typography>
                            <Typography component="h2" variant="h2" className="progr-text">
                                Fill the Listing Registration Form
                            </Typography>
                            <Typography component="h2" variant="h2" className="progr-text">
                                LIVE Listing
                            </Typography>
                        </div>
                    </div>
                </section>
                <section>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    }} className="request-main-res-box">
                        <div style={{
                            width: '100%',
                        }}>
                            <div className={classes.formIconsBox + ' reg-form-box'}>
                                <div className={classes.iconBox+' icon-box-2'}>
                                    <div style={{position: 'relative'}}>
                                        <div className={classes.horizontalLine+' horizontal-line-2'}></div>
                                        <div className={classes.iconBox2+' icon-1'}>
                                            <div className={classes.iconStyle+' icon-style-2'} 
                                                style={{
                                                    color: '#fff', 
                                                    fontSize: '20px'
                                                    }}>
                                                i
                                            </div>
                                            <Typography component="h2" variant="h2" className={classes.iconText+' icon-text'}>
                                                Information
                                            </Typography>
                                        </div>
                                    </div>
                                    <div style={{position: 'relative'}}>
                                        <div className={classes.horizontalLine+' horizontal-line-2'} 
                                            style={{backgroundColor: 'var(--primary-color)'}}></div>
                                        <div className={classes.iconBox2+' icon-2'}>
                                            <div className={classes.iconStyle+' icon-style-2'} 
                                                style={{backgroundColor: 'var(--primary-color)'}}>
                                                <CameraAltIcon className={classes.icon} fontSize="small" />
                                            </div>
                                            <Typography component="h2" variant="h2" className={classes.iconText+' icon-text'} 
                                            style={{color: 'var(--primary-color)'}}>
                                                Uploads
                                            </Typography>
                                        </div>
                                    </div>
                                    <div style={{position: 'relative'}}>
                                        <div className={classes.horizontalLine+' horizontal-line-2'} 
                                            style={{backgroundColor: 'var(--disable-color)'}}></div>
                                        <div className={classes.iconBox2+' icon-3'}>
                                            <div className={classes.iconStyle+' icon-style-2'} 
                                                style={{backgroundColor: 'var(--disable-color)'}}>
                                                <MenuBookIcon className={classes.icon} fontSize="small" />
                                            </div>
                                            <Typography component="h2" variant="h2" className={classes.iconText+' icon-text'} 
                                            style={{color: 'var(--disable-color)'}}>
                                                Courses
                                            </Typography>
                                        </div>
                                    </div>
                                    {isWebsiteFor === "university" &&
                                    <div style={{position: 'relative'}}>
                                        <div className={classes.horizontalLine+' horizontal-line-2'} 
                                            style={{backgroundColor: 'var(--disable-color)'}}></div>
                                        <div className={classes.iconBox2+' icon-4'}>
                                            <div className={classes.iconStyle+' icon-style-2'} 
                                                style={{backgroundColor: 'var(--disable-color)'}}>
                                                <BusinessCenterIcon className={classes.icon} fontSize="small" />
                                            </div>
                                            <Typography component="h2" variant="h2" className={classes.iconText+' icon-text'} 
                                            style={{color: 'var(--disable-color)', textAlign: 'center'}}>
                                                Placement Records
                                            </Typography>
                                        </div>
                                    </div>}
                                </div>
                            </div>
                            <>
                                <div style={{
                                    marginTop: '130px'
                                }} className="upload-text-box">
                                    <Typography component="h2" variant="h2" className="upload-doc-text">
                                        Upload Documents
                                    </Typography>
                                    <Typography component="p" variant="p" className="upload-doc-small-text" 
                                        sx={{
                                            fontSize: '14px',
                                            color: 'rgb(0, 0, 0, 0.5)'
                                            }}>
                                        Upload the following documents in the mention format.
                                    </Typography>
                                </div>
                                <div className="upload-aria">
                                    <div className="pics-box">
                                        <div>
                                            <Typography component="h2" variant="h2" className="input-label-text">
                                                1. Upload Institute Logo
                                            </Typography>
                                            <div style={{
                                                margin: '5% 0 5% 12%'
                                            }}>
                                                <UploadBox
                                                    name="logo-upload"
                                                    value={isWebsiteFor === "coaching" ? uploadInfo?.uploads?.coaching_logo : 
                                                        uploadInfo?.uploads?.university_logo}
                                                    handleUpload={(name, value) => handleImageUploads(name, value)} 
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <Typography component="h2" variant="h2" className="input-label-text">
                                                3. Upload photo-gallery of your institute</Typography>
                                            <div style={{
                                                display: 'inline-block',
                                                width: '100%',
                                            }}>
                                                {gallaryPhotoNumber?.map((item, index) => (
                                                    <div key={index} style={{
                                                        margin: '5% 0 5% 12%',
                                                        display: 'inline-block'
                                                    }}>
                                                        <UploadBox
                                                            name={`gallary-upload-${index}`}
                                                            value={item}
                                                            handleUpload={(name, value) => handleImageUploads(name, value)} 
                                                        />
                                                    </div>
                                                ))}
                                                <div className="add-more-btn" style={{
                                                    display: 'inline-block',
                                                }}>
                                                    <Button className={classes.addMore} 
                                                        sx={{backgroundColor: isWebsiteFor === "coaching" ?
                                                            'var(--tertiary-color)' : 'var(--primary-color)'}}
                                                        onClick={() => addMore(uploadInfo?.uploads?.photo_gallery.length)} 
                                                        variant="contained">
                                                        + Add More
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <Typography component="h2" variant="h2" className="input-label-text">
                                            2. Upload Institute Introductory/ Promotional video&nbsp;
                                            <span className="optional-text-style">(Optional)</span>
                                            </Typography>
                                        <TextField 
                                            className="inputShadow"
                                            placeholder="" 
                                            onChange={(e) => 
                                                setUploadInfo({
                                                    ...uploadInfo, 
                                                    uploads: {
                                                        ...uploadInfo.uploads, 
                                                        introductory_video: e.target.value
                                                    }})
                                            } 
                                            value={uploadInfo?.uploads?.introductory_video}
                                            variant="outlined" 
                                            sx={{
                                                width: '100%',
                                                height: '62px',
                                                marginTop: '2%'
                                            }} />
                                    </div>
                                </div>
                            </>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                marginTop: '48px',
                                marginBottom: '52px'
                            }}>
                                <Button className={classes.changesButton} onClick={saveAndNext} disabled={btnLoader} sx={{
                                    backgroundColor: btnLoader ? 'var(--disabled-color)' : (
                                        isWebsiteFor === 'coaching' ? 'var(--tertiary-color)' : 'var(--secondary-color)')
                                }}>
                                    {btnLoader ? <LoaderIcon/> : "Save & Next"}
                                </Button>
                                <Button 
                                    variant="outlined" 
                                    className={classes.cancelButton} 
                                    onClick={cancelClicked}
                                    sx={{
                                        backgroundColor: '#fff',
                                        color: isWebsiteFor === 'coaching' ? 'var(--tertiary-color)' : 'var(--secondary-color)',
                                        borderColor: isWebsiteFor === 'coaching' ? 'var(--tertiary-color)' : 'var(--secondary-color)',
                                        marginLeft: '16px'
                                    }}>
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </div>
                </section>
            </Box>
        </Box>
    );
}
