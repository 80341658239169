import * as React from 'react';
import { Button } from "@mui/material";
import HomeLayout from "../homeLayout/HomeLayout";
import PurchaseTable from "../../components/BasicTable/BasicTable";
import Pagination from "../../components/Pagination/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import EmptyPurchase from "../../assest/images/empty_purchase.png";
import Spinner from "../../components/Spinner/Spinner";
import { toast } from 'react-toastify';
import { getStudentByToken } from "../../redux/requestForm/requestFormSlice";
  
export default function Purchase() {
    const [limit, setLimit] = React.useState(10);
    const [offset, setOffset] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [userData, setUserData] = React.useState(null);
    const [universiApp, setUniversityApp] = React.useState([]);
    const [isEmpty, setIsEmpty] = React.useState(false);
    const dispatch = useDispatch();
    const colR = ["SNo", "Name of Product", "Name of Coaching/University", "Product type", "Price", "Link", "Invoice"];

    const { totalCount } = useSelector((state) => state.student);
    
    const { 
        isLoading, 
        isError,
        studentByToken,
        message } = useSelector((state) => state.form);

    React.useEffect(() => {
        const res = dispatch(getStudentByToken());
        setUserData(JSON.parse(localStorage.getItem('cc_user')))
        if(localStorage.getItem("isRefreshPage")){
            window.location.reload();
            localStorage.removeItem("isRefreshPage")
        }
        res?.then((r) => {
            (r?.payload?.message === "Session Expired" || 
            r?.payload?.message === "Unauthorized") &&
            toast.error("Your session expired, please login")
        })
    },[])

    const getInvoice = (url) => {
        if(!url) return toast.error("No Invoice Present");
        window.open(url, '_blank', 'noopener,noreferrer')
    }
   
    if (isLoading) return <Spinner />;
    if (isError) return <h1>{message}</h1>;
    return (
      <>
        <HomeLayout />
        <div style={{
            marginTop: '100px'
        }}>
            {(universiApp && universiApp?.length > 0 && isEmpty) && <Pagination
                totalCount={totalCount}
                limit={limit}
                page={page}
                changeLimit={setLimit}
                changeOffset={setOffset}
                changePage={setPage}
            />}
        </div>
        <div className="content-box table-box" style={{
            marginTop: '0px',
            overflow: isEmpty && 'scroll'
            }}>
            <>
            {console.log("hey===",studentByToken?.data?.products)}
                {(studentByToken?.data?.products && 
                    studentByToken?.data?.products?.length > 0) ?
                    <PurchaseTable
                        columns={colR}
                        where="recipes"
                        rows={studentByToken?.data?.products?.map((item) => {
                            const res = {
                            ...item,
                            Invoice: (
                                <Button 
                                    onClick={() => getInvoice(item?.invoice_url)} 
                                    sx={{
                                        color: '#fff',
                                        textTransform: 'capitalize',
                                        fontWeight: 'bold',
                                        background: 'var(--secondary-color)',
                                        '&:hover':{
                                            background: 'var(--secondary-color)',
                                        }
                                    }}>
                                    Invoice
                                </Button>
                            ),
                            };
                            return res;
                        })
                        }
                    />
                    :
                    <div className="empty-purchase">
                        <img src={EmptyPurchase} alt="emptyPurchase" />
                        <p>
                            There is no purchase yet
                        </p>
                    </div>
                }
            </>
        </div>
      </>
    );
  }