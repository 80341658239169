import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import QuizIcon from '@mui/icons-material/Quiz';
import BookIcon from '@mui/icons-material/Book';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import PersonIcon from '@mui/icons-material/Person';
import CoachingIcon from "../../assest/images/coaching.png";
import InventoryIcon from '@mui/icons-material/Inventory';
import HelpIcon from '@mui/icons-material/Help';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import UnivProductGrey from "../../assest/images/university_product_grey.png";
import UnivProductWhite from "../../assest/images/university_product_white.png";
import UnivStudentGrey from "../../assest/images/university_student_grey.png";
import UnivStudentWhite from "../../assest/images/university_student_white.png";
import SchoolIcon from '@mui/icons-material/School';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import { useLocation, useNavigate, Link } from "react-router-dom";
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MenuIcon from '@mui/icons-material/Menu';
import Collapse from "@mui/material/Collapse";
import { Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import PrepClueWhite from "../../assest/images/prepClueWhite.png";
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Notification from '../notification/Notification';
import "./homeLayout.css";
import routes from '../../react_routes_enum';
import PrepClueLogo from "../../assest/svgs/prep-clue-logo.svg";
import { useDispatch, useSelector } from "react-redux";
import { setProductsFilterDetails } from '../../redux/product/productSlice';
import { setSearchTextInForm } from '../../redux/requestForm/requestFormSlice';
import { logout, reset } from "../../redux/auth/authSlice";
import { setSearchText } from "../../redux/instituteStudent/instituteStudentSlice";
import { setTestSearchText } from "../../redux/test/testSlice";

export default function TemporaryDrawer() {
  const [isWebsiteFor, setIsWebsiteFor] = React.useState(localStorage.getItem("is-website-for"))
  const [openCollapsible, setOpenCollapsible] = React.useState(false);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [selectedTab, setSelectedTab] = React.useState("Dashboard");
  const navigate = useNavigate();
  const location = useLocation();

  const selectTab = (tab) => {
    console.log("selectTab",tab)
    setSelectedTab(tab);
    if(isWebsiteFor === "student"){
      tab === "Dashboard" && navigate(routes.STUDENT_DASHBOARD);
      tab === "University" && navigate(routes.STUDENT_UNIVERSITY);
      tab === "Coaching" && navigate(routes.STUDENT_COACHING);
      tab === "Reviews" && navigate(routes.STUDENT_COACHING);
      tab === "Purchase" && navigate(routes.PURCHASES);
      tab === "Help & Support" && navigate(routes.HELP_SUPPORT);
    }else{
      tab === "Dashboard" && navigate(routes.DASHBOARD);
      tab === "Products" && navigate(routes.PRODUCTS);
      tab === "Blogs" && navigate(routes.BLOG);
      tab === "Tests" && navigate(routes.TESTS);
      tab === "Questions" && navigate(routes.QUESTIONS);
      tab === "Recorded Course" && navigate(routes.COURSE);
      tab === "Content" && navigate(routes.CONTENT);
      tab === "Lead Generation System" && navigate(routes.LGS);
      if(tab === "Students"){ 
        setOpenCollapsible(!openCollapsible);
        navigate(routes.STUDENT_PENDING);
      }
    }
  }
  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleSubmenu = () => {
    setOpenCollapsible(true);
  }

  const list = (anchor) => (
    <Box
      sx={{ 
        width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250,
       }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      {isWebsiteFor !== "student" &&
      <List>
        {(isWebsiteFor === "coaching" || isWebsiteFor === "university") ?
        ['Products', 'Students', 'Blogs', 'Tests', 'Questions', 'Recorded Course', 'Content'].map((text, index) => (
          
          <ListItem key={text} disablePadding sx={
            ((location?.pathname?.split("/")[1] === text.toLocaleLowerCase()) ||
            (location?.pathname?.split("/")[1] === 'test-question' & index === 0) ||
            (location?.pathname?.split("/")[1] === 'course' & index === 5) ||
            (location?.pathname?.split("/")[1] === 'online-recorded-course' & 
            index === 5) ||
            (location?.pathname?.split("/")[1] === 'add-content' & 
            index === 6)
            ) ? 
            {backgroundImage: isWebsiteFor === "university" ? 
            'linear-gradient(#672F8F 40%, #BA68C8 100%, #D9D9D9 0%)' :
            'linear-gradient(#EF4840 100%, #A60800 100%, #D9D9D9 0%)'
          } : 
            {backgroundColor: 'white'}}>
            <ListItemButton onClick={() => selectTab(text)}>
              <ListItemIcon sx={{color: (
                (location?.pathname?.split("/")[1] === text.toLocaleLowerCase()) ||
                (location?.pathname?.split("/")[1] === 'test-question' & index === 0) ||
                (location?.pathname?.split("/")[1] === 'course' & 
                  index === 5) ||
                (location?.pathname?.split("/")[1] === 'online-recorded-course' & 
                  index === 5) ||
                (location?.pathname?.split("/")[1] === 'add-content' & 
                  index === 6)
                ) ? 'white' :'rgb(0, 0, 0, 0.4)'}}>
                {text === "Products" && 
                  <img src={
                    location?.pathname?.split("/")[1] === text.toLocaleLowerCase() ? 
                    UnivProductWhite : UnivProductGrey} alt="productIcon" style={{
                    width: '24px'
                  }} />}
                {text === "Students" && 
                  <img src={location?.pathname?.split("/")[1] === text.toLocaleLowerCase() ? 
                  UnivStudentWhite : UnivStudentGrey} alt="productIcon" style={{
                    width: '24px'
                  }} />}
                {text === "Blogs" && <BookIcon />}
                {text === "Tests" && <QuizIcon />}
                {text === "Questions" && <QuestionMarkIcon />}
                {text === "Recorded Course" && <LocalLibraryIcon />}
                {text === "Content" && <PictureAsPdfIcon />}
              </ListItemIcon>
              <ListItemText primary={text} 
              sx={{color: (
                (location?.pathname?.split("/")[1] === text.toLocaleLowerCase()) ||
                (location?.pathname?.split("/")[1] === 'test-question' & index === 0) ||
                (location?.pathname?.split("/")[1] === 'course' & 
                  index === 5) ||
                (location?.pathname?.split("/")[1] === 'online-recorded-course' & 
                  index === 5) ||
                (location?.pathname?.split("/")[1] === 'add-content' & 
                  index === 6)
                ) ?
               'white' :
                'rgb(0, 0, 0, 0.4)'}} />
            </ListItemButton>
          </ListItem>
        ))
        :
        ['Dashboard', 'Lead Generation System'].map((text, index) => (
          <ListItem key={text} disablePadding sx={
            ((location?.pathname?.split("/")[1] === text.toLocaleLowerCase()) ||
            (location?.pathname?.split("/")[1] === 'lead-generation-system' & 
                    index === 1)
            ) ? 
            {backgroundImage: 'linear-gradient(#672F8F 40%, #BA68C8 100%, #D9D9D9 0%)'
          } : 
            {backgroundColor: 'white'}}>
            <ListItemButton onClick={() => selectTab(text)}>
              <ListItemIcon sx={{color: (
                (location?.pathname?.split("/")[1] === text.toLocaleLowerCase()) ||
                (location?.pathname?.split("/")[1] === 'lead-generation-system' & 
                    index === 1)
                ) ? 'white' :'rgb(0, 0, 0, 0.4)'}}>
                {text === "Dashboard" && <InboxIcon />}
                {text === "Lead Generation System" && <LeaderboardIcon />}
              </ListItemIcon>
              <ListItemText primary={text} 
              sx={{color: (
                (location?.pathname?.split("/")[1] === text.toLocaleLowerCase()) ||
                (location?.pathname?.split("/")[1] === 'lead-generation-system' & 
                    index === 1)
                ) ?
               'white' :
                'rgb(0, 0, 0, 0.4)'}} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>}
      {isWebsiteFor === "student" &&
      <List>
        {['Dashboard', 'University', 'Coaching', 'Purchase', 'Help & Support'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton onClick={() => selectTab(text)}>
              <ListItemIcon sx={{color: 'white'}}>
                {text === "Dashboard" && <InboxIcon />}
                {text === "University" && <SchoolIcon />}
                {text === "Coaching" && <img src={CoachingIcon} style={{width: '24px'}}
                    alt="coachingIcon" />}
                {text === "Purchase" && <InventoryIcon />}
                {text === "Help & Support" && <HelpIcon />}
              </ListItemIcon>
              <ListItemText primary={text} 
              sx={{
                border: (
                  (location?.pathname?.split("/")[1] === text.toLocaleLowerCase()) ||
                  ((location?.pathname?.split("/")[1] === "help-&-support") &&
                  (text === "Help & Support"))
                  ) ? '1px solid white' : 'none',
                color: 'white',
                borderRadius: '4px',
                maxWidth: '82px',
                padding: '4px'}}
                />
            </ListItemButton>
          </ListItem>
        ))}
      </List>}
    </Box>
  );

  const goToHome = () => {
    isWebsiteFor === "student" && navigate(routes.LANDING_PAGE);
  }

  return (
    <>
      <div className="mobile-menu">
        <div style={{
          position: 'fixed', 
          backgroundColor: 'white',
          width: '100%',
          zIndex: 1,
          top: 0}}>
          {['left'].map((anchor) => (
            <React.Fragment key={anchor}>
              <div style={{
                display: 'flex',
              }}>
                <Button onClick={toggleDrawer(anchor, true)}>
                  {/* {anchor} */}
                  <MenuIcon />
                </Button>
                <TopRightHeader isInMobile={true} />
              </div>
              <Drawer
                className={isWebsiteFor === 'student' ? 'mobile-drawer-color' : 
                  'mobile-drawer-color-1'}
                anchor={anchor}
                open={state[anchor]}
                onClose={toggleDrawer(anchor, false)}
              >
                <div style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '16px',
                }}>
                  <div style={{
                    width: '60px',
                    marginLeft: '8px'}}
                    onClick={goToHome}>
                    <img 
                      src={isWebsiteFor === 'student' ? PrepClueWhite : PrepClueLogo} 
                      alt="logo" 
                      style={{width: '100%'}} />
                  </div>
                  <Typography component="h4" variant="h4" 
                    sx={{
                      fontSize: '14px', 
                      fontWeight: isWebsiteFor === "student" ? 400 : 'bold',
                      color: isWebsiteFor === "student" ? '#fff' : '#000'
                    }}>
                    Menu
                  </Typography>
                  <div>
                    <MenuIcon sx={{
                      color: isWebsiteFor === "student" ? '#fff' : '#000'
                    }} />
                  </div>
                </div>
                {list(anchor)}
              </Drawer>
            </React.Fragment>
          ))}
        </div>
      </div>
      <Box className="laptop-menu">
        <div className="laptop-menu-side-menu" style={{
          backgroundColor: isWebsiteFor === "student" ? "var(--primary-color)" :'white',
          height: '80vh',
        }}>
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            margin: '24px 0 12px 0',
            cursor: 'pointer'
            }} onClick={goToHome}>
            <img 
              src={isWebsiteFor === 'student' ? PrepClueWhite : PrepClueLogo} 
              alt="logo" 
              style={{width: '100px'}} />
          </div>
          {isWebsiteFor === "student" ?
          <List>
            {['Dashboard', 'University', 'Coaching', 'Purchase', 'Help & Support'].map((text, index) => (
              <ListItem key={text} disablePadding >
                <ListItemButton onClick={() => selectTab(text)}>
                  <ListItemIcon sx={{color: 'white'}}>
                    {text === "Dashboard" && <InboxIcon />}
                    {text === "University" && <SchoolIcon />}
                    {text === "Coaching" && <img 
                      src={CoachingIcon} 
                      style={{width: '24px'}}
                      alt="coachingIcon" />}
                    {text === "Purchase" && <InventoryIcon />}
                    {text === "Help & Support" && <HelpIcon />}
                  </ListItemIcon>
                  <ListItemText primary={text} sx={{
                    border: (((location?.pathname?.split("/")[1] === "help-&-support") &&
                            (text === "Help & Support")) ||
                            (location?.pathname?.split("/")[1] === text.toLocaleLowerCase()) ||
                            ((location?.pathname?.split("/")[1] === "profile") &&
                            text === "Dashboard")) ?
                            '1px solid white' : 'none',
                    color: 'white',
                    borderRadius: '4px',
                    maxWidth: '82px',
                    padding: '4px'}} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          :
          <List>
            {(isWebsiteFor === "coaching" || isWebsiteFor === "university") ?
            ['Products', 'Students', 'Blogs', 'Tests', 'Questions', 'Recorded Course', 'Content'].map((text, index) => (
              <>
              <ListItem key={text} disablePadding sx={
                ((location?.pathname?.split("/")[1] === text.toLocaleLowerCase()) ||
                (location?.pathname?.split("/")[1] === 'add-new-test' & index === 3) ||
                (location?.pathname?.split("/")[1] === 'test' & index === 3) ||
                (location?.pathname?.split("/")[1] === 'question' & index === 4) ||
                (location?.pathname?.split("/")[1] === 'add-questions' & index === 4) ||
                (location?.pathname?.split("/")[1] === 'course' & 
                  index === 5) ||
                (location?.pathname?.split("/")[1] === 'online-recorded-course' & 
                  index === 5) ||
                (location?.pathname?.split("/")[1] === 'add-content' & index === 6) ||
                (location?.pathname?.split("/")[1] === 'profile' & index === 0)) ? 
                {backgroundImage: 
                  (isWebsiteFor === "university" || isWebsiteFor === "partner") ?
                  'linear-gradient(#672F8F 40%, #BA68C8 100%, #D9D9D9 0%)' :
                  'linear-gradient(#EF4840 100%, #A60800 100%, #D9D9D9 0%)'
                } : 
                {backgroundColor: 'white'}}>
                <ListItemButton onClick={() => selectTab(text)}>
                  <ListItemIcon sx={{color: (
                    (location?.pathname?.split("/")[1] === text.toLocaleLowerCase()) ||
                    (location?.pathname?.split("/")[1] === 'profile' & index === 0) ||
                    (location?.pathname?.split("/")[1] === 'test' & index === 4) ||
                    (location?.pathname?.split("/")[1] === 'add-new-test' & index === 4) ||
                    (location?.pathname?.split("/")[1] === 'add-questions' & index === 5) ||
                    (location?.pathname?.split("/")[1] === 'question' & index === 5) ||
                    (location?.pathname?.split("/")[1] === 'course' & index === 5) ||
                    (location?.pathname?.split("/")[1] === 'add-content' & index === 6) ||
                    (location?.pathname?.split("/")[1] === 'online-recorded-course' & 
                      index === 5)
                    ) ? 'white' :
                    'rgb(0, 0, 0, 0.4)'}}>
                    {text === "Dashboard" && <InboxIcon />}
                    {text === "Products" && 
                      <img src={
                        location?.pathname?.split("/")[1] === text.toLocaleLowerCase() ? 
                        UnivProductWhite : UnivProductGrey} alt="productIcon" style={{
                        width: '24px'
                      }} />}
                    {text === "Students" && 
                      <img src={
                        location?.pathname?.split("/")[1] === text.toLocaleLowerCase() ? 
                        UnivStudentWhite : UnivStudentGrey} alt="productIcon" style={{
                        width: '24px'
                      }} />}
                    {text === "Blogs" && <BookIcon />}
                    {text === "Tests" && <QuizIcon />}
                    {text === "Questions" && <QuestionMarkIcon />}
                    {text === "Recorded Course" && <LocalLibraryIcon />}
                    {text === "Content" && <PictureAsPdfIcon />}
                  </ListItemIcon>
                  <ListItemText primary={text} sx={{color: (
                    (location?.pathname?.split("/")[1] === text.toLocaleLowerCase()) ||
                    (location?.pathname?.split("/")[1] === 'profile' & index === 0) ||
                    (location?.pathname?.split("/")[1] === 'test' & index === 4) ||
                    (location?.pathname?.split("/")[1] === 'add-new-test' & index === 4) ||
                    (location?.pathname?.split("/")[1] === 'test-question' & index === 0) ||
                    (location?.pathname?.split("/")[1] === 'question' & index === 5) ||
                    (location?.pathname?.split("/")[1] === 'add-questions' & index === 5) ||
                    (location?.pathname?.split("/")[1] === 'course' & index === 5) ||
                    (location?.pathname?.split("/")[1] === 'add-content' & index === 6) ||
                    (location?.pathname?.split("/")[1] === 'online-recorded-course' & 
                      index === 5)
                    ) ? 'white' :
                    'rgb(0, 0, 0, 0.4)'}} />
                </ListItemButton>

              </ListItem>
              {text === "Students" &&
                <Collapse in={location?.pathname?.split("/")[1] === "students" } timeout="auto">
                  <List
                    component="div"
                    disablePadding>
                    {[
                      { submenu: "Pending", link: routes.STUDENT_PENDING },
                      { submenu: "Document", link: routes.STUDENT_DOCUMENT },
                      { submenu: "Approved", link: routes.APPROVED_STUDENT },
                      { submenu: "Rejected", link: routes.REJECTED_STUDENT },
                      { submenu: "Enrolled", link: routes.STUDENT_ENROLLED },
                    ].map((item, i) => (
                      <Link to={item.link} key={item.submenu}>
                        <ListItemButton
                          onClick={handleSubmenu}
                          sx={{ pl: 6, color: "rgb(0, 0, 0, 0.5)"}}
                        >
                          <ListItemText primary={item.submenu} 
                            className={`student-sub-menu ${(location?.pathname?.split("/")[2] === item.submenu.toLocaleLowerCase()) && 
                            `${isWebsiteFor === "university" ? ' active-sub-menu' : ' active-sub-menu-coaching'}`}`}
                            />
                        </ListItemButton>
                      </Link>
                    ))}
                  </List>
                </Collapse>
                }
              </>
            ))
            :
            ['Dashboard', 'Lead Generation System'].map((text, index) => (
              <>
              <ListItem key={text} disablePadding sx={
                ((location?.pathname?.split("/")[1] === text.toLocaleLowerCase()) ||
                (location?.pathname?.split("/")[1] === 'lead-generation-system' & index === 1) ||
                (location?.pathname?.split("/")[1] === 'create-new-lead' & index === 1) ||
                (location?.pathname?.split("/")[1] === 'view-lead' & index === 1)) ? 
                {backgroundImage: 
                  isWebsiteFor === "partner" ?
                  'linear-gradient(#672F8F 40%, #BA68C8 100%, #D9D9D9 0%)' :
                  'linear-gradient(#EF4840 100%, #A60800 100%, #D9D9D9 0%)'
                } : 
                {backgroundColor: 'white'}}>
                <ListItemButton onClick={() => selectTab(text)}>
                  <ListItemIcon sx={{color: (
                    (location?.pathname?.split("/")[1] === text.toLocaleLowerCase()) ||
                    (location?.pathname?.split("/")[1] === 'lead-generation-system' & index === 1) ||
                    (location?.pathname?.split("/")[1] === 'create-new-lead' & index === 1) ||
                    (location?.pathname?.split("/")[1] === 'view-lead' & index === 1)) ? 
                    'white' :
                    'rgb(0, 0, 0, 0.4)'}}>
                    {text === "Dashboard" && <InboxIcon />}
                    {text === "Lead Generation System" && <LeaderboardIcon />}
                  </ListItemIcon>
                  <ListItemText primary={text} sx={{color: (
                    (location?.pathname?.split("/")[1] === text.toLocaleLowerCase()) ||
                    (location?.pathname?.split("/")[1] === 'lead-generation-system' & index === 1) ||
                    (location?.pathname?.split("/")[1] === 'create-new-lead' & index === 1) ||
                    (location?.pathname?.split("/")[1] === 'view-lead' & index === 1)) ? 
                    'white' :
                    'rgb(0, 0, 0, 0.4)'}} />
                </ListItemButton>
              </ListItem>
              </>
            ))
            }

          </List>}
        </div>
        <div className="laptop-right-content">
          <TopRightHeader isInMobile={false} />
        </div>
      </Box>
    </>
  );
}

const TopRightHeader = ({isInMobile}) => {
  const isWebsiteFor = localStorage.getItem("is-website-for");
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [inputText, setInputText] = React.useState();
  const [userData, setUserData] = React.useState(null);
  const [notificationOpen, setNotificationOpen] = React.useState(false);
  const { actionType } = useSelector(state => state.product);

  const handleLogout = () => {
    dispatch(logout());
    dispatch(reset());
    navigate('/login')
  };
  React.useEffect(() => {
    setUserData(JSON.parse(localStorage.getItem('cc_user')));
    if(localStorage.getItem("isRefresh")){
      window.location.reload();
      localStorage.removeItem("isRefresh");
    }
  },[])

  const goToProfile = () => {
    navigate(routes.PROFILE);
  }

  return (
    <div className="right-menu-top-header" 
      style={isInMobile ? { 
          width: '100%', 
          padding: '28px 16px 24px 0px'} : {
          padding: '28px 16px 24px 16px'
          }}>
      <Typography component="h3" variant="h3" sx={{
          fontWeight: 'bold',
          color: (isWebsiteFor === "university" || isWebsiteFor === "partner") ? 'var(--primary-color)' : 'var(--tertiary-color)',
      }} className="page-top-heading">
        {isWebsiteFor === "student" ?
        <>
          <span style={{
            color: '#000'
          }} className="lap-wel">Welcome </span><span style={{
            color: '#F69220',
            textTransform: 'capitalize'
          }} className="lap-wel">{userData?.student?.name}</span>
          <span className="for-mob">{location?.pathname?.split("/")[1]}</span>
        </>
        :
        <>
          {((location?.pathname?.split("/")[1] === "online-recorded-course") ||
          (location?.pathname?.split("/")[1] === "test-question") ||
          (location?.pathname?.split("/")[1] === "add-questions") ||
          (location?.pathname?.split("/")[1] === 'lead-generation-system') ||
          (location?.pathname?.split("/")[1] === 'create-new-lead') ||
          (location?.pathname?.split("/")[1] === 'view-lead') ||
          (location?.pathname?.split("/")[1] === "add-new-test") ||
          (location?.pathname?.split("/")[1] === "add-content")) ?
          <>
            {location?.pathname?.split("/")[1] === "online-recorded-course" && 
              'Online Recorded Course'}
            {location?.pathname?.split("/")[1] === "test-question" && 'Test Question'}
            {location?.pathname?.split("/")[1] === "add-questions" && 'Add Questions'}
            {location?.pathname?.split("/")[1] === "add-new-test" && 'Add New Test'}
            {location?.pathname?.split("/")[1] === 'view-lead' && 'View Lead'}
            {location?.pathname?.split("/")[1] === 'lead-generation-system' && 
              'Lead Generation System'}
            {location?.pathname?.split("/")[1] === 'create-new-lead' && 
              'Create New Lead'}
            {location?.pathname?.split("/")[1] === "add-content" && 
            ((actionType === 'View' || 
              actionType === 'Edit') ? (actionType === 'View' ? 'View Content' : 'Edit Content') : 
              'Add Content')}
          </> :
          (location?.pathname?.split("/")[1])[0].toLocaleUpperCase()+
            location?.pathname?.split("/")[1].substring(1, location?.pathname?.split("/")[1].length)}
          </>}

      </Typography>
      {!isInMobile && <div className="search-aria">
          <TextField placeholder="Search" onChange={(e) => {
            setInputText(e.target.value)}} 
            variant="outlined" 
          sx={{
            width: '100%',
            height: '100%',
            borderRadius: '16px',
          }} />
          <Button variant="contained" sx={{
            position: 'absolute',
            right: '0',
            height: '100%',
            borderRadius: '16px',
            backgroundColor: (isWebsiteFor === "university" || 
              isWebsiteFor === "student" || isWebsiteFor === "partner") ? 
              'var(--primary-color)' : 
              'var(--tertiary-color)',
            '&:hover': {
                backgroundColor: (isWebsiteFor === "university" || 
                isWebsiteFor === "student" ||
                isWebsiteFor === "partner") ? 
                'var(--primary-color)' : 
                'var(--tertiary-color)',
            }
          }} onClick={() => {
              location?.pathname?.split("/")[1] === "products" && dispatch(setProductsFilterDetails(inputText))
              location?.pathname?.split("/")[1] === "blogs" && dispatch(setSearchText(inputText))
              location?.pathname?.split("/")[1] === "lead-generation-system" && dispatch(setSearchTextInForm(inputText))
              location?.pathname?.split("/")[1] === "tests" && dispatch(setTestSearchText(inputText))
              location?.pathname?.split("/")[1] === "questions" && dispatch(setTestSearchText(inputText))
            }}>
          <SearchIcon />
          </Button>
      </div>}
      {!isInMobile && 
      <div className="user-aria">
          <div className="notification-icon" 
          style={{
              position: 'relative',
              cursor: 'pointer',
          }} onClick={() => setNotificationOpen(!notificationOpen)}>
              <NotificationsNoneIcon fontSize="large" sx={{
              color: 'rgb(0, 0, 0, 0.4)'
              }} />
              <div style={{
                backgroundColor: '#EF0000',
                height: '12px',
                width: '12px',
                borderRadius: '50%',
                position: 'absolute',
                top: '4px',
                right: '2px'
              }}>
              </div>
          </div>
          <div style={{
            width: '48px',
            height: '48px',
            borderRadius: '50%',
            backgroundColor: 'grey',
            margin: '0 12px',
            display: 'flex', 
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            border: '1px solid rgb(0, 0, 0, 0.1)'
          }} onClick={goToProfile}>
            {(userData?.university || userData?.coaching) && <img src={
              userData?.university?.uploads?.university_logo ||
              userData?.coaching?.uploads?.coaching_logo
            } alt="logo" style={{
              width: '100%',
              height: '100%',
              borderRadius: '50%'
            }} />}

            {userData?.student && (userData?.student?.profile_picture ? 
              <img src={userData?.student?.profile_picture} alt="logo" style={{
                width: '100%',
                height: '100%',
                borderRadius: '50%'
              }} /> : <PersonIcon fontSize='large' sx={{color: 'white'}} />)}
          </div>
          <div style={{
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
          }} className="user-detail">
          <Typography component="p" variant="p" sx={{fontWeight: 'bold'}} className="user-name">
            {userData?.university?.university_name &&
            (userData?.university?.university_name?.length > 6 ? 
            `${userData?.university?.university_name?.substr(0, 6)}...` : 
            `${userData?.university?.university_name?.substr(0, 6)}`)}

            {userData?.coaching?.coaching_name && 
              (userData?.coaching?.coaching_name?.length > 6 ? 
              `${userData?.coaching?.coaching_name?.substr(0, 6)}...` : 
              `${userData?.coaching?.coaching_name?.substr(0, 6)}`)}

            {userData?.student?.name && 
              (userData?.student?.name?.length > 6 ? 
              `${userData?.student?.name?.substr(0, 6)}...` : 
              `${userData?.student?.name?.substr(0, 6)}`)}
          </Typography>
          <div>
              <KeyboardArrowDownIcon sx={{color: 'rgb(0, 0, 0, 0.4)'}} />
          </div>
          <div className="user-detail-options" style={{
            right: 0
          }}>
            <Button type="button" onClick={handleLogout}>
              Logout
            </Button>
          </div>
          </div>
      </div>}
      {isInMobile && <div className="user-aria">
          <div className="notification-icon" 
          style={{
              position: 'relative',
          }} onClick={() => setNotificationOpen(!notificationOpen)}>
              <NotificationsNoneIcon fontSize="medium" sx={{
              color: 'rgb(0, 0, 0, 0.4)'
              }} />
              <div style={{
                backgroundColor: '#EF0000',
                height: '8px',
                width: '8px',
                borderRadius: '50%',
                position: 'absolute',
                top: '4px',
                right: '2px'
              }}>
              </div>
          </div>
          <div style={{
            width: '36px',
            height: '36px',
            borderRadius: '50%',
            backgroundColor: 'grey',
            margin: '0 12px',
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }} className="user-detail">
          {isWebsiteFor !== "student" ? <img src={
            userData?.university?.uploads?.university_logo ||
            userData?.coaching?.uploads?.coaching_logo
          } alt="logo" style={{
            width: '100%',
          }} />:
          <PersonIcon />}
          <div className="user-detail-options">
              Logout
          </div>
          </div>
      </div>}
      <Notification 
        open={notificationOpen} 
        handleClick={() => setNotificationOpen(!notificationOpen)} />
  </div>
  )
}