import * as React from 'react';
import HomeLayout from "../homeLayout/HomeLayout";
import routes from '../../react_routes_enum';
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";

export default function CourseCompleted() {
    const navigate = useNavigate();
    
    return (
      <>
        <HomeLayout />
        <div className="content-box table-box" style={{
            overflow: 'hidden',
        }}>
            <div className="congrats-box">
                <h2>Congratulations !</h2>
                <p>
                    You have completed the course. Go to the <br /> 
                    Achievements to have access to your <br /> 
                    certifications.
                </p>
                <Button 
                    className="completed-btn-continue" 
                    onClick={() => {navigate(routes.PURCHASES)}}>
                    Continue
                </Button>
            </div>
        </div>
      </>
    );
  }