import * as React from 'react';
import { useNavigate } from "react-router-dom";
import VisibilityIcon from '@mui/icons-material/Visibility';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from '@mui/material/DialogTitle';
import AppRegistrationOutlinedIcon from '@mui/icons-material/AppRegistrationOutlined';
import { 
  Typography,
  Button,
  IconButton,
  InputLabel,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import HomeLayout from "../homeLayout/HomeLayout";
import routes from '../../react_routes_enum';
import StudentProfileTable from "../../components/BasicTable/BasicTable";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../components/Spinner/Spinner";
import Pagination from "../../components/Pagination/Pagination";
import { toast } from 'react-toastify';
import { getInstituteApplication, 
  updateInstituteApplication,
  setInstituteId } from '../../redux/instituteStudent/instituteStudentSlice';

let acceptBody = {
  status: "target_accepted",
  fee_payment: {
      fees: "",
      discount: "",
      is_percentage_discount: 0,
      gst_percent: "",
      total_amount: 0,
      reason_for_discount: ""
  }
}

let requestBody = {
  status: "target_changes_requested",
  changes: []
}

export default function DocumentTable() {
  const isWebsiteFor = localStorage.getItem("is-website-for");
  const colR = ["SNo", "Name of Student", "DOB","Status","Action", ];
  const [open, setOpen] = React.useState(false);
  const [openAccept, setOpenAccept] = React.useState(false); 
  const [openRequest, setOpenRequest] =React.useState(false);
  const [limit, setLimit] = React.useState(10);
  const [offset, setOffset] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [acceptData, setAcceptData] = React.useState(acceptBody);
  const [requestChange, setRequestChange] = React.useState(requestBody);
  const [instId, setInstId] = React.useState("");
  const [status, setStatus] = React.useState("");

  const handleClose = () => {
    setOpen(false);
  };  
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { 
    isLoading, 
    isError, 
    totalCount, 
    message, 
    searchText,
    applicationData,  
  }= useSelector((state) => state.instituteStudent);

  React.useEffect(() => {
    getInstApp()
  }, [limit, offset, searchText])

  const getInstApp = () => {
    dispatch(getInstituteApplication({
      limit, 
      offset, 
      searchText, 
      status: ['target_upload_requested','target_uploaded','target_changes_requested'], 
      isWebsiteFor }))
  }

  const handleClickOpenView = (id) => {
    dispatch(setInstituteId(id))
    navigate(routes?.REGISTER_TO_APPLY);
  };

  const requestSubmit = () => {
    if(requestChange?.changes?.length === 0){
      toast.error("Mention the changes you want");
      return;
    }
    if(status === "target_upload_requested"){
      toast.error("Document Not Uploaded Yet");
      return;
    }
    if(status === "target_changes_requested"){
      toast.error("Document Not Changed Yet");
      return;
    }
    const res = dispatch(updateInstituteApplication({id: instId, body: requestChange, isWebsiteFor}));
    res.then((r) => {
      if(r?.payload?.statusCode === 200){
        setOpen(false);
        getInstApp();
        toast.success("Change request sent successfully")
      }else{
        toast.error("Error while updating document")
      }
    })
  }

  const calculateTotal = (e, from) => {
    if(from === "fees"){
      let total = 0;
      let discount = 0;
      let gst = 0;
      if(acceptData?.fee_payment?.discount > 0){
        discount = acceptData?.fee_payment?.is_percentage_discount ?
        parseInt((e.target.value/100)*acceptData?.fee_payment?.discount) : 
          acceptData?.fee_payment?.discount
      }
      if(acceptData?.fee_payment?.gst_percent > 0){
        gst = parseInt(((e.target.value - discount)/100)*acceptData?.fee_payment?.gst_percent)
      }
      total = e.target.value - discount + gst;
      setAcceptData({
        ...acceptData, 
        fee_payment: {
          ...acceptData?.fee_payment, 
          fees: Number(e.target.value),
          total_amount: total
        }
      })
    }
    if(from === "discount"){
      let total = 0;
      let discount = 0;
      let gst = 0;
      if(e.target.value > 0){
        discount = acceptData?.fee_payment?.is_percentage_discount ?
          parseInt((acceptData?.fee_payment?.fees/100)*e.target.value) : 
            e.target.value
      }
      if(acceptData?.fee_payment?.gst_percent > 0){
        gst = parseInt(((acceptData?.fee_payment?.fees - discount)/100)*acceptData?.fee_payment?.gst_percent)
      }
      total = acceptData?.fee_payment?.fees - discount + gst;
      setAcceptData({
        ...acceptData, 
        fee_payment: {
          ...acceptData?.fee_payment, 
          discount: Number(e.target.value),
          total_amount: total
        }
      })
    }
    if(from === "gst"){
      let total = 0;
      let discount = 0;
      let gst = 0;
      if(acceptData?.fee_payment?.discount > 0){
        discount = acceptData?.fee_payment?.is_percentage_discount ?
          parseInt((acceptData?.fee_payment?.fees/100)*acceptData?.fee_payment?.discount) : 
            acceptData?.fee_payment?.discount
      }
      if(e.target.value > 0){
        gst = parseInt(((acceptData?.fee_payment?.fees - discount)/100)*e.target.value)
      }
      total = acceptData?.fee_payment?.fees - discount + gst;
      setAcceptData({
        ...acceptData, 
        fee_payment: {
          ...acceptData?.fee_payment, 
          gst_percent: Number(e.target.value),
          total_amount: total
        }
      })
    }
    if(from === "discount_type"){
      let total = 0;
      let discount = 0;
      let gst = 0;
      if(acceptData?.fee_payment?.discount > 0){
        discount = e.target.value === "percentage" ?
          parseInt((acceptData?.fee_payment?.fees/100)*acceptData?.fee_payment?.discount) : 
            acceptData?.fee_payment?.discount
      }
      if(acceptData?.fee_payment?.gst_percent > 0){
        gst = parseInt(((acceptData?.fee_payment?.fees - discount)/100)*acceptData?.fee_payment?.gst_percent)
      }
      total = acceptData?.fee_payment?.fees - discount + gst;
      setAcceptData({
        ...acceptData, 
        fee_payment: {
          ...acceptData?.fee_payment, 
          is_percentage_discount: e.target.value === "percentage" ? 1 : 0,
          total_amount: total
        }
      })
    }
  }

  const submitAccept = () => {
    if(acceptData?.fee_payment?.fees === ""){
      toast.error("Fees required");
      return;
    }
    if(acceptData?.fee_payment?.discount === ""){
      toast.error("Discount required");
      return;
    }
    if(acceptData?.fee_payment?.gst_percent === ""){
      toast.error("GST required");
      return;
    }
    if(acceptData?.fee_payment?.total_amount === ""){
      toast.error("Total amount required");
      return;
    }
    if(acceptData?.fee_payment?.reason_for_discount === ""){
      toast.error("Reason required");
      return;
    }
    if(status === "target_upload_requested"){
      toast.error("Document Not Uploaded Yet");
      return;
    }
    if(status === "target_changes_requested"){
      toast.error("Document Not Changed Yet");
      return;
    }
    const res = dispatch(updateInstituteApplication({id: instId, body: acceptData, isWebsiteFor}));
    res.then((r) => {
      if(r?.payload?.statusCode === 200){
        setOpen(false);
        getInstApp();
        toast.success("Document Update Successfully");
      }else{
        toast.error("Error while updating document");
      }
    })
  }

  const handleInputChange = (e) => {
    setRequestChange({...requestChange, changes: e.target.value.split("\n")})
  }

  if (isLoading) return <Spinner />;

  return (
    <>
      <HomeLayout />
      <Dialog maxWidth="sm" open={open} onClose={handleClose} fullWidth>
          {openAccept && <div>
            <DialogTitle 
              sx={{color: isWebsiteFor === "university" ? 'var(--primary-color)' : 'var(--tertiary-color)',
              fontWeight:'600'}}
              >Fees Payment <span style={{fontSize:'14px', color:'rgb(0, 0, 0, 0.5)', fontWeight: '500'}}>(mention the fees with discount)</span>
            </DialogTitle>
            <DialogContent>
              <div className="fees-info-block">
                <div style={{display:'flex', alignItems: 'center'}}>
                  <div>
                    <InputLabel className="label-for-input">Fees</InputLabel>
                    <TextField
                      placeholder="" 
                      type="number" 
                      variant="outlined" 
                      name="fees" 
                      className="discount"
                      // value={acceptData?.fee_payment?.fees}
                      onChange={(e) => {calculateTotal(e, 'fees')}}
                      required 
                    />
                  </div>
                  <div>
                    <InputLabel className="label-for-input">Discount</InputLabel>
                    <TextField
                      placeholder="" 
                      type="number" 
                      variant="outlined" 
                      name="discount"
                      value={acceptData?.fee_payment?.discount > 0 && 
                        acceptData?.fee_payment?.discount}
                      onChange={(e) => {calculateTotal(e, 'discount')}}
                      className="discount"
                      required 
                    />
                  </div>
                  <div style={{marginLeft:'10px'}}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="percentage"  
                      defaultValue={acceptData?.fee_payment?.is_percentage_discount ? 'percentage' : 'flat'}            
                      onChange={(e) => {calculateTotal(e, "discount_type")}}       
                    >                
                      <FormControlLabel 
                        value="percentage" 
                        control={<Radio sx={{
                        color: 'var(--secondary-color)',
                        '&.Mui-checked': {
                        color: 'var(--secondary-color)',
                        },
                      }} />} 
                        label="Percentage Discount" />
                      <FormControlLabel 
                        value="flat" 
                        control={<Radio sx={{
                        color: 'var(--secondary-color)',
                        '&.Mui-checked': {
                        color: 'var(--secondary-color)',
                        },
                      }} />} 
                        label="Flat Discount" />
                    </RadioGroup>                                        
                  </div>
                </div>
                <div style={{display:'flex',marginTop:"-5px"}}>
                  <div>
                    <InputLabel className="label-for-input">GST %</InputLabel>
                    <TextField
                      placeholder="" 
                      type="number" 
                      variant="outlined" 
                      name="gst" 
                      value={acceptData?.fee_payment?.gst_percent > 0 && 
                        acceptData?.fee_payment?.gst_percent}
                      onChange={(e) => {calculateTotal(e, 'gst')}}
                      className="discount"
                      required 
                    />
                  </div>
                  <div>
                    <InputLabel className="label-for-input">Total Amount</InputLabel>
                    <TextField
                      placeholder="" 
                      type="number" 
                      variant="outlined" 
                      name="total_amout"
                      value={acceptData?.fee_payment?.total_amount}
                      onChange={(e) => {setAcceptData({
                        ...acceptData, fee_payment: {
                          ...acceptData?.fee_payment, total_amount: Number(e.target.value)}})}}
                      className="discount"
                      required 
                    />
                  </div>
                </div>
                <div style={{marginTop:'10px',}}>
                  <InputLabel className="label-for-input">Reason for giving discount</InputLabel>
                  <TextField
                    rows={3}
                    multiline
                    type="text" 
                    variant="outlined" 
                    name="reason" 
                    // value={acceptData?.fee_payment?.reason_for_discount}
                    onChange={(e) => {setAcceptData({
                      ...acceptData, fee_payment: {
                        ...acceptData?.fee_payment, reason_for_discount: e.target.value}})}}
                    className="reason-for-discount"
                    required 
                  />
                </div>
              </div>
              <DialogActions>
                <Button variant="contained"
                  sx={{
                    backgroundColor: isWebsiteFor === "university" ? 'var(--primary-color)' : 'var(--tertiary-color)',
                    width: '84px',
                    marginRight: '12px',
                    textTransform: 'capitalize',
                    "&:hover": {
                      backgroundColor: 'var(--primary-color)',
                    },
                  }}
                  onClick={submitAccept}>Submit</Button>
              </DialogActions>
            </DialogContent>
          </div>}
          {openRequest &&
            <>
              <DialogTitle
                sx={{color: isWebsiteFor === "university" ? 'var(--primary-color)' : 'var(--tertiary-color)',
                fontWeight:'600'}}>
                Request Changes <span style={{
                  fontSize:'14px', 
                  color:'rgb(0, 0, 0, 0.5)', 
                  fontWeight: '400'}}>(mention the changes you want in the document)</span>
              </DialogTitle>
              <DialogContent>
                <div style={{
                  position: 'relative'
                }}>
                  <TextField fullWidth 
                    placeholder="message" 
                    variant="outlined"
                    multiline
                    onChange={handleInputChange}
                    rows={7}
                    sx={{border: 'none',
                    "&:hover":{border:'none'}
                    }}
                    required/>
                  <div style={{
                    position: 'absolute',
                    bottom: 8,
                    right: 8
                  }}>
                    <Button variant="contained"
                      sx={{
                        backgroundColor: isWebsiteFor === "university" ? 'var(--primary-color)' : 'var(--tertiary-color)',
                        width: '84px',
                        marginRight: '12px',
                        textTransform: 'capitalize',
                        "&:hover": {
                          backgroundColor: 'var(--primary-color'
                        },
                        }}
                      type="submit"
                      onClick={requestSubmit}>Submit</Button>
                  </div>
                </div>
              </DialogContent>
            </>
          }
      </Dialog>
      <div style={{
        marginTop: '100px'
      }} className="pagination-addPost">
        <Pagination
          totalCount={totalCount}
          limit={limit}
          page={page}
          changeLimit={setLimit}
          changeOffset={setOffset}
          changePage={setPage}
        />
      </div>
      <div className="content-box table-box" style={{marginTop: '0px'}}>     
        <StudentProfileTable
        columns={colR}
        where="recipes"
        rows={applicationData && applicationData?.map((item) => {
          const res = {
            ...item,
            Status: (
              <>
                {item?.status === "target_upload_requested" && 
                <span style={{
                  color: '#F69220',
                  fontSize: '18px',
                  fontWeight: 'bold'
                }}>
                  Pending
                </span>}
                {item?.status === "target_uploaded" && 
                <span style={{
                  color: '#3F9AE0',
                  fontSize: '18px',
                  fontWeight: 'bold'
                }}>
                  Uploaded
                </span>}
                {item?.status === "target_changes_requested" && 
                <span style={{
                  color: '#07AB35',
                  fontSize: '18px',
                  fontWeight: 'bold'
                }}>
                  Need Changes
                </span>}
              </>
            ),
            Action: (
            <div style={{display:'flex',columnGap:'6px'}}>
              <div>
                <IconButton
                style={{display:'block'}}
                  color="inherit"
                  edge="start"
                  onClick={() => {
                    setInstId(item?._id);
                    setStatus(item?.status);
                    setOpen(true);
                    setOpenAccept(false);
                    setOpenRequest(true);
                  }}
                >                                    
                <AppRegistrationOutlinedIcon sx={{color: '#FBB44B'}} className="action-icon" />
                <Typography component="p" variant="p" 
                sx={{
                color: '#FBB44B',
                fontWeight: '600'
                }} className="action-buton">
                Requested
                </Typography>
                  </IconButton>
              </div>
              <div>
                <IconButton
                style={{display:'block'}}
                  color="inherit"
                  edge="start"
                  onClick={() => {
                    setInstId(item?._id);
                    setStatus(item?.status);
                    setOpen(true);
                    setOpenAccept(true);
                    setOpenRequest(false);
                  }}
                >                                    
                <CheckCircleOutlinedIcon sx={{color: '#07AB35'}} className="action-icon" />
                <Typography component="p" variant="p" 
                sx={{
                color: '#07AB35',
                fontWeight: '600'
                }} className="action-buton">
                Accept
            </Typography>
                </IconButton>
                </div>
                <div>
                  <IconButton
                  style={{display:'block'}}
                    color="inherit"
                    edge="start"
                    onClick={() => handleClickOpenView(item?._id)}
                  >                                    
                  <VisibilityIcon sx={{color: '#BA68C8'}} className="action-icon" />
                  <Typography component="p" variant="p" 
                  sx={{
                  color: '#BA68C8',
                  fontWeight: '600'
                  }} className="action-buton">
                  View
                  </Typography>       
                  </IconButton>                           
                </div>   
              </div>)};
              return res;
                    })
                    }
                />
      </div>
    </>
  );
}