import * as React from "react";
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
    Box,
    Button,
    IconButton,
    InputAdornment,
    Typography,
    InputLabel,
    TextField,
  } from "@mui/material";
import { useDispatch } from "react-redux";
import endpoint from "../../index.routes";
import Grid from "@mui/material/Grid";
import OtpInput from 'react-otp-input';
import validator from "validator";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import LoaderIcon from "../../components/Loader/Loader";
import LoginPageImage from "../../assest/images/loginPageImage.jpeg";
import { resetPassword, forgotPassword } from "../../redux/auth/authSlice";

const useStyle = makeStyles({
    marginBot24: {
        marginBottom: '24px'
    },
    formInputLabel: {
        color: '#000 !important',
        fontSize: '18px !important',
        marginBottom: '4px',
        fontWeight: 'bold !important'
    },
    requestButton: {
        color: '#fff !important',
        textTransform: 'capitalize !important',
        width: '100%',
        height: '44px',
        fontSize: '16px !important',
        borderRadius: '8px !important',
        boxShadow: '0 2px 5px rgb(0, 0, 0, 0.5)',
        "&:hover":{
            backgroundColor: 'var(--secondary-color) !important',
        }
    }
})

let formData = {
    email: "",
    otp: "",
    password: ""
}

export default function ResetPassword() {
    const classes = useStyle();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isOTPSent, setIsOTPSent] = React.useState(false);
    const [otpValue, setOtpValue] = React.useState("");
    const [submitData, setSubmitData] = React.useState(formData);
    const [showPassword, setShowPassword] = React.useState("");
    const [requestLoading, setRequestLoading] = React.useState(false);
    
    const submit = () => {
        let path = "";
        if(otpValue === ""){
            toast.error("Enter OTP");
            return;
        }
        if(otpValue.length !== 4){
            toast.error("Invalid OTP");
            return;
        }
        if(!submitData?.password || submitData?.password === ""){
            toast.error("Password Required");
            return;
        }
        if(submitData?.password?.length < 7 ){
            toast.error("Password Must Be Greater Than 6 Character");
            return;
        }
        let body = {
            email: submitData?.email,
            otp: otpValue,
            password: submitData?.password
        }
        
        localStorage.getItem("is-website-for") === "student" && (path = endpoint.STUDENT_RESET_PASSWORD);
        localStorage.getItem("is-website-for") === "partner" && (path = endpoint.PARTENR_RESET_PASSWORD);
        localStorage.getItem("is-website-for") === "coaching" && (path = endpoint.COACHING_RESET_PASSWORD);
        setRequestLoading(true);
        const res = dispatch(resetPassword({path, body}));
        res?.then((r) => {
            setRequestLoading(false);
            if(r?.payload?.message === "Password reset successfully"){
                navigate('/login');
                toast.success("Password Reset Successfully");
                return;
            }
            toast.error("Incorrect OTP Enter");
        })
    }

    const forgotPasswordPressed = () => {
        let path = "";
        let body = {};
        if(submitData?.email === ""){
          toast.error("Email Required");
          return;
        }
        if(!validator.isEmail(submitData?.email)){
          toast.error("Invalid Email Entered");
          return;
        }
        localStorage.getItem("is-website-for") === "student" && (path = endpoint.STUDENT_FORGOT_PASSWORD);
        localStorage.getItem("is-website-for") === "partner" && (path = endpoint.PARTNER_FORGOT_PASSWORD);
        localStorage.getItem("is-website-for") === "coaching" && (path = endpoint.COACHING_FORGOT_PASSWORD);
        
        body = {email: submitData?.email};
        setRequestLoading(true);
        const res = dispatch(forgotPassword({path, body}));
        res?.then((r) => {
            setRequestLoading(false);
            if(r?.payload?.message === "Otp Sent"){
                setIsOTPSent(true);
                toast.success(r?.payload?.message);
                return;
            }
            toast.error(r?.payload?.message);
        })
    }

    const handleOTPChange = (value) => {
        setOtpValue(value);
    }

    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);
    return (
        <>
        <Grid
            container
            component="main"
            sx={{ height: "100vh" }}
            className="login-form"
        >
            <Grid
                item
                xs={false}
                sm={4}
                md={6}
                sx={{ backgroundColor: 'var(--primary-color)' }}>
                <img src={LoginPageImage} alt="LoginImage" className="login-image" />
            </Grid>
            <Grid
            item
            xs={12}
            sm={8}
            md={6}
            sx={{ alignSelf: "center !important" }}
            >
            <Box
                className="login-box"
                sx={{
                my: 8,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                }}
            >
                <Typography component="h2" variant="h2" className="form-heading" sx={{color: 'var(--primary-color)'}}>
                    Forgot Password
                </Typography>
                <div style={{width: '100%'}}>
                    <form onSubmit={submit}>
                        <div className={classes.marginBot24}>
                            <InputLabel className={classes.formInputLabel}>
                                Email
                            </InputLabel>
                            <div>
                                <TextField 
                                    placeholder="" 
                                    type="email" 
                                    variant="outlined" 
                                    sx={{
                                        width: '100%',
                                        height: '62px'
                                    }} 
                                    name="email" 
                                    disabled={isOTPSent}
                                    onChange={(e) => setSubmitData({...submitData, email: e.target.value})} 
                                    value={submitData.email} 
                                    required
                                />
                                {isOTPSent &&
                                    <div style={{
                                        marginTop: '12px'
                                    }}>
                                        <InputLabel className={classes.formInputLabel}>
                                            Enter OTP
                                        </InputLabel>
                                        <div style={{display: 'flex', justifyContent: 'center'}}>
                                            <OtpInput
                                                value={otpValue}
                                                onChange={handleOTPChange}
                                                numInputs={4}
                                                inputStyle={{
                                                    width: '80px',
                                                    height: '36px',
                                                    borderTop: 'none',
                                                    borderRight: 'none',
                                                    borderLeft: 'none',
                                                    "&:focusVisible":{
                                                        borderTop: 'none',
                                                        borderRight: 'none',
                                                        borderLeft: 'none',
                                                    }
                                                }}
                                                isInputNum={true}
                                                separator={<span>&nbsp;&nbsp;&nbsp;</span>}
                                            />
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        {isOTPSent &&
                            <div className={classes.marginBot24}>
                                <InputLabel className={classes.formInputLabel}>
                                    Enter New Password
                                </InputLabel>
                                <TextField
                                    id="password"
                                    name="password"
                                    type={showPassword ? "text" : "password"}
                                    value={submitData.password}
                                    onChange={(e) => setSubmitData({...submitData, password: e.target.value})}
                                    autoComplete="current-password"
                                    margin="normal"
                                    required
                                    InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                        </InputAdornment>
                                    ),
                                    }}
                                    fullWidth
                                    sx={{
                                        width: '100%',
                                        height: '62px'
                                    }}
                                />
                            </div>
                        }
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}>
                            <Button 
                                className={classes.requestButton} 
                                onClick={isOTPSent ? submit : forgotPasswordPressed}
                                sx={requestLoading ? {backgroundColor: 'var(--disable-color)'} : 
                                    {backgroundColor: 'var(--secondary-color)'}}
                                disabled={requestLoading}>
                                {requestLoading ? 
                                    <LoaderIcon /> : 
                                    (isOTPSent ? 'Reset Password' : 'Send OTP')
                                }
                            </Button>
                        </div>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: '32px'
                        }}>
                            <Typography>
                                Try login
                            </Typography>
                            <Link to="/login" style={{
                                fontWeight: 'bold',
                                marginLeft: '4px'
                            }}>Login</Link>
                        </div>
                    </form>
                </div>
            </Box>
            </Grid>
        </Grid>
        </>
    );
}