import * as React from 'react';
import {
    Button,
    Input,
    InputLabel,
    Typography,
    TextField
} from "@mui/material";
import Select from '../Select/Select';
import { makeStyles } from "@mui/styles";
import Radio from '@mui/material/Radio';
import { useDispatch, useSelector } from "react-redux";
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import JoditEditor from 'jodit-react';
import UploadBox from '../UploadBox/uploadBox';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import upload from '../../assest/svgs/upload.svg';
import UploadPinkIcon from '../../assest/svgs/upload-pink-icon.svg';
import Loader from "../../assest/images/preloader.gif";
import { toast } from "react-toastify";
import getImageUrls from "../../api/uploadImageOnS3";

const useStyle = makeStyles({
    marginBot24: {
        marginBottom: '24px',
    },
    upload: {
        border: '1px solid rgb(0, 0, 0, 0.2) !important',
        fontSize: '10px !important',
        color: 'rgb(0, 0, 0, 0.4) !important',
        textTransform: 'capitalize !important',
        width: '80px',
        height: '20px',
        marginBottom: '4px'
    }
})

const AddOns = (props) => {
    const isWebsiteFor = localStorage.getItem("is-website-for");
    const editor = React.useRef(null);
    const classes = useStyle();

    const { testData } = useSelector((state) => state.test);
    const { courseData, contentData } = useSelector((state) => state.product);

    return (
        <div>
            <Typography variant="h3" component="h3" sx={{
                color: isWebsiteFor === "university" ? '#672F8F' : 'var(--tertiary-color)',
                fontSize: '24px',
                marginBottom: '8px',
                fontWeight: 'bold'
            }}>
                Addons
            </Typography>
            <div className={[`${classes.marginBot24} form-part-1`]}>
                <div className='four-input-box margin-bot-change'>
                    <InputLabel className={classes.formInputLabel}>Product Type</InputLabel>
                    <Select 
                        name={`add_on_p_type_${props.index}`}
                        value={props.item?.product_type}
                        option={props.type} 
                        handleSelect={(e) => props.handleChange(e, props.index)}
                    />
                </div>
                <div className='four-input-box category-input margin-bot-change'>
                    <InputLabel className={classes.formInputLabel}>Product name</InputLabel>
                    <TextField 
                        placeholder="" 
                        type="text" 
                        value={props.item?.name} 
                        variant="outlined" 
                        name={`add_on_product_name_${props.index}`} 
                        sx={{
                            width: '100%',
                            height: '62px'
                        }} 
                        onChange={(e) => props.handleChange(e, props.index)} 
                        required/>
                </div>
                <div className='four-input-box author-box'>
                    <InputLabel className={classes.formInputLabel}>
                        Author name
                    </InputLabel>
                    <TextField 
                        placeholder="" 
                        type="text" 
                        variant="outlined" 
                        disabled={props.item?.product_type !== "Books"}
                        value={props.item?.author_name}
                        name={`add_on_author_name_${props.index}`} 
                        sx={{
                            width: '100%',
                            height: '62px'
                        }} 
                        onChange={(e) => props.handleChange(e, props.index)} 
                        required/>
                </div>
                <div className='four-input-box addons-price'>
                    <InputLabel className={classes.formInputLabel}>Price</InputLabel>
                    <TextField 
                        placeholder="" 
                        type="number" 
                        variant="outlined" 
                        value={props.item?.price}
                        name={`add_on_price_${props.index}`} 
                        sx={{
                            width: '100%',
                            height: '62px'
                        }} 
                        onChange={(e) => props.handleChange(e, props.index)} 
                        required/>
                </div>
            </div>
            <div className={[`${classes.marginBot24} description-box`]}>
                <div className="margin-bot-change semes-box">
                    <Typography component="h2" variant="h2" className="input-label-text">
                        Upload image <span className="optional-text-style">(Optional)</span>
                    </Typography>
                    <div className="product-upload-box">
                        <UploadBox
                            name={`add_on_img_${props.index}`}
                            height={'190px'}
                            width={'216px'}
                            value={props.item?.images_url && props.item?.images_url[0]}
                            handleUpload={(name, value) => props.handleChange(name, value)} />
                    </div>
                </div>
                <div className="description-input-addons">
                    <InputLabel className={classes.formInputLabel}>Description</InputLabel>
                    <JoditEditor 
                        ref={editor}
                        value={props.item?.description}
                        onChange={newText => props.textEditor(newText)}
                    />
                    {/* <TextField 
                        placeholder="" 
                        type="text" 
                        variant="outlined" 
                        sx={{
                            width: '100%',
                            height: '62px'
                        }} 
                        name={`add_on_desc_${props.index}`}
                        onChange={(e) => props.handleChange(e, props.index)} 
                        value={props.item?.description}
                        rows={6}
                        multiline
                        required/> */}
                </div>
                <div className="branch-course">
                    <div className="four-input-box margin-bot-change">
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue={props.item?.is_percentage_discount ? 'percentage' : 'flat'}
                            name={`add_on_per_${props.index}`}
                            onChange={(e) => props.handleChange(e, props.index)}
                        >
                            <FormControlLabel 
                                value="percentage" 
                                control={<Radio sx={{
                                    color: 'var(--secondary-color)',
                                    '&.Mui-checked': {
                                        color: 'var(--secondary-color)',
                                        },
                                }} />} 
                                label="Percentage Discount" />
                            <FormControlLabel 
                                value="flat" 
                                control={<Radio sx={{
                                    color: 'var(--secondary-color)',
                                    '&.Mui-checked': {
                                        color: 'var(--secondary-color)',
                                        },
                                }} />} 
                                label="Flat Discount" />
                        </RadioGroup>
                    </div>
                    <div className="four-input-box course-type-input margin-bot-change">
                        <InputLabel className={classes.formInputLabel}>
                            Offers <span className="optional-text-style">(Optional)</span>
                        </InputLabel>
                        <TextField 
                            placeholder="" 
                            type="number" 
                            variant="outlined" 
                            name={`add_on_offer_${props.index}`} 
                            value={props.item?.discount}
                            sx={{
                                width: '100%',
                                height: '62px'
                            }} 
                            onChange={(e) => props.handleChange(e, props.index)} 
                            required/>
                        
                    </div>
                </div>
            </div>
            {/* New Added */}
            <InputLabel className={classes.formInputLabel}>
                Select anyone of them <span className="optional-text-style">(Optional)</span>
            </InputLabel>
            <div className={[`${classes.marginBot24} form-part-1`]}>
                <div className='four-input-box margin-bot-change'>
                    <InputLabel className={classes.formInputLabel}>
                        Select Test
                    </InputLabel>
                    <Select 
                        from="test"
                        isDisabled={!(props.item?.product_type === "Test Series")}
                        name={`add_test_${props.index}`}
                        value={props.item?.test_id}
                        option={testData ? testData : []} 
                        handleSelect={(e) => props.handleChange(e, props.index)}
                    />
                </div>
                <div className='four-input-box category-input margin-bot-change'>
                    <InputLabel className={classes.formInputLabel}>
                        Select Course
                    </InputLabel>
                    <Select 
                        from="test"
                        isDisabled={!(props.item?.product_type === "Recorded Course")}
                        name={`add_course_${props.index}`}
                        value={props.item?.course_id}
                        option={courseData ? courseData : []} 
                        handleSelect={(e) => props.handleChange(e, props.index)}
                    />
                </div>
                <div className='four-input-box author-box'>
                    <InputLabel className={classes.formInputLabel}>
                        Study Material or Notes
                    </InputLabel>
                    <Select 
                        from="test"
                        isDisabled={(
                            props.item?.product_type === "Test Series" ||
                            props.item?.product_type === "Recorded Course"
                        )}
                        name={`add_study_material_${props.index}`}
                        value={props.item?.reading_material_id}
                        option={contentData ? contentData : []} 
                        handleSelect={(e) => props.handleChange(e, props.index)}
                    />
                    {/* <label htmlFor={`add_study_material_${props.index}`} style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '64%',
                        height: '100%',
                    }}>
                        {props?.item?.study_material ? 
                            <span style={{
                                fontSize: '12px',
                                color: 'rgb(0, 0, 0, 0.5)',
                                lineHeight: '14px',
                                marginBottom: '4px',
                                marginTop: '8px',
                            }}>{props?.item?.study_material.split('-')[3]}</span>
                            : <img src={isWebsiteFor === "university" ? 
                                upload : UploadPinkIcon} 
                            alt="upload" width="20" height="32" />}
                        <Input
                            id={`add_study_material_${props.index}`}
                            name={`add_study_material_${props.index}`}
                            type="file"
                            accept="application/pdf"
                            sx={{display: 'none'}}
                            onChange={(e) => {uploadImage(e, props.index)}}
                        />
                        <Button component="div" className={classes.upload}>
                            <UploadFileIcon fontSize="15" />
                            {(loading && (loaderIndex === props.index)) ? 
                                <img src={Loader} width="12" alt="Loader" /> : 
                                <span>{'Upload'}</span>}
                        </Button>
                    </label> */}
                </div>
            </div>
            <div className={[`${classes.marginBot24} form-part-1`]}>
                {isWebsiteFor === "university" && 
                <div className='four-input-box margin-bot-change'>
                    <InputLabel className={classes.formInputLabel}>Course name</InputLabel>
                    <TextField 
                        placeholder="" 
                        type="text" 
                        variant="outlined" 
                        name={`add_ons_course_${props.index}`} 
                        value={props.item?.course_name}
                        sx={{
                            width: '100%',
                            height: '62px'
                        }} 
                        onChange={(e) => props.handleChange(e, props.index)} 
                        required/>
                </div>}
                <div className={isWebsiteFor === 'university' && 'payment-options'}>
                    <InputLabel className={classes.formInputLabel}>
                        How you want to do payment ?
                    </InputLabel>
                    <RadioGroup
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue={props.item?.payment_procedure || props.item?.payment_mode}
                        name={`add_ons_product_payment_process_${props.index}`}
                        onChange={(e) => props.handleChange(e, props.index)}
                    >
                        <FormControlLabel 
                            value="direct_pay" 
                            control={<Radio sx={{
                                color: 'var(--secondary-color)',
                                '&.Mui-checked': {
                                    color: 'var(--secondary-color)',
                                    },
                            }} />} 
                            label="Direct Pay" />
                        <FormControlLabel 
                            value="full_process" 
                            control={<Radio sx={{
                                color: 'var(--secondary-color)',
                                '&.Mui-checked': {
                                    color: 'var(--secondary-color)',
                                    },
                            }} />} 
                            label="Follow Full Process" />
                    </RadioGroup>
                </div>
            </div>
        </div>
    )
}
export default AddOns