import * as React from 'react';
import parse from 'html-react-parser';
import Moment from "moment";

import "./blogCard.css";


const BlogCard = (props) => {
    
    return (
        <div className="blog-card-box">
            <div style={{
                height: '260px'
            }}>
                <img src={props?.data?.images[0]} alt="blogImage" 
                    onClick={() => props.detail(props?.data?._id)} />
            </div>
            <div className="blog-footer">
                <h1 onClick={() => props.detail(props?.data?._id)} style={{
                    textTransform: 'capitalize'
                }}>{props.data.title.length > 27 ?
                 `${props.data.title.substr(0, 27)}...` : props.data.title}</h1>
                <p style={{
                    height: '112px',
                    // overflow: fullDescription ? 'auto' : 'hidden'
                    overflow: 'auto'
                }}>
                    {parse(props.data.description)}
                    {/* {fullDescription ? parse(props.data.description) : parse(props.data.description.substr(0, 120))}
                    {props.data.description.length > 120 && <span style={{
                        color: '#C32248',
                        cursor: 'pointer'
                    }} onClick={() => {setFullDescription(!fullDescription)}}>
                        {fullDescription ? ' show less' : " read more..."}
                    </span>} */}
                </p>
                <h6 style={{
                    textTransform: 'uppercase',
                }}>
                    {Moment(props?.data?.created_at).format('MMMM DD, YYYY')}- 
                    {props?.data?.coaching_id ? "COACHING" : "UNIVERSITY"} 
                </h6>
            </div>
        </div>
    )
}

export default BlogCard;