import * as React from "react";
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
    Box,
    Button,
    Input,
    IconButton,
    InputAdornment,
    Typography,
    InputLabel,
    TextField,
    Select,
    MenuItem,
    FormControlLabel,
    RadioGroup,
    Radio
  } from "@mui/material";
import { useDispatch } from "react-redux";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import Grid from "@mui/material/Grid";
import OtpInput from 'react-otp-input';
import Loader from "../../assest/images/preloader.gif";
import validator from "validator";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import LoaderIcon from "../../components/Loader/Loader";
import getImageUrls from "../../api/uploadImageOnS3";
import LoginPageImage from "../../assest/images/loginPageImage.jpeg";
import { 
    verify, 
    sendOTP,
    studentSignup } from "../../redux/requestForm/requestFormSlice";

const countryList = require("../../data/country.json");

const useStyle = makeStyles({
    benefitSingleBox: {
        display: "flex",
        marginBottom: '24px',
        alignItems: "center",
    },
    howItWorkSingleBox: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginBottom: '24px',
        alignItems: "center",
    },
    buttonStyle: {
        background: 'none',
        textTransform: 'capitalize !important',
        position: 'absolute !important',
        right: 0,
        top: 8,
    },
    verifySubmit: {
        height: '36px',
        textTransform: 'capitalize !important',
        color: '#FFFFFF !important',
        width: '150px',
        '&:hover':{
            backgroundColor: '#DC0707 !important',
        },
    },
    marginBot24: {
        marginBottom: '24px'
    },
    formInputLabel: {
        color: '#000 !important',
        fontSize: '18px !important',
        marginBottom: '4px',
        fontWeight: 'bold !important'
    },
    uploadButton: {
        height: '80px',
        border: '2px dashed rgb(0,0,0,0.5) !important',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#FFFFFF !important',
        "&:hover": {
            backgroundColor: '#ffffff !important',
        }
    },
    requestButton: {
        color: '#fff !important',
        textTransform: 'capitalize !important',
        width: '100%',
        height: '44px',
        fontSize: '16px !important',
        borderRadius: '8px !important',
        boxShadow: '0 2px 5px rgb(0, 0, 0, 0.5)',
        "&:hover":{
            backgroundColor: 'var(--secondary-color) !important',
        }
    }
})

let formData = {
    email: "",
    password: "",
    name: "",
    address: "",
    phone: "",
    dob: "",
    id_proof: "",
    phone_country_code: "91",
    verification_id_email: "",
    registered_for: "university",
}

var nameValidator = /^[a-zA-Z][a-zA-Z\s]*$/

export default function Signup() {
    const [isMobileVerified, setIsMobileVerified] = React.useState(false);
    const [isEmailVerified, setIsEmailVerified] = React.useState(false);
    const [showPassword, setShowPassword] = React.useState("");
    const [otp, setOtp] = React.useState("");
    const [isLoader, setIsLoader] = React.useState(false);
    const [from, setFrom] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [requestLoading, setRequestLoading] = React.useState(false);
    const [mobileLoading, setMobileLoading] = React.useState(false);
    const [emailLoading, setEmailLoading] = React.useState(false);
    const [errors, setErrors] = React.useState(false);
    const [formInformation, setFormInformation] = React.useState(formData);
    const [isEmailOtpField, setIsEmailOtpField] = React.useState(false);
    const [isPhoneOtpField, setIsPhoneOtpField] = React.useState(false);
    const classes = useStyle();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleChange = (event) => {
        event.target.name === 'name' && setFormInformation({...formInformation, name: event.target.value})
        event.target.name === 'email' && setFormInformation({...formInformation, email: event.target.value})
        event.target.name === 'phone-number' && setFormInformation({...formInformation, phone: event.target.value})
        event.target.name === 'phone-country-code' && setFormInformation({...formInformation, phone_country_code: event.target.value})
        event.target.name === "dob" && setFormInformation({...formInformation, dob: event.target.value})
        event.target.name === "course-interested-in" && setFormInformation({...formInformation, course_interested_in: event.target.value})
        event.target.name === 'address' && setFormInformation({...formInformation, address: event.target.value})
        event.target.name === 'password' && setFormInformation({...formInformation, password: event.target.value})
        event.target.name === "registration-type" && setFormInformation({
            ...formInformation, 
            registered_for: event.target.value})
        if(event.target.name === "image-upload"){
            if(event.target?.files[0]?.size > 1049000){
                toast.error("Image size must be less than 1MB");
                return;
            }else{
                handleUploadFilesOnS3(event.target.files[0]);
            }
        }
    };
    const handleUploadFilesOnS3 = async(image) => {
        const response = await getImageUrls({
            image: image,
            setLoading,
            setErrors,
        });
        setFormInformation({...formInformation, id_proof: response?.data?.urls[0]})
    }
    const requestBtnClicked = () => {
        if(validate().status){
            let body = {};
            if(formInformation.registered_for === "university"){
                body = {
                    email: formInformation.email,
                    password: formInformation.password,
                    name: formInformation.name,
                    address: formInformation.address,
                    phone: formInformation.phone,
                    dob: formInformation.dob,
                    id_proof: formInformation.id_proof,
                    registered_for: formInformation.registered_for,
                    phone_country_code: formInformation.phone_country_code,
                    verification_id_email: formInformation.verification_id_email,
                }
            }else{
                body = {
                    email: formInformation.email,
                    password: formInformation.password,
                    name: formInformation.name,
                    address: formInformation.address,
                    phone: formInformation.phone,
                    dob: formInformation.dob,
                    id_proof: formInformation.id_proof,
                    phone_country_code: formInformation.phone_country_code,
                    verification_id_email: formInformation.verification_id_email,
                    registered_for: formInformation.registered_for,
                }
            }
            setRequestLoading(true);
            let res = dispatch(studentSignup(body));
                res.then((r) => {
                    setRequestLoading(false);
                    if(r.payload.statusCode === 200){
                        toast.success(r?.payload?.message);
                        navigate('/login');
                    }else{
                        toast.error(r?.payload?.message);
                    }
                })
        }else{
            toast.error(validate().message)
        }
    }
    const validate = () => {
        let error = {status: true, message: ""}
        if(formInformation.name === ""){
            error.status = false;
            error.message = "Name required";
            return error;
        }
        if(formInformation.name.length < 4 || !nameValidator.test(formInformation.name) || 
            formInformation.name.length > 20){
            formInformation.name.length < 4 && (error = {status: false, message: "Name must be at least 4 characters"})
            formInformation.name.length > 20 && (error = {status: false, message: "Name must be less than 20 characters"})
            !nameValidator.test(formInformation.name) && (error = {status: false, message: "Invalid name"})
            return error;
        }
        if(formInformation.email === ""){
            error.status = false;
            error.message = "Email required";
            return error;
        }
        if(!validator.isEmail(formInformation.email)){
            error.status = false;
            error.message = "Invalid Email";
            return error;
        }
        if(formInformation.phone_country_code === ""){
            error.status = false;
            error.message = "Phone Country Code Required";
            return error;
        }
        if(formInformation.phone === "" || formInformation.phone.length !== 10){
            error.status = false;
            error.message = formInformation.phone === "" ? "Phone Number Required" : "Invalid Phone Number";
            return error;
        }
        if(formInformation.dob === ""){
            error.status = false;
            error.message = "DOB Required";
            return error;
        }
        if(new Date().getFullYear() - new Date(formInformation.dob).getFullYear() < 12){
            error.status = false;
            error.message = "Age must be greater than 12 year";
            return error;
        }
        if(formInformation.address === "" || formInformation.address.length < 6){
            error.status = false;
            error.message = formInformation.address === "" ? "Address Required" : "Address must be at least 6 characters";
            return error;
        }
        if(formInformation.id_proof === ""){
            error.status = false;
            error.message = "Id Proof Required";
            return error;
        }
        if(formInformation.password === "" || formInformation.password.length < 6){
            error.status = false;
            error.message = formInformation.password === "" ? "Password Required" : "Password must be at least 6 characters";
            return error;
        }
        if(formInformation.verification_id_email === ""){
            error.status = false;
            error.message = "Email Not Verified";
            return error;
        }
        // if(formInformation.verification_id_phone === ""){
        //     error.status = false;
        //     error.message = "Phone Number Not Verified";
        //     return error;
        // }
        // if(formInformation.course_interested_in === ""){
        //     error.status = false;
        //     error.message = "Course Interested In Required";
        //     return error;
        // }
        // if(formInformation.countries_interested_in.length === 0 && formInformation.registered_for === "university"){
        //     error.status = false;
        //     error.message = "Countries Interested In Required";
        //     return error;
        // }
        // if(formInformation.budget_in_lakhs === "" && formInformation.registered_for === "university"){
        //     error.status = false;
        //     error.message = "Budget Required";
        //     return error;
        // }
        // if(formInformation.subjects.length === 0 && formInformation.registered_for === "coaching"){
        //     error.status = false;
        //     error.message = "Subject Required";
        //     return error;
        // }
        return error;
    }
    const verifyFunc = (type) => {
        if(type === "Mobile"){
            if(formInformation.phone === "" || formInformation.phone.length !== 10){
                toast.error("Invalid Mobile Number");
                return;
            }else{
                setMobileLoading(true);
                let verifyInfo = {
                    phone: formInformation.phone,
                    phone_country_code: formInformation.phone_country_code,
                    type: "phone"
                }
                const res = dispatch(verify(verifyInfo));
                res.then((r) => {
                    setMobileLoading(false);
                    if(r.payload.statusCode === 200){ 
                        setIsMobileVerified(true);
                        setIsPhoneOtpField(false);
                        setFormInformation({...formInformation, verification_id_phone: r?.payload?.data})
                    }
                    toast.success(r?.payload?.message);
                });
            }
        }else{
            if(!validator.isEmail(formInformation.email)){
                toast.error("Invalid Email");
                return;
            }
            if(otp === ""){
                toast.error("OTP Required");
                return;
            }
            if(otp?.length !== 4){
                toast.error("Invalid OTP Entered");
                return;
            }
            else{
                setEmailLoading(true);
                let verifyInfo = {
                    email: formInformation.email,
                    type: "email",
                    otp: otp
                }
                const res = dispatch(verify(verifyInfo));
                res?.then((r) => {
                    setEmailLoading(false);
                    if(r?.payload?.message === "Verified"){ 
                        setIsEmailVerified(true);
                        setIsEmailOtpField(false);
                        setFormInformation({...formInformation, verification_id_email: r?.payload?.data});
                        return;
                    }
                    toast.success(r?.payload?.message);
                });
            }
        }
    }
    const handleOtpChange = (value) => {
        setOtp(value);
    }

    const verifyButtonClicked = (type) => {
        if(!validator.isEmail(formInformation.email)){
            toast.error("Invalid Email");
            return;
        }
        let verifyInfo = {
            email: formInformation.email,
            type: "email"
        }
        setFrom(type);
        setIsLoader(true);
        const res = dispatch(sendOTP(verifyInfo));
        res?.then((r) => {
            setIsLoader(false);
            if(r?.payload?.statusCode === 200){ 
                setIsEmailOtpField(true);
                toast.success("OTP Sent Successfully");
            }else{
                toast.error("Server Error");
            }
        })
    }

    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);
    return (
        <>
        <Grid
            container
            component="main"
            sx={{ height: "100vh" }}
            className="login-form"
        >
            <Grid
                item
                xs={false}
                sm={4}
                md={6}
                sx={{ backgroundColor: 'var(--primary-color)' }}>
                <img src={LoginPageImage} alt="LoginImage" className="login-image" />
            </Grid>
            <Grid
            item
            xs={12}
            sm={8}
            md={6}
            sx={{ alignSelf: "center !important" }}
            >
            <Box
                className="login-box"
                sx={{
                my: 8,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                }}
            >
                <Typography component="h2" variant="h2" className="form-heading" sx={{color: 'var(--primary-color)'}}>
                    Sign Up
                </Typography>
                <div style={{width: '100%'}}>
                    <form onSubmit={requestBtnClicked}>
                        <div className={classes.marginBot24}>
                            <InputLabel className={classes.formInputLabel}>
                                Name
                            </InputLabel>
                            <TextField placeholder="" type="text" variant="outlined" name="name" 
                                sx={{
                                    width: '100%',
                                    height: '62px'
                                }} onChange={handleChange} value={formInformation.name} required/>
                        </div>
                        <div className={classes.marginBot24}>
                            <InputLabel className={classes.formInputLabel}>
                                Email
                            </InputLabel>
                            <div style={{
                                    position: 'relative',
                                }}>
                                <TextField 
                                    placeholder="" 
                                    type="email" 
                                    variant="outlined" 
                                    sx={{
                                        width: '100%',
                                        height: '62px'
                                    }} 
                                    name="email" 
                                    disabled={formInformation?.verification_id_email !== ""}
                                    onChange={handleChange} 
                                    value={formInformation.email} 
                                    required/>
                                {isEmailVerified ? 
                                    <Button 
                                        sx={{color: '#07AB35'}} 
                                        className={classes.buttonStyle}>
                                        Verified
                                    </Button>: 
                                    <Button 
                                        sx={{color: '#DC0707'}} 
                                        className={classes.buttonStyle}
                                        disabled={isLoader && from === "Verify"}
                                        onClick={() => {verifyButtonClicked("Verify")}}>
                                        {(isLoader && from === "Verify") ? 
                                        <LoaderIcon /> : 'Verify'}
                                    </Button>}
                            </div>
                            {isEmailOtpField &&
                            <div>
                                <OtpAria 
                                    otp={otp} 
                                    isLoader={isLoader}
                                    from={from}
                                    type="Email"
                                    title="OTP send on your above mention email ID"
                                    loading={emailLoading}
                                    btnTitle="Verify Email"
                                    resend={(type) => verifyButtonClicked(type)}
                                    verifyClicked={(type) => verifyFunc(type)}
                                    handleOChange={handleOtpChange} />
                            </div>}
                        </div>
                        <div className={classes.marginBot24}>
                            <InputLabel className={classes.formInputLabel}>
                                Phone number
                            </InputLabel>
                            <div style={{
                                display: 'flex'
                            }}>
                                <div style={{
                                    width: '34%',
                                    marginRight: '12px'
                                }}>
                                    <Select
                                        value={formInformation?.phone_country_code}
                                        onChange={handleChange}
                                        placeholder="+91"
                                        name="phone-country-code"
                                        MenuProps={{ PaperProps: { sx: { maxHeight: 250 } } }}
                                        autoWidth
                                        required
                                        sx={{
                                            width: '100%',
                                            height: '56px',
                                            position: 'relative',
                                        }}
                                        >
                                        {countryList.map((item, index) => 
                                        <MenuItem value={item.phonecode} key={index}>
                                            {item.phonecode}
                                        </MenuItem>)}
                                    </Select>
                                </div>
                                <div style={{
                                    width: '100%',
                                    position: 'relative',
                                }}>
                                    <TextField 
                                        placeholder="" 
                                        type="number" 
                                        variant="outlined" 
                                        sx={{
                                            width: '100%',
                                            height: '62px'
                                        }} 
                                        name="phone-number" 
                                        onChange={handleChange} 
                                        value={formInformation.phone} 
                                        required/>
                                    {/* {!isMobileVerified ? 
                                    <Button 
                                        sx={{color: '#DC0707'}} 
                                        className={classes.buttonStyle}
                                        onClick={() => {setIsPhoneOtpField(true)}}>
                                        Verify
                                    </Button>: 
                                    <Button sx={{color: '#07AB35'}} className={classes.buttonStyle}>
                                        Verified
                                    </Button>} */}
                                </div>
                            </div>
                            {/* {isPhoneOtpField &&
                            <div>
                                <OtpAria 
                                    otp={otp} 
                                    type="Mobile"
                                    loading={mobileLoading}
                                    title="OTP send on your above mention phone number"
                                    btnTitle="Verify Phone"
                                    verifyClicked={(type) => verifyFunc(type)}
                                    handleOChange={handleOtpChange} />
                            </div>} */}
                        </div>
                        <div className={classes.marginBot24}>
                            <InputLabel className={classes.formInputLabel}>
                                Date of birth
                            </InputLabel>
                            <TextField placeholder="" type="date" variant="outlined" name="dob" sx={{
                                width: '100%',
                                height: '62px'
                            }} onChange={handleChange} value={formInformation.dob} required/>
                        </div>
                        <div className={classes.marginBot24}>
                            <InputLabel className={classes.formInputLabel}>Full Address</InputLabel>
                            <TextField 
                                placeholder="" 
                                type="text" 
                                variant="outlined" 
                                sx={{
                                    width: '100%',
                                    height: '62px'
                                }} 
                                multiline
                                rows={4}
                                name="address" 
                                onChange={handleChange} 
                                value={formInformation.address} 
                                required/>
                        </div>
                        <div className={classes.marginBot24 +' upload-addr-box'} style={{
                            marginTop: '84px'
                        }}>
                            <div>
                                <InputLabel className={classes.formInputLabel}>ID Proof</InputLabel>
                                <InputLabel sx={{
                                    fontSize: '12px',
                                    color: 'rgb(0, 0, 0, 0.6)'
                                }}>Upload the document for ID proof</InputLabel>
                            </div>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center'
                            }}>
                                <div className="upload-addr-btn-box" style={{position: 'relative'}}>
                                    <label htmlFor="workout-images">
                                        <Input
                                            accept="image/*"
                                            id="workout-images"
                                            name="image-upload"
                                            type="file"
                                            sx={{display: 'none'}}
                                            onChange={handleChange}
                                        />
                                        <Button component="div" className="upload-addr-btn">
                                            <FileUploadOutlinedIcon />
                                            {loading ? <img src={Loader} width="24" alt="Loader" /> : 
                                                <span>{formInformation?.id_proof ? 
                                                        formInformation?.id_proof?.split("-")[3] : 'Upload Image'}</span>}
                                        </Button>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className={classes.marginBot24}>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="registration-type"
                                defaultValue="university"
                                onChange={handleChange}
                            >
                                <FormControlLabel value={"university"} 
                                control={<Radio sx={{
                                    color: 'var(--secondary-color)',
                                    '&.Mui-checked': {
                                        color: 'var(--secondary-color)',
                                      },
                                }} />} label="University" sx={{
                                    fontWeight: 'bold',
                                }} />
                                <FormControlLabel value={"coaching"} 
                                control={<Radio sx={{
                                    color: 'var(--secondary-color)',
                                    '&.Mui-checked': {
                                        color: 'var(--secondary-color)',
                                      },
                                }} />} label="Coaching" />
                                <FormControlLabel value={"both"} 
                                control={<Radio sx={{
                                    color: 'var(--secondary-color)',
                                    '&.Mui-checked': {
                                        color: 'var(--secondary-color)',
                                      },
                                }} />} label="Both" />
                            </RadioGroup>
                        </div>
                        <div className={classes.marginBot24}>
                            <InputLabel className={classes.formInputLabel}>Password</InputLabel>
                            <TextField
                                id="password"
                                name="password"
                                type={showPassword ? "text" : "password"}
                                value={formInformation.password}
                                onChange={handleChange}
                                autoComplete="current-password"
                                margin="normal"
                                required
                                InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                    </InputAdornment>
                                ),
                                }}
                                fullWidth
                                sx={{
                                    width: '100%',
                                    height: '62px'
                                }}
                            />
                        </div>
                        
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}>
                            <Button 
                                className={classes.requestButton} 
                                onClick={requestBtnClicked}
                                sx={requestLoading ? {backgroundColor: 'var(--disable-color)'} : 
                                    {backgroundColor: 'var(--secondary-color)'}}
                                disabled={requestLoading}>
                                {requestLoading ? <LoaderIcon /> : 'Sign up'}
                            </Button>
                        </div>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: '32px'
                        }}>
                            <Typography>
                                Already the user?
                            </Typography>
                            <Link to="/login" style={{
                                fontWeight: 'bold',
                                marginLeft: '4px'
                            }}>Login</Link>
                        </div>
                    </form>
                </div>
            </Box>
            </Grid>
        </Grid>
        </>
    );
}

const OtpAria = (props) => {
    const classess = useStyle()
    return (
        <>
            <div style={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center'
            }}>
                <Typography sx={{
                    color: '#EF4840',
                    fontSize: '12px'
                }} component="p" variant="p">
                    {props.title}
                </Typography>
                <Button sx={{
                    textTransform: 'capitalize',
                    fontSize: '12px',
                    color: '#000',
                    background: 'none'
                }} 
                disabled={props.isLoader && props.from === "Resend"}
                onClick={() => props.resend("Resend")}>
                    {(props.isLoader && props.from === "Resend") ? 
                    <LoaderIcon />
                    : 
                    "Resend OTP"}
                </Button>
            </div>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '12px'
            }}>
                <OtpInput
                    value={props.otp}
                    onChange={props.handleOChange}
                    numInputs={4}
                    inputStyle={{
                        width: '80px',
                        height: '36px',
                        borderTop: 'none',
                        borderRight: 'none',
                        borderLeft: 'none',
                        "&:focusVisible":{
                            borderTop: 'none',
                            borderRight: 'none',
                            borderLeft: 'none',
                        }
                    }}
                    isInputNum={true}
                    separator={<span>&nbsp;&nbsp;&nbsp;</span>}
                />
            </div>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
            }}>
                <Button 
                    className={classess.verifySubmit} 
                    disabled={props.loading}
                    sx={props.loading ? {backgroundColor: 'var(--disable-color)'} : {backgroundColor: '#DC0707'}}
                    onClick={() => props.verifyClicked(props.type === "Mobile" ? "Mobile" : "Email")}>
                    {props.loading ? 
                        <LoaderIcon />
                     : 
                    props.btnTitle}
                </Button>
            </div>
        </>
    )
}
